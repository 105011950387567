import { Component, Inject, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { isUndefined } from 'underscore';
import { S3FileManagementService } from '../../services/s3FileManagement.service';
import { getAllAcceptedFileTypes, isFileExtensionPdf } from '@user-interface/gcv-ui';
import * as base64arraybuffer from 'base64-arraybuffer';
import { environment } from '../../environments/environment';
import { openUrlFromFile } from '../../shared/io.util';

export interface DueDiligenceFileUploadModalComponentResult {
  isDisabled?: boolean;
  comments?: string;
  documents?: any[];
  docObjs?: string[];
  oldAttachments?: any[];
}

@Component({
  selector: 'gcv-create-new-dd-modal',
  templateUrl: './create-new-dd-modal.component.html',
  styleUrls: ['./create-new-dd-modal.component.scss'],
})
export class CreateNewDdModalComponent implements OnInit {
  section = 0;
  results: FormGroup;
  category = '';
  staticCategory = false;
  edit = false;
  canDelete = true;
  types = [
    { value: 'legal', viewValue: 'Legal' },
    { value: 'financial', viewValue: 'Financial' },
    { value: 'operational', viewValue: 'Operational' },
  ];

  @ViewChild('form') form: ElementRef;
  @ViewChild('form2', { static: true }) form2: ElementRef;
  ctrl = new FormGroup({
    s3Key: new FormControl(''),
    file_name: new FormControl(''),
    data: new FormControl(''),
    name: new FormControl(''),
  });
  disabledCtrl = new FormGroup({
    disabled: new FormControl(false, Validators.required),
    comment: new FormControl('', Validators.required),
  });
  prevUploadedDocuments: { id: string; file_name: string }[] = [];
  removedDocIds: string[] = [];
  public documentName = '';
  public documentDescription = '';
  public uploadedDocuments = [];
  public tempUploadedDocuments = [];
  public uploadedDocsObjUrls = [];
  public modalData;
  acceptedFileTypes = getAllAcceptedFileTypes();

  constructor(
    private matDialogRef: MatDialogRef<CreateNewDdModalComponent>,
    private cdr: ChangeDetectorRef,
    private s3File: S3FileManagementService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.documentName = data.documentName;
    this.documentDescription = data.documentDescription;
    this.disabledCtrl.controls.disabled.setValue(data.isDisabled);
    this.disabledCtrl.controls.comment.setValue(data.comments);
    if (data.uploadDocuments) {
      this.modalData = data.uploadDocuments;
      this.uploadedDocuments = data.uploadDocuments.documents;
      this.tempUploadedDocuments = [...this.uploadedDocuments];
      this.uploadedDocsObjUrls = data.uploadDocuments.docObjs;
    }
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  ngOnInit() {
    if (this.data) {
      const { category, staticCategory, edit, canDelete, attachments } = this.data;
      if (category) {
        this.category = category;
        this.staticCategory = staticCategory;
      }
      if (attachments) {
        this.prevUploadedDocuments = attachments;
      }
      if (edit) {
        this.edit = edit;
      }
      if (!isUndefined(canDelete)) {
        this.canDelete = canDelete;
      }
    }
    this.results = new FormGroup({
      name: new FormControl(
        this.data && this.data.name ? this.data.name : '',
        Validators.compose([Validators.minLength(3), Validators.maxLength(30), Validators.required])
      ),
      description: new FormControl(
        this.data && this.data.description ? this.data.description : '',
        Validators.compose([Validators.minLength(5), Validators.maxLength(300), Validators.required])
      ),
      category: new FormControl(this.data && this.data.category ? this.data.category : '', Validators.required),
    });
  }

  commentInput(e) {
    this.results.controls.description.setValue(e.srcElement.value);
  }

  cancelClick() {
    this.matDialogRef.close();
  }

  deleteRequirement() {
    this.matDialogRef.close({
      ...this.results.getRawValue(),
      delete: true,
    });
  }

  createClick() {
    this.uploadedDocuments = [...this.tempUploadedDocuments];

    this.matDialogRef.close({
      ...this.results.getRawValue(),
      documents: this.uploadedDocuments,
      oldAttachments: this.prevUploadedDocuments,
    });
  }

  onFileEvent(event) {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const dataReader = new FileReader();
    dataReader.onload = e => {
      const arrayBuffer = e.target['result'];
      const base64 = base64arraybuffer.encode(<ArrayBuffer>arrayBuffer);
      this.ctrl.controls['data'].setValue(base64);
      this.ctrl.controls.file_name.setValue(file.name);
      this.disabledCtrl.controls.disabled.setValue(false);
      this.disabledCtrl.controls.comment.setValue('');
      this.tempUploadedDocuments.push(this.ctrl.getRawValue());
      this.reset();
    };
    dataReader.readAsArrayBuffer(file);
    const urlReader = new FileReader();
    urlReader.onload = e => {
      this.uploadedDocsObjUrls.push(window.URL.createObjectURL(file));
      this.cdr.markForCheck();
    };
    urlReader.readAsDataURL(file);

    if (!this.ctrl.controls['file_name'].value && !this.ctrl.controls['data'].value) {
      this.ctrl.controls['file_name'].setValue('');
      this.ctrl.controls['data'].setValue(null);
      this.cdr.markForCheck();
    }
  }

  removeDocument(i: number) {
    this.tempUploadedDocuments.splice(i, 1);
  }

  close(results: DueDiligenceFileUploadModalComponentResult = {}) {
    this.reset();
    this.matDialogRef.close({ results });
    this.uploadedDocuments = [];
    this.tempUploadedDocuments = [];
    this.prevUploadedDocuments = [];
    this.uploadedDocuments = [];
    this.uploadedDocsObjUrls = [];
  }

  save() {
    this.uploadedDocuments = [...this.tempUploadedDocuments];
    if (this.disabledCtrl.get('disabled').value) {
      this.close({
        isDisabled: this.disabledCtrl.get('disabled').value,
        comments: this.disabledCtrl.get('comment').value,
        documents: this.uploadedDocuments,
        oldAttachments: this.prevUploadedDocuments,
      });
    } else {
      this.close({
        isDisabled: false,
        documents: this.uploadedDocuments,
        oldAttachments: this.prevUploadedDocuments,
      });
    }
  }

  private reset() {
    this.ctrl.controls.s3Key.setValue('');
    this.ctrl.controls.data.setValue('');
    this.ctrl.controls.file_name.setValue('');
    this.disabledCtrl.reset();
    if (this.form) {
      this.form.nativeElement.reset();
    }

    if (this.form2) {
      this.form2.nativeElement.reset();
    }
    this.uploadedDocsObjUrls = [];
  }

  async viewPrevDocument(index: number) {
    const { id } = this.prevUploadedDocuments[index];
    const blob = await this.s3File.viewDocument(
      { orgId: this.data.bank_id, userType: this.data.userType },
      id,
      `${environment.env}-gcv-shared-documents-file-bucket`
    );
    this.s3File.openDocumentInNewTab(blob);
  }

  removePrevDocument(i: number) {
    const [removedDoc] = this.prevUploadedDocuments.splice(i, 1);
    this.removedDocIds = [...this.removedDocIds, removedDoc.id];
  }

  async viewDocument(index: number) {
    try {
      const currentDocument = this.tempUploadedDocuments[index];

      if (!this.modalData || currentDocument) {
        // if document was just uploaded and not yet attached to the requirement object
        if (currentDocument.hasOwnProperty('data')) {
          const base64Array = base64arraybuffer.decode(currentDocument.data);
          const fileExt = currentDocument.file_name
            .split('.')
            .pop()
            .toLowerCase();
          const fileType =
            fileExt === 'png'
              ? 'image/png'
              : fileExt === ('jpg' || 'jpeg')
              ? 'image/jpeg'
              : fileExt === 'pdf'
              ? 'application/pdf'
              : '';
          const file = new File([base64Array], currentDocument.file_name, {
            type: fileType,
          });

          openUrlFromFile(file);
        } else {
          const { org_id, id } = currentDocument;
          const blob = await this.s3File.viewDocument({ orgId: org_id, userType: this.data.userType }, id);
          this.s3File.openDocumentInNewTab(blob);
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
