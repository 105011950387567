import { select, Store } from '@ngrx/store';
import { DepositState } from './deposits.reducer';
import { Dictionary } from '@ngrx/entity';
import { Deposit, DepositFilter } from '@gcv/shared';
import {
  selectAllDeposits,
  selectDepositById,
  selectDepositEntities,
  selectDepositsByDispensaryId,
  selectNewDeposit,
} from './deposits.selectors';
import { Observable } from 'rxjs';
import {
  AcceptDeposit,
  CreateDeposit,
  DepositAccepted,
  LoadDepositsByBankId,
  LoadDepositsById,
  LoadNewDeposit,
  ResetDeposits,
} from './deposits.actions';
import { Injectable } from '@angular/core';

export interface IDepositsFacade {
  getDepositsByDispensaryId(dispensaryId: string): void;

  getDepositsByBankId(bankId: string): void;

  postDeposit(deposit: Partial<Deposit>, dispensaryId: string): void;

  putAcceptDeposit(params: { deposit: Deposit; bankId: string }): void;

  getNewDepositByDispensaryId(dispensaryId: string, maxDepositFeatureFlag: boolean): void;

  resetDeposits(): void;

  selectDepositById(depositId: string): any;

  selectNewDeposit(): any;

  selectDepositsByDispensaryId(dispensaryId: string): any;

  selectAllDeposits(): any;
}

@Injectable({
  providedIn: 'root',
})
export class DepositsFacade implements IDepositsFacade {
  deposits$: Observable<Dictionary<Deposit>>;

  constructor(private store: Store<DepositState>) {
    this.deposits$ = store.pipe(select(selectDepositEntities));
  }

  getDepositsByBankId(bankId: string, startDate?: string, endDate?: string, filter?: DepositFilter) {
    this.store.dispatch(new LoadDepositsByBankId({ bankId, startDate, endDate, filter }));
  }

  getDepositsByDispensaryId(dispensaryId: string) {
    this.store.dispatch(new LoadDepositsById({ dispensaryId }));
  }

  postDeposit(deposit: Partial<Deposit>, dispensaryId: string) {
    this.store.dispatch(new CreateDeposit({ deposit, dispensaryId }));
  }

  putAcceptDeposit(params: { deposit: Deposit; bankId: string }) {
    this.store.dispatch(new AcceptDeposit(params));
  }

  getNewDepositByDispensaryId(dispensaryId: string, maxDepositFeatureFlag?: boolean) {
    this.store.dispatch(new LoadNewDeposit({ dispensaryId, maxDepositFeatureFlag })); //feature flag can be removed once we fully switch to the newMaxDeposit
  }

  resetDeposits() {
    this.store.dispatch(new ResetDeposits());
  }

  selectDepositById(depositId: string) {
    return this.store.pipe(select(selectDepositById, { depositId }));
  }

  selectNewDeposit() {
    return this.store.pipe(select(selectNewDeposit));
  }

  selectDepositsByDispensaryId(dispensaryId: string) {
    return this.store.pipe(select(selectDepositsByDispensaryId, { dispensaryId }));
  }

  selectAllDeposits() {
    return this.store.pipe(select(selectAllDeposits));
  }

  updateDeposit(deposit: Deposit) {
    this.store.dispatch(new DepositAccepted(deposit));
  }
}
