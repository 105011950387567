import * as base64arraybuffer from 'base64-arraybuffer';
import fileType from 'file-type';

import { environment } from 'apps/user-interface/src/environments/environment';
import { api } from 'libs/react-ui/src/api';

export const s3Util = apiClient => {
  const getPresignedUrl = async (s3Key, action: 'get' | 'put', userType = 'bank', bucket?: string) => {
    const body = {
      bucket: bucket ? bucket : `${environment.env}-org-documents-file-bucket`,
      key: s3Key,
      action: action,
      userType: userType,
    };

    return await apiClient.permissions.getS3Permission(body, () => {});
  };

  const readFileAsDataURL = async file => {
    const result_base64 = await new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onloadend = e => {
        const arrayBuffer = e.target['result'];
        if (typeof arrayBuffer !== 'string') {
          resolve(base64arraybuffer.encode(arrayBuffer));
        }
      };
      fileReader.readAsArrayBuffer(file);
    }).catch(e => console.log(e));

    return result_base64;
  };

  const uploadfileToS3 = async (fileuploadurl, file) => {
    try {
      return fetch(fileuploadurl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'text/plain',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: file, // body data type must match "Content-Type" header
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddDocumentToS3 = async ({ blob, userType }, orgId, custom_s3_key?, bucket?, encode: boolean = true) => {
    const data = encode ? await readFileAsDataURL(blob) : blob;
    const initial_s3_key = custom_s3_key ? custom_s3_key : `${orgId}/${blob.name}`;
    const config = {
      document: { file_name: blob.name, data },
      userType: userType,
      orgId: orgId,
      s3_key: initial_s3_key,
    };

    if (!config.document.data) {
      console.log(`Document data was null or missing, ${config.document}`);
      throw new Error();
    }
    const { s3LinkPath, s3_key } = await getPresignedUrl(initial_s3_key, 'put', userType, bucket);
    await uploadfileToS3(s3LinkPath, config.document.data);

    return {
      filename: blob.name,
      s3_key: s3_key,
    };
  };

  function base64DecodeS3Data(e: ProgressEvent, type?: string, filename?: string) {
    filename = filename ? filename : 'filename';
    const base64 = e.target['result'];
    if (!base64) {
      throw new Error("base64DecodeS3Data: no base64 data in event's result");
    }
    const base64ArrayBuffer = base64arraybuffer.decode(base64);
    if (!base64ArrayBuffer) {
      throw new Error('base64DecodeS3Data: no base64ArrayBuffer when decoding base64 data');
    }
    const parsedFileType = type ? { mime: type } : fileType(base64ArrayBuffer);
    if (!parsedFileType) {
      throw new Error('base64DecodeS3Data: unable to parse file type from base64ArrayBuffer');
    }
    const { mime } = parsedFileType;
    const file = new File([base64ArrayBuffer], filename, { type: mime });
    return { file };
  }

  const openUrlFromFile = (file: File) => {
    const url = window.URL.createObjectURL(file);
    if (url) {
      //download csv file with filename
      if (
        file.type === '' ||
        (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png')
      ) {
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', file.name);
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => document.body.removeChild(a), 0);
      } else {
        //open in new tab
        window.open(url, '_blank');
      }
    }
  };

  const openUrlFromBlob = (blob: Blob, file_name: string) => {
    const fileReader = new FileReader();
    fileReader.onload = (e: ProgressEvent) => {
      const { file } = base64DecodeS3Data(e, file_name.includes('.csv') ? 'text/csv' : null, file_name);
      openUrlFromFile(file);
    };
    fileReader.readAsBinaryString(blob);
  };

  const viewDocument = async ({ file_name, s3_key }, bucket?) => {
    const { s3LinkPath } = await getPresignedUrl(s3_key, 'get', null, bucket);

    const response = await fetch(s3LinkPath, {
      method: 'GET',
    });

    const blob = await response.blob();

    openUrlFromBlob(blob, file_name);
  };

  return {
    viewDocument,
    openUrlFromBlob,
    handleAddDocumentToS3,
    getPresignedUrl,
  };
};
