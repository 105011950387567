import { $grey2 } from 'libs/react-ui/src/util';
import styled from 'styled-components';

interface Props {
  complete?: boolean;
  current?: boolean;
}

export const Container = styled.div`
  margin: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const Content = styled.div`
  margin: 0 auto;
`;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 17px;
  margin: 30px auto;
  width: 70%;
  word-wrap: normal;
`;

export const SubTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0 auto;
  width: 40%;
  word-wrap: normal;
`;

export const DashboardSubTitle = styled.div`
  color: ${$grey2};
  max-width: 65%;
  margin: 0 auto 0.25rem;
`;

export const ButtonWrapper = styled.div`
  margin: 25px auto;
`;
