import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-bank-transportation-vendors',
  templateUrl: './bank-transportation-vendors.component.html',
  styleUrls: ['./bank-transportation-vendors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransportationVendorsComponent implements OnInit {
  @Input() vendors;
  @Input() dispensaryView = true;
  @Output() favoriteUser = new EventEmitter();
  @Output() editVendor = new EventEmitter();
  title = '';
  subtitle = '';

  constructor() {}

  ngOnInit() {
    if (this.dispensaryView) {
      this.title = 'Provided by Financial Institution';
      this.subtitle =
        'These services are managed by your banking partner. If a service is mandatory, all other options will be greyed out.';
    } else {
      this.title = 'Methods of Transportation';
      this.subtitle =
        this.vendors && this.vendors.length > 0
          ? // tslint:disable-next-line:max-line-length
            'Click the star to universally apply a service to all accounts. You can apply these services to individuals from the accounts tab.'
          : 'Add a new service using the button at the top of the screen.';
    }
  }

  handleFavorite(vendor, i) {
    this.favoriteUser.emit({ type: 'bank', data: vendor });
  }

  handleEdit(vendor) {
    if (vendor.active) {
      this.editVendor.emit(vendor);
    }
  }
}
