import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Snackbar, Grid } from '@material-ui/core';

import {
  SubText,
  CardRowContainer,
  Card,
  PosIcon,
  DrawerHeader,
  VerticalCenter,
  Title,
  DrawerContent,
  ButtonContainer,
  DrawerText,
  IconContainer,
  FooterContent,
} from './styles';
import { GcvStatusIndicator, GcvDrawer, GcvInputForm, GcvButton, GcvCheckbox, GcvContent } from '../../lib';
import {
  Portal42Form,
  MjFreewayForm,
  GreenBitsForm,
  TreezForm,
  FlowHub,
  LeafLogixForm,
  CovaForm,
  GrowFlowForm,
  IntacctForm,
} from './PosTypes/';

import { useForm, FormContextValues } from 'react-hook-form';
import { BioTrackForm } from './PosTypes/BioTrack';
import { QuickBooksForm } from './PosTypes/QuickBooks';
import { BlazeForm } from './PosTypes/Blaze';
import { Pos } from './PosConfigs';
import { PosType } from '@gcv/shared';
import { $danger } from '../../util';

interface Props {
  drawerPosType: Pos;
  form: FormContextValues<Record<string, any>>;
  hasLocationStep: boolean;
  loading: boolean;
  onSubmit: (data: any) => void;
  integrateQuickbooks: () => any;
  error: string;
}

export const EnterPosConfig = ({
  drawerPosType,
  form,
  loading,
  hasLocationStep,
  onSubmit,
  integrateQuickbooks,
  error,
}: Props) => {
  const mockForm = useForm({ mode: 'onChange' });

  const needDateGateCheck =
    drawerPosType.name === PosType.GrowFlow ||
    drawerPosType.name === PosType.Cova ||
    drawerPosType.name === PosType.MjFreeway ||
    drawerPosType.name === PosType.MjFreewayWholesale;

  const toolTip = 'Please enter required fields to enable';

  return (
    <Grid container direction={'column'} justify="space-between" style={{ height: '91vh' }}>
      <Grid item>
        <DrawerContent>
          {drawerPosType.name === PosType.Portal42 ? <Portal42Form form={form}></Portal42Form> : null}
          {drawerPosType.name === PosType.MjFreeway || drawerPosType.name === PosType.MjFreewayWholesale ? (
            <MjFreewayForm form={form}></MjFreewayForm>
          ) : null}
          {drawerPosType.name === PosType.GreenBits ? <GreenBitsForm form={form}></GreenBitsForm> : null}
          {drawerPosType.name === PosType.TreezIO ? <TreezForm form={form}></TreezForm> : null}
          {drawerPosType.name === PosType.Flowhub ? <FlowHub form={form}></FlowHub> : null}
          {drawerPosType.name === PosType.LeafLogix || drawerPosType.name === PosType.LeafLogixWholesale ? (
            <LeafLogixForm form={form}></LeafLogixForm>
          ) : null}
          {drawerPosType.name === PosType.BioTrackSql || drawerPosType.name === PosType.BioTrackSqlWholesale ? (
            <BioTrackForm form={form}></BioTrackForm>
          ) : null}
          {drawerPosType.name === PosType.Cova ? <CovaForm form={form}></CovaForm> : null}
          {drawerPosType.name === PosType.Quickbooks ? <QuickBooksForm form={form}></QuickBooksForm> : null}
          {drawerPosType.name === PosType.Blaze ? <BlazeForm form={form}></BlazeForm> : null}
          {drawerPosType.name === PosType.GrowFlow ? <GrowFlowForm form={form}></GrowFlowForm> : null}
          {drawerPosType.name === PosType.Intacct ? <IntacctForm form={form}></IntacctForm> : null}
          {needDateGateCheck ? (
            <>
              <DrawerText>Please enter the date you sent the email:</DrawerText>
              <GcvInputForm
                {...mockForm}
                name="date"
                label="Email Date"
                type="date"
                rules={{ required: true }}
              ></GcvInputForm>
            </>
          ) : null}
        </DrawerContent>
      </Grid>

      <Grid item>
        <FooterContent>
          <ButtonContainer style={{ justifyContent: 'center' }}>
            <label data-tip={toolTip}>
              <GcvButton
                primary={true}
                isLoading={loading}
                onClick={drawerPosType.name === PosType.Quickbooks ? integrateQuickbooks : form.handleSubmit(onSubmit)}
                disabled={!form.formState.isValid || !mockForm.formState.isValid}
              >
                {hasLocationStep ? 'Continue' : 'Connect'}
              </GcvButton>
              {!form.formState.isValid || !mockForm.formState.isValid ? (
                <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
              ) : null}
            </label>
          </ButtonContainer>
          <GcvContent type="l2" style={{ color: $danger, marginTop: '.5rem', textAlign: 'center' }} content={error} />
        </FooterContent>
      </Grid>
    </Grid>
  );
};
