import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import { Dispensary, User, Bank, AccountActivity as AccountActivityRes } from '@gcv/shared';
import { BodySubContainer, BodySubTitle, BodySubContent } from './styles';
import { GcvCard, GcvLoading, GcvContent, GcvTimePeriodDropdown, GcvStatusIndicator, GcvZeroState } from '../../../lib';
import {
  formatMissingDash,
  formatMoney,
  $danger,
  $primary,
  formatDateOrDash,
  formatMonthlyOrDailyDateOrDashForCharts,
  checkPermissions,
} from '../../../util';
import calculateTimeRange, { calculateChartBatchSize } from '../../../util/calculateDateRange';
import { Grid, Typography } from '@material-ui/core';
import { SectionTypes } from '../Account-Review-Overview.page';
import { entitytypes } from 'libs/gcv-ui/src/lib';
import { Header, ReviewText, Title, VerticalCenter } from '../styles';
import { BackButtonIcon } from 'libs/react-ui/src/icons/BackButtonIcon';
import { GcvChart } from 'libs/react-ui/src/lib/GcvChart/GcvChart';
import { MultiLineConfig, VerticalBarConfig } from 'libs/react-ui/src/lib/GcvChart/chart-options';
import { api } from 'libs/react-ui/src/api';
import { dispensary_frequency_options, dispensary_rating_options_map } from 'libs/react-ui/src/constants/Accounts';
import { FlexBox } from 'libs/react-ui/src/styles/theme';
import { InfoIcon } from '../../../icons/InfoIcon';
import ReactTooltip from 'react-tooltip';
import { tooltip } from 'libs/react-ui/src/lib/GcvChart/Tooltip';

interface Props {
  dispensary?: Dispensary;
  user: User;
  onSave: (type: SectionTypes) => void;
  onBack: () => void;
  emitData: (type: string, data) => void;
  isActiveAccount?: boolean;
  bank: Bank;
}

export const AccountActivity = ({ dispensary, user, onSave, onBack, isActiveAccount, emitData, bank }: Props) => {
  const [accountActivityResponse, setAccountActivityResponse] = useState<AccountActivityRes>(null);
  const [timePeriod, setTimePeriod] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiClient = api();

  useEffect(() => {
    if (dispensary && !timePeriod) {
      setTimePeriod(calculateTimeRange('last30Days', dispensary.iana_timezone));
    }
  }, [dispensary]);

  useEffect(() => {
    if (timePeriod && timePeriod.start) {
      fetchAccountActivity();
    }
  }, [timePeriod]);

  const handleTimeChange = results => {
    const timeRange = calculateTimeRange(results.value, dispensary.iana_timezone);
    setTimePeriod(timeRange);
  };

  const fetchAccountActivity = async () => {
    const batchSize: 'daily' | 'monthly' = calculateChartBatchSize(timePeriod.start, timePeriod.end);
    const res = await apiClient.banks.getAccountActivity(
      bank.id,
      dispensary.id,
      setLoading,
      timePeriod.start,
      timePeriod.end,
      batchSize
    );
    setAccountActivityResponse(res);
  };

  const transactionActivityChartConfig: MultiLineConfig =
    accountActivityResponse && Object.keys(accountActivityResponse.core_txn_activity).length
      ? {
          categories: [
            {
              category: Object.keys(accountActivityResponse.core_txn_activity).map(k => ({
                label: formatMonthlyOrDailyDateOrDashForCharts(k),
              })),
            },
          ],
          dataset: [
            {
              color: $primary,
              data: Object.entries(accountActivityResponse.core_txn_activity).map(([k, v]) => ({
                value: (v.deposits / 100).toFixed(1),
                tooltext: tooltip(formatMonthlyOrDailyDateOrDashForCharts(k), [
                  {
                    value: formatMoney(v.deposits),
                    label: 'Deposits',
                  },
                ]),
              })),
            },
            {
              color: `#1270FC`,
              data: Object.entries(accountActivityResponse.core_txn_activity).map(([k, v]) => ({
                value: (v.withdrawals / 100).toFixed(1),
                tooltext: tooltip(formatMonthlyOrDailyDateOrDashForCharts(k), [
                  {
                    value: formatMoney(v.withdrawals),
                    label: 'Withdrawals',
                  },
                ]),
              })),
            },
          ],
        }
      : null;
  const salesActivityChartConfig: VerticalBarConfig =
    accountActivityResponse && Object.keys(accountActivityResponse.sales_activity).length
      ? {
          data: Object.entries(accountActivityResponse.sales_activity).map(([k, v]) => ({
            value: v.total_amount / 100,
            color: $primary,
            label: formatMonthlyOrDailyDateOrDashForCharts(k),
            tooltext: tooltip(formatMonthlyOrDailyDateOrDashForCharts(k), [
              {
                value: `${formatMoney(v.verified_amount)}  (${v.percent_verified.toFixed(1)}%)`,
                label: 'Verified',
              },
              {
                value: `${formatMoney(v.unverified_amount)}  (${v.percent_unverified.toFixed(1)}%)`,
                label: 'Unverified',
              },
              {
                value: `${formatMoney(v.unchecked_amount)}  (${v.percent_unchecked.toFixed(1)}%)`,
                label: 'Unchecked',
              },
              {
                value: `${formatMoney(v.total_amount)}`,
                label: 'Total',
              },
            ]),
          })),
        }
      : null;

  const openReviews = () => {
    emitData('openReviews', { name: dispensary.name });
  };
  const openReports = (type: 'CTR' | 'SAR') => {
    emitData('openReports', { id: dispensary.id, type });
  };

  return (
    <>
      {!isActiveAccount && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Header style={{ justifyContent: 'start' }}>
              <BackButtonIcon onClick={onBack} style={{ cursor: 'pointer' }} />
              <VerticalCenter style={{ marginLeft: '1rem' }}>
                <Title>Business Details</Title>
              </VerticalCenter>
            </Header>
            <ReviewText>Look over the business’s identification, address and contact details.</ReviewText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontFamily: 'Lato, Helvetica, Arial, san-serif' }}>
              You are reviewing: <strong>{dispensary.name}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReviewText>
            Not seeing deposit and withdrawal activity on the page below? Simply{' '}
            <a
              href={`mailto:support@greencheckverified.com?subject=Enable Account Monitoring for ${bank.orgName}&body=Our financial institution would like to enable Account Monitoring to begin seeing transaction data for our accounts.`}
            >
              contact us
            </a>{' '}
            to enable the Account Monitoring feature and start gaining insights about your accounts through core
            transaction data.
          </ReviewText>
        </Grid>
        <Grid item xs={3}>
          <GcvTimePeriodDropdown
            emitData={handleTimeChange}
            labelText={'Time Period'}
            newStyle={true}
            defaultValueDrop={'last30Days'}
          ></GcvTimePeriodDropdown>
        </Grid>
      </Grid>
      {loading || !accountActivityResponse ? (
        <GcvLoading></GcvLoading>
      ) : (
        <Grid container spacing={2} style={{ marginTop: '.5rem' }}>
          <Grid item xs={6}>
            <GcvCard>
              <GcvCard.Header title={<strong>Transaction Summary</strong>} />
              <GcvCard.Body>
                <Grid container spacing={2} style={{ marginLeft: '1rem' }}>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Total Deposits" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(formatMoney(accountActivityResponse.core_txns.total_deposits))}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Average Deposit" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(formatMoney(accountActivityResponse.core_txns.avg_deposist))}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Total Fees" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(formatMoney(accountActivityResponse.core_txns.total_fees))}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Total Withdrawals" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(formatMoney(accountActivityResponse.core_txns.total_withdrawals))}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Average Withdrawal" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(formatMoney(accountActivityResponse.core_txns.avg_withdrawal))}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Most Recent Transaction" />
                      <GcvContent type="p1" content={formatDateOrDash(accountActivityResponse.core_txns.most_recent)} />
                    </div>
                  </Grid>
                </Grid>
              </GcvCard.Body>
            </GcvCard>
          </Grid>
          <Grid item xs={6}>
            <GcvCard>
              <GcvCard.Header title={<strong>Sales Summary</strong>} />
              <GcvCard.Body>
                <Grid container spacing={2} style={{ marginLeft: '1rem' }}>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Total Sales" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(formatMoney(accountActivityResponse.sales.total))}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Most Recent Sale" />
                      <GcvContent type="p1" content={formatDateOrDash(accountActivityResponse.sales.most_recent)} />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <FlexBox>
                        <GcvContent type="l1" grey2={true} content="Variance Amount" />
                        <label
                          data-for="varianceAmountToolTip"
                          data-tip={'Total sales minus total deposits for the period'}
                        >
                          <VerticalCenter style={{ height: '100%', marginLeft: '.25rem' }}>
                            <InfoIcon></InfoIcon>
                          </VerticalCenter>
                          <ReactTooltip
                            id="varianceAmountToolTip"
                            place="top"
                            type="dark"
                            effect="solid"
                            delayShow={250}
                          />
                        </label>
                      </FlexBox>
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(formatMoney(accountActivityResponse.sales.variance))}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Verified" />
                      <GcvContent
                        type="p1"
                        content={
                          accountActivityResponse.sales.percent_verified ||
                          accountActivityResponse.sales.percent_verified === 0
                            ? `${accountActivityResponse.sales.percent_verified.toFixed(1)}%`
                            : '--'
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      {dispensary.posConfig?.fetch?.fetchStrategy === 'upload' ? (
                        <>
                          <GcvContent type="l1" grey2={true} content="Most Recent Upload" />
                          <GcvContent
                            type="p1"
                            content={formatDateOrDash(accountActivityResponse.sales.most_recent_upload)}
                          />
                        </>
                      ) : (
                        <>
                          <GcvContent type="l1" grey2={true} content="Integration Status:" />
                          <GcvContent type="p1" content={'Connected'} />
                        </>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Variance Percentage" />
                      <GcvContent
                        type="p1"
                        content={
                          accountActivityResponse.sales.percent_variance ||
                          accountActivityResponse.sales.percent_variance === 0
                            ? `${accountActivityResponse.sales.percent_variance.toFixed(1)}%`
                            : '--'
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </GcvCard.Body>
            </GcvCard>
          </Grid>
          <Grid item xs={12}>
            <GcvCard>
              <GcvCard.Header
                title={
                  <FlexBox style={{ justifyContent: 'space-between' }}>
                    <strong>Transaction Activity</strong>
                    <FlexBox>
                      <VerticalCenter style={{ margin: ' 0 .5rem' }}>
                        <div style={{ width: 8, height: 8, backgroundColor: $primary, borderRadius: '50%' }}></div>
                      </VerticalCenter>
                      <GcvContent type="p1" content="Deposits" style={{ marginRight: '1.5rem' }} />
                      <VerticalCenter style={{ margin: '0 .5rem' }}>
                        <div style={{ width: 8, height: 8, backgroundColor: '#1270FC', borderRadius: '50%' }}></div>
                      </VerticalCenter>
                      <GcvContent type="p1" content="Withdrawals" />
                    </FlexBox>
                  </FlexBox>
                }
              />
              <GcvCard.Body>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {transactionActivityChartConfig ? (
                      <GcvChart type="msline" config={transactionActivityChartConfig} height={300}></GcvChart>
                    ) : (
                      <GcvZeroState
                        type="basic"
                        subText="No activity recorded"
                        style={{ height: '300px', margin: '0px' }}
                      ></GcvZeroState>
                    )}
                  </Grid>
                </Grid>
              </GcvCard.Body>
            </GcvCard>
          </Grid>
          <Grid item xs={12}>
            <GcvCard>
              <GcvCard.Header title={<strong style={{ lineHeight: '24px' }}>Sales Activity</strong>} />
              <GcvCard.Body>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {salesActivityChartConfig ? (
                      <GcvChart type="verticalBar" config={salesActivityChartConfig} height={300}></GcvChart>
                    ) : (
                      <GcvZeroState
                        type="basic"
                        subText="No activity recorded"
                        style={{ height: '300px', margin: '0px' }}
                      ></GcvZeroState>
                    )}
                  </Grid>
                </Grid>
              </GcvCard.Body>
            </GcvCard>
          </Grid>
          <Grid item xs={6}>
            <GcvCard>
              <GcvCard.Header
                title={
                  <FlexBox style={{ justifyContent: 'space-between', width: '100%', height: '2rem' }}>
                    <strong>Account Reviews</strong>
                    {checkPermissions(['account_review_view'], user, bank) ? (
                      <GcvContent
                        type="a"
                        content={`${accountActivityResponse.account_review.open_reviews_count} open review${
                          accountActivityResponse.account_review.open_reviews_count === 1 ? '' : 's'
                        }`}
                        onClick={openReviews}
                      />
                    ) : null}
                  </FlexBox>
                }
              />
              <GcvCard.Body>
                <Grid container spacing={2} style={{ marginLeft: '1rem' }}>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Last Completed On" />
                      <GcvContent
                        type="p1"
                        content={formatDateOrDash(accountActivityResponse.account_review.last_completed)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Period" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(
                          dispensary_frequency_options.find(
                            v => v.value === accountActivityResponse.account_review.period
                          ).label
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Next Review" />
                      <GcvContent
                        type="p1"
                        content={formatDateOrDash(accountActivityResponse.account_review.next_review)}
                        style={{ color: getDueDateColor(accountActivityResponse.account_review.next_review) }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Completed By" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(accountActivityResponse.account_review.completed_by_username)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Risk Rating" />
                      <GcvContent
                        type="p1"
                        content={formatMissingDash(
                          dispensary_rating_options_map[accountActivityResponse.account_review.risk_rating]
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </GcvCard.Body>
            </GcvCard>
          </Grid>
          <Grid item xs={6}>
            <GcvCard>
              <GcvCard.Header
                title={
                  <FlexBox style={{ justifyContent: 'space-between', width: '100%', height: '2rem' }}>
                    <strong>FinCEN</strong>
                    {checkPermissions(['fincen_report_view'], user, bank) ? (
                      <FlexBox style={{ flexDirection: 'column' }}>
                        <GcvContent
                          type="a"
                          content={`${accountActivityResponse.fincen.open_ctrs.length} open CTR${
                            accountActivityResponse.fincen.open_ctrs.length === 1 ? '' : 's'
                          }`}
                          onClick={() => openReports('CTR')}
                        />
                        <GcvContent
                          type="a"
                          content={`${accountActivityResponse.fincen.open_sars.length} open SAR${
                            accountActivityResponse.fincen.open_sars.length === 1 ? '' : 's'
                          }`}
                          onClick={() => openReports('SAR')}
                        />
                      </FlexBox>
                    ) : null}
                  </FlexBox>
                }
              />
              <GcvCard.Body>
                <Grid container spacing={2} style={{ marginLeft: '1rem' }}>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Last SAR Filed" />
                      <GcvContent type="p1" content={formatDateOrDash(accountActivityResponse.fincen.last_sar_filed)} />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Last CTR Filed" />
                      <GcvContent type="p1" content={formatDateOrDash(accountActivityResponse.fincen.last_ctr_filed)} />
                    </div>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Next SAR Due" />
                      <GcvContent
                        type="p1"
                        content={formatDateOrDash(getNextFincenReportDueDate(accountActivityResponse.fincen.open_sars))}
                        style={{
                          color: getDueDateColor(getNextFincenReportDueDate(accountActivityResponse.fincen.open_sars)),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <GcvContent type="l1" grey2={true} content="Next CTR Due" />
                      <GcvContent
                        type="p1"
                        content={formatDateOrDash(getNextFincenReportDueDate(accountActivityResponse.fincen.open_ctrs))}
                        style={{
                          color: getDueDateColor(getNextFincenReportDueDate(accountActivityResponse.fincen.open_ctrs)),
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </GcvCard.Body>
            </GcvCard>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const getDueDateColor = (date: string): string => {
  if (date && date < DateTime.local().toISO()) {
    return $danger;
  } else {
    return '';
  }
};

const getNextFincenReportDueDate = (
  reports: {
    report_id: string;
    due_date: string;
    overdue: boolean;
  }[]
): string => {
  const sortedReports = reports.sort((a, b) => (a.due_date > b.due_date ? 1 : -1));
  return sortedReports[0]?.due_date;
};
