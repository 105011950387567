import { ReactWrapperComponent, WrapperProps, ReportsTable } from '@user-interface/react-ui';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { DispensaryFacade, BankFacade, FincenFacade } from '@user-interface/gcv-state';
import { Bank, FincenSarReport, FincenCtrReport } from '@gcv/shared';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fincen-reports',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></ app-react-wrapper>',
})
export class ReportsTableComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Input() reports: any;
  @Output() clickEmitter = new EventEmitter<any>();

  component = ReportsTable;
  reportsStatus = '';
  reportsType = '';
  bank: Bank;

  constructor(
    private dispFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private router: Router,
    private fincenFacade: FincenFacade,
    private route: ActivatedRoute
  ) {
    super();
    this.handleEmitData = this.handleEmitData.bind(this);
  }

  ngOnInit() {
    this.props = {
      ...this.props,
      reportsLoading: true,
    };
    this.setUpStreams();
    this.props = {
      ...this.props,
      reports: this.reports,
      reportsStatus: this.getreportsStatus(),
      reportsType: this.route.snapshot.paramMap.get('report_type'),
    };
  }

  getreportsStatus() {
    if (this.router.url.includes('open')) {
      return 'open';
    } else if (this.router.url.includes('filed')) {
      return 'filed';
    } else {
      return '';
    }
  }

  setUpStreams() {
    this.bankFacade
      .selectCurrentBank()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(bank => {
        this.bank = bank;
        this.props = {
          ...this.props,
          bank,
        };
      });
    let hasReports = this.reports && (this.reports.ctrs.length > 0 || this.reports.sars.length > 0);
    if (!hasReports) {
      this.fincenFacade.getFincenByBankId(this.bank.id);
    }
    const reports$ = this.fincenFacade.selectAllFincen().pipe(
      map((reports: (FincenCtrReport | FincenSarReport)[]) => {
        const filteredReports = {
          ctrs: [],
          sars: [],
          reportsLoaded: reports.length > 0,
        };
        reports.forEach((report: FincenCtrReport | FincenSarReport) => {
          if (this.getreportsStatus() === 'open') {
            if (report.report_type === 'SAR' && !report.exported_on) {
              filteredReports.sars.push(report);
            } else if (!report.exported_on) {
              filteredReports.ctrs.push(report);
            }
          } else {
            if (report.report_type === 'SAR' && report.exported_on) {
              filteredReports.sars.push(report);
            } else if (report.exported_on) {
              filteredReports.ctrs.push(report);
            }
          }
        });
        return filteredReports;
      })
    );
    reports$.pipe(takeUntil(this.unsubscribe$)).subscribe(reports => {
      this.reports = reports;
      this.props = {
        ...this.props,
        reports: reports,
        reportsLoading: !reports.reportsLoaded,
      };
    });
    this.dispFacade
      .selectDispensaryDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(dispensaryMap => {
        this.props = {
          ...this.props,
          dispensaryMap,
        };
      });
  }

  ngOnChanges() {
    this.props = {
      ...this.props,
      reports: this.reports,
      reportsStatus: this.getreportsStatus(),
    };
  }

  async handleEmitData(payload) {
    if (payload.type === 'viewReport') {
      const data: NavigationExtras = {
        queryParams: {
          id: payload.reportId,
        },
      };
      this.router.navigate(['/secure/bank/view/report'], data);
    } else if (payload.type === 'getReports') {
      this.fincenFacade.getFincenByBankId(this.bank.id);
    }
  }
}
