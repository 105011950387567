import React from 'react';

import { RouteObject } from '../../util/types';
import AccountMonitoring from './Account-Monitoring.page';
import Review from './Review.page';

export const routes: RouteObject[] = [
  {
    path: '/secure/bank/account-monitoring/review/:id/:tab',
    render: props => <Review {...props}></Review>,
  },
  {
    path: '/secure/bank/account-monitoring/:tab',
    render: props => <AccountMonitoring {...props}></AccountMonitoring>,
  },
];
