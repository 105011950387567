import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FincenCtrReport, FincenSarReport } from '@gcv/shared';
import { FincenActionTypes } from './fincen.actions';

export interface FincenState extends EntityState<FincenCtrReport | FincenSarReport> {}

export const adapterFincen: EntityAdapter<FincenCtrReport | FincenSarReport> = createEntityAdapter<
  FincenCtrReport | FincenSarReport
>();

export const initialFincenState = adapterFincen.getInitialState();

export function fincenReducer(state = initialFincenState, action): FincenState {
  switch (action.type) {
    case FincenActionTypes.FincenLoaded:
      return adapterFincen.upsertMany(action.payload, state);
    case FincenActionTypes.FincenByBankIdLoaded:
      return adapterFincen.setAll(action.payload, state);
    case FincenActionTypes.FincenUpdated:
      return adapterFincen.upsertOne(action.payload, state);
    case FincenActionTypes.ResetFincen:
      return initialFincenState;
    case FincenActionTypes.FincenDeleted:
      return adapterFincen.removeOne(action.payload, state);
    default:
      return state;
  }
}

export const {
  selectIds: selectFincenIds,
  selectEntities: selectFincenEntities,
  selectAll: selectAllFincen,
} = adapterFincen.getSelectors();
