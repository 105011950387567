import styled from 'styled-components';

import { $grey1, $white, $grey5, $grey9, $blue, $grey11 } from '../../../util/styleVariables';

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ViewFile = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${$blue};
  margin: 15px auto;
  cursor: pointer;
`;

export const HorizontalCenter = styled.div`
  margin: 60px 0;
  display: flex;
  justify-content: center;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${$grey11};
  padding: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 90px;
  background-color: ${$white};
  flex-shrink: 0;
`;

export const HeaderContent = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

export const Spacer = styled.div`
  margin: 20px 0px;
`;

export const Body = styled.div`
  padding: 0px 75px 75px 75px;
  overflow-y: auto;
`;

export const SubTitle = styled.div`
  margin-top: 18px;
  font-size: 13px;
  line-height: 16px;
  color: ${$grey9};
`;

export const SubContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 2px 0px;
`;

export const ReadMoreButton = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${$blue};
  &: hover {
    cursor: pointer;
  }
`;

export const UserInput = styled.div`
  border: none;
`;

export const Block = styled.div`
  padding: 10px 0px;
  margin: 30px 0px;
  border-top: 1px solid ${$grey11};
`;

export const Footer = styled.div`
  border-top: 1px solid ${$grey1};
  padding: 25px;
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${$white};
  flex-shrink: 0;
`;

export const DrawerContent = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
`;
