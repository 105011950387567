import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { $blue, $danger, $grey2, $grey3, $grey4, $primary, $primaryDark, $white } from '../../util/styleVariables';
import { ButtonProps } from './GcvButton';

export const Button = styled.button<ButtonProps>`
  height: 2.25rem;
  max-width: 18rem;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0 1rem;
  text-align: center;
  border-width: 1.5px;
  border: none;
  min-width: 9rem;
  cursor: pointer;
  transition: translate 0.2s ease, transform 0.2s ease, background-color 0.4s ease, border 0.3s ease, position 0.2s ease;
  -webkit-transition: translate 0.2s ease, transform 0.2s ease, background-color 0.4s ease, border 0.3s ease,
    position 0.2s ease;

   

  .buttonInner {
    text-rendering: geometricPrecision;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  ${props =>
    props.pill &&
    css`
      height: 30px;
      border-radius: 15px;
      font-size: 14px;
      padding: 0;
      width: 7.5rem;
      min-width: unset;
    `}

  ${props =>
    props.primary &&
    css`
      border-color: ${$primary};
      background-color: ${$primary};
      color: ${$white};

      .icon {
        fill: $white;
      }

      &:hover {
        background: ${$primaryDark};
      }
    `}

    ${props =>
      props.secondary &&
      css`
        border: 1.5px solid ${$primary};
        background-color: transparent;
        color: ${$primary};

        .icon {
          fill: ${$primary};
        }

        &:hover {
          color: ${$primaryDark};
          border-color: ${$primaryDark};

          .icon {
            fill: ${$primaryDark};
          }
        }
      `}

      ${props =>
        props.tertiary &&
        css`
          border: 1.5px solid ${$grey3};
          background-color: transparent;
          color: ${$grey2};

          .icon {
            fill: ${$grey2};
          }

          &:hover {
            color: ${$grey2};
            background: transparent;
            border-color: ${$grey2};

            .icon {
              fill: ${$grey2};
            }
          }
        `}

        ${props =>
          props.link &&
          css`
            border: 1.5px solid transparent;
            background-color: transparent;
            color: ${$blue};
            padding: 0.5rem;

            .icon {
              fill: ${$blue};
            }

            &:hover {
              border: 1.5px solid ${$grey3};
              color: $blue;
              background: transparent;
              border-color: ${$grey3};

              .icon {
                fill: ${$grey3};
              }
            }
          `}

          ${props =>
            props.danger &&
            css`
              border: 1px solid transparent;
              background-color: ${$danger};
              color: ${$white};

              .icon {
                fill: ${$white};
              }

              &:hover {
                background: ${darken(0.15, $danger)};
              }
            `}

          ${props =>
            props.dangerSecondary &&
            css`
              border: 1.5px solid ${$danger};
              background-color: transparent;
              color: ${$danger};

              .icon {
                fill: ${$danger};
              }

              &:hover {
                color: ${darken(0.15, $danger)};
                border-color: ${darken(0.15, $danger)};
              }
            `}

        ${props =>
          props.cancel &&
          css`
            background-color: white;
            color: rgb(165, 168, 186);

            &:hover {
              background-color: white;
              color: rgb(165, 168, 186);
            }
          `}

            ${props =>
              props.fullWidth &&
              css`
                width: 100%;
                max-width: unset;
                padding-left: unset;
                padding-right: unset;
              `}

            ${props =>
              props.large &&
              css`
                font-size: 16px;
                height: 3rem;
              `}

            ${props =>
              props.small &&
              css`
                font-size: 13px;
                width: 4rem;
              `}

          
  ${props =>
    props.disabled &&
    css`
      background: ${$grey4};

      border: 1.5px solid transparent;
      color: ${$grey3};
      cursor: default;

      .icon {
        fill: ${$grey3};
      }

      &:hover {
        color: ${$grey3};
        border: 1.5px solid transparent;
        background: ${$grey4};
      }
    `}

        
`;
