import React from 'react';

import { DailyDistribution, DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { AreaConfig } from './chart-options';
import { DateTime } from 'luxon';
import { tooltip } from './Tooltip';
import { formatMoney } from 'libs/react-ui/src/util';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const TotalSalesAndDepositsAreaChart: React.FC<Props> = props => {
  return <GcvChart type="area" config={chartDataAdapter(props.review)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData) => {
  return dataAdapter(review.data.daily_distribution);
};

export const dataAdapter = (data: { [date: string]: DailyDistribution }): AreaConfig => {
  function capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatName(unFormattedName: string) {
    const splitName = unFormattedName.split('_');

    const name: string[] = [];

    for (let x = 0; x < splitName.length; x++) {
      name.push(capitalize(splitName[x]));
    }

    return name.join(' ');
  }

  function colorPicker(type: string) {
    switch (type) {
      case 'ach':
        return '#F5A623';
      case 'ach_wire':
        return '#414288';
      case 'adjustment':
        return '#682d63';
      case 'bill_payments':
        return '#5FB49C';
      case 'card':
        return '#00A77E';
      case 'card_credit':
        return '#98DFAF';
      case 'card_debit':
        return '#DEEFB7';
      case 'cash':
        return '#3581B8';
      case 'check':
        return '#E65F5C';
      case 'crypto':
        return '#0F0326';
      case 'dividend':
        return '#B5D99C';
      case 'fee':
        return '#324376';
      case 'interest_dividend':
        return '#F5F7DC';
      case 'interest':
        return '#FFFF82';
      case 'monetary_instrument':
        return '#F19A3E';
      case 'payments':
        return '#E8FCCF';
      case 'p2p':
        return '#96E072';
      case 'physical':
        return '#78A9F5';
      case 'returned_items':
        return '#3DA35D';
      case 'nsf':
        return '#3E8914';
      case 'rtp':
        return '#134611';
      case 'tax':
        return '#211A1D';
      case 'transfer':
        return '#EB8B47';
      case 'wire':
        return '#BB6BD9';
      case 'other':
        return '#6320EE';
      default:
        return '#B3CDD1';
    }
  }

  const formattedData = {
    categories: [
      {
        category: Object.keys(data)
          .sort((a, b) =>
            DateTime.fromFormat(a, 'yyyy-MM-dd') > DateTime.fromFormat(b, 'yyyy-MM-dd')
              ? 1
              : DateTime.fromFormat(b, 'yyyy-MM-dd') > DateTime.fromFormat(a, 'yyyy-MM-dd')
              ? -1
              : 0
          )
          .map(d => {
            return { label: DateTime.fromFormat(d, 'yyyy-MM-dd').day.toString() };
          }),
      },
    ],
    dataset: [
      {
        seriesName: 'Sales',
        color: '#CCF2E0',
        renderAs: 'line',
        data: Object.keys(data)
          .sort((a, b) =>
            DateTime.fromFormat(a, 'yyyy-MM-dd') > DateTime.fromFormat(b, 'yyyy-MM-dd')
              ? 1
              : DateTime.fromFormat(b, 'yyyy-MM-dd') > DateTime.fromFormat(a, 'yyyy-MM-dd')
              ? -1
              : 0
          )
          .map(d => {
            return {
              value: data[d].sales / 100,
              toolText: tooltip('Sales', [
                { label: 'Total', value: formatMoney(data[d].sales) },
                { label: 'Date', value: DateTime.fromFormat(d, 'yyyy-MM-dd').toLocaleString() },
              ]),
            };
          }),
      },
    ].concat(
      data[Object.keys(data)[0]]?.deposits
        ? [
            ...Object.keys(data[Object.keys(data)[0]]?.deposits).reduce((acc, curr) => {
              return [
                ...acc,
                {
                  seriesName: formatName(curr),
                  color: colorPicker(curr),
                  data: Object.keys(data)
                    .sort((a, b) =>
                      DateTime.fromFormat(a, 'yyyy-MM-dd') > DateTime.fromFormat(b, 'yyyy-MM-dd')
                        ? 1
                        : DateTime.fromFormat(b, 'yyyy-MM-dd') > DateTime.fromFormat(a, 'yyyy-MM-dd')
                        ? -1
                        : 0
                    )
                    .map(d => {
                      return {
                        value: data[d].deposits[curr] / 100,
                        toolText: tooltip(
                          'Deposits',
                          Object.keys(data[d].deposits)
                            .reduce((acc, curr) => {
                              return [
                                ...acc,
                                {
                                  label: formatName(curr),
                                  value: formatMoney(data[d].deposits[curr]),
                                },
                              ];
                            }, [])
                            .concat([
                              {
                                label: 'Total',
                                value: formatMoney(Object.values(data[d].deposits).reduce((a, b) => a + b, 0)),
                              },
                              { label: 'Date', value: DateTime.fromFormat(d, 'yyyy-MM-dd').toLocaleString() },
                            ])
                        ),
                      };
                    }),
                },
              ];
            }, []),
          ]
        : []
    ),
  };

  return formattedData;
};
