import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'underscore';
import * as Chart from 'chart.js';

@Component({
  selector: 'gcv-data-chart',
  templateUrl: './data-chart.component.html',
  styleUrls: ['./data-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataChartComponent implements OnChanges {
  @ViewChild('chartCanvas', { static: true })
  chartCanvas: ElementRef;
  @Input() loading: boolean;
  @Input() chartData: { [k: string]: { bankable: number; unbankable: number } };
  @Input() verifiedChecked: boolean;
  @Input() unverifiedChecked: boolean;
  @Input() timeLabel: string;
  private chart: any;

  constructor() {}

  static sortSalesData(a, b) {
    const textA = a.toUpperCase();
    const textB = b.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupChart(this.chartData);
  }

  setupChart(salesData) {
    const labels = _.keys(salesData).sort(DataChartComponent.sortSalesData);

    const bankableData = labels.map(label => {
      return salesData[label].bankable / 100;
    });
    const unbankableData = labels.map(label => {
      return salesData[label].unbankable / 100;
    });

    if (this.chart) {
      this.chart.destroy();
    }

    const maxTickLine =
      Math.round(
        (_.max(bankableData) +
          _.max(unbankableData) +
          Math.max(500, (_.max(bankableData) + _.max(unbankableData)) * (0.25 / labels.length))) /
          1000
      ) * 1000;

    const chartContext = this.chartCanvas.nativeElement.getContext('2d');
    const data = {
      type: 'bar',
      data: {
        labels,
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.3,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                stepSize: maxTickLine / 5,
                max: maxTickLine,
                callback: (value, index, values) => {
                  if (parseInt(value, 10) >= 1000) {
                    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  } else {
                    return '$' + value;
                  }
                },
              },
            },
          ],
        },
      },
    };
    if (this.verifiedChecked) {
      data.data.datasets.push({
        label: 'Verified',
        backgroundColor: '#00BC66',
        data: bankableData,
      });
    }
    if (this.unverifiedChecked) {
      data.data.datasets.push({
        label: 'Unverified',
        backgroundColor: '#E5F8EF',
        data: unbankableData,
      });
    }
    this.chart = new Chart(chartContext, data);
  }
}
