import React, { useState } from 'react';
import { GcvButton, GcvModal } from '../../../../lib';
import { Buttons } from './styles';

interface Props {
  emitData: (data) => void;
  bankId: string;
}

export const CreateNewFincenReportButton = ({ emitData, ...props }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const completeRequestSAR = () => {
    emitData('sar');
  };

  const completeRequestCTR = () => {
    emitData('ctr');
  };

  return (
    <>
      <GcvButton onClick={toggleModal} primary={true} style={{ width: '12rem' }}>
        Create New Report
      </GcvButton>

      <GcvModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="What type of report do you want to create?"
        backButton={
          <GcvButton tertiary={true} onClick={toggleModal}>
            Cancel
          </GcvButton>
        }
      >
        <Buttons>
          <GcvButton onClick={completeRequestSAR} primary={true}>
            SAR
          </GcvButton>
          <GcvButton onClick={completeRequestCTR} primary={true}>
            CTR
          </GcvButton>
        </Buttons>
      </GcvModal>
    </>
  );
};
