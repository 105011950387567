import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, iif, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { AmplifyService } from 'aws-amplify-angular';
import { SecureAppFamilyService } from '../../../services/secure.service';
import { Bank, Dispensary, User, isBankUser, isDispensaryUser, isGcvUser } from '@gcv/shared';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Cache } from 'aws-amplify';

import {
  AggregatesFacade,
  BankFacade,
  DepositsFacade,
  DispensaryFacade,
  ErrorActionTypes,
  FincenFacade,
  NotificationsFacade,
  PrimaryContactFacade,
  SalesFacade,
  StaffFacade,
  UserFacade,
} from '@user-interface/gcv-state';
import { LoggerService } from '@user-interface/gcv-ui';
import { Unsubscribe } from '@user-interface/ng-shared';

import { termsOfServiceRoutesNames } from '../../terms-of-service/terms-of-service.routes.names';
import { isInitialDispensaryUserAndStillNeedsToSignTermsOfService } from '../../terms-of-service/terms-of-service.util';
import { supportUserLoginRoutesNames } from '../../support-user-login/support-user-login.routes.names';
import { isGcvCustomerSupportUserAndNeedsToSignIn } from '../../support-user-login/support-user-login.util';
import { DefaultService } from '@gcv/generated-services';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends Unsubscribe {
  public loading = new BehaviorSubject(false);
  loggedIn = false;
  private authGuard = false;
  private companyInitialized = false;

  constructor(
    private amplifyService: AmplifyService,
    private secureService: SecureAppFamilyService,
    private router: Router,
    private userFacade: UserFacade,
    private dispFacade: DispensaryFacade,
    private aggFacade: AggregatesFacade,
    private notificationFacade: NotificationsFacade,
    private staffFacade: StaffFacade,
    private bankFacade: BankFacade,
    private saleFacade: SalesFacade,
    private primaryContactFacade: PrimaryContactFacade,
    private depositFacade: DepositsFacade,
    private fincenFacade: FincenFacade,
    private loggerService: LoggerService,
    private updates$: Actions,
    private sdk: DefaultService
  ) {
    super();
    this.updates$
      .pipe(
        ofType(ErrorActionTypes.AddError),
        takeUntil(this.unsubscribe$),
        tap((arg: any) => {
          this.loggerService.error(JSON.stringify(arg));
          //  TOODO(pc): figure out how to properly handle errors (e.g. 404)
          // if (arg.payload.action === DispensaryActionTypes.LoadDispensary) {
          // this.logout();
          // }
        })
      )
      .subscribe();
  }

  public set loggin(val) {
    this.loggedIn = val;
  }

  public get loggin() {
    return this.loggedIn;
  }

  private checkUser(user: User, company: User['companies'][0]) {
    this.loggerService.log('check user');
    if (user.invitation_status === 'archived') {
      this.logout();
      return;
    } else if (isGcvCustomerSupportUserAndNeedsToSignIn(user)) {
      this.loggerService.log('support user logged in');
      this.router
        .navigate([`/${supportUserLoginRoutesNames.SUPPORT_USER_LOGIN}`])
        .then(value => this.loggerService.log(value))
        .catch(e => this.loggerService.error(e));
      return;
    } else if (isInitialDispensaryUserAndStillNeedsToSignTermsOfService(user)) {
      this.router
        .navigate([`/${termsOfServiceRoutesNames.TERMS_OF_SERVICE}`])
        .then(value => this.loggerService.log(value))
        .catch(e => this.loggerService.error(e));
      return;
    }
  }

  public initLogin(authUser, authGuard = false): Observable<boolean> {
    this.authGuard = authGuard;
    return this.userFacade.getUserById(authUser.username, true, authUser).pipe(
      filter((user: User) => user !== undefined),
      tap((user: User) => {
        const company = user.companies[0];
        this.checkUser(user, company);
        this.secureService.updateThirdPartyConfigs(user);
      }),
      first(),
      mergeMap((user: User) => this.determineCompany(user))
    );
  }

  determineCompany(user: User) {
    if (user && !this.companyInitialized) {
      const updatedUserDetails = { lastLogin: new Date().toISOString() };
      if (user.invitation_status === 'pending') {
        updatedUserDetails['invitation_status'] = 'accepted';
      }
      this.userFacade.updateUserIdentification(user.id, updatedUserDetails);
      this.companyInitialized = true;
      const orgId = user.companies[0].id;

      if (user.role === 'gcv_customer_support') {
        if (user.companies[0].companyType === 'dispensary') {
          return this.setupDispensary(user);
        } else {
          return this.setupBank(user.id, orgId);
        }
      }

      if (isDispensaryUser(user)) {
        return this.setupDispensary(user);
      }

      if (isBankUser(user)) {
        return this.setupBank(user.id, orgId);
      }

      if (isGcvUser(user)) {
        return this.setupGcv();
      }

      this.loading.next(false);
    } else {
      this.loading.next(false);
      return of(true);
    }
  }

  initializeStoreForDispensary(dispensaryIds: string[]) {}

  initializeStoreForBank(companyId: string) {
    this.bankFacade.getBankById(companyId);
    this.staffFacade.getOrganizationStaffByOrgId(companyId);
  }

  setupBank(userId: string, orgId: string) {
    this.dispFacade.getDispensariesbyBankId(orgId);
    this.initializeStoreForBank(orgId);
    this.notificationFacade.getNotificationsByUserId(
      userId,
      orgId,
      DateTime.utc()
        .minus({ days: 30 })
        .toISO()
    );
    return this.bankFacade.selectBankById(orgId).pipe(
      filter((bank: Bank) => bank !== undefined),
      mergeMap((bank: Bank) => this.routeBank(bank))
    );
  }

  routeBank(bank: Bank) {
    return from(this.handleAuthGuard('/secure/bank/dashboard/overview')).pipe(map(() => true));
  }

  setupGcv() {
    this.dispFacade.getAllDispensaries();
    this.bankFacade.getAllBanks();
    this.staffFacade.getAllOrganizationStaff();
    return from(this.handleAuthGuard('/secure/gcv/dashboard')).pipe(map(() => true));
  }

  setupDispensary(user) {
    const dispensaries = user.companies;
    const pastId = window.sessionStorage.getItem('currentDispensaryId');
    const initialDispensaryId = pastId || dispensaries[0].id;
    this.addDispensariesToStore(dispensaries);
    this.dispFacade.updateCurrentDispensary(initialDispensaryId);
    this.initializeStoreForDispensary([initialDispensaryId]);
    this.notificationFacade.getNotificationsByUserId(
      user.id,
      initialDispensaryId,
      DateTime.utc()
        .minus({ days: 30 })
        .toISO()
    );
    return this.dispFacade.selectDispensaryById(initialDispensaryId).pipe(
      filter((dispensary: Dispensary) => dispensary !== undefined),
      first(),
      mergeMap((dispensary: Dispensary) => this.routeDispensary(dispensary))
    );
  }

  addDispensariesToStore(companies) {
    companies.forEach(company => {
      this.dispFacade.getDispensarybyId(company.id);
    });
  }

  routeDispensary(dispensary: Dispensary) {
    return from(this.handleAuthGuard('/secure/dispensary/dashboard/sales')).pipe(map(() => true));
  }

  handleAuthGuard(route) {
    return new Promise((resolve, reject) => {
      if (!this.authGuard) {
        this.router
          .navigate([route])
          .then(() => {
            this.loading.next(false);
            resolve();
          })
          .catch(err => console.error(err));
      } else {
        this.loading.next(false);
        resolve();
      }
    });
  }

  resetNgrxFacades() {
    this.notificationFacade.resetNotifications();
    this.saleFacade.resetSales();
    this.depositFacade.resetDeposits();
    this.fincenFacade.resetFincen();
    this.aggFacade.resetAggregateStore();
    this.primaryContactFacade.resetPrimaryContact();
    this.dispFacade.resetDispensary();
    this.bankFacade.resetBankState();
    this.staffFacade.resetStaff();
    this.userFacade.resetUser();
  }

  logout(): void {
    this.userFacade.selectCurrentUser().subscribe(user => {
      if (user.role === 'gcv_customer_support') {
        this.sdk
          .usersIdPut(user.id, {
            ...user,
            initial_user: true,
          })
          .subscribe(() => {
            this.completeLogout();
            this.router.navigate(['/login']).then(() => window.location.reload());
          });
      } else {
        this.completeLogout();
        this.router.navigate(['/login']).then(() => window.location.reload());
      }
    });
  }

  completeLogout() {
    this.amplifyService.auth().signOut();
    this.amplifyService.setAuthState({ state: 'signedOut', user: null });
    this.loggin = false;
    this.loading.next(false);
    Cache.clear();
    window.sessionStorage.removeItem('currentDispensaryId');

    // this.resetNgrxFacades(); // FIXME GS-2354
  }

  isUserLoggedIn() {
    return from(this.amplifyService.auth().currentAuthenticatedUser());
  }
}
