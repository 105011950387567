import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-account-mot',
  templateUrl: './view-account-mot.component.html',
  styleUrls: ['./view-account-mot.component.scss'],
})
export class ViewAccountMotComponent {
  user: any;
  cognitoId: any;
  dispensary: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private matDialogRef: MatDialogRef<ViewAccountMotComponent>) {
    this.user = data.vendor;
    this.cognitoId = data.cognitoId;
    this.dispensary = data.dispensary;
  }

  close() {
    this.matDialogRef.close();
  }
}
