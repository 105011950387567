import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';

import { ReactWrapperComponent, WrapperProps, SettingsRouter } from '@user-interface/react-ui';

import { slideInAnimation } from '../../app/animations/router-animation';
import { BankFacade, DispensaryFacade, UserFacade } from '@user-interface/gcv-state';
import { takeUntil } from 'rxjs/operators';
import { User } from '@gcv/shared';

@Component({
  selector: 'bank-settings',
  template: '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props"></app-react-wrapper>',
  animations: [trigger('routeAnimations', [transition('active <=> pending', [useAnimation(slideInAnimation)])])],
})
export class BankSettingsComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = SettingsRouter;
  bank: any;
  user: User;

  constructor(private bankFacade: BankFacade, private dispFacade: DispensaryFacade, private userFacade: UserFacade) {
    super();
  }

  ngOnInit() {
    this.setUpStreams();
  }

  setUpStreams() {
    this.bankFacade
      .selectCurrentBank()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(bank => {
        this.bank = bank;
        this.props = { ...this.props, bank };
      });
    this.dispFacade
      .selectAllDispensaries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(dispensaries => {
        this.props = { ...this.props, dispensaries };
      });
    this.userFacade
      .selectCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.user = user;
        this.props = { ...this.props, user };
      });
  }
}
