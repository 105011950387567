import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';

import { GcvButton, GcvInputFile, GcvInputForm, GcvInputSelect, GcvModal } from '../../lib';

import { FormContainer } from './styles';

interface Props {
  emitData: (data) => any;
}

export const AddNewDocument = (props: Props) => {
  const form = useForm();

  const [modalOpen, setModalOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const onSubmit = data => {
    const document = {
      filename: data.documentName,
      type: data.documentType.value,
      data: files[0],
    };
    props.emitData(document);
    toggleModal();
    setFiles([]);
  };

  const options = [
    { value: 'financial', label: 'Financial' },
    { value: 'legal', label: 'Legal' },
    { value: 'operational', label: 'Operational' },
    { value: 'other', label: 'Other' },
  ];

  const formComplete = form.watch('documentName') && form.watch('documentType') && files.length > 0;

  return (
    <>
      <GcvButton onClick={toggleModal} primary={true}>
        Add New Document
      </GcvButton>
      <GcvModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        backButton={
          <GcvButton onClick={toggleModal} primary={true}>
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={form.handleSubmit(onSubmit)} primary={true} disabled={!formComplete}>
            Add
          </GcvButton>
        }
        title={'Add New Document'}
      >
        <FormContainer>
          <GcvInputForm {...form} name="documentName" label="Document Name" type="text" />
        </FormContainer>
        <FormContainer>
          <GcvInputSelect {...form} label="Type" name="documentType" options={options} />
        </FormContainer>
        <FormContainer>
          <GcvInputFile
            updateFileState={fileState => setFiles(fileState.allFiles)}
            files={files}
            acceptedTypes={['all']}
          />
        </FormContainer>
      </GcvModal>
    </>
  );
};
