<div [ngStyle]="styles" class="floating-form">
  <div class="floating-label">
    <input
      (blur)="onBlur()"
      (change)="onInputChange($event)"
      (focus)="onFocus()"
      [attr.data-cy]="'input.component.' + property"
      [autocomplete]="autoFill"
      [disabled]="disabled"
      [type]="type"
      [value]="inputValue"
      class="floating-input"
      placeholder=" "
    />
    <span class="highlight"></span> <label>{{ label }}<span *ngIf="required" class="required"> *</span></label>
  </div>
</div>
