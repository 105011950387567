import { DispensaryRiskRating, ReviewFrequency } from '@gcv/shared';

export const dispensary_rating_options: { label: string; value: DispensaryRiskRating }[] = [
  { label: 'No Rating', value: 'none' },
  { label: 'Low', value: 'low' },
  { label: 'Low-Moderate', value: 'low-moderate' },
  { label: 'Moderate-High', value: 'high-moderate' },
  { label: 'High', value: 'high' },
];
export const dispensary_rating_options_map: { [value: string]: string } = {
  none: 'No Rating',
  low: 'Low',
  'low-moderate': 'Low-Moderate',
  'high-moderate': 'Moderate-High',
  high: 'High',
};

export const dispensary_frequency_options: { label: string; value: ReviewFrequency }[] = [
  { label: 'No Review', value: ReviewFrequency.None },
  { label: 'Monthly', value: ReviewFrequency.Monthly },
  { label: 'Quarterly', value: ReviewFrequency.Quarterly },
  { label: 'Yearly', value: ReviewFrequency.Yearly },
];
