import styled, { createGlobalStyle } from 'styled-components';
import { $blue, $grey2, $grey5 } from '../../util/styleVariables';

export const Container = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
`;

export const SubContainer = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin: 1rem 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

export const Description = styled.div`
  margin: 1rem 0;
`;

export const ButtonContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: flex-end;
`;

export const ArchiveContainer = styled.div`
  margin: 24px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

export const Archive = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ArchiveText = styled.span`
  color: ${$blue};
  margin-left: 5px;
`;

export const DescriptionContainer = styled.span`
  margin: 1rem 0;
  height: 8rem;
`;

export const PosIcon = styled.img`
  margin: 10px auto;
`;

export const Spacer = styled.div`
  margin: 20px 0px;
`;

export const SubTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: ${$grey2};
`;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

export const DrawerText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 5px 0 20px 0;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 65px;
  height: 91vh;
  overflow-y: auto;
  box-sizing: border-box;
  width: 482px;
`;

export const DrawerHeader = styled.div`
  height: 9vh;
  padding: 23px;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f2f9;
  position: sticky;
  top: 0;
  background-color: white;
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Warning = styled.div`
  font-size: 10px;
  padding: 2px 0px;
  color: red;
  height: 14px;
`;
