import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AggregatesFacade,
  BankFacade,
  DepositsFacade,
  DispensaryFacade,
  NotificationsFacade,
  SalesFacade,
  StaffFacade,
  UserFacade,
} from '@user-interface/gcv-state';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dispensary, User } from '@gcv/shared';
import Amplify, { API, Auth } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
@Component({
  selector: 'gcv-navigation-shell',
  templateUrl: './navigation-shell.component.html',
  styleUrls: ['./navigation-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationShellComponent implements OnInit, OnChanges {
  @Input() username: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() companies: object;
  @Input() notificationsComplete: any[];
  @Input() notificationsIncomplete: any[];
  @ViewChild('sidenav', { static: true }) sideNav;
  @Output() profileClicked = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() notificationClicked = new EventEmitter();
  @Output() notificationHandled = new EventEmitter();
  @Output() navigateToDashboard = new EventEmitter();
  public accountOpen = false;
  public opened = false;
  public tabIndex = 0;
  public completeNotifications = [];
  public incompleteNotifications = [];
  dispensaries$: Observable<Dispensary[]>;
  currentDispensary$: Observable<Dispensary>;
  user: User;
  currentDispensary: Dispensary;

  constructor(
    private router: Router,
    private dispensaryFacade: DispensaryFacade,
    private aggFacade: AggregatesFacade,
    private notificationFacade: NotificationsFacade,
    private staffFacade: StaffFacade,
    private saleFacade: SalesFacade,
    private bankFacade: BankFacade,
    private depositFacade: DepositsFacade,
    private userFacade: UserFacade
  ) {}

  ngOnInit() {
    this.userFacade.selectCurrentUser().subscribe(user => {
      this.user = user;
    });
    this.dispensaries$ = this.dispensaryFacade
      .selectDispensaryDictionary()
      .pipe(map((dispensaries: any) => dispensaries)) as Observable<Dispensary[]>;
    this.currentDispensary$ = this.dispensaryFacade.selectCurrentDispensary().pipe();
    this.currentDispensary$.subscribe(dispensary => {
      this.currentDispensary = dispensary;
    });
  }

  ngOnChanges() {}

  handleProfileClick() {
    this.profileClicked.emit();
  }

  handleNotificationIconClick() {
    this.sideNav.toggle();
  }

  handleNotificationClicked(e) {
    this.sideNav.toggle();
    this.notificationHandled.emit(e);
  }

  handleClick({ id, settings, logout }) {
    if (id) {
      this.dispensaryFacade.updateCurrentDispensary(id);
      window.sessionStorage.setItem('currentDispensaryId', id);
      this.clearStore();
      this.populateStore(id);
    } else if (settings) {
      this.profileClicked.emit();
    } else if (logout) {
      this.logout.emit();
    } else {
      console.log('err no payload');
    }
  }

  populateStore(dispensaryId: string) {
    this.saleFacade.getAllUndepositedSalesByDispensaryId(dispensaryId);
    this.depositFacade.getDepositsByDispensaryId(dispensaryId);
    this.staffFacade.getOrganizationStaffByOrgId(dispensaryId);
    this.dispensaryFacade.getDispensaryGcvDueDiligenceTemplate(dispensaryId);
    this.bankFacade.getBankPartial(dispensaryId);
  }

  clearStore() {
    this.notificationFacade.resetNotifications();
    this.saleFacade.resetSales();
    this.depositFacade.resetDeposits();
    this.aggFacade.resetAggregateStore();
    this.staffFacade.resetStaff();
    // this.primaryContactFacade.resetPrimaryContact();
    // this.dispFacade.resetDispensary();
    // this.bankFacade.resetBankState();
  }

  goToDashboard() {
    const url = this.router.url;
    if (url.search('bank') > -1) {
      this.navigateToDashboard.emit({ view: '/secure/bank/dashboard/overview' });
    } else if (url.search('dispensary') > -1) {
      this.navigateToDashboard.emit({ view: '/secure/dispensary/dashboard/sales' });
    } else {
      this.navigateToDashboard.emit({ view: '/gcv/dashboard/' });
      this.router.navigate(['']);
    }
  }

  toggleProfileDrawer(val) {
    this.accountOpen = val;
  }

  handleDismissNotificationDrawer() {
    this.sideNav.toggle();
  }

  handleNotificationTabClicked(val) {
    this.tabIndex = val;
  }

  clearNotifications() {
    const orgId =
      this.user.companies[0].companyType === 'dispensary' ? this.currentDispensary.id : this.user.companies[0].id;

    return API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/notifications/${orgId}/${this.user.id}/seen`, {})
      .then(() => {
        this.notificationFacade.resetNotifications();
      })
      .catch(e => {
        console.log(e);
      });
  }
}
