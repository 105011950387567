import { Storage } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { LoggerService } from '@user-interface/gcv-ui';
import { DefaultService } from '@gcv/generated-services';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HttpHeaders, HttpRequest, HttpClient } from '@angular/common/http';
import { openFileInNewWindow } from '../shared/io.util';
import Amplify, { API, Auth } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';

export interface DocumentConfig {
  document?: any;
  orgId?: string;
  userType?: 'bank' | 'dispensary' | 'gcv';
  s3_key?: string;
}

@Injectable({
  providedIn: 'root',
})
export class S3FileManagementService {
  constructor(private logger: LoggerService, private sdk: DefaultService, private http: HttpClient) {
    this.setupBucket();
  }

  setupBucket() {
    Storage.configure({
      bucket: environment.storageConfig.document,
      region: 'us-east-1',
    });
  }

  uploadDocument(config: DocumentConfig, bucket?) {
    return this.getPresignedUrl(config, 'put', bucket).pipe(
      mergeMap(
        // @ts-ignore
        ({ s3LinkPath, s3_key }) => from(this.uploadfileToS3(s3LinkPath, config.document.data, s3_key))
      ),
      mergeMap(({ s3_key }) =>
        this.saveDocumentInDatabase(config.orgId, s3_key, config.document.filename || config.document.file_name)
      )
    );
  }

  async uploadUserDocument(config) {
    try {
      //@ts-ignore
      const { s3LinkPath, s3_key } = await this.getUserPresignedUrl(config, 'put');
      await this.uploadfileToS3(s3LinkPath, config.document.data, s3_key);
      return this.saveUserDocumentInDatabase(
        config.userId,
        s3_key,
        config.document.filename || config.document.file_name
      );
    } catch (err) {
      this.logger.error(err);
    }
  }

  async viewDocument(config: DocumentConfig, documentId: string, bucket?) {
    try {
      const { s3_key } = await this.getDocumentById(config.orgId, documentId);

      const configWithS3Key: DocumentConfig = { ...config, s3_key };
      // @ts-ignore
      const { s3LinkPath } = await this.getPresignedUrl(configWithS3Key, 'get', bucket).toPromise();

      // const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
      const req = new HttpRequest('GET', s3LinkPath, {
        // headers: headers,
        reportProgress: false,
        responseType: 'blob',
      });
      const { body } = (await this.http.request(req).toPromise()) as any;
      return body;
    } catch (e) {
      console.log(e);
    }
  }

  async viewUserDocument(config: DocumentConfig, documentId: string) {
    try {
      const { s3_key } = await this.getUserDocumentById(documentId);

      const configWithS3Key: DocumentConfig = { ...config, s3_key };
      // @ts-ignore
      const { s3LinkPath } = await this.getUserPresignedUrl(configWithS3Key, 'get');

      // const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
      const req = new HttpRequest('GET', s3LinkPath, {
        // headers: headers,
        reportProgress: false,
        responseType: 'blob',
      });
      const { body } = (await this.http.request(req).toPromise()) as any;
      return body;
    } catch (e) {
      console.log(e);
    }
  }

  uploadfileToS3(fileuploadurl, file, s3_key) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
      const req = new HttpRequest('PUT', fileuploadurl, file, {
        headers: headers,
        reportProgress: false,
      });
      this.http
        .request(req)
        .toPromise()
        .then(() => {
          resolve({ s3_key });
        })
        .catch(reject);
    });
  }

  saveDocumentInDatabase(orgId: string, s3Key: string, fileName: string) {
    return this.sdk.documentsPost({ orgId, fileName, s3Key });
  }

  saveUserDocumentInDatabase(userId: string, s3Key: string, fileName: string) {
    return API.post(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/documents/user`, {
      body: { userId, fileName, s3Key },
    });
  }

  async getDocumentById(orgId: string, documentId: string) {
    try {
      const result = await this.sdk.documentsOrgIdIdGet(orgId, documentId).toPromise();
      if (Array.isArray(result)) {
        return result[0];
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  }

  async getUserDocumentById(documentId: string) {
    try {
      const result = await API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/documents/user/id/${documentId}`, {});

      if (Array.isArray(result)) {
        return result[0];
      }

      return result;
    } catch (e) {
      console.log(e);
    }
  }

  getPresignedUrl(config: DocumentConfig, action: 'put' | 'get', bucket?: string) {
    const { s3_key, userType } = config;
    return this.sdk.permissionsS3Post({
      bucket: bucket ? bucket : `${environment.env}-org-documents-file-bucket`,
      key: s3_key,
      action,
      userType,
    });
  }

  async getUserPresignedUrl(config: DocumentConfig, action: 'put' | 'get') {
    const { s3_key, userType } = config;
    return this.sdk
      .permissionsS3Post({
        bucket: `${environment.env}-user-documents-file-bucket`,
        key: s3_key,
        action,
        userType,
      })
      .toPromise();
  }

  fetchDataFromS3(s3Key) {
    this.get(s3Key).then((fetchKey: string) => {
      this.getPdfData(fetchKey).subscribe(
        (res: Blob) => {
          openFileInNewWindow(res);
        },
        err => {
          console.error(err);
        }
      );
    });
  }

  getPdfData(s3KeyUrl: string): any {
    const cacheBusterUrl = `${s3KeyUrl}`;
    return this.http.get(cacheBusterUrl, {
      responseType: 'blob',
    });
  }

  openDocumentInNewTab(document: Blob, type?: string, filename?: string) {
    openFileInNewWindow(document, type, filename);
  }

  async get(key: string) {
    try {
      return await Storage.get(key);
    } catch (err) {
      this.logger.error(err);
    }
  }

  async getRegulatoryGuide(key: string) {
    try {
      return await Storage.get(key, {
        level: 'public',
        contentType: 'text/plain',
        bucket: environment.storageConfig.regulatoryGuide,
      });
    } catch (err) {
      this.logger.error(err);
    }
  }

  async putRegulatoryGuide(key: string, data) {
    return await Storage.vault.put(key, data, {
      level: 'public',
      contentType: 'text/plain',
      bucket: environment.storageConfig.regulatoryGuide,
    });
  }

  putSales(key, data, metadata?) {
    Storage.vault
      .put(key, data, {
        level: 'public',
        contentType: 'text/plain',
        bucket: environment.storageConfig.salesFile,
        metadata,
      })
      .then(() => {})
      .catch(err => this.logger.error(err));
  }

  putDocuments(key, data, type = 'image/jpg') {
    Storage.put(key, data, {
      level: 'public',
      contentType: type,
    })
      .then(() => {})
      .catch(err => this.logger.error(err));
  }
}
