import React from 'react';

interface Props {
  style?: any;
  color?: string;
}

export const DownArrowIcon = ({ style, color }: Props) => (
  <svg
    height="14"
    version="1"
    width="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    style={{ paddingTop: '8px', ...style }}
    data-cy="down-arrow-icon"
  >
    <path d="M13 0h2v2L8 8 0 2V0h2l6 5 5-5z" fill={color ? color : '#A5A8BA'} fillRule="nonzero" />
  </svg>
);
