import React from 'react';
import { Card, CardContent, CardHeader, Grid, Select, TextField, MenuItem, InputLabel } from '@material-ui/core';

interface Props {
  selectedDeposit: any;
  setSelectedDeposit: (deposit: {}) => void;
}

export const EditDepositsCard: React.FC<Props> = props => {
  return (
    <Card>
      <CardHeader title="Edit Deposit" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="final_deposit"
              label="Final Deposit"
              value={props.selectedDeposit.final_deposit}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setSelectedDeposit({ ...props.selectedDeposit, final_deposit: parseInt(e.target.value) })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="expected_arrival_date"
              label="Expected Arrival Date"
              value={props.selectedDeposit.expected_arrival_date}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setSelectedDeposit({ ...props.selectedDeposit, expected_arrival_date: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="arrived_date"
              label="Arrived Date"
              value={props.selectedDeposit.arrived_date}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setSelectedDeposit({ ...props.selectedDeposit, arrived_date: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              id="bank_comments"
              label="Bank Comments"
              value={props.selectedDeposit.bank_comments}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setSelectedDeposit({ ...props.selectedDeposit, bank_comments: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              fullWidth
              labelId="status-select-label"
              value={props.selectedDeposit.status}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setSelectedDeposit({ ...props.selectedDeposit, status: e.target.value })
              }
            >
              <MenuItem value={'pending'}>Pending</MenuItem>
              <MenuItem value={'under_review'}>Under Review</MenuItem>
              <MenuItem value={'accepted'}>Reconciled</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="posted_date"
              label="Date Posted"
              value={props.selectedDeposit.posted_date}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setSelectedDeposit({ ...props.selectedDeposit, posted_date: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
