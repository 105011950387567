import React, { useState, useEffect } from 'react';
import { api } from '../api';
import { GcvLoading, GcvInputSelectNew, GcvInputFile, GcvButton } from '../lib';
import { Bank } from '@gcv/shared';
import { environment } from 'apps/user-interface/src/environments/environment';
import { HorizontalCenter } from '../styles/theme';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

const Container = styled.div`
  margin: 0 1rem;
`;

const Header = styled.div`
  width: 288px;
  color: white;
  text-align: center;
  padding: 0.5rem 4rem;
  box-sizing: border-box;
  background-color: #37d890;
`;

const HeaderText = styled.h3`
  color: white;
  background-color: #37d890;
  font-size: 1.17em;
  font-weight: bold;
`;

const Block = styled.div`
  margin: 1rem;
`;

export const GcvEnterBankCodes = props => {
  const apiClient = api();
  const [loading, setLoading] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState<string>('');
  const [files, setFiles] = useState([]);

  const onSubmit = () => {
    const reader = new FileReader();

    //this needs to match the order of the columns that are in the csv the user uploads
    const normalizedHeaders = ['transaction_type', 'transaction_code', 'transaction_name', 'payment_type'];

    reader.onload = function(e) {
      var csv = reader.result;
      //@ts-ignore
      var lines = csv.split('\n');
      var result = [];

      //skip line 0 that has the headers
      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(',');
        if (currentline.length === 1) continue; //skip empty lines
        for (var j = 0; j < normalizedHeaders.length; j++) {
          obj[normalizedHeaders[j]] = currentline[j].trim();
        }
        result.push(obj);
      }

      const codeMap = result.reduce((acc, line) => {
        acc[line.transaction_code] = line;
        return acc;
      }, {});

      try {
        apiClient.banks.upsertBankInternalCodes(selectedBankId, codeMap, setLoading).then(() => {
          alert('Successfully added internal codes');
        });
      } catch (e) {
        alert('something went wrong');
        setLoading(false);
      }
    };

    reader.readAsText(files[0]);
  };

  return (
    <Container>
      <Header>
        <HeaderText>Enter Bank Internal Codes</HeaderText>
      </Header>
      {loading ? (
        <GcvLoading></GcvLoading>
      ) : (
        <div>
          <Block>
            <TextField
              fullWidth
              label="Bank ID"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedBankId(e.target.value)}
            />
          </Block>

          <Block>
            <GcvInputFile
              updateFileState={fileState => setFiles(fileState.allFiles)}
              acceptedTypes={['all']}
              files={files}
              multiple={false}
            ></GcvInputFile>
          </Block>

          <Block>
            <HorizontalCenter>
              <GcvButton onClick={onSubmit} disabled={!files.length || !selectedBankId} primary={true}>
                Submit
              </GcvButton>
            </HorizontalCenter>
          </Block>
        </div>
      )}
    </Container>
  );
};
