import React from 'react';
import { Wrapper, InitialContainer } from './styles';

interface Props {
  firstName?: string;
  lastName?: string;
}

export const GcvInitialsLogo = (props: Props) => {
  return (
    <Wrapper>
      <InitialContainer>
        {props.firstName.split('')[0]}
        {props.lastName.split('')[0]}
      </InitialContainer>
    </Wrapper>
  );
};
