import { environment } from '../../environments/environment';
import { LoggerService, LogLevelEnum } from '@user-interface/gcv-ui';
import * as LogRocket from 'logrocket';
import { GreenCheckAwsEnvironmentName, User } from '@gcv/shared';

const logger = new LoggerService();

if (environment.env === GreenCheckAwsEnvironmentName.PROD) {
  logger.setLevel(LogLevelEnum.OFF);
}

const logRocketConfig = {};

function setSessionUrl(data) {
  logger.log('Logrocket Enabled');
  let logrocketSessionUrl;
  LogRocket.getSessionURL(sessionURL => {
    logrocketSessionUrl = sessionURL;
    logRocketConfig['sessionURL'] = logrocketSessionUrl;
  });
  LogRocket.identify(data.userId, {
    name: data.username,
    email: data.email,
  });
}

export function updateLogRocketConfig(user: User) {
  const data = {
    userId: user.id,
    username: `${user.firstName} ${user.lastName}`,
    email: user.email,
    env: environment.env,
    companyType: user.companies[0].companyType,
  };
  if (environment.env === GreenCheckAwsEnvironmentName.PROD) {
    setSessionUrl(data);
    LogRocket.init('ajzrpt/production-btbu3');
  } else if (environment.env === GreenCheckAwsEnvironmentName.STAGING) {
    setSessionUrl(data);
    LogRocket.init('ajzrpt/staging-1hqux');
  } else {
    logger.log('Logrocket Disabled');
  }
}
