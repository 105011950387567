import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Bank, Dispensary, DispensaryAccountReviewWithData, ReviewPeriodType } from '@gcv/shared';
import { GcvDataTable, GcvInputSelectNew, GcvLoading } from 'libs/react-ui/src/lib';
import {
  formatChangePercentage,
  formatMoney,
  formatMoneyWithNegatives,
  formatPercentage,
  formatOrdinalIndicator,
  doesReviewPeriodSpanMultipleMonths,
  getMonthsInTimeRange,
} from 'libs/react-ui/src/util';

import { Container } from './Container';
import { Card } from './Card';
import { Legend } from './Legend';

import { TransactionAnalysisPreviousPeriodRadarChart } from './charts/TransactionAnalysisPreviousPeriodRadarChart';
import { TransactionAnalysisPeerGroupRadarChart } from './charts/TransactionAnalysisPeerGroupRadarChart';
import { TransactionSummaryWithdrawalsDollarsBulletChart } from './charts/TransactionSummaryWithdrawalsDollarsBulletChart';
import { TransactionSummaryWithdrawalsDollarAvgBulletChart } from './charts/TransactionSummaryWithdrawalsDollarAvgBulletChart';
import { TransactionSummaryDepositDollarsBulletChart } from './charts/TransactionSummaryDepositDollarsBulletChart';
import { TransactionSummarySalesDollarsBulletChart } from './charts/TransactionSummarySalesDollarsBulletChart';
import { TransactionSummaryDepositsDollarAvgBulletChart } from './charts/TransactionSummaryDepositsDollarAvgBulletChart';
import { TotalSalesAndDepositsAreaChart } from './charts/TotalSalesAndDepositsAreaChart';
import { api } from 'libs/react-ui/src/api';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { GcvContent } from 'libs/react-ui/src/lib/GcvContent/GcvContent';
import { AccountMonitoringContext } from '../account-monitoring-context';

interface Props {
  bank: Bank;
  review: DispensaryAccountReviewWithData;
}

const formatPositiveNegativeColor = (value: string) => {
  const valueNumber = parseFloat(value);

  if (valueNumber < 0) {
    return <span style={{ color: 'red' }}>{value}</span>;
  } else {
    return <span style={{ color: '#00bc66' }}>{value}</span>;
  }
};

const ReviewAnalysis: React.FC<Props> = ({ bank, review }: Props) => {
  const apiClient = api();
  const localReview = cloneDeep(review);
  const [filteredReview, setFilteredReview] = useState(review);
  const [reviewOptions, setReviewOptions] = useState([]);

  const [peerGroups, setPeerGroups] = useState<{
    peer_groups: { [id: string]: { name: string; description: string } };
  }>();
  const [accountActivityData, setAccountActivityData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const context = useContext(AccountMonitoringContext);

  useEffect(() => {
    apiClient.accountMonitoring
      .getPeerGroups(bank.id, () => {})
      .then(r => {
        setPeerGroups(r);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (review) {
      const options = getMonthsInTimeRange(review, review.dispensary.iana_timezone);
      onMonthChange(options[0]);
      setReviewOptions(options);
      getAccountActivityData();
    }
  }, [review]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onMonthChange = month => {
    const newReviewDailyDistribution = {};

    Object.keys(review.data.daily_distribution)
      .filter(date => {
        return DateTime.fromFormat(date, 'yyyy-MM-dd').month === month.value;
      })
      .forEach(key => {
        newReviewDailyDistribution[key] = review.data.daily_distribution[key];
      });

    localReview.data.daily_distribution = newReviewDailyDistribution;

    setFilteredReview(localReview);
  };

  const getAccountActivityData = () => {
    const startDate = DateTime.fromISO(review.review_period_start_date, { zone: review.dispensary.iana_timezone })
      .minus({ years: 1 })
      .toFormat('yyyy-MM-dd');
    const endDate = DateTime.fromISO(review.review_period_end_date, { zone: review.dispensary.iana_timezone }).toFormat(
      'yyyy-MM-dd'
    );
    const numberOfDays = Math.round(
      DateTime.fromISO(review.review_period_end_date).diff(
        DateTime.fromISO(review.review_period_start_date, { zone: review.dispensary.iana_timezone }),
        'days'
      ).days
    );
    apiClient.accountMonitoring
      .getDispensaryAccountActivity(
        bank.id,
        review.dispensary_id,
        startDate,
        endDate,
        review.review_period_type,
        () => {},
        numberOfDays
      )
      .then(accountActivityData => {
        //because of reasons, custom date ranges come back in correct descending order. Others must be reversed
        const orderedData =
          review.review_period_type === ReviewPeriodType.Custom ? accountActivityData : accountActivityData.reverse();
        setAccountActivityData(orderedData);
      });
  };

  const coreData = review.data.aggregated_core_transaction_data;
  const salesData = review.data.aggregated_sales_data;

  function capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatName(unFormattedName: string) {
    const splitName = unFormattedName.split('_');

    const name: string[] = [];

    for (let x = 0; x < splitName.length; x++) {
      name.push(capitalize(splitName[x]));
    }

    return name.join(' ');
  }

  const transactionAnalysisData = [
    ...Object.keys(coreData.current_period.deposit).reduce((acc, cp) => {
      const currentPeriodDeposit = coreData.current_period.deposit[cp];
      const previousPeriodDeposit = coreData.previous_period.deposit[cp]
        ? coreData.previous_period.deposit[cp]
        : { a: 0 };
      const previousYearDeposit = coreData.previous_year.deposit[cp] ? coreData.previous_year.deposit[cp] : { a: 0 };

      if (!currentPeriodDeposit.a && !previousPeriodDeposit.a && !previousYearDeposit.a) {
        return acc;
      }

      return [
        ...acc,
        {
          label: formatName(cp) + ' In',
          isSummary: false,
          columns: [
            {
              name: 'Total',
              value: formatMoney(currentPeriodDeposit.a),
              delta: null,
            },
            {
              name: 'Change over last period',
              value: formatMoneyWithNegatives(currentPeriodDeposit.a - previousPeriodDeposit.a),
              delta: formatPositiveNegativeColor(
                formatChangePercentage(previousPeriodDeposit.a, currentPeriodDeposit.a)
              ),
            },
            {
              name: 'Change over last year',
              value: formatMoneyWithNegatives(currentPeriodDeposit.a - previousYearDeposit.a),
              delta: formatPositiveNegativeColor(formatChangePercentage(previousYearDeposit.a, currentPeriodDeposit.a)),
            },
            {
              name: 'Peer Group',
              value: `${formatOrdinalIndicator(
                coreData.peer_groups[context.peerGroupId]?.percentiles.avg_deposits[cp]
              )} percentile`,
              delta: null,
            },
          ],
        },
      ];
    }, []),
    {
      label: 'Deposits',
      isSummary: true,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.deposit_amount),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount - coreData.previous_period.deposit_amount
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(coreData.previous_period.deposit_amount, coreData.current_period.deposit_amount)
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.deposit_amount - coreData.previous_year.deposit_amount
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(coreData.previous_year.deposit_amount, coreData.current_period.deposit_amount)
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.avg_deposit_amount
          )} percentile`,
          delta: null,
        },
      ],
    },
    ...Object.keys(coreData.current_period.withdrawal).reduce((acc, cp) => {
      const currentPeriodWithdrawal = coreData.current_period.withdrawal[cp];
      const previousPeriodWithdrawal = coreData.previous_period.withdrawal[cp]
        ? coreData.previous_period.withdrawal[cp]
        : { a: 0 };
      const previousYearWithdrawal = coreData.previous_year.withdrawal[cp]
        ? coreData.previous_year.withdrawal[cp]
        : { a: 0 };

      if (!currentPeriodWithdrawal.a && !previousPeriodWithdrawal.a && !previousYearWithdrawal.a) {
        return acc;
      }

      return [
        ...acc,
        {
          label: formatName(cp) + ' Out',
          isSummary: false,
          columns: [
            {
              name: 'Total',
              value: formatMoney(currentPeriodWithdrawal.a),
              delta: null,
            },
            {
              name: 'Change over last period',
              value: formatMoneyWithNegatives(currentPeriodWithdrawal.a - previousPeriodWithdrawal.a),
              delta: formatPositiveNegativeColor(
                formatChangePercentage(previousPeriodWithdrawal.a, currentPeriodWithdrawal.a)
              ),
            },
            {
              name: 'Change over last year',
              value: formatMoneyWithNegatives(currentPeriodWithdrawal.a - previousYearWithdrawal.a),
              delta: formatPositiveNegativeColor(
                formatChangePercentage(previousYearWithdrawal.a, currentPeriodWithdrawal.a)
              ),
            },
            {
              name: 'Peer Group',
              value: `${formatOrdinalIndicator(
                coreData.peer_groups[context.peerGroupId]?.percentiles.avg_withdrawals[cp]
              )} percentile`,
              delta: null,
            },
          ],
        },
      ];
    }, []),
    {
      label: 'Withdrawals',
      isSummary: true,
      columns: [
        {
          name: 'Total',
          value: formatMoney(coreData.current_period.withdrawal_amount),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount - coreData.previous_period.withdrawal_amount
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(
              coreData.previous_period.withdrawal_amount,
              coreData.current_period.withdrawal_amount
            )
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(
            coreData.current_period.withdrawal_amount - coreData.previous_year.withdrawal_amount
          ),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(coreData.previous_year.withdrawal_amount, coreData.current_period.withdrawal_amount)
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            coreData.peer_groups[context.peerGroupId]?.percentiles.avg_withdrawal_amount
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Sales',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(salesData.current_period.total_sales),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(salesData.current_period.total_sales - salesData.previous_period.total_sales),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(salesData.previous_period.total_sales, salesData.current_period.total_sales)
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(salesData.current_period.total_sales - salesData.previous_year.total_sales),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(salesData.previous_year.total_sales, salesData.current_period.total_sales)
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            salesData.peer_groups[context.peerGroupId]?.percentiles.avg_total_sales
          )} percentile`,
          delta: null,
        },
      ],
    },
    {
      label: 'Variance',
      isSummary: false,
      columns: [
        {
          name: 'Total',
          value: formatMoney(salesData.current_period.variance),
          delta: null,
        },
        {
          name: 'Change over last period',
          value: formatMoneyWithNegatives(salesData.previous_period.variance),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(salesData.previous_period.variance, salesData.current_period.variance)
          ),
        },
        {
          name: 'Change over last year',
          value: formatMoneyWithNegatives(salesData.previous_year.variance),
          delta: formatPositiveNegativeColor(
            formatChangePercentage(salesData.previous_year.variance, salesData.current_period.variance)
          ),
        },
        {
          name: 'Peer Group',
          value: `${formatOrdinalIndicator(
            salesData.peer_groups[context.peerGroupId]?.percentiles.avg_variance
          )} percentile`,
          delta: null,
        },
      ],
    },
  ];

  const cell = field => <span title={field}>{field}</span>;

  const accountActivityColumns = [
    {
      name: 'Period',
      selector: 'time_label',
      sortable: true,
      left: true,
      cell: r => cell(r['time_label']),
    },
    {
      name: 'Deposits',
      selector: 'deposits',
      sortable: true,
      left: true,
      cell: r => cell(formatMoney(r['deposits'])),
    },
    {
      name: 'Sales',
      selector: 'sales',
      sortable: true,
      left: true,
      cell: r => cell(formatMoney(r['sales'])),
    },
    {
      name: 'Sales - Deposits',
      selector: 'salesMinusDeposits',
      sortable: true,
      left: true,
      cell: r => cell(formatMoneyWithNegatives(r['sales'] - r['deposits'])),
    },
    {
      name: 'Variance',
      selector: 'variance',
      sortable: true,
      left: true,
      format: r => formatPercentage(r['variance'], 100),
    },
    {
      name: 'Withdrawals',
      selector: 'withdrawals',
      sortable: true,
      left: true,
      cell: r => cell(formatMoney(r['withdrawals'])),
    },
  ];

  function colorPicker(type: string) {
    switch (type) {
      case 'ach':
        return '#F5A623';
      case 'ach_wire':
        return '#414288';
      case 'adjustment':
        return '#682d63';
      case 'bill_payments':
        return '#5FB49C';
      case 'card':
        return '#00A77E';
      case 'card_credit':
        return '#98DFAF';
      case 'card_debit':
        return '#DEEFB7';
      case 'cash':
        return '#3581B8';
      case 'check':
        return '#E65F5C';
      case 'crypto':
        return '#0F0326';
      case 'dividend':
        return '#B5D99C';
      case 'fee':
        return '#324376';
      case 'interest_dividend':
        return '#F5F7DC';
      case 'interest':
        return '#FFFF82';
      case 'monetary_instrument':
        return '#F19A3E';
      case 'payments':
        return '#E8FCCF';
      case 'p2p':
        return '#96E072';
      case 'physical':
        return '#78A9F5';
      case 'returned_items':
        return '#3DA35D';
      case 'nsf':
        return '#3E8914';
      case 'rtp':
        return '#134611';
      case 'tax':
        return '#211A1D';
      case 'transfer':
        return '#EB8B47';
      case 'wire':
        return '#BB6BD9';
      case 'other':
        return '#6320EE';
      default:
        return '#B3CDD1';
    }
  }

  return (
    <Container>
      <Container.Item>
        <Card>
          <Card.Header
            title={'Transaction Analysis'}
            actions={[
              <Legend
                inline
                items={[
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#00BC66" />
                      </svg>
                    ),
                    label: 'This Period',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#1270FC" />
                      </svg>
                    ),
                    label: 'Previous Period',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#F5A623" />
                      </svg>
                    ),
                    label: 'Peer Group',
                  },
                ]}
              />,
            ]}
          />
          <Card.Content>
            <Container>
              <Container.Item hCenter size={[6]}>
                <GcvContent type="l1" content="CURRENT vs PREVIOUS PERIOD" />

                <TransactionAnalysisPreviousPeriodRadarChart review={review} />
              </Container.Item>
              <Container.Item hCenter size={[6]}>
                <GcvContent type="l1" content="CURRENT vs PEER GROUP*" />

                <TransactionAnalysisPeerGroupRadarChart review={review} />
              </Container.Item>

              <Container.Item hCenter>
                <div style={{ fontSize: '12px', color: '#3A3C45', textAlign: 'center' }}>
                  <strong>*Current Peer Group:</strong>{' '}
                  {peerGroups && peerGroups.peer_groups && peerGroups.peer_groups[context.peerGroupId]?.name}
                  &nbsp;&nbsp;
                  <label
                    data-for="currentPeerGroupTooltip"
                    data-tip={
                      peerGroups && peerGroups.peer_groups && peerGroups.peer_groups[context.peerGroupId]?.description
                    }
                  >
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.95 2.75H6.05V3.85H4.95V2.75ZM4.95 4.95H6.05V8.25H4.95V4.95ZM5.5 0C2.464 0 0 2.464 0 5.5C0 8.536 2.464 11 5.5 11C8.536 11 11 8.536 11 5.5C11 2.464 8.536 0 5.5 0ZM5.5 9.9C3.0745 9.9 1.1 7.9255 1.1 5.5C1.1 3.0745 3.0745 1.1 5.5 1.1C7.9255 1.1 9.9 3.0745 9.9 5.5C9.9 7.9255 7.9255 9.9 5.5 9.9Z"
                        fill="#9B9EB1"
                      />
                    </svg>
                    <ReactTooltip id="currentPeerGroupTooltip" place="top" type="dark" effect="solid" delayShow={250} />
                  </label>
                </div>
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header
            title={'Transaction Summary'}
            actions={[
              <Legend
                inline
                items={[
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#00BC66" />
                      </svg>
                    ),
                    label: 'This Period',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#6896FB" />
                      </svg>
                    ),
                    label: 'Previous Period',
                  },
                  {
                    icon: (
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ borderRadius: '6px' }}
                      >
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#ffffff" stroke="#000000" />
                      </svg>
                    ),
                    label: 'Previous Year',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#3A3C45" />
                      </svg>
                    ),
                    label: 'Peer Group Avg (current period)',
                  },
                ]}
              />,
            ]}
          />
          <Card.Content>
            <Container>
              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Sales" />
                    <GcvContent type="l1" grey2 content="$000s" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummarySalesDollarsBulletChart review={review} />
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Deposits" />
                    <GcvContent type="l1" grey2 content="$000s" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummaryDepositDollarsBulletChart review={review} />
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Deposits Avg" />
                    <GcvContent type="l1" grey2 content="Dollar average" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummaryDepositsDollarAvgBulletChart review={review} />
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Withdrawals" />
                    <GcvContent type="l1" grey2 content="$000s" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummaryWithdrawalsDollarsBulletChart review={review} />
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item>
                <Container>
                  <Container.Item size={[3]}>
                    <GcvContent type="p1" content="Withdrawals Avg" />
                    <GcvContent type="l1" grey2 content="Dollar average" />
                  </Container.Item>
                  <Container.Item size={[9]}>
                    <TransactionSummaryWithdrawalsDollarAvgBulletChart review={review} />
                  </Container.Item>
                </Container>
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header
            title={'Total Sales and Deposits'}
            actions={[
              doesReviewPeriodSpanMultipleMonths(review, review.dispensary.iana_timezone) && reviewOptions.length ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <GcvInputSelectNew
                    label="Month"
                    options={reviewOptions}
                    onChange={onMonthChange}
                    dropWidth={'150px'}
                    labelWidth={'100px'}
                    dropCharLength={20}
                  />
                </div>
              ) : (
                undefined
              ),
              <div style={{ textAlign: 'right', width: '100%', paddingTop: '10px' }}>
                <Legend
                  inline
                  items={[
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#CCF2E0" />
                        </svg>
                      ),
                      label: 'Sales',
                    },
                  ].concat(
                    Object.keys(filteredReview.data.daily_distribution).reduce((acc, curr) => {
                      const dailyDistribtion = filteredReview.data.daily_distribution;
                      const categories: { icon: React.ReactNode; label: string }[] = [...acc];

                      Object.keys(dailyDistribtion[curr].deposits).forEach(category => {
                        if (!categories.find(c => c.label === formatName(category))) {
                          categories.push({
                            icon: (
                              <svg
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="3.5" cy="3.5" r="3.5" fill={colorPicker(category)} />
                              </svg>
                            ),
                            label: formatName(category),
                          });
                        }
                      });

                      return categories;
                    }, [])
                  )}
                />
              </div>,
            ]}
          />
          <Card.Content>{!isLoading && <TotalSalesAndDepositsAreaChart review={filteredReview} />}</Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header title={'Transaction Analysis'} />
          <Card.Content>
            <Container gutterBottom>
              <Container.Item hCenter size={[4]}>
                <GcvContent type="l1" content="DEPOSITS" />
                <GcvContent type="p1" content={formatMoney(coreData.current_period.deposit_amount)} />
              </Container.Item>
              <Container.Item hCenter size={[4]}>
                <GcvContent type="l1" content="SALES" />
                <GcvContent type="p1" content={formatMoney(salesData.current_period.total_sales)} />
              </Container.Item>
              <Container.Item hCenter size={[4]}>
                <GcvContent type="l1" content="WITHDRAWALS" />
                <GcvContent type="p1" content={formatMoney(coreData.current_period.withdrawal_amount)} />
              </Container.Item>
            </Container>

            <Container>
              <Container.Item>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#f2f4fa',
                    border: '1px solid #ebeef7',
                    boxSizing: 'border-box',
                    borderRadius: '10px 10px 0px 0px',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: 'rgba(0,0,0,0.87)',
                    height: '40px',
                    margin: '-8px',
                  }}
                >
                  <div style={{ flex: 1, width: `${100 / (transactionAnalysisData[0].columns.length + 1)}%` }}></div>
                  {transactionAnalysisData[0].columns.map((c, i) => {
                    return (
                      <div
                        key={c.name + i}
                        style={{ flex: 1, width: `${100 / (transactionAnalysisData[0].columns.length + 1)}%` }}
                      >
                        {c.name}
                      </div>
                    );
                  })}
                </div>
              </Container.Item>

              <div
                style={{
                  borderRight: '1px solid #EBEEF7',
                  borderLeft: '1px solid #EBEEF7',
                  borderBottom: '1px solid #EBEEF7',
                  borderRadius: '0 0 10px 10px',
                  width: '100%',
                  padding: '0 0.5rem',
                }}
              >
                {transactionAnalysisData.map((d, i) => {
                  return (
                    <Container.Item
                      sparse
                      borderBottom={i < transactionAnalysisData.length - 1}
                      bold={d.isSummary}
                      key={d.label + i}
                    >
                      <div style={{ display: 'flex', fontSize: '13px' }}>
                        <div style={{ flex: 1, width: `${100 / (transactionAnalysisData[0].columns.length + 1)}%` }}>
                          {d.label}
                        </div>
                        {d.columns.map((c, i) => {
                          return (
                            <div
                              key={c.name + i}
                              style={{ flex: 1, width: `${100 / (transactionAnalysisData[0].columns.length + 1)}%` }}
                            >
                              {c.value}
                              {c.delta ? <>&nbsp;&nbsp;&nbsp;{c.delta}</> : ''}
                            </div>
                          );
                        })}
                      </div>
                    </Container.Item>
                  );
                })}
              </div>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header title={'Historic Account Activity'} />
          <Card.Content>
            <GcvDataTable
              data={accountActivityData ? accountActivityData : []}
              columns={accountActivityColumns}
              defaultSortField={'name'}
              defaultSortAsc={true}
              keyField="id"
              subHeaderAlign="left"
              paginationPerPage={10}
              noDataComponent={<GcvLoading></GcvLoading>}
              pointerOnHover={false}
              highlightOnHover={false}
            />
          </Card.Content>
        </Card>
      </Container.Item>
    </Container>
  );
};

export default ReviewAnalysis;
