import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gcv-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  isBank: boolean;

  constructor(private router: Router) {
    this.isBank = this.router.url.search('bank') !== 1;
  }

  handleBack() {
    const org = this.isBank ? 'dispensary' : 'bank';
    this.router.navigate([`/secure/${org}/dashboard/${org === 'dispensary' ? 'sales' : 'overview'}`]);
  }
}
