<div class="amplify-container" data-cy="require-new-password.component">
  <div class="amplify-form-container">
    <img src="../../assets/gcv_logo_alt.png" class="logo" width="150px" />
    <div class="amplify-form-body">
      <div class="amplify-form-header">Please choose a secure password</div>
      <div class="amplify-form-row">
        <mat-form-field class="example-full-width" [formGroup]="changePasswordData">
          <input
            matInput
            type="password"
            placeholder="New Password"
            formControlName="newPass"
            autocomplete="new-password"
            data-cy="require-new-password.component.newPassword"
          />
          <mat-error *ngIf="changePasswordData.controls.newPass.errors?.required">
            A new password is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="changePasswordData.controls.newPass.errors?.minlength">
            Your password must be at least 10 characters
          </mat-error>
        </mat-form-field>
      </div>
      <div class="amplify-form-row">
        <mat-form-field class="example-full-width" [formGroup]="changePasswordData">
          <input
            matInput
            type="password"
            placeholder="Confirm New Password"
            formControlName="newPassCheck"
            autocomplete="new-password"
            data-cy="require-new-password.component.confirmPassword"
          />
          <mat-error *ngIf="changePasswordData.controls.newPassCheck.errors?.equalTo">
            Your new password does not match
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <password-strength-meter
          *ngIf="changePasswordData.controls.newPass.value.length > 0"
          [password]="changePasswordData.controls.newPass.value"
        ></password-strength-meter>
        <div class="requirements">
          <span>
            Requirements:
          </span>
          <ul>
            <li [ngClass]="requirements.length && 'fulfilled'">10 or more characters</li>
            <li [ngClass]="requirements.lower && 'fulfilled'">A lowercase letter</li>
            <li [ngClass]="requirements.number && 'fulfilled'">A number</li>
            <li [ngClass]="requirements.upper && 'fulfilled'">An uppercase letter</li>
            <li [ngClass]="requirements.special && 'fulfilled'">A special character</li>
          </ul>
        </div>
      </div>
      <div class="amplify-form-actions">
        <div class="amplify-form-cell-right">
          <gcv-button
            [disabled]="!isValid()"
            class="amplify-form-button-gcv"
            (clickEvent)="onSubmit()"
            buttonText="Login"
            class="fullWidth"
            type="primary full-width large"
            data-cy="require-new-password.component.submit"
          ></gcv-button>
        </div>
      </div>
    </div>
  </div>
</div>
