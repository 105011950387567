import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { DispensaryFacade, UserFacade } from '@user-interface/gcv-state';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Dispensary, User } from '@gcv/shared';
import { isInitialDispensaryUserAndStillNeedsToSignTermsOfService } from './terms-of-service.util';
import { getDispensaryDashboardOverviewCommand } from '../app.routes.names';

@Injectable()
export class CanActivateTermsOfService implements CanActivate, CanActivateChild {
  constructor(private userFacade: UserFacade, private dispensaryFacade: DispensaryFacade, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userFacade.selectCurrentUser().pipe(
      map((user: User) => {
        // need to make sure we have a user available or this will break
        if (!user) {
          this.router.navigate(['/']);
          return false;
        }
        return user;
      }),
      take(1),
      tap((user: User) => {
        this.dispensaryFacade.getDispensarybyId(user.companies[0].id);
      }),
      mergeMap((user: User) =>
        this.dispensaryFacade.selectDispensaryById(user.companies[0].id).pipe(
          filter((dispensary: Dispensary) => dispensary !== undefined),
          take(1),
          map((dispensary: Dispensary) => {
            const routeToTos = user && isInitialDispensaryUserAndStillNeedsToSignTermsOfService(user);

            return { routeToTos, dispensaryName: dispensary.name };
          })
        )
      ),
      map(mappedObject => {
        let canActivate: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
        if (mappedObject.routeToTos) {
          if (!this.router.getCurrentNavigation().extras.state) {
            this.router.getCurrentNavigation().extras.state = {};
          }
          this.router.getCurrentNavigation().extras.state.orgName = mappedObject.dispensaryName;
          canActivate = true;
        } else {
          canActivate = this.router.parseUrl(getDispensaryDashboardOverviewCommand());
        }
        return canActivate;
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    throw this.canActivate(childRoute, state);
  }
}
