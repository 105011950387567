import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatISODate',
})
export class FormatISODatePipe implements PipeTransform {
  constructor() {}
  transform(date: string): string {
    const dt = DateTime.fromISO(date, { zone: 'utc' });

    if (dt.isValid) {
      return dt.toLocaleString();
    }

    console.warn(`Invalid date supplied to FormatISODate: ${date}`);
    return '';
  }
}
