import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { setArchivalPropertiesOnUser, User } from '@gcv/shared';
import { openConfirmDialogForArchivingUser } from '../../dialog-helper';
import { DateTime } from 'luxon';

@Component({
  selector: 'gcv-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditUserComponent implements OnInit {
  message = '';
  userDetails: FormGroup;
  user: User;
  orgType: 'dispensary' | 'bank';
  roles = [];

  constructor(
    private matDialogRef: MatDialogRef<EditUserComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.user && data.orgType) {
      this.orgType = data.orgType;
      this.user = data.user;
    } else {
      this.close({ isSaving: false });
    }
  }

  ngOnInit() {
    this.userDetails = new FormGroup({
      firstName: new FormControl(this.user.firstName ? this.user.firstName : '', Validators.required),
      lastName: new FormControl(this.user.lastName ? this.user.lastName : '', Validators.required),
      email: new FormControl(this.user.email ? this.user.email : '', Validators.required),
    });
  }

  saveUser() {
    this.close({ isSaving: true, userDetails: this.userDetails.getRawValue() });
  }

  close(data) {
    this.matDialogRef.close({ data });
  }

  archiveUser() {
    const dialog = openConfirmDialogForArchivingUser(this.matDialog);
    dialog.afterClosed().subscribe(data => {
      if (data.confirmed) {
        this.close({
          isSaving: true,
          userDetails: {
            ...this.userDetails.getRawValue(),
            invitation_status: 'archived',
            archive_date: DateTime.utc().toISO(),
          },
        });
      } else {
        this.close({ isSaving: false });
      }
    });
  }
}
