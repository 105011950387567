import * as React from 'react';
import { GcvCard } from '../../../styles/GcvCard';
import { GcvButton } from '../../../lib';
import { getComplianceScoreColor } from '../columns';

import { InnerWrapper, ContentHeader, Logo, Heading, ContentWrapper, ReviewText } from './styles';

interface Props {
  name: string;
  complianceScore?: number;
  logo: string;
  onButtonClick: () => any;
  isApproved?: boolean;
  isAwaitingReview?: boolean;
}

export const AccountCard = (props: Props) => {
  const { name, complianceScore, logo, isApproved, isAwaitingReview } = props;

  const onButtonClick = () => {
    props.onButtonClick();
  };

  const renderBodyContent = () => {
    if (isAwaitingReview) {
      return (
        <ReviewText data-cy="account-card-body-text">
          Please review this account for all required information and documentation.
        </ReviewText>
      );
    } else if (!isApproved) {
      return (
        <ReviewText data-cy="account-card-body-text">
          This account’s application is currently in progress and not ready for review.
        </ReviewText>
      );
    }

    return (
      <>
        {complianceScore ? (
          <div data-cy="compliance-score">
            Compliance Score:
            <span style={{ color: getComplianceScoreColor(complianceScore) }}>{` ${complianceScore}%`}</span>
          </div>
        ) : (
          'No Score Available'
        )}
      </>
    );
  };

  return (
    <GcvCard data-cy="account-card">
      <InnerWrapper>
        <ContentHeader data-cy="account-card-header">
          <Logo src={logo} alt="Logo" data-cy="account-card-header-logo"></Logo>
          <Heading data-cy="account-card-header-name">{name}</Heading>
        </ContentHeader>
        <ContentWrapper>{renderBodyContent()}</ContentWrapper>

        <GcvButton
          pill
          {...{ primary: isAwaitingReview, secondary: !isAwaitingReview }}
          style={{ marginTop: '1rem' }}
          onClick={onButtonClick}
          data-cy="button.component.review"
        >
          {isApproved ? 'View More' : isAwaitingReview ? 'Review' : 'View'}
        </GcvButton>
      </InnerWrapper>
    </GcvCard>
  );
};
