<button
  (click)="handleClick($event)"
  [attr.data-cy]="'button.component.' + getCypressName()"
  [disabled]="disabled"
  [ngClass]="calculateClasses()"
  [ngStyle]="styles"
  class="btn"
>
  <div class="buttonInner">
    <svg
      *ngIf="icon === 'back'"
      class="icon backIcon"
      height="13.017"
      viewBox="0 0 8 13.017"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.309,2.5A1.055,1.055,0,0,1,1.8,1.009L8,7.209l-6.2,6.2A1.055,1.055,0,0,1,.309,11.917L5.017,7.209Z"
        transform="translate(8 13.717) rotate(180)"
      />
    </svg>
    <svg *ngIf="icon === 'plus'" class="icon plusIcon" xmlns="http://www.w3.org/2000/svg" width="12.9" height="12.9">
      <path d="M6.4 12.9c-.6 0-1.1-.5-1.1-1.1V1.1C5.4.5 5.9 0 6.4 0 7 0 7.5.5 7.5 1.1v10.8c0 .5-.5 1-1.1 1z" />
      <path d="M0 6.4c0-.6.5-1.1 1.1-1.1h10.8c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1H1.1C.5 7.5 0 7 0 6.4z" />
    </svg>

    <svg
      *ngIf="icon === 'spinner'"
      version="1.1"
      id="loader-1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="20px"
      viewBox="0 0 80 50"
      style="enable-background:new 0 0 50 50;"
      xml:space="preserve"
    >
      <path
        fill="#888"
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="1.3s"
          repeatCount="indefinite"
        ></animateTransform>
      </path>
    </svg>

    {{ buttonText }}
    <svg
      *ngIf="icon === 'next'"
      class="icon nextIcon"
      height="13.017"
      viewBox="0 0 8 13.017"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.309,2.5A1.055,1.055,0,0,1,1.8,1.009L8,7.209l-6.2,6.2A1.055,1.055,0,0,1,.309,11.917L5.017,7.209Z"
        transform="translate(0 -0.7)"
      />
    </svg>
  </div>
</button>
