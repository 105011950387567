import React, { useContext } from 'react';

import { DispensaryAccountReviewWithData, PeerGroupPeriodSummary, PeriodSummary } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { VerticalBarConfig } from './chart-options';
import { tooltip } from './Tooltip';
import { formatChangePercentage, formatMoney, formatPercentage } from 'libs/react-ui/src/util';
import { AccountMonitoringContext } from '../../account-monitoring-context';

interface Props {
  review: DispensaryAccountReviewWithData;
  isWholesale: boolean;
}

export const PaymentBreakdownBarChart: React.FC<Props> = props => {
  const context = useContext(AccountMonitoringContext);
  return (
    <GcvChart type="verticalBar" config={chartDataAdapter(props.review, context.peerGroupId, props.isWholesale)} />
  );
};

export const chartDataAdapter = (
  review: DispensaryAccountReviewWithData,
  peerGroupId: string,
  isWholesale: boolean
) => {
  return dataAdapter(review.data.aggregated_sales_data, peerGroupId, isWholesale);
};

export const dataAdapter = (
  data: {
    current_period: PeriodSummary;
    previous_period: PeriodSummary;
    previous_year: PeriodSummary;
    peer_groups: {
      [id: string]: PeerGroupPeriodSummary;
    };
  },
  peerGroupId: string,
  isWholesale: boolean
): VerticalBarConfig => {
  const reportData = [
    {
      label: 'Cash',
      value: data.current_period.total_cash / 100,
      color: '#00BC66',
      tooltext: tooltip('Cash', [
        {
          value: `${formatMoney(data.current_period.total_cash)} (${formatPercentage(
            data.current_period.total_cash,
            data.current_period.total_sales
          )})`,
          label: 'Sales',
        },
        {
          value: `${data.current_period.number_of_cash_transactions}`,
          label: 'Transactions',
        },
        {
          value: formatChangePercentage(data.previous_period.total_cash, data.current_period.total_cash),
          label: 'Change Over Last Period',
        },
        {
          value: formatMoney(data.peer_groups[peerGroupId]?.avg_cash_amount),
          label: 'Peer Group Avg',
        },
      ]),
    },
    {
      label: 'Debit',
      value: data.current_period.total_debit / 100,
      color: '#00BC66',
      tooltext: tooltip('Debit', [
        {
          value: `${formatMoney(data.current_period.total_debit)} (${formatPercentage(
            data.current_period.total_debit,
            data.current_period.total_sales
          )})`,
          label: 'Sales',
        },
        {
          value: `${data.current_period.number_of_debit_transactions}`,
          label: 'Transactions',
        },
        {
          value: formatChangePercentage(data.previous_period.total_debit, data.current_period.total_debit),
          label: 'Change Over Last Period',
        },
        {
          value: formatMoney(data.peer_groups[peerGroupId]?.avg_debit_amount),
          label: 'Peer Group Avg',
        },
      ]),
    },
    {
      label: 'Check',
      value: data.current_period.total_check / 100,
      color: '#00BC66',
      tooltext: tooltip('Check', [
        {
          value: `${formatMoney(data.current_period.total_check)} (${formatPercentage(
            data.current_period.total_check,
            data.current_period.total_sales
          )})`,
          label: 'Sales',
        },
        {
          value: `${data.current_period.number_of_check_transactions}`,
          label: 'Transactions',
        },
        {
          value: formatChangePercentage(data.previous_period.total_check, data.current_period.total_check),
          label: 'Change Over Last Period',
        },
        {
          value: formatMoney(data.peer_groups[peerGroupId]?.avg_check_amount),
          label: 'Peer Group Avg',
        },
      ]),
    },
    {
      label: 'Credit',
      value: data.current_period.total_credit / 100,
      color: '#00BC66',
      tooltext: tooltip('Credit', [
        {
          value: `${formatMoney(data.current_period.total_credit)} (${formatPercentage(
            data.current_period.total_credit,
            data.current_period.total_sales
          )})`,
          label: 'Sales',
        },
        {
          value: `${data.current_period.number_of_credit_transactions}`,
          label: 'Transactions',
        },
        {
          value: formatChangePercentage(data.previous_period.total_credit, data.current_period.total_credit),
          label: 'Change Over Last Period',
        },
        {
          value: formatMoney(data.peer_groups[peerGroupId]?.avg_credit_amount),
          label: 'Peer Group Avg',
        },
      ]),
    },
  ];

  if (isWholesale) {
    reportData.push({
      label: 'ACH',
      value: data.current_period.total_ach / 100,
      color: '#00BC66',
      tooltext: tooltip('ACH', [
        {
          value: `${formatMoney(data.current_period.total_ach)} (${formatPercentage(
            data.current_period.total_ach,
            data.current_period.total_sales
          )})`,
          label: 'Sales',
        },
        {
          value: `${data.current_period.number_of_ach_transactions}`,
          label: 'Transactions',
        },
        {
          value: formatChangePercentage(data.previous_period.total_ach, data.current_period.total_ach),
          label: 'Change Over Last Period',
        },
        {
          value: formatMoney(data.peer_groups[peerGroupId]?.avg_ach_amount),
          label: 'Peer Group Avg',
        },
      ]),
    });

    reportData.push({
      label: 'Wire',
      value: data.current_period.total_wire / 100,
      color: '#00BC66',
      tooltext: tooltip('Wire', [
        {
          value: `${formatMoney(data.current_period.total_wire)} (${formatPercentage(
            data.current_period.total_wire,
            data.current_period.total_sales
          )})`,
          label: 'Sales',
        },
        {
          value: `${data.current_period.number_of_wire_transactions}`,
          label: 'Transactions',
        },
        {
          value: formatChangePercentage(data.previous_period.total_wire, data.current_period.total_wire),
          label: 'Change Over Last Period',
        },
        {
          value: formatMoney(data.peer_groups[peerGroupId]?.avg_wire_amount),
          label: 'Peer Group Avg',
        },
      ]),
    });
  }

  return {
    data: reportData,
  };
};
