import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { ValueType } from 'react-select';
import { Dispensary, IANATimezones } from '@gcv/shared';
import { GcvCheckbox } from '../../../lib/GcvCheckbox/GcvCheckbox';
import { SearchIcon } from '../../../icons/SearchIcon';
import { GcvButton } from '../../../lib/GcvButton/GcvButton';
import { GcvTimePeriodDropdown } from '../../../lib/GcvTimePeriodDropdown/GcvTimePeriodDropdown';
import calculateDateRange from '../../../util/calculateDateRange';
import {
  ModalStyleOverrides,
  customModalStyles,
  ExportActivityModalBody,
  InputContainer,
  RadioButtons,
  RadioGroup,
  SearchInput,
  InputIconWrapper,
  SearchInputContainer,
  AccountsContainer,
  AccountItem,
  AccountsFooter,
  ButtonContainer,
} from '../styles';
import { ActivityType } from '..';

interface Props {
  active: boolean;
  toggleModal: () => void;
  onSubmit: (payload: Payload) => void;
  accounts: Dispensary[];
}

interface Payload {
  dispensaryIds: string[];
  startDate: string;
  endDate: string;
  activityType?: ActivityType;
}

export const ExportActivityModal = (props: Props) => {
  const [timePeriod, setTimePeriod] = useState(null);
  const [activityType, setActivityType] = useState<ActivityType>('sales');
  const [filteredAccounts, setFilteredAccounts] = useState<Dispensary[]>(props.accounts);
  const [query, setQuery] = useState('');
  const [selectedAccounts, setSelectedAccounts] = useState({} as { [accountId: string]: string | Dispensary });
  const [isValid, setIsValid] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);

  useEffect(() => {
    setFilteredAccounts(props.accounts);
  }, [props.accounts]);

  useEffect(() => {
    Modal.setAppElement('#modal-root');
  }, []);

  useEffect(() => {
    if (!props.active) {
      // tslint:disable-next-line: no-use-before-declare
      resetFormState();
    }
  }, [props.active]);

  useEffect(() => {
    if (Object.keys(selectedAccounts).length > 0 && timePeriod) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [Object.keys(selectedAccounts).length, timePeriod]);

  useEffect(() => {
    if (query.length > 0) {
      const filteredByQuery = props.accounts.filter(account =>
        account.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredAccounts(filteredByQuery);
    } else {
      setFilteredAccounts(props.accounts);
    }
  }, [query, props.accounts.length]);

  const resetFormState = () => {
    setQuery('');
    setActivityType('sales');
    setSelectedAccounts({});
    setTimePeriod(null);
    setExportInProgress(false);
  };

  const onTimePeriodSelect = (value: any) => {
    setTimePeriod(value);
  };

  const onActivityTypeSelect: React.FormEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;

    if (value === 'sales' || value === 'deposits' || value === 'daily-summaries') {
      setActivityType(value);
    }
  };

  const onSearchInput: React.FormEventHandler<HTMLInputElement> = e => {
    setQuery(e.currentTarget.value);
  };

  const toggleAccount = (account: Dispensary) => {
    return () => {
      if (selectedAccounts.hasOwnProperty(account.id)) {
        const { [account.id]: toDelete, ...remainingAccounts } = selectedAccounts;

        setSelectedAccounts(remainingAccounts);
      } else {
        setSelectedAccounts({ ...selectedAccounts, [account.id]: account });
      }
    };
  };

  const toggleSelectAll = () => {
    if (Object.keys(selectedAccounts).length === props.accounts.length) {
      setSelectedAccounts({});
    } else {
      const allAccountsMap = props.accounts.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      setSelectedAccounts(allAccountsMap);
    }
  };

  const renderAccounts = () => {
    return filteredAccounts
      .sort((accountA, accountB) => (accountA.name > accountB.name ? 1 : -1))
      .map(account => {
        const isSelected = selectedAccounts.hasOwnProperty(account.id);
        return (
          <AccountItem key={account.id} isSelected={isSelected}>
            <label>
              {account.name}
              <GcvCheckbox checked={isSelected} onChange={toggleAccount(account)} />
            </label>
          </AccountItem>
        );
      });
  };

  const createPayload = (): Payload => {
    const { start, end } = calculateDateRange(timePeriod.value, IANATimezones.America_NewYork); //this component isn't used anymore, using dummy timezone
    return {
      dispensaryIds: Object.keys(selectedAccounts),
      startDate: start,
      endDate: end,
      activityType,
    };
  };

  // throttle to prevent double click generating multiple reports
  const handleSubmit = () => {
    const payload = createPayload();
    props.onSubmit(payload);
    setExportInProgress(true);
  };

  return (
    <>
      <ModalStyleOverrides />
      <Modal style={customModalStyles} isOpen={props.active} onRequestClose={props.toggleModal}>
        <ExportActivityModalBody>
          <h2>Export Activity</h2>
          <h5>Please select the activity you'd like to export</h5>
          <InputContainer>
            <div style={{ width: '250px' }}>
              <GcvTimePeriodDropdown emitData={onTimePeriodSelect} />
            </div>

            <RadioButtons>
              <RadioGroup>
                <input
                  id="sales"
                  type="radio"
                  value="sales"
                  onChange={onActivityTypeSelect}
                  checked={activityType === 'sales'}
                />
                <label htmlFor="sales">Sales</label>
              </RadioGroup>
              <RadioGroup>
                <input
                  id="deposits"
                  type="radio"
                  value="deposits"
                  onChange={onActivityTypeSelect}
                  checked={activityType === 'deposits'}
                />
                <label htmlFor="deposits">Deposits</label>
              </RadioGroup>
              <RadioGroup>
                <input
                  id="daily-summaries"
                  type="radio"
                  value="daily-summaries"
                  onChange={onActivityTypeSelect}
                  checked={activityType === 'daily-summaries'}
                />
                <label htmlFor="daily-summaries">Daily Summaries</label>
              </RadioGroup>
            </RadioButtons>
          </InputContainer>

          <SearchInputContainer>
            <SearchInput
              className="floating-input"
              onChange={onSearchInput}
              value={query}
              type="text"
              placeholder="Search Accounts"
              label="Search Accounts"
              id="search"
            ></SearchInput>

            <InputIconWrapper>
              <SearchIcon />
            </InputIconWrapper>
          </SearchInputContainer>

          <AccountsContainer>{renderAccounts()}</AccountsContainer>

          <AccountsFooter>
            <span className="accounts-selected">{Object.keys(selectedAccounts).length} accounts selected</span>{' '}
            <span onClick={toggleSelectAll} className="select-all">
              {Object.keys(selectedAccounts).length === props.accounts.length ? 'Deselect All' : 'Select All'}
            </span>
          </AccountsFooter>

          <ButtonContainer>
            <GcvButton tertiary onClick={props.toggleModal}>
              Cancel
            </GcvButton>
            <GcvButton primary disabled={!isValid || exportInProgress} onClick={handleSubmit}>
              {exportInProgress ? 'Submitting...' : 'Export'}
            </GcvButton>
          </ButtonContainer>
        </ExportActivityModalBody>
      </Modal>
    </>
  );
};
