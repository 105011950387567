import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { Container, BadgeText } from './styles';

interface Props {
  score?: number;
}

export const ComplianceScoreBadge = (props: Props) => {
  const { score } = props;

  const setBadgeColor = () => {
    if (score === undefined || score >= 90) {
      return 'green';
    } else if (score >= 80 && score < 90) {
      return 'yellow';
    }
    return 'red';
  };

  const setTooltipText = () => {
    const noScoreText = 'This business has not run any sales through Green Check yet';
    const scoreText = `This business has ${score}% verified sales since using Green Check`;

    return score === undefined ? noScoreText : scoreText;
  };

  return (
    <Container color={setBadgeColor()} data-tip={setTooltipText()} data-testid="compliance-score-container">
      <BadgeText>{score !== undefined ? `${score}%` : '--'}</BadgeText>
      <BadgeText score>Score</BadgeText>
      <ReactTooltip place="top" type="dark" effect="solid" />
    </Container>
  );
};
