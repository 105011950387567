import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { Dispensary, DueDiligenceStatus, posDisplayNames } from '@gcv/shared';
import {
  BodySubContainer,
  BodySubTitle,
  BodySubContent,
  HoursContainer,
  Day,
  Time,
  HoursContainerLast,
} from './styles';
import { GcvCard, GcvButton } from '../../../lib';
import { formatMissingDash } from '../../../util';
import { SectionTypes } from '../Account-Review-Overview.page';
import { Header, ReviewText, Title, VerticalCenter } from '../styles';
import { BackButtonIcon } from 'libs/react-ui/src/icons/BackButtonIcon';

interface Props {
  dispensary?: Dispensary;
  canUserApproveAccounts: boolean;
  onSave: (type: SectionTypes) => void;
  onBack: () => void;
  isActiveAccount?: boolean;
}

export const AccountOperational = ({ dispensary, canUserApproveAccounts, onSave, onBack, isActiveAccount }: Props) => {
  const [businessHours, setBusinessHours] = useState(dispensary.businessHours);
  const isAwaitingReview = dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_AWAITING_REVIEW;
  const isAlreadyReviewed = dispensary.due_diligence.operational_details_section_bank_review_complete;

  const calculateBusinessHours = (day: string): string => {
    if (!businessHours) {
      return '--';
    }

    if (!businessHours[day] || !businessHours[day].isOpen) {
      return 'Closed';
    }

    const getHours = (type: 'openHour' | 'closeHour') => {
      const time = businessHours[day][type];
      const hour = parseInt(time.split(':')[0]);

      if (hour < 12) {
        return `${time} A.M.`;
      } else if (hour === 12) {
        return `${time} P.M.`;
      } else {
        return `${hour - 12}:${time.split(':')[1]} P.M.`;
      }
    };

    return [getHours('openHour'), getHours('closeHour')].join(' - ');
  };
  return (
    <>
      {!isActiveAccount && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Header style={{ justifyContent: 'start' }}>
              <BackButtonIcon onClick={onBack} style={{ cursor: 'pointer' }} />
              <VerticalCenter style={{ marginLeft: '1rem' }}>
                <Title>Operational Details</Title>
              </VerticalCenter>
            </Header>
            <ReviewText>This information helps you understand the day-to-day operations of this business.</ReviewText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontFamily: 'Lato, Helvetica, Arial, san-serif' }}>
              You are reviewing: <strong>{dispensary.name}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <GcvCard data-cy="employees-card">
            <GcvCard.Header title={<strong>Employees</strong>} data-cy="employees-title" />
            <GcvCard.Body>
              <BodySubContainer>
                <BodySubTitle>Full-Time Employees</BodySubTitle>
                <BodySubContent data-cy="employees-ft">{formatMissingDash(dispensary.ftEmployees)}</BodySubContent>
              </BodySubContainer>
              <BodySubContainer>
                <BodySubTitle>Part-Time Employees</BodySubTitle>
                <BodySubContent data-cy="employees-pt">{formatMissingDash(dispensary.ptEmployees)}</BodySubContent>
              </BodySubContainer>
            </GcvCard.Body>
          </GcvCard>
        </Grid>

        <Grid item xs={4}>
          <GcvCard data-cy="hours-card">
            <GcvCard.Header title={<strong>Hours of Operation</strong>} data-cy="hours-title" />
            <GcvCard.Body>
              <HoursContainer data-cy="hours-monday">
                <Day data-cy="day-text">Monday</Day>
                <Time data-cy="time-text">{calculateBusinessHours('monday')}</Time>
              </HoursContainer>
              <HoursContainer data-cy="hours-tuesday">
                <Day data-cy="day-text">Tuesday</Day>
                <Time data-cy="time-text">{calculateBusinessHours('tuesday')}</Time>
              </HoursContainer>
              <HoursContainer data-cy="hours-wednesday">
                <Day data-cy="day-text">Wednesday</Day>
                <Time data-cy="time-text">{calculateBusinessHours('wednesday')}</Time>
              </HoursContainer>
              <HoursContainer data-cy="hours-thursday">
                <Day data-cy="day-text">Thursday</Day>
                <Time data-cy="time-text">{calculateBusinessHours('thursday')}</Time>
              </HoursContainer>
              <HoursContainer data-cy="hours-friday">
                <Day data-cy="day-text">Friday</Day>
                <Time data-cy="time-text">{calculateBusinessHours('friday')}</Time>
              </HoursContainer>
              <HoursContainer data-cy="hours-saturday">
                <Day data-cy="day-text">Saturday</Day>
                <Time data-cy="time-text">{calculateBusinessHours('saturday')}</Time>
              </HoursContainer>
              <HoursContainerLast data-cy="hours-sunday">
                <Day data-cy="day-text">Sunday</Day>
                <Time data-cy="time-text">{calculateBusinessHours('sunday')}</Time>
              </HoursContainerLast>
            </GcvCard.Body>
          </GcvCard>
        </Grid>

        <Grid item xs={4}>
          <GcvCard data-cy="financial-card">
            <GcvCard.Header title={<strong>Financial Information</strong>} data-cy="financial-title" />
            <GcvCard.Body>
              <BodySubContainer>
                <BodySubTitle>Estimated Monthly Sales</BodySubTitle>
                <BodySubContent data-cy="financial-monthly-sales">
                  {formatMissingDash(dispensary.monthlySales)}
                </BodySubContent>
              </BodySubContainer>
              <BodySubContainer>
                <BodySubTitle>Estimated Monthly Customers</BodySubTitle>
                <BodySubContent data-cy="financial-monthly-customers">
                  {formatMissingDash(dispensary.monthlyCustomers)}
                </BodySubContent>
              </BodySubContainer>
              <BodySubContainer>
                <BodySubTitle>Sale/Invoice Tracking System</BodySubTitle>
                <BodySubContent data-cy="financial-pos">{formatMissingDash(dispensary.pos_name)}</BodySubContent>
              </BodySubContainer>
              <BodySubContainer>
                <BodySubTitle>Source of Sales</BodySubTitle>
                <BodySubContent data-cy="financial-source">
                  {dispensary && dispensary.posConfig
                    ? formatMissingDash(posDisplayNames[dispensary.posConfig.posName])
                    : '--'}
                </BodySubContent>
              </BodySubContainer>
            </GcvCard.Body>
          </GcvCard>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          {isAwaitingReview && !isAlreadyReviewed ? (
            <GcvButton
              primary={true}
              disabled={!canUserApproveAccounts}
              onClick={() => onSave('operational')}
              data-cy="button.component.save"
            >
              Save
            </GcvButton>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
