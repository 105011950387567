import styled from 'styled-components';

export const GcvCard = styled.div`
  width: 100%;
  min-height: 12rem;
  border: 1px solid #dbe0eb;
  border-radius: 6px;
  background-color: #ffffff;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  align-self: start;
  box-sizing: border-box;
  padding: 1rem;
`;
