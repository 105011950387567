import {
  citiesPattern,
  einAndTinPattern,
  genericAlphanumericFPattern,
  organizationNamePattern,
  phoneNumberPattern,
  zipCodePattern,
} from '../validators/commonValidators';

export const pointOfSaleKey = [
  {
    value: 'firstName',
    viewValue: 'Admin First Name',
    required: true,
    errors: [],
  },
  {
    value: 'lastName',
    viewValue: 'Admin Last Name',
    required: true,
    errors: [],
  },
  {
    value: 'email',
    viewValue: 'Admin Email',
    required: true,
    errors: [],
  },
];

export const generalKey = [
  {
    value: 'name',
    viewValue: 'Legal Entity Name',
    required: true,
    validators: [
      {
        type: 'pattern',
        match: organizationNamePattern,
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a legal entity name',
      },
      {
        type: 'pattern',
        message: 'Entity name must be valid',
      },
    ],
  },
  { value: 'entityType', viewValue: 'Entity Type', required: true },
  {
    value: 'establishedDate',
    viewValue: 'Date Established',
    required: true,
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a date of establishment',
      },
    ],
  },
  {
    value: 'ein',
    viewValue: 'Employer Identification Number',
    required: true,
    errors: [],
    validators: [
      { type: 'minLength', minLength: 9 },
      { type: 'maxLength', maxLength: 10 },
      { type: 'pattern', match: einAndTinPattern },
    ],
    validatorText: [
      {
        type: 'minLength',
        message: 'Please enter a nine-digit EIN',
      },
      {
        type: 'pattern',
        message: 'Please enter a valid EIN',
      },
      {
        type: 'required',
        message: 'EIN is required',
      },
    ],
  },
];

export const dispensaryAddressKey = [
  {
    value: 'streetAddress',
    viewValue: 'Street Address',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: genericAlphanumericFPattern,
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'Invalid character entered',
      },
      {
        type: 'required',
        message: 'Please enter a valid street address',
      },
    ],
  },
  {
    value: 'city',
    viewValue: 'City',
    required: true,
    errors: [],
    validators: [{ type: 'pattern', match: citiesPattern }],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please enter a valid city',
      },
      {
        type: 'required',
        message: 'Please enter a valid city',
      },
    ],
  },
  {
    value: 'state',
    viewValue: 'State',
    required: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'Please select a state',
      },
    ],
  },
  {
    value: 'country',
    viewValue: 'Country',
    required: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'Please select a country',
      },
    ],
  },
  {
    value: 'postalCode',
    viewValue: 'Zip Code',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: zipCodePattern,
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'You must enter a 5 or 9 digit zipcode',
      },
      {
        type: 'required',
        message: 'You must enter a 5 or 9 digit zipcode ',
      },
    ],
  },
  {
    value: 'iana_timezone',
    viewValue: 'Timezone',
    required: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'Please select a timezone',
      },
    ],
  },
];

export const financialKey = [
  { value: 'monthlySales', viewValue: 'Estimated Monthly Sales', prefix: 'true', prefixValue: '$', required: true },
  { value: 'monthlyCustomers', viewValue: 'Estimated Monthly Customers', required: true },
  { value: 'pos_name', viewValue: 'Sale/Invoice Tracking System', required: true },
  { value: 'business_type', viewValue: 'Business Type', required: true },
];

export const employeesKey = [
  {
    value: 'ftEmployees',
    viewValue: 'Full-Time Employees',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: '^[0-9]+$',
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please input a valid number',
      },
      {
        type: 'required',
        message: 'Please input a valid number',
      },
    ],
  },
  {
    value: 'ptEmployees',
    viewValue: 'Part-Time Employees',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: '^[0-9]+$',
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please input a valid number',
      },
      {
        type: 'required',
        message: 'Please input a valid number',
      },
    ],
  },
];

export const idPersonalKey = [
  {
    value: 'firstName',
    viewValue: 'First Name',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: "[a-zA-Z ,'.-]{2,30}",
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please enter a valid first name',
      },
      {
        type: 'required',
        message: 'Please enter a valid first name',
      },
    ],
  },
  {
    value: 'minitial',
    viewValue: 'Middle Name',
    required: false,
    errors: [],
    validators: [{ type: 'pattern', match: "[a-zA-Z ,'.-]{2,30}" }],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please enter a valid middle name or initial',
      },
      {
        type: 'required',
        message: 'Please enter a valid middle name or initial',
      },
    ],
  },
  {
    value: 'lastName',
    viewValue: 'Last Name',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: "[a-zA-Z ,'.-]{2,30}",
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'Please enter a valid last name',
      },
      {
        type: 'pattern',
        message: 'Please enter a valid last name',
      },
    ],
  },
  {
    value: 'dateOfBirth',
    viewValue: 'Date of Birth',
    required: true,
    errors: [],
    type: 'date',
    validators: [],
    validatorText: [
      {
        type: 'required',
        message: 'Please enter a valid last name',
      },
    ],
  },
  {
    value: 'email',
    viewValue: 'Email',
    required: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a valid email address ',
      },
    ],
  },
  {
    value: 'phone',
    viewValue: 'Phone',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: phoneNumberPattern,
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'Phone number is required',
      },
      {
        type: 'pattern',
        message: 'Phone number must be valid',
      },
    ],
  },
];

export const idAddressKey = [
  {
    value: 'address',
    viewValue: 'Street Address',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: genericAlphanumericFPattern,
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'Invalid character entered',
      },
      {
        type: 'required',
        message: 'Please enter a valid street address',
      },
    ],
  },
  {
    value: 'city',
    viewValue: 'City',
    required: true,
    errors: [],
    validators: [{ type: 'pattern', match: citiesPattern }],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please enter a valid city',
      },
      {
        type: 'required',
        message: 'Please enter a valid city',
      },
    ],
  },
  {
    value: 'state',
    viewValue: 'State',
    required: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'Please select a state',
      },
    ],
  },
  {
    value: 'zipcode',
    viewValue: 'Zip Code',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: zipCodePattern,
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'You must enter a 5 or 9 digit zipcode',
      },
      {
        type: 'required',
        message: 'You must enter a 5 or 9 digit zipcode ',
      },
    ],
  },
  {
    value: 'country',
    viewValue: 'Country',
    required: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'Please select a country',
      },
    ],
  },
];

export const idVerificationKey = [
  {
    value: 'idNumber',
    viewValue: 'ID Number',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: '[a-zA-Z0-9]{2,30}',
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please enter a valid ID Number',
      },
      {
        type: 'required',
        message: 'You must enter an ID number',
      },
    ],
  },
];

export const dispensaryContactKey = [
  {
    value: 'phone',
    viewValue: 'Business Phone',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: phoneNumberPattern,
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'Phone number is required',
      },
      {
        type: 'pattern',
        message: 'Phone number must be valid',
      },
    ],
  },
  {
    value: 'website',
    viewValue: 'Business Website',
    errors: [],
    validatorText: [
      {
        type: 'pattern',
        message: 'Your website must start with http:// or https://',
      },
    ],
  },
];
