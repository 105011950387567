<div class="background">
  <div class="wrapper">
    <div class="headerBar">
      <div class="logo">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 484 60.5">
          <defs><path id="a" d="M75.3 0l4.5 4.5L24 60.4 0 36.8l4.5-4.6L24 51.5z" /></defs>
          <use xlink:href="#a" overflow="visible" fill="#267832" />
          <defs><path id="b" d="M46.7 60.5h-9l49.6-49.6 4.5 4.4z" /></defs>
          <use xlink:href="#b" overflow="visible" fill="#88c933" />
          <g>
            <defs><path id="c" d="M69.4 60.2h-9l38.8-38.9 4.5 4.5z" /></defs>
            <use xlink:href="#c" overflow="visible" fill="#88c933" />
          </g>
          <g>
            <defs><path id="d" d="M92.1 60.3h-9l28.1-28.2 4.5 4.5z" /></defs>
            <use xlink:href="#d" overflow="visible" fill="#88c933" />
          </g>
          <g>
            <defs>
              <path
                id="e"
                d="M479.5 46.2l-13.3-15.3-6.6 6.8v8.6h-3.4l-.2-32.7h3.4v19.7l19.1-19.7h4l-14.1 14.8 15.1 17.8h-4zm-42.2.3c-9.8 0-17-7-17.1-16.5s7.2-16.6 17-16.6c4.7 0 9 1.6 11.9 4.7l-2.2 2.2c-2.7-2.7-5.9-3.8-9.6-3.8-7.9 0-13.8 5.8-13.7 13.5s5.9 13.5 13.8 13.5c3.7 0 7-1.2 9.6-3.9l2.2 2.2c-2.8 3.1-7.1 4.7-11.9 4.7zm-41.1-3.1h19.6v3h-23l-.1-32.6H415v3h-18.9v11.6h16.8v2.9h-16.8l.1 12.1zm-17-29.6h3.4l.1 32.6h-3.4v-15h-20.4v15h-3.4l-.1-32.6h3.4v14.5h20.4V13.8zm-42.6 32.9c-9.8 0-17-7-17.1-16.5 0-9.5 7.2-16.6 17-16.6 4.7 0 9 1.6 11.9 4.7l-2.2 2.2c-2.7-2.7-5.9-3.8-9.6-3.8-7.9 0-13.8 5.8-13.7 13.5s5.9 13.5 13.8 13.5c3.7 0 7-1.2 9.6-3.9l2.2 2.2c-2.8 3.1-7.1 4.7-11.9 4.7zM297.3 14h3.4l.1 32.6H298l-21-26.4.1 26.4h-3.4l-.2-32.6h2.8l21 26.4V14zm-50.9 29.7H266v3h-23l-.1-32.6h22.3v3h-18.9v11.6h16.8v2.9h-16.8l.1 12.1zm-30.6.1h19.6v3h-23l-.1-32.6h22.3v3h-18.9v11.6h16.8v2.9h-16.8l.1 12.1zm-24.5-10.3c6.5 0 9.9-3 9.9-8.2 0-5.2-3.5-8.2-10-8.2h-8.7v16.3l8.8.1zm13.9 13.3h-3.8L194 36.3c-.8.1-1.7.1-2.6.1h-8.7v10.3h-3.4l-.1-32.6h12.2c8.3 0 13.3 4.2 13.3 11.1 0 5.1-2.7 8.7-7.4 10.3l7.9 11.3zm-38.5-5.3v-11h3.3v12.4c-3.1 2.8-7.4 4.2-11.9 4.2-9.9 0-17.2-7-17.2-16.5S148.1 14 158.1 14c4.9 0 9.2 1.6 12.1 4.7l-2.1 2.2c-2.8-2.7-6-3.8-9.8-3.8-8 0-13.9 5.8-13.9 13.5s5.9 13.5 13.9 13.5c3 0 5.9-.7 8.4-2.6z"
              />
            </defs>
            <use xlink:href="#e" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="#267832" />
          </g>
        </svg>
      </div>
      <div class="headerText">Support Login</div>
    </div>
    <div class="textAreaWrapper">
      <div class="textArea">
        <mat-tab-group>
          <mat-tab label="CRBs">
            <mat-form-field>
              <input matInput #dispensarySearch placeholder="Search..." />
            </mat-form-field>
            <mat-selection-list
              #dispensaryList
              [multiple]="false"
              [(ngModel)]="selectedDispensaries"
              (ngModelChange)="onSelectedDispensariesChange($event)"
              [disableRipple]="true"
            >
              <mat-list-option
                *ngFor="let dispensary of dispensaries | orgSearch: dispensarySearch.value"
                (click)="selectOrg(dispensary.id, dispensary.orgType)"
                [value]="dispensary"
              >
                {{ dispensary.name }}
              </mat-list-option>
            </mat-selection-list>
          </mat-tab>

          <mat-tab label="FIs">
            <mat-form-field>
              <input matInput #bankSearch placeholder="Search..." />
            </mat-form-field>
            <mat-selection-list
              #fiList
              [multiple]="false"
              [(ngModel)]="selectedBanks"
              (ngModelChange)="onSelectedBanksChange($event)"
              [disableRipple]="true"
            >
              <mat-list-option
                *ngFor="let bank of banks | orgSearch: bankSearch.value"
                (click)="selectOrg(bank.id, bank.orgType)"
                [value]="bank"
              >
                {{ bank.orgName }}
              </mat-list-option>
            </mat-selection-list>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div class="buttonRow">
      <gcv-button
        class="button accept"
        buttonText="Continue"
        type="Primary"
        [disabled]="orgId === undefined"
        (clickEvent)="continueToDashboard()"
      ></gcv-button>
    </div>
  </div>
</div>
