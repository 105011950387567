import { Action } from '@ngrx/store';
import { Dispensary, Group } from '@gcv/shared';
import { ApprovalRequest } from './dispensary.facade';

export enum DispensaryActionTypes {
  LoadDispensary = '[Dispensary] Load Dispensary',
  DispensaryLoaded = '[Dispensary] Dispensary Loaded',

  LoadAllDispensary = '[Dispensary] Load All Dispensaries',
  AllDispensaryLoaded = '[Dispensary] All Dispensary Loaded',

  LoadDispensariesByBankId = '[Dispensary] Load Dispensaries By Bank Id',
  DispensariesByBankIdLoaded = '[Dispensary] Dispensaries By Bank Id Loaded',

  UpdateDispensary = '[Dispensary] Update Dispensary',
  DispensaryUpdated = '[Dispensary] Dispensary Updated',

  CreateDispensary = '[Dispensary] Create Dispensary',
  DispensaryCreated = '[Dispensary] Dispensary Created',

  approveBankDueDiligence = '[Dispensary] Approve Bank Due Diligence',
  bankDueDiligenceApproved = '[Dispensary] Bank Due Diligence Approved',

  approveGcvDueDiligence = '[Dispensary] Approve Gcv Due Diligence',
  gcvDueDiligenceApproved = '[Dispensary] Gcv Due Diligence Approved',

  getGcvDueDiligenceTemplate = '[Dispensary] Get Gcv DueDiligence Template',
  gcvDueDiligenceTemplateFetched = '[Dispensary] Gcv DueDiligence Template Fetched',

  DispensaryUpdateGroups = '[Dispensary] Update Groups',
  DispensaryGroupsUpdated = '[Dispensary] Groups Updated',

  approveBankLicense = '[Dispensary] Approve License',
  bankLicenseApproved = '[Dispensary] License Approved',

  ReviewDispensary = '[Dispensary] Review Dispensary',
  DispensaryReviewed = '[Dispensary] Dispensary Reviewed',

  ApproveBankReviewSections = '[Dispensary] Approve Bank Sections',
  BankReviewSectionsApproved = '[Dispensary] Bank Sections Approved',

  ResetDispensary = '[Dispensary] Reset Dispensary',

  UpdateCurrentDispensary = '[Dispensary] Update Current Dispensary',

  UpdateGcvDueDiligenceStatus = '[Dispensary] Update GCV Due Diligence Status',
  GcvDueDiligenceStatusUpdated = '[Dispensary] GCV Due Diligence Status Updated',

  UpdateBankDueDiligenceStatus = '[Dispensary] Update Bank Due Diligence Status',
  BankDueDiligenceStatusUpdated = '[Dispensary] Bank Due Diligence Status Updated',

  UpdateDispensaryBusinessDetails = '[Dispensary] Update Dispensary Business Details',
  UpdateDispensaryOperationalDetails = '[Dispensary] Update Dispensary Operational Details',
  UpdateDispensaryAccountOwnersSectionComplete = '[Dispensary] Update Dispensary Account Owners Section Complete',
  UpdateDispensaryRequirement = '[Dispensary] Update Dispensary Requirement',

  InviteDispensaryUser = '[Dispensary] Invite Dispensary User',
}

export class UpdateGcvDueDiligenceStatus implements Action {
  readonly type = DispensaryActionTypes.UpdateGcvDueDiligenceStatus;
  constructor(public payload: { dispensaryId: string }) {}
}

export class GcvDueDiligenceStatusUpdated implements Action {
  readonly type = DispensaryActionTypes.GcvDueDiligenceStatusUpdated;
  constructor(public payload: Dispensary) {}
}

export class UpdateBankDueDiligenceStatus implements Action {
  readonly type = DispensaryActionTypes.UpdateBankDueDiligenceStatus;
  constructor(public payload: { dispensaryId: string }) {}
}

export class BankDueDiligenceStatusUpdated implements Action {
  readonly type = DispensaryActionTypes.BankDueDiligenceStatusUpdated;
  constructor(public payload: Dispensary) {}
}

export class ApproveBankDueDiligence implements Action {
  readonly type = DispensaryActionTypes.approveBankDueDiligence;

  constructor(
    public payload: {
      dispensaryId: string;
      bankId: string;
      data: ApprovalRequest;
    }
  ) {}
}

export class BankDueDiligenceApproved implements Action {
  readonly type = DispensaryActionTypes.bankDueDiligenceApproved;

  constructor(public payload: Dispensary) {}
}

export class ApproveGcvDueDiligence implements Action {
  readonly type = DispensaryActionTypes.approveGcvDueDiligence;

  constructor(public payload: { dispensaryId: string; data: { reviewer: string; status: string } }) {}
}

export class GcvDueDiligenceApproved implements Action {
  readonly type = DispensaryActionTypes.gcvDueDiligenceApproved;

  constructor(public payload: Dispensary) {}
}

export class ApproveBankLicense implements Action {
  readonly type = DispensaryActionTypes.approveBankLicense;
  constructor(
    public payload: {
      dispensaryId: string;
      data: { reviewer: string; status: string; type: string; reviewer_id: string };
    }
  ) {}
}

export class BankLicenseApproved implements Action {
  readonly type = DispensaryActionTypes.bankLicenseApproved;

  constructor(public payload: Dispensary) {}
}
export class ApproveBankReviewSections implements Action {
  readonly type = DispensaryActionTypes.ApproveBankReviewSections;

  constructor(
    public payload: {
      dispensaryId: string;
      data: {
        operation_details?: boolean;
        account_owners?: boolean;
        due_diligence_documentation?: boolean;
        business_details?: boolean;
      };
    }
  ) {}
}
export class BankReviewSectionsApproved implements Action {
  readonly type = DispensaryActionTypes.BankReviewSectionsApproved;

  constructor(public payload: Dispensary) {}
}

export class LoadDispensary implements Action {
  readonly type = DispensaryActionTypes.LoadDispensary;

  constructor(public payload: { dispensaryId: string }) {}
}

export class DispensaryLoaded implements Action {
  readonly type = DispensaryActionTypes.DispensaryLoaded;

  constructor(public payload: Dispensary) {}
}

export class LoadAllDispensary implements Action {
  readonly type = DispensaryActionTypes.LoadAllDispensary;
}

export class AllDispensaryLoaded implements Action {
  readonly type = DispensaryActionTypes.AllDispensaryLoaded;

  constructor(public payload: Dispensary[]) {}
}

export class LoadDispensariesByBankId implements Action {
  readonly type = DispensaryActionTypes.LoadDispensariesByBankId;
  constructor(public payload: { bankId: string }) {}
}

export class DispensariesByBankIdLoaded implements Action {
  readonly type = DispensaryActionTypes.DispensariesByBankIdLoaded;
  constructor(public payload: Dispensary[]) {}
}

export class UpdateDispensary implements Action {
  readonly type = DispensaryActionTypes.UpdateDispensary;

  constructor(public payload: Dispensary) {}
}

export class DispensaryUpdated implements Action {
  readonly type = DispensaryActionTypes.DispensaryUpdated;

  constructor(public payload: Dispensary) {}
}

export class CreateDispensary implements Action {
  readonly type = DispensaryActionTypes.CreateDispensary;

  constructor(public payload: Partial<Dispensary>) {}
}

export class DispensaryCreated implements Action {
  readonly type = DispensaryActionTypes.DispensaryCreated;

  constructor(public payload: Dispensary) {}
}

export class DispensaryUpdateGroups implements Action {
  readonly type = DispensaryActionTypes.DispensaryUpdateGroups;

  constructor(public payload: { dispensaryId: string; groups: Group[] }) {}
}

export class DispensaryGroupsUpdated implements Action {
  readonly type = DispensaryActionTypes.DispensaryGroupsUpdated;

  constructor(public payload: Dispensary) {}
}

export class ResetDispensary implements Action {
  readonly type = DispensaryActionTypes.ResetDispensary;
}

export class UpdateCurrentDispensary implements Action {
  readonly type = DispensaryActionTypes.UpdateCurrentDispensary;

  constructor(public payload: string) {}
}

export class GetGcvDueDiligenceTemplate implements Action {
  readonly type = DispensaryActionTypes.getGcvDueDiligenceTemplate;

  constructor(public payload: { id: string }) {}
}

export class GcvDueDiligenceTemplateFetched implements Action {
  readonly type = DispensaryActionTypes.gcvDueDiligenceTemplateFetched;

  constructor(public payload: any) {}
}

export class ReviewDispensary implements Action {
  readonly type = DispensaryActionTypes.ReviewDispensary;

  constructor(public payload: { bankId: string; dispensaryId: string }) {}
}

export class DispensaryReviewed implements Action {
  readonly type = DispensaryActionTypes.DispensaryReviewed;

  constructor(public payload: Dispensary) {}
}

export class UpdateDispensaryBusinessDetails implements Action {
  readonly type = DispensaryActionTypes.UpdateDispensaryBusinessDetails;

  constructor(public payload: { dispensaryId: string; businessDetails: any }) {}
}

export class UpdateDispensaryOperationalDetails implements Action {
  readonly type = DispensaryActionTypes.UpdateDispensaryOperationalDetails;

  constructor(public payload: { dispensaryId: string; operationalDetails: any }) {}
}

export class UpdateDispensaryAccountOwnersSectionComplete implements Action {
  readonly type = DispensaryActionTypes.UpdateDispensaryAccountOwnersSectionComplete;

  constructor(public payload: { dispensaryId: string; accountOwnersSectionComplete: any }) {}
}

export class UpdateDispensaryRequirement implements Action {
  readonly type = DispensaryActionTypes.UpdateDispensaryRequirement;

  constructor(public payload: { dispensaryId: string; requirement: any }) {}
}

export class InviteDispensaryUser implements Action {
  readonly type = DispensaryActionTypes.InviteDispensaryUser;

  constructor(public payload: { dispensaryId: string; user: any }) {}
}

export type DispensaryActions =
  | LoadDispensary
  | DispensaryLoaded
  | LoadAllDispensary
  | AllDispensaryLoaded
  | UpdateDispensary
  | DispensaryUpdated
  | CreateDispensary
  | DispensaryCreated
  | DispensaryUpdateGroups
  | DispensaryGroupsUpdated
  | ResetDispensary
  | UpdateCurrentDispensary
  | ApproveBankDueDiligence
  | ApproveBankLicense
  | BankDueDiligenceApproved
  | GcvDueDiligenceApproved
  | ReviewDispensary
  | DispensaryReviewed
  | ApproveGcvDueDiligence
  | UpdateGcvDueDiligenceStatus
  | GcvDueDiligenceStatusUpdated
  | UpdateBankDueDiligenceStatus
  | BankDueDiligenceStatusUpdated;
