import { appRoutesNames } from '../../app.routes.names';
import { secureRoutesNames } from '../../secure.routes.names';

export const FINCEN_ROUTES_NAMES = {
  CURRENT: 'current',
  OPEN: 'open',
  FILED: 'filed',
  DETAILS: 'details',
  SALES: 'sales',
  CREATE: 'create',
  CTR: 'ctr',
  SAR: 'sar',
  EXPORTS: 'exports',
};

export function getCreateRouteForFincenReportType(
  reportType: typeof FINCEN_ROUTES_NAMES.SAR | typeof FINCEN_ROUTES_NAMES.CTR
) {
  return `/${appRoutesNames.SECURE}/${secureRoutesNames.BANK}/fincen/${FINCEN_ROUTES_NAMES.CREATE}/${reportType}`;
}

export function getCurrentRouteForFincen() {
  return `/${appRoutesNames.SECURE}/${secureRoutesNames.BANK}/fincen/${FINCEN_ROUTES_NAMES.CURRENT}`;
}

export function getOpenRouteForFincen() {
  return `/${appRoutesNames.SECURE}/${secureRoutesNames.BANK}/fincen/${FINCEN_ROUTES_NAMES.OPEN}`;
}
