import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { SearchDepositsCard } from './search-deposits-card/search-deposits-card.component';
import { EditDepositsCard } from './edit-deposits-card/edit-deposits-card.component';

export const GcvDeposits: React.FC = props => {
  const [selectedDeposit, setSelectedDeposit] = useState({
    final_deposit: '',
    expected_arrival_date: '',
    arrived_date: '',
    bank_comments: '',
    status: '',
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchDepositsCard selectedDeposit={selectedDeposit} setSelectedDeposit={setSelectedDeposit} />
      </Grid>
      <Grid item xs={12}>
        <EditDepositsCard selectedDeposit={selectedDeposit} setSelectedDeposit={setSelectedDeposit} />
      </Grid>
    </Grid>
  );
};
