import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-due-diligence-requirement-card',
  templateUrl: './due-diligence-requirement-card.component.html',
  styleUrls: ['./due-diligence-requirement-card.component.scss'],
})
export class DueDiligenceRequirementCardComponent implements OnInit {
  @Input() isUploaded = false;
  @Input() multipleDocs = false;
  @Input() noDocument = false;
  @Input() filename = '';
  @Input() document = '';
  @Input() description = '';
  @Output() uploadEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();

  spinnerReady = false;
  private _isUploading: boolean;

  @Input()
  set isUploading(value: boolean) {
    this._isUploading = value;
    if (!value) {
      this.spinnerReady = false;
    }
  }

  get isUploading(): boolean {
    return this._isUploading;
  }

  constructor() {}

  ngOnInit() {}

  handleEdit() {
    this.spinnerReady = true;
    this.editEvent.emit();
  }

  handleUpload() {
    this.spinnerReady = true;
    this.uploadEvent.emit();
  }
}
