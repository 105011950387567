import { useApi } from '../hooks';

import { accountMonitoringClient } from './account-reviews.client';
import { banksClient } from './banks.client';
import { dispensariesClient } from './dispensaries.client';
import { documentManagementClient } from './document-management.client';
import { documentsClient } from './documents.client';
import { dueDiligenceTemplatesClient } from './due-diligence-templates.client';
import { eventsClient } from './events.client';
import { fincenClient } from './fincen.client';
import { gcvClient } from './gcv.client';
import { groupsClient } from './groups.client';
import { notificationsClient } from './notifications.client';
import { organizationsClient } from './organizations.client';
import { peerGroupsClient } from './peer-groups.client';
import { permissionsClient } from './permissions.client';
import { quickbooksClient } from './quickooks.client';
import { reportingClient } from './reporting.client';
import { serviceFileClient } from './service-file.client';
import { usersClient } from './users.client';

export const api = () => {
  const { API } = useApi();

  return {
    accountMonitoring: accountMonitoringClient(API),
    banks: banksClient(API),
    dispensaries: dispensariesClient(API),
    documentManagement: documentManagementClient(API),
    documents: documentsClient(API),
    dueDiligenceTemplate: dueDiligenceTemplatesClient(API),
    events: eventsClient(API),
    fincen: fincenClient(API),
    gcv: gcvClient(API),
    groups: groupsClient(API),
    notifications: notificationsClient(API),
    organizations: organizationsClient(API),
    peerGroups: peerGroupsClient(API),
    permissions: permissionsClient(API),
    quickbooks: quickbooksClient(API),
    reporting: reportingClient(API),
    serviceFile: serviceFileClient(API),
    users: usersClient(API),
  };
};
