import React, { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Grid } from '@material-ui/core';
import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { Dropdown, VerticalCenter } from './styles';
import { GcvUserInputContainer } from '../GcvUserInputContainer/GcvUserInputContainer';
import { GcvSearchInput } from '../GcvSearchInput/GcvSearchInput';
import { GcvCheckbox } from '../GcvCheckbox/GcvCheckbox';
import { GcvButton } from '../GcvButton/GcvButton';
import { FlexBox } from '../../styles/theme';

interface Props {
  searchText?: string;
  itemLabel?: string;
  distinctItems: Option[];
  filterReport?: (results: any[]) => void;
  noSearch?: boolean;
  defaultValue?: string;
}
interface Option {
  value: string;
  label: string;
}

export const GcvSearchDropDown = (props: Props) => {
  const [query, setQuery] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [filteredItems, setFilteredItems] = useState(props.distinctItems);
  const [selectedItems, setSelectedItems] = useState([] as Option[]);
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    if (!initialized && props.distinctItems && props.distinctItems.length && props.defaultValue) {
      const selectedItem = props.distinctItems.find(a => a.value === props.defaultValue);
      setSelectedItems([selectedItem]);
      toggleItem(selectedItem);
      setInitialized(true);
    }
  });

  useEffect(() => {
    if (!props.defaultValue || initialized) {
      setFilteredItemsFromQuery();
    }
  }, [props.distinctItems]);

  useEffect(() => {
    if (showSearchDropDown) {
      document.addEventListener('mousedown', handleDropdownBlur);

      return () => {
        document.removeEventListener('mousedown', handleDropdownBlur);
      };
    }
  }, [showSearchDropDown]);

  useEffect(() => {
    if (selectedItems.length === 0) {
      props.filterReport(props.distinctItems.map(a => a.value));
    } else {
      props.filterReport(selectedItems.map(a => a.value));
    }
  }, [selectedItems]);

  useEffect(() => {
    if (!props.defaultValue || initialized) {
      setFilteredItemsFromQuery();
    }
  }, [query]);

  const toggleItem = (item: Option) => {
    if (selectedItems.find(o => o.value === item.value)) {
      setSelectedItems(selectedItems.filter(a => a.value !== item.value));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedItems.length === filteredItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filteredItems);
    }
  };

  const handleDropdownBlur = e => {
    if (dropdownRef) {
      // @ts-ignore
      if (!dropdownRef.current.contains(e.target)) {
        setShowSearchDropDown(false);
      }
    }
  };

  const applyFilter = () => {
    setShowSearchDropDown(false);

    if (!props.filterReport) {
      return;
    }

    if (selectedItems.length === 0) {
      props.filterReport(props.distinctItems.map(a => a.value));
    } else {
      props.filterReport(selectedItems.map(a => a.value));
    }
  };

  const onSearchItemsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const setFilteredItemsFromQuery = () => {
    updateSelectedItems();
    if (query) {
      setFilteredItems(props.distinctItems.filter(a => a.label.toLowerCase().includes(query.toLowerCase())));
    } else {
      setFilteredItems(props.distinctItems);
    }
  };

  const updateSelectedItems = () => {
    let existingSelectedItems = [];
    if (selectedItems.length > 0) {
      selectedItems.forEach(selectedItem => {
        if (props.distinctItems.some(distinctItem => distinctItem.value === selectedItem.value)) {
          existingSelectedItems.push(selectedItem);
        }
      });
    }
    setSelectedItems(existingSelectedItems);
  };

  const toggleDropDown = e => {
    setShowSearchDropDown(true);
  };

  const getDisplayValue = () => {
    const selectedItemLabels = selectedItems.map(a => a.label);

    let string;
    if (selectedItemLabels.length === props.distinctItems.length || selectedItemLabels.length === 0) {
      string = `All ${props.itemLabel}`;
    } else if (selectedItemLabels.length > 1) {
      string = `${selectedItemLabels.length} ${props.itemLabel}`;
    } else if (selectedItemLabels.length === 1) {
      string = selectedItemLabels[0];
    }

    return <GcvUserInputContainer string={string} length={16}></GcvUserInputContainer>;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '34px', boxSizing: 'border-box' }}>
      <div
        style={{
          backgroundColor: '#F2F4FA',
          border: '1px solid #EBEEF7',
          borderRight: 'none',
          borderRadius: '4px 0 0 4px',
          padding: '6px 8px',
          width: '100px',
          textAlign: 'center',
          height: '34px',
          boxSizing: 'border-box',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '14px',
          color: '#828599',
          verticalAlign: 'middle',
        }}
      >
        <VerticalCenter style={{ height: '100%' }}>
          <div>{props.itemLabel}</div>
        </VerticalCenter>
      </div>
      <Dropdown>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '175px',
            cursor: 'pointer',
          }}
          onClick={toggleDropDown}
        >
          <VerticalCenter
            style={{
              padding: '6px 8px',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '14px',
              color: 'black',
            }}
          >
            {getDisplayValue()}
          </VerticalCenter>

          <VerticalCenter style={{ padding: '0 .5rem' }}>
            <DownArrowIcon></DownArrowIcon>
          </VerticalCenter>
        </div>

        {showSearchDropDown ? (
          <div
            style={{
              zIndex: 10000,
              position: 'absolute',
              backgroundColor: 'rgb(255, 255, 255)',
              border: '1px solid #EBEEF7',
              borderRadius: '0 0 4px 4px',
              borderTop: 0,
              padding: '15px',
              width: '275px',
              maxHeight: '500px',
              marginTop: '34px',
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '14px',
              boxSizing: 'border-box',
              boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
            }}
            ref={dropdownRef}
          >
            <div style={{ borderBottom: '1px solid #EBEEF7' }}>
              {!props.noSearch ? (
                <GcvSearchInput
                  label={props.searchText}
                  placeholder={props.searchText}
                  id="searchAccounts"
                  value={query}
                  style={{ height: 'auto', marginTop: '0px' }}
                  onChange={onSearchItemsChange}
                />
              ) : null}
              <ul
                style={{
                  listStyleType: 'none',
                  maxHeight: '250px',
                  overflowY: 'auto',
                  padding: 0,
                }}
              >
                {filteredItems
                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                  .map(item => {
                    const isSelected = selectedItems.some(s => s.value === item.value);

                    return (
                      <li key={item.value} style={{ paddingRight: '10px' }}>
                        <div
                          style={{
                            display: 'flex',
                            margin: '8px 0',
                          }}
                        >
                          <ReactTooltip place="top" type="dark" effect="solid" delayShow={100} />
                          <p
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              margin: '0px 0.5rem 0 0',
                            }}
                            data-tip={item.label}
                          >
                            {item.label}
                          </p>
                          <label style={{ marginLeft: 'auto' }}>
                            <GcvCheckbox checked={isSelected} onChange={() => toggleItem(item)} />
                          </label>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div style={{ marginTop: `15px` }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <span style={{ color: '#9B9EB1', fontSize: '16px' }}>{selectedItems.length} Selected</span>
                </Grid>
                <Grid item xs={6} style={{ justifyContent: 'flex-end' }}>
                  <FlexBox style={{ justifyContent: 'flex-end' }}>
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#6896FB',
                        cursor: 'pointer',
                        width: '100%',
                        textAlign: 'right',
                      }}
                      onClick={toggleSelectAll}
                    >
                      {filteredItems.length === selectedItems.length ? 'Deselect All' : 'Select All'}
                    </span>
                  </FlexBox>
                </Grid>
              </Grid>
              <div style={{ textAlign: 'center', width: '100%', marginTop: '15px' }}>
                <GcvButton primary onClick={applyFilter}>
                  Apply
                </GcvButton>
              </div>
            </div>
          </div>
        ) : null}
      </Dropdown>
    </div>
  );
};
