import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';

import { DrawerText, DrawerImage, VerticalCenter } from '../styles';
import { ViewMoreIcon } from 'libs/react-ui/src/icons/ViewMoreIcon';

interface Props {
  form: any;
}

export const BlazeForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To configure this integration, you’ll need to generate a developer key from the backend of BLAZE Retail.
      </DrawerText>
      <DrawerText>
        <a
          target="_blank"
          href="https://support.blaze.me/hc/en-us/articles/360024795293-Retail-Generating-Developer-Keys-and-3rd-Party-API-Integration-"
          style={{ display: 'flex' }}
        >
          <VerticalCenter>Step-by-step instructions</VerticalCenter>
          <VerticalCenter style={{ marginLeft: '5px' }}>
            <ViewMoreIcon></ViewMoreIcon>
          </VerticalCenter>
        </a>
      </DrawerText>
      <DrawerImage src="../../../assets/BlazeDetails.png"></DrawerImage>
      <GcvInputForm
        {...form}
        name="Authorization"
        label="Key"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
    </>
  );
};
