import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Routes from './routes';

export const SalesAndDepositsRouter = props => {
  const renderRoutes = () =>
    Routes.map(({ path, render }) => {
      const componentRender = () => render(props);
      return <Route path={path} render={componentRender} exact key={path}></Route>;
    });

  return <Router>{renderRoutes()}</Router>;
};
