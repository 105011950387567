import React, { useState, useEffect, useRef } from 'react';

import { Controller } from 'react-hook-form';
import Cleave from 'cleave.js/react';
import { DateTime } from 'luxon';

import { Container, InputContainer, PlaceHolder, Warning, CalendarIcon } from './styles';
import { errorMessages } from '../../util/validationHelpers';

import Calendar from 'react-calendar';
import { CalendarContainer } from '../GcvTimePeriodDropdown/styles';

require('cleave.js/dist/addons/cleave-phone.us');

/*
  Needs to be used with react-hook-form in the parent component: 
  For more information see https://react-hook-form.com/
*/

interface Props {
  rules?: {
    required?: any;
    maxLength?: any;
    minLength?: any;
    max?: any;
    min?: any;
    pattern?: any;
    validate?: any;
  };
  name: string;
  label: React.ReactNode;
  watch: any;
  errors: any;
  defaultValue?: string;
  type?: string;
  control: any;
  setValue: any;
  triggerValidation: any;
  testDataTag?: string;
  readonly?: boolean;
}

/*
  it is possible to input a custom message on the register prop:
    const form = useForm()
    <GcvInputForm
      ...
      rules={ minLength: {value:3, message: custom message} }
      {...form} 
    />
*/

const options = {
  text: { blocks: [99999], delimiter: '' },
  date: { date: true, datePattern: ['m', 'd', 'Y'], noImmediatePrefix: true }, //use dateFormatter to format
  currency: { numeral: true, numeralThousandsGroupStyle: 'thousand', prefix: '$', noImmediatePrefix: true },
  phone: { numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ['(', ')', ' ', '-'] },
  tin: { numericOnly: true, blocks: [2, 7], delimiters: ['-'] },
  number: { numeral: true },
};

export const dateFormatterToISO = date =>
  DateTime.fromFormat(date, 'MM/dd/yyyy')
    .toUTC()
    .toISO();
export const dateFormatterFromISO = date => DateTime.fromISO(date).toFormat('MM/dd/yyyy');
export const formDateRules = {
  required: true,
  validate: {
    future: value => dateFormatterToISO(value) < DateTime.local().toISO() || "can't input a date in the future",
  },
  minLength: 10,
};

export const GcvInputForm = ({
  control,
  rules = {},
  label,
  name,
  defaultValue,
  watch,
  errors,
  type = 'text',
  setValue,
  triggerValidation,
  testDataTag,
  readonly = false,
}: Props) => {
  const [focus, setFocus] = useState(false);
  const [isTiny, setIsTiny] = useState(defaultValue !== undefined);
  const [calendar, setCalendar] = useState(false);
  const calendarRef = useRef();
  const calendarIconRef = useRef();

  const handleFocus = () => {
    setFocus(true);
    if (type === 'date' || type === 'dateRange') {
      toggleCalendar();
    }
  };

  const handleBlur = e => {
    //@ts-ignore
    if (!(calendarRef?.current?.contains(e.target) || calendarIconRef?.current?.contains(e.target))) {
      triggerValidation(name);
      setFocus(false);
    }
  };

  useEffect(() => {
    setIsTiny(focus || !!watch(name));
  }, [focus, watch(name)]);

  const handleDateChange = date => {
    const newDate = new Date(date);
    setValue(name, dateFormatterFromISO(newDate.toISOString()));
    triggerValidation(name);
    setCalendar(false);
  };

  const handleDateRangeChange = dates => {
    const newStartDate = new Date(dates[0]);
    const newEndDate = new Date(dates[1]);
    setValue(
      name,
      dateFormatterFromISO(newStartDate.toISOString()) + ' - ' + dateFormatterFromISO(newEndDate.toISOString())
    );
    triggerValidation(name);
    setCalendar(false);
  };

  const toggleCalendar = () => {
    setCalendar(!calendar);
  };

  const handleCalendarBlur = e => {
    if (calendarRef && calendarIconRef) {
      // @ts-ignore
      if (!calendarRef.current?.contains(e.target) && !calendarIconRef.current?.contains(e.target)) {
        setCalendar(false);
      }
    }
  };

  useEffect(() => {
    if (calendar) {
      document.addEventListener('mousedown', handleCalendarBlur);
      return () => {
        document.removeEventListener('mousedown', handleCalendarBlur);
      };
    }
  }, [calendar]);

  return (
    <Container>
      <PlaceHolder tiny={isTiny} focus={focus}>
        {label} {rules.required ? '*' : ''}
      </PlaceHolder>
      <InputContainer>
        <Controller
          as={
            <Cleave
              className="input-style"
              options={options[type]}
              value={defaultValue}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          }
          rules={rules}
          name={name}
          control={control}
          defaultValue={defaultValue}
          disabled={readonly}
          data-cy={testDataTag}
        />
        {type === 'date' && !readonly ? (
          <CalendarIcon onClick={toggleCalendar} src="../../../assets/CalendarIcon.png" ref={calendarIconRef} />
        ) : null}
      </InputContainer>
      {calendar ? (
        <CalendarContainer ref={calendarRef}>
          <Calendar
            onChange={type === 'dateRange' ? handleDateRangeChange : handleDateChange}
            selectRange={type === 'dateRange'}
            activeStartDate={new Date()}
            calendarType={'US'}
          />
        </CalendarContainer>
      ) : null}
      {errors[name] && (
        <Warning data-testid={`warning`}>
          {errors[name] && `* ${label} ${errors[name].message || errorMessages[errors[name].type]}`}
        </Warning>
      )}
    </Container>
  );
};
