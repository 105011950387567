import { ReactWrapperComponent, WrapperProps, GcvTriggerIngestSales } from '@user-interface/react-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gcv-trigger-ingest-sales',
  template: '<app-react-wrapper [wrappedComponent]="component" ></app-react-wrapper>',
})
export class GcvTriggerIngestSalesComponent extends ReactWrapperComponent {
  component = GcvTriggerIngestSales;

  constructor() {
    super();
  }
}
