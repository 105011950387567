import { IANATimezones } from '@gcv/shared';
import * as dateTimeHelpers from './dateTimeHelpers';
import { DateTime } from 'luxon';

export default (timePeriod: string, timezone: IANATimezones): { start: string; end: string } => {
  if (timePeriod.includes(' - ')) {
    return dateTimeHelpers.getCustomTimeRange(timePeriod, timezone);
  }
  switch (timePeriod) {
    case 'today':
    default:
      return dateTimeHelpers.getUtcDateTimeRangeToday(timezone);
    case 'yesterday':
      return dateTimeHelpers.getUtcDateTimeRangeYesterday(timezone);
    case 'thisWeek':
      return dateTimeHelpers.getUtcDateTimeRangeThisWeek(timezone);
    case 'thisMonth':
      return dateTimeHelpers.getUtcDateTimeRangeThisMonth(timezone);
    case 'thisQuarter':
      return dateTimeHelpers.getUtcDateTimeRangeThisQuarter(timezone);
    case 'thisYear':
      return dateTimeHelpers.getUtcDateTimeRangeThisYear(timezone);
    case 'lastWeek':
      return dateTimeHelpers.getUtcDateTimeRangeLastWeek(timezone);
    case 'lastMonth':
      return dateTimeHelpers.getUtcDateTimeRangeLastMonth(timezone);
    case 'lastQuarter':
      return dateTimeHelpers.getUtcDateTimeRangeLastQuarter(timezone);
    case 'lastYear':
      return dateTimeHelpers.getUtcDateTimeRangeLastYear(timezone);
    case 'last30Days':
      return dateTimeHelpers.getUtcDateTimeRangeLastThirtyDays(timezone);
    case 'all':
      return dateTimeHelpers.getUtcDateTimeRangeAll();
  }
};

export const timePeriodOptions = [
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'thisWeek', label: 'This Week' },
  { value: 'thisMonth', label: 'This Month' },
  { value: 'thisQuarter', label: 'This Quarter' },
  { value: 'thisYear', label: 'This Year' },
  { value: 'lastWeek', label: 'Last Week' },
  { value: 'lastMonth', label: 'Last Month' },
  { value: 'lastQuarter', label: 'Last Quarter' },
  { value: 'lastYear', label: 'Last Year' },
  { value: 'last30Days', label: 'Last 30 Days' },
  { value: 'custom', label: 'Custom' },
];

export const calculateChartBatchSize = (start: string, end: string): 'daily' | 'monthly' => {
  if (!start || !end) {
    return null;
  }
  const i1 = DateTime.fromISO(start);
  const i2 = DateTime.fromISO(end);
  const diff = i2.diff(i1, 'days').toObject().days;

  const timeScale: 'daily' | 'monthly' = diff > 31 ? 'monthly' : 'daily';

  return timeScale;
};
