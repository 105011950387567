import { ReactWrapperComponent, WrapperProps } from '../../../util';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivityReportingCRB } from './ActivityReportingCRB';
import { ActivityReportingCRBPayload as Payload } from '.';

@Component({
  selector: 'app-activity-reporting-crb',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="onFormSubmit"></app-react-wrapper>',
})
export class ActivityReportingCRBComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Output() payloadEmitter = new EventEmitter<any>();
  component = ActivityReportingCRB;

  constructor() {
    super();
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(payload: Payload) {
    this.payloadEmitter.emit(payload);
  }
}
