import { Effect } from '@ngrx/effects';
import { DataPersistence } from '../../utils/data.persistance';
import { PrimaryContactState } from './primaryContact.reducer';
import {
  ApprovePrimaryContact,
  LoadPrimaryContact,
  PrimaryContactActionTypes,
  PrimaryContactApproved,
  PrimaryContactLoaded,
} from './primaryContact.action';
import { map } from 'rxjs/operators';
import { User } from '@gcv/shared';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';

@Injectable({
  providedIn: 'root',
})
export class PrimaryContactEffect {
  @Effect() loadPrimaryContact$ = this.dataPersistance.fetch(PrimaryContactActionTypes.LoadPrimaryContact, {
    run: (action: LoadPrimaryContact, state: PrimaryContactState) => {
      return this.sdk
        .usersIdGet(action.payload.primaryContactId)
        .pipe(map((res: User) => new PrimaryContactLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: PrimaryContactActionTypes.LoadPrimaryContact, message: err });
    },
  });

  @Effect() approvePrimaryContact$ = this.dataPersistance.fetch(PrimaryContactActionTypes.ApprovePrimaryContact, {
    run: (action: ApprovePrimaryContact, state: PrimaryContactState) => {
      return this.sdk
        .usersIdPut(action.payload.id, action.payload)
        .pipe(map((res: User) => new PrimaryContactApproved(res)));
    },
    onError: err => {
      return new AddError({ action: PrimaryContactActionTypes.ApprovePrimaryContact, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<PrimaryContactState>, private sdk: DefaultService) {}
}
