import React from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import WidgetCharts from 'fusioncharts/fusioncharts.widgets';
import ReactFusioncharts from 'react-fusioncharts';

import {
  AreaConfig,
  BubbleConfig,
  BulletConfig,
  chartOptions,
  HeatmapConfig,
  HorizontalBarConfig,
  PieConfig,
  RadarConfig,
  VerticalBarConfig,
  MultiLineConfig,
} from './chart-options';

type CommonProps = {
  height?: number;
};

type ChartProps =
  | {
      type: 'msline';
      config: MultiLineConfig;
    }
  | {
      type: 'area';
      config: AreaConfig;
    }
  | {
      type: 'bubble';
      config: BubbleConfig;
    }
  | {
      type: 'bullet';
      config: BulletConfig;
    }
  | {
      type: 'heatmap';
      config: HeatmapConfig;
    }
  | {
      type: 'horizontalBar';
      config: HorizontalBarConfig;
    }
  | {
      type: 'pie';
      config: PieConfig;
    }
  | {
      type: 'radar';
      config: RadarConfig;
    }
  | {
      type: 'verticalBar';
      config: VerticalBarConfig;
    };

type Props = CommonProps & ChartProps;

export const GcvChart: React.FC<Props> = props => {
  Charts(FusionCharts);
  PowerCharts(FusionCharts);
  WidgetCharts(FusionCharts);

  FusionCharts.options['license']({
    key:
      'HA-8E3A-64coB5A2D4D2D4E4C3A16C10C3D2A2mA-8vC1A9B-22D-16ssdF3E4warA7A3C4F2C1G1F1A10C1A6A1F6C4F2B1H2ettD2E6E1A-8E1G2C11qpgB4F1D4akzD7E6F4aH-9yD2I3B6B8D2C5F2G4C1G2B1B-21ppB1A4c1G4da1wB9B6C6nA-9H4VH4A9kkdE3D4A2A3C3B10C10A3B5A4D2D3I3F1b==',
    creditLabel: false,
  });

  let chartType = '';
  let dataSource = {};

  switch (props.type) {
    case 'area':
      chartType = 'stackedcolumn2dline';

      dataSource = {
        chart: chartOptions.area,
        ...props.config,
      };
      break;
    case 'bubble':
      chartType = 'bubble';

      dataSource = {
        chart: chartOptions.bubble,
        ...props.config,
      };
      break;
    case 'bullet':
      chartType = 'hbullet';

      dataSource = {
        chart: chartOptions.bullet,
        ...props.config,
      };
      break;
    case 'heatmap':
      chartType = 'heatmap';
      dataSource = {
        chart: chartOptions.heatmap,
        ...props.config,
      };
      break;
    case 'horizontalBar':
      chartType = 'stackedbar2d';

      const dataSet = Object.values(props.config.dataset);
      const dataValue1 = dataSet[0].data[0].value ? dataSet[0].data[0].value : 0;
      const dataValue2 = dataSet[1].data[0].value ? dataSet[1].data[0].value : 0;
      const dataValue3 = dataSet[2].data[0].value ? dataSet[2].data[0].value : 0;

      chartOptions.horizontalBar['yAxisMaxValue'] = dataValue1 + dataValue2 + dataValue3;

      dataSource = {
        chart: chartOptions.horizontalBar,
        ...props.config,
      };
      break;
    case 'pie':
      chartType = 'doughnut2d';

      dataSource = {
        chart: chartOptions.pie,
        ...props.config,
      };
      break;
    case 'radar':
      chartType = 'radar';

      dataSource = {
        chart: chartOptions.radar,
        ...props.config,
      };
      break;
    case 'verticalBar':
      chartType = 'column2d';
      dataSource = {
        chart: chartOptions.verticalBar,
        ...props.config,
      };
      break;
    case 'msline':
      chartType = 'msline';
      dataSource = {
        chart: chartOptions.msline,
        ...props.config,
      };
      break;
  }

  return (
    <ReactFusioncharts
      type={chartType}
      width="100%"
      height={props.height ? props.height : 300}
      dataFormat="JSON"
      dataSource={dataSource}
    />
  );
};
