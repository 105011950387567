import { FormGroup } from '@angular/forms';

export function formChecker(formStepOrder: FormGroup[], stepStatus: any[]) {
  formStepOrder.forEach((form, index) => {
    const formIndex = index + 1;
    if (form.valid) {
      stepStatus[formIndex] = { ...stepStatus[formIndex], complete: true, currentStep: false };
      if (formIndex < formStepOrder.length)
        stepStatus[formIndex + 1] = { ...stepStatus[formIndex + 1], currentStep: true };
    } else {
      stepStatus[formIndex] = { ...stepStatus[formIndex], complete: false, currentStep: true };
      if (formIndex < formStepOrder.length)
        stepStatus[formIndex + 1] = { ...stepStatus[formIndex + 1], currentStep: false };
    }
  });
  return stepStatus;
}
