import styled from 'styled-components';
import { AmplifyScssStyledPort } from '../styles';

export const Container = styled(AmplifyScssStyledPort)`
  .resend-form {
    font-size: 16px;
    line-height: 1.25;
    -webkit-box-direction: normal;
    font-family: Lato, Helvetica, Arial, san-serif;
    height: auto;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #dbe0eb;
    width: 100%;
    max-width: 24rem;
    justify-self: center;
    padding: 1.5rem;
  }

  .wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    height: 100%;
    background: url(/assets/Login-Background.svg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    amplify-authenticator {
      flex: 1 100%;
    }
    app-amplify-auth-sign-in-core,
    app-amplify-auth-forgot-password-core {
      align-self: center;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .center {
      align-self: center;
    }

    .amplify-container {
      box-sizing: border-box;
      background-color: $white;
      border-radius: 6px;
      border: 1px solid $primaryBorder;
      padding: 0;
      width: 100%;
      max-width: 24rem;
      justify-self: center;
      padding: 1.5rem;
    }

    .amplify-form-row {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      justify-content: space-between;
    }

    .amplify-input-label {
      width: unset;
      flex: 1;
    }

    .amplify-form-input {
      max-width: 26rem;
      box-sizing: border-box;
      padding: 1.5rem 1rem;
      margin: 0;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $primaryBorder;
      flex: 5;
      font-size: 16px;
      color: $grey1;
    }

    .amplify-form-link {
      text-decoration: underline;
    }
    .amplify-form-link:hover {
      color: $primary;
    }

    .amplify-form-cell-right,
    .amplify-form-actions {
      text-align: right;
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    .amplify-forgot-password {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    .amplify-form-body {
      padding: 0;
    }

    .logo {
      margin-bottom: 2rem;
    }

    .amplify-form-actions {
      padding-bottom: 0;
    }

    .amplify-form-header {
      font-size: 22px;
    }
  }
`;
