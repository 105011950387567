import React, { useState, useEffect } from 'react';

import {
  GcvDataTable,
  GcvButton,
  GcvTimePeriodDropdown,
  GcvSearchDropDown,
  GcvZeroState,
  GcvInputSelectNew,
  GcvLoading,
} from '../../lib';
import { columns } from './ReportColumns';
import {
  Deposit,
  FilterRequest,
  FilterOptions,
  SearchAccountsFilter,
  MethodOfTransportFilter,
  StatusFilter,
  useFilter,
} from './ReportFilters';
import { DepositDetailDrawer } from './components';
import { Dictionary } from '@ngrx/entity';
import { DepositFilter, MinifiedDispensary, IANATimezones, User } from '@gcv/shared';
import useQueryString from '../../hooks/useQueryString';
import calculateTimeRange from '../../util/calculateDateRange';

interface Props {
  deposits: Deposit[];
  bankId: string;
  dispensaries: Dictionary<MinifiedDispensary>;
  userMap: Dictionary<User>;
  viewStatus: string;
  emitData: any;
  timezone: IANATimezones;
  loadingDepositsTable: boolean;
}

interface Option {
  value: string;
  label: string;
}

export const Deposits: React.FC<Props> = props => {
  const [deposits, setDeposits] = useState([]);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const [loadingDepositsTable, setLoadingDepositsTable] = useState<boolean>();
  const [loadingDeposits, setLoadingDeposits] = useState([]);
  const [timeSelectType, setTimeSelectType] = useState<DepositFilter>(DepositFilter.DateCreated);
  const [timeSelect, setTimeSelect] = useState<string>('last30Days');
  const [statusFilter, setStatusFilter] = useState('Pending');
  const [selectedDepositId, setSelectedDepositId] = useQueryString('id', undefined);
  const [distinctMots, setDistinctMots] = useState<Option[]>([{ value: 'All', label: 'All' }]);
  const [distinctAccounts, setDistinctAccounts] = useState<Option[]>([]);
  const [filterRequest, setFilterRequest] = useState<FilterRequest<Deposit>>({
    data: props.deposits,
    searchTerms: {},
  });

  const filterReport = useFilter(filterRequest, setFilterRequest, props.deposits);

  // initial populate deposits when props are ready
  useEffect(() => {
    setDeposits(props.deposits);
    filterReport({ value: statusFilter }, FilterOptions.Status);

    // find the distinct methods of transportation
    // for use in the filter drop down list
    const mots = findDistinctMots();
    setDistinctMots(mots);

    // find the distinct accounts
    // for use in the account search drop down list
    const accounts = findDistinctAccounts();
    setDistinctAccounts(accounts);

    loadingDeposits.forEach(loadingDeposit => {
      const updatedDeposit = props.deposits.find(d => d.id === loadingDeposit);
      if (updatedDeposit && updatedDeposit.status === 'pending') {
        updatedDeposit.reconciling = true;
      } else if (updatedDeposit && updatedDeposit.status === 'accepted') {
        setLoadingDeposits(loadingDeposits.filter(d => d != updatedDeposit.id));
      }
    });
  }, [props.deposits, props.loadingDepositsTable]);

  // watch for state changes in a report filter request
  useEffect(() => {
    handleSearchFilters();
  }, [filterRequest]);

  const fetchDeposits = async (timeSelect, timeSelectType) => {
    setLoadingDepositsTable(true);
    const timeRange = calculateTimeRange(timeSelect, props.timezone);
    const payload = {
      type: 'fetchDeposits',
      start: timeRange.start,
      end: timeRange.end,
      filter: timeSelectType,
    };
    props.emitData(payload);
  };

  const clearSelectedDepositId = () => {
    setSelectedDepositId('');
  };

  const handleRowClick = rowData => {
    setSelectedDepositId(rowData.deposit_id);
  };

  const handleSearchFilters = () => {
    const searchFilter = new SearchAccountsFilter(setFilteredDeposits);
    const motFilter = new MethodOfTransportFilter(setFilteredDeposits);
    const statusFilter = new StatusFilter(setFilteredDeposits);

    searchFilter.Next(motFilter);
    motFilter.Next(statusFilter);

    searchFilter.Handle(filterRequest);
    setLoadingDepositsTable(false);
  };

  const handleFilterAccounts = results => {
    filterReport({ value: results }, FilterOptions.SearchAccounts);
  };

  const findDistinctMots = (): Option[] => {
    const uniqueMots = [];
    const mots = [{ value: 'All', label: 'All' }];

    for (let i = 0; i < props.deposits.length; i++) {
      if (props.deposits[i].methodOfTransport && !uniqueMots[props.deposits[i].methodOfTransport]) {
        mots.push({ value: props.deposits[i].methodOfTransport, label: props.deposits[i].methodOfTransport });
        uniqueMots[props.deposits[i].methodOfTransport] = 1;
      }
    }

    return mots;
  };

  const findDistinctAccounts = (): Option[] => {
    const uniqueAccounts = [];
    const accounts = [];

    for (let i = 0; i < props.deposits.length; i++) {
      if (props.deposits[i].account && !uniqueAccounts[props.deposits[i].account]) {
        accounts.push({ value: props.deposits[i].dispensary_id, label: props.deposits[i].account });
        uniqueAccounts[props.deposits[i].account] = 1;
      }
    }

    return accounts;
  };

  const handleTimeTypeChange = e => {
    setTimeSelectType(e.value);
    fetchDeposits(timeSelect, e.value);
  };

  const handleTimeChange = e => {
    setTimeSelect(e.value);
    fetchDeposits(e.value, timeSelectType);
  };

  const updateDeposit = (deposit, type: 'updateDeposit' | 'acceptingDeposit' | 'acceptedDeposit') => {
    if (type === 'acceptingDeposit') {
      props.deposits.find(d => d.id === deposit.deposit_id).reconciling = true;
      setLoadingDeposits([...loadingDeposits, deposit.deposit_id]);
    } else if (type === 'acceptedDeposit') {
      props.emitData({ type: 'acceptDeposit', deposit: deposit });
    } else if (type === 'updateDeposit') {
      props.emitData({ type: 'updateDeposit', deposit: deposit });
    }
  };

  const updateStatusFilter = e => {
    setStatusFilter(e.value);
    filterReport({ value: e.value }, FilterOptions.Status);
  };

  const selectedDeposit = deposits.find(d => d.deposit_id === selectedDepositId);

  const statusOptions: Option[] = [
    { value: 'All', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Reconciled', label: 'Reconciled' },
    { value: 'under_review', label: 'Under Review' },
  ];

  const filterOptions: { value: DepositFilter; label: string }[] = [
    { value: DepositFilter.DateCreated, label: 'Created' },
    { value: DepositFilter.PlannedArrivalDate, label: 'Planned Arrival' },
    { value: DepositFilter.ArrivedDate, label: 'Arrived' },
  ];

  return (
    <>
      <h1 style={{ marginBottom: 0 }}>Deposits</h1>
      {props.viewStatus !== 'DefaultState' ? (
        <>
          <GcvZeroState
            headerText="You currently have no deposits to view"
            dashboardSubText="To begin accepting applications from cannabis businesses please finish setting up your company profile"
            type="basic"
            button={
              <GcvButton
                secondary={props.viewStatus === 'DueDiligenceInProgressState'}
                primary={props.viewStatus === 'DueDiligenceZeroState'}
                onClick={() => props.emitData({ type: 'ddButtonClicked' })}
              >
                {props.viewStatus === 'DueDiligenceZeroState' ? "Let's Go" : 'Continue'}
              </GcvButton>
            }
          ></GcvZeroState>
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              margin: '2rem 0 1rem 0',
              width: '100%',
            }}
          >
            <GcvSearchDropDown
              distinctItems={distinctAccounts}
              searchText={'Search Accounts'}
              itemLabel={'Account(s)'}
              filterReport={handleFilterAccounts}
            />
            <GcvTimePeriodDropdown
              selectableLabel={true}
              emitData={handleTimeChange}
              newStyle={true}
              labelOptions={filterOptions}
              onLabelChange={handleTimeTypeChange}
              includeAllOption={false}
              defaultValueDrop={'last30Days'}
            />
            <GcvInputSelectNew
              label="MOT"
              options={distinctMots}
              onChange={e => filterReport({ value: e.value }, FilterOptions.MethodOfTransport)}
              dropWidth={'175px'}
              labelWidth={'100px'}
              dropCharLength={20}
            />
            <GcvInputSelectNew
              label="Status"
              options={statusOptions}
              onChange={e => updateStatusFilter(e)}
              dropWidth={'175px'}
              labelWidth={'100px'}
              dropCharLength={20}
              defaultValueDrop={'Pending'}
            />
          </div>
          <div>
            {!loadingDepositsTable ? (
              <GcvDataTable
                data={filteredDeposits}
                columns={columns(handleRowClick)}
                onRowClicked={handleRowClick}
                keyField="react_key"
                defaultSortField="plannedArrivalDate"
                defaultSortAsc={false}
                subHeaderAlign="left"
                noDataComponent={
                  <GcvZeroState
                    type="bankDash"
                    headerText={'There are no deposits to show with the selected filters.'}
                    subText={'Please try changing your filters to show more records.'}
                  />
                }
              />
            ) : (
              <GcvLoading></GcvLoading>
            )}
          </div>

          <DepositDetailDrawer
            depositId={selectedDepositId}
            open={selectedDepositId && selectedDeposit}
            onClose={clearSelectedDepositId}
            emitData={updateDeposit}
            bankId={props.bankId}
            userMap={props.userMap}
            type={'bank'}
            dispensaryMap={props.dispensaries}
            depositStatus={selectedDeposit?.status}
          />
        </>
      )}
    </>
  );
};
