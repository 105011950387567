<gcv-card class="card" data-cy="editable-card.component">
  <div *ngIf="!editable" card-body class="staticCard" data-cy="editable-card.component.staticCard">
    <div class="editButtonWrapper">
      <span class="title" data-cy="editable-card.component.title">{{ title }}</span>
      <div *ngIf="showEdit">
        <div (click)="edit()" class="editIcon" data-cy="editable-card.component.editPermissions">
          <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 12v3.3c0 .2 0 .4.2.5l.5.2H4c.2 0 .3 0 .5-.2L15.8 4.5a1 1 0 0 0 0-1L12.5.2a.7.7 0 0 0-1 0L9.3 2.5l-9 9c-.2.1-.3.3-.3.5zM12 1.7L14.3 4 13 5.2 10.8 3 12 1.7zM1.4 12.3L9.8 4 12 6.2l-8.3 8.4H1.4v-2.3z"
              fill="#A5A8BA"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="formWrapper" data-cy="editable-card.component.formWrapper">
      <div *ngFor="let key of formKey" class="innerInputWrapper">
        <span class="innerInputText" data-cy="editable-card.component.innerInputText">{{ key.viewValue }}</span>
        <span class="innerInputValue" data-cy="editable-card.component.innerInputValue"
          >{{ key.prefixValue }}{{ data.controls[key.value].value }}{{ key.suffixValue }}</span
        >
        <div class="idButtonContainer margin-top">
          <gcv-button type="secondary full-width" buttonText="View ID" (clickEvent)="viewIdClicked()"></gcv-button>
        </div>
      </div>
    </div>
    <ng-content select="[card-body]"></ng-content>
  </div>

  <div *ngIf="editable" card-body class="editableCard" data-cy="editable-card.component.editableCard">
    <h2 class="title" data-cy="editable-card.component.title">{{ title }}</h2>
    <div class="contentWrapper">
      <div *ngFor="let key of formKey" class="inputWrapper" data-cy="editable-card.component.inputWrapper">
        <gcv-mat-input
          (blur)="onBlurCalled(key.value)"
          (focus)="onFocusCalled(key.value)"
          (value)="handleInput($event)"
          [control]="key.value"
          [errors]="tempData.controls[key.value].errors ? tempData.controls[key.value].errors : []"
          [group]="tempData"
          [label]="key.viewValue"
          [prefixValue]="key.prefixValue"
          [prefix]="key.prefix"
          [required]="key.required"
          [suffixValue]="key.suffixValue"
          [suffix]="key.suffix"
          [validators]="key.validatorText ? key.validatorText : []"
          data-cy="editable-card.component.app-mat-input"
          type="text"
        ></gcv-mat-input>
      </div>
      <div class="idButtonContainer margin-bottom margin-top">
        <gcv-button type="secondary full-width" buttonText="View ID" (clickEvent)="viewIdClicked()"></gcv-button>
      </div>
    </div>

    <div class="btnWrapper">
      <gcv-button
        (clickEvent)="cancel()"
        buttonText="Cancel"
        data-cy="editable-card.component.cancelButton"
        type="tertiary fullWidth"
      ></gcv-button>
      <gcv-button
        (clickEvent)="save()"
        [disabled]="!tempData.valid"
        buttonText="Save"
        data-cy="editable-card.component.saveButton"
        type="primary fullWidth"
      ></gcv-button>
    </div>
  </div>
</gcv-card>
