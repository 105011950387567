import React from 'react';

interface Props {
  onClick?: () => void;
}

export const EditIcon = (props: Props) => (
  <div
    onClick={props.onClick}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: props.onClick ? 'pointer' : '',
    }}
  >
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0164558 11.9998L0 15.2911C0 15.4766 0.0675108 15.662 0.202743 15.7973C0.337976 15.9325 0.505909 16 0.692196 16L3.96647 15.9835C4.15191 15.9835 4.32069 15.9158 4.45592 15.7808L15.7636 4.47232C16.0338 4.20291 16.0338 3.76367 15.7636 3.47675L12.523 0.202691C12.2528 -0.0675635 11.8144 -0.0675635 11.5277 0.202691L9.26628 2.48139L0.219199 11.5103C0.101266 11.6453 0.0164558 11.8143 0.0164558 11.9998ZM12.0336 1.68772L14.3123 3.96642L13.03 5.2487L10.7513 2.97L12.0336 1.68772ZM1.41752 12.3038L9.75488 3.96642L12.0336 6.24512L3.69621 14.5652L1.40106 14.5825L1.41752 12.3038Z"
        fill="#DBE1ED"
      />
    </svg>
  </div>
);
