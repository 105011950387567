import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { ExportActivityModalBody, customModalStyles } from '../styles';

interface Props {
  type: 'success' | 'failure';
  active: boolean;
  toggleModal: () => void;
  headerText?: string;
  bodyText?: string;
  buttonComponent?: React.ReactNode;
}

const styles = {
  ...customModalStyles,
  content: { ...customModalStyles.content, width: '456px', height: '268px' },
};

export const ExportActivityResponseModal = (props: Props) => {
  useEffect(() => {
    Modal.setAppElement('#modal-root');
  }, []);

  return (
    <Modal style={styles} isOpen={props.active} onRequestClose={props.toggleModal}>
      <ExportActivityModalBody>
        <h2>{props.headerText}</h2>
        <p>{props.bodyText}</p>

        <div className="button-container">{props.buttonComponent}</div>
      </ExportActivityModalBody>
    </Modal>
  );
};
