<div class="overviewWrapper" data-cy="initial-dashboard.component">
  <div class="contentWrapper">
    <div class="imgWrapper">
      <svg *ngIf="!logo" fill="none" height="76" width="76" xmlns="http://www.w3.org/2000/svg">
        <path d="M38 75a37 37 0 1 0 0-74 37 37 0 0 0 0 74z" fill="#F9FAFD" stroke="#E0E5F0" />
        <g clip-path="url(#clip0)">
          <path d="M59.1 52.3h-42V56h42v-3.6z" fill="#F2F2F2" />
          <path
            d="M59.1 22.5h-42v33.6h42V22.5z"
            stroke="#00BC66"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.8"
          />
          <path
            d="M60.2 18.9H16.4v3.7h43.8V19z"
            stroke="#00BC66"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.8"
          />
          <path
            d="M17.2 52.4h42M17.2 30h42M14.9 56H61"
            stroke="#00BC66"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.8"
          />
          <path d="M33.5 38.6h-10V56h10V38.6z" fill="#F2F2F2" />
          <path
            d="M33.5 38.6h-9.8V56h9.8V38.6zM29.8 49.1v-2.9M54.2 39H38.3v8.6h15.9V39z"
            stroke="#00BC66"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.8"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path d="M0 0h48v39H0z" fill="#fff" transform="translate(14 18)" />
          </clipPath>
        </defs>
      </svg>
      <img *ngIf="logo" [src]="logo" alt="placeholder" />
    </div>
    <div class="contentWrapper">
      <span class="contentTitle" data-cy="initial-dashboard.component.contentTitle">{{ title }}</span>
      <span class="contentSubTitle">{{ subTitle }}</span>
    </div>
    <div class="btnWrapper">
      <gcv-button
        (clickEvent)="handleButtonClicked()"
        [buttonText]="buttonText"
        data-cy="initial-dashboard.component.button"
        type="fullWidth large primary"
      ></gcv-button>
    </div>
  </div>
</div>
