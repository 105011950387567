import { Component, Input } from '@angular/core';
import { Bank } from '@gcv/shared';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'gcv-bank-status-indicator',
  templateUrl: './bank-status-indicator.component.html',
  styleUrls: ['./bank-status-indicator.component.scss'],
})
export class BankStatusIndicatorComponent {
  @Input() hasBank: boolean;
  @Input() bank: Bank;
  @Input() logo: string;
  imagePath;
  logoTextStyles = {};

  public expanded = false;
  dayArray = [
    { value: 'monday', viewValue: 'Mon' },
    { value: 'tuesday', viewValue: 'Tue' },
    { value: 'wednesday', viewValue: 'Wed' },
    { value: 'thursday', viewValue: 'Thu' },
    { value: 'friday', viewValue: 'Fri' },
    { value: 'saturday', viewValue: 'Sat' },
    { value: 'sunday', viewValue: 'Sun' },
  ];
  constructor(private _sanitizer: DomSanitizer) {}

  ngOnChanges() {
    if (this.bank && this.bank.theme && this.logo) {
      //@ts-ignore
      this.logo.text().then(text => {
        this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64,${text}`);
        this.logoTextStyles = { color: this.bank.theme.color };
      });
    } else if (this.bank && !this.bank.theme) {
      this.imagePath = null;
      this.logoTextStyles = {};
    }
  }

  expandToolTip() {
    if (this.hasBank) {
      this.expanded = !this.expanded;
    }
  }
}
