import { Action } from '@ngrx/store';
import {
  AggregateRequestPayload,
  BankAggregate,
  DispensaryAggregate,
  FincenDepositAggregate,
  FincenReportAggregate,
  SalesAggregate,
  AggregatesRequestPayload,
} from '../../typings/aggregates';

export enum AggregatesActionTypes {
  ResetAggregates = '[Aggregates] Reset Aggregates',

  LoadBankAggregate = '[Aggregates] Load Bank Aggregate',
  BankAggregateLoaded = '[Aggregates] Bank Aggregate Loaded',

  LoadSalesAggregate = '[Aggregates] Load Sales Aggregate',
  SalesAggregateLoaded = '[Aggregates] Sales Aggregate Loaded',

  LoadSalesAggregates = '[Aggregates] Load Sales Aggregates',
  SalesAggregatesLoaded = '[Aggregates] Sales Aggregates Loaded',

  LoadFincenDepositAggregate = '[Aggregates] Load Fincen Deposit Aggregate',
  FincenDepositAggregateLoaded = '[Aggregates] Fincen Deposit Aggregate Loaded',

  LoadFincenReportAggregate = '[Aggregates] Load Fincen Report Aggregate',
  FincenReportAggregateLoaded = '[Aggregates] Fincen Report Aggregate Loaded',

  LoadDispensaryAggregate = '[Aggregates] Load Dispensary Aggregate',
  DispensaryAggregateLoaded = '[Aggregates] Dispensary Aggregate Loaded',

  LoadDispensaryAggregates = '[Aggregates] Load Dispensary Aggregates',
  DispensaryAggregatesLoaded = '[Aggregates] Dispensary Aggregates Loaded',

  LoadSarReportPeriodAggregate = '[Aggregates] Load SAR Report Period Aggregate',
  SarReportPeriodAggregateLoaded = '[Aggregates] SAR Report Period Aggregate Loaded',
}

export class ResetAggregates implements Action {
  readonly type = AggregatesActionTypes.ResetAggregates;
}

export class LoadBankAggregate implements Action {
  readonly type = AggregatesActionTypes.LoadBankAggregate;

  constructor(public payload: AggregateRequestPayload) {}
}

export class BankAggregateLoaded implements Action {
  readonly type = AggregatesActionTypes.BankAggregateLoaded;

  constructor(public payload: { id: string; data: BankAggregate }) {}
}

export class LoadSalesAggregate implements Action {
  readonly type = AggregatesActionTypes.LoadSalesAggregate;

  constructor(public payload: AggregateRequestPayload) {}
}

export class SalesAggregateLoaded implements Action {
  readonly type = AggregatesActionTypes.SalesAggregateLoaded;

  constructor(public payload: { id: string; data: SalesAggregate }) {}
}

export class LoadSalesAggregates implements Action {
  readonly type = AggregatesActionTypes.LoadSalesAggregates;

  constructor(public payload: AggregatesRequestPayload) {}
}

export class SalesAggregatesLoaded implements Action {
  readonly type = AggregatesActionTypes.SalesAggregatesLoaded;

  constructor(public payload: { aggregates: { id: string; data: SalesAggregate }[] }) {}
}

export class LoadFincenDepositAggregate implements Action {
  readonly type = AggregatesActionTypes.LoadFincenDepositAggregate;

  constructor(public payload: AggregateRequestPayload) {}
}

export class FincenDepositAggregateLoaded implements Action {
  readonly type = AggregatesActionTypes.FincenDepositAggregateLoaded;

  constructor(public payload: { id: string; data: FincenDepositAggregate }) {}
}

export class LoadFincenReportAggregate implements Action {
  readonly type = AggregatesActionTypes.LoadFincenReportAggregate;

  constructor(public payload: AggregateRequestPayload) {}
}

export class FincenReportAggregateLoaded implements Action {
  readonly type = AggregatesActionTypes.FincenReportAggregateLoaded;

  constructor(public payload: FincenReportAggregate[]) {}
}
export class LoadSarReportPeriodAggregate implements Action {
  readonly type = AggregatesActionTypes.LoadSarReportPeriodAggregate;

  constructor(public payload: AggregateRequestPayload) {}
}

export class SarReportPeriodAggregateLoaded implements Action {
  readonly type = AggregatesActionTypes.SarReportPeriodAggregateLoaded;

  constructor(public payload: { data: DispensaryAggregate[] }) {}
}

export class LoadDispensaryAggregate implements Action {
  readonly type = AggregatesActionTypes.LoadDispensaryAggregate;

  constructor(public payload: AggregateRequestPayload) {}
}

export class DispensaryAggregateLoaded implements Action {
  readonly type = AggregatesActionTypes.DispensaryAggregateLoaded;

  constructor(public payload: { id: string; data: DispensaryAggregate }) {}
}

export class LoadDispensaryAggregates implements Action {
  readonly type = AggregatesActionTypes.LoadDispensaryAggregates;

  constructor(public payload: AggregatesRequestPayload) {}
}

export class DispensaryAggregatesLoaded implements Action {
  readonly type = AggregatesActionTypes.DispensaryAggregatesLoaded;

  constructor(public payload: { aggregates: { id: string; data: DispensaryAggregate }[] }) {}
}

export type AggregateActions =
  | ResetAggregates
  | LoadBankAggregate
  | BankAggregateLoaded
  | LoadSalesAggregate
  | SalesAggregateLoaded
  | LoadSalesAggregates
  | SalesAggregatesLoaded
  | LoadFincenDepositAggregate
  | FincenDepositAggregateLoaded
  | LoadFincenReportAggregate
  | FincenReportAggregateLoaded
  | LoadDispensaryAggregate
  | DispensaryAggregateLoaded
  | LoadSarReportPeriodAggregate
  | SarReportPeriodAggregateLoaded;
