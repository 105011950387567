<gcv-data-table
  (deleteClicked)="handleDeleteClicked($event)"
  (rowClicked)="handleRowClicked($event)"
  *ngIf="documents; else loading"
  [clickable]="true"
  [clickable]="editable"
  [columnNames]="displayedColumns"
  [dataMap]="dataMap"
  [data]="documents"
  [deletable]="true"
  class="dataTable"
></gcv-data-table>

<ng-template #loading>
  <gcv-spinner-icon [ngStyle]="{ 'align-self': 'center' }"></gcv-spinner-icon>
</ng-template>
