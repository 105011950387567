import React, { useEffect, useState, useRef } from 'react';
import { timePeriodOptions } from '../../util/calculateDateRange';
import { GcvInputSelect } from '../GcvInputSelect/GcvInputSelect';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';
import { CalendarContainer } from './styles';
import { GcvInputSelectNew } from '../GcvInputSelectNew/GcvInputSelect';

interface Props {
  emitData: (value) => void;
  value?: { value: string; label: string };
  newStyle?: boolean;
  filterOptions?: { value: string; label: string }[];
  labelOptions?: { value: string; label: string }[];
  onLabelChange?: (value) => void;
  includeAllOption?: boolean;
  defaultValueDrop?: string;
  defaultValueLabel?: string;
  selectableLabel?: boolean;
  labelText?: string;
}

export const GcvTimePeriodDropdown = (props: Props) => {
  const form = useForm();
  const calendarRef = useRef();
  const [calendarValues, setCalendarValues] = useState([]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [options, setOptions] = useState(
    props.includeAllOption ? [{ value: 'all', label: 'All' }, ...timePeriodOptions] : timePeriodOptions
  );

  const onChange = data => {
    if (data && data.value !== 'custom') {
      props.emitData(data);
    } else if (data && data.value === 'custom') {
      setCalendarOpen(true);
    }
  };

  useEffect(() => {
    form.setValue('timePeriod', props.value);
  }, [props.value]);

  const convertDateShort = date =>
    DateTime.fromISO(new Date(date).toISOString()).toLocaleString({
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  const convertDateISO = date => new Date(date).toISOString();

  const selectCustom = values => {
    setCalendarOpen(false);
    const newCustomOptions = [...options];
    const selectedCustomValue = `${convertDateShort(values[0])} - ${convertDateShort(values[1])}`;
    newCustomOptions[newCustomOptions.length - 1].label = selectedCustomValue;
    setOptions(newCustomOptions);
    props.emitData({ value: `${convertDateISO(values[0])} - ${convertDateISO(values[1])}`, label: 'custom' });
  };

  const onClick = date => {
    const calendarVals = calendarValues.length == 2 ? [] : [...calendarValues];
    if (calendarVals.length <= 1) {
      setCalendarValues([...calendarVals, date]);
      const newCustomOptions = [...options];
      const selectedCustomValue = `${convertDateShort(date)} - `;
      newCustomOptions[newCustomOptions.length - 1].label = selectedCustomValue;
      setOptions(newCustomOptions);
    }
  };

  const handleCalendarBlur = e => {
    if (calendarRef) {
      // @ts-ignore
      if (!calendarRef.current.contains(e.target)) {
        const existingCustomOption = options.find(o => o.value === 'custom');
        //gs-4733: Maintain custom option
        if (existingCustomOption.label !== 'Custom' && existingCustomOption.label.split('-')[1]?.trim()) {
          const startDate = convertDateISO(existingCustomOption.label.split('-')[0].trim());
          const endDate = DateTime.fromISO(convertDateISO(existingCustomOption.label.split('-')[1].trim()))
            .endOf('day')
            .toISO();
          props.emitData({
            value: `${startDate} - ${endDate}`,
            label: 'custom',
          });
        }
        setCalendarOpen(false);
      }
    }
  };

  useEffect(() => {
    if (calendarOpen) {
      document.addEventListener('mousedown', handleCalendarBlur);
      return () => {
        document.removeEventListener('mousedown', handleCalendarBlur);
      };
    }
  }, [calendarOpen]);

  return (
    <div style={{ width: props.newStyle ? '' : '250px' }} data-cy="timefilter.component">
      {props.newStyle ? (
        <GcvInputSelectNew
          label={props.labelText}
          options={options}
          onChange={e => {
            onChange({ value: e.value });
          }}
          dropWidth={'175px'}
          labelWidth={'125px'}
          labelCharLength={8}
          dropCharLength={20}
          selectableLabel={props.selectableLabel}
          labelOptions={props.labelOptions}
          onLabelChange={e => {
            props.onLabelChange({ value: e.value });
          }}
          defaultValueDrop={props.defaultValueDrop}
          defaultValueLabel={props.defaultValueLabel}
        />
      ) : (
        <GcvInputSelect
          label="Select Time Period"
          options={options}
          name="timePeriod"
          {...form}
          noWarning={true}
          onChangeCallback={onChange}
        />
      )}
      {calendarOpen ? (
        <CalendarContainer ref={calendarRef}>
          <Calendar selectRange={true} onChange={selectCustom} calendarType={'US'} onClickDay={onClick} />
        </CalendarContainer>
      ) : null}
    </div>
  );
};
