import React, { useState } from 'react';
import { Tab, TabContainer, CurrentTabContainer, NameContainer, IndexContainer } from './styles';

interface Tab {
  component: any;
  name: string;
}

interface Props {
  tabs: Tab[];
  updateData: (data) => void;
  onComplete: () => void;
  onCancel: () => void;
  data: any;
}

export const GcvTabWizard = ({ tabs, updateData, onComplete, onCancel, data }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [latestComplete, setLatestComplete] = useState(0);

  const next = dataForNextCallback => {
    if (currentIndex < tabs.length - 1) {
      updateData(dataForNextCallback);
      setCurrentIndex(currentIndex + 1);
    } else {
      updateData(dataForNextCallback);
      onComplete();
    }
    setLatestComplete(latestComplete + 1);
  };

  const back = dataForBackCallback => {
    if (currentIndex > 0) {
      updateData(dataForBackCallback);
      setCurrentIndex(currentIndex - 1);
    } else {
      updateData(dataForBackCallback);
      onCancel();
    }
  };

  const renderTabs = () => {
    return tabs.map(({ name }, index) => (
      <Tab key={name}>
        <IndexContainer complete={index < currentIndex} data-testid="tab" />
        <NameContainer current={index === currentIndex} data-testid="name">
          {name}
        </NameContainer>
      </Tab>
    ));
  };

  const CurrentTab = tabs[currentIndex].component;

  return (
    <>
      <TabContainer data-cy="tab-container">{renderTabs()}</TabContainer>
      <CurrentTabContainer data-cy="current-tab-content">
        <CurrentTab next={next} back={back} data={data} />
      </CurrentTabContainer>
    </>
  );
};
