import * as React from 'react';
import { Container } from './styles';
import { EmailUtil } from '@gcv/shared';
import { useApi } from '../../hooks/useApi';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { useState } from 'react';
import { GcvUserInputContainer } from '../../lib/GcvUserInputContainer/GcvUserInputContainer';
import { GcvButton } from '../../lib/GcvButton/GcvButton';

interface Props {
  email?: string;
}

/**
 * @param props
 * @constructor
 */
export const ResendUserInvite = (props: Props) => {
  const { email } = props;
  const isValidEmail = EmailUtil.isValidEmail(email);
  const { API } = useApi();
  const [hasEmailBeenResent, setHasEmailBeenResent] = useState(false);
  const [isRequestInFlight, setIsRequestInFlight] = useState(false);
  const [didRequestError, setDidRequestError] = useState(false);

  const resendEmail = () => {
    setIsRequestInFlight(true);
    setDidRequestError(false);
    API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/auth/resend-invitation-email`, {
      queryStringParameters: {
        email,
      },
    })
      .then(() => {
        setHasEmailBeenResent(true);
      })
      .catch(() => {
        setDidRequestError(true);
      })
      .finally(() => {
        setIsRequestInFlight(false);
      });
  };

  return (
    <Container>
      <div className="resend-form">
        <div className="amplify-container">
          <img className="logo" src="../../assets/gcv_logo_alt.png" width="150px"></img>
          <div className="amplify-form-container">
            <div className="amplify-form-body">
              <div className="amplify-form-header">Request Confirmation Email</div>
              <div>
                <b>Email: </b>
                <GcvUserInputContainer string={email} length={30} />
              </div>
              <br />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <GcvButton onClick={resendEmail} disabled={!isValidEmail || isRequestInFlight} primary={true}>
                  Request
                </GcvButton>
              </div>
              <br />
              {!isValidEmail ? (
                <div>
                  <br />
                  <div>Warning, email is invalid.</div>
                </div>
              ) : null}
              {hasEmailBeenResent ? (
                <div>
                  <br />
                  <div>A confirmation email has been resent. Please check your inbox for further instructions.</div>
                </div>
              ) : null}
              {didRequestError ? (
                <div>
                  <br />
                  <div>
                    There was an error resending your confirmation email. Please reach out to{' '}
                    <a rel="noopener noreferrer" target="_blank" href="mailto:support@greencheckverified.com">
                      support@greencheckverified.com
                    </a>{' '}
                    and let them know.
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
