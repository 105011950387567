<div class="card">
  <div class="title">
    <h4>{{ document }}</h4>
  </div>
  <div class="subtitle">
    <span>
      {{ description }}
    </span>
  </div>
  <div *ngIf="spinnerReady && isUploading && !noDocument" class="button-wrapper">
    <div class="spinner"></div>
  </div>
  <div *ngIf="!isUploaded && (!spinnerReady || !isUploading)" class="button-wrapper">
    <gcv-button (clickEvent)="handleUpload()" buttonText="Start"></gcv-button>
  </div>
  <div *ngIf="isUploaded && (!spinnerReady || !isUploading || noDocument)" class="button-wrapper">
    <div class="fileWrapper">
      <svg
        *ngIf="!multipleDocs && !noDocument"
        fill="none"
        height="18"
        viewBox="0 0 14 18"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.38614 3.28775V0.313477L0.322266 3.28775H3.38614Z" fill="#02B45B" />
        <path
          d="M13.4576 0H4.45654V3.8139C4.45654 4.09852 4.20733 4.34045 3.91414 4.34045H0V16.8068C0 17.0914 0.249215 17.3333 0.542408 17.3333H13.4576C13.7508 17.3333 14 17.0914 14 16.8068V0.526546C14 0.241927 13.7508 0 13.4576 0ZM4.10471 5.62124H9.88063C10.1738 5.62124 10.423 5.86316 10.423 6.14778C10.423 6.4324 10.1738 6.67433 9.88063 6.67433H4.10471C3.81152 6.67433 3.5623 6.4324 3.5623 6.14778C3.5623 5.86316 3.81152 5.62124 4.10471 5.62124ZM4.10471 7.58511H9.88063C10.1738 7.58511 10.423 7.82704 10.423 8.11166C10.423 8.39628 10.1738 8.6382 9.88063 8.6382H4.10471C3.81152 8.6382 3.5623 8.39628 3.5623 8.11166C3.5623 7.81281 3.81152 7.58511 4.10471 7.58511ZM4.10471 9.53476H9.88063C10.1738 9.53476 10.423 9.77668 10.423 10.0613C10.423 10.3459 10.1738 10.5878 9.88063 10.5878H4.10471C3.81152 10.5878 3.5623 10.3459 3.5623 10.0613C3.5623 9.77668 3.81152 9.53476 4.10471 9.53476ZM4.10471 11.4986H8.45864C8.75183 11.4986 9.00105 11.7406 9.00105 12.0252C9.00105 12.3098 8.75183 12.5517 8.45864 12.5517H4.10471C3.81152 12.5517 3.5623 12.3098 3.5623 12.0252C3.5623 11.7263 3.81152 11.4986 4.10471 11.4986Z"
          fill="#02B45B"
        />
      </svg>
      <svg
        *ngIf="multipleDocs && !noDocument"
        fill="none"
        height="15"
        viewBox="0 0 17 15"
        width="17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2455 2.71135H6.40269L6.17814 1.55173C6.14535 1.39214 6.07852 1.25232 5.98752 1.15296C5.89653 1.05359 5.78622 0.999962 5.6729 1H3.21184C3.09852 0.999962 2.9882 1.05359 2.89721 1.15296C2.80622 1.25232 2.73939 1.39214 2.7066 1.55173L2.47082 2.71135H1.7545C1.65534 2.71092 1.55709 2.73933 1.46541 2.79496C1.37374 2.85059 1.29045 2.93234 1.22033 3.03551C1.15021 3.13868 1.09465 3.26122 1.05684 3.3961C1.01903 3.53097 0.999707 3.67552 1 3.82142V12.659C0.999707 12.8049 1.01903 12.9494 1.05684 13.0843C1.09465 13.2192 1.15021 13.3417 1.22033 13.4449C1.29045 13.5481 1.37374 13.6298 1.46541 13.6855C1.55709 13.7411 1.65534 13.7695 1.7545 13.7691H15.2455C15.3447 13.7695 15.4429 13.7411 15.5346 13.6855C15.6263 13.6298 15.7095 13.5481 15.7797 13.4449C15.8498 13.3417 15.9053 13.2192 15.9432 13.0843C15.981 12.9494 16.0003 12.8049 16 12.659V3.82142C16.0003 3.67552 15.981 3.53097 15.9432 3.3961C15.9053 3.26122 15.8498 3.13868 15.7797 3.03551C15.7095 2.93234 15.6263 2.85059 15.5346 2.79496C15.4429 2.73933 15.3447 2.71092 15.2455 2.71135Z"
          fill="#00BC66"
          stroke="#00BC66"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span [attr.data-cy]="'due-diligence-requirement-card.component.fileName'" id="fileName">{{
        (!noDocument ? filename : 'No Document Uploaded') | fileNamePipe
      }}</span>
    </div>
    <span [attr.data-cy]="'due-diligence-requirement-card.component.edit'" (click)="handleEdit()" class="edit"
      >Edit</span
    >
  </div>
</div>
