import { select, Store } from '@ngrx/store';
import { UserState } from './user.reducer';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { User } from '@gcv/shared';
import {
  DeleteUser,
  LoadUser,
  LoadUserKba,
  ResetUser,
  ResetUserKba,
  UpdateUser,
  UpdateUserDocuments,
  UpdateUserIdentification,
  UserUpdated,
  VerifyUserKbaAnswers,
} from './user.actions';
import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { selectKbaQuestions, selectUserEntities } from './user.selectors';

export interface IUserFacade {
  getUserById(userId: string): any;

  putUserById(user: User): void;

  selectCurrentUser(): any;

  verifyUserByKba(userId: string): any;

  verifyUserKbaAnswers(userId: string): any;

  updateUserDocuments(userId: string): void;

  resetUserKba(): void;

  resetUser(): void;
}

@Injectable({
  providedIn: 'root',
})
export class UserFacade implements IUserFacade {
  user$: Observable<Dictionary<User>>;

  constructor(private store: Store<UserState>) {
    this.user$ = store.pipe(select(selectUserEntities));
  }

  getUserById(userId: string, initialLogin: Boolean = false, authUser: any = null) {
    this.store.dispatch(new LoadUser({ userId, initialLogin, authUser }));
    return this.user$.pipe(map(userDictionary => userDictionary[userId]));
  }

  putUserById(user: User) {
    this.store.dispatch(new UpdateUser(user));
  }

  // Update user in the store directly without needing to make a PUT request and wait for response.
  // Should be used only after receiving an updatedUser from the api
  // e.g after accepting terms of service
  updateUserOptimistic(user: User) {
    this.store.dispatch(new UserUpdated(user));
    return this.getUserById(user.id);
  }

  selectCurrentUser() {
    return this.user$.pipe(map(userDictionary => Object.values(userDictionary)[0]));
  }

  verifyUserByKba(userId: string) {
    this.store.dispatch(new LoadUserKba({ userId }));
    return this.store.pipe(
      select(selectKbaQuestions),
      filter((questions: any) => questions !== null)
    );
  }

  verifyUserKbaAnswers(userId: string) {
    this.store.dispatch(new VerifyUserKbaAnswers({ userId }));
    return this.user$.pipe(map(userDictionary => userDictionary[userId]));
  }

  updateUserDocuments(userId: string) {
    this.store.dispatch(new UpdateUserDocuments({ userId }));
  }

  resetUserKba() {
    this.store.dispatch(new ResetUserKba());
  }

  resetUser() {
    this.store.dispatch(new ResetUser());
  }

  deleteUser(userId: string) {
    this.store.dispatch(new DeleteUser({ userId }));
  }

  updateUserIdentification(userId: string, identificationDetails: any) {
    this.store.dispatch(new UpdateUserIdentification({ userId, identificationDetails }));
  }
}
