import styled from 'styled-components';
import { $grey1, $grey9, $grey5, $blue, $white, $grey11, $black2 } from '../../../util/styleVariables';

interface Props {
  type?: 'warning' | 'red_flag';
  height?: string;
}

const colorMap = {
  warning: { background: 'rgba(244, 155, 32, 0.25)', border: '1px solid #F49B20' },
  red_flag: { background: 'rgba(255, 78, 80, 0.25)', border: '1px solid #ff4447' },
};

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${$grey1};
  padding: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 90px;
  background-color: ${$white};
  flex-shrink: 0;
`;

export const HeaderContent = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

export const Spacer = styled.div`
  margin: 20px 0px;
`;

export const Body = styled.div`
  padding: 0px 50px 50px 50px;
  overflow-y: auto;
`;

export const SubTitle = styled.div`
  margin-top: 18px;
  font-size: 13px;
  line-height: 16px;
  color: ${$grey9};
`;

export const SubContent = styled.div<Props>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 2px 0px;
  ${props => props.height};
`;

export const ReadMoreButton = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${$blue};
  &: hover {
    cursor: pointer;
  }
`;

export const UserInput = styled.div`
  border: none;
`;

export const Block = styled.div`
  padding: 10px 0px 20px 0px;
  border-bottom: 1px solid ${$grey11};
`;

export const Footer = styled.div`
  border-top: 1px solid ${$grey1};
  padding: 25px;
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${$white};
  flex-shrink: 0;
`;

export const WarningsExceptionsContainer = styled.div`
  margin-top: 2rem;
  padding: 0 20px;
`;

export const WarningExceptionLabel = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 28px;
  ${props => ({ ...colorMap[props.type] })};
  border-radius: 15px;
  color: ${$black2};
  font-size: 14px;
  line-height: 16px;
`;

export const ExceptionsList = styled.ul`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.25px;
  padding-inline-start: 20px;
`;

export const DrawerContent = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
`;
