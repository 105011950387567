import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { Bank, DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE, DueDiligenceRequirementTemplate } from '@gcv/shared';

import { useApi, getEndpoint } from '../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { $grey2, formatISOToDateAtTime } from '../../util';
import { GcvDataTable, GcvZeroState, GcvDrawer, GcvButton, GcvCheckbox } from '../../lib';

import {
  Container,
  Header,
  Description,
  DescriptionContainer,
  SubContainer,
  ArchiveContainer,
  Archive,
  ArchiveText,
} from './styles';
import { requirement, category } from './columns';
import { TemplateDrawer } from './TemplateDrawer';
import { VerticalCenter } from '../../lib/GcvStatusIndicator/styles';
import { api } from '../../api';

interface Props {
  bank: Bank;
  emitData: (type) => void;
}

const gcvDueDiligenceRequirements = () => {
  const reqs = JSON.parse(JSON.stringify(DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE.documentation.categories));
  reqs.legal.push(
    {
      name: 'Business Licenses',
      description:
        'A business may have multiple licenses issued by the state that allows them to conduct business activities in different business categories.',
      category: 'legal',
    },
    {
      name: 'Employee Licenses',
      description: 'An employee license establishes that an employee is legally allowed to work at the business.',
      category: 'legal',
    }
  );
  return reqs;
};

const flattenDueDiligenceRequirements = (categories: { [id: string]: DueDiligenceRequirementTemplate[] }) => {
  if (!categories) {
    return [];
  }
  const customReqs = [];
  Object.entries(categories).forEach(([category, template]) => {
    template.forEach(req => {
      req.category = category;
      customReqs.push(req);
    });
  });
  return customReqs;
};

export const BankDueDiligence = ({ bank, emitData }: Props) => {
  const apiClient = api();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewArchived, setViewArchived] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<DueDiligenceRequirementTemplate>(null);
  const [toggleClearRows, setToggleClearRows] = useState<boolean>(false);
  const [drawerType, setDrawerType] = useState<'custom' | 'standard'>(null);

  const [selectedArchivedRows, setSelectedArchivedRows] = useState([]);
  const [selectedActiveRows, setSelectedActiveRows] = useState([]);

  const onSelectedRowsChange = props => {
    viewArchived ? setSelectedArchivedRows(props.selectedRows) : setSelectedActiveRows(props.selectedRows);
  };

  const updateData = () => {
    emitData('getRequirements');
  };

  const toggleDrawer = (type?: 'custom' | 'standard') => {
    setDrawerOpen(!drawerOpen);
    setDrawerType(type);
  };

  const toggleArchived = () => setViewArchived(!viewArchived);

  const standardReqs = flattenDueDiligenceRequirements(gcvDueDiligenceRequirements());

  const customReqs = bank.due_diligence_template.documentation
    ? flattenDueDiligenceRequirements(bank.due_diligence_template.documentation.categories)
    : [];
  const archivedReqs = customReqs.filter(req => req.archived);
  const activeReqs = customReqs.filter(req => !req.archived);

  const handleRowClick = (row, type) => {
    toggleDrawer(type);
    setCurrentTemplate(row);
  };

  const addNewRequirement = () => {
    setCurrentTemplate(null);
    toggleDrawer('custom');
  };

  const columnsCustom = [requirement('Requirement', handleRowClick, 'custom'), category('Type')];
  const columnsStandard = [requirement('Requirement', handleRowClick, 'standard'), category('Type')];

  const headerText = 'There are currently no custom requirements';

  const setReqStatus = () => {
    const requirement_ids = viewArchived
      ? selectedArchivedRows.map(req => req.requirement_id)
      : selectedActiveRows.map(req => req.requirement_id);
    const status = viewArchived ? 'active' : 'archived';
    saveReqStatus(status, requirement_ids);
  };

  const saveReqStatus = async (status, requirement_ids) => {
    await apiClient.banks
      .updateTemplateRequirementStatus(bank.id, { status, requirement_ids }, () => {})
      .then(() => {
        updateData();
        resetState();
      })
      .catch(e => {});
  };

  const resetState = () => {
    setToggleClearRows(!toggleClearRows);
    setSelectedArchivedRows([]);
    setSelectedActiveRows([]);
  };

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  return (
    <>
      <Container>
        <SubContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Header>Custom Requirements</Header>
            <VerticalCenter>
              <GcvButton primary={true} onClick={addNewRequirement}>
                Add New Requirement
              </GcvButton>
            </VerticalCenter>
          </div>
          <DescriptionContainer>
            <Description>
              These are requirements that your institution currently asks for from all of your cannabis businesses. You
              can add or archive requirements at any time.
            </Description>
            <ArchiveContainer>
              <div>
                {viewArchived && selectedArchivedRows.length > 0 ? (
                  <GcvButton primary={true} onClick={setReqStatus}>
                    Restore Requirements to Active
                  </GcvButton>
                ) : null}
                {!viewArchived && selectedActiveRows.length > 0 ? (
                  <GcvButton primary={true} onClick={setReqStatus}>
                    Archive Requirements
                  </GcvButton>
                ) : null}
              </div>
              <Archive onClick={toggleArchived}>
                <span>
                  Switch To:
                  <ArchiveText>
                    {viewArchived
                      ? `Active Requirements (${activeReqs.length})`
                      : `Archived Requirements (${archivedReqs.length})`}
                  </ArchiveText>
                </span>
              </Archive>
            </ArchiveContainer>
          </DescriptionContainer>
          <GcvDataTable
            data={viewArchived ? archivedReqs : activeReqs}
            columns={columnsCustom}
            onRowClicked={row => {
              handleRowClick(row, 'custom');
            }}
            defaultSortField={'type'}
            defaultSortAsc={false}
            keyField="requirement_id"
            noDataComponent={<GcvZeroState type="basic" headerText={headerText} />}
            subHeaderAlign="left"
            paginationPerPage={5}
            selectableRows={true}
            onSelectedRowsChange={onSelectedRowsChange}
            selectableRowsComponent={renderSelectComponent}
            clearSelectedRows={toggleClearRows}
          />
        </SubContainer>
        <SubContainer>
          <Header>Standard Requirements</Header>
          <DescriptionContainer>
            <Description>
              These are requirements that Green Check asks for from all cannabis businesses. You don't have to do
              anything here.
            </Description>
          </DescriptionContainer>
          <GcvDataTable
            data={standardReqs}
            columns={columnsStandard}
            onRowClicked={row => {
              handleRowClick(row, 'standard');
            }}
            defaultSortField={'type'}
            defaultSortAsc={false}
            keyField="name"
            subHeaderAlign="left"
            paginationPerPage={5}
          />
        </SubContainer>
      </Container>
      <TemplateDrawer
        open={drawerOpen}
        onClose={toggleDrawer}
        template={currentTemplate}
        updateReqStatus={saveReqStatus}
        bank={bank}
        updateData={updateData}
        drawerType={drawerType}
      ></TemplateDrawer>
    </>
  );
};
