import { useReducer } from 'react';

const INITIAL_STATE = {
  loading: false,
  result: null,
  error: null,
};

function asyncReducer(state, action) {
  switch (action.type) {
    case 'call':
      return { ...state, loading: true };
    case 'success':
      return { ...state, result: action.payload, loading: false };
    case 'error':
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}

export const useAsync = (asyncCall: () => Promise<any>) => {
  const [state, dispatch] = useReducer(asyncReducer, INITIAL_STATE);

  const makeCall = async () => {
    try {
      dispatch({ type: 'call' });

      const data = await asyncCall();

      dispatch({ type: 'success', payload: data });
    } catch (e) {
      console.log(e);
      dispatch({ type: 'error', payload: e });
    }
  };

  return { makeCall, ...state };
};
