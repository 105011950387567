import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const eventsClient = (API: APIClass) => {
  return {
    getFailedIngestions: async (orgId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/events/failed-ingestions/${orgId}`, setIsLoading);
    },
  };
};
