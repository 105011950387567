import { ReactWrapperComponent, WrapperProps } from '../../../util';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivityReportingFI } from './ActivityReportingFI';
import { ActivityReportingFIPayload as Payload } from '.';

@Component({
  selector: 'app-activity-reporting-fi',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="onFormSubmit"></app-react-wrapper>',
})
export class ActivityReportingFIComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Output() payloadEmitter = new EventEmitter<any>();
  component = ActivityReportingFI;

  constructor() {
    super();
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(payload: Payload) {
    this.payloadEmitter.emit(payload);
  }
}
