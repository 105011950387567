import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@gcv/shared';

// This is invalid..
// [class.profileHeader]="type === 'profileHeader'"
@Component({
  selector: 'gcv-initial-logo',
  template: `
    <div class="wrapper">
      <div
        *ngIf="type === 'initials'"
        class="initialContainer"
        [class.whiteBorder]="borderColor === 'white'"
        data-cy="initial-logo.component"
      >
        {{ firstName.split('')[0] }}{{ lastName.split('')[0] }}
      </div>
      <div class="hover" *ngIf="hover && type === 'initials'">
        <span>{{ firstName }} {{ lastName }}</span>
      </div>

      <div *ngIf="type === 'additionalUsers'" class="initialContainer additionalUsers">+ {{ users.length - 5 }}</div>
      <div class="hover stack" *ngIf="hover && type === 'additionalUsers'">
        <span *ngFor="let user of users | slice: 5"
          >{{ userMap[user] ? userMap[user].firstName : '' }} {{ userMap[user] ? userMap[user].lastName : '' }}</span
        >
      </div>

      <div *ngIf="type === 'profileHeader'" class="initialContainer profileHeader">
        {{ firstName.split('')[0] }}{{ lastName.split('')[0] }}
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        position: relative;
      }

      .initialContainer {
        position: relative;
        width: 37px;
        height: 37px;
        background: #f0f2f9;
        border: 1px solid #dbe1ed;
        box-sizing: border-box;
        border-radius: 50%;
        color: #33353c;
        font-weight: 700;
        line-height: 16px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        cursor: default;
      }

      .whiteBorder {
        border: 1.5px solid white;
      }

      .additionalUsers {
        background: #00bc66;
        border: 1.5px solid #37d890;
        color: white;
        z-index: 10;
        position: relative;
        right: 50px;
      }

      .hover {
        position: absolute;
        top: 38px;
        left: 10px;
        font-size: 12px;
        border: 0.5px solid #ebeef7;
        border-radius: 5px;
        padding: 2px;
        opacity: 0;
        text-transform: capitalize;
        display: flex;
        flex-flow: row nowrap;
        white-space: nowrap;
        color: #a5a8ba;
        transition: opacity ease-in 0.1s;
        background: white;
      }

      .initialContainer:hover + .hover {
        opacity: 1;
      }

      .hover.stack {
        flex-flow: column nowrap;
        left: -25px;
      }

      .profileHeader {
        width: 45px;
        height: 45px;
        font-size: 16px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialLogoComponent {
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() borderColor: string;
  @Input() type: 'initials' | 'profileHeader' | 'additionalUsers' = 'initials';
  @Input() hover = false;
  @Input() users: User[];
  @Input() userMap: any;

  constructor() {}
}
