import React from 'react';

interface Props {
  style?: any;
  onClick?: () => any;
}

export const ForwardArrowIcon = (props: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="forward-arrow-icon"
    style={{ ...props.style, cursor: props.onClick ? 'pointer' : '' }}
    onClick={props.onClick}
  >
    <path
      d="M13.1654 11.293C12.8725 11.0001 12.8725 10.5253 13.1654 10.2324C13.4583 9.93949 13.9331 9.93949 14.226 10.2324L20 16.0064L14.226 21.7803C13.9331 22.0732 13.4583 22.0732 13.1654 21.7803C12.8725 21.4874 12.8725 21.0126 13.1654 20.7197L17.8787 16.0064L13.1654 11.293Z"
      fill="#A5A8BA"
      fillRule="nonzero"
    />
  </svg>
);
