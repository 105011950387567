import { ReactWrapperComponent, WrapperProps, GcvEnterBankCodes } from '@user-interface/react-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gcv-enter-bank-codes',
  template: '<app-react-wrapper [wrappedComponent]="component" ></app-react-wrapper>',
})
export class GcvEnterBankCodesComponent extends ReactWrapperComponent {
  component = GcvEnterBankCodes;

  constructor() {
    super();
  }
}
