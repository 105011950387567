import { Dispensary, OrganizationRoleResolver, User, DueDiligenceStatus } from '@gcv/shared';
import { isUndefined } from 'lodash';
import { DispensaryFacade } from '@user-interface/gcv-state';
import { EventEmitter } from '@angular/core';

export const ComplianceZeroState = 'ComplianceZeroState';
export const IdentificationVerification = 'IdentificationVerification';
export const DashboardState = 'DashboardState';
export const StartDueDiligence = 'StartDueDiligence';
export const ContinueDueDiligence = 'ContinueDueDiligence';

export const getDispensaryRouteForDueDiligenceStatus = (
  dueDiligenceStatus: string,
  routeContext: 'company' | 'user'
) => {
  switch (dueDiligenceStatus) {
    case IdentificationVerification:
      return '/secure/dispensary/verify-identity';
    case StartDueDiligence:
    case ContinueDueDiligence:
      return '/secure/dispensary/onboarding/business-details';
    default:
      return `/secure/dispensary/${routeContext}/overview`;
  }
};

/**
  // 1) Hide Side button if not initial_user flag
  // 2) (User.identification_verified && (Dispensary.status === 'In Progress' || dispensary.status === 'fulfilled)) -> Compliance Zero State
  // 3) (Dispensary.status === 'Fulfilled' || Approved) && (!user.Identification_verified and user belongs to mot or account owner ?) -> Identification Verification
  // 4) Dispensary.status === 'approved' && user.identification_verified -> Dashboard
  // 5) Dispensary.status !== 'approved' && user doesn't belong to mot or account owner ? -> Compliance Zero State
  // 5) Dispensary.status === 'approved' && user doesn't belong to mot or account owner ? -> Dashboard
 */
export const getComponentForDueDiligenceStatus = (dispensary: Dispensary, user: User) => {
  const resolver = new OrganizationRoleResolver();
  const role = resolver
    .getUsersGroups(dispensary.groups, user.id)
    .find(o => o.type === 'account_owner' || o.type === 'deposit_transport');
  const dueDiligenceStatus = dispensary.due_diligence.due_diligence_status;
  const isAccountOwnerOrMotUser = !!role;
  const isUserVerified = user.identityVerified !== false && !isUndefined(user.identityVerified);
  const isUnverifiedAccountOwnerOrMotUser = isAccountOwnerOrMotUser && !isUserVerified;

  return getComponentForDueDiligenceStatusAndUserState(
    dueDiligenceStatus,
    isUnverifiedAccountOwnerOrMotUser,
    isAccountOwnerOrMotUser
  );
};

export function getComponentForDueDiligenceStatusAndUserState(
  dueDiligenceStatus: DueDiligenceStatus,
  isUnverifiedAccountOwnerOrMotUser: boolean,
  isAccountOwnerOrMotUser: boolean
) {
  switch (dueDiligenceStatus) {
    case DueDiligenceStatus.GCV_APPROVED:
    case DueDiligenceStatus.BANK_APPROVED:
    case DueDiligenceStatus.BANK_PENDING:
    case DueDiligenceStatus.BANK_IN_PROGRESS:
    case DueDiligenceStatus.BANK_AWAITING_REVIEW:
      return isUnverifiedAccountOwnerOrMotUser ? IdentificationVerification : DashboardState;
    case DueDiligenceStatus.GCV_AWAITING_REVIEW:
      return isUnverifiedAccountOwnerOrMotUser ? IdentificationVerification : ComplianceZeroState;
    case DueDiligenceStatus.GCV_IN_PROGRESS:
      return isAccountOwnerOrMotUser ? ContinueDueDiligence : ComplianceZeroState;
    case DueDiligenceStatus.GCV_PENDING:
      return isAccountOwnerOrMotUser ? StartDueDiligence : ComplianceZeroState;
    default:
      return ComplianceZeroState;
  }
}

export const handleDueDiligenceButtonClicked = (
  status: string,
  dispFacade: DispensaryFacade,
  orgId: string,
  handleButtonEvent: EventEmitter<any>
) => {
  if (status === StartDueDiligence) {
    dispFacade.updateGcvDueDiligenceStatus(orgId);
  }
  handleButtonEvent.emit(status);
};
