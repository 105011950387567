import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const organizationsClient = (API: APIClass) => {
  return {
    getUsersForOrg: async (orgId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/organizations/${orgId}/users`, setIsLoading);
    },
  };
};
