import { Routes } from '@angular/router';
import { SupportUserLoginComponent } from './support-user-login.component';
import { CanActivateSupportUserLogin } from './support-user-login.can-activate';
import { supportUserLoginRoutesNames } from './support-user-login.routes.names';

export const SUPPORT_USER_LOGIN_ROUTES: Routes = [
  {
    path: supportUserLoginRoutesNames.SUPPORT_USER_LOGIN,
    component: SupportUserLoginComponent,
    canActivate: [CanActivateSupportUserLogin],
    pathMatch: 'full',
  },
];
