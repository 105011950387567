import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gcv-user-interface-review-document-comments',
  templateUrl: './review-document-comments.component.html',
  styleUrls: ['./review-document-comments.component.scss'],
})
export class ReviewDocumentCommentsComponent implements OnInit {
  comment = '';
  document = '';
  description = '';
  isDispensary = false;
  status = 'review';

  constructor(
    private matDialogRef: MatDialogRef<ReviewDocumentCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.comment = data.comment;
    this.document = data.document;
    this.description = data.description;
    this.status = data.status;
    this.isDispensary = data.isDispensary;
  }

  ngOnInit() {}

  approve() {
    this.matDialogRef.close({ approved: true });
  }

  close() {
    this.matDialogRef.close({ approved: false });
  }
}
