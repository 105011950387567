import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'gcv-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUserDialogComponent implements OnInit {
  data: FormGroup;
  title = 'Invite User';
  subtitle = 'This user will receive an email with everything they need to set up their account on Green Check';
  roles = [];
  bank: any;
  roleMap = {
    dispensary: [
      { viewValue: 'Admin Users', value: 'dispensary_admin' },
      { viewValue: 'Standard Users', value: 'dispensary_user' },
    ],
    gcv: [
      { viewValue: 'Admin Users', value: 'gcv_admin' },
      { viewValue: 'Standard Users', value: 'gcv_user' },
    ],
    bank: [
      { viewValue: 'Admin Users', value: 'bank_admin' },
      { viewValue: 'Standard Users', value: 'bank_user' },
    ],
  };

  constructor(
    private matDialogRef: MatDialogRef<CreateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    if (this.dialogData.isAccountOwnerInv) {
      this.title = 'Invite another Account Owner to Green Check';
      this.roleMap = this.dialogData.roleMap;
    }
  }

  ngOnInit() {
    this.roles = this.roleMap[this.dialogData.orgType];
    this.data = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      primaryContact: new FormControl(false),
      role: new FormControl('', Validators.required),
    });
  }

  isDisabled() {
    return !this.data.valid;
  }

  handleCreateUser() {
    this.matDialogRef.close({ user: this.data.getRawValue() });
  }
}
