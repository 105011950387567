import { DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE, Dispensary, LicenseManager } from '@gcv/shared';

export const mergeGcvAndBankRequirements = (bankTemplate, dispensary: Dispensary) => {
  const gcv = collapseTemplate(DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE);
  const bank = collapseTemplate(bankTemplate, dispensary);
  const licenses = getLicenseTemplate(dispensary.state);
  return { ...bank, ...licenses, ...gcv };
};

const collapseTemplate = (template, dispensary?: Dispensary) => {
  const requirementMapping = {};
  if (!template || !template.documentation) {
    return requirementMapping;
  }
  const categories = template.documentation.categories;
  const categoryKeys = Object.keys(categories);
  categoryKeys.forEach(key => {
    categories[key].forEach(requirement => {
      if (requirement.archived) {
        if (dispensary) {
          if (dispensary.due_diligence.requirements[requirement.requirement_id]) {
            requirementMapping[requirement.requirement_id] = {
              ...requirement,
              type: key,
            };
          }
        }
      } else {
        requirementMapping[requirement.requirement_id] = {
          ...requirement,
          type: key,
        };
      }
    });
  });
  return requirementMapping;
};

// TODO  : Remove after fixing GCV DD template
const getLicenseTemplate = state => {
  const licenseTemplate: any = {
    business_licenses: {
      name: 'Business Licenses',
      requirement_id: 'business_licenses',
      type: 'business_licenses',
    },
  };

  if (LicenseManager.getLicenseData(state).employee_license_required) {
    licenseTemplate.employee_licenses = {
      name: 'Employee Licenses',
      requirement_id: 'employee_licenses',
      type: 'employee_licenses',
    };
  }
  return licenseTemplate;
};

export const getEmptyRequirement = templateRequirement => {
  if (!templateRequirement) {
    return {
      documents: {},
    };
  }

  return {
    documents: {},
    name: templateRequirement.name,
    description: templateRequirement.description,
    type: templateRequirement.type,
    status: 'needs_review',
  };
};
