import * as base64arraybuffer from 'base64-arraybuffer';
import { ElementRef } from '@angular/core';
import fileType from 'file-type';

export function base64DecodeS3Data(e: ProgressEvent, type?: string, filename?: string) {
  filename = filename ? filename : 'filename';
  const base64 = e.target['result'];
  if (!base64) {
    throw new Error("base64DecodeS3Data: no base64 data in event's result");
  }
  const base64ArrayBuffer = base64arraybuffer.decode(base64);
  if (!base64ArrayBuffer) {
    throw new Error('base64DecodeS3Data: no base64ArrayBuffer when decoding base64 data');
  }
  const parsedFileType = type ? { mime: type } : fileType(base64ArrayBuffer);
  if (!parsedFileType) {
    throw new Error('base64DecodeS3Data: unable to parse file type from base64ArrayBuffer');
  }
  const { mime } = parsedFileType;
  const file = new File([base64ArrayBuffer], filename, { type: mime });
  return { file };
}

export function conditionallySetImageViewerForFile(file: File, elementRef: ElementRef) {
  if (file.type.includes('image/')) {
    elementRef.nativeElement.src = generateHTMLTemplateForImage(file);
  } else {
    elementRef.nativeElement.src = window.URL.createObjectURL(file);
  }
}

export function openUrlFromFile(file: File) {
  const url = window.URL.createObjectURL(file);
  if (url) {
    //download file with filename
    if (
      file.type === '' ||
      (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png')
    ) {
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', file.name);
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => document.body.removeChild(a), 0);
    } else {
      //open in new tab
      window.open(url, '_blank');
    }
  }
}

export function openFileInNewWindow(blob: Blob, type?: string, filename?: string) {
  callFileReaderWithCallback(blob, (e: ProgressEvent) => {
    const { file } = base64DecodeS3Data(e, type, filename);
    openUrlFromFile(file);
  });
}

export function openImageInPdfView(blob: Blob, elementRef: ElementRef) {
  callFileReaderWithCallback(blob, (e: ProgressEvent) => {
    const { file } = base64DecodeS3Data(e);
    conditionallySetImageViewerForFile(file, elementRef);
  });
}

function callFileReaderWithCallback(blob: Blob, callback: (e: ProgressEvent) => any) {
  const fileReader = new FileReader();
  fileReader.onload = callback;
  fileReader.readAsBinaryString(blob);
}

/*
 * this allows us to inline the document src for the iframe so we can make sure the
 * image is contained via CSS (GS-1794)
 */
export function generateHTMLTemplateForImage(imageFile: File): string {
  return `javascript:
              '<!DOCTYPE html>
                <html lang="en">
                <head>
                  <style>img{
                              display: block;
                              position: absolute;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              max-height: 100%;
                            }</style>
                </head>
                <body>
                  <img src="${window.URL.createObjectURL(imageFile)}" />
                </body>
                </html>'`;
}
