import * as React from 'react';
import styled from 'styled-components';
import { $grey1, $primary } from '../../util';

type CommonProps = {
  content: any;
  style?: any;
};

type ContentProps =
  | {
      type: 'h1';
      green?: boolean;
    }
  | {
      type: 'h2';
    }
  | {
      type: 'h3';
      grey2?: boolean;
      green?: boolean;
    }
  | {
      type: 'p1';
      grey2?: boolean;
    }
  | {
      type: 'p2';
    }
  | {
      type: 'l1';
      grey2?: boolean;
      green?: boolean;
    }
  | {
      type: 'l2';
    }
  | {
      type: 'a';
      onClick: () => any;
    };

type Props = CommonProps & ContentProps;

const H1 = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
`;

const H2 = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

const H3 = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

const P1 = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const P2 = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const L1 = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

const L2 = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const A = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #6896fb;
  cursor: pointer;
`;

export const GcvContent: React.FC<Props> = (props: Props) => {
  let color;
  switch (props.type) {
    case 'h1':
      color = props.green ? $primary : $grey1;
      return <H1 style={{ color: color, ...props.style }}>{props.content}</H1>;
    case 'h2':
      color = $grey1;
      return <H2 style={{ color: color, ...props.style }}>{props.content}</H2>;
    case 'h3':
      color = props.grey2 ? '#828599' : props.green ? $primary : $grey1;
      return <H3 style={{ color: color, ...props.style }}>{props.content}</H3>;
    case 'p1':
      color = props.grey2 ? '#828599' : $grey1;
      return <P1 style={{ color: color, ...props.style }}>{props.content}</P1>;
    case 'p2':
      color = $grey1;
      return <P2 style={{ color: color, ...props.style }}>{props.content}</P2>;
    case 'l1':
      color = props.grey2 ? '#828599' : props.green ? $primary : $grey1;
      return <L1 style={{ color: color, ...props.style }}>{props.content}</L1>;
    case 'l2':
      color = $grey1;
      return <L2 style={{ color: color, ...props.style }}>{props.content}</L2>;
    case 'a':
      return (
        <A onClick={props.onClick} style={props.style}>
          {props.content}
        </A>
      );
  }
};
