import { Pipe, PipeTransform } from '@angular/core';
import { formatReportDate } from '../../utils';

@Pipe({
  name: 'formatReportDate',
})
export class FormatReportDatePipe implements PipeTransform {
  transform(date: any, args?: number): any {
    return formatReportDate(date, args);
  }
}
