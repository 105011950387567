import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    label: {
      marginLeft: '0.5rem',
    },
    labelInline: {
      fontSize: '12px',
      color: '#3A3C45',
      margin: '0 1rem 0 0.5rem',
    },
  };
});

interface Props {
  items: { icon: ReactNode; label: string }[];

  inline?: boolean;
}

export const Legend: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <>
      {props.items.map((item, index) => {
        return props.inline ? (
          <span key={index}>
            {item.icon} <span className={classes.labelInline}>{item.label}</span>
          </span>
        ) : (
          <div key={index}>
            {item.icon} <span className={classes.label}>{item.label}</span>
          </div>
        );
      })}
    </>
  );
};
