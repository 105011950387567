<pagination-controls
  (pageChange)="page = $event"
  (pageChange)="onPageChange($event)"
  [autoHide]="autoHide"
  [directionLinks]="directionLinks"
  [id]="config.id"
  [maxSize]="maxSize"
  [nextLabel]="labels.nextLabel"
  [previousLabel]="labels.previousLabel"
  [responsive]="responsive"
  [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
  [screenReaderPageLabel]="labels.screenReaderPageLabel"
  [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
  data-cy="pagination-controls.component"
></pagination-controls>
