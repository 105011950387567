import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DueDiligenceDetailsDialogComponent } from '../due-diligence-details-dialog/due-diligence-details-dialog.component';

@Component({
  selector: 'gcv-due-diligence-requirement-table',
  templateUrl: './due-diligence-requirement-table.component.html',
  styleUrls: ['./due-diligence-requirement-table.component.scss'],
})
export class DueDiligenceRequirementTableComponent implements OnInit {
  @Input() editable = true;
  @Input() documents: { requirement: string; description: string; type: string }[];

  public dataMap = {
    requirement: 'Requirement',
    type: 'Type',
  };

  public displayedColumns: string[];

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.displayedColumns = this.editable ? ['select', 'requirement', 'type'] : ['requirement', 'type'];
  }

  handleRowClicked(row) {
    this.dialog.open(DueDiligenceDetailsDialogComponent, {
      data: { description: row.description, requirement: row.requirement, type: row.type },
    });
  }

  handleDeleteClicked(row) {}
}
