<div class="background">
  <div class="wrapper">
    <div class="headerBar">
      <div class="logo">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 484 60.5">
          <defs><path id="a" d="M75.3 0l4.5 4.5L24 60.4 0 36.8l4.5-4.6L24 51.5z" /></defs>
          <use xlink:href="#a" overflow="visible" fill="#267832" />
          <defs><path id="b" d="M46.7 60.5h-9l49.6-49.6 4.5 4.4z" /></defs>
          <use xlink:href="#b" overflow="visible" fill="#88c933" />
          <g>
            <defs><path id="c" d="M69.4 60.2h-9l38.8-38.9 4.5 4.5z" /></defs>
            <use xlink:href="#c" overflow="visible" fill="#88c933" />
          </g>
          <g>
            <defs><path id="d" d="M92.1 60.3h-9l28.1-28.2 4.5 4.5z" /></defs>
            <use xlink:href="#d" overflow="visible" fill="#88c933" />
          </g>
          <g>
            <defs>
              <path
                id="e"
                d="M479.5 46.2l-13.3-15.3-6.6 6.8v8.6h-3.4l-.2-32.7h3.4v19.7l19.1-19.7h4l-14.1 14.8 15.1 17.8h-4zm-42.2.3c-9.8 0-17-7-17.1-16.5s7.2-16.6 17-16.6c4.7 0 9 1.6 11.9 4.7l-2.2 2.2c-2.7-2.7-5.9-3.8-9.6-3.8-7.9 0-13.8 5.8-13.7 13.5s5.9 13.5 13.8 13.5c3.7 0 7-1.2 9.6-3.9l2.2 2.2c-2.8 3.1-7.1 4.7-11.9 4.7zm-41.1-3.1h19.6v3h-23l-.1-32.6H415v3h-18.9v11.6h16.8v2.9h-16.8l.1 12.1zm-17-29.6h3.4l.1 32.6h-3.4v-15h-20.4v15h-3.4l-.1-32.6h3.4v14.5h20.4V13.8zm-42.6 32.9c-9.8 0-17-7-17.1-16.5 0-9.5 7.2-16.6 17-16.6 4.7 0 9 1.6 11.9 4.7l-2.2 2.2c-2.7-2.7-5.9-3.8-9.6-3.8-7.9 0-13.8 5.8-13.7 13.5s5.9 13.5 13.8 13.5c3.7 0 7-1.2 9.6-3.9l2.2 2.2c-2.8 3.1-7.1 4.7-11.9 4.7zM297.3 14h3.4l.1 32.6H298l-21-26.4.1 26.4h-3.4l-.2-32.6h2.8l21 26.4V14zm-50.9 29.7H266v3h-23l-.1-32.6h22.3v3h-18.9v11.6h16.8v2.9h-16.8l.1 12.1zm-30.6.1h19.6v3h-23l-.1-32.6h22.3v3h-18.9v11.6h16.8v2.9h-16.8l.1 12.1zm-24.5-10.3c6.5 0 9.9-3 9.9-8.2 0-5.2-3.5-8.2-10-8.2h-8.7v16.3l8.8.1zm13.9 13.3h-3.8L194 36.3c-.8.1-1.7.1-2.6.1h-8.7v10.3h-3.4l-.1-32.6h12.2c8.3 0 13.3 4.2 13.3 11.1 0 5.1-2.7 8.7-7.4 10.3l7.9 11.3zm-38.5-5.3v-11h3.3v12.4c-3.1 2.8-7.4 4.2-11.9 4.2-9.9 0-17.2-7-17.2-16.5S148.1 14 158.1 14c4.9 0 9.2 1.6 12.1 4.7l-2.1 2.2c-2.8-2.7-6-3.8-9.8-3.8-8 0-13.9 5.8-13.9 13.5s5.9 13.5 13.9 13.5c3 0 5.9-.7 8.4-2.6z"
              />
            </defs>
            <use xlink:href="#e" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" fill="#267832" />
          </g>
        </svg>
      </div>
      <div class="headerText">User Agreement</div>
      <div class="lastUpdated">
        <span class="label">Last Updated</span> &nbsp;<span class="date">August 5, 2019</span>
      </div>
    </div>
    <div class="textAreaWrapper">
      <div class="textArea">
        <h1>User Agreement</h1>
        <p>
          This&nbsp;<strong>User Agreement</strong> is made between&nbsp;<strong>Green Check Verified, Inc.</strong>, a
          Delaware corporation and you (the &ldquo;<strong>Client</strong>&rdquo;). As used herein,
          &ldquo;<strong>Agreement</strong>&rdquo; means the Terms and Conditions attached hereto, and any appendices,
          exhibits, schedules and riders hereto or thereto,&nbsp;the terms and conditions of which are hereby
          incorporated herein by reference thereto, as the same may be amended from time to time and in effect.
          &nbsp;This Agreement contains the entire understanding of both parties with respect to the subject matter
          addressed and supersedes any prior representations or agreements, whether written or oral. &nbsp;Definitions
          of certain capitalized terms are set forth in <strong><u>Schedule I</u></strong
          >.
        </p>

        <p>
          By installing or otherwise using the Software, you are accepting the terms of this Agreement. &nbsp;If you are
          not willing to be bound by the terms of this Agreement, you should click on the button marked &ldquo;I DO NOT
          ACCEPT&rdquo; and you may not install, access or otherwise use the Software. &nbsp;Your clicking on the button
          marked &ldquo;I ACCEPT&rdquo; and your continued use of the Software indicates your acknowledgement that you
          have read, understand and accept the terms and conditions of this Agreement. &nbsp;The date upon which you
          accept the terms and conditions of this Agreement shall be the &ldquo;<strong>Effective Date</strong>&rdquo;.
          The Company is providing use of the Software free of charge.
        </p>

        <h1>TERMS AND CONDITIONS</h1>

        <p>OVERVIEW</p>

        <p>
          <strong>Whereas,&nbsp;</strong>the Company has developed a certain proprietary software-as-a-service (SaaS)
          product known as Green Check Verified&trade; as set forth and described in
          <strong><u>Schedule II</u></strong> hereto (collectively with all intellectual property rights therein, and
          all modules and features listed on <strong><u>Schedule II</u></strong
          >, as the same may be supplemented, modified, updated or enhanced from time to time, the
          &ldquo;<strong>Software</strong>&rdquo;); and
        </p>

        <p>
          <strong>Whereas,</strong> the Client desires to subscribe to and use the Software, subject to all of the terms
          and conditions hereof;
        </p>

        <p>
          <strong>Now, Therefore,</strong> in consideration of the mutual covenants and other forms of consideration,
          the adequacy and receipt of which are hereby acknowledged, the parties hereto agree as follows:
        </p>

        <ol>
          <li>
            <h1>SOFTWARE. &nbsp;</h1>

            <ol>
              <li>
                <h2>
                  <u>Activation and Use</u>. &nbsp;Upon the Activation Date, the Company shall provide remote electronic
                  access to the Software. &nbsp;Client shall access the Software by means of a client account accessed
                  via a unique client log-in and password for the Client (the &ldquo;<strong>Client Account</strong
                  >&rdquo;). &nbsp; The Client Account shall be made available to the Client via electronic remote
                  access only and, other than such client-side object code as may be necessary for such electronic
                  remote access, shall not be available in executable object code form or in source code form. &nbsp;
                  Subject and conditioned on Client&rsquo;s payment of all fees and payments due hereunder and
                  Client&rsquo;s compliance and performance in accordance with all other terms and conditions of this
                  Agreement, the Company hereby authorizes the Client to, on a limited, non-exclusive, non-assignable,
                  non-sublicensable and non-transferable basis (only in accordance with all of the terms and conditions
                  set forth in this Agreement) use the Software during the Term through Client&rsquo;s Client Account
                  access.
                </h2>
              </li>
            </ol>
          </li>
          <li>
            <h1>SERVICE Acknowledgements and Agreements</h1>

            <ol>
              <li>
                <h2>
                  <u>Client Systems</u>. &nbsp;The Client shall be responsible at its own expense for providing access
                  to the internet in order to access the Software.&nbsp; &nbsp;The Client shall be responsible for
                  ensuring that all Client Systems are free from viruses, worms, trojan horses and other malicious code.
                  &nbsp;The Client has and will retain sole control over the operation, maintenance and management of,
                  and all access to and use of, the&nbsp;Client Systems, and sole responsibility for all access to and
                  use of the Software by any person by or through the&nbsp;Client Systems or any other means controlled
                  by&nbsp;Client, including any: (i) information, instructions or materials provided by any of them to
                  the Software; (ii) results obtained from any use of the Software; and (iii) conclusions, decisions or
                  actions based on such use.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Updates</u>. &nbsp;The Company reserves the right in its sole discretion, but shall not be
                  obligated, to revise, update, upgrade, edit or delete any appearance or functions of, or any
                  documents, information or other content appearing on or part of, the Software&nbsp;(other then as
                  required to remain compliant with applicable laws, guidance and regulations (&ldquo;<strong
                    >Applicable Law</strong
                  >&rdquo;)) or Client Account; <u>provided</u>, <u>however</u>, that the Company shall not delete any
                  material function of the Software or Client Account without concurrently providing new function(s)
                  which provide the same or better capabilities than such deleted function, and the Company shall
                  provide Client with prior written notice of such deletions/changes to material functions.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Security</u>. &nbsp;Client shall keep all passwords and usernames to its Client Account, as well as
                  its operating systems (and passwords and usernames thereto), confidential and secure, and Client shall
                  be solely responsible for any damage caused by unauthorized access to the Client Account or its
                  operating systems. &nbsp;Client shall not share its passwords or usernames with any other persons, or
                  otherwise provide access to the Client Account to any other person. &nbsp;The Company is not liable
                  for any Client Data or other information or data of the Client obtained by unauthorized persons due to
                  security breaches (unless such security breaches resulted from Company&rsquo;s gross negligence or
                  willful misconduct), Client&rsquo;s negligence, or breach of this Agreement by Client, nor is the
                  Company liable for any damage or loss of Client Data due to security breaches, virus attacks,
                  Client&rsquo;s negligence, or breach of this Agreement by Client. &nbsp;Client shall not undermine,
                  damage or cause harm to the Software, the Client Account, any other servers of the Company, or any
                  other customer, client or affiliate of the Company.
                </h2>
              </li>
            </ol>
          </li>
        </ol>

        <ol>
          <li>
            <h2><u>Client Personnel and Contacts</u>. &nbsp;</h2>

            <ol>
              <li>
                <h2>
                  The Company shall provide to the Client telephone number(s) and email addresses to enable
                  communication with a service representative of the Company who shall assist the Client in identifying,
                  verifying and resolving technical problems with the Client Account. &nbsp;The Company shall provide
                  technical support regarding the Client Account to the Client, and any other issues of the Client
                  regarding the Software, only to the Client, and only the Client shall contact the Company for any such
                  support or assistance. &nbsp;The Company shall have no obligation to provide, and shall not be liable
                  for any failure to provide, (i) any technical services with the Client Account through any individual
                  other than the Client, (ii) any resolution or dialogue with any individual other than the Client
                  regarding any aspect of the Software other than technical services regarding the Client Account, or
                  (iii) any support or assistance during any observed holidays of the Company, or outside of Monday
                  through Friday, 9:00 AM through 5 PM (eastern time). &nbsp;The Company shall have the right to change,
                  replace, remove or add any business hours for support or assistance by at least ten (10) days prior
                  written notice to Client (and upon such written notice this Section 2.4(a) shall be deemed to have
                  automatically been amended as provided in such written notice).
                </h2>
              </li>
              <li>
                <h2>
                  The&nbsp;Company shall not be responsible for any delays or access issues that are related to any
                  breach of this Agreement by, or any act, omission to act or neglect of, Client, or any of its
                  personnel, agents, representatives or contractors.
                </h2>
              </li>
            </ol>
          </li>
          <li>
            <h2><u>Limitation</u>. &nbsp;</h2>

            <ol>
              <li>
                <h2>
                  Neither the Company nor any employees, contractors or personnel of the Company (including those
                  individuals giving any initial or on-going training) is being engaged to make recommendations or
                  furnish any advice based on any Client Data, and therefore neither the Company nor any employees,
                  contractors or personnel of the Company (including those individuals giving any initial or on-going
                  training) have any liability or obligation to Client or any third-party based upon any reliance upon,
                  application or use of any information or data or any reports furnished or actions taken as a result of
                  Client&rsquo;s use or access to the Software. &nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  The Company may develop and market new or different products or services, which use part or all of the
                  Software, and which perform all or a part of the functions performed by the Software. &nbsp;Nothing
                  contained in this Agreement shall give the Client any rights with respect to any such new or different
                  products or services.&nbsp;
                </h2>
              </li>
            </ol>
          </li>
          <li>
            <h2><u>Additional Covenants</u>. &nbsp;Client shall:</h2>

            <ol>
              <li>
                <h2>
                  comply with all of the terms and conditions of this Agreement, and the terms and conditions of the
                  Software as in effect from time to time (and the Company shall have the right to change such terms and
                  conditions at its sole discretion), which are set forth in
                  <a href="https://www.greencheckverified.com/terms-of-use"
                    >https://www.greencheckverified.com/terms-of-use</a
                  >, which terms and conditions are hereby incorporated herein by reference thereto;
                </h2>
              </li>
              <li>
                <h2>
                  not permit any unauthorized person to use or gain access to the Software (including the Client
                  Account);&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  not permit any person to reproduce, rebroadcast, copy, distribute, resell or incorporate into any
                  information retrieval system (electronic or mechanical), any information, content, form or document
                  delivered via the Software;
                </h2>
              </li>
              <li>
                <h2>
                  only use the Software (including the Client Account) for its benefit and internal use and not in the
                  operation of a service bureau or for the benefit of any other person or entity;
                </h2>
              </li>
              <li>
                <h2>
                  not use the Software (including the Client Account), in whole or in part, for any illegal, obscene,
                  offensive or immoral purpose;&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  not use the Software (including the Client Account), in whole or in part, in any manner, or in
                  connection with any content, data, hardware, software or other materials provided by or on behalf of
                  the Client (collectively, the &ldquo;<strong>Client Materials</strong>&rdquo;) that (A) infringes upon
                  or violates any patent, copyright, trade secret, trademark, or other intellectual property right of
                  any third party, (B) constitutes defamation, libel, invasion of privacy, or violation of any right of
                  publicity or other third-party right or is threatening, harassing or malicious, or (C) violates any
                  applicable international, federal, state or local law, rule, legislation, regulation or ordinance; and
                </h2>
              </li>
              <li>
                <h2>
                  ensure that all Client Materials are free from viruses, worms, trojan horses and other malicious code.
                </h2>
              </li>
            </ol>
          </li>
        </ol>

        <p>
          <strong
            ><u><br />&nbsp;</u></strong
          >
        </p>

        <ol>
          <li>
            <h1>fee Structure</h1>

            <ol>
              <li>
                <h2>The Company is providing use of the Software free of charge.</h2>
              </li>
            </ol>
          </li>
          <li>
            <h1>TERM AND TERMINATION</h1>

            <ol>
              <li>
                <h2>
                  <u>Term</u>. &nbsp; The initial term of this Agreement shall commence on the Effective Date and shall
                  continue until terminated pursuant to Section 4.2 (the &ldquo;<strong>Term</strong>&rdquo;). &nbsp;
                </h2>
              </li>
              <li>
                <h2><u>Termination</u>. &nbsp;</h2>

                <ol>
                  <li>
                    <h2>
                      Either party may terminate this Agreement for any reason at any time by written notice delivered
                      five (5) days in advance.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      Each party shall have the right to immediately terminate this Agreement upon any material breach
                      by the other party of its proprietary and/or confidentiality obligations under this Agreement.
                      &nbsp;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      The Company shall have the right to immediately terminate this Agreement upon the Client&rsquo;s
                      failure to use the Software on a continuous basis with the intent of securing and maintaining a
                      commercial relationship with a financial institution that is also a registered client of the
                      Company.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      In the event that either party shall become insolvent, make a general assignment for the benefit
                      of creditors, suffer or permit the appointment of a receiver for its business or assets, or shall
                      avail itself of, or shall become subject to, any proceedings under any bankruptcy (or similar laws
                      relating to insolvency or the protection of rights of creditors) of any jurisdiction, then the
                      other party shall be entitled to terminate this Agreement with immediate effect upon written
                      notice to such affected party.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      The exercise of any right of termination shall not limit any other rights or remedies of the
                      terminating party at law, in equity or hereunder
                    </h2>
                  </li>
                </ol>
              </li>
              <li>
                <h2>
                  <u>Actions Upon Termination/Expiration</u>. &nbsp;Upon any termination/expiration of this Agreement:
                </h2>

                <ol>
                  <li>
                    <h2>
                      Client shall at its own expense return to the Company any Proprietary Information of the Company
                      in its possession and shall immediately cease using any of the Software or Client Account.
                      &nbsp;In connection therewith, upon the termination/expiration date of this Agreement, or at any
                      time thereafter, the Company shall have the right to de-activate the Client Account.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      Upon request of Client, the Company shall transfer all Client Data as so requested,
                      <u>provided</u> that: (i) if this Agreement is terminated by the Company as a result of breach of
                      this Agreement by Client, then Client shall be responsible to pay any and all costs and expenses
                      incurred or to be incurred by the Company in connection therewith; and (ii) if this Agreement
                      expires or is terminated by the Client as a result of breach of this Agreement by Company, then
                      Client shall have the option to: &nbsp;(A) receive quote from the Company for the costs and
                      expenses payable to the Company for its transfer of Client Data, and choose, at Client&rsquo;s
                      discretion, to purchase such transfer services by writing delivered to the Company within ten (10)
                      Business Days of such quote delivery; or (B) have a third party provider at its discretion
                      transfer all Client Data, in collaboration with the Company, within thirty (30) days after the
                      effective termination/expiration date of this Agreement.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      All rights and obligations of each party hereunder shall terminate, subject to Section 8.14.
                    </h2>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h1>PROPRIETARY RIGHTS; CONFIDENTIALITY</h1>

            <ol>
              <li>
                <h2>
                  <u>Intellectual Property Rights</u>. &nbsp;The Company owns and shall remain owning the Client
                  Account, the Documentation, if any, the Software and any other software developed by or for the
                  Company (collectively, the &ldquo;<strong>Intellectual Property</strong>&rdquo;), including without
                  limitation all applicable rights to patents, copyrights, trademarks, trade secrets or other
                  proprietary or intellectual property rights inherent therein or appurtenant thereto (collectively, the
                  &ldquo;<strong>Intellectual Property Rights</strong>&rdquo;). &nbsp;Nothing in this Agreement grants
                  any right, title or interest in or to (including any license under) any Intellectual Property Rights,
                  whether expressly, by implication, estoppel or otherwise. &nbsp;Client shall:
                </h2>

                <ol>
                  <li>
                    <h2>
                      not rent, lease, sublicense, distribute, transfer, copy or modify any Intellectual Property of the
                      Company or the Software, in whole or in part;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      only use the Intellectual Property made available to Client by the Company for its own benefit and
                      internal use and not for the benefit of any other person, including on or in connection with the
                      internet or any time-sharing, service bureau, software as a service, cloud or other technology or
                      service;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      not translate, decompile, or create or attempt to create, by reverse engineering or otherwise, the
                      source code, in whole or in part, from the object code to the Software or Client Account made
                      available hereunder;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      not adapt any of the Intellectual Property of the Company, in whole or in part, in any way or use
                      it to create a derivative work; and
                    </h2>
                  </li>
                  <li>
                    <h2>
                      not remove, obscure, or alter, in whole or in part, the Company&rsquo;s proprietary notices,
                      trademarks, or other proprietary rights notices affixed or contained in or on any Intellectual
                      Property of the Company, including without limitation any proprietary notices of any third party
                      service suppliers to any of the foregoing;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      not bypass or breach any security device or protection used by Software or access or use the
                      Software other than by the Client Account;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      not damage, destroy, disrupt, disable, impair, interfere with or otherwise impede or harm in any
                      manner the Software or the Company&rsquo;s provision of services to any third party, in whole or
                      in part;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      not remove, delete, alter or obscure any trademarks, Documentation, warranties or disclaimers, or
                      any copyright, trademark, patent or other intellectual property or proprietary rights notices from
                      any Software, including any copy thereof; and
                    </h2>
                  </li>
                  <li>
                    <h2>
                      not access or use the Software for purposes of competitive analysis of the Software, the
                      development, provision or use of a competing software service or product or any other purpose that
                      is to the Company&rsquo;s detriment or commercial disadvantage.
                    </h2>
                  </li>
                </ol>
              </li>
              <li>
                <h2>
                  <u>Client Input Data</u>. &nbsp;Client hereby irrevocably grants all such rights and permissions in or
                  relating to Client Input Data: (a) to the Company and its affiliates and personnel as are necessary or
                  useful to perform the Company&rsquo;s obligations hereunder; (b) to the Company as are necessary or
                  useful to enforce this Agreement; and (c) to the Company as are necessary or useful to improve or
                  further develop the Software, subject to any confidentiality requirements hereunder.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Proprietary and Confidential Information</u>. &nbsp;Each party (the &ldquo;<strong
                    >Receiving Party</strong
                  >&rdquo;) agrees that all Proprietary Information of the other party (the &ldquo;<strong
                    >Disclosing Party</strong
                  >&rdquo;) which has heretofore been disclosed and which will hereafter be disclosed to the Receiving
                  Party, or of which the Receiving Party may otherwise attain knowledge during the Term, in oral,
                  written or other tangible form, shall be deemed to be confidential information and the sole property
                  of the Disclosing Party. &nbsp;This Agreement shall govern all communications between the parties that
                  are made during the Term.
                </h2>

                <ol>
                  <li>
                    <h2>
                      The Receiving Party shall keep all Proprietary Information of the Disclosing Party as well as the
                      financial terms of this Agreement (collectively, the &ldquo;<strong
                        >Confidential Information</strong
                      >&rdquo;) strictly confidential and shall not disclose, distribute or disseminate in any way to
                      any third party any of the Confidential Information. &nbsp;Furthermore, the Receiving Party shall
                      not utilize for the Receiving Party&rsquo;s own benefit or the benefit of any third party:
                      &nbsp;(i) any Proprietary Information of the Disclosing Party disclosed by the Disclosing Party or
                      by any person associated with the Disclosing Party; (ii) any Proprietary Information of the
                      Disclosing Party of which the Receiving Party attains knowledge in connection with this Agreement;
                      or (iii) any information, processes, inventions, intellectual property or the like generated by
                      the Receiving Party based in whole or in part on the Proprietary Information of the Disclosing
                      Party, including, without limitation, any improvements, analyses, compilations, studies or other
                      documents or records prepared or generated from such Proprietary Information of the Disclosing
                      Party, which foregoing materials shall be deemed part of the Proprietary Information of the
                      Disclosing Party. &nbsp;Notwithstanding the foregoing: &nbsp;(A) in connection with the
                      Proprietary Information of the Company, the Client&rsquo;s limitation on use and disclosure of
                      such Proprietary Information shall be subject to Client&rsquo;s rights to the Software hereunder;
                      (B) in connection with the Proprietary Information of the Client, the Company&rsquo;s limitation
                      on use and disclosure of such Proprietary Information shall be subject to the right of the Company
                      to support, maintain, update and provide the Software and Client Account; and (C) the Company
                      shall be permitted to use and disclose such Proprietary Information to the extent necessary to
                      provide the Software hereunder. &nbsp;The Receiving Party shall protect the Proprietary
                      Information of the Disclosing Party as well as the financial terms of this Agreement by using the
                      same degree of care, but no less than a reasonable degree of care, to prevent the unauthorized
                      use, dissemination or publication of such Confidential Information as Receiving Party uses to
                      protect its own Proprietary Information of a like nature and as it uses to protect the financial
                      terms of this Agreement. &nbsp;Notwithstanding the foregoing, the Receiving Party shall have the
                      right to disclose any financial terms of this Agreement: &nbsp;(x) to its legal and financial
                      advisors who are under a legal obligation of confidentiality or (y) to its trustees, officers,
                      directors, members, managers, representatives, agents and employees, on a need-to-know basis,
                      provided that each of the foregoing are under a legal obligation of confidentiality.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      Notwithstanding anything in this Agreement to the contrary, the Receiving Party may disclose the
                      Proprietary Information of the Disclosing Party as well as the financial terms of this Agreement
                      to the extent that such disclosure is required by an order of a court, administrative agency or
                      governmental authority, or by any law, rule or regulation, or by subpoena, discovery request,
                      summons or other administrative or legal process, or by any formal or informal investigation by
                      any governmental agency or authority; <u>provided</u>, <u>however</u>, that, unless prohibited by
                      law: &nbsp;(i) the Receiving Party shall give prompt written notice of any such request or
                      requirement to the Disclosing Party; (ii) the Receiving Party shall give the Disclosing Party
                      prior written notice of the Confidential Information it believes it is required to disclose; and
                      (iii) the Receiving Party shall use its reasonable efforts to cooperate, to the extent
                      practicable, with the Disclosing Party to avoid or minimize such disclosure or to obtain
                      confidential treatment thereof or other protective order.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      The Receiving Party&rsquo;s obligations under this Section 5.3 shall terminate when, the Receiving
                      Party, upon seeking to avoid the Receiving Party&rsquo;s obligations hereunder, can prove by clear
                      and convincing documentary evidence that: &nbsp;(i) with respect to disclosure of any financial
                      terms of this Agreement, such financial terms have entered the public domain; or (ii) with respect
                      to any Proprietary Information of the Disclosing Party, such Proprietary Information (A) was in
                      the public domain at the time of disclosure to Receiving Party by Disclosing Party, (B) entered
                      the public domain without violation of this Agreement or any other confidentiality obligation
                      subsequent to the time of disclosure to Receiving Party by Disclosing Party, (C) was communicated
                      to the Receiving Party by a third party, free of any obligation of such third party to maintain
                      the confidentiality of such, or (D) was independently developed by Receiving Party using no amount
                      of the Proprietary Information of the Disclosing Party. &nbsp;Notwithstanding the foregoing,
                      specific information shall not be deemed to be within any of the foregoing exceptions merely
                      because it is in the scope of more general information within any such exceptions, and a
                      combination of features shall not be deemed to be within any such exceptions merely because
                      individual features are within such exceptions.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      Upon termination of this Agreement, each party shall return all Proprietary Information of the
                      other party, or destroy same (and confirm such action in writing), except for one copy for
                      purposes of compliance with Applicable Law, which shall remain subject to the confidentiality
                      requirements hereunder (which shall survive termination).
                    </h2>
                  </li>
                </ol>
              </li>
              <li>
                <h2>
                  <u>Irreparable Harm</u>. &nbsp;Each party acknowledges that any breach of its obligations with respect
                  to this Section 5 may cause the other party irreparable harm or injury for which there are inadequate
                  remedies at law and that such other party may be entitled to equitable relief in addition to all other
                  remedies available to it. &nbsp;Each party agrees that, if a court of competent jurisdiction
                  determines that such party has breached, or attempted or threatened to breach, its obligations
                  pursuant to this Section 5, the other party will be entitled to obtain appropriate injunctive relief
                  and other measures restraining further, attempted or threatened breaches of such obligations. &nbsp;
                  Such relief or measures shall be in addition to, and not in lieu of, any other rights and remedies
                  available to such aggrieved party.<u>&nbsp;</u>
                </h2>
              </li>
            </ol>
          </li>
          <li>
            <h1>INDEMNITY</h1>

            <ol>
              <li>
                <h2>
                  <u>Client Indemnity</u>. &nbsp;The Client shall indemnify, defend, and hold harmless the Company and
                  its affiliates, subsidiaries and parents, and the directors, officers, members, managers, employees,
                  representatives and agents of the foregoing&nbsp;from and against any and all claims, lawsuits and
                  other civil actions or proceedings commenced by any third party (&ldquo;<strong
                    >Third Party Claims</strong
                  >&rdquo;) against the Company or any of its foregoing covered indemnitees, and&nbsp;any and
                  all&nbsp;damages, awards, losses, liabilities, settlements, judgments, costs and
                  expenses&nbsp;(including, without limitation, interest awards, litigation costs, and reasonable
                  attorneys&rsquo; fees awards) (&ldquo;<strong>Losses</strong>&rdquo;) incurred by Company or any of
                  its foregoing covered indemnitees and resulting from or arising out of such Third Party Claims, to the
                  extent that such&nbsp;Third Party Claims are&nbsp;based upon (i) any violation of any Regulatory
                  Requirements&nbsp;by Client and/or (ii) any state or federal criminal prosecutions associated with the
                  Client and/or its business, unless such Third Party Claims resulted from Company&rsquo;s gross
                  negligence or willful misconduct.&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  <u>Company Indemnity</u>. &nbsp;Company shall indemnify, defend and hold harmless Client, and the
                  officers, directors, employees, agents, representatives and contractors thereof, from and against any
                  and all Third Party Claims against Client or any of its foregoing covered indemnitees, and any and all
                  Losses incurred by Client or any of its foregoing covered indemnitees and resulting from or arising
                  out of such Third Party Claims, to the extent that such Third Party Claims are based upon (i) any
                  violation of any Regulatory Requirements by the Company, (ii) any state or federal criminal
                  prosecutions associated with the Company and/or its business, and/or (iii)&nbsp;allegations that the
                  Software infringes any third party&rsquo;s Intellectual Property Rights. &nbsp; The foregoing
                  indemnification obligations of the Company under clause (iii) (the &ldquo;<strong
                    >IP Indemnity Obligations</strong
                  >&rdquo;) are subject to the following:
                </h2>

                <ol>
                  <li>
                    <h2>
                      If Client&rsquo;s use of any of the Software actually or in Company&rsquo;s sole opinion is likely
                      to be the subject of a claim for infringement, then Company, at its option and expense, may either
                      (i) procure for the Client the right to continue using the Software, or (ii) replace or modify the
                      Software so that it becomes non-infringing, or (iii) terminate this Agreement. &nbsp;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      Notwithstanding anything else, the foregoing IP Indemnity Obligations do not apply: &nbsp;(i) if
                      Client admits any related third party allegation without the express prior written consent of
                      Company; (ii) to any Third Party Claim or Loss to the extent such Third Party Claim or Loss
                      results from or arises out of (A) any act or omission by Client or any of its personnel,
                      contractors or agents (other than the use of the Software or other actions, in any case as
                      expressly authorized in this Agreement), (B) the existence or use of any property, equipment,
                      facilities, Confidential Information or Intellectual Property of Client, (C) any use of any of the
                      Software in combination with any technology, operating platforms, hardware, software, content,
                      processes, systems, tools, products or other property not provided by Company, where the
                      allegation of infringement or misappropriation relates to the combination, and/or (E) the
                      continued use by Client of infringing Software after Company has provided any remedy described in
                      Section 6.2(a) above; (iii) any unauthorized modification, operation or use of the Software by
                      Client or any of its personnel, contractors or agents; (iv) the willful misconduct or gross
                      negligence of the Client; and/or (v) the breach by the Client of this Agreement.&rdquo;
                    </h2>
                  </li>
                </ol>
              </li>
              <li>
                <h2>
                  <u>Indemnification Procedures</u>. &nbsp;A party seeking indemnification hereunder (an &ldquo;<strong
                    >Indemnified Party</strong
                  >&rdquo;) shall give the party from whom indemnification is sought (the &ldquo;<strong
                    >Indemnifying Party</strong
                  >&rdquo;): &nbsp; (a) reasonably prompt notice of the relevant claim; (b) reasonable cooperation in
                  the defense of such claim; and (c) the right to control the defense and settlement of such;
                  <u>provided</u>, <u>however</u>, that the Indemnifying Party shall not, without the prior written
                  approval of the Indemnified Party, settle or dispose of any claims in a manner that affects the
                  Indemnified Party&rsquo;s rights or interest (except as otherwise set forth in this Agreement).
                  &nbsp;The Indemnified Party shall have the right to participate in the defense at its own
                  expense.&nbsp;
                </h2>
              </li>
            </ol>
          </li>
          <li>
            <h1>LIMITATION OF LIABILITY</h1>

            <ol>
              <li>
                <h2>
                  <u>Warranty Disclaimer</u>. &nbsp;the Company MAKES NO, AND SPECIFICALLY DISCLAIMS, ANY REPRESENTATION
                  OR WARRANTY, EXPRESS OR IMPLIED (INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABLITY,
                  FITNESSS FOR A PARTICULAR PURPOSE AND TITLE), THAT THE SERVICE (IN WHOLE AND IN PART), ANY
                  DOCUMENTATION, ANY REPORTS FURNISHED BY THE SOFTWARE TO CLIENT (IN ORAL OR WRITTEN FORM), THE SOFTWARE
                  (IN WHOLE AND IN PART), THE CLIENT ACCOUNT (IN WHOLE AND IN PART), OR ANY INTELLECTUAL PROPERTY OF the
                  Company&nbsp;PROVIDED TO CLIENT, OR ANY COMPONENT OF ANY OF THE FOREGOING, WILL MEET THE
                  CLIENT&rsquo;S REQUIREMENTS OR THAT THE CLIENT&rsquo;S USE OF THE CLIENT ACCOUNT WILL BE UNINTERRUPTED
                  OR ERROR-FREE. &nbsp;CLIENT ACKNOWLEDGES AND AGREES THAT: &nbsp;(I) THE SOFTWARE MAY REQUIRE JUDGMENTS
                  TO BE MADE THAT ARE BASED UPON LIMITED DATA RATHER THAN UPON SCIENTIFIC CERTAINTIES; AND (II) ULTIMATE
                  OUTCOMES COULD BE INCONSISTENT WITH THE DATA AND TRENDS DEVELOPED BY THE SOFTWARE.&nbsp;
                </h2>
              </li>
              <li>
                <h2><u>Limitation of Liability</u>. &nbsp;</h2>

                <ol>
                  <li>
                    <h2>
                      IN NO EVENT SHALL THE COMPANY&nbsp;BE LIABLE FOR ANY EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL,
                      SPECIAL, OR CONSEQUENTIAL (INCLUDING LOST PROFITS) DAMAGES ARISING FROM OR IN ANY WAY CONNECTED
                      WITH ITS PERFORMANCE OR FAILURE TO PERFORM UNDER THIS AGREEMENT, EVEN IF THE AFFECTED PARTY HAS
                      KNOWLEDGE OF THE POSSIBILITY OF SUCH DAMAGES. &nbsp;IN NO EVENT WILL THE COLLECTIVE AGGREGATE
                      LIABILITY OF THE COMPANY AND ITS LICENSORS, SERVICE PROVIDERS AND SUPPLIERS UNDER OR IN CONNECTION
                      WITH THIS AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH
                      OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED THE GREATER OF
                      (i) $100,000 OR (ii) THE AMOUNTS PAID OR PAYABLE BY CLIENT UNDER THIS AGREEMENT.&nbsp;
                    </h2>
                  </li>
                  <li>
                    <h2>
                      The foregoing limitations of liability set forth in Sections 7.3(a) and (b) shall not apply with
                      respect to: &nbsp;(i) the willful misconduct of a party; or (ii) the obligations of any
                      Indemnifying Party pursuant to Section 6.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      THE FOREGOING LIMITATION APPLIES NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS
                      ESSENTIAL PURPOSE.
                    </h2>
                  </li>
                </ol>
              </li>
              <li>
                <h2><u>Regulatory Requirements</u>. &nbsp;</h2>

                <ol>
                  <li>
                    <h2>
                      Client acknowledges and agrees that: &nbsp;(i) the Software will require that judgments to be made
                      by Client with regard to compliance with Regulatory Requirements, and it is the Client&rsquo;s
                      sole responsibility to comply with, and attain legal counsel in connection with complying with,
                      Regulatory Requirements; (ii) &nbsp;the Company shall not be responsible for providing, and Client
                      hereby acknowledges that the Company will not provide, legal advice, opinions or recommendations
                      in connection with any Regulatory Requirements; (iii) the Company is not a law firm and will not
                      perform services performed by an attorney; (iv) the Company shall not be responsible for
                      Client&rsquo;s failure to comply with any Regulatory Requirements, even if Client uses the
                      Software; and (v) the Company does not guarantee, and hereby disclaims any representations,
                      warranties and/or liabilities with respect to, Client&rsquo;s compliance with Regulatory
                      Requirements, even if Client uses the Software.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      Client hereby acknowledges that: &nbsp;(i) the dispensing of marijuana for palliative or
                      recreational purposes may be legal pursuant to certain state laws, but currently violates the
                      federal Controlled Substances Act; (ii) previously, the U.S. Department of Justice
                      (&ldquo;<strong>DOJ</strong>&rdquo;) advised federal prosecutors to defer to states for the
                      enforcement of these types of laws, provided that the states have implemented strong and effective
                      regulatory guidelines that support federal enforcement priorities; however, changes within the
                      federal government have impacted, and may in the future impact, this guidance, and DOJ policy
                      statements such as this may be subject to change at any time and do not provide a defense in any
                      future criminal prosecution, civil forfeiture action or civil RICO action, whether public or
                      private; (iii) possessing, using, distributing, selling, and/or the use of property or buildings
                      to possess, store or distribute, marijuana or marijuana-based products is illegal under federal
                      law, regardless of any state law that may decriminalize such activity under certain circumstances;
                      and (iv) although federal enforcement policy may at times defer to states&rsquo; laws and not
                      enforce conflicting federal laws, compliance with state law in no way assures compliance with
                      federal law, and there is a risk that conflicting federal laws may be enforced in the future.
                    </h2>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h1>MISCELLANEOUS TERMS</h1>

            <ol>
              <li>
                <h2><u>Assignment</u>. &nbsp;</h2>

                <ol>
                  <li>
                    <h2>
                      Subject to Section 8.1(b) below, neither the Company nor the Client shall assign, transfer,
                      sublicense, or otherwise dispose of this Agreement (or any rights or benefits hereunder), in whole
                      or in part, or delegate is obligations under this Agreement, in whole or in part, to any other
                      person without the prior written consent of the other party, which consent shall not be
                      unreasonably withheld or delayed; <u>provided</u> that, subject to Section 8.1(b), each party
                      shall have the right to assign this Agreement, without the consent of the other party, in
                      connection with the sale of all or substantially all of the assets of the assigning party, or
                      merger of the assigning party (a &ldquo;<strong>Permitted Assignment</strong>&rdquo;), which shall
                      include, the case of an assignment by the Client, the sale of at least 51% of the ownership of the
                      Client.
                    </h2>
                  </li>
                  <li>
                    <h2>
                      Neither an assignment/delegation by Client with written consent of the Company, nor a Permitted
                      Assignment, releases the assigning/delegating party from any of its obligations under this
                      Agreement unless such written consent so states. &nbsp;The rights of any permitted assignee
                      hereunder shall be subject to any and all set-offs, counterclaims and other comparable rights
                      arising hereunder. &nbsp;Any assignment/delegation of rights/obligations of any of this Agreement
                      contrary to the terms and conditions of this Section 8.1 shall by null and void and of no force or
                      effect.
                    </h2>
                  </li>
                </ol>
              </li>
              <li>
                <h2>
                  <u>Governing Law; Jurisdiction Venue; Disputes</u>. &nbsp;This Agreement is deemed to have been
                  entered in the State of Connecticut, and its interpretation, construction, and the remedies for
                  enforcement or breach are to be applied pursuant to, and in accordance with, the laws of the State of
                  Connecticut, without giving effect to any choice or conflict of law provision or rule (whether of the
                  State of Connecticut or any other jurisdiction) that would cause the application of the laws of any
                  jurisdiction other than the State of Connecticut. &nbsp;Venue and jurisdiction for any action or claim
                  brought under this Agreement shall be in the courts with proper jurisdiction located in the State of
                  Connecticut, and the parties expressly submit themselves to the personal jurisdiction of such courts.
                  &nbsp;Disputes shall be resolved pursuant to the terms and conditions of
                  <strong><u>Schedule III</u></strong
                  >.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Force Majeure</u>. &nbsp;Neither the Company nor Client shall not be liable to the other party for
                  any delay or non-performance of its obligations hereunder in the event and to the extent that such
                  delay or non-performance is due to a Force Majeure Event. &nbsp;A &ldquo;<strong
                    >Force Majeure Event</strong
                  >&rdquo; is any event beyond the control of the excusing party which occurs after the Effective Date
                  and which was not reasonably foreseeable at that time and whose effects are not capable of being
                  overcome without reasonable expense or loss of time or both, including (without limitation) war,
                  terrorism, civil unrest, blockades, boycotts, strikes, lock-outs and other general labor disputes,
                  acts of government or public authorities, natural disasters, exceptional weather conditions, breakdown
                  or general unavailability of transport facilities, accidents, fire, explosions and general shortages
                  of energy, failures in external networks, or any delay to the extent caused by the acts or omissions
                  of the other party.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Binding</u>. &nbsp;This Agreement shall be binding on the parties, their affiliates, parents,
                  subsidiaries, successors, and permitted assigns (if any), and each party warrants that the undersigned
                  representative of such party is authorized to execute this Agreement on behalf of such party.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Complete Understanding</u>. &nbsp;This Agreement constitutes the final, complete and exclusive
                  agreement between the parties with respect to the subject matter hereof, and supersedes any prior
                  proposals, understandings and other agreements (as to all, written and oral) between the parties
                  relating to the subject matter hereof.&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  <u>Severability</u>. &nbsp;If any provision of this Agreement is held by a court of competent
                  jurisdiction to be contrary to law, such provision shall be changed and interpreted so as to best
                  accomplish the objectives of the original provision to the fullest extent allowed by law and the
                  remaining provisions of this Agreement shall remain in full force and effect.&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  <u>Waiver and Amendment</u>. &nbsp;No modification, amendment or waiver of any provision of this
                  Agreement shall be effective unless in writing signed by the party to be charged. &nbsp;No failure or
                  delay by either party in exercising any right, power, or remedy under this Agreement shall operate as
                  a waiver of any such right, power or remedy.&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  <u>Independent Contractors</u>. &nbsp;The Company, and its personnel, contractors and agents, in their
                  performance under this Agreement, are acting as independent contractors and not as employees or agents
                  of the Client. &nbsp;Under no circumstance will either party have the right or authority to enter into
                  any contracts or assume any obligations for the other or to give any warranty or make any
                  representation on behalf of the other.&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  <u>Non-Solicitation</u>. During the Term and for one (1) year after the termination/expiration date of
                  the Term, neither the Company nor the Client shall, and neither shall assist any other person to,
                  directly or indirectly recruit or solicit (other than by general advertisement not directed
                  specifically to any person or persons) for employment or engagement as an independent contractor any
                  person then or within the prior twelve (12) months employed or engaged by the other party and involved
                  in any respect with the Software or the performance of this Agreement.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Notices</u>. &nbsp; Any notice provided pursuant to this Agreement shall be in writing and shall be
                  deemed given (i) if by hand delivery, upon receipt thereof; (ii) if mailed, upon receipt; or (iii) if
                  by next day delivery service, upon such delivery. &nbsp;All notices shall be addressed to the
                  applicable party at its respective address first set forth in the Preamble to this Agreement, or such
                  other address as may be designated on notice to the other party pursuant these notice
                  provisions.&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  <u>Cumulative Rights and Remedies</u>. &nbsp;The rights and remedies of the Company and Client
                  provided for under this Agreement are neither exclusive nor mutually exclusive, and the Company and
                  Client shall be entitled to resort to any such rights and remedies, or any other remedy available to
                  the Company and Client at law or in equity, or some or all in any combination, at each of their
                  discretion.&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  <u>Click-Through Agreement</u>. &nbsp;By installing or otherwise using the Software, and checking the
                  &ldquo;I ACCEPT&rdquo; button, Client and Company have entered into this Agreement upon the Effective
                  Date. &nbsp;&nbsp;
                </h2>
              </li>
              <li>
                <h2>
                  <u>Publication Grant</u>. &nbsp;Client hereby grants to the Company the irrevocable right, but not the
                  obligation, throughout the world, for the term of this Agreement, and solely for the purpose of
                  promoting and providing information regarding the Company&rsquo;s business, services, products and
                  programs, in the Company&rsquo;s printed and electronic materials, including annual reports,
                  brochures, newsletters and websites, to publicly indicate and list Client as a customer of the
                  Company, which may include the trade name(s), trademarks and logos of the Client.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Rules of Usage</u>. &nbsp;In this Agreement, unless a clear intention appears otherwise: &nbsp;(a)
                  the singular number includes the plural number and vice versa; (b) reference to any person includes
                  such person&rsquo;s successors and assigns but, if applicable, only if such successors and assigns are
                  not prohibited by this Agreement, and reference to a person in a particular capacity excludes such
                  person in any other capacity or individually; (c) reference to any gender includes each other gender;
                  (d) reference to any agreement, document or instrument means such agreement, document or instrument as
                  amended or modified and in effect from time to time in accordance with the terms thereof; (e)
                  reference to any law means such law as amended, modified, codified, replaced or reenacted, in whole or
                  in part, and in effect from time to time, including rules and regulations promulgated thereunder; (f)
                  &ldquo;hereunder,&rdquo; &ldquo;hereof,&rdquo; &ldquo;hereto,&rdquo; and words of similar import shall
                  be deemed references to this Agreement as a whole and not to any particular section or other provision
                  hereof; (g) &ldquo;including&rdquo; (and with correlative meaning &ldquo;include&rdquo;) means
                  including without limiting the generality of any description preceding such term; (h) &ldquo;or&rdquo;
                  is used in the inclusive sense of &ldquo;and/or&rdquo;; (i) with respect to the determination of any
                  period of time, &ldquo;from&rdquo; means &ldquo;from and including&rdquo; and &ldquo;to&rdquo; means
                  &ldquo;to but excluding&rdquo;; (j) references to documents, instruments or agreements shall be deemed
                  to refer as well to all addenda, exhibits, schedules or amendments thereto; (k) references to
                  &ldquo;person&rdquo; or &ldquo;persons&rdquo; means an individual, corporation, limited liability
                  company, partnership, trust, joint venture or other legal entity; (l) article and section headings
                  herein are for convenience only and shall not affect the construction hereof; and (m) section and
                  article references shall be deemed to refer to all subsections and sections thereof, unless otherwise
                  expressly indicated.
                </h2>
              </li>
              <li>
                <h2>
                  <u>Survival</u>. &nbsp;In addition to all other provisions which expressly survive
                  termination/expiration of this Agreement, or whose context requires such survival, the following
                  provisions shall specifically survive termination of this Agreement: &nbsp;Sections 2.3, 2.5, 3, 4.3,
                  5, 6, 7 and 8, and <strong><u>Schedule III</u></strong
                  >.
                </h2>
              </li>
            </ol>
          </li>
        </ol>

        <p>&nbsp;</p>

        <h1>Schedule i</h1>

        <h1>DEFINITIONS</h1>

        <p>
          &ldquo;<strong>Activation Date</strong>&rdquo; means the date the Company provides access to the Client; such
          access to be deemed given when the Client is able to access the Client Account through the Client username and
          password therefor.
        </p>

        <p>
          &ldquo;<strong>Business Day</strong>&rdquo; means the active banking days as designated by the Federal Reserve
          Bank of New York, New York excluding Saturday, Sunday and specified holidays.
        </p>

        <p>
          &ldquo;<strong>Client Data</strong>&rdquo; means data regarding the Client as generated by the Software and
          Client Account.
        </p>

        <p>
          &ldquo;<strong>Client Input Data</strong>&rdquo; means information, data and other content, in any form or
          medium, that is collected, downloaded or otherwise received, directly or indirectly from the Client by or
          through the Software or that incorporates or is derived from the processing of such information, data or
          content by or through the Software.&nbsp;
        </p>

        <p>
          &ldquo;<strong>Client Systems</strong>&rdquo; means the Client&rsquo;s information technology infrastructure,
          including computers, servers, operating systems, software, hardware, databases, electronic systems (including
          database management systems) and networks, whether operated directly by Client or through the use of
          third-party services.
        </p>

        <p>
          &ldquo;<strong>Documentation</strong>&rdquo; means all manuals, guidelines, reports, media and other
          documentation regarding the Software or any Intellectual Property of the Company which are provided in writing
          or electronic format by the Company to the Client.
        </p>

        <p>
          &ldquo;<strong>Proprietary Information</strong>&rdquo; means: &nbsp;(a) with respect to Proprietary
          Information of the Company, confidential or proprietary information, processes and material of the Company
          relating to or associated with the Software that are conceived, made or possessed by the Company including
          without limitation: &nbsp;plans, designs, performance specifications, marketing plans, algorithms,
          Intellectual Property, Intellectual Property Rights, ideas, inventions, formulas, techniques, and know how as
          well as any of the foregoing relating to the Software and the Client Account (and each of their respective
          source codes) and the Documentation; and (b) with respect to Proprietary Information of Client, confidential
          or proprietary information, and material of Client relating to or associated with the Client Data including
          without limitation the Client Materials, data and information regarding Client&rsquo;s customers and all other
          non-public personally identifiable information.
        </p>

        <p>
          &ldquo;<strong>Regulatory Authority</strong>&rdquo; means any international, U.S. federal or state
          governmental or quasi-governmental regulatory authority, department, or judicial or administrative body.&nbsp;
        </p>

        <p>
          &ldquo;<strong>Regulatory Requirement</strong>&rdquo; means any law, ordinance, regulation, rule, judgment,
          order, declaration, decree, directive, legislative enactment, or other binding requirement of or by any
          Regulatory Authority. &nbsp; References to any Regulatory Requirement refer to such Regulatory Requirement in
          changed or supplemented form, or to a newly adopted Regulatory Requirement replacing a previous Regulatory
          Requirement.
        </p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <h1>Schedule iI</h1>

        <h1>SOFTWARE</h1>

        <h2>&nbsp;</h2>

        <p>
          Client receives access to Green Check Verified&rsquo;s web-based compliance management system. Base Software
          Features include:
        </p>

        <p>&nbsp;</p>

        <ul>
          <li>Account onboarding&nbsp;</li>
          <li>Compliance Rules Engine</li>
          <li>Compliance Guides</li>
          <li>Sales ingestion process</li>
        </ul>

        <p>&nbsp;</p>

        <p><u>Fees</u></p>

        <p>&nbsp;</p>

        <ul>
          <li>Setup Fee - $0</li>
          <li>Base Software Subscription - $0</li>
        </ul>

        <p>&nbsp;</p>

        <p><u>Banking Fees</u></p>

        <p>&nbsp;</p>

        <p>Not included in the scope of this agreement</p>

        <p>&nbsp;</p>

        <h1>Schedule iII</h1>

        <p>&nbsp;</p>

        <p><strong>DISPUTE RESOLUTION</strong></p>

        <ol>
          <li>
            <strong><u>General Intent</u></strong
            ><strong>.</strong>&nbsp; Any and all disputes between the parties arising from or relating to this
            Agreement or the relationship between the parties as reflected by this Agreement (each a
            &ldquo;<strong>Dispute</strong>&rdquo;) shall be resolved in accordance with this
            <strong><u>Schedule III</u></strong
            >. &nbsp;The intent of the parties is to identify and promptly attempt to resolve all Disputes. &nbsp;
            Except as provided otherwise elsewhere in this Agreement, before attempting to exercise any legal or
            equitable remedy, including termination of this Agreement for cause, each party shall follow the dispute
            resolution procedure set forth in this <strong><u>Schedule III</u></strong> (the &ldquo;<strong
              >Dispute Resolution Process</strong
            >&rdquo;). &nbsp;Any periods of time specified in this <strong><u>Schedule III</u></strong> shall be
            independent of, and may run concurrently with, any periods of time indicated in the Term. &nbsp;
          </li>
        </ol>

        <p>&nbsp;</p>

        <ol>
          <li>
            <strong><u>Notice</u></strong
            ><strong>.</strong>&nbsp; Each&nbsp;party shall notify the other party in writing within a reasonable period
            of time after becoming aware of any Dispute or of any breach by the other party of this Agreement, (a
            &ldquo;<strong>Breach</strong>&rdquo;). &nbsp;A notice of Dispute or Breach shall describe in reasonable
            detail the nature of the Dispute or Breach.
          </li>
        </ol>

        <p>&nbsp;</p>

        <ol>
          <li>
            <strong><u>Escalation</u></strong
            ><strong>.&nbsp;</strong> If either party delivers to the other a notice of Dispute or Breach, then the
            parties shall promptly engage in good faith negotiations between progressively more senior representatives
            of each party over a period of thirty (30) days. &nbsp;Notwithstanding the foregoing, a party will not be
            required to engage in discussions pursuant to this Section 3 of a Dispute or Breach beyond a time that is
            ninety (90) days before expiration of the statute of limitations applicable to that Dispute or Breach.
            &nbsp;All discussions conducted pursuant to this Section 3 will be treated as confidential settlement
            discussions and none of the proposals, counter-proposals or statements made during discussions will be
            admissible for any purpose in any other proceeding.
          </li>
        </ol>

        <p>&nbsp;</p>

        <ol>
          <li>
            <strong><u>Mediation</u></strong
            ><strong>.</strong>&nbsp; If the discussions described in Section 3 do not result in negotiated resolution,
            then either party may require the other to participate in non-binding commercial mediation proceedings,
            whereupon each party shall participate in such mediation in good faith in an effort to resolve their
            differences. &nbsp;The mediation shall be&nbsp;administered by the American Arbitration Association
            (&ldquo;<strong>AAA</strong>&rdquo;) under its Commercial Mediation Rules then in effect (insofar as such
            rules are not inconsistent with the provisions of this Agreement), and shall be conducted in the State of
            Connecticut and City of New Haven at a location mutually agreed to by the parties (or determined by the
            mediator, if the parties fail to so agree), before a sole, neutral, and impartial mediator mutually agreed
            to by the parties or, if the parties fail to so agree, chosen by the AAA. &nbsp;Any person who serves as
            mediator shall possess the qualifications necessary to serve as an arbitrator under the American Arbitration
            Association&rsquo;s Commercial Arbitration Rules for Large, Complex Commercial Disputes (with the intent of
            the foregoing being to ensure that the person serving as mediator possesses sufficient experience in large,
            complex commercial disputes to serve a useful role as mediator with respect to Disputes or Breaches arising
            under this Agreement) and, more specifically, shall possess significant knowledge and experience in the
            information technology industry. &nbsp;The person who serves as mediator hereunder for any given Dispute or
            Breach shall not be eligible to serve as an arbitrator for the same Dispute or Breach. &nbsp;The mediator
            shall be selected, as described above, and the mediation commenced, no later than twenty-one (21) days after
            the delivery of the applicable written demand for mediation. &nbsp;The mediation shall be concluded,
            regardless of whether the applicable Dispute or Breach has then been resolved, within sixty (60) days after
            delivery of the applicable written demand for mediation. &nbsp;Each party shall pay its own expenses
            incurred in connection with any mediation under this Section, and each party shall share equally the costs
            of the mediator and AAA and any other third-party costs and expenses of the mediation. &nbsp;The mediation
            will be treated as a confidential settlement discussion and none of the proposals, counter-proposals or
            statements made in the course of the mediation will be admissible for any purpose in any other proceeding.
            &nbsp;The mediator will not testify for either party in any later proceeding related to the dispute.
            &nbsp;No recording or transcript will be kept of the mediation proceedings. &nbsp;Notwithstanding the
            foregoing, a party will not be required to engage in mediation pursuant to this Section 4 of a Dispute or
            Breach beyond a time that is ninety (90) days before expiration of the statute of limitations applicable to
            that Dispute or Breach.
          </li>
        </ol>

        <p>&nbsp;</p>

        <ol>
          <li>
            <strong><u>Arbitration</u></strong
            ><strong>.</strong>&nbsp; Any Dispute&nbsp;or Breach that is not resolved by the discussions or the
            mediation required by this Agreement, including any issue regarding the extent to which any Dispute or
            Breach&nbsp;is subject to arbitration and the applicability or enforceability of these dispute resolution
            procedures, shall, subject to Section 6, be decided by binding arbitration administered by the AAA under its
            Complex Commercial Arbitration Rules then in effect (insofar as such rules are not inconsistent with the
            provisions of this Agreement), and shall be conducted, State of Connecticut and City of New Haven at a
            location mutually agreed to by the parties (or determined by the arbitrators, if the parties fail to so
            agree), before three arbitrators, each of whom shall&nbsp;possess the same qualifications and
            characteristics specified with respect to mediators in Section 4. &nbsp;Each of the parties shall
            choose&nbsp;one arbitrator and the third arbitrator shall be chosen by the first two arbitrators. &nbsp;If,
            for any reason, the parties cannot agree upon an arbitrator, either party fails to designate an arbitrator,
            such designated arbitrators fail to designate a third arbitrator, or any designated arbitrator refuses to
            serve in such capacity, an arbitrator shall be promptly designated by the AAA upon the demand of either
            party. &nbsp;Any counterclaims and other related actions shall be brought in the same venue. &nbsp;During
            the&nbsp;ninety (90) days following the commencement of arbitration, the parties will consult between
            themselves and with the arbitrator to define and limit the issues and will exchange those documents or other
            evidence each intends to use at the arbitration to support its case, and provide each other with names of
            all proposed witnesses. &nbsp; Each party shall cooperate fully in facilitating discovery. &nbsp;A majority
            of the arbitrators shall have the power to, as appropriate and consistently with the terms of this
            Agreement, determine and effect a fair resolution of the applicable Dispute or Breach, effect a termination
            of this Agreement, and award damages. &nbsp;The arbitrators (or a majority of them) shall decide all matters
            submitted to them within thirty (30) days following the conclusion of any necessary hearings. &nbsp; Any
            award rendered by a majority of the arbitrators shall be in writing and shall set forth in reasonable detail
            the facts of the Dispute or Breach, the decision of the arbitrators, and their reasons therefor. &nbsp; The
            award rendered by a majority of the arbitrators in any arbitration hereunder shall be final and binding upon
            the parties, and judgment on any such award may be entered in any court having jurisdiction thereof.
            &nbsp;The arbitrator shall have no power or authority to award exemplary or punitive damages, or
            non-monetary or equitable relief of any sort, or any relief not measured by the prevailing party&rsquo;s
            actual damages (adjustments for time value of money permitted), and will not make any decision inconsistent
            with this Agreement. &nbsp;All aspects of the arbitration and any award shall be confidential. &nbsp;Neither
            the parties nor the arbitrator may disclose the existence, content or results of the arbitration, except as
            necessary to comply with legal or regulatory requirements. &nbsp;Before making any such disclosure, a party
            will give written notice to all other parties and shall afford such parties a reasonable opportunity to
            protect their interests.
          </li>
        </ol>

        <p>&nbsp;</p>

        <ol>
          <li>
            <strong><u>Exceptions to Arbitration; Forum</u></strong
            ><strong>. &nbsp;</strong>Notwithstanding anything to the contrary in this Agreement, either party may (a)
            seek equitable remedies from a court of competent jurisdiction and (b) in lieu of arbitration pursuant to
            Section 5, litigate Disputes&nbsp;or Breaches concerning privacy, or ownership or misuse of
            proprietary/confidential information or intellectual property in courts of competent jurisdiction, as
            provided below, and upon commencement of any such proceeding, any arbitration then pending shall be stayed,
            insofar as it concerns the matters subject to decision by such court. &nbsp;Any court proceedings pursuant
            to the preceding sentence, or any matter that may for any reason be litigated before a court notwithstanding
            the arbitration provisions hereof, shall be litigated and decided solely and exclusively in a court having
            jurisdiction and which is located in the county (for state courts) or the district (for federal courts) of
            the&nbsp;State of Connecticut and City of New Haven. &nbsp;Any counterclaims and other related actions may
            be brought in the same venue. &nbsp;This choice of venue is intended by the parties to be mandatory and not
            permissive, thereby precluding the possibility of litigation of Disputes&nbsp;or Breaches in any other
            jurisdiction or venue. &nbsp;Each party hereby waives any right it may have to assert the doctrine of
            <em>forum non conviens</em> or any similar doctrine or to object to venue with respect to any proceeding
            brought in accordance with this paragraph, and stipulates that the state and federal courts used pursuant to
            this Agreement shall have <em>in personam</em> jurisdiction over each of them (and their respective
            indemnitees) for the purpose of litigating any such dispute, controversy or proceeding. &nbsp;Each party
            hereby authorizes and accepts service of process sufficient for personal jurisdiction in any action against
            it as contemplated by this action by registered or certified mail, return receipt requested, postage
            prepaid, to its address for the giving of notices as set forth in this Agreement. &nbsp;Nothing herein shall
            affect the right of any party to serve process in any other manner permitted by law. &nbsp;<strong
              >THE PARTIES HERETO WAIVE THEIR RIGHT TO A JURY TRIAL IN ANY ACTIONS THAT THEY MAY BRING, OR THAT MAY BE
              BROUGHT AGAINST THEM, UNDER OR IN CONNECTION WITH THIS AGREEMENT.</strong
            >
          </li>
        </ol>

        <p>&nbsp;</p>

        <ol>
          <li>
            <strong><u>Equitable Relief</u></strong
            ><strong>.&nbsp;</strong> In the event of any breach or threatened breach of the provisions of this
            Agreement concerning ownership or misuse of confidential information or intellectual property, the parties
            acknowledge that monetary damages may be an inadequate remedy and the innocent party may suffer irreparable
            harm. &nbsp;Accordingly, those provisions may be enforced by appropriate equitable relief pursuant to
            Section 6 upon a showing that the moving party has no adequate legal remedy, is likely to suffer irreparable
            harm, and satisfies other applicable requirements for equitable relief.
          </li>
        </ol>

        <p>&nbsp;</p>

        <ol>
          <li>
            <strong><u>Fees and Costs</u></strong
            ><strong>.</strong>&nbsp; During the pendency of any arbitral or court proceeding, each party shall bear its
            own attorneys&rsquo; fees and costs, and the parties shall each pay half the arbitration costs. &nbsp;The
            prevailing party in any arbitration or court proceeding under this Agreement will be entitled to recover its
            reasonable fees and costs incurred in the arbitration or proceeding including reasonable attorneys&rsquo;
            fees and costs and the costs of arbitration (but not the costs of mediation pursuant to Section 4) from the
            non-prevailing party, provided that the arbitrators or judge have the discretion to determine that there is
            no prevailing party or to eliminate or reduce the prevailing party&rsquo;s recovery of its fees and costs to
            the extent that the arbitrators or judge determine that full recovery thereof would be unreasonable or
            disproportionate to the harm suffered by the prevailing party.
          </li>
        </ol>
      </div>
      <div class="downloadPdf">
        <a class="downloadLink" href="assets/Green_Check-SaaS.pdf" download
          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path
              d="M20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.1,0A1.1,1.1,0,0,0,13.06,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Zm-4.71-4.71-.29.3V12a1,1,0,0,0-2,0v2.59l-.29-.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l2-2a1,1,0,0,0-1.42-1.42Z"
            />
          </svg>
          Download PDF</a
        >
      </div>
    </div>
    <div class="acceptText" data-cy="terms-of-service.component.acceptText">
      <span
        >By clicking Accept you are agreeing to Green Check’s User Agreement on behalf of {{ getDisplayName() }}.</span
      >
    </div>
    <div class="buttonRow">
      <gcv-button
        class="button cancel"
        buttonText="I Do Not Accept"
        type="tertiary"
        (clickEvent)="logout()"
      ></gcv-button>
      <gcv-button class="button accept" buttonText="I Accept" type="Primary" (clickEvent)="accept()"></gcv-button>
    </div>
  </div>
</div>
