import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as base64arraybuffer from 'base64-arraybuffer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { S3FileManagementService } from '../../services/s3FileManagement.service';
import { getAllAcceptedFileTypes, isFileExtensionPdf } from '@user-interface/gcv-ui';

@Component({
  selector: 'app-create-new-license-modal',
  templateUrl: './create-new-license-modal.component.html',
  styleUrls: ['./create-new-license-modal.component.scss'],
})
export class CreateNewLicenseModalComponent implements OnInit {
  @ViewChild('form') form: ElementRef;
  @ViewChild('form2') form2: ElementRef;

  @Input() licenseType: 'business' | 'employee';
  @Input() licenses;
  @Input() needReminder = false;
  @Output() formEmitter = new EventEmitter<FormGroup>();

  licenseInfo: FormGroup;
  ctrl: FormGroup;
  section = 0;
  shouldSave = true;
  acceptedFileTypes = getAllAcceptedFileTypes();

  public uploadedDocuments = [];
  public documentName = '';
  public documentDescription = '';
  public isEditing = false;
  objUrl;

  reminderTimePeriods = [
    {
      value: '1',
      viewValue: '1 week',
    },
    {
      value: '2',
      viewValue: '2 weeks',
    },
    {
      value: '3',
      viewValue: '3 weeks',
    },
    {
      value: '4',
      viewValue: '4 weeks',
    },
  ];

  constructor(
    private matDialogRef: MatDialogRef<CreateNewLicenseModalComponent>,
    private cdr: ChangeDetectorRef,
    private httpClient: HttpClient,
    private s3FileService: S3FileManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    matDialogRef.disableClose = true;
    if (data.licenses && !data.licenses.employee_license_required) {
      this.licenseType = 'business';
      this.section = 1;
    } else if (data.licenseType !== undefined) {
      this.licenseType = data.licenseType;
      this.section = 1;
    }

    if (data.needReminder !== undefined) {
      this.needReminder = data.needReminder;
    }

    if (data.licenses !== undefined) {
      this.licenses = data.licenses;
    }

    this.isEditing = data.isEditing;

    if (this.needReminder) {
      this.licenseInfo = new FormGroup({
        name: new FormControl(data.data ? data.data.license_name : '', Validators.required),
        number: new FormControl(data.data ? data.data.license_number : '', Validators.required),
        expiration: new FormControl(data.data ? data.data.expiration_date : '', Validators.required),
        reminder: new FormControl(''),
      });
    } else {
      this.licenseInfo = new FormGroup({
        name: new FormControl(data.data ? data.data.license_name : '', Validators.required),
        number: new FormControl(data.data ? data.data.license_number : '', Validators.required),
        expiration: new FormControl(data.data ? data.data.expiration_date : '', Validators.required),
      });
    }

    if (data.documents) {
      this.uploadedDocuments = data.documents;
      this.shouldSave = false;
    }

    this.ctrl = new FormGroup({
      s3Key: new FormControl(''),
      filename: new FormControl(''),
      data: new FormControl(''),
      name: new FormControl(''),
    });
  }

  ngOnInit() {}

  isPart1Valid() {
    return (
      this.licenseInfo.controls['name'].valid &&
      this.licenseInfo.controls['number'].valid &&
      this.licenseInfo.controls['expiration'].valid &&
      this.uploadedDocuments.length > 0
    );
  }

  onFileEvent(event) {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    this.ctrl.controls['filename'].setValue(file.name);
    const dataReader = new FileReader();
    dataReader.onload = e => {
      const arrayBuffer = e.target['result'];
      const base64 = base64arraybuffer.encode(<ArrayBuffer>arrayBuffer);
      this.ctrl.controls['data'].setValue(base64);
      this.uploadedDocuments.push(this.ctrl.getRawValue());
      this.shouldSave = true;
      this.reset();
    };
    dataReader.readAsArrayBuffer(file);
    const urlReader = new FileReader();
    urlReader.onload = e => {
      this.objUrl = window.URL.createObjectURL(file);
      this.cdr.markForCheck();
    };
    urlReader.readAsDataURL(file);

    if (!this.ctrl.controls['filename'].value && !this.ctrl.controls['data'].value) {
      this.ctrl.controls['filename'].setValue('');
      this.ctrl.controls['data'].setValue(null);
      this.cdr.markForCheck();
    }
  }

  selectLicense(type) {
    this.licenseType = type;
    this.section = 1;
  }

  close(results = null) {
    if (this.shouldSave) {
      const forms = this.licenseInfo.getRawValue();
      this.reset();
      this.matDialogRef.close({ results, forms, licenseType: this.licenseType });
      this.uploadedDocuments = [];
    } else {
      this.matDialogRef.close({ forms: this.licenseInfo.getRawValue(), licenseType: this.licenseType });
    }
  }

  cancel() {
    this.reset();
    this.matDialogRef.close(null);
  }

  back() {
    this.section--;
  }

  removeDocument(i: number) {
    this.uploadedDocuments.splice(i, 1);
  }

  submitForm() {
    if (this.section === 1 && this.needReminder) {
      this.section = 2;
    } else {
      this.formEmitter.emit(this.licenseInfo);
      this.close({
        license: this.licenseInfo,
        documents: this.uploadedDocuments,
      });
    }
  }

  save() {
    this.close({
      documents: this.uploadedDocuments,
    });
  }

  private reset() {
    this.ctrl.controls.s3Key.setValue('');
    this.ctrl.controls.data.setValue('');
    this.ctrl.controls.filename.setValue('');
    if (this.form) {
      this.form.nativeElement.reset();
    }

    if (this.form2) {
      this.form2.nativeElement.reset();
    }
  }

  async viewDocument(doc, i) {
    if (this.objUrl) {
      window.open(this.objUrl, '_blank');
    } else {
      const { orgId, userType, document_id, filename } = doc;
      const blob = await this.s3FileService.viewDocument({ orgId, userType }, document_id);

      const fileExt = filename
        .split('.')
        .pop()
        .toLowerCase();
      const fileType =
        fileExt === 'png'
          ? 'image/png'
          : fileExt === ('jpg' || 'jpeg')
          ? 'image/jpeg'
          : fileExt === 'pdf'
          ? 'application/pdf'
          : fileExt === 'txt'
          ? 'text/plain'
          : fileExt === 'csv'
          ? 'text/csv'
          : '';

      this.s3FileService.openDocumentInNewTab(blob, fileType, filename);
    }
  }
}
