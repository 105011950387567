import * as React from 'react';
import invariant from 'invariant';
import { v4 as uuid } from 'uuid';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as ReactDOM from 'react-dom';
import { Subject } from 'rxjs';

export interface WrapperProps {
  [k: string]: any;
}

@Component({
  selector: 'app-react-wrapper',
  template: '<span [id]="rootDomID"></span>',
})
export class ReactWrapperComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  public unsubscribe$ = new Subject<void>();

  @Input() wrappedProps?: WrapperProps;
  @Input() wrappedComponent: React.FunctionComponent<{}> | React.ComponentClass<{}, any>;
  @Input() emitData: any;

  rootDomID: string;
  rootDomNode: any;

  protected getRootDomNode() {
    const node = document.getElementById(this.rootDomID);

    invariant(node, `Node '${this.rootDomID} not found!`);

    return node;
  }

  protected getProps(): WrapperProps {
    return { ...this.wrappedProps, emitData: this.emitData };
  }

  private isMounted(): boolean {
    return !!this.rootDomID && !!this.wrappedComponent;
  }

  protected render() {
    if (this.isMounted()) {
      this.rootDomNode = this.getRootDomNode();
      ReactDOM.render(React.createElement(this.wrappedComponent, this.getProps()), this.rootDomNode);
    }
  }

  ngOnInit() {
    this.rootDomID = uuid();
  }

  ngOnChanges(changes) {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    if (this.rootDomNode) {
      ReactDOM.unmountComponentAtNode(this.rootDomNode);
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
