import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-due-diligence-requirement-list',
  templateUrl: './due-diligence-requirement-list.component.html',
  styleUrls: ['./due-diligence-requirement-list.component.scss'],
})
export class DueDiligenceRequirementListComponent implements OnInit {
  @Input() requirements: { name: string }[];
  @Input() helpDescription: string;
  @Output() helpClickEvent = new EventEmitter<void>();
  @Input() helpLink: string;
  @Input() showNeedHelpLinks = false;

  constructor() {}

  ngOnInit() {}

  handleHelpEvent() {
    this.helpClickEvent.emit();
    window.open(this.helpLink, '_blank');
  }
}
