import { FetchStrategy } from '@gcv/shared';

export * from './fincen-form-view-constants';

export const MINIMUM_DEPOSIT_IN_US_DOLLARS = 25;
export interface EntityType {
  value: string;
  viewValue: string;
}

export interface Hour {
  value: string;
  viewValue: string;
}

export interface IsOpen {
  value: string;
  viewValue: string;
}

export interface State {
  viewValue: string;
}

export interface IdType {
  value: string;
  viewValue: string;
}

export interface Country {
  value: string;
  viewValue: string;
}

export const entitytypes: EntityType[] = [
  { value: 'sole', viewValue: 'Sole Proprietorship' },
  {
    value: 'partnership',
    viewValue: 'General Partnership',
  },
  { value: 'corporation', viewValue: 'Corporation' },
  { value: 'llc', viewValue: 'Limited Liability Company' },
];

export const isOpen: IsOpen[] = [
  { value: 'open', viewValue: 'Open' },
  { value: 'closed', viewValue: 'Closed' },
];

export const states: any[] = [
  { viewValue: 'Alabama', value: 'AL' },
  { viewValue: 'Alaska', value: 'AK' },
  { viewValue: 'Arizona', value: 'AZ' },
  { viewValue: 'Arkansas', value: 'AR' },
  { viewValue: 'California', value: 'CA' },
  { viewValue: 'Colorado', value: 'CO' },
  { viewValue: 'Connecticut', value: 'CT' },
  { viewValue: 'Delaware', value: 'DE' },
  { viewValue: 'Florida', value: 'FL' },
  { viewValue: 'Georgia', value: 'GA' },
  { viewValue: 'Hawaii', value: 'HI' },
  { viewValue: 'Idaho', value: 'ID' },
  { viewValue: 'Illinois', value: 'IL' },
  { viewValue: 'Indiana', value: 'IN' },
  { viewValue: 'Iowa', value: 'IA' },
  { viewValue: 'Kansas', value: 'KS' },
  { viewValue: 'Kentucky', value: 'KY' },
  { viewValue: 'Louisiana', value: 'LA' },
  { viewValue: 'Maine', value: 'ME' },
  { viewValue: 'Maryland', value: 'MD' },
  { viewValue: 'Massachusetts', value: 'MA' },
  { viewValue: 'Michigan', value: 'MI' },
  { viewValue: 'Minnesota', value: 'MN' },
  { viewValue: 'Mississippi', value: 'MS' },
  { viewValue: 'Missouri', value: 'MO' },
  { viewValue: 'Montana', value: 'MT' },
  { viewValue: 'Nebraska', value: 'NE' },
  { viewValue: 'Nevada', value: 'NV' },
  { viewValue: 'New Hampshire', value: 'NH' },
  { viewValue: 'New Jersey', value: 'NJ' },
  { viewValue: 'New Mexico', value: 'NM' },
  { viewValue: 'New York', value: 'NY' },
  { viewValue: 'North Carolina', value: 'NC' },
  { viewValue: 'North Dakota', value: 'ND' },
  { viewValue: 'Ohio', value: 'OH' },
  { viewValue: 'Oklahoma', value: 'OK' },
  { viewValue: 'Oregon', value: 'OR' },
  { viewValue: 'Pennsylvania', value: 'PA' },
  { viewValue: 'Rhode Island', value: 'RI' },
  { viewValue: 'South Carolina', value: 'SC' },
  { viewValue: 'South Dakota', value: 'SD' },
  { viewValue: 'Tennessee', value: 'TN' },
  { viewValue: 'Texas', value: 'TX' },
  { viewValue: 'Utah', value: 'UT' },
  { viewValue: 'Vermont', value: 'VT' },
  { viewValue: 'Virginia', value: 'VA' },
  { viewValue: 'Washington', value: 'WA' },
  { viewValue: 'West Virginia', value: 'WV' },
  { viewValue: 'Wisconsin', value: 'WI' },
  { viewValue: 'Wyoming', value: 'WY' },
];

export const supportDispensaryStates = [
  { viewValue: 'Arizona', value: 'AZ' },
  { viewValue: 'Arkansas', value: 'AR' },
  { viewValue: 'California', value: 'CA' },
  { viewValue: 'Colorado', value: 'CO' },
  { viewValue: 'Connecticut', value: 'CT' },
  { viewValue: 'Florida', value: 'FL' },
  { viewValue: 'Massachusetts', value: 'MA' },
  { viewValue: 'Maine', value: 'ME' },
  { viewValue: 'Maryland', value: 'MD' },
  { viewValue: 'Michigan', value: 'MI' },
  { viewValue: 'Missouri', value: 'MO' },
  { viewValue: 'New Jersey', value: 'NJ' },
  { viewValue: 'Nevada', value: 'NV' },
  { viewValue: 'Ohio', value: 'OH' },
  { viewValue: 'Oklahoma', value: 'OK' },
  { viewValue: 'Illinois', value: 'IL' },
  { viewValue: 'West Virginia', value: 'WV' },
  { viewValue: 'South Dakota', value: 'SD' },
];
export const hours: Hour[] = [
  { value: `am12`, viewValue: `12:00 AM` },
  { value: `am1230`, viewValue: `12:30 AM` },
  { value: `am1`, viewValue: `1:00 AM` },
  { value: `am130`, viewValue: `1:30 AM` },
  { value: `am2`, viewValue: `2:00 AM` },
  { value: `am230`, viewValue: `2:30 AM` },
  { value: `am3`, viewValue: `3:00 AM` },
  { value: `am330`, viewValue: `3:30 AM` },
  { value: `am4`, viewValue: `4:00 AM` },
  { value: `am430`, viewValue: `4:30 AM` },
  { value: `am5`, viewValue: `5:00 AM` },
  { value: `am530`, viewValue: `5:30 AM` },
  { value: `am6`, viewValue: `6:00 AM` },
  { value: `am630`, viewValue: `6:30 AM` },
  { value: `am7`, viewValue: `7:00 AM` },
  { value: `am730`, viewValue: `7:30 AM` },
  { value: `am8`, viewValue: `8:00 AM` },
  { value: `am830`, viewValue: `8:30 AM` },
  { value: `am9`, viewValue: `9:00 AM` },
  { value: `am930`, viewValue: `9:30 AM` },
  { value: `am10`, viewValue: `10:00 AM` },
  { value: `am1030`, viewValue: `10:30 AM` },
  { value: `am11`, viewValue: `11:00 AM` },
  { value: `am1130`, viewValue: `11:30 AM` },
  { value: `pm12`, viewValue: `12:00 PM` },
  { value: `pm1230`, viewValue: `12:30 PM` },
  { value: `pm1`, viewValue: `1:00 PM` },
  { value: `pm130`, viewValue: `1:30 PM` },
  { value: `pm2`, viewValue: `2:00 PM` },
  { value: `pm230`, viewValue: `2:30 PM` },
  { value: `pm3`, viewValue: `3:00 PM` },
  { value: `pm330`, viewValue: `3:30 PM` },
  { value: `pm4`, viewValue: `4:00 PM` },
  { value: `pm430`, viewValue: `4:30 PM` },
  { value: `pm5`, viewValue: `5:00 PM` },
  { value: `pm530`, viewValue: `5:30 PM` },
  { value: `pm6`, viewValue: `6:00 PM` },
  { value: `pm630`, viewValue: `6:30 PM` },
  { value: `pm7`, viewValue: `7:00 PM` },
  { value: `pm730`, viewValue: `7:30 PM` },
  { value: `pm8`, viewValue: `8:00 PM` },
  { value: `pm830`, viewValue: `8:30 PM` },
  { value: `pm9`, viewValue: `9:00 PM` },
  { value: `pm930`, viewValue: `9:30 PM` },
  { value: `pm10`, viewValue: `10:00 PM` },
  { value: `pm1030`, viewValue: `10:30 PM` },
  { value: `pm11`, viewValue: `11:00 PM` },
  { value: `pm1130`, viewValue: `11:30 PM` },
];

export const idtypes: IdType[] = [
  { value: 'passport', viewValue: 'Passport' },
  { value: 'dlicense', viewValue: `Driver's License` },
  { value: 'nondriverid', viewValue: 'Non-Driver State ID' },
  { value: 'ssnumber', viewValue: 'Social Security Number' },
];

export const countries: Country[] = [
  { value: `unitedstates`, viewValue: `United States` },
  { value: `afghanistan`, viewValue: `Afghanistan` },
  { value: `albania`, viewValue: `Albania` },
  { value: `algeria`, viewValue: `Algeria` },
  { value: `americansamoa`, viewValue: `American Samoa` },
  { value: `andorra`, viewValue: `Andorra` },
  { value: `angola`, viewValue: `Angola` },
  { value: `anguilla`, viewValue: `Anguilla` },
  { value: `antiguabarbuda`, viewValue: `Antigua & Barbuda` },
  { value: `argentina`, viewValue: `Argentina` },
  { value: `armenia`, viewValue: `Armenia` },
  { value: `aruba`, viewValue: `Aruba` },
  { value: `australia`, viewValue: `Australia` },
  { value: `austria`, viewValue: `Austria` },
  { value: `azerbaijan`, viewValue: `Azerbaijan` },
  { value: `bahamasthe`, viewValue: `Bahamas, The` },
  { value: `bahrain`, viewValue: `Bahrain` },
  { value: `bangladesh`, viewValue: `Bangladesh` },
  { value: `barbados`, viewValue: `Barbados` },
  { value: `belarus`, viewValue: `Belarus` },
  { value: `belgium`, viewValue: `Belgium` },
  { value: `belize`, viewValue: `Belize` },
  { value: `benin`, viewValue: `Benin` },
  { value: `bermuda`, viewValue: `Bermuda` },
  { value: `bhutan`, viewValue: `Bhutan` },
  { value: `bolivia`, viewValue: `Bolivia` },
  { value: `bosniaherzegovina`, viewValue: `Bosnia & Herzegovina` },
  { value: `botswana`, viewValue: `Botswana` },
  { value: `brazil`, viewValue: `Brazil` },
  { value: `britishvirginis`, viewValue: `British Virgin Is.` },
  { value: `brunei`, viewValue: `Brunei` },
  { value: `bulgaria`, viewValue: `Bulgaria` },
  { value: `burkinafaso`, viewValue: `Burkina Faso` },
  { value: `burma`, viewValue: `Burma` },
  { value: `burundi`, viewValue: `Burundi` },
  { value: `cambodia`, viewValue: `Cambodia` },
  { value: `cameroon`, viewValue: `Cameroon` },
  { value: `canada`, viewValue: `Canada` },
  { value: `capeverde`, viewValue: `Cape Verde` },
  { value: `caymanislands`, viewValue: `Cayman Islands` },
  { value: `centralafricanrep`, viewValue: `Central African Rep.` },
  { value: `chad`, viewValue: `Chad` },
  { value: `chile`, viewValue: `Chile` },
  { value: `china`, viewValue: `China` },
  { value: `colombia`, viewValue: `Colombia` },
  { value: `comoros`, viewValue: `Comoros` },
  { value: `congodemrep`, viewValue: `Congo, Dem. Rep.` },
  { value: `congorepubofthe`, viewValue: `Congo, Repub. of the` },
  { value: `cookislands`, viewValue: `Cook Islands` },
  { value: `costarica`, viewValue: `Costa Rica` },
  { value: `coted'ivoire`, viewValue: `Cote d'Ivoire` },
  { value: `croatia`, viewValue: `Croatia` },
  { value: `cuba`, viewValue: `Cuba` },
  { value: `cyprus`, viewValue: `Cyprus` },
  { value: `czechrepublic`, viewValue: `Czech Republic` },
  { value: `denmark`, viewValue: `Denmark` },
  { value: `djibouti`, viewValue: `Djibouti` },
  { value: `dominica`, viewValue: `Dominica` },
  { value: `dominicanrepublic`, viewValue: `Dominican Republic` },
  { value: `easttimor`, viewValue: `East Timor` },
  { value: `ecuador`, viewValue: `Ecuador` },
  { value: `egypt`, viewValue: `Egypt` },
  { value: `elsalvador`, viewValue: `El Salvador` },
  { value: `equatorialguinea`, viewValue: `Equatorial Guinea` },
  { value: `eritrea`, viewValue: `Eritrea` },
  { value: `estonia`, viewValue: `Estonia` },
  { value: `ethiopia`, viewValue: `Ethiopia` },
  { value: `faroeislands`, viewValue: `Faroe Islands` },
  { value: `fiji`, viewValue: `Fiji` },
  { value: `finland`, viewValue: `Finland` },
  { value: `france`, viewValue: `France` },
  { value: `frenchguiana`, viewValue: `French Guiana` },
  { value: `frenchpolynesia`, viewValue: `French Polynesia` },
  { value: `gabon`, viewValue: `Gabon` },
  { value: `gambiathe`, viewValue: `Gambia, The` },
  { value: `gazastrip`, viewValue: `Gaza Strip` },
  { value: `georgia`, viewValue: `Georgia` },
  { value: `germany`, viewValue: `Germany` },
  { value: `ghana`, viewValue: `Ghana` },
  { value: `gibraltar`, viewValue: `Gibraltar` },
  { value: `greece`, viewValue: `Greece` },
  { value: `greenland`, viewValue: `Greenland` },
  { value: `grenada`, viewValue: `Grenada` },
  { value: `guadeloupe`, viewValue: `Guadeloupe` },
  { value: `guam`, viewValue: `Guam` },
  { value: `guatemala`, viewValue: `Guatemala` },
  { value: `guernsey`, viewValue: `Guernsey` },
  { value: `guinea`, viewValue: `Guinea` },
  { value: `guinea-bissau`, viewValue: `Guinea-Bissau` },
  { value: `guyana`, viewValue: `Guyana` },
  { value: `haiti`, viewValue: `Haiti` },
  { value: `honduras`, viewValue: `Honduras` },
  { value: `hongkong`, viewValue: `Hong Kong` },
  { value: `hungary`, viewValue: `Hungary` },
  { value: `iceland`, viewValue: `Iceland` },
  { value: `india`, viewValue: `India` },
  { value: `indonesia`, viewValue: `Indonesia` },
  { value: `iran`, viewValue: `Iran` },
  { value: `iraq`, viewValue: `Iraq` },
  { value: `ireland`, viewValue: `Ireland` },
  { value: `isleofman`, viewValue: `Isle of Man` },
  { value: `israel`, viewValue: `Israel` },
  { value: `italy`, viewValue: `Italy` },
  { value: `jamaica`, viewValue: `Jamaica` },
  { value: `japan`, viewValue: `Japan` },
  { value: `jersey`, viewValue: `Jersey` },
  { value: `jordan`, viewValue: `Jordan` },
  { value: `kazakhstan`, viewValue: `Kazakhstan` },
  { value: `kenya`, viewValue: `Kenya` },
  { value: `kiribati`, viewValue: `Kiribati` },
  { value: `koreanorth`, viewValue: `Korea, North` },
  { value: `koreasouth`, viewValue: `Korea, South` },
  { value: `kuwait`, viewValue: `Kuwait` },
  { value: `kyrgyzstan`, viewValue: `Kyrgyzstan` },
  { value: `laos`, viewValue: `Laos` },
  { value: `latvia`, viewValue: `Latvia` },
  { value: `lebanon`, viewValue: `Lebanon` },
  { value: `lesotho`, viewValue: `Lesotho` },
  { value: `liberia`, viewValue: `Liberia` },
  { value: `libya`, viewValue: `Libya` },
  { value: `liechtenstein`, viewValue: `Liechtenstein` },
  { value: `lithuania`, viewValue: `Lithuania` },
  { value: `luxembourg`, viewValue: `Luxembourg` },
  { value: `macau`, viewValue: `Macau` },
  { value: `macedonia`, viewValue: `Macedonia` },
  { value: `madagascar`, viewValue: `Madagascar` },
  { value: `malawi`, viewValue: `Malawi` },
  { value: `malaysia`, viewValue: `Malaysia` },
  { value: `maldives`, viewValue: `Maldives` },
  { value: `mali`, viewValue: `Mali` },
  { value: `malta`, viewValue: `Malta` },
  { value: `marshallislands`, viewValue: `Marshall Islands` },
  { value: `martinique`, viewValue: `Martinique` },
  { value: `mauritania`, viewValue: `Mauritania` },
  { value: `mauritius`, viewValue: `Mauritius` },
  { value: `mayotte`, viewValue: `Mayotte` },
  { value: `mexico`, viewValue: `Mexico` },
  { value: `micronesiafedst`, viewValue: `Micronesia, Fed. St.` },
  { value: `moldova`, viewValue: `Moldova` },
  { value: `monaco`, viewValue: `Monaco` },
  { value: `mongolia`, viewValue: `Mongolia` },
  { value: `montserrat`, viewValue: `Montserrat` },
  { value: `morocco`, viewValue: `Morocco` },
  { value: `mozambique`, viewValue: `Mozambique` },
  { value: `namibia`, viewValue: `Namibia` },
  { value: `nauru`, viewValue: `Nauru` },
  { value: `nepal`, viewValue: `Nepal` },
  { value: `netherlands`, viewValue: `Netherlands` },
  { value: `netherlandsantilles`, viewValue: `Netherlands Antilles` },
  { value: `newcaledonia`, viewValue: `New Caledonia` },
  { value: `newzealand`, viewValue: `New Zealand` },
  { value: `nicaragua`, viewValue: `Nicaragua` },
  { value: `niger`, viewValue: `Niger` },
  { value: `nigeria`, viewValue: `Nigeria` },
  { value: `nmarianaislands`, viewValue: `N. Mariana Islands` },
  { value: `norway`, viewValue: `Norway` },
  { value: `oman`, viewValue: `Oman` },
  { value: `pakistan`, viewValue: `Pakistan` },
  { value: `palau`, viewValue: `Palau` },
  { value: `panama`, viewValue: `Panama` },
  { value: `papuanewguinea`, viewValue: `Papua New Guinea` },
  { value: `paraguay`, viewValue: `Paraguay` },
  { value: `peru`, viewValue: `Peru` },
  { value: `philippines`, viewValue: `Philippines` },
  { value: `poland`, viewValue: `Poland` },
  { value: `portugal`, viewValue: `Portugal` },
  { value: `puertorico`, viewValue: `Puerto Rico` },
  { value: `qatar`, viewValue: `Qatar` },
  { value: `reunion`, viewValue: `Reunion` },
  { value: `romania`, viewValue: `Romania` },
  { value: `russia`, viewValue: `Russia` },
  { value: `rwanda`, viewValue: `Rwanda` },
  { value: `sainthelena`, viewValue: `Saint Helena` },
  { value: `saintkittsnevis`, viewValue: `Saint Kitts & Nevis` },
  { value: `saintlucia`, viewValue: `Saint Lucia` },
  { value: `stpierremiquelon`, viewValue: `St Pierre & Miquelon` },
  {
    value: `saintvincentandthegrenadines`,
    viewValue: `Saint Vincent and the Grenadines`,
  },
  { value: `samoa`, viewValue: `Samoa` },
  { value: `sanmarino`, viewValue: `San Marino` },
  { value: `saotomeprincipe`, viewValue: `Sao Tome & Principe` },
  { value: `saudiarabia`, viewValue: `Saudi Arabia` },
  { value: `senegal`, viewValue: `Senegal` },
  { value: `serbia`, viewValue: `Serbia` },
  { value: `seychelles`, viewValue: `Seychelles` },
  { value: `sierraleone`, viewValue: `Sierra Leone` },
  { value: `singapore`, viewValue: `Singapore` },
  { value: `slovakia`, viewValue: `Slovakia` },
  { value: `slovenia`, viewValue: `Slovenia` },
  { value: `solomonislands`, viewValue: `Solomon Islands` },
  { value: `somalia`, viewValue: `Somalia` },
  { value: `southafrica`, viewValue: `South Africa` },
  { value: `spain`, viewValue: `Spain` },
  { value: `srilanka`, viewValue: `Sri Lanka` },
  { value: `sudan`, viewValue: `Sudan` },
  { value: `suriname`, viewValue: `Suriname` },
  { value: `swaziland`, viewValue: `Swaziland` },
  { value: `sweden`, viewValue: `Sweden` },
  { value: `switzerland`, viewValue: `Switzerland` },
  { value: `syria`, viewValue: `Syria` },
  { value: `taiwan`, viewValue: `Taiwan` },
  { value: `tajikistan`, viewValue: `Tajikistan` },
  { value: `tanzania`, viewValue: `Tanzania` },
  { value: `thailand`, viewValue: `Thailand` },
  { value: `togo`, viewValue: `Togo` },
  { value: `tonga`, viewValue: `Tonga` },
  { value: `trinidadtobago`, viewValue: `Trinidad & Tobago` },
  { value: `tunisia`, viewValue: `Tunisia` },
  { value: `turkey`, viewValue: `Turkey` },
  { value: `turkmenistan`, viewValue: `Turkmenistan` },
  { value: `turkscaicosis`, viewValue: `Turks & Caicos Is` },
  { value: `tuvalu`, viewValue: `Tuvalu` },
  { value: `uganda`, viewValue: `Uganda` },
  { value: `ukraine`, viewValue: `Ukraine` },
  { value: `unitedarabemirates`, viewValue: `United Arab Emirates` },
  { value: `unitedkingdom`, viewValue: `United Kingdom` },
  { value: `uruguay`, viewValue: `Uruguay` },
  { value: `uzbekistan`, viewValue: `Uzbekistan` },
  { value: `vanuatu`, viewValue: `Vanuatu` },
  { value: `venezuela`, viewValue: `Venezuela` },
  { value: `vietnam`, viewValue: `Vietnam` },
  { value: `virginislands`, viewValue: `Virgin Islands` },
  { value: `wallisandfutuna`, viewValue: `Wallis and Futuna` },
  { value: `westbank`, viewValue: `West Bank` },
  { value: `westernsahara`, viewValue: `Western Sahara` },
  { value: `yemen`, viewValue: `Yemen` },
  { value: `zambia`, viewValue: `Zambia` },
  { value: `zimbabwe`, viewValue: `Zimbabwe` },
];

export interface SelectValues {
  value: string;
  viewValue?: string;
}

export const DEFAULT_TIME_FILTER = 'last30Days';
export const DEFAULT_SELECTED_RANGE_OUTPUT = 'Last 30 days';

export const totalSalesMap = {
  lastWeek: 'last week',
  last30Days: 'last 30 days',
  lastMonth: 'last month',
  lastQuarter: 'last quarter',
  lastYear: 'last year',
  thisMonth: 'this month',
  thisQuarter: 'this quarter',
  thisWeek: 'this week',
  thisYear: 'this year',
  today: 'today',
  yesterday: 'yesterday',
};

export const DATES = [
  'Today',
  'Yesterday',
  'This week',
  'This month',
  'This quarter',
  'This year',
  'Last week',
  'Last month',
  'Last quarter',
  'Last year',
  'Last 30 days',
];

export const fetchStrategies: FetchStrategy[] = ['automatic', 'upload'];
