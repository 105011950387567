import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { GcvModal, GcvButton } from '../../../lib';
import { SubTitle, UploadContainer, VerticalSpacer } from './styles';
import { User } from '@gcv/shared';

import { useApi, getEndpoint } from '../../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { GcvInputTextArea } from '../../../lib/GcvInputTextArea/GcvInputTextArea';
import { api } from 'libs/react-ui/src/api';

interface AddCommentModalProps {
  updateApplication: () => void;
  toggleModal: () => void;
  modalOpen: boolean;
  dispensaryId: string;
  requirementId: string;
  title: string;
  subTitle: string;
  userMap: { [id: string]: User };
}

export const AddCommentModal = ({
  updateApplication,
  dispensaryId,
  title,
  subTitle,
  requirementId,
  toggleModal,
  modalOpen,
}: AddCommentModalProps) => {
  const apiClient = api();
  const { register, handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });

  const [loading, setLoading] = useState(false);

  const addComment = comment => {
    const body = {
      comment,
    };

    apiClient.documentManagement
      .addCommentToRequirement(dispensaryId, requirementId, body, () => {})
      .then(() => {
        updateApplication();
        toggleModal();
        setLoading(false);
      })
      .catch(e => {
        alert('Error adding a comment, please contact support@greencheckverified.com');
        setLoading(false);
      });
  };

  const onSubmit = ({ comment }) => {
    addComment(comment);
    setLoading(true);
  };

  return (
    <>
      <GcvModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        backButton={
          <GcvButton onClick={toggleModal} tertiary={true} data-cy="cancel-button">
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={handleSubmit(onSubmit)} primary={true} disabled={!formState.isValid} data-cy="add-button">
            Add
          </GcvButton>
        }
        title={title}
        loading={loading}
      >
        <SubTitle>{subTitle}</SubTitle>
        {
          <UploadContainer>
            <VerticalSpacer>
              <GcvInputTextArea
                {...form}
                register={register}
                name="comment"
                label="Add a comment to this requirement"
                rules={{ required: true }}
                testDataTag="add-comment-textbox"
              />
            </VerticalSpacer>
          </UploadContainer>
        }
      </GcvModal>
    </>
  );
};
