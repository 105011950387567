import React from 'react';
import { RouteObject } from '../../util/types';
import { CompanyProfile } from './CompanyProfile.page';

export const routes: RouteObject[] = [
  {
    path: '/secure/bank/company/:section',
    render: props => <CompanyProfile {...props}></CompanyProfile>,
  },
];
