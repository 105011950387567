<div class="inputWrapper">
  <div [ngClass]="calculateClasses()">
    <span>{{ buttonText }}</span>
    <input
      (change)="onFileEvent($event)"
      (click)="handleClick($event)"
      accept=".json, .csv"
      data-cy="file-button.component"
      name="myfile"
      type="file"
      id="gcvFileButtonComponentInput"
    />
  </div>
</div>
