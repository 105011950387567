import React, { useState, useEffect } from 'react';
import { Dispensary } from '@gcv/shared';
import { GcvButton } from '../../../lib/GcvButton/GcvButton';
import { ExportActivityModal } from './ExportActivityModal';
import { ExportActivityResponseModal } from './ExportActivityResponseModal';
import { ActivityReportingFIPayload as Payload } from '.';

interface Props {
  accounts: Dispensary[];
  exportActivityFailure?: boolean;
  exportActivitySuccess?: boolean;
  emitData: (payload: Payload) => any;
}

export const ActivityReportingFI: any = (props: Props) => {
  const [modalActive, setModalActive] = useState(false);
  const [responseModalActive, setResponseModalActive] = useState(false);

  useEffect(() => {
    if (props.exportActivityFailure || props.exportActivitySuccess) {
      setModalActive(false);

      setTimeout(() => setResponseModalActive(true), 100);
    }
  }, [props.exportActivityFailure, props.exportActivitySuccess]);

  const toggleModal = () => {
    setModalActive(!modalActive);
  };

  const toggleResponseModal = () => {
    setResponseModalActive(!responseModalActive);
  };

  const handleSubmit = payload => {
    props.emitData(payload);
  };

  if (!props.accounts) {
    return null;
  }

  return (
    <>
      <div id="modal-root"></div>
      <GcvButton onClick={toggleModal} primary>
        Export Activity
      </GcvButton>

      <ExportActivityModal
        active={modalActive}
        toggleModal={toggleModal}
        accounts={props.accounts}
        onSubmit={handleSubmit}
      />

      {props.exportActivitySuccess ? (
        <ExportActivityResponseModal
          headerText="Your file is on its way"
          bodyText="Your file is being prepared now, When it’s ready, a link to your file will appear in your notifications. You’ll also receive a confirmation email."
          type="success"
          active={responseModalActive}
          toggleModal={toggleResponseModal}
          buttonComponent={
            <GcvButton primary onClick={toggleResponseModal}>
              Great!
            </GcvButton>
          }
        ></ExportActivityResponseModal>
      ) : null}
      {props.exportActivityFailure ? (
        <ExportActivityResponseModal
          headerText="Oops, something went wrong"
          bodyText="Please try the export again. For help, email us at support@greencheckverified.com."
          type="success"
          active={responseModalActive}
          toggleModal={toggleResponseModal}
          buttonComponent={
            <GcvButton secondary onClick={toggleResponseModal}>
              Close
            </GcvButton>
          }
        ></ExportActivityResponseModal>
      ) : null}
    </>
  );
};
