import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Button, createStyles, Grid, makeStyles, TextareaAutosize } from '@material-ui/core';

import { DynamicUi } from '../../shared/DynamicUiGenerator/ui-factory';
import { GcvInputForm, GcvLoading } from '../../lib';
import { api } from '../../api';
import { selectBankState } from 'libs/gcv-state/src/lib/state/bank/bank.selectors';
import { Bank, Dispensary } from '@gcv/shared';

const useStyles = makeStyles(() =>
  createStyles({
    widthFull: {
      width: '100%',
    },
  })
);

export const GcvCustomFields: React.FC = () => {
  const apiClient = api();
  const classes = useStyles();
  const methods = useForm({ mode: 'onChange' });

  const [isLoading, setIsLoading] = useState(false);
  const [uiJson, setUiJson] = useState('');
  const [formOutput, setFormOutput] = useState();

  const loadBank = data => {
    const bankId = data.bankId;

    if (!bankId) {
      alert('Please enter a bank ID');
      return;
    }

    apiClient.banks.getBankById(bankId, setIsLoading).then(r => {
      const bank = r as Bank;
      const customFields = bank.due_diligence_template.custom_fields;

      // parse and re-stringify to apply proper formatting
      setUiJson(customFields ? JSON.stringify(JSON.parse(customFields.template_json), null, 2) : '{}');
    });
  };

  const saveCustomFields = data => {
    const bankId = data.bankId;
    // parse and re-stringify to remove extra characters
    const uiString = JSON.stringify(JSON.parse(uiJson));

    apiClient.banks
      .upsertBankCustomFieldsTemplate(bankId, uiString, setIsLoading)
      .then(() => {
        alert('JSON Saved');
      })
      .catch(e => {
        alert(`Error: ${e.message}`);
      });
  };

  const onSubmit = (data: any): Promise<Dispensary> => {
    setFormOutput(data);

    //mock out an updated dispensary from an API call
    const dispensary = {
      due_diligence: {
        bank_custom_fields: {
          responses: data,
        },
      },
    };

    return new Promise((resolve, reject) => {
      resolve(dispensary as Dispensary);
    });
  };

  return (
    <>
      <h1>Custom Fields</h1>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <GcvInputForm {...methods} name="bankId" label={`Bank ID`} />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" onClick={methods.handleSubmit(loadBank)}>
            Load Bank
          </Button>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" onClick={methods.handleSubmit(saveCustomFields)}>
            Save JSON
          </Button>
        </Grid>

        <Grid item xs={6}>
          <TextareaAutosize
            id="ui-json"
            className={classes.widthFull}
            placeholder="UI JSON"
            rowsMin={10}
            value={uiJson}
            onChange={e => setUiJson(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <DynamicUi uiJson={uiJson} uiData={''} saveData={onSubmit} />
          <pre>{JSON.stringify(formOutput, null, 2)}</pre>
        </Grid>
      </Grid>
    </>
  );
};
