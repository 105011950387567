import styled from 'styled-components';

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Dropdown = styled.div`
  background-color: white;
  height: 34px;
  box-sizing: border-box;
  border: 1px solid #ebeef7;
  border-left: none;
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: flex-end;
`;

export enum FilterOptions {
  SearchAccounts = 'SearchAccounts',
  MethodOfTransport = 'MethodOfTransport',
  Status = 'Status',
  DateTimeFilter = 'DateTimeFilter',
}
