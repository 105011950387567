import React, { useState } from 'react';
import { SearchIcon } from '../../icons/SearchIcon';
import { SearchInput, InputIconWrapper, SearchInputContainer, GlobalStyles } from './styles';

interface Props {
  label: string;
  placeholder: string;
  id: string;
  style?: any;
  value: string;
  onChange: (e) => any;
  onClick?: (e) => any;
  onBlur?: (e) => any;
}

export const GcvSearchInput = ({ label, placeholder, id, value, onChange, onClick, onBlur, ...props }: Props) => {
  return (
    <>
      <GlobalStyles />
      <SearchInputContainer style={props.style}>
        <SearchInput
          className="floating-input"
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          value={value}
          type="text"
          placeholder={placeholder}
          label={label}
          id={id}
          {...props}
        />

        <InputIconWrapper>
          <SearchIcon />
        </InputIconWrapper>
      </SearchInputContainer>
    </>
  );
};
