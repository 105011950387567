import { $primary } from '../../util';

export type ChartType = 'area' | 'bullet' | 'heatmap' | 'horizontalBar' | 'pie' | 'radar' | 'verticalbar';

export interface AreaConfig {
  categories: {
    category: {
      label: string;
    }[];
  }[];
  dataset: {
    seriesName: string;
    renderAs?: string;
    color: string;
    alpha?: number;
    data: {
      value: number;
      toolText: string;
    }[];
  }[];
}

export interface BubbleConfig {
  categories: {
    label: string;
  }[];

  dataset: {
    color: string;
    data: {
      amount: number;
      count: number;
      date: string;
    }[];
  }[];
}

export interface BulletConfig {
  colorRange: {
    color: {
      minValue: number;
      maxValue: number;
      code: string;
      alpha: number;
    }[];
  };

  value: number;

  target: number;
}

export interface HeatmapConfig {
  dataset: {
    data: any[];
  }[];

  colorrange: {
    gradient: string;
    minvalue: string;
    code: string;
    startlabel: string;
    endlabel: string;
    color: {
      code: string;
      minvalue: number;
      maxvalue: number;
      label: string;
    }[];
  };
}

export interface HorizontalBarConfig {
  categories: {
    category: {}[];
  }[];
  dataset: {
    seriesname: string;
    color: string;
    data: {
      value: number;
      displayValue: string;
    }[];
  }[];
}

export interface PieConfig {
  data: {
    label: string;
    value: number;
    displayValue: string;
    color: string;
  }[];
}

export interface RadarConfig {
  categories: {
    category: {
      label: string;
    }[];
  }[];

  dataset: {
    seriesname: string;
    color: string;
    anchorBgColor: string;
    data: {
      value: number;
    }[];
  }[];
}

export interface VerticalBarConfig {
  data: {
    label: string;
    value: number;
    color: string;
    tooltext: string;
  }[];
}

export interface MultiLineConfig {
  categories: {
    category: {}[];
  }[];
  dataset: {
    seriesname?: string;
    color?: string;
    data: {
      displayValue?: string;
      value: string;
      tooltext?: string;
    }[];
  }[];
}

export const chartOptions = {
  area: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    maxLabelWidthPercent: '20',
    palette: 1,
    plotFillAlpha: '90',
    plotFillColor: '#ffffff',
    showPlotBorder: 0,
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    showValues: 0,
    showValue: 0,
    useEllipsesWhenOverflow: 1,
    showLegend: 0,
    chartTopMargin: 10,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 0,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
  },
  bubble: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    maxLabelWidthPercent: '20',
    palette: 1,
    plotFillAlpha: '100',
    showPlotBorder: 0,
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    showValues: 0,
    useEllipsesWhenOverflow: 1,
    showYAxisValues: 0,
    numDivlines: 0,
    showTrendlineLabels: 0,
    drawQuadrant: 0,
    yAxisLineThickness: 0,
    lineThickness: 0,
    lineColor: $primary,
    yAxisLineColor: '#F2F4FA',
    xAxisLineThickness: 0,
    vDivLineThickness: 0,
    vDivLineColor: '#F2F4FA',
    divLineThickness: 0,
    divLineColor: '#F2F4FA',
    xAxisLineColor: '#F2F4FA',
    chartTopMargin: 0,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 0,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
  },
  msline: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    showaxislines: 0,
    xAxisLineColor: '#ffffff',
    yAxisLineColor: '#ffffff',
    maxLabelWidthPercent: '20',
    vDivLineThickness: 1,
    vDivLineColor: '#F2F4FA',
    divLineThickness: 1,
    divLineColor: '#F2F4FA',
    palette: 1,
    plotFillAlpha: '90',
    plotFillColor: '#ffffff',
    showPlotBorder: 0,
    canvasPadding: '30',
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    labelDisplay: 'rotate',
    slantLabel: 1,
    showValues: 0,
    showValue: 0,
    useEllipsesWhenOverflow: 1,
    showLegend: 0,
    chartTopMargin: 10,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 0,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
  },
  bullet: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    maxLabelWidthPercent: '20',
    palette: 1,
    plotFillAlpha: '60',
    plotFillColor: '#ffffff',
    showPlotBorder: 0,
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    showValues: 0,
    showValue: 0,
    useEllipsesWhenOverflow: 1,
    showLegend: 0,
    baseFont: 'Lato',
    baseFontSize: '12',
    showShadow: 0,
    chartTopMargin: 0,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 0,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
    formatNumberScale: 1,
    decimals: 0,
    showTooltip: 0,
  },
  heatmap: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    maxLabelWidthPercent: '20',
    palette: 1,
    plotFillAlpha: '100',
    showPlotBorder: 0,
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    showValues: 0,
    useEllipsesWhenOverflow: 1,
    showLegend: 0,
    baseFont: 'Lato',
    baseFontSize: '12',
    plottooltext: '$trlabel',
    chartTopMargin: 20,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 20,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
  },
  horizontalBar: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    maxLabelWidthPercent: '20',
    showPlotBorder: 0,
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    showValues: 0,
    useEllipsesWhenOverflow: 1,
    showLegend: 0,
    showLabels: 0,
    showDivLineValues: 0,
    showLimits: 0,
    showSecondaryLimits: 0,
    lineAlpha: 0,
    divLineAlpha: 0,
    canvasLineAlpha: 0,
    borderAlpha: 0,
    canvasBorderAlpha: 0,
    showYAxisValues: 0,
    showXAxisValues: 0,
    showXAxisLine: 0,
    showYAxisLine: 0,
    numDivLines: 0,
    baseFont: 'Lato',
    baseFontSize: '12',
    plottooltext: '$displayValue',
    chartTopMargin: 0,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 0,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
  },
  pie: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    maxLabelWidthPercent: '20',
    showPlotBorder: 0,
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    showValues: 1,
    showPercentValues: 1,
    useEllipsesWhenOverflow: 1,
    showLegend: 0,
    use3DLighting: 0,
    showShadow: 0,
    showLabels: 1,
    showDivLineValues: 0,
    showLimits: 0,
    showSecondaryLimits: 0,
    lineAlpha: 0,
    divLineAlpha: 0,
    canvasLineAlpha: 0,
    borderAlpha: 0,
    canvasBorderAlpha: 0,
    showYAxisValues: 0,
    showXAxisValues: 0,
    showXAxisLine: 0,
    showYAxisLine: 0,
    numDivLines: 0,
    baseFont: 'Lato',
    baseFontSize: '12',
    doughnutRadius: 35,
    chartTopMargin: 0,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 0,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
    plottooltext: '$label',
  },
  radar: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    maxLabelWidthPercent: '20',
    palette: 1,
    plotFillAlpha: '40',
    showPlotBorder: 0,
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    showValues: 0,
    useEllipsesWhenOverflow: 1,
    showLegend: 0,
    baseFont: 'Lato',
    baseFontSize: '12',
    chartTopMargin: 0,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 0,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
    showYAxisValues: 0,
    plottooltext: '$$dataValue, $label - $seriesName',
  },
  verticalBar: {
    animation: 0,
    bgColor: '#ffffff',
    canvasBgColor: '#ffffff',
    toolTipBorderColor: '#E0E5F0',
    showAlternateHGridColor: 0,
    showAlternateVGridColor: 0,
    maxLabelWidthPercent: '20',
    showPlotBorder: 0,
    usePlotGradientColor: 0,
    showBorder: 0,
    showCanvasBorder: 0,
    showTooltipforWrappedLabels: 1,
    showValues: 0,
    useEllipsesWhenOverflow: 1,
    labelDisplay: 'rotate',
    slantLabel: 1,
    showLegend: 0,
    showLabels: 1,
    showDivLineValues: 1,
    showLimits: 1,
    showSecondaryLimits: 1,
    lineAlpha: 1,
    divLineAlpha: 1,
    canvasLineAlpha: 1,
    canvasPadding: 10,
    borderAlpha: 0,
    canvasBorderAlpha: 0,
    showYAxisValues: 1,
    showXAxisValues: 1,
    showXAxisLine: 0,
    showYAxisLine: 0,
    numDivLines: 5,
    baseFont: 'Lato',
    baseFontSize: '12',
    chartTopMargin: 10,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    chartBottomMargin: 0,
    chartTopPadding: 10,
    chartLeftPadding: 0,
    chartRightPadding: 0,
    chartBottomPadding: 0,
    decimals: 2,
  },
};
