import React, { useContext } from 'react';

import { DispensaryAccountReviewWithData, PeerGroupPeriodSummary, PeriodSummary } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { HorizontalBarConfig } from './chart-options';
import { tooltip } from './Tooltip';
import { formatChangePercentage, formatPercentage } from 'libs/react-ui/src/util';
import { AccountMonitoringContext } from '../../account-monitoring-context';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const TransactionCountBarChart: React.FC<Props> = props => {
  const context = useContext(AccountMonitoringContext);
  return <GcvChart type="horizontalBar" height={80} config={chartDataAdapter(props.review, context.peerGroupId)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData, peerGroupId: string) => {
  return dataAdapter(review.data.aggregated_sales_data, peerGroupId);
};

export const dataAdapter = (
  data: {
    current_period: PeriodSummary;
    previous_period: PeriodSummary;
    previous_year: PeriodSummary;
    peer_groups: {
      [id: string]: PeerGroupPeriodSummary;
    };
  },
  peerGroupId: string
): HorizontalBarConfig => {
  return {
    categories: [
      {
        category: [{}],
      },
    ],
    dataset: [
      {
        seriesname: 'Verified',
        color: '#00BC66',
        data: [
          {
            value: data.current_period.number_of_verified_transactions,
            displayValue: tooltip('Verified', [
              {
                value: `${data.current_period.number_of_verified_transactions} (${formatPercentage(
                  data.current_period.number_of_verified_transactions,
                  data.current_period.total_number_of_transactions
                )})`,
                label: 'This Period',
              },
              {
                value: `${data.previous_period.number_of_verified_transactions} (${formatPercentage(
                  data.previous_period.number_of_verified_transactions,
                  data.previous_period.total_number_of_transactions
                )})`,
                label: 'Last Period',
              },
              {
                value: formatChangePercentage(
                  data.previous_period.number_of_verified_transactions,
                  data.current_period.number_of_verified_transactions
                ),
                label: 'Change Over Last Period',
              },
              {
                value: data.peer_groups[peerGroupId]?.avg_number_of_verified_transactions.toString(),
                label: 'Peer Group Avg',
              },
            ]),
          },
        ],
      },
      {
        seriesname: 'Unchecked',
        color: '#A5A8BA',
        data: [
          {
            value: data.current_period.number_of_unchecked_transactions,
            displayValue: tooltip('Unchecked', [
              {
                value: `${data.current_period.number_of_unchecked_transactions} (${formatPercentage(
                  data.current_period.number_of_unchecked_transactions,
                  data.current_period.total_number_of_transactions
                )})`,
                label: 'This Period',
              },
              {
                value: `${data.previous_period.number_of_unchecked_transactions} (${formatPercentage(
                  data.previous_period.number_of_unchecked_transactions,
                  data.previous_period.total_number_of_transactions
                )})`,
                label: 'Last Period',
              },
              {
                value: formatChangePercentage(
                  data.previous_period.number_of_unchecked_transactions,
                  data.current_period.number_of_unchecked_transactions
                ),
                label: 'Change Over Last Period',
              },
              {
                value: data.peer_groups[peerGroupId]?.avg_number_of_unchecked_transactions.toString(),
                label: 'Peer Group Avg',
              },
            ]),
          },
        ],
      },
      {
        seriesname: 'Unverified',
        color: '#FF4E50',
        data: [
          {
            value: data.current_period.number_of_unverified_transactions,
            displayValue: tooltip('Unverified', [
              {
                value: `${data.current_period.number_of_unverified_transactions} (${formatPercentage(
                  data.current_period.number_of_unverified_transactions,
                  data.current_period.total_number_of_transactions
                )})`,
                label: 'This Period',
              },
              {
                value: `${data.previous_period.number_of_unverified_transactions} (${formatPercentage(
                  data.previous_period.number_of_unverified_transactions,
                  data.previous_period.total_number_of_transactions
                )})`,
                label: 'Last Period',
              },
              {
                value: formatChangePercentage(
                  data.previous_period.number_of_unverified_transactions,
                  data.current_period.number_of_unverified_transactions
                ),
                label: 'Change Over Last Period',
              },
              {
                value: data.peer_groups[peerGroupId]?.avg_number_of_unverified_transactions.toString(),
                label: 'Peer Group Avg',
              },
            ]),
          },
        ],
      },
    ],
  };
};
