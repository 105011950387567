import React, { useState, useEffect } from 'react';
import { GcvButton } from '../../../lib/GcvButton/GcvButton';
import { ExportActivityModal } from './ExportActivityModal';
import { ExportActivityResponseModal } from './ExportActivityResponseModal';
import { ActivityReportingCRBPayload as Payload } from '.';
import { HyperTextButton } from '../styles';
import { IANATimezones } from '@gcv/shared';

interface Props {
  exportActivityFailure?: boolean;
  exportActivitySuccess?: boolean;
  timezone: IANATimezones;
  emitData: (payload: Payload) => any;
}

export const ActivityReportingCRB: any = (props: Props) => {
  const [type, setType] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [responseModalActive, setResponseModalActive] = useState(false);

  const toggleModal = () => {
    setModalActive(!modalActive);
  };

  const closeResponseModal = () => {
    setResponseModalActive(false);
    setType(null);
  };

  const handleSubmit = payload => {
    props.emitData(payload);
  };

  useEffect(() => {
    if (props.exportActivityFailure || props.exportActivitySuccess) {
      setModalActive(false);
      if (props.exportActivitySuccess) {
        setType(true);
      } else {
        setType(false);
      }
    }
  }, [props.exportActivityFailure, props.exportActivitySuccess]);

  useEffect(() => {
    if (type !== null) {
      setResponseModalActive(true);
    }
  }, [type]);

  return (
    <>
      <div id="modal-root"></div>
      <HyperTextButton onClick={toggleModal}>Export Activity</HyperTextButton>
      {modalActive && (
        <ExportActivityModal
          active={modalActive}
          toggleModal={toggleModal}
          onSubmit={handleSubmit}
          timezone={props.timezone}
        />
      )}
      {responseModalActive && (
        <ExportActivityResponseModal
          type={type}
          active={responseModalActive}
          toggleModal={closeResponseModal}
          buttonComponent={
            <GcvButton secondary onClick={closeResponseModal}>
              {type ? 'Great!' : 'Close'}
            </GcvButton>
          }
        />
      )}
    </>
  );
};
