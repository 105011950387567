import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'gcv-mat-input',
  templateUrl: './mat-input.component.html',
  styleUrls: ['./mat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatInputComponent implements OnInit {
  @Input() type: string;
  @Input() label: string;
  @Input() prefix = false;
  @Input() required = false;
  @Input() prefixValue = '';
  @Input() suffix = false;
  @Input() suffixValue = '';
  @Input() styles: string;
  @Input() property: string;
  @Input() autoFill; //= 'thisIsNotAAutoFill';
  @Input() control: string;
  @Input() group: FormGroup;
  @Input() disabled = false;
  @Input() validators: any[];
  @Input() errors: any;
  @Input() inputValue = '';
  @Output() value = new EventEmitter();
  @Output() blurCalled = new EventEmitter();
  @Output() focusCalled = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onInputChange(e) {
    this.value.emit({ value: e.srcElement.value, property: this.property });
  }

  onBlur(e) {
    this.blurCalled.emit();
  }

  onFocus(e) {
    this.focusCalled.emit();
  }

  getErrorMessage() {
    let msg = '';

    for (const error of this.validators) {
      if (this.group.controls[this.control].hasError(error.type)) {
        msg = error.message;
      }
    }

    return msg;
  }
}
