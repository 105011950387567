import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { TransitionEffects } from './styles';
import { routes } from './routes';

export const DashboardRouterCRB = props => {
  return (
    <Router>
      <TransitionEffects>
        <Switch>
          {routes.map(({ path, render }) => {
            return <Route path={path} render={() => render(props)} key={path} />;
          })}
        </Switch>
      </TransitionEffects>
    </Router>
  );
};
