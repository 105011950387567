import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';
import { ViewMoreIcon } from '../../../icons/ViewMoreIcon';

import { DrawerText, DrawerImage, DrawerList } from '../styles';
import { VerticalCenter } from '../../SalesAndDeposits/Deposits/depositDetailDrawer/styles';

interface Props {
  form: any;
}

export const GrowFlowForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        <b>Please note: At this time, the GrowFlow integration only supports retail businesses.</b>
      </DrawerText>
      <DrawerText>
        To configure this integration, send the following message to GrowFlow Support using the chat bubble in your
        GrowFlow account:
      </DrawerText>
      <DrawerText>
        <b>
          Hello, <br />
          <br />I would like to connect my GrowFlow account to Green Check. Can you please enable this integration for
          me?
        </b>
      </DrawerText>
      <DrawerText>
        After you contact GrowFlow, enable and connect the integration below. Once GrowFlow enables the integration on
        their end, we will complete the connection. The integration may take 2-3 days.
      </DrawerText>
      <GcvInputForm
        {...form}
        name="client_id_name"
        label="Client ID"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <DrawerText>
        Your Client ID can be found in your GrowFlow URL: https://retail.growflow.com/c/<mark>CLIENT_ID</mark>/
      </DrawerText>
    </>
  );
};
