<div *ngIf="idleState" [style.height.px]="height" class="content" data-cy="timeout.component">
  <div class="message">
    <div class="header">
      <span>Still Working?</span>
    </div>
    <div class="body">
      <div class="bodyHeader">
        <span *ngIf="timedOut">Your session has expired</span>
        <div *ngIf="idleState && !timedOut">
          <p>
            You've been away for a while.
          </p>
          <p>
            Come back to this window or your session will expire in...
          </p>
        </div>
      </div>
      <div class="timer">
        <div class="imageWrapper">
          <svg height="96" viewBox="0 0 24 24" width="96" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 2a8 8 0 1 1 0 16 8 8 0 1 1 0-16zm-1 2v6.4l4.3 4.3 1.4-1.4-3.7-3.7V6h-2z"
              font-family="sans-serif"
              font-weight="400"
              overflow="visible"
              style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;isolation:auto;mix-blend-mode:normal"
              white-space="normal"
            />
          </svg>
        </div>
        <h3 *ngIf="idleState && !timedOut">{{ countdown }} Seconds</h3>
        <h3 *ngIf="timedOut">Still Working?</h3>
      </div>
      <div class="textWrapper">
        <span *ngIf="timedOut">Click "Continue" to keep working or "Log out" to end your session.</span>
      </div>
      <div *ngIf="timedOut" class="btnWrapper">
        <gcv-button
          (clickEvent)="logout()"
          buttonText="Log Out"
          data-cy="timeout.component.logOut"
          type="secondary fullWidth"
        ></gcv-button>
        <gcv-button
          (clickEvent)="reset()"
          buttonText="Continue"
          data-cy="tab-group.component.continue"
          type="primary fullWidth"
        ></gcv-button>
      </div>
    </div>
  </div>
</div>
