import styled from 'styled-components';

import {
  $grey1,
  $grey4,
  $grey8,
  $white,
  $primaryLight,
  $primaryBorder,
  $blue,
  $fontFamily,
} from '../../util/styleVariables';

interface Props {
  open?: boolean;
}

export const Container = styled.div<Props>`
  color: ${$grey1};
  font-family: ${$fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 275px;
  height: 4rem;
  background: #fff;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

export const Main = styled.div<Props>`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  padding: 0px 25px 0px 20px;
  border-bottom: 1px solid ${$primaryBorder};

  &:hover {
    cursor: pointer;
  }
  box-sizing: border-box;
`;

export const Option = styled.div<Props>`
  flex: 1;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid ${$primaryBorder};
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  transition: all 0.3s ease-out;
  box-sizing: border-box;

  &:hover {
    background-color: ${$grey4};
    color: ${$primaryLight};
    cursor: pointer;
  }
  .name {
    font-weight: bold;
    font-size: 16px;
  }
  .email {
    margin-top: 3px;
    font-size: 12px;
    color: ${$grey8};
  }
  .settings {
    margin-top: 3px;
    color: ${$blue};
  }
`;

export const VerticalCenter = styled.div<Props>`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Logout = styled.div<Props>`
  flex: 1;
  height: 50px;
  vertical-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  transition: all 0.3s ease-out;
  color: ${$blue};
  border-bottom: 1px solid ${$primaryBorder};

  &:hover {
    background-color: ${$grey4};
    cursor: pointer;
  }
  box-sizing: border-box;
`;

export const DropDown = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${$white};
  outline: 1px solid ${$primaryBorder};

  .dispensaries {
    overflow-y: auto;
    max-height: 300px;

    ::-webkit-scrollbar {
      width: 5px; /* Remove scrollbar space */
    }
  }
`;
