// fx
import React, { useState } from 'react';

// app
import { EditSaleCard } from './edit-sale-card/edit-sale-card.component';
import { SearchSalesCard } from './search-sales-card/search-sales-card.component';
import { Grid } from '@material-ui/core';

interface Props {}

export const GcvSales: React.FC<Props> = props => {
  const [selectedSale, setSelectedSale] = useState();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchSalesCard selectSale={setSelectedSale} />
      </Grid>

      <Grid item xs={12}>
        <EditSaleCard sale={selectedSale} updateSale={setSelectedSale} />
      </Grid>
    </Grid>
  );
};
