import React, { useEffect, useState } from 'react';
import { ReadMoreButton } from './styles';

interface Props {
  string: string;
  length: number;
  style?: any;
}

export const GcvReadMore = ({ string, length, ...props }: Props) => {
  const isStringTooLong = string.length > length;
  const initialString = isStringTooLong ? string.slice(0, length) + '...' : string;
  const [text, setText] = useState(initialString);
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
    if (!readMore) {
      setText(string);
    } else {
      setText(initialString);
    }
  };

  useEffect(() => {
    if (readMore) {
      setText(string);
    } else {
      setText(initialString);
    }
  }, [string]);

  return (
    <div {...props} style={{ overflowWrap: 'break-word', fontSize: '14px' }}>
      {text}
      {isStringTooLong ? (
        <ReadMoreButton onClick={toggleReadMore}>{readMore ? 'See Less' : 'See More'}</ReadMoreButton>
      ) : null}
    </div>
  );
};
