import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const serviceFileClient = (API: APIClass) => {
  return {
    getSalesFileDataHashByHash: async (hash: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/salesFile/hash/${hash}`, setIsLoading);
    },
  };
};
