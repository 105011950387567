<div class="modalWrapper">
  <div class="orgWrapper">
    <div class="header">
      <div class="iconWrapper">
        <div class="iconContainer"><img src="../../../../../assets/Compliance-documents-icon.svg" alt="" /></div>
      </div>
      <h3>Deposit History</h3>
    </div>
    <div class="body">
      <div class="infoContainer">
        <div class="infoContainerItem">
          <div>{{ deposit.deposit_date | formatISODate }}</div>
          <div class="depositEvent">
            Deposit created
            <div class="subText">
              {{ created_by }}, <br />
              {{ dispensary_name }}
            </div>
          </div>
        </div>
        <div *ngIf="deposit.accepted_date" class="infoContainerItem">
          <div>{{ deposit.accepted_date | formatISODate }}</div>
          <div class="depositEvent">
            Deposit accepted
            <div class="subText">{{ accepted_by }}</div>
          </div>
        </div>
      </div>

      <div class="header">
        Internal Notes
      </div>

      <div *ngIf="deposit.bank_comments; else empty_message" class="notesContainer">
        {{ deposit.bank_comments }}
      </div>

      <ng-template #empty_message>
        <p>
          No notes were included with this deposit.
        </p>
      </ng-template>
    </div>

    <div class="btnWrapper">
      <gcv-button type="secondary" (clickEvent)="close()" buttonText="Close"></gcv-button>
    </div>
  </div>
</div>
