import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { documentPropertyToHumanReadableNameMap } from '@gcv/shared';
import { RoleResolverService } from '../../../../services/role-resolver.service';
import { S3FileManagementService } from 'apps/user-interface/src/services/s3FileManagement.service';
import { openImageInPdfView } from '../../../../shared/io.util';

@Component({
  selector: 'app-review-account-document',
  templateUrl: './review-account-document.component.html',
  styleUrls: ['./review-account-document.component.scss'],
})
export class ReviewAccountDocumentComponent implements OnInit {
  @ViewChild('pdfView', { static: true })
  pdfView: ElementRef;
  selectedTab = 0;
  docMap = documentPropertyToHumanReadableNameMap;
  objUrl: string;
  documentName = '';
  docDescription = '';
  statusSelected = 'approve';
  s3KeyUrl: string;
  type: string;
  approved = false;
  docData: any;
  loading = false;
  isBank;

  constructor(
    private httpClient: HttpClient,
    private roleResolver: RoleResolverService,
    private matDialogRef: MatDialogRef<ReviewAccountDocumentComponent>,
    private s3FileService: S3FileManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.docData = data;
  }

  ngOnInit() {
    this.documentName = this.docData.documentName;
    if (this.docData.status === 'reviewed') {
      this.approved = true;
    }
    if (this.docData.objUrl) {
      this.objUrl = this.docData.objUrl;
      this.pdfView.nativeElement.src = this.objUrl;
    } else if (this.docData.documentId) {
      this.showDocument();
    }

    if (this.type === 'image/jpg') {
      const nativeEl = this.pdfView.nativeElement;
      if ((nativeEl.contentDocument || nativeEl.contentWindow.document).readyState === 'complete') {
        nativeEl.onload = this.onIframeLoad.bind(this);
      } else {
        if (nativeEl.addEventListener) {
          nativeEl.addEventListener('load', this.onIframeLoad.bind(this), true);
        } else if (nativeEl.attachEvent) {
          nativeEl.attachEvent('onload', this.onIframeLoad.bind(this));
        }
      }
    }

    this.isBank = window.location.pathname.includes('bank');
  }

  onIframeLoad() {
    const imgTag = this.pdfView.nativeElement.contentWindow.document.body.children[0];
    if (imgTag) {
      imgTag.style.cssText = 'width: 100%; height: 100%; object-fit: contain;';
    }
  }

  async showDocument() {
    try {
      const { orgId, userType, documentId } = this.docData;

      this.loading = true;

      const blob = await this.s3FileService.viewDocument({ orgId, userType }, documentId);
      openImageInPdfView(blob, this.pdfView);
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  cancel() {
    this.matDialogRef.close();
  }

  submit() {
    const results = {
      documentName: this.documentName,
      status: this.statusSelected,
    };
    this.documentName = '';
    this.matDialogRef.close({
      data: results,
    });
  }

  isDisabled() {
    return this.roleResolver.checkPermission(['account_info_update']);
  }
}
