<div class="wrapper">
  <section class="header">
    <h1>Requirement Details</h1>
    <p class="subheading">This requirement is managed by Green Check</p>
  </section>
  <section>
    <span class="label">Document Title</span>
    <p>{{ name }}</p>
  </section>
  <section>
    <span class="label">Description</span>
    <p>{{ description }}</p>
  </section>
  <section>
    <span class="label">Type</span>
    <p>{{ type | titlecase }}</p>
  </section>
  <section class="buttonSection">
    <div>
      <gcv-button (clickEvent)="close()" buttonText="Close" class="button" type="tertiary"></gcv-button>
    </div>
  </section>
</div>
