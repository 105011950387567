import { ReactWrapperComponent, WrapperProps, RequirementDetailView } from '@user-interface/react-ui';

import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Dispensary, Bank, User, DueDiligenceStatus, DueDiligenceTemplate } from '@gcv/shared';

import { slideInAnimation } from '../../app/animations/router-animation';

import { DispensaryFacade, BankFacade, UserFacade } from '@user-interface/gcv-state';

import { S3FileManagementService } from '../../services/s3FileManagement.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'requirement-detail-view',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="componentProps" [emitData]="handleClick"></app-react-wrapper>',
  animations: [trigger('routeAnimations', [transition('active <=> pending', [useAnimation(slideInAnimation)])])],
})
export class RequirementDetailViewComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  dispensary: Dispensary;
  bank: Bank;
  companyType = this.router.url.includes('bank') ? 'bank' : 'dispensary';
  dispensaryId = this.activeRoute.snapshot.queryParams['account'];
  template: DueDiligenceTemplate;
  componentProps = {
    dispensary: {},
    requirementId: this.activeRoute.snapshot.queryParams['req'],
    bank: {},
    companyType: this.companyType,
    user: {},
    template: {},
  };

  component = RequirementDetailView;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dispFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private s3FileManagement: S3FileManagementService,
    private userFacade: UserFacade
  ) {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  ngOnInit() {
    this.setUpStreams();
  }

  setUpStreams() {
    this.dispFacade
      .selectDispensaryById(this.dispensaryId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dispensary: Dispensary) => {
        if (dispensary) {
          this.bankFacade
            .selectBankById(dispensary.bank_id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((bank: Bank) => {
              this.dispensary = dispensary;
              this.bank = bank;
              if (bank) {
                this.template = bank.due_diligence_template;
                this.componentProps = {
                  ...this.componentProps,
                  bank,
                  dispensary,
                  template: bank.due_diligence_template,
                };
              } else {
                this.dispFacade
                  .selectDispensaryGcvDueDiligenceTemplate()
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe(template => {
                    this.template = template.bank;
                    this.componentProps = { ...this.componentProps, bank, dispensary, template: this.template };
                  });
              }
            });
        }
      });
    this.userFacade
      .selectCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.componentProps = { ...this.componentProps, user };
      });
  }

  handleClick(type, data) {
    if (type === 'back') {
      const data = { queryParams: { tab: 3, account: this.dispensaryId } };
      if (this.companyType === 'dispensary') {
        delete data.queryParams.account;
        this.router.navigate(['/secure/dispensary/company/overview'], data).then(() => {});
      } else if (this.dispensary.due_diligence.due_diligence_status !== DueDiligenceStatus.BANK_APPROVED) {
        data.queryParams.tab = 4;
        this.router.navigate(['/secure/bank/account/review'], data).then(() => {});
      } else {
        data.queryParams.tab = 3;
        this.router.navigate(['/secure/bank/account/view'], data).then(() => {});
      }
    } else if (type === 'viewDocument') {
      this.viewDocument(data);
    } else if (type === 'updateDispensary') {
      this.dispFacade.updateDispensary(data);
    }
  }

  async viewDocument({ docId, document }) {
    const isCsv = document.file_name.includes('.csv');
    const blob = await this.s3FileManagement.viewDocument(
      { orgId: this.dispensary.id, userType: this.companyType === 'bank' ? 'bank' : 'dispensary' },
      docId
    );
    this.s3FileManagement.openDocumentInNewTab(blob, isCsv ? 'text/csv' : null, document.file_name);
  }
}
