import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Snackbar, Grid } from '@material-ui/core';

import {
  SubText,
  CardRowContainer,
  Card,
  PosIcon,
  DrawerHeader,
  VerticalCenter,
  Title,
  DrawerContent,
  ButtonContainer,
  DrawerText,
  IconContainer,
  FooterContent,
} from './styles';
import {
  GcvStatusIndicator,
  GcvDrawer,
  GcvInputForm,
  GcvButton,
  GcvCheckbox,
  GcvContent,
  GcvInputSelectNew,
  GcvInputSelect,
} from '../../lib';
import {
  Portal42Form,
  MjFreewayForm,
  GreenBitsForm,
  TreezForm,
  FlowHub,
  LeafLogixForm,
  CovaForm,
  GrowFlowForm,
  IntacctForm,
} from './PosTypes/';

import { useForm, FormContextValues } from 'react-hook-form';
import { BioTrackForm } from './PosTypes/BioTrack';
import { QuickBooksForm } from './PosTypes/QuickBooks';
import { BlazeForm } from './PosTypes/Blaze';
import { Pos } from './PosConfigs';
import { PosType, PosOrganizationLocation } from '@gcv/shared';
import { $danger } from '../../util';

interface Props {
  drawerPosType: Pos;
  form: FormContextValues<Record<string, any>>;
  loading: boolean;
  onSubmit: (data: any) => void;
  onBack: () => any;
  locations: PosOrganizationLocation[];
  currentAccount: string;
  error: string;
}

const formatOptionLabel = (name: string, address: string) => {
  if (name && address) {
    return `${name} - ${address}`;
  } else if (name) {
    return name;
  } else if (address) {
    return address;
  } else {
    return 'Missing Dispensary Identifier';
  }
};

export const EnterPosLocation = ({
  form,
  loading,
  onSubmit,
  onBack,
  locations,
  drawerPosType,
  currentAccount,
  error,
}: Props) => {
  const toolTip = 'Please enter required fields to enable';

  const drawerText = `The ${drawerPosType.displayName} API process found several available locations. Select the location you want to use for this integration.`;

  const options: { value: string; label: string }[] = locations.map(location => ({
    value: location.id,
    label: formatOptionLabel(location.name, location.address),
  }));

  return (
    <Grid container style={{ height: '91vh' }}>
      <Grid item>
        <DrawerContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GcvContent type="l1" grey2 content={'Account'} />
              <GcvContent type="h3" content={currentAccount} />
            </Grid>
            <Grid item xs={12}>
              <GcvContent type="p1" content={drawerText} />
            </Grid>
            <Grid item xs={12}>
              <GcvInputSelect
                options={options}
                {...form}
                label={drawerPosType.name === PosType.Quickbooks ? 'Select business unit' : 'Select location'}
                name="location_id"
                rules={{ required: true }}
              ></GcvInputSelect>
            </Grid>
          </Grid>
        </DrawerContent>
      </Grid>

      <Grid item>
        <FooterContent>
          <ButtonContainer>
            <GcvButton secondary={true} onClick={onBack}>
              Back
            </GcvButton>
            <label data-tip={toolTip}>
              <GcvButton
                primary={true}
                isLoading={loading}
                onClick={form.handleSubmit(onSubmit)}
                disabled={!form.formState.isValid}
              >
                Connect
              </GcvButton>
              {!form.formState.isValid ? <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} /> : null}
            </label>
          </ButtonContainer>
          <GcvContent type="l2" style={{ color: $danger, marginTop: '.5rem', textAlign: 'center' }} content={error} />
        </FooterContent>
      </Grid>
    </Grid>
  );
};
