<gcv-card classes="fullHeight" data-cy="gcv-card.component">
  <div card-body class="cardBody">
    <div class="cardTop">
      <div class="cardHeader">
        <span class="number">{{ number }}.</span>
        <svg *ngIf="!complete" viewBox="0 0 26 26" width="26px" xmlns="http://www.w3.org/2000/svg">
          <g clip-rule="evenodd" fill-rule="evenodd">
            <circle cx="13" cy="13" fill="#e0e5f0" r="13" />
            <path
              d="M15.9 10.3l-4.3 4-1.4-1.4c-.5-.5-1.3-.5-1.8 0s-.5 1.3 0 1.8l1.7 1.7c.4.4.9.6 1.5.6.5 0 1-.2 1.4-.6l4.6-4.2c.5-.5.5-1.3.1-1.8-.5-.5-1.3-.5-1.8-.1"
              fill="#fff"
            />
          </g>
        </svg>
        <svg *ngIf="complete" viewBox="0 0 26 26" width="26px" xmlns="http://www.w3.org/2000/svg">
          <g clip-rule="evenodd" fill-rule="evenodd">
            <circle cx="13" cy="13" fill="#00bc66" r="13" />
            <path
              d="M15.9 10.3l-4.3 4-1.4-1.4c-.5-.5-1.3-.5-1.8 0s-.5 1.3 0 1.8l1.7 1.7c.4.4.9.6 1.5.6.5 0 1-.2 1.4-.6l4.6-4.2c.5-.5.5-1.3.1-1.8-.5-.5-1.3-.5-1.8-.1"
              fill="#fff"
            />
          </g>
        </svg>
      </div>
      <div class="cardContent">
        <span class="cardTitle" data-cy="gcv-card.component.cardTitle">{{ title }}</span>
        <br *ngIf="break" />
        <br *ngIf="break" />
        <br *ngIf="break" />
        <span class="subTitle" data-cy="gcv-card.component.subTitle">{{ subTitle }} </span>
      </div>
    </div>
    <div class="cardFooter">
      <gcv-button
        (clickEvent)="buttonPressed()"
        *ngIf="buttonVisible && complete"
        buttonText="Edit Details"
        data-cy="gcv-card.component.edit"
        type="secondary full-width pill"
      ></gcv-button>
      <gcv-button
        (clickEvent)="buttonPressed()"
        *ngIf="buttonVisible && !complete"
        buttonText="Get Started"
        data-cy="gcv-card.component.getStarted"
        type="primary full-width pill"
      ></gcv-button>
    </div>
  </div>
</gcv-card>
