import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { BackButtonIcon } from '../../../icons/BackButtonIcon';
import { GcvButton, GcvDrawer, GcvInputForm, GcvInputTextArea } from '../../../lib';
import { PeerGroup } from '@gcv/shared';

interface Props {
  openDrawer: boolean;
  toggleDrawer: () => void;
  onFormComplete: (data: any) => void;
  defaultValues?: PeerGroup;
  loading: boolean;
  completeButtonText: string;
  headerText: string;
}

export const PeerGroupDrawer = (props: Props) => {
  if (props.loading) {
    return <></>;
  }

  const { handleSubmit, formState, ...form } = useForm({
    mode: 'onChange',
    defaultValues: props.defaultValues
      ? {
          name: props.defaultValues.name,
          description: props.defaultValues.description,
        }
      : {},
  });

  useEffect(() => {
    if (props.defaultValues) {
      form.reset({
        name: props.defaultValues.name,
        description: props.defaultValues.description,
      });
    }
  }, [props.defaultValues, props.openDrawer]);

  return (
    <GcvDrawer open={props.openDrawer} onClose={props.toggleDrawer} style={{ width: '482px' }}>
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '1rem', height: 'calc(100% - 2rem)' }}
        data-cy="peer-groups-drawer"
      >
        <Grid container spacing={3}>
          <Grid item xs={1} style={{ lineHeight: '80px' }}>
            <BackButtonIcon onClick={props.toggleDrawer} />
          </Grid>
          <Grid item xs={11}>
            <h3 data-cy="drawer-header">{props.headerText}</h3>
          </Grid>
        </Grid>

        <hr style={{ margin: '0 -1rem' }} />
        <br />

        <Grid container spacing={3}>
          <Grid item xs={12} style={{ padding: '0 3rem' }}>
            <GcvInputForm
              {...form}
              rules={{ required: true }}
              name="name"
              label="Name"
              type="text"
              testDataTag="name"
            />
          </Grid>
          <Grid item xs={12} style={{ padding: '0 3rem' }}>
            <GcvInputTextArea {...form} name={'description'} label={'Description'} testDataTag="description" />
          </Grid>
        </Grid>

        <div style={{ flex: 1 }}></div>

        <hr style={{ margin: '0 -1rem' }} />
        <br />

        <Grid container justify="center" spacing={3}>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <GcvButton tertiary onClick={props.toggleDrawer} data-cy="cancel-button">
              Cancel
            </GcvButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <GcvButton
              primary
              disabled={!formState.isValid}
              onClick={handleSubmit(props.onFormComplete)}
              data-cy="complete-button"
            >
              {props.completeButtonText}
            </GcvButton>
          </Grid>
        </Grid>
      </div>
    </GcvDrawer>
  );
};
