import { ReactWrapperComponent, WrapperProps, DashboardRouterCRB } from '@user-interface/react-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DispensaryFacade, UserFacade, StaffFacade, DepositsFacade } from '@user-interface/gcv-state';
import { Dispensary, User, Deposit } from '@gcv/shared';
import {
  ComplianceZeroState as ComplianceZeroStateConstant,
  ContinueDueDiligence as ContinueDueDiligenceConstant,
  DashboardState as DashboardStateConstant,
  getComponentForDueDiligenceStatus,
  IdentificationVerification as IdentificationVerificationConstant,
  StartDueDiligence as StartDueDiligenceConstant,
} from '../../app/dispensary/dispensary.util';
import { DispensaryAppFamilyService } from '../../services/dispensary.service';
import { Router } from '@angular/router';

@Component({
  selector: 'crb-dashboard',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></ app-react-wrapper>',
})
export class DashboardComponent extends ReactWrapperComponent {
  public dispensary: Dispensary;
  public user: User;
  public deposits: Deposit[];
  public staff: { [id: string]: User };
  viewStatus;

  ComplianceZeroState = ComplianceZeroStateConstant;
  IdentificationVerification = IdentificationVerificationConstant;
  DashboardState = DashboardStateConstant;
  StartDueDiligence = StartDueDiligenceConstant;
  ContinueDueDiligence = ContinueDueDiligenceConstant;

  @Input() props: WrapperProps;
  @Input() reports: any;
  @Output() clickEmitter = new EventEmitter<any>();

  component = DashboardRouterCRB;

  constructor(
    private router: Router,
    private dispApp: DispensaryAppFamilyService,
    private dispFacade: DispensaryFacade,
    private userFacade: UserFacade,
    private staffFacade: StaffFacade,
    private depositsFacade: DepositsFacade
  ) {
    super();
  }

  ngOnInit() {
    this.setUpStreams();
    this.updateProps();
  }

  setUpStreams() {
    this.staffFacade.selectStaffDictionary().subscribe(staffMap => {
      this.staff = staffMap;
      this.updateProps();
    });

    this.dispFacade.selectCurrentDispensaryId().subscribe(id => {
      this.dispFacade.selectAllDispensaries().subscribe(disps => {
        this.dispensary = disps.find(disp => disp.id === id);
        if (this.user) {
          this.viewStatus = this.determineStatus();
        }
        this.updateProps();
      });
    });

    this.userFacade.selectCurrentUser().subscribe(user => {
      this.user = user;
      this.viewStatus = this.determineStatus();
      this.updateProps();
    });

    this.depositsFacade.getDepositsByDispensaryId(this.dispensary.id);
    this.depositsFacade.selectAllDeposits().subscribe(deps => {
      this.deposits = deps;
      this.updateProps();
    });
  }

  updateProps() {
    this.props = {
      ...this.props,
      staff: this.staff,
      dispensary: this.dispensary,
      user: this.user,
      deposits: this.deposits,
      viewStatus: this.viewStatus,
    };
  }

  ngOnChanges() {
    this.updateProps();
  }

  handleEmitData = (type, data) => {
    if (type === 'updateDispensary') {
      this.dispFacade.updateDispensary(data);
    }

    if (type === 'getDispensary') {
      this.dispFacade.getDispensarybyId(data);
    }

    if (type === 'goToOnboarding') {
      this.handleButtonClicked();
    }

    if (type === 'deposit') {
      this.router.navigate(['/secure/dispensary/deposit']).then(() => {});
    }

    if (type === 'integration') {
      this.router.navigate(['/secure/dispensary/company/overview'], { queryParams: { tab: 5 } }).then(() => {});
    }
  };

  determineStatus() {
    return getComponentForDueDiligenceStatus(this.dispensary, this.user);
  }

  handleButtonClicked() {
    if (this.viewStatus === this.IdentificationVerification) {
      this.dispApp.view.next({ view: '/secure/dispensary/verify-identity' });
    } else if (this.viewStatus === this.StartDueDiligence) {
      this.dispFacade.updateGcvDueDiligenceStatus(this.dispensary.id);
      this.dispApp.view.next({ view: '/secure/dispensary/onboarding/business-details' });
    } else if (this.viewStatus === this.ContinueDueDiligence) {
      this.dispApp.view.next({ view: '/secure/dispensary/onboarding/business-details' });
    } else {
      this.dispApp.view.next({ view: '/secure/dispensary/dashboard/sales' });
    }
  }
}
