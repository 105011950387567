import React, { useEffect, useState } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { GcvTabs } from '../../lib/GcvTabs/GcvTabs';
import { Header, Title, VerticalCenter } from './styles';
import { MinifiedDispensary, Bank, User, Dispensary } from '@gcv/shared';
import { AccountOwners } from './components/AccountOwners';
import { BackButtonIcon } from '../../icons/BackButtonIcon';
import { RouteObject } from '../../util/types';
import calculateTimeRange from '../../util/calculateDateRange';
import { AccountGeneral } from './components/AccountGeneral';
import { AccountOperational } from './components/AccountOperational';
import { AccountDocuments } from './components/AccountDocuments';
import { AccountTransportation } from './components/AccountTransportation';
import { AccountInternal } from './components/AccountInternal';
import { GcvLoading, GcvTimePeriodDropdown } from '../../lib';
import { api } from 'libs/react-ui/src/api';
import { GcvPage } from '../../lib/GcvPage/GcvPage';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { AccountAdditionalInformation } from './components/AccountAdditionalInformation';
import { AccountActivity } from './components/AccountActivity';
import { FlexBox } from '../../styles/theme';

interface Props {
  dispensaries: MinifiedDispensary[];
  bank: Bank;
  user: User;
  emitData: (type: string, data: Dispensary) => void;
}

const Tabs: RouteObject[] = [
  {
    path: '/secure/bank/account/:id/view/activity',
    render: props => <AccountActivity {...props} />,
  },
  {
    path: '/secure/bank/account/:id/view/account-owners',
    render: props => <AccountOwners {...props} />,
  },
  {
    path: '/secure/bank/account/:id/view/general',
    render: props => <AccountGeneral {...props} />,
  },
  {
    path: '/secure/bank/account/:id/view/operational',
    render: props => <AccountOperational {...props} />,
  },
  {
    path: '/secure/bank/account/:id/view/additional-info',
    render: props => <AccountAdditionalInformation {...props} />,
  },
  {
    path: '/secure/bank/account/:id/view/documents',
    render: props => <AccountDocuments {...props} />,
  },
  {
    path: '/secure/bank/account/:id/view/transportation',
    render: props => <AccountTransportation {...props} />,
  },
  {
    path: '/secure/bank/account/:id/view/internal',
    render: props => <AccountInternal {...props} />,
  },
];

export const ViewAccount = ({ bank, user, emitData }: Props) => {
  const [dispensaryUsers, setDispensaryUsers] = useState<User[]>([]);
  const [selectedDispensary, setSelectedDispensary] = useState<Dispensary>();
  const history = useHistory();
  const apiClient = api();
  const { dispensary_id } = useParams<{ dispensary_id: string }>();

  const emitDataWrapper = (type: string, data: Dispensary) => {
    if (type === 'updateDispensary') {
      setSelectedDispensary(data);
    }
    emitData(type, data);
  };

  const getDispensary = async () => {
    const dispensary = await apiClient.banks.getDispensary(bank.id, dispensary_id, () => {});
    setSelectedDispensary(dispensary);
  };

  const getUsers = async () => {
    const users = await apiClient.organizations.getUsersForOrg(dispensary_id, () => {});
    setDispensaryUsers(users);
  };

  useEffect(() => {
    getDispensary();
    getUsers();
  }, []);

  if (!selectedDispensary) {
    return <GcvLoading></GcvLoading>;
  }

  const tabRoutes = [
    { path: `/secure/bank/account/${dispensary_id}/view/activity`, label: `Activity` },
    { path: `/secure/bank/account/${dispensary_id}/view/account-owners`, label: `Account Owners` },
    { path: `/secure/bank/account/${dispensary_id}/view/general`, label: `General` },
    { path: `/secure/bank/account/${dispensary_id}/view/operational`, label: `Operational` },
    { path: `/secure/bank/account/${dispensary_id}/view/additional-info`, label: `Additional Info` },
    { path: `/secure/bank/account/${dispensary_id}/view/documents`, label: `Documents` },
    { path: `/secure/bank/account/${dispensary_id}/view/transportation`, label: `Transportation` },
    { path: `/secure/bank/account/${dispensary_id}/view/internal`, label: `Internal` },
  ];

  const onBack = () => {
    history.push(`/secure/bank/accounts/active`);
  };

  return (
    <GcvPage
      header={
        <GcvPageHeader
          title={selectedDispensary ? selectedDispensary.name : ''}
          handleBackClick={onBack}
          tabs={<GcvTabs routes={tabRoutes}></GcvTabs>}
        ></GcvPageHeader>
      }
      body={
        <>
          {Tabs.map(({ path, render }) => {
            return (
              <Route exact path={path} key={path}>
                {({ match }) =>
                  match != null ? (
                    render({
                      dispensary: selectedDispensary,
                      bank,
                      user,
                      emitData: emitDataWrapper,
                      isActiveAccount: true,
                      dispensaryUsers,
                    })
                  ) : (
                    <></>
                  )
                }
              </Route>
            );
          })}
        </>
      }
    />
  );
};
