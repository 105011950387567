export const $accent = '#828599';
export const $borderAccent = '#a5a8ba';
export const $primaryBorder = '#dbe0eb';
export const $warn = '#f5a623';
export const $danger = '#ff4e50';
export const $blue = '#6896fb';
export const $primary = '#00bc66';
export const $primaryLight = '#37d890';
export const $primaryDark = '#00a358';
export const $white = '#ffffff';
export const $black = '#16181e';
export const $black2 = '#33353C';
export const $grey1 = '#3a3c45';
export const $grey2 = '#a5a8ba';
export const $grey3 = '#e0e5f0';
export const $grey4 = '#f2f4fa';
export const $grey5 = '#f9fafd';
export const $grey6 = '#ebeef7';
export const $grey7 = 'rgba(0; 0; 0; 0.54)';
export const $grey8 = '#9b9eb1';
export const $grey9 = '#5A5E6B';
export const $grey10 = '#F8F9FD';
export const $grey11 = '#DBE1ED';
export const $grey12 = '#DEEBFF';
export const $grey13 = '#CBD1E2';

export const $fontFamily = 'Lato, Helvetica, Arial, sans-serif';
