import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MinifiedDispensary, Bank, BankPeerGroups, PeerGroup, User, OrganizationRoleResolver } from '@gcv/shared';
import { GcvButton, GcvLoading, GcvZeroState, GcvDataTable, GcvSearchInput } from '../../lib';
import { GcvPage } from '../../lib/GcvPage/GcvPage';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { VerticalCenter, FlexBox } from '../../styles/theme';
import { api } from '../../api';
import { AddAccountsModal } from './components/AddAccountsModal';
import { PeerGroupDrawer } from './components/PeerGroupDrawer';
import { getMinifiedDispensaryLicenseType } from 'libs/react-ui/src/util';
import ReactTooltip from 'react-tooltip';

interface Props {
  bank: Bank;
  dispensaries: MinifiedDispensary[];
  user: User;
}

export const PeerGroupDetails = (props: Props) => {
  const orgResolver = new OrganizationRoleResolver();
  const canEditDetails = props.bank
    ? orgResolver.userCanDoAction(props.bank.groups, props.user, 'peer_groups_edit')
    : true;

  const { group_id } = useParams<{ group_id?: string }>();
  const history = useHistory();
  const apiClient = api();

  const [loading, setLoading] = useState(true);
  const [peerGroup, setPeerGroup] = useState<PeerGroup>({} as PeerGroup);
  const [searchedTableAccount, setSearchedTableAccount] = useState<string>('');
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (props.bank.id) {
      loadPeerGroups();
    }
  }, [props.bank.id]);

  const loadPeerGroups = () => {
    apiClient.peerGroups.getPeerGroups(props.bank.id, setLoading).then((groups: BankPeerGroups) => {
      setPeerGroup(groups.peer_groups[group_id]);
    });
  };

  useEffect(() => {
    if (!loading && peerGroup.dispensaries) {
      filterAccountsTable();
    }
  }, [searchedTableAccount, peerGroup]);

  const handleBackClick = () => {
    history.push(`/secure/bank/settings/peer-groups`);
  };

  const onCompleteEdit = data => {
    apiClient.peerGroups
      .updatePeerGroup(props.bank.id, peerGroup.id, data, setLoading)
      .then((groups: BankPeerGroups) => {
        setPeerGroup(groups.peer_groups[group_id]);
        setOpenDrawer(false);
      });
  };

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 4,
      format: row => (row.name ? row.name : '--'),
    },
    {
      name: 'City',
      selector: 'city',
      sortable: true,
      grow: 1,
      format: row => (row.city ? row.city : '--'),
    },
    {
      name: 'License',
      selector: 'license',
      sortable: true,
      grow: 1,
      format: row => getMinifiedDispensaryLicenseType(row),
    },
  ];

  const zeroStateHeaderText = () =>
    searchedTableAccount ? 'There are no accounts that match your search' : 'There are no accounts in this peer group';
  const zeroStateSubText = () =>
    searchedTableAccount
      ? 'Please try changing your search above to show more accounts'
      : 'Add accounts to this peer group using the button above';

  const filterAccountsTable = () => {
    let filterAccounts = Object.values(props.dispensaries).filter(disp => peerGroup.dispensaries[disp.id]);
    if (searchedTableAccount) {
      filterAccounts = filterAccounts.filter(disp =>
        disp.name.toLowerCase().includes(searchedTableAccount.toLowerCase())
      );
    }
    setFilteredAccounts(filterAccounts);
  };

  return (
    <GcvPage
      loader={loading && <GcvLoading></GcvLoading>}
      header={
        <>
          <GcvPageHeader
            handleBackClick={handleBackClick}
            title={
              peerGroup.default_group ? (
                <span>
                  {peerGroup?.name} <img src={'../../../../../assets/Icon-Lock.svg'} />
                </span>
              ) : (
                peerGroup?.name
              )
            }
            subTitle={peerGroup?.description}
            actions={
              <VerticalCenter>
                <div
                  data-tip={peerGroup.default_group ? 'The Default group cannot be edited' : 'Edit Peer Group'}
                  style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }}
                >
                  {canEditDetails ? (
                    <>
                      <GcvButton
                        disabled={!peerGroup || peerGroup.default_group}
                        secondary={true}
                        onClick={() => setOpenDrawer(true)}
                        style={{ marginRight: '1rem' }}
                        data-cy="edit-button"
                      >
                        Edit Group
                      </GcvButton>
                      <GcvButton
                        disabled={!peerGroup || peerGroup.default_group}
                        primary={true}
                        onClick={() => setOpenModal(true)}
                        data-cy="edit-accounts-button"
                      >
                        Add / Remove Accounts
                      </GcvButton>
                      {peerGroup.default_group ? (
                        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
                      ) : null}
                    </>
                  ) : null}
                </div>
              </VerticalCenter>
            }
          ></GcvPageHeader>
        </>
      }
      body={
        <>
          <FlexBox style={{ flexDirection: 'column' }}>
            <FlexBox style={{ justifyContent: 'flex-end', width: '100%', height: '4rem' }}>
              <GcvSearchInput
                label="Search Accounts"
                onChange={e => setSearchedTableAccount(e.target.value)}
                value={searchedTableAccount}
                placeholder={''}
                id="search"
                style={{ marginTop: '0', width: '25%' }}
                data-cy="search-accounts"
              ></GcvSearchInput>
            </FlexBox>
            <GcvDataTable
              data={filteredAccounts}
              columns={columns}
              defaultSortField={'City'}
              defaultSortAsc={false}
              highlight={false}
              pointer={false}
              keyField="id"
              noDataComponent={
                <GcvZeroState type="basic" headerText={zeroStateHeaderText()} subText={zeroStateSubText()} />
              }
              subHeaderAlign="left"
              paginationPerPage={8}
              pointerOnHover={false}
            />
          </FlexBox>

          <PeerGroupDrawer
            toggleDrawer={() => setOpenDrawer(false)}
            openDrawer={openDrawer}
            defaultValues={peerGroup}
            loading={!peerGroup?.id}
            onFormComplete={onCompleteEdit}
            completeButtonText={'Save'}
            headerText={'Edit Group'}
          ></PeerGroupDrawer>

          <AddAccountsModal
            dispensaries={props.dispensaries}
            peerGroup={peerGroup}
            openModal={openModal}
            toggleModal={() => setOpenModal(!openModal)}
            bankId={props.bank.id}
            refreshData={loadPeerGroups}
            loading={loading}
          ></AddAccountsModal>
        </>
      }
    />
  );
};
