<div class="checkbox">
  <label>
    <input
      (change)="handleChange($event)"
      [(ngModel)]="checked"
      [attr.data-cy]="'checkbox.component'"
      type="checkbox"
    /><span class="checkbox-material"><span [attr.data-cy]="'checkbox.component.' + label" class="check"></span></span>
    <span [ngStyle]="labelStyle" class="labelText">{{ label }}</span>
  </label>
</div>
