import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { ModalStyleOverrides, customModalStyles, Buttons, Title } from './styles';
import { GcvLoading } from '../GcvLoading/GcvLoading';

export interface ModalProps {
  children?: React.ReactNode;
  modalOpen: boolean;
  toggleModal: () => void;
  backButton: React.ReactNode;
  continueButton?: React.ReactNode;
  title: string;
  loading?: boolean;
  [key: string]: any;
}

export const GcvModal = ({
  children,
  toggleModal,
  modalOpen,
  backButton,
  continueButton,
  title,
  loading,
  ...props
}: ModalProps) => {
  useEffect(() => {
    Modal.setAppElement('#modal-root');
  }, []);

  return (
    <>
      <ModalStyleOverrides />
      <div id="modal-root"></div>
      <Modal style={customModalStyles} isOpen={modalOpen} onRequestClose={toggleModal} {...props}>
        <Title data-cy="modal-title">{title}</Title>
        {loading ? (
          <GcvLoading></GcvLoading>
        ) : (
          <>
            {children}
            <Buttons single={!continueButton || !backButton}>
              {backButton}
              {continueButton}
            </Buttons>
          </>
        )}
      </Modal>
    </>
  );
};
