<gcv-card class="col-4" data-cy="provided-services-transportation">
  <div card-body class="innerWrapper">
    <div class="header">
      <h4 data-cy="title">{{ title }}</h4>
      <span data-cy="subtext">{{ subtitle }}</span>
    </div>
    <div class="mainWrapper">
      <div
        *ngFor="let vendor of vendors; let i = index"
        data-cy="vendor-bank"
        [ngClass]="i === vendors.length - 1 ? 'lastItem' : 'item'"
        class="userWrapper"
      >
        <span
          [ngClass]="vendor.active ? (vendor.favorite ? 'favorite' : 'active') : 'inactive'"
          data-cy="vendor-name"
          >{{ vendor.name }}</span
        >
        <div class="iconWrapper">
          <svg
            *ngIf="dispensaryView"
            fill="none"
            height="19"
            viewBox="0 0 13 19"
            width="13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8825 17.9725H2.11716C1.50129 17.9725 1 17.4712 1 16.8554V8.07565C1 7.45978 1.50129 6.9585 2.11716 6.9585H10.8825C11.4984 6.9585 11.9997 7.45978 11.9997 8.07565V16.841C12.014 17.4712 11.5127 17.9725 10.8825 17.9725Z"
              stroke="#DBE1ED"
              stroke-miterlimit="10"
              stroke-width="1.5"
            />
            <path
              d="M6.49924 1C4.35086 1 2.60352 2.74735 2.60352 4.89573V6.97249H10.3806V4.89573C10.395 2.74735 8.64762 1 6.49924 1Z"
              stroke="#DBE1ED"
              stroke-miterlimit="10"
              stroke-width="1.5"
            />
            <line stroke="#DBE1ED" stroke-linecap="round" x1="6.5" x2="6.5" y1="10.5" y2="14.5" />
          </svg>
          <button (click)="handleEdit(vendor)" *ngIf="!dispensaryView" class="miniBtn">
            <svg
              class="imgActive edit"
              fill="none"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M0.0164558 11.9998L0 15.2911C0 15.4766 0.0675108 15.662 0.202743 15.7973C0.337976 15.9325 0.505909 16 0.692196 16L3.96647 15.9835C4.15191 15.9835 4.32069 15.9158 4.45592 15.7808L15.7636 4.47232C16.0338 4.20291 16.0338 3.76367 15.7636 3.47675L12.523 0.202691C12.2528 -0.0675635 11.8144 -0.0675635 11.5277 0.202691L9.26628 2.48139L0.219199 11.5103C0.101266 11.6453 0.0164558 11.8143 0.0164558 11.9998ZM12.0336 1.68772L14.3123 3.96642L13.03 5.2487L10.7513 2.97L12.0336 1.68772ZM1.41752 12.3038L9.75488 3.96642L12.0336 6.24512L3.69621 14.5652L1.40106 14.5825L1.41752 12.3038Z"
                fill="#DBE1ED"
                fill-rule="evenodd"
              />
            </svg>
          </button>
          <button
            (click)="handleFavorite(vendor, i)"
            [attr.data-cy]="'bank-transport-vendors.component.favorite.' + vendor.name"
            [disabled]="!(vendor.active && !dispensaryView)"
            [ngClass]="vendor.active && !dispensaryView ? 'imgActive' : 'imgInactive'"
            class="miniBtn"
          >
            <svg
              *ngIf="vendor.active && vendor.favorite"
              class="starImg"
              fill="none"
              height="18"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.6 1a1 1 0 0 1 1.8 0l2 4.3 4.7.7a1 1 0 0 1 .5 1.7L13.2 11 14 16a1 1 0 0 1-1.4 1l-4.1-2.2-4 2.2A1 1 0 0 1 3 16l.8-4.9L.4 7.7A1 1 0 0 1 .9 6l4.7-.7 2-4.4z"
                fill="#02B45B"
              />
            </svg>
            <svg
              *ngIf="vendor.active && !vendor.favorite"
              class="starImg"
              fill="none"
              height="18"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9 5.5l.1.3h.3l4.7.8c.3 0 .4.4.2.6l-3.4 3.5-.2.2v.3l.8 4.9c.1.3-.2.5-.5.4l-4.1-2.3-.3-.1-.3.1-4 2.3c-.4.1-.7-.1-.6-.4l.7-4.9V11l-.1-.2L.8 7.2a.4.4 0 0 1 .2-.6l4.7-.8H6l.1-.3 2-4.3c.2-.3.6-.3.8 0l2 4.3z"
                stroke="#DBE1ED"
                stroke-width="1.2"
              />
            </svg>

            <svg
              *ngIf="!vendor.active && !vendor.favorite"
              class="starImg"
              fill="none"
              height="18"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 5.5l.1.2h.3l4.6.8c.4 0 .6.5.3.8l-3.4 3.5-.2.2v.2l.8 4.8c.1.5-.3.8-.7.6l-4-2.3-.3-.1-.2.1-4.1 2.3a.5.5 0 0 1-.7-.6l.7-4.8v-.4L.6 7.3a.5.5 0 0 1 .3-.8l4.6-.8H6l.1-.2L8 1c.2-.3.8-.3 1 0l2 4.4z"
                stroke="#F0F2F9"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</gcv-card>
