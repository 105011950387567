import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Bank, Dispensary } from '@gcv/shared';
import * as _ from 'lodash';

export const createHoursFormGroup = (org: Bank | Dispensary) => {
  return new FormGroup({
    monday: new FormGroup({
      isOpen: new FormControl(_.get(org, 'businessHours.monday.isOpen', true), Validators.required),
      openHour: new FormControl(_.get(org, 'businessHours.monday.openHour', '09:00'), Validators.required),
      closeHour: new FormControl(_.get(org, 'businessHours.monday.closeHour', '17:00'), Validators.required),
    }),
    tuesday: new FormGroup({
      isOpen: new FormControl(_.get(org, 'businessHours.tuesday.isOpen', true), Validators.required),
      openHour: new FormControl(_.get(org, 'businessHours.tuesday.openHour', '09:00'), Validators.required),
      closeHour: new FormControl(_.get(org, 'businessHours.tuesday.closeHour', '17:00'), Validators.required),
    }),
    wednesday: new FormGroup({
      isOpen: new FormControl(_.get(org, 'businessHours.wednesday.isOpen', true), Validators.required),
      openHour: new FormControl(_.get(org, 'businessHours.wednesday.openHour', '09:00'), Validators.required),
      closeHour: new FormControl(_.get(org, 'businessHours.wednesday.closeHour', '17:00'), Validators.required),
    }),
    thursday: new FormGroup({
      isOpen: new FormControl(_.get(org, 'businessHours.thursday.isOpen', true), Validators.required),
      openHour: new FormControl(_.get(org, 'businessHours.thursday.openHour', '09:00'), Validators.required),
      closeHour: new FormControl(_.get(org, 'businessHours.thursday.closeHour', '17:00'), Validators.required),
    }),
    friday: new FormGroup({
      isOpen: new FormControl(_.get(org, 'businessHours.friday.isOpen', true), Validators.required),
      openHour: new FormControl(_.get(org, 'businessHours.friday.openHour', '09:00'), Validators.required),
      closeHour: new FormControl(_.get(org, 'businessHours.friday.closeHour', '17:00'), Validators.required),
    }),
    saturday: new FormGroup({
      isOpen: new FormControl(_.get(org, 'businessHours.saturday.isOpen', false), Validators.required),
      openHour: new FormControl(_.get(org, 'businessHours.saturday.openHour', '09:00'), Validators.required),
      closeHour: new FormControl(_.get(org, 'businessHours.saturday.closeHour', '17:00'), Validators.required),
    }),
    sunday: new FormGroup({
      isOpen: new FormControl(_.get(org, 'businessHours.sunday.isOpen', false), Validators.required),
      openHour: new FormControl(_.get(org, 'businessHours.sunday.openHour', '09:00'), Validators.required),
      closeHour: new FormControl(_.get(org, 'businessHours.sunday.closeHour', '17:00'), Validators.required),
    }),
  }) as FormGroup;
};
