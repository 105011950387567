import styled from 'styled-components';
import { $grey3, $grey2 } from 'libs/react-ui/src/util/styleVariables';

export const CalendarContainer = styled.div`
  .react-calendar {
    margin-top: 10px;
    position: absolute;
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    z-index: 12;

    button {
      text-align: center;
      padding: 0.75em 0.5em;
      background: none;
      cursor: pointer;
      border: none;

      &: hover {
        background-color: ${$grey3};
      }

      &: focus {
        outline: none;
      }
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
    }

    .react-calendar__month-view__days__day--weekend {
      color: #d10000;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #757575;
    }
    .react-calendar__tile--active {
      background-color: ${$grey2};
    }
    .react-calendar__tile--hover {
      background-color: ${$grey3};
    }
  }
`;
