// tslint:disable
/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
// tslint:enable

import { Component, Input } from '@angular/core';
import { AuthState } from 'aws-amplify-angular/dist/src/providers/auth.state';
import { AmplifyService } from 'aws-amplify-angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordStrengthValidator } from '../../validators/passwordStrength';
import { CustomValidators } from 'ngx-custom-validators';
import { checkRequirements } from '../password-util';
import { API } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-amplify-auth-forgot-password-core',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  // tslint:disable-next-line:variable-name
  _authState: AuthState;
  // tslint:disable-next-line:variable-name
  _show: boolean;

  username: string;
  code: string;
  password: string;

  userData = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  formData: FormGroup;

  errorMessage: string;

  requirements = {
    length: false,
    special: false,
    number: false,
    lower: false,
    upper: false,
  };

  // tslint:disable-next-line:variable-name
  code_sent = false;

  amplifyService: AmplifyService;

  constructor(amplifyService: AmplifyService, private snackbar: MatSnackBar) {
    this.amplifyService = amplifyService;
    const password = new FormControl('', [Validators.required, Validators.minLength(10), PasswordStrengthValidator]);
    const certainPassword = new FormControl('', CustomValidators.equalTo(password));
    this.formData = new FormGroup({
      code: new FormControl('', Validators.required),
      newPass: password,
      newPassCheck: certainPassword,
    });
    password.valueChanges.subscribe(pw => {
      this.requirements = checkRequirements(pw);
    });
  }

  @Input()
  set data(data: any) {
    this._authState = data.authState;
    this.userData.controls.email.setValue(data.authState.user ? data.authState.user.username.toLowerCase() || '' : '');
  }

  @Input()
  set authState(authState: AuthState) {
    this._authState = authState;
    this._show = authState.state === 'forgotPassword';

    this.userData.controls.email.setValue(authState.user ? authState.user.username.toLowerCase() || '' : '');
  }

  setUsername(username: string) {
    this.username = username.toLowerCase();
  }

  isValid() {
    return this.formData.valid;
  }

  isEmailValid() {
    return this.userData.valid;
  }

  setCode(code: string) {
    this.code = code;
  }

  setPassword(password: string) {
    this.password = password;
  }

  onSend() {
    const { email } = this.userData.getRawValue();
    if (!email) {
      this.errorMessage = 'Email cannot be empty';
      return;
    }

    API.post(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/auth/forgot-password`, {
      body: { email: email.toLowerCase() },
    })
      .then(() => {
        this._setError(null);
        this.code_sent = true;
        this.snackbar.open(`Code sent to ${email}!`, null, {
          verticalPosition: 'top',
          duration: 4000,
        });
      })
      .catch(err => {
        this._setError(`Something went wrong, please try again later`);
        this.code_sent = false;
      });
  }

  onSubmit() {
    const { email } = this.userData.getRawValue();
    const { code, newPass } = this.formData.getRawValue();
    API.post(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/auth/forgot-password-submit`, {
      body: { email: email.toLowerCase(), code, password: newPass },
    })
      .then(() => {
        this.snackbar.open(`Successfully reset password!`, null, {
          verticalPosition: 'top',
          duration: 4000,
        });
        const user = { username: email };
        this.amplifyService.setAuthState({ state: 'signIn', user });
      })
      .catch(err => {
        if (err.message === 'Request failed with status code 403') {
          this._setError(`Code mismatch, ensure you typed the correct code and try again`);
        } else {
          this._setError(`Something went wrong, please try again later`);
        }
      });
  }

  onSignIn() {
    this.amplifyService.setAuthState({ state: 'signIn', user: null });
  }

  onAlertClose() {
    this._setError(null);
  }

  _setError(err) {
    if (!err) {
      this.errorMessage = null;
      return;
    }

    this.errorMessage = err.message || err;
  }
}
