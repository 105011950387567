<div class="wrapper" data-cy="create-user-dialog.component">
  <h1>{{ title }}</h1>
  <div class="orgDetails">
    <div class="userDetails">
      <gcv-mat-input
        [errors]="data.controls['firstName'].errors"
        [group]="data"
        [validators]="[
          {
            type: 'required',
            message: 'You must enter a First Name'
          }
        ]"
        control="firstName"
        data-cy="create-user-dialog.component.firstName"
        label="First Name"
        property="amount"
        required="true"
        type="text"
        [autoFill]="'off'"
      >
      </gcv-mat-input>
      <gcv-mat-input
        [errors]="data.controls['lastName'].errors"
        [group]="data"
        [validators]="[
          {
            type: 'required',
            message: 'You must enter a Last Name'
          }
        ]"
        control="lastName"
        data-cy="create-user-dialog.component"
        data-cy="create-user-dialog.component.lastName"
        label="Last Name"
        property="amount"
        required="true"
        type="text"
      >
      </gcv-mat-input>
      <gcv-mat-input
        [errors]="data.controls['email'].errors"
        [group]="data"
        [autoFill]="'off'"
        [validators]="[
          {
            type: 'required',
            message: 'You must enter an email'
          },
          {
            type: 'email',
            message: 'Email must be valid'
          }
        ]"
        control="email"
        data-cy="create-user-dialog.component.email"
        label="Email"
        property="email"
        required="true"
        type="text"
      >
      </gcv-mat-input>
      <mat-form-field>
        <mat-select
          [formControl]="data.controls['role']"
          data-cy="create-user-dialog.component.roleSelect"
          placeholder="Initial Group"
          required
        >
          <mat-option *ngFor="let role of roles" [value]="role.value" data-cy="create-user-dialog.component.roleOption">
            {{ role.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <p class="subtitle">{{ subtitle }}</p>
    <gcv-button
      (clickEvent)="handleCreateUser()"
      [disabled]="isDisabled()"
      buttonText="Send Invite"
      class="full-width"
      data-cy="create-user-dialog.component.button"
      type="primary full-width"
    ></gcv-button>
  </div>
</div>
