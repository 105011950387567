import React, { useState, useEffect } from 'react';
import { api } from '../api';
import { GcvLoading, GcvInputSelectNew, GcvInputFile, GcvButton, GcvInputForm, GcvContent } from '../lib';
import { Dispensary } from '@gcv/shared';
import { environment } from 'apps/user-interface/src/environments/environment';
import { HorizontalCenter } from '../styles/theme';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { s3Util } from '../util/s3.util';

const Container = styled.div`
  margin: 0 1rem;
`;

const Header = styled.div`
  width: 288px;
  color: white;
  text-align: center;
  padding: 0.5rem 4rem;
  box-sizing: border-box;
  background-color: #37d890;
`;

const HeaderText = styled.h3`
  color: white;
  background-color: #37d890;
  font-size: 1.17em;
  font-weight: bold;
`;

const Block = styled.div`
  margin: 1rem;
`;

export const GcvTriggerIngestSales = props => {
  const apiClient = api();
  const [loading, setLoading] = useState(false);
  const [selectedDispensaryId, setSelectedDispensaryId] = useState<string>('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const [selectedDispensary, setSelectedDispensary] = useState<Dispensary>(null);

  const getDispensary = async () => {
    if (selectedDispensaryId) {
      try {
        const disp = await apiClient.dispensaries.getDispensaryById(selectedDispensaryId, () => {});
        setSelectedDispensary(disp);
      } catch (e) {
        setError('Error Finding Dispensary');
      }
    }
  };

  const onSubmit = async () => {
    try {
      apiClient.gcv.manuallyTriggerSalesIngestion(
        selectedDispensaryId,
        { start_date: startDate, end_date: endDate },
        setLoading
      );
    } catch (e) {
      console.log(e);
      setError('Error Triggering Ingestion');
    }
  };

  return (
    <Container>
      <Header>
        <HeaderText>Trigger Sales Ingestion</HeaderText>
      </Header>
      {loading ? (
        <GcvLoading></GcvLoading>
      ) : (
        <div>
          <Block>
            <TextField
              fullWidth
              label="Dispensary ID*"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedDispensaryId(e.target.value)}
              onBlur={getDispensary}
            />
            {selectedDispensary ? (
              <GcvContent type="h3" content={`You have selected: ${selectedDispensary.name}`} />
            ) : null}
          </Block>

          <Block>
            <GcvContent type="p1" content="Dates need to be entered in ISO format e.g:" />
            <GcvContent style={{ marginTop: '.25rem' }} type="p2" content="2020-02-24T00:00:00" />
          </Block>

          <Block>
            <TextField
              fullWidth
              label="Start Date*"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
            />
          </Block>

          <Block>
            <TextField
              fullWidth
              label="End Date (will default to today)"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
            />
          </Block>

          <Block>
            <HorizontalCenter>
              <GcvButton onClick={onSubmit} disabled={!selectedDispensaryId || !startDate} primary={true}>
                Submit
              </GcvButton>
            </HorizontalCenter>
            {error ? (
              <HorizontalCenter style={{ color: 'red', marginTop: '1rem', fontSize: 10 }}>
                Error: {error}
              </HorizontalCenter>
            ) : null}
          </Block>
        </div>
      )}
    </Container>
  );
};
