import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { Bank, Dispensary, DispensaryMonitoringData, User } from '@gcv/shared';
import { api } from '../../../api';
import calculateTimeRange from '../../../util/calculateDateRange';
import {
  GcvButton,
  GcvDataTable,
  GcvLoading,
  GcvSearchInput,
  GcvTimePeriodDropdown,
  GcvUserInputContainer,
  GcvZeroState,
} from '../../../lib';
import { formatMoney, formatMoneyWithNegatives, formatPercentage } from 'libs/react-ui/src/util';
import { DateTime } from 'luxon';

interface Props {
  bank: Bank;
  dispensaries: { [id: string]: Dispensary };
  user: User;
}

export const DataTab = (props: Props) => {
  const apiClient = api();
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [searchableTransactions, setSearchableTransactions] = useState([]);
  const [timePeriod, setTimePeriod] = useState(calculateTimeRange('last30Days', props.bank.iana_timezone));

  useEffect(() => {
    getDispensaryAccountReviewTableData();
  }, [props.bank.id, props.dispensaries, timePeriod]);

  useEffect(() => {
    if (!isLoading) {
      setFilteredTransactionsFromQuery(searchableTransactions);
    }
  }, [query]);

  const getDispensaryAccountReviewTableData = () => {
    if (!props.dispensaries) {
      return;
    }
    const startDate = DateTime.fromISO(timePeriod.start, { zone: props.bank.iana_timezone }).toFormat('yyyy-MM-dd');
    const endDate = DateTime.fromISO(timePeriod.end, { zone: props.bank.iana_timezone }).toFormat('yyyy-MM-dd');
    apiClient.accountMonitoring.getAccountMonitoring(props.bank.id, startDate, endDate, setIsLoading).then(r => {
      const transactions = Object.values(r).map((t: DispensaryMonitoringData) => {
        const dispensary = props.dispensaries[t.dispensaryId];

        return {
          ...t,
          account: dispensary ? dispensary.name : '',
        };
      });
      setSearchableTransactions(transactions);
      setFilteredTransactionsFromQuery(transactions);
    });
  };

  const columns = [
    {
      name: 'Account',
      selector: 'account',
      sortable: true,
      left: true,
      format: r => <GcvUserInputContainer string={r.account} length={20} />,
    },
    {
      name: 'Deposits',
      selector: 'deposits',
      sortable: true,
      left: true,
      format: r => formatMoney(r['deposits']),
    },
    {
      name: 'Sales',
      selector: 'sales',
      sortable: true,
      left: true,
      format: r => formatMoney(r['sales']),
    },
    {
      name: 'Sales - Deposits',
      selector: 'sales_deposits',
      sortable: true,
      left: true,
      format: r => formatMoneyWithNegatives(r['sales_deposits']),
    },
    {
      name: 'Withdrawals',
      selector: 'withdrawals',
      sortable: true,
      left: true,
      format: r => formatMoney(r['withdrawals']),
    },
    {
      name: 'Avg. Deposit',
      selector: 'average_deposit',
      sortable: true,
      left: true,
      format: r => formatMoney(r['average_deposit']),
    },
    {
      name: 'Physical In',
      selector: 'physical_in',
      sortable: true,
      left: true,
      format: r => formatMoney(r['physical_in']),
    },
  ];

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const setFilteredTransactionsFromQuery = transactions => {
    if (query) {
      setFilteredTransactions(transactions.filter(a => a.account.toLowerCase().includes(query.toLowerCase())));
    } else {
      setFilteredTransactions(transactions);
    }
  };

  const handleTimeChange = results => {
    const timeRange = calculateTimeRange(results.value, props.bank.iana_timezone);
    setIsLoading(true);
    setTimePeriod(timeRange);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={5} sm={6} md={7}>
          <GcvTimePeriodDropdown
            emitData={handleTimeChange}
            labelText={'Time Period'}
            newStyle={true}
            defaultValueDrop={'last30Days'}
          />
        </Grid>
        <Grid item xs={7} sm={6} md={5} style={{ textAlign: 'right' }}>
          <Grid container spacing={3}>
            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
              <GcvSearchInput
                label="Search Accounts"
                placeholder="Search Accounts"
                id="searchGroups"
                value={query}
                style={{ height: 'auto', marginTop: '0px', textAlign: 'left' }}
                onChange={onSearchChange}
                data-cy="search-accounts"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <GcvLoading></GcvLoading>
          ) : (
            <GcvDataTable
              data={filteredTransactions ? filteredTransactions : []}
              columns={columns}
              defaultSortField={'name'}
              defaultSortAsc={true}
              keyField="id"
              highlight={false}
              pointer={false}
              noDataComponent={
                query ? (
                  <GcvZeroState
                    type="bankDash"
                    headerText={'There are no transactions that match your search'}
                    subText={'Please try changing your search to show more transactions'}
                  />
                ) : (
                  <GcvZeroState
                    type="bankDash"
                    headerText={'No transactions have been imported'}
                    subText={'Your transactions will be displayed here as soon as they are imported'}
                  />
                )
              }
              subHeaderAlign="left"
              paginationPerPage={10}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
