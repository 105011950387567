import { select, Store } from '@ngrx/store';
import { NotificationState } from './notifications.reducer';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import {
  selectCompleteNotification,
  selectIncompleteNotifications,
  selectNotificationsAll,
  selectNotificationsEntities,
} from './notifications.selectors';
import { Notification } from '@gcv/shared';
import {
  CompleteNotification,
  LoadNotifications,
  MarkNotificationSeen,
  ResetNotifications,
  UpdateNotification,
} from './notifications.actions';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsFacade {
  notifications$: Observable<Dictionary<Notification>>;

  constructor(private store: Store<NotificationState>) {
    this.notifications$ = store.pipe(select(selectNotificationsEntities));
  }

  getNotificationsByUserId(userId: string, orgId?: string, timeStamp?: string) {
    this.store.dispatch(new LoadNotifications({ userId, orgId, timeStamp }));
  }

  updateNotification(notification: Notification) {
    this.store.dispatch(new UpdateNotification(notification));
  }

  completeNotification(notification: Notification) {
    this.store.dispatch(new CompleteNotification(notification));
  }

  markNotificationSeen(notificationId: string) {
    this.store.dispatch(new MarkNotificationSeen({ notificationId }));
  }

  resetNotifications() {
    this.store.dispatch(new ResetNotifications());
  }

  selectIncompleteNotifications() {
    return this.store.pipe(select(selectIncompleteNotifications));
  }

  selectCompleteNotifications() {
    return this.store.pipe(select(selectCompleteNotification));
  }

  selectAllNotifications() {
    return this.store.pipe(select(selectNotificationsAll));
  }
}
