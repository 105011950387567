import styled from 'styled-components';
import { $primary, $grey3, $grey2 } from '../../util/styleVariables';

interface PlaceHolderProps {
  tiny: boolean;
  focus: boolean;
}

export const Container = styled.div`
  width: 100%;
  position: relative;
  .input-style {
    flex-grow: 1;
    font-size: 16px;
    margin-top: 16px;
    padding: 5px 0px;
    border: none;
    border-bottom: 1px solid ${$grey3};
    &: focus {
      outline: none;
      border: none;
      border-bottom: 1px solid ${$primary};
    }
  }

  .input-style:disabled {
    border-bottom: none;
  }

  .react-calendar {
    margin-top: 10px;
    position: absolute;
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    z-index: 1;

    button {
      text-align: center;
      padding: 0.75em 0.5em;
      background: none;
      cursor: pointer;
      border: none;

      &: hover {
        background-color: ${$grey3};
      }

      &: focus {
        outline: none;
      }
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
    }

    .react-calendar__month-view__days__day--weekend {
      color: #d10000;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #757575;
    }
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const CalendarIcon = styled.img`
  margin: 21px 20px 5px 20px;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

export const Warning = styled.div`
  font-size: 10px;
  padding: 2px 0px;
  color: red;
  height: 14px;
`;

export const PlaceHolder = styled.div<PlaceHolderProps>`
  font-size: ${props => (props.tiny ? '13px' : '16px')};
  margin-top: ${props => (props.tiny ? '0px' : '16px')};
  padding-bottom: 0px 0px 10px 0px;
  transition-property: margin-top, font-size;
  transition-duration: 0.5s;
  position: absolute;
  color: ${props => (props.focus ? $primary : $grey2)};
  pointer-events: none;
`;

export const DisplayValue = styled.div`
  width: 100%;
  font-size: 16px;
  margin-top: 16px;
  padding: 5px 0px;
  border: none;
  pointer-events: none;
  position: absolute;
  &: focus {
    outline: none;
    border: none;
  }
`;
