import * as fromAggregates from './aggregates.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectAggregatesState = createFeatureSelector<fromAggregates.AggregatesState>('aggregates');

export const selectBankAggregates = createSelector(
  selectAggregatesState,
  (state: fromAggregates.AggregatesState) => state.bankAggregate
);

export const selectSalesAggregates = createSelector(
  selectAggregatesState,
  (state: fromAggregates.AggregatesState) => state.salesAggregate
);

export const selectFincenReportAggregates = createSelector(
  selectAggregatesState,
  (state: fromAggregates.AggregatesState) => state.fincenReportAggregate
);

export const selectSarReportPeriodAggregates = createSelector(
  selectAggregatesState,
  (state: fromAggregates.AggregatesState) => state.sarReportPeriodAggregate
);

export const selectFincenDepositAggregates = createSelector(
  selectAggregatesState,
  (state: fromAggregates.AggregatesState) => state.fincenDepositAggregate
);

export const selectDispensaryAggregates = createSelector(
  selectAggregatesState,
  (state: fromAggregates.AggregatesState) => state.dispensaryAggregate
);

export const selectAggregateById = createSelector(selectSalesAggregates, (sales, props) => {
  return sales[props.companyId];
});

export const selectDispensaryAggregateById = createSelector(
  selectDispensaryAggregates,
  (dispensaryAggregate, props) => {
    return dispensaryAggregate[props.companyId];
  }
);

export const selectBankAggregateById = createSelector(selectBankAggregates, (bankAggregate, props) => {
  return bankAggregate[props.bankId];
});

export const selectFincenDepositAggregateById = createSelector(
  selectFincenDepositAggregates,
  (fincenDepositAgg, props) => {
    return Object.values(fincenDepositAgg)[0] ? Object.values(fincenDepositAgg)[0][props.reportId] : undefined;
  }
);
