import React, { useState } from 'react';
import { ExpansionSectionContainer, SectionHeader } from './styles';
import { VerticalCenter } from '../../styles/theme';
import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { Collapse } from 'react-collapse';

interface Props {
  label: string;
  defaultOpen?: boolean;
}

export const GcvCollapsibleSection: React.FC<Props> = props => {
  const [expansionVisible, setExpansionVisible] = useState(props.defaultOpen);
  return (
    <ExpansionSectionContainer>
      <div
        onClick={() => setExpansionVisible(!expansionVisible)}
        style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
        data-cy={`${props.label}-toggle`}
      >
        <VerticalCenter style={{ width: 'fit-content' }}>
          <DownArrowIcon
            style={{
              transform: expansionVisible ? '' : 'rotate(-90deg)',
              transition: 'transform 0.5s',
            }}
          />
        </VerticalCenter>
        <SectionHeader>{props.label}</SectionHeader>
      </div>
      <Collapse isOpened={expansionVisible}>{props.children}</Collapse>
    </ExpansionSectionContainer>
  );
};
