import React from 'react';

interface Props {
  height?: number;
  width?: number;
  style?: any;
  onClick(event: React.MouseEvent<HTMLOrSVGElement>): void;
}

export const BackButtonIcon = ({ height = 32, width = 33, style, onClick }: Props) => (
  <svg
    onClick={onClick}
    style={{ cursor: 'pointer', ...style }}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="back-button-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3559 31C24.8197 31 31.681 24.2843 31.681 16C31.681 7.71573 24.8197 1 16.3559 1C7.89204 1 1.03076 7.71573 1.03076 16C1.03076 24.2843 7.89204 31 16.3559 31Z"
      fill="white"
      stroke="#DBE0EB"
    />
    <path
      d="M19.2518 20.707C19.551 20.9999 19.551 21.4747 19.2518 21.7676C18.9526 22.0605 18.4674 22.0605 18.1682 21.7676L12.269 15.9936L18.1682 10.2197C18.4674 9.92678 18.9526 9.92678 19.2518 10.2197C19.551 10.5126 19.551 10.9874 19.2518 11.2803L14.4363 15.9936L19.2518 20.707Z"
      fill="#A5A8BA"
    />
  </svg>
);
