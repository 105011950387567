import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-zero-state',
  templateUrl: './zero-state.component.html',
  styleUrls: ['./zero-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZeroStateComponent implements OnInit {
  @Input() headerText = ``;
  @Input() image: 'default' | 'bank-dashboard' | 'bank-dd' | 'scroll' = 'default';
  @Input() subText = ``;
  @Input() imgSrc;
  @Output() buttonClickedEvent = new EventEmitter<void>();
  @Input() button: { type: string; text: string };

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}

  handleButtonClicked() {
    this.buttonClickedEvent.emit();
  }
}
