import React from 'react';
import { Container, Content, Title, SubTitle, ButtonWrapper, DashboardSubTitle } from './styles';

const basicImg = require('../../../../../apps/user-interface/src/assets/book.svg').default as string;
const scrollImg = require('../../../../../apps/user-interface/src/assets/scroll.svg').default as string;
const bankDashImg = require('../../../../../apps/user-interface/src/assets/bankDash.svg').default as string;
const bankImg = require('../../../../../apps/user-interface/src/assets/bank.svg').default as string;
const verifyImg = require('../../../../../apps/user-interface/src/assets/ids.svg').default as string;
const dispImg = require('../../../../../apps/user-interface/src/assets/retail.svg').default as string;
const depositImg = require('../../../../../apps/user-interface/src/assets/deposit.svg').default as string;

interface Props {
  type?: 'basic' | 'scroll' | 'bankDash' | 'bank' | 'verify' | 'disp' | 'deposit' | '';
  headerText?: string;
  subText?: any;
  dashboardSubText?: string;
  button?: React.ReactNode;
  style?: any;
}

export const GcvZeroState = ({ type, headerText, subText, dashboardSubText, button, style }: Props) => {
  let imgSrc: string = '';
  switch (type) {
    case 'basic':
    default:
      imgSrc = basicImg;
      break;
    case 'scroll':
      imgSrc = scrollImg;
      break;
    case 'bankDash':
      imgSrc = bankDashImg;
      break;
    case 'bank':
      imgSrc = bankImg;
      break;
    case 'verify':
      imgSrc = verifyImg;
      break;
    case 'disp':
      imgSrc = dispImg;
      break;
    case 'deposit':
      imgSrc = depositImg;
      break;
  }
  return (
    <Container data-cy="zero-state-container" style={style}>
      <Content>{type?.length > 0 && <img src={imgSrc} data-cy="zero-state-icon" />}</Content>
      {headerText ? <Title data-cy="zero-state.component.headerText">{headerText}</Title> : null}
      <SubTitle data-cy="zero-state.component.subText">{subText}</SubTitle>
      <DashboardSubTitle data-cy="zero-state.component.dashboardSubText">{dashboardSubText}</DashboardSubTitle>

      {button ? <ButtonWrapper>{button}</ButtonWrapper> : null}
    </Container>
  );
};
