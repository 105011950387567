import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@gcv/shared';

interface DocumentationView {
  type: string;
  id: string;
  files: string;
  user_uploaded: string;
  name: string;
  status: string;
  reviewed_by: string;
}

@Component({
  selector: 'gcv-view-account-documents-table',
  templateUrl: './view-account-documents-table.component.html',
  styleUrls: ['./view-account-documents-table.component.scss'],
})
export class ViewAccountDocumentsTableComponent implements OnInit {
  @Input() documents: any;
  @Input() userMap;
  transformedDocs: DocumentationView[];
  filteredDocuments: DocumentationView[];
  sortedDocuments: DocumentationView[];
  sort: { active: string; direction: 'asc' | 'desc' | '' } = { active: '', direction: '' };
  filterToggled = false;
  displayedColumns: string[] = ['requirement', 'type', 'files', 'upload_date', 'uploaded_by'];
  filter = { financial: false, legal: false, operational: false, other: false };
  @Output() openDocumentEmitter = new EventEmitter<DocumentationView>();

  constructor() {}

  ngOnInit() {
    this.updateDocuments();
  }

  ngOnChanges() {
    this.updateDocuments();
  }

  updateDocuments() {
    this.transformedDocs = this.makeDocumentsArray();
    this.filteredDocuments = this.transformedDocs.slice();
    this.sortedDocuments = this.filteredDocuments.slice();
  }

  makeDocumentsArray() {
    const docArray = [];
    for (const category in this.documents) {
      if (this.documents[category]) {
        for (const id in this.documents[category]) {
          if (this.documents[category][id]) {
            const doc = this.documents[category][id] as any;
            const uploader = this.userMap.filter((user: User) => user.id === doc.uploaded_by)[0];
            if (uploader && uploader.firstName && uploader.lastName) {
              doc.user_uploaded = `${uploader.firstName} ${uploader.lastName}`;
            }
            doc.type = category;
            doc.id = id;
            doc.files =
              doc.file_names.length === 0
                ? 'Not Provided'
                : doc.file_names.length === 1
                ? doc.file_names[0]
                : 'Multiple Files';
            docArray.push(doc);
          }
        }
      }
    }
    return docArray;
  }

  handleFilterToggle(event) {
    event.stopPropagation();
    this.filterToggled = !this.filterToggled;
  }

  handleRowClick(row) {
    this.openDocumentEmitter.emit(row);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  filterDocuments() {
    if (this.filter.financial || this.filter.legal || this.filter.operational || this.filter.other) {
      this.filteredDocuments = this.transformedDocs.filter(doc => {
        if (this.filter.financial && doc.type.toLowerCase() === 'financial') {
          return true;
        }
        if (this.filter.legal && doc.type.toLowerCase() === 'legal') {
          return true;
        }
        if (this.filter.operational && doc.type.toLowerCase() === 'operational') {
          return true;
        }
        if (this.filter.other && doc.type.toLowerCase() === 'other') {
          return true;
        }
        return false;
      });
    } else {
      this.filteredDocuments = this.transformedDocs.slice();
    }
    this.sortDocuments(this.sort);
  }

  sortDocuments(sort) {
    if (sort) {
      this.sort = sort;
    }
    const data = this.filteredDocuments.slice();
    if (!this.sort.active || this.sort.direction === '') {
      this.sortedDocuments = data;
      return;
    }
    this.sortedDocuments = data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'requirement':
          return compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'reviewed':
          return compare(a.status, b.status, isAsc);
        case 'reviewed_by':
          return compare(a.reviewed_by, b.reviewed_by, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
