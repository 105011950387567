import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const groupsClient = (API: APIClass) => {
  return {
    getOrgGroups: async (orgId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/groups/${orgId}`, setIsLoading);
    },

    updateOrgGroup: async (orgId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/groups/${orgId}`, body, setIsLoading);
    },
  };
};
