import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { GcvUserInputContainer } from '../GcvUserInputContainer/GcvUserInputContainer';
import { BlackXIcon } from '../../icons/BlackXIcon';
import { ViewMoreIcon } from '../../icons/ViewMoreIcon';
import { GreenPlusIcon } from '../../icons/GreenPlusIcon';
import { DocumentMiniIcon } from '../../icons/DocumentMiniIcon';
import { $primary, $danger, $grey8 } from '../../util/styleVariables';

import {
  UploadPad,
  Text,
  LargeText,
  UploadedFile,
  RemoveFile,
  ViewFile,
  Input,
  AddFile,
  VerticalCenter,
} from './styles';
import { getAllAcceptedFileTypes } from '../../../../gcv-ui/src/lib/file-upload.util';

type FileType = 'pdf' | 'jpg' | 'jpeg' | 'xlsx' | 'png' | 'xml' | 'all';

interface Props {
  updateFileState: (filestate: { allFiles: any[]; newFiles: any[]; removedFiles: any[] }) => void;
  acceptedTypes: FileType[];
  files: any[];
  multiple?: boolean;
  maxHeight?: string;
  large?: boolean;
  viewFilesOnly?: boolean;
}

const typesMap = {
  pdf: { viewValue: 'PDF', value: 'application/pdf' },
  jpg: { viewValue: 'JPG', value: 'image/jpg' },
  jpeg: { viewValue: 'JPEG', value: 'image/jpeg' },
  xlsx: { viewValue: 'XLSX', value: '.xlsx' },
  png: { viewValue: 'PNG', value: '.png' },
  xml: { viewValue: 'XML', value: 'text/xml' },
  all: { viewValue: 'ALL', value: getAllAcceptedFileTypes() },
};

export const GcvInputFile = ({
  updateFileState,
  acceptedTypes,
  files,
  multiple,
  maxHeight,
  large,
  viewFilesOnly,
}: Props) => {
  const [rejected, setRejected] = useState(false);

  const onDrop = newFiles => {
    updateFileState({ allFiles: [...files, ...newFiles], newFiles: newFiles, removedFiles: [] });
    setRejected(false);
  };

  const formatTypes = view => {
    let typesString = '';
    acceptedTypes.forEach((type: string) => {
      typesString = typesString.length ? typesString + ', ' + typesMap[type][view] : typesMap[type][view];
    });
    return typesString;
  };

  const handleRejection = () => {
    setRejected(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: formatTypes('value'),
    onDropRejected: handleRejection,
  });

  const renderFiles = files => {
    return files.map((file, index) => {
      const handleViewFile = () => {
        const objectURL = window.URL.createObjectURL(file);
        window.open(objectURL, '_blank');
      };

      const onRemoveFile = () => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        updateFileState({ allFiles: newFiles, newFiles: [], removedFiles: [file] });
      };

      return (
        <UploadedFile key={'key' + file.name + index}>
          <ViewFile onClick={handleViewFile}>
            <DocumentMiniIcon onClick={handleViewFile} style={{ marginRight: '10px', cursor: 'pointer' }} />
            <VerticalCenter>
              <GcvUserInputContainer string={file.name} length={20} type="document" />
            </VerticalCenter>
            <VerticalCenter>
              <ViewMoreIcon style={{ marginLeft: '20px' }} />
            </VerticalCenter>
          </ViewFile>
          {viewFilesOnly ? null : (
            <RemoveFile>
              <BlackXIcon onClick={onRemoveFile} />
            </RemoveFile>
          )}
        </UploadedFile>
      );
    });
  };

  return (
    <>
      {!files.length && !viewFilesOnly ? (
        <UploadPad {...getRootProps()} style={large ? { padding: '2rem 0' } : {}}>
          <Input {...getInputProps()} />
          {isDragActive ? (
            <>
              <Text>Drop the file here ...</Text>
            </>
          ) : (
            <>
              <LargeText>
                Drag file here or <LargeText style={{ color: $primary }}>Browse</LargeText>
              </LargeText>
              {formatTypes('viewValue') !== 'ALL' ? (
                <Text style={{ color: rejected ? $danger : $grey8 }}>
                  {rejected ? '*' : ''} Accepted file type: {formatTypes('viewValue')}
                </Text>
              ) : (
                <></>
              )}
              <Text>Max file size: 20MB</Text>
            </>
          )}
        </UploadPad>
      ) : (
        <div>
          <div style={{ maxHeight: maxHeight ? maxHeight : '', overflowY: 'auto' }}>{renderFiles(files)}</div>
          {multiple && !viewFilesOnly ? (
            <AddFile {...getRootProps()}>
              <Input {...getInputProps()} />
              <VerticalCenter>
                <GreenPlusIcon style={{ marginRight: '10px' }} width={18} height={18} />
              </VerticalCenter>

              <VerticalCenter>
                <div>Upload Another File</div>
              </VerticalCenter>
            </AddFile>
          ) : null}
        </div>
      )}
    </>
  );
};
