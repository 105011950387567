import {
  BankAggregate,
  DispensaryAggregate,
  FincenDepositAggregate,
  FincenReportAggregate,
  SalesAggregate,
} from '../../typings/aggregates';
import { AggregatesActionTypes, SarReportPeriodAggregateLoaded } from './aggregates.actions';

const formatSarReportPeriods = (action: SarReportPeriodAggregateLoaded) => {
  const sarReportPeriods = {};
  action.payload.data.map((sarReportPeriod: any) => {
    sarReportPeriods[sarReportPeriod.dispensary_id] = sarReportPeriod;
  });
  return sarReportPeriods;
};

const formatAggregate = (action: any) => {
  const aggregate = {};
  aggregate[action.payload.id] = action.payload.data;
  return aggregate;
};

const formatAggregates = (action: any) => {
  const mergedAggregates = {};
  action.payload.aggregates.forEach(aggregate => {
    mergedAggregates[aggregate.id] = aggregate.data;
  });
  return mergedAggregates;
};

export interface AggregatesState {
  dispensaryAggregate:
    | {
        [key: string]: DispensaryAggregate | {};
      }
    | {};
  bankAggregate:
    | {
        [key: string]: BankAggregate | {};
      }
    | {};
  fincenDepositAggregate:
    | {
        [key: string]: FincenDepositAggregate | {};
      }
    | {};
  salesAggregate:
    | {
        [key: string]: SalesAggregate | {};
      }
    | {};
  fincenReportAggregate: FincenReportAggregate[] | [];
  sarReportPeriodAggregate: any[] | [];
}

export const initialAggregateState: AggregatesState = {
  dispensaryAggregate: {},
  bankAggregate: {},
  fincenDepositAggregate: {},
  salesAggregate: {},
  fincenReportAggregate: [],
  sarReportPeriodAggregate: [],
};

export function aggregatesReducer(state = initialAggregateState, action): AggregatesState {
  switch (action.type) {
    case AggregatesActionTypes.BankAggregateLoaded:
      return {
        ...state,
        bankAggregate: {
          ...state.bankAggregate,
          ...formatAggregate(action),
        },
      };
    case AggregatesActionTypes.DispensaryAggregateLoaded:
      return {
        ...state,
        dispensaryAggregate: {
          ...state.dispensaryAggregate,
          ...formatAggregate(action),
        },
      };
    case AggregatesActionTypes.DispensaryAggregatesLoaded:
      return {
        ...state,
        dispensaryAggregate: {
          ...state.dispensaryAggregate,
          ...formatAggregates(action),
        },
      };
    case AggregatesActionTypes.FincenDepositAggregateLoaded:
      return {
        ...state,
        fincenDepositAggregate: {
          ...state.fincenDepositAggregate,
          ...formatAggregate(action),
        },
      };
    case AggregatesActionTypes.FincenReportAggregateLoaded:
      return {
        ...state,
        fincenReportAggregate: action.payload,
      };
    case AggregatesActionTypes.SalesAggregateLoaded:
      return {
        ...state,
        salesAggregate: {
          ...state.salesAggregate,
          ...formatAggregate(action),
        },
      };
    case AggregatesActionTypes.SalesAggregatesLoaded:
      return {
        ...state,
        salesAggregate: {
          ...state.salesAggregate,
          ...formatAggregates(action),
        },
      };
    case AggregatesActionTypes.SarReportPeriodAggregateLoaded:
      return {
        ...state,
        sarReportPeriodAggregate: { ...state.sarReportPeriodAggregate, ...formatSarReportPeriods(action) },
      };
    case AggregatesActionTypes.ResetAggregates:
      return initialAggregateState;
    default:
      return state;
  }
}
