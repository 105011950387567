import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from '../../services/util-service/utils.service';
import { DEFAULT_SELECTED_RANGE_OUTPUT, totalSalesMap } from '../../constants';

@Component({
  selector: 'gcv-timefilter',
  templateUrl: './timefilter.component.html',
  styleUrls: ['./timefilter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimefilterComponent implements OnInit, OnChanges {
  timeSpanChange$ = new BehaviorSubject(null);
  @Input()
  items: any;
  @Input()
  selectedRange: any = DEFAULT_SELECTED_RANGE_OUTPUT;
  @Output()
  startDateControl: any;
  @Output()
  endDateControl: any;
  @Output()
  filterChanged = new EventEmitter();
  public totalSalesMap = totalSalesMap;

  constructor(private utils: UtilsService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.timeSpanChange$.next(this.selectedRange);
    this.filterChanged.next(this.selectedRange);
  }

  dateTimeRangeChange(dateRange: string) {
    if (this.totalSalesMap[this.selectedRange] !== dateRange.toLowerCase()) {
      this.selectedRange = dateRange;
      this.timeSpanChange$.next(this.selectedRange);
      this.filterChanged.next(this.selectedRange);
    }
  }
}
