import React from 'react';

import { GcvButton, GcvLoading, GcvModal } from 'libs/react-ui/src/lib';
import { createStyles, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { api } from 'libs/react-ui/src/api';
import { CoreTransactionUploadAction } from '@gcv/shared';

const useStyles = makeStyles(() =>
  createStyles({
    radioBtn: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    checked: {
      color: '#00bc66',
    },
  })
);

interface Props {
  toggleModal: () => void;
  modalOpen: boolean;
  numberOfDuplicates: number;
  bankId: string;
  s3key: string;
  s3bucket: string;
  setSnackBarMessageAndOpen: (message: string, duration: number) => void;
}

export const DuplicateCoreTransactionsModal: React.FC<Props> = props => {
  const apiClient = api();
  const classes = useStyles();

  const [value, setValue] = React.useState('replace');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <GcvModal
      toggleModal={props.toggleModal}
      modalOpen={props.modalOpen}
      backButton={
        <GcvButton tertiary disabled={isLoading} onClick={props.toggleModal}>
          Cancel
        </GcvButton>
      }
      continueButton={
        <GcvButton
          primary
          isLoading={isLoading}
          onClick={() => {
            if (isLoading) {
              return;
            }

            setIsLoading(true);
            apiClient.banks
              .completeCoreTransactionUpload(
                props.bankId,
                props.s3key,
                props.s3bucket,
                value as CoreTransactionUploadAction,
                () => {}
              )
              .then(() => {
                props.setSnackBarMessageAndOpen('Your import is complete', 5000);
              })
              .catch(() => {
                props.setSnackBarMessageAndOpen('An error occurred. Please try again.', 60000);
              })
              .finally(() => {
                props.toggleModal();
                setIsLoading(false);
              });
          }}
        >
          Continue
        </GcvButton>
      }
      title={`${props.numberOfDuplicates} of the imported transactions already exist`}
    >
      <div style={{ textAlign: 'center', padding: '2rem 1rem' }}>
        Do you want to replace them with the data from this import?
      </div>

      <FormControl component="fieldset">
        <RadioGroup name="duplicateTransactionOptions" value={value} onChange={handleChange}>
          <FormControlLabel
            value="replace"
            control={
              <Radio disableRipple classes={{ root: classes.radioBtn, checked: classes.checked }} color="default" />
            }
            label="Replace these transactions (recommended)"
          />
          <FormControlLabel
            value="keep"
            control={
              <Radio disableRipple classes={{ root: classes.radioBtn, checked: classes.checked }} color="default" />
            }
            label="Keep the old transactions and ignore these new ones"
          />
        </RadioGroup>
      </FormControl>

      <div style={{ textAlign: 'center', padding: '2rem 1rem' }}>
        Your selection will not affect the import of the {props.numberOfDuplicates} new transactions if you continue.
      </div>
    </GcvModal>
  );
};
