import React, { useContext } from 'react';
import parse from 'html-react-parser';

import {
  DispensaryAccountReviewWithData,
  DispensaryPeriodSummaryCoreTransactions,
  PeerGroupPeriodSummaryCoreTransactions,
} from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { BulletConfig } from './chart-options';
import { createStyles, makeStyles, Popover, Theme } from '@material-ui/core';
import { tooltip } from './Tooltip';
import { formatMoney } from 'libs/react-ui/src/util';
import { AccountMonitoringContext } from '../../account-monitoring-context';

interface Props {
  review: DispensaryAccountReviewWithData;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(0.25),
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 10%)',
      border: '1px solid #E0E5F0',
      borderRadius: 0,
    },
  })
);

export const TransactionSummaryDepositsDollarAvgBulletChart: React.FC<Props> = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const context = useContext(AccountMonitoringContext);

  return (
    <>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {parse(
          tooltip('Deposits Average', [
            {
              label: 'This Period',
              value: formatMoney(
                props.review.data.aggregated_core_transaction_data.current_period.number_of_deposits
                  ? Math.round(
                      props.review.data.aggregated_core_transaction_data.current_period.deposit_amount /
                        props.review.data.aggregated_core_transaction_data.current_period.number_of_deposits
                    )
                  : 0
              ),
            },
            {
              label: 'Previous Period',
              value: formatMoney(
                props.review.data.aggregated_core_transaction_data.previous_period.number_of_deposits
                  ? Math.round(
                      props.review.data.aggregated_core_transaction_data.previous_period.deposit_amount /
                        props.review.data.aggregated_core_transaction_data.previous_period.number_of_deposits
                    )
                  : 0
              ),
            },
            {
              label: 'Previous Year',
              value: formatMoney(
                props.review.data.aggregated_core_transaction_data.previous_year.number_of_deposits
                  ? Math.round(
                      props.review.data.aggregated_core_transaction_data.previous_year.deposit_amount /
                        props.review.data.aggregated_core_transaction_data.previous_year.number_of_deposits
                    )
                  : 0
              ),
            },
            {
              label: 'Peer Group Avg',
              value: formatMoney(
                props.review.data.aggregated_core_transaction_data.peer_groups[context.peerGroupId]
                  ?.avg_per_deposit_amount
              ),
            },
          ])
        )}
      </Popover>

      <div onMouseEnter={e => handlePopoverOpen(e)} onMouseLeave={handlePopoverClose}>
        <GcvChart type="bullet" height={65} config={chartDataAdapter(props.review, context.peerGroupId)} />
      </div>
    </>
  );
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData, peerGroupdId: string) => {
  return dataAdapter(review.data.aggregated_core_transaction_data, peerGroupdId);
};

export const dataAdapter = (
  data: {
    current_period: DispensaryPeriodSummaryCoreTransactions;
    previous_period: DispensaryPeriodSummaryCoreTransactions;
    previous_year: DispensaryPeriodSummaryCoreTransactions;
    peer_groups: { [id: string]: PeerGroupPeriodSummaryCoreTransactions };
  },
  peerGroupId: string
): BulletConfig => {
  const depositsPrevPeriod = data.previous_period.number_of_deposits
    ? data.previous_period.deposit_amount / data.previous_period.number_of_deposits / 100
    : 0;
  const depositsCurrPeriod = data.current_period.number_of_deposits
    ? data.current_period.deposit_amount / data.current_period.number_of_deposits / 100
    : 0;
  const prevYearAvg = data.previous_year.number_of_deposits
    ? data.previous_year.deposit_amount / data.previous_year.number_of_deposits / 100
    : 0;
  const peerGroup = data.peer_groups[peerGroupId];
  const peerGroupAvg = peerGroup?.avg_per_deposit_amount / 100;

  const max = Math.max(depositsPrevPeriod, depositsCurrPeriod, prevYearAvg, peerGroupAvg);

  return {
    colorRange: {
      color: [
        {
          minValue: 0,
          // deposits previous period
          maxValue: depositsPrevPeriod,
          code: '#1270FC',
          alpha: 60,
        },
        {
          minValue: 0,
          // deposits this period
          maxValue: depositsCurrPeriod,
          code: '#00BC66',
          alpha: 60,
        },
        {
          minValue: 0,
          // fill space to max
          maxValue: max,
          code: '#000000',
          alpha: 15,
        },
      ],
    },
    // previous year avg
    value: prevYearAvg,
    // peer group avg
    target: peerGroupAvg,
  };
};
