export interface Deposit {
  id: string;
  created: string;
  account: string;
  amount: string;
  methodOfTransport: string;
  plannedArrivalDate: string;
  arrived_date: string;
  status: string;
  dispensary_id: string;
  reconciling: Boolean;
}

export enum FilterOptions {
  SearchAccounts = 'SearchAccounts',
  MethodOfTransport = 'MethodOfTransport',
  Status = 'Status',
  DateTimeFilter = 'DateTimeFilter',
}

export interface FilterRequest<T> {
  data: T[];
  searchTerms: { [key in FilterOptions]?: { value: any; [key: string]: any } };
}

export const useFilter = (filterRequest, setFilterRequest, filterData) => (
  query: { value: string | string[]; [key: string]: any },
  filterType: FilterOptions
) => {
  const filterSearchTerms = {};
  filterSearchTerms[filterType] = query;

  setFilterRequest({
    data: filterData,
    searchTerms: {
      ...filterRequest.searchTerms,
      ...filterSearchTerms,
    },
  });
};

abstract class Filter<T> {
  protected next: Filter<T>;

  constructor(protected completeRequest: (requestData: {}) => void) {}

  public Next(next: Filter<T>): void {
    this.next = next;
  }

  public abstract Handle(request: FilterRequest<T>): void;
}

export class SearchAccountsFilter extends Filter<Deposit> {
  constructor(completeRequest: (requestData: {}) => void) {
    super(completeRequest);
  }

  public Handle(request: FilterRequest<Deposit>): void {
    const filterValue = request.searchTerms[FilterOptions.SearchAccounts];

    if (filterValue) {
      if (filterValue.value.length) {
        const data = request.data.filter(d => filterValue.value.includes(d.dispensary_id));
        request.data = data;
      }
    }

    if (this.next) {
      this.next.Handle(request);
    } else {
      this.completeRequest(request.data);
    }
  }
}

export class MethodOfTransportFilter extends Filter<Deposit> {
  constructor(completeRequest: (requestData: {}) => void) {
    super(completeRequest);
  }

  public Handle(request: FilterRequest<Deposit>): void {
    const filterValue = request.searchTerms[FilterOptions.MethodOfTransport];
    let data: Deposit[];

    if (filterValue) {
      if (filterValue.value.toLowerCase() === 'all') {
        data = request.data;
      } else {
        data = request.data.filter(d => d.methodOfTransport.toLowerCase() === filterValue.value.toLowerCase());
      }

      request.data = data;
    }

    if (this.next) {
      this.next.Handle(request);
    } else {
      this.completeRequest(request.data);
    }
  }
}

export class StatusFilter extends Filter<Deposit> {
  constructor(completeRequest: (requestData: {}) => void) {
    super(completeRequest);
  }

  public Handle(request: FilterRequest<Deposit>): void {
    const filterValue = request.searchTerms[FilterOptions.Status];
    let data: Deposit[];

    if (filterValue) {
      if (filterValue.value.toLowerCase() === 'all') {
        data = request.data;
      } else {
        const value =
          filterValue.value.toLowerCase() === 'reconciled'
            ? 'accepted'
            : filterValue.value.toLowerCase() === 'pending'
            ? 'pending'
            : 'under_review';
        data = request.data.filter(d => d.status.toLowerCase() === value);
      }

      request.data = data;
    }

    if (this.next) {
      this.next.Handle(request);
    } else {
      this.completeRequest(request.data);
    }
  }
}
