import { ReactWrapperComponent, WrapperProps } from '../../util';
import { ComplianceScoreBadge } from './ComplianceScoreBadge';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-compliance-score-badge',
  template: '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props"></ app-react-wrapper>',
})
export class ComplianceScoreBadgeComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = ComplianceScoreBadge;
}
