import React from 'react';
import { GcvButton } from '../../../lib';
import { ExportActivityResponseModal } from './ExportActivityResponseModal';

interface Props {
  exportActivitySuccess: boolean;
  exportActivityFailure: boolean;
  responseModalActive: boolean;
  setResponseModalActive: (active: boolean) => void;
}

export const SuccessFailureModal: React.FC<Props> = props => {
  const { exportActivitySuccess, exportActivityFailure, responseModalActive, setResponseModalActive } = props;

  const toggleResponseModal = () => {
    setResponseModalActive(!responseModalActive);
  };

  return (
    <>
      <div id="modal-root"></div>
      {exportActivitySuccess ? (
        <ExportActivityResponseModal
          headerText="Your file is on its way"
          bodyText="Your file is being prepared now, When it’s ready, a link to your file will appear in your notifications. You’ll also receive a confirmation email."
          type="success"
          active={responseModalActive}
          toggleModal={toggleResponseModal}
          buttonComponent={
            <GcvButton primary onClick={toggleResponseModal}>
              Great!
            </GcvButton>
          }
        ></ExportActivityResponseModal>
      ) : null}

      {exportActivityFailure ? (
        <ExportActivityResponseModal
          headerText="Oops, something went wrong"
          bodyText="Please try the export again. For help, email us at support@greencheckverified.com."
          type="success"
          active={responseModalActive}
          toggleModal={toggleResponseModal}
          buttonComponent={
            <GcvButton secondary onClick={toggleResponseModal}>
              Close
            </GcvButton>
          }
        ></ExportActivityResponseModal>
      ) : null}
    </>
  );
};
