import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { APIClass } from 'aws-amplify';

// Base API CRUD operations
export const apiClient = (API: APIClass) => {
  return {
    // Create
    post: async (endpoint: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      setIsLoading(true);

      return await API.post(GREEN_CHECK_SERVICES_AMPLIFY_NAME, endpoint, { body }).then(response => {
        setIsLoading(false);

        return response;
      });
    },
    // Read
    get: async (endpoint: string, setIsLoading: (isLoading: boolean) => void) => {
      setIsLoading(true);

      return await API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, endpoint, {}).then(response => {
        setIsLoading(false);

        return response;
      });
    },
    // Update
    patch: async (endpoint: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      setIsLoading(true);

      return await API.patch(GREEN_CHECK_SERVICES_AMPLIFY_NAME, endpoint, { body }).then(response => {
        setIsLoading(false);

        return response;
      });
    },
    put: async (endpoint: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      setIsLoading(true);

      return await API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, endpoint, { body }).then(response => {
        setIsLoading(false);

        return response;
      });
    },
    // Delete
    delete: async (endpoint: string, setIsLoading: (isLoading: boolean) => void) => {
      setIsLoading(true);

      return await API.del(GREEN_CHECK_SERVICES_AMPLIFY_NAME, endpoint, {}).then(response => {
        setIsLoading(false);

        return response;
      });
    },
  };
};
