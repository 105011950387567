import React, { useState, useEffect } from 'react';
import { GcvButton } from '../../../lib/GcvButton/GcvButton';
import { ExportActivityModal } from './ExportActivityModal';
import { ExportActivityResponseModal } from './ExportActivityResponseModal';
import { Dispensary } from '@gcv/shared';
import { GcvContent } from 'libs/react-ui/src/lib';
import { api } from 'libs/react-ui/src/api';

interface Props {
  dispensary: Dispensary;
}

export const ActivityReporting = (props: Props) => {
  const [type, setType] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [responseModalActive, setResponseModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiClient = api();

  const toggleModal = () => {
    setModalActive(!modalActive);
  };

  const closeResponseModal = () => {
    setResponseModalActive(false);
    setType(null);
  };

  const handleSubmit = payload => {
    const { activityType, ...exportRequest } = payload;
    const reportData = { ...exportRequest, dispensaryId: props.dispensary.id };
    const newPayload = { reportData };

    if (activityType === 'sales') {
      apiClient.dispensaries
        .getDispensarySalesReport(props.dispensary.id, newPayload, setLoading)
        .then(() => {
          setModalActive(false);
          setType(true);
        })
        .catch(() => {
          setModalActive(false);
          setType(false);
        });
    } else if (activityType === 'deposits') {
      apiClient.dispensaries
        .getDispensaryDepositsReport(props.dispensary.id, newPayload, setLoading)
        .then(() => {
          setModalActive(false);
          setType(true);
        })
        .catch(() => {
          setModalActive(false);
          setType(false);
        });
    } else if (activityType === 'daily-summaries') {
      apiClient.dispensaries
        .getDispensaryDailySummariesReport(props.dispensary.id, newPayload, setLoading)
        .then(() => {
          setModalActive(false);
          setType(true);
        })
        .catch(() => {
          setModalActive(false);
          setType(false);
        });
    }
  };

  useEffect(() => {
    if (type !== null) {
      setResponseModalActive(true);
    }
  }, [type]);

  return (
    <>
      <div id="modal-root"></div>
      <GcvContent type="a" onClick={toggleModal} content="Export Activity" />
      <ExportActivityModal
        active={modalActive}
        toggleModal={toggleModal}
        onSubmit={handleSubmit}
        timezone={props.dispensary.iana_timezone}
        exportInProgress={loading}
      />

      {responseModalActive && (
        <ExportActivityResponseModal
          type={type}
          active={responseModalActive}
          toggleModal={closeResponseModal}
          buttonComponent={
            <GcvButton secondary onClick={closeResponseModal}>
              {type ? 'Great!' : 'Close'}
            </GcvButton>
          }
        />
      )}
    </>
  );
};
