// fx
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

// app
import { DailyCoreTransactionsSummary, Dispensary, DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvInputSelectNew } from 'libs/react-ui/src/lib';
import {
  doesReviewPeriodSpanMultipleMonths,
  formatMoney,
  formatPercentage,
  getMonthsInTimeRange,
} from 'libs/react-ui/src/util';

import { Card } from './Card';
import { Container } from './Container';
import { GcvContent } from 'libs/react-ui/src/lib/GcvContent/GcvContent';

import { TransactionTimelineBubbleChart } from './charts/TransactionTimelineBubbleChart';

interface Props {
  review: DispensaryAccountReviewWithData;
}

const ReviewTransactions: React.FC<Props> = props => {
  const { handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });
  const [chartDataMonth, setChartDataMonth] = useState(null);

  useEffect(() => {
    setChartDataMonth(getMonthsInTimeRange(props.review, props.review.dispensary.iana_timezone)[0].value);
  }, [props.review]);

  const setRangedChartData = e => {
    const month = e.value;

    setChartDataMonth(month);
  };

  const toInt = value => {
    return ~~value;
  };

  function capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatName(unFormattedName: string) {
    const splitName = unFormattedName.split('_');

    const name: string[] = [];

    for (let x = 0; x < splitName.length; x++) {
      name.push(capitalize(splitName[x]));
    }

    return name.join(' ');
  }

  const bubbleChartTypes = () => {
    const types = [];

    Object.keys(props.review.data.aggregated_core_transaction_data.current_period.deposit).forEach(d => {
      if (
        !types.includes(d) &&
        (props.review.data.aggregated_core_transaction_data.current_period.deposit[d].a > 0 ||
          props.review.data.aggregated_core_transaction_data.current_period.deposit[d].c > 0)
      ) {
        types.push(d);
      }
    });

    Object.keys(props.review.data.aggregated_core_transaction_data.current_period.withdrawal).forEach(w => {
      if (
        !types.includes(w) &&
        (props.review.data.aggregated_core_transaction_data.current_period.withdrawal[w].a > 0 ||
          props.review.data.aggregated_core_transaction_data.current_period.withdrawal[w].c > 0)
      ) {
        types.push(w);
      }
    });

    return types;
  };

  return (
    <Container>
      <Container.Item>
        <Card>
          <Card.Header
            title="Transaction Timeline"
            actions={[
              doesReviewPeriodSpanMultipleMonths(props.review, props.review.dispensary.iana_timezone) ? (
                <GcvInputSelectNew
                  label="Month"
                  options={getMonthsInTimeRange(props.review, props.review.dispensary.iana_timezone)}
                  onChange={setRangedChartData}
                  dropWidth={'150px'}
                  labelWidth={'100px'}
                  dropCharLength={20}
                />
              ) : (
                undefined
              ),
            ]}
          />

          <Card.Content>
            {bubbleChartTypes().map(t => {
              return (
                <Container key={t} gutterTop>
                  <Container.Item vCenter size={[2]}>
                    <GcvContent type="h3" content={formatName(t)} />
                  </Container.Item>

                  <Container.Item size={[10]}>
                    {chartDataMonth && (
                      <TransactionTimelineBubbleChart review={props.review} month={chartDataMonth} type={t} />
                    )}
                  </Container.Item>

                  <Container.Item>
                    <hr />
                  </Container.Item>
                </Container>
              );
            })}

            <Container hCenter gutterTop>
              <Container.Item size={[6]}>
                <Container hCenter width={200}>
                  <Container.Item lAlign dense size={[6]}>
                    <GcvContent type="l2" content="Less Credits" />
                  </Container.Item>
                  <Container.Item rAlign dense size={[6]}>
                    <GcvContent type="l2" content="More Credits" />
                  </Container.Item>

                  <Container.Item hCenter>
                    <svg width="116" height="16" viewBox="0 0 116 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="94" width="21.5873" height="16" rx="2" fill="#00A77E" />
                      <rect x="70.7622" width="21.5873" height="16" rx="2" fill="#00BC66" />
                      <rect x="47.1748" width="21.5873" height="16" rx="2" fill="#44D359" />
                      <rect x="23.5874" width="21.5873" height="16" rx="2" fill="#96E688" />
                      <rect width="21.5873" height="16" rx="2" fill="#C1EEAA" />
                    </svg>
                  </Container.Item>
                </Container>
              </Container.Item>

              <Container.Item size={[6]}>
                <Container hCenter width={200}>
                  <Container.Item lAlign dense size={[6]}>
                    <GcvContent type="l2" content="Less Debits" />
                  </Container.Item>
                  <Container.Item rAlign dense size={[6]}>
                    <GcvContent type="l2" content="More Debits" />
                  </Container.Item>

                  <Container.Item hCenter>
                    <svg width="116" height="16" viewBox="0 0 116 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="94" width="21.5873" height="16" rx="2" fill="#0066FF" />
                      <rect x="70.7622" width="21.5873" height="16" rx="2" fill="#4791FF" />
                      <rect x="47.1748" width="21.5873" height="16" rx="2" fill="#8BB7F9" />
                      <rect x="23.5874" width="21.5873" height="16" rx="2" fill="#B0D0FF" />
                      <rect width="21.5873" height="16" rx="2" fill="#CFE2FE" />
                    </svg>
                  </Container.Item>
                </Container>
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>
      <Container.Item>
        <Card>
          <Card.Header title="Transaction Summary" />
          <Card.Content>
            <div
              style={{
                width: '100%',
                border: '1px solid rgb(235, 238, 247)',
                borderRadius: '6px',
                borderSpacing: '0px',
              }}
            >
              <table
                style={{
                  borderCollapse: 'collapse',
                  width: '100%',
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#F2F4FA',
                        fontSize: '12px',
                        color: 'rgba(0,0,0,0.87)',
                        padding: '0.5rem',
                      }}
                    ></th>
                    <th
                      style={{
                        backgroundColor: '#F2F4FA',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.87)',
                        padding: '0.5rem',
                      }}
                    >
                      Total
                    </th>
                    <th
                      style={{
                        backgroundColor: '#F2F4FA',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.87)',
                        padding: '0.5rem',
                      }}
                    >
                      Transactions
                    </th>
                    <th
                      style={{
                        backgroundColor: '#F2F4FA',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.87)',
                        padding: '0.5rem',
                      }}
                    >
                      Average Transaction Size
                    </th>
                    <th
                      style={{
                        backgroundColor: '#F2F4FA',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.87)',
                        padding: '0.5rem',
                      }}
                    >
                      % of Total ($)
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {/* Deposits */}
                  {Object.keys(props.review.data.aggregated_core_transaction_data.current_period.deposit).map(d => {
                    const data = props.review.data.aggregated_core_transaction_data.current_period.deposit[d];

                    if (!data.a && !data.c) {
                      return;
                    }

                    return (
                      <tr
                        key={d}
                        style={{
                          borderBottom: '1px solid #DBE0EB',
                          fontSize: '13px',
                        }}
                      >
                        <td style={{ padding: '1rem' }}>{formatName(d) + ' In'}</td>
                        <td style={{ padding: '1rem', textAlign: 'center' }}>{formatMoney(data.a)}</td>
                        <td style={{ padding: '1rem', textAlign: 'center' }}>{data.c}</td>
                        <td style={{ padding: '1rem', textAlign: 'center' }}>{formatMoney(toInt(data.a / data.c))}</td>
                        <td style={{ padding: '1rem', textAlign: 'center' }}>
                          {formatPercentage(
                            data.a,
                            props.review.data.aggregated_core_transaction_data.current_period.deposit_amount
                          )}
                        </td>
                      </tr>
                    );
                  })}

                  {/* Deposit summary */}
                  <tr
                    style={{
                      borderBottom: '2px solid #DBE0EB',
                      fontSize: '13px',
                    }}
                  >
                    <td style={{ padding: '1rem' }}>
                      <b>Deposits</b>
                    </td>
                    <td style={{ padding: '1rem', textAlign: 'center' }}>
                      <b>
                        {formatMoney(props.review.data.aggregated_core_transaction_data.current_period.deposit_amount)}
                      </b>
                    </td>
                    <td style={{ padding: '1rem', textAlign: 'center' }}>
                      <b>{props.review.data.aggregated_core_transaction_data.current_period.number_of_deposits}</b>
                    </td>
                    <td style={{ padding: '1rem', textAlign: 'center' }}>
                      <b>
                        {formatMoney(
                          toInt(
                            props.review.data.aggregated_core_transaction_data.current_period.deposit_amount /
                              props.review.data.aggregated_core_transaction_data.current_period.number_of_deposits
                          )
                        )}
                      </b>
                    </td>
                    <td style={{ padding: '1rem', textAlign: 'center' }}>
                      <b>100%</b>
                    </td>
                  </tr>

                  {/* Withdrawals */}
                  {Object.keys(props.review.data.aggregated_core_transaction_data.current_period.withdrawal).map(w => {
                    const data = props.review.data.aggregated_core_transaction_data.current_period.withdrawal[w];

                    if (!data.a && !data.c) {
                      return;
                    }

                    return (
                      <tr
                        key={w}
                        style={{
                          borderBottom: '1px solid #DBE0EB',
                          fontSize: '13px',
                        }}
                      >
                        <td style={{ padding: '1rem' }}>{formatName(w) + ' Out'}</td>
                        <td style={{ padding: '1rem', textAlign: 'center' }}>{formatMoney(data.a)}</td>
                        <td style={{ padding: '1rem', textAlign: 'center' }}>{data.c}</td>
                        <td style={{ padding: '1rem', textAlign: 'center' }}>{formatMoney(toInt(data.a / data.c))}</td>
                        <td style={{ padding: '1rem', textAlign: 'center' }}>
                          {formatPercentage(
                            data.a,
                            props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount
                          )}
                        </td>
                      </tr>
                    );
                  })}

                  {/* Withdrawal summary */}
                  <tr
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    <td style={{ padding: '1rem' }}>
                      <b>Withdrawals</b>
                    </td>
                    <td style={{ padding: '1rem', textAlign: 'center' }}>
                      <b>
                        {formatMoney(
                          props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount
                        )}
                      </b>
                    </td>
                    <td style={{ padding: '1rem', textAlign: 'center' }}>
                      <b>{props.review.data.aggregated_core_transaction_data.current_period.number_of_withdrawals}</b>
                    </td>
                    <td style={{ padding: '1rem', textAlign: 'center' }}>
                      <b>
                        {formatMoney(
                          toInt(
                            props.review.data.aggregated_core_transaction_data.current_period.withdrawal_amount /
                              props.review.data.aggregated_core_transaction_data.current_period.number_of_withdrawals
                          )
                        )}
                      </b>
                    </td>
                    <td style={{ padding: '1rem', textAlign: 'center' }}>
                      <b>100%</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card.Content>
        </Card>
      </Container.Item>
    </Container>
  );
};

export default ReviewTransactions;
