import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import * as base64arraybuffer from 'base64-arraybuffer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IdType, idtypes } from '@user-interface/gcv-ui';
import { S3FileManagementService } from '../../services/s3FileManagement.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-identification-document-card',
  templateUrl: './identification-document-card.component.html',
  styleUrls: ['./identification-document-card.component.scss'],
})
export class IdentificationDocumentCardComponent implements OnInit {
  @ViewChild('form', { static: true }) form: ElementRef;
  @ViewChild('form1', { static: true }) form1: ElementRef;

  public idTypes = idtypes;
  identificationForm: any;
  isUploading = true;
  uploadedIdFront = null;
  uploadedIdBack = null;
  idFrontChanged = false;
  idBackChanged = false;

  constructor(
    private matDialogRef: MatDialogRef<IdentificationDocumentCardComponent>,
    private cdr: ChangeDetectorRef,
    private s3File: S3FileManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.identificationForm = data.identificationForm;
    this.isUploading = !data.isNotUploading;
    const { idBack, idFront } = this.identificationForm.getRawValue();

    if (idBack.document_id && idFront.document_id) {
      this.uploadedIdFront = idFront;
      this.uploadedIdBack = idBack;
    }
  }

  ngOnInit() {}

  cancel() {
    this.matDialogRef.close();
  }

  fileExtensionAllowed(filename) {
    filename = filename.toLowerCase();
    return !!(
      filename.includes('.jpg') ||
      filename.includes('.png') ||
      filename.includes('.jpeg') ||
      filename.includes('.pdf')
    );
  }

  onFileEvent(event, control) {
    const file = event.target.files[0];
    if (file && file.name && this.fileExtensionAllowed(file.name)) {
      this.identificationForm.controls[control].controls['filename'].setValue(file.name);
      const dataReader = new FileReader();
      dataReader.onload = e => {
        const arrayBuffer = e.target['result'];
        const base64 = base64arraybuffer.encode(<ArrayBuffer>arrayBuffer);
        this.identificationForm.controls[control].controls['data'].setValue(base64);
      };
      dataReader.readAsArrayBuffer(file);
      control === 'idFront' ? (this.idFrontChanged = true) : (this.idBackChanged = true);

      const urlReader = new FileReader();
      urlReader.onload = e => {
        if (control === 'idFront') {
          this.uploadedIdFront = {
            s3Key: null,
            url: window.URL.createObjectURL(file),
            filename: file.name,
          };
        } else {
          this.uploadedIdBack = {
            s3Key: null,
            url: window.URL.createObjectURL(file),
            filename: file.name,
          };
        }
        this.cdr.markForCheck();
      };
      urlReader.readAsDataURL(file);
    }
  }

  async viewDocument(control) {
    try {
      if (control.url) {
        window.open(control.url, '_blank');
      } else {
        const blob = await this.s3File.viewUserDocument(this.data.userId, control.document_id);
        this.s3File.openDocumentInNewTab(blob);
      }
    } catch (e) {
      console.log(e);
    }
  }

  removeDocument(doc) {
    const idForm: any = this.identificationForm.controls;
    if (doc === 'idFront') {
      idForm.idFront.controls.filename.setValue('');
      idForm.idFront.controls.data.setValue('');
      this.uploadedIdFront = null;
    } else {
      idForm.idBack.controls.filename.setValue('');
      idForm.idBack.controls.data.setValue('');
      this.uploadedIdBack = null;
    }
  }

  finish() {
    const { idBack, idFront } = this.identificationForm.getRawValue();
    this.matDialogRef.close({
      data: { idBack, idFront, idFrontChanged: this.idFrontChanged, idBackChanged: this.idBackChanged },
    });
  }
}
