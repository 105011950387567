import styled from 'styled-components';

interface Props {
  color?: string;
}

export const Container = styled.div<Props>`
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 124px;
  height: 35px;
  color: ${props => props.color};
  /* padding: 5px 0px; */

  span {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.21875px;
    margin-left: 10%;
  }
`;

export const Background = styled.div<Props>`
  position: absolute;
  left: 0;
  top: 0;
  background: ${props => props.color};
  opacity: 0.2;
  width: 100%;
  height: 100%;
  /* padding: 5px 3px; */
  border-radius: 18px;
`;
