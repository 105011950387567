import React, { useContext } from 'react';

import { DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { RadarConfig } from './chart-options';
import { AccountMonitoringContext } from '../../account-monitoring-context';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const TransactionAnalysisPeerGroupRadarChart: React.FC<Props> = props => {
  const context = useContext(AccountMonitoringContext);
  const peerGroupId = context.peerGroupId;

  if (!peerGroupId) {
    return <></>;
  }

  return <GcvChart type="radar" config={chartDataAdapter(props.review, peerGroupId)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData, peerGroupId: string) => {
  const coreData = review.data.aggregated_core_transaction_data;

  return transactionDataRadarAdapter({
    current_period: {
      ...Object.keys(coreData.current_period.deposit).reduce((acc, curr) => {
        const nextValue = coreData.current_period.deposit[curr].a / 100;

        if (!nextValue) {
          return acc;
        }

        return { ...acc, [curr + '_in']: nextValue };
      }, {}),
      ...Object.keys(coreData.current_period.withdrawal).reduce((acc, curr) => {
        const nextValue = coreData.current_period.withdrawal[curr].a / 100;

        if (!nextValue) {
          return acc;
        }

        return { ...acc, [curr + '_out']: nextValue };
      }, {}),
    },
    peer_group: {
      ...Object.keys(coreData.peer_groups[peerGroupId].avg_deposits).reduce((acc, curr) => {
        const nextValue = coreData.peer_groups[peerGroupId].avg_deposits[curr] / 100;

        if (!nextValue) {
          return acc;
        }

        return { ...acc, [curr + '_in']: nextValue };
      }, {}),
      ...Object.keys(coreData.peer_groups[peerGroupId].avg_withdrawals).reduce((acc, curr) => {
        const nextValue = coreData.peer_groups[peerGroupId].avg_withdrawals[curr] / 100;

        if (!nextValue) {
          return acc;
        }

        return { ...acc, [curr + '_out']: nextValue };
      }, {}),
    },
  });
};

type TransactionDataType = {
  current_period: {
    [key: string]: number;
  };

  peer_group: {
    [key: string]: number;
  };
};

function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatCategoryName(data: { [key: string]: number }) {
  const category = [];

  Object.keys(data).map(key => {
    if (!data[key]) {
      return;
    }

    const splitKey = key.split('_');

    const name: string[] = [];

    for (let x = 0; x < splitKey.length; x++) {
      name.push(capitalize(splitKey[x]));
    }

    category.push(name.join(' '));
  });

  return category.map(c => {
    return { label: c };
  });
}

function getCategories(data: TransactionDataType) {
  const currentPeriod = formatCategoryName(data.current_period);
  const peerGroup = formatCategoryName(data.peer_group);

  const categories: { label: string }[] = [];

  currentPeriod.forEach(cp => {
    if (!categories.find(c => c.label === cp.label)) {
      categories.push(cp);
    }
  });

  peerGroup.forEach(pg => {
    if (!categories.find(c => c.label === pg.label)) {
      categories.push(pg);
    }
  });

  return categories;
}

export const transactionDataRadarAdapter = (data: TransactionDataType): RadarConfig => {
  const dataSet = [];
  if (Object.values(data.current_period).length) {
    dataSet.push({
      seriesname: 'This Period',
      color: '#00BC66',
      anchorBgColor: '#00BC66',
      data: Object.values(data.current_period).map(cp => {
        return { value: cp };
      }),
    });
  }
  if (Object.values(data.peer_group).length) {
    dataSet.push({
      seriesname: 'Peer Group',
      color: '#F5A623',
      anchorBgColor: '#F5A623',
      data: Object.values(data.peer_group).map(pg => {
        return { value: pg };
      }),
    });
  }
  return {
    categories: [
      {
        category: getCategories(data),
      },
    ],
    dataset: dataSet.length ? dataSet : null,
  };
};
