import styled from 'styled-components';
import { $danger, $grey5, $primary, $warn } from '../../util/styleVariables';

interface Props {
  green?: string;
  yellow?: string;
  red?: string;
  score?: boolean;
}

export const Container = styled.div<Props>`
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  left: 75%;
  top: 5%;
  display: flex;
  border-radius: 50%;
  background: ${({ color }) =>
    (color === 'green' && $primary) || (color === 'yellow' && $warn) || (color === 'red' && $danger)};
  cursor: default;
`;

export const BadgeText = styled.div<Props>`
  font-style: normal;
  font-weight: ${props => (props.score ? 'normal' : 'bold')};
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  color: ${$grey5};
  text-align: center;
`;
