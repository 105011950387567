import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as base64arraybuffer from 'base64-arraybuffer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { S3FileManagementService } from '../../services/s3FileManagement.service';
import { environment } from '../../environments/environment';
import { openUrlFromFile } from '../../shared/io.util';
import { getAllAcceptedFileTypes } from '@user-interface/gcv-ui';

export interface DueDiligenceFileUploadModalComponentResult {
  isDisabled?: boolean;
  comments?: string;
  documents?: any[];
  docObjs?: string[];
}

@Component({
  selector: 'app-due-diligence-file-upload-modal',
  templateUrl: './due-diligence-file-upload-modal.component.html',
  styleUrls: ['./due-diligence-file-upload-modal.component.scss'],
})
//FIXME test the shit out of this piece of shit
export class DueDiligenceFileUploadModalComponent {
  @ViewChild('form') form: ElementRef;
  @ViewChild('form2', { static: true }) form2: ElementRef;
  ctrl = new FormGroup({
    s3Key: new FormControl(''),
    file_name: new FormControl(''),
    data: new FormControl(''),
    name: new FormControl(''),
  });
  disabledCtrl = new FormGroup({
    disabled: new FormControl(false, Validators.required),
    comment: new FormControl('', Validators.required),
  });
  acceptedFileTypes = getAllAcceptedFileTypes();

  public documentName = '';
  public documentDescription = '';
  public uploadedDocuments = [];
  public tempUploadedDocuments = [];
  public uploadedDocsObjUrls = [];
  public modalData;
  public attachments = [];
  public bankId = '';

  constructor(
    private matDialogRef: MatDialogRef<DueDiligenceFileUploadModalComponent>,
    private cdr: ChangeDetectorRef,
    private s3File: S3FileManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.documentName = data.documentName;
    this.attachments = data.attachments ? data.attachments : [];
    this.documentDescription = data.documentDescription;
    this.bankId = data.bankId;
    this.disabledCtrl.controls.disabled.setValue(data.isDisabled);
    this.disabledCtrl.controls.comment.setValue(data.comments);
    if (data.uploadDocuments) {
      this.modalData = data.uploadDocuments;
      this.uploadedDocuments = data.uploadDocuments.documents;
      this.tempUploadedDocuments = [...this.uploadedDocuments];
      this.uploadedDocsObjUrls = data.uploadDocuments.docObjs;
    }
  }

  async viewAttachment(docId) {
    try {
      const blob = await this.s3File.viewDocument(
        { orgId: this.bankId, userType: this.data.userType },
        docId,
        `${environment.env}-gcv-shared-documents-file-bucket`
      );
      this.s3File.openDocumentInNewTab(blob);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  onFileEvent(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const dataReader = new FileReader();
    dataReader.onload = e => {
      const arrayBuffer = e.target['result'];
      const base64 = base64arraybuffer.encode(<ArrayBuffer>arrayBuffer);
      this.ctrl.controls['data'].setValue(base64);
      this.ctrl.controls.file_name.setValue(file.name);
      this.disabledCtrl.controls.disabled.setValue(false);
      this.disabledCtrl.controls.comment.setValue('');
      this.tempUploadedDocuments.push(this.ctrl.getRawValue());
      this.reset();
    };
    dataReader.readAsArrayBuffer(file);
    const urlReader = new FileReader();
    urlReader.onload = e => {
      this.uploadedDocsObjUrls.push(window.URL.createObjectURL(file));
      this.cdr.markForCheck();
    };
    urlReader.readAsDataURL(file);

    if (!this.ctrl.controls['file_name'].value && !this.ctrl.controls['data'].value) {
      this.ctrl.controls['file_name'].setValue('');
      this.ctrl.controls['data'].setValue(null);
      this.cdr.markForCheck();
    }
  }

  removeDocument(i: number) {
    this.tempUploadedDocuments.splice(i, 1);
  }

  close(results: DueDiligenceFileUploadModalComponentResult = {}) {
    this.reset();
    this.matDialogRef.close({ results });
    this.uploadedDocuments = [];
    this.tempUploadedDocuments = [];
  }

  save() {
    this.uploadedDocuments = [...this.tempUploadedDocuments];
    if (this.disabledCtrl.get('disabled').value) {
      this.close({
        isDisabled: this.disabledCtrl.get('disabled').value,
        comments: this.disabledCtrl.get('comment').value,
        documents: this.uploadedDocuments,
        docObjs: this.uploadedDocsObjUrls,
      });
    } else {
      this.close({
        isDisabled: false,
        documents: this.uploadedDocuments,
        docObjs: this.uploadedDocsObjUrls,
      });
    }
  }

  private reset() {
    this.ctrl.controls.s3Key.setValue('');
    this.ctrl.controls.data.setValue('');
    this.ctrl.controls.file_name.setValue('');
    this.disabledCtrl.reset();
    if (this.form) {
      this.form.nativeElement.reset();
    }

    if (this.form2) {
      this.form2.nativeElement.reset();
    }
    this.uploadedDocsObjUrls = [];
  }

  async viewDocument(index: number) {
    try {
      const currentDocument = this.tempUploadedDocuments[index];

      if (!this.modalData || currentDocument) {
        // if document was just uploaded and not yet attached to the requirement object
        if (currentDocument.hasOwnProperty('data')) {
          const base64Array = base64arraybuffer.decode(currentDocument.data);
          const fileExt = currentDocument.file_name
            .split('.')
            .pop()
            .toLowerCase();
          const fileType =
            fileExt === 'png'
              ? 'image/png'
              : fileExt === ('jpg' || 'jpeg')
              ? 'image/jpeg'
              : fileExt === 'pdf'
              ? 'application/pdf'
              : '';
          const file = new File([base64Array], currentDocument.file_name, {
            type: fileType,
          });

          openUrlFromFile(file);
        } else {
          const { org_id, id } = currentDocument;
          const blob = await this.s3File.viewDocument({ orgId: org_id, userType: this.data.userType }, id);
          this.s3File.openDocumentInNewTab(blob);
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
