<div class="wrapper" data-cy="user-group.component">
  <gcv-user-group-card
    (viewGroup)="handleViewGroup($event)"
    *ngFor="let group of groups"
    [group]="group"
    [userMap]="users"
    data-cy="user-group.component.app-user-group-card"
  ></gcv-user-group-card>
  <gcv-card-group-card
    (createGroup)="handleCreateGroup()"
    data-cy="user-group.component.createGroup"
  ></gcv-card-group-card>
</div>
