<div class="inputWrapper shadowHover" data-cy="upload-button.component">
  <div
    (mouseenter)="showDisable(true)"
    (mouseleave)="showDisable(false)"
    [ngClass]="
      ctrl.controls && ctrl.controls['filename'] && ctrl.controls['filename'].value.length
        ? 'uploadWrapper complete'
        : 'uploadWrapper'
    "
  >
    <div class="textContainer">
      <span class="title">{{ textWhenEmpty }}</span>
      <span
        *ngIf="ctrl.controls && ctrl.controls['filename'] && ctrl.controls['filename'].value.length"
        class="fileName"
        data-cy="upload-button.component.filename"
        >{{ ctrl.controls['filename'].value | fileNamePipe }}</span
      >
      <span *ngIf="ctrl.controls && !ctrl.controls['filename']" class="fileName"></span>
    </div>

    <svg
      *ngIf="!ctrl.controls || !ctrl.controls['filename'] || !ctrl.controls['filename'].value.length"
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.8a10.8 10.8 0 1 1 0-21.6 10.8 10.8 0 0 1 0 21.6zm4.7-12c.2.3.2.6 0 .9l-.4.1-.4-.1-3.3-3.4v9.2c0 .3-.3.6-.6.6a.6.6 0 0 1-.6-.6V8.3l-3.3 3.3c-.2.2-.6.2-.8 0a.6.6 0 0 1 0-.8l4.3-4.3h.1l.1-.1h.5l.1.1 4.3 4.3z"
        fill="#00BC66"
        fill-rule="evenodd"
      />
    </svg>
    <svg
      *ngIf="ctrl.controls && ctrl.controls['filename'] && ctrl.controls['filename'].value.length"
      height="24"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <path
          d="M16 23H2a1 1 0 0 1-1-1V2c0-.6.4-1 1-1h10v3c0 1.1.9 2 2 2h3v16c0 .6-.4 1-1 1zM13 1.7L16.3 5H14a1 1 0 0 1-1-1V1.7zm4.9 3.4l-5-5-.4-.1H2a2 2 0 0 0-2 2v20c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5.5l-.1-.4z"
          fill="#A5A8BA"
        />
        <path
          d="M13.5 14.8l-.8-.5h.3c.7 0 1 0 1 .2s0 .3-.5.3zm-5.1-.6V14l.6-1.5a20.2 20.2 0 0 0 1.3 1.2l-2 .5zM8 8.8c0-1 .3-1 .5-1 .4 0 .5 0 .5 1l-.4 1.6A3 3 0 0 1 8 8.8zm-3.5 9a.8.8 0 0 1-.5-.2c0-.3 1-.8 2-1.3h.3c-.8 1-1.5 1.5-1.8 1.5zm8.5-4.5l-1.5.2a14.5 14.5 0 0 1-2.2-2.2c.5-1.4.7-2.1.7-2.5 0-1.7-.6-2-1.5-2-.7 0-1.5.4-1.5 2 0 .8.4 1.7 1.2 2.8l-.7 2c-.1.4-.2.8-.4 1l-.3.3-1.1.5C4 16.2 3 16.7 3 17.6c0 .8.8 1.2 1.5 1.2 1 0 2.3-1.2 3.4-3.3 1-.5 2.3-.8 3.4-1 .8.7 1.8 1.3 2.2 1.3 1.2 0 1.5-.7 1.5-1.3 0-1.2-1.4-1.2-2-1.2z"
          fill="#FF4E50"
        />
      </g>
    </svg>
    <input
      (change)="onFileEvent($event)"
      (click)="onFileEvent($event)"
      accept=".jpg, .png, .jpeg, .pdf"
      class="upload"
      data-cy="upload-button.component.uploadFile"
      name="myfile"
      type="file"
    />
    <svg
      (click)="removeFile()"
      *ngIf="
        ((ctrl.controls && ctrl.controls['data'] && ctrl.controls['data'].value) ||
          (ctrl.controls && ctrl.controls['s3Key'].value)) &&
        delete
      "
      class="completeIcon"
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle clip-rule="evenodd" cx="13" cy="13" fill="#ff4e50" fill-rule="evenodd" r="13" />
      <path
        d="M14.8 13.1l2.4-2.2c.5-.5.6-1.3.1-1.8-.4-.6-1.3-.6-1.8-.1l-2.4 2.2-2.2-2.4c-.5-.5-1.3-.6-1.8-.1-.6.4-.6 1.3-.1 1.8l2.2 2.4-2.4 2.2c-.5.5-.6 1.3-.1 1.8s1.3.6 1.8.1l2.4-2.2 2.2 2.4c.5.5 1.3.6 1.8.1s.6-1.3.1-1.8l-2.2-2.4z"
        fill="#fff"
      />
    </svg>
    <svg
      *ngIf="
        ((ctrl.controls && ctrl.controls['data'] && ctrl.controls['data'].value) ||
          (ctrl.controls && ctrl.controls['s3Key'].value)) &&
        !delete
      "
      class="completeIcon"
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-rule="evenodd" fill-rule="evenodd">
        <circle cx="13" cy="13" fill="#00bc66" r="13" />
        <path
          d="M15.9 10.3l-4.3 4-1.4-1.4c-.5-.5-1.3-.5-1.8 0s-.5 1.3 0 1.8l1.7 1.7a2 2 0 0 0 2.9 0l4.6-4.2c.5-.5.5-1.3.1-1.8-.5-.5-1.3-.5-1.8-.1"
          fill="#fff"
        />
      </g>
    </svg>
  </div>
</div>
