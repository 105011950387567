import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';

import {
  Bank,
  Dispensary,
  DispensaryAccountReviewTableData,
  OrganizationRoleResolver,
  ReviewPeriodType,
  User,
} from '@gcv/shared';
import { api } from '../../../api';
import { GcvButton, GcvCheckbox, GcvDataTable, GcvLoading, GcvModal, GcvSearchInput, GcvZeroState } from '../../../lib';
import { CardData, CardIcon, CardIconDivider, CardLabel, Cards, Table, TableIconLine } from '../styles';
import { $grey13, $grey4, getReviewPeriodFriendlyName, formatMoney } from 'libs/react-ui/src/util';
import { DateTime } from 'luxon';
import { dispensary_rating_options } from 'libs/react-ui/src/constants/Accounts';
import { ModalTextSmall, ModalTextLarge } from 'libs/react-ui/src/components/fincen/Reports/styles';
import { GcvDropButton } from 'libs/react-ui/src/lib/GcvDropButton/GcvDropButton';
import ReactTooltip from 'react-tooltip';

interface Props {
  bank: Bank;
  user: User;
  dispensaries: { [id: string]: Dispensary };
  bankUsers: { [id: string]: User };
}

const GcvTooltip = withStyles({
  tooltip: {
    color: '#ffffff',
    backgroundColor: '#000000',
    fontSize: '13px',
  },
  arrow: {
    '&::before': {
      color: '#000000',
    },
  },
})(Tooltip);

export const CompletedReviewsTab = (props: Props) => {
  const apiClient = api();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [filteredReviews, setFilteredReviews] = useState<DispensaryAccountReviewTableData[]>([]);
  const [searchableReviews, setSearchableReviews] = useState<DispensaryAccountReviewTableData[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<DispensaryAccountReviewTableData[]>([]);
  const [tableView, setTableView] = useState(false);

  const orgResolver = new OrganizationRoleResolver();
  const userCanDeleteReviews = props.bank
    ? orgResolver.userCanDoAction(props.bank.groups, props.user, 'account_review_edit')
    : false;

  useEffect(() => {
    apiClient.accountMonitoring.getReviews(props.bank.id, 'completed', setIsLoading).then(reviewList => {
      setFilteredReviews(reviewList);
      setSearchableReviews(reviewList);

      if (reviewList.length > 6) {
        setTableView(true);
      }
    });
  }, [props.bank]);

  useEffect(() => {
    if (!isLoading) {
      setFilteredTransactionsFromQuery();
    }
  }, [query]);

  const columns = [
    {
      name: 'Account',
      selector: 'account',
      sortable: true,
      left: true,
    },
    {
      name: 'Completed On',
      selector: 'completedOn',
      sortable: true,
      left: true,
      format: r => DateTime.fromISO(r['completedOn']).toLocaleString(),
    },
    {
      name: 'Completed By',
      selector: 'completedBy',
      sortable: true,
      left: true,
    },
    {
      name: 'Previous Risk Rating',
      selector: 'previousRiskRating',
      sortable: true,
      left: true,
      format: r => dispensary_rating_options.find(rating => rating.value === r['previousRiskRating'])?.label,
    },
    {
      name: 'New Risk Rating',
      selector: 'newRiskRating',
      sortable: true,
      left: true,
      format: r => dispensary_rating_options.find(rating => rating.value === r['newRiskRating'])?.label,
    },
    {
      name: '',
      selector: 'riskRatingChange',
      sortable: true,
      left: true,
      format: r =>
        r['riskRatingChange'] !== undefined ? (
          r['riskRatingChange'] === 0 ? (
            'No Change'
          ) : r['riskRatingChange'] > 0 ? (
            <img src={'../../../../../assets/green-down-arrow.svg'} />
          ) : (
            <img src={'../../../../../assets/red-up-arrow.svg'} />
          )
        ) : (
          ''
        ),
    },
  ];

  const getRiskRatingChange = (review: DispensaryAccountReviewTableData): number | undefined => {
    if (!review.starting_risk_rating || review.starting_risk_rating === 'none' || review.final_risk_rating === 'none') {
      return undefined;
    }

    const values = {
      low: 0,
      'low-moderate': 1,
      moderate: 2,
      'high-moderate': 3,
      high: 4,
    };

    return values[review.starting_risk_rating] > values[review.final_risk_rating]
      ? 1
      : values[review.starting_risk_rating] < values[review.final_risk_rating]
      ? -1
      : 0;
  };

  const tableData = filteredReviews.map(review => {
    return {
      id: review.id,
      account: review.dispensary_name,
      completedOn: review.completed_date,
      completedBy: props.bankUsers[review.completed_by]
        ? `${props.bankUsers[review.completed_by].firstName} ${props.bankUsers[review.completed_by].lastName}`
        : '',
      previousRiskRating: review.starting_risk_rating,
      newRiskRating: review.final_risk_rating,
      riskRatingChange: getRiskRatingChange(review),
    };
  });

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRowsData(selectedRows);
  };

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    const toolTip = 'You do not have permission to delete reviews';
    return !userCanDeleteReviews ? (
      <label data-tip={toolTip}>
        <GcvCheckbox ref={ref} {...props} />
        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
      </label>
    ) : (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  const deleteButton = {
    title: 'Delete',
    onClick: () => setModalOpen(true),
    disabled: selectedRowsData.length === 0 && userCanDeleteReviews,
  };

  const batchDeleteReviews = () => {
    const reviewIds = selectedRowsData.map(r => r.id);
    apiClient.accountMonitoring.batchDeleteReviews(props.bank.id, reviewIds, setIsModalLoading).then(() => {
      setModalOpen(false);
      setSearchableReviews(searchableReviews.filter(r => !reviewIds.includes(r.id)));
      setFilteredReviews(filteredReviews.filter(r => !reviewIds.includes(r.id)));
      setSelectedRowsData([]);
    });
  };

  const setFilteredTransactionsFromQuery = () => {
    const filteredReviews = query
      ? searchableReviews.filter(r => r.dispensary_name.toLowerCase().includes(query.toLowerCase()))
      : searchableReviews;
    setFilteredReviews(filteredReviews);
  };

  const handleRowClick = row => {
    history.push(`/secure/bank/account-monitoring/review/${row.id}/overview`);
  };

  if (isLoading) {
    return <GcvLoading />;
  }

  return (
    <>
      <GcvModal
        modalOpen={modalOpen}
        toggleModal={() => setModalOpen(!modalOpen)}
        backButton={<GcvButton onClick={() => setModalOpen(false)}>Cancel</GcvButton>}
        continueButton={
          <GcvButton danger={true} onClick={batchDeleteReviews}>
            Delete
          </GcvButton>
        }
        title="Are you sure you want to delete these reviews?"
        loading={isModalLoading}
      >
        {
          <>
            <ModalTextSmall>
              Deleted reviews will be removed from this view and permanently deleted 30 days after. Permanently deleted
              reviews can not be recovered.
            </ModalTextSmall>
            <ModalTextLarge>Are you sure you want to delete the selected reviews?</ModalTextLarge>
          </>
        }
      </GcvModal>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={4} md={5}></Grid>
        <Grid item xs={9} sm={8} md={7} style={{ textAlign: 'right' }}>
          <Grid container spacing={4}>
            <Grid item xs={!tableView ? 6 : 2}></Grid>
            <Grid item xs={4}>
              <GcvSearchInput
                label="Search Accounts"
                placeholder="Search Accounts"
                id="searchAccounts"
                value={query}
                style={{ height: 'auto', marginTop: '0px', textAlign: 'left' }}
                onChange={onSearchChange}
                data-cy="search-accounts"
              />
            </Grid>
            {tableView ? (
              <Grid item xs={3}>
                <GcvDropButton
                  title={'Actions'}
                  secondary={true}
                  style={{
                    margin: '0',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '12rem',
                    boxSizing: 'border-box',
                  }}
                  buttons={[deleteButton]}
                ></GcvDropButton>
              </Grid>
            ) : null}
            <Grid item xs={!tableView ? 2 : 3}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Cards
                  onClick={() => {
                    setTableView(false);
                  }}
                  style={{ backgroundColor: !tableView ? $grey13 : $grey4 }}
                  data-cy="accounts-cards-toggle"
                >
                  <CardIconDivider>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                  </CardIconDivider>
                  <CardIconDivider>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                  </CardIconDivider>
                  <CardIconDivider>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                  </CardIconDivider>
                </Cards>
                <Table
                  onClick={() => {
                    setTableView(true);
                  }}
                  style={{ backgroundColor: tableView ? $grey13 : $grey4 }}
                  data-cy="accounts-table-toggle"
                >
                  <TableIconLine></TableIconLine>
                  <TableIconLine></TableIconLine>
                  <TableIconLine></TableIconLine>
                </Table>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {!tableView && (
            <>
              {filteredReviews.length > 0 ? (
                <Grid container spacing={3}>
                  {filteredReviews.map(filteredReview => {
                    return (
                      <Grid item key={filteredReview.id} xs={12}>
                        <Card
                          style={{ cursor: 'pointer', boxShadow: 'none', border: '1px solid #ebeef7' }}
                          onClick={() =>
                            history.push(`/secure/bank/account-monitoring/review/${filteredReview.id}/overview`)
                          }
                          data-cy="completed-review-card"
                        >
                          <CardContent>
                            <div style={{ display: 'flex', padding: '1rem' }}>
                              <div style={{ width: '23%' }}>
                                <CardData
                                  style={{
                                    textAlign: 'left',
                                    fontWeight: 400,
                                    color: '#000000',
                                    lineHeight: '24px',
                                  }}
                                  data-cy="account-name"
                                >
                                  <GcvTooltip title={filteredReview.dispensary_name} arrow>
                                    <Typography
                                      noWrap
                                      style={{
                                        fontFamily: 'Lato',
                                        fontWeight: 500,
                                        fontSize: '16px',
                                        lineHeight: '19px',
                                      }}
                                    >
                                      {filteredReview.dispensary_name}
                                    </Typography>
                                  </GcvTooltip>
                                </CardData>
                                <CardLabel
                                  style={{ color: '#00BC66', textAlign: 'left', fontWeight: 700, lineHeight: '15px' }}
                                  data-cy="review-period-friendly"
                                >
                                  {getReviewPeriodFriendlyName(filteredReview) +
                                    ': ' +
                                    filteredReview.review_period_timeframe}
                                </CardLabel>
                              </div>
                              <div style={{ width: '15.4%' }} data-cy="completed-on">
                                <CardLabel>Completed On</CardLabel>
                                <CardData>{DateTime.fromISO(filteredReview.completed_date).toLocaleString()}</CardData>
                              </div>
                              <div style={{ width: '15.4%' }} data-cy="completed-by">
                                <CardLabel>Completed By</CardLabel>
                                <CardData>
                                  {props.bankUsers[filteredReview.completed_by]
                                    ? `${props.bankUsers[filteredReview.completed_by].firstName} ${
                                        props.bankUsers[filteredReview.completed_by].lastName
                                      }`
                                    : ''}
                                </CardData>
                              </div>
                              <div style={{ width: '15.4%' }} data-cy="previous-risk-rating">
                                <CardLabel>Previous Risk Rating</CardLabel>
                                <CardData>
                                  {
                                    dispensary_rating_options.find(
                                      rating => rating.value === filteredReview.starting_risk_rating
                                    )?.label
                                  }
                                </CardData>
                              </div>
                              <div style={{ width: '15.4%' }} data-cy="final-risk-rating">
                                <CardLabel>New Risk Rating</CardLabel>
                                <CardData>
                                  {dispensary_rating_options.find(
                                    rating => rating.value === filteredReview.final_risk_rating
                                  )?.label ?? '--'}
                                </CardData>
                              </div>
                              <div style={{ width: '15.4%' }} data-cy="risk-rating-change">
                                <CardLabel></CardLabel>
                                <CardData>
                                  {getRiskRatingChange(filteredReview) != undefined ? (
                                    getRiskRatingChange(filteredReview) === 0 ? (
                                      'No Change'
                                    ) : getRiskRatingChange(filteredReview) > 0 ? (
                                      <img
                                        src={'../../../../../assets/green-down-arrow.svg'}
                                        data-cy="green-down-arrow"
                                      />
                                    ) : (
                                      <img src={'../../../../../assets/red-up-arrow.svg'} data-cy="red-up-arrow" />
                                    )
                                  ) : (
                                    '--'
                                  )}
                                </CardData>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : query ? (
                <GcvZeroState
                  type="bankDash"
                  headerText={'There are no completed reviews that match your search'}
                  subText={'Please try changing your search to show more reviews'}
                />
              ) : (
                <GcvZeroState
                  type="bankDash"
                  headerText={'There are no completed reviews'}
                  subText={'Reviews will be displayed here as soon as they are completed'}
                />
              )}
            </>
          )}

          {tableView && (
            <GcvDataTable
              data={filteredReviews ? tableData : []}
              columns={columns}
              onRowClicked={row => {
                handleRowClick(row);
              }}
              defaultSortField={'name'}
              defaultSortAsc={true}
              keyField="id"
              noDataComponent={
                query ? (
                  <GcvZeroState
                    type="bankDash"
                    headerText={'There are no completed reviews that match your search'}
                    subText={'Please try changing your search to show more reviews'}
                  />
                ) : (
                  <GcvZeroState
                    type="bankDash"
                    headerText={'There are no completed reviews'}
                    subText={'Reviews will be displayed here as soon as they are completed'}
                  />
                )
              }
              selectableRows={true}
              onSelectedRowsChange={onSelectedRowsChange}
              selectableRowsComponent={renderSelectComponent}
              subHeaderAlign="left"
              paginationPerPage={10}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
