import React, { useEffect, useState } from 'react';
import { GcvButton, GcvInputForm, GcvInputSelect, GcvModal } from '../../lib';
import { Grid, Tooltip } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { states } from 'libs/gcv-ui/src/lib';

import styled from 'styled-components';

const FormContent = styled.div`
  margin: 5px 0px;
`;

const ModalContent = styled.div`
  padding: 0 2rem;
`;

interface Props {
  onSubmit: (payload: any) => any;
  modalOpen: boolean;
  toggleModal: () => void;
  defaultValues?: {
    id: string;
    type: string;
    name: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    phone: string;
    tin: string;
  };
}

export const AddTransportationServiceModal = ({
  onSubmit,
  modalOpen,
  toggleModal,
  defaultValues = { id: '', type: '', name: '', street: '', city: '', state: '', zipCode: '', phone: '', tin: '' },
}: Props) => {
  const { handleSubmit, ...form } = useForm();

  const options = states.map(s => {
    return { value: s.value, label: s.viewValue };
  });

  const closeModal = data => {
    toggleModal();
    onSubmit({ ...defaultValues, ...data });
  };

  return (
    <>
      <GcvModal
        title={'Add a Transportation Service'}
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        backButton={
          <GcvButton tertiary onClick={toggleModal}>
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={handleSubmit(closeModal)} primary={true} data-cy="submit-button">
            Submit
          </GcvButton>
        }
      >
        <ModalContent data-cy="add-transportation-vendor-modal">
          <div style={{ padding: '0 2rem' }}>
            <form noValidate autoComplete="off">
              <FormContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <GcvInputForm
                      {...form}
                      name={'name'}
                      rules={{ required: true }}
                      label={"Entity's Legal Name"}
                      type="text"
                      defaultValue={defaultValues.name}
                      testDataTag="name"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <GcvInputForm
                      {...form}
                      name={'street'}
                      rules={{ required: true }}
                      label={'Street Address'}
                      type="text"
                      defaultValue={defaultValues.street}
                      testDataTag="street"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GcvInputForm
                      {...form}
                      name={'city'}
                      rules={{ required: true }}
                      label={'City'}
                      type="text"
                      defaultValue={defaultValues.city}
                      testDataTag="city"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <GcvInputSelect
                      {...form}
                      name={'state'}
                      label={'State'}
                      options={options}
                      rules={{ required: { value: true, message: 'is required' } }}
                      defaultValue={options.find(s => s.value === defaultValues.state)}
                      testDataTag="state"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GcvInputForm
                      {...form}
                      name={'zipCode'}
                      rules={{ required: true }}
                      label={'Zip Code'}
                      type="text"
                      defaultValue={defaultValues['zipCode']}
                      testDataTag="zipCode"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <GcvInputForm
                      {...form}
                      name={'phone'}
                      rules={{ required: true }}
                      label={'Phone Number'}
                      type="text"
                      defaultValue={defaultValues.phone}
                      testDataTag="phone"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GcvInputForm {...form} name={'tin'} label={'TIN'} type="tin" defaultValue={defaultValues.tin} />
                    <Tooltip
                      title="A Tax Identification Number (TIN) is a 9-digit number used to identify a business entity, and is used to
          maintain banking records. You should check service provider’s documentation or request their TIN directly."
                    >
                      <a>What's this?</a>
                    </Tooltip>
                  </Grid>
                </Grid>
              </FormContent>
            </form>
          </div>
        </ModalContent>
      </GcvModal>
    </>
  );
};
