import { ReactWrapperComponent, WrapperProps, ExportsTable } from '@user-interface/react-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { DispensaryFacade, BankFacade, FincenFacade, StaffFacade } from '@user-interface/gcv-state';
import { Bank, FincenExportRecord } from '@gcv/shared';
import { API } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { getEndpoint } from 'libs/react-ui/src/hooks';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fincen-exports',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></ app-react-wrapper>',
})
export class FincenExportsComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Output() clickEmitter = new EventEmitter<any>();

  component = ExportsTable;
  bank: Bank;
  reports$: any;
  exports: FincenExportRecord[];
  reportsLoading: Boolean = false;

  constructor(
    private dispFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private staffFacade: StaffFacade,
    private router: Router,
    private fincenFacade: FincenFacade,
    private route: ActivatedRoute
  ) {
    super();
    this.handleEmitData = this.handleEmitData.bind(this);
  }

  async ngOnInit() {
    this.props = {
      ...this.props,
      exportsLoading: true,
    };

    await this.setUpStreams();
  }

  async setUpStreams() {
    this.bankFacade
      .selectCurrentBank()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(bank => {
        this.bank = bank;
        this.props = {
          ...this.props,
          bank,
        };
      });
    this.dispFacade
      .selectDispensaryDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(dispensaryMap => {
        this.props = {
          ...this.props,
          dispensaryMap,
        };
      });
    this.staffFacade.getOrganizationStaffByOrgId(this.bank.id);
    this.staffFacade
      .selectAllStaff()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(users => {
        this.props = {
          ...this.props,
          users,
        };
      });
    this.exports = await API.get(
      GREEN_CHECK_SERVICES_AMPLIFY_NAME,
      `${getEndpoint.getFincenExports(this.bank.id)}`,
      {}
    );
    this.props = {
      ...this.props,
      exports: this.exports,
      exportsLoading: false,
    };
    this.fincenFacade
      .selectAllFincen()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(reports => {
        this.reports$ = reports;
        this.props = {
          ...this.props,
          reports,
          reportsLoading: false,
        };
        if (this.exports.length > 0) {
          if (reports.length === 0) {
            this.fincenFacade.getFincenByBankId(this.bank.id);
            this.props = {
              ...this.props,
              reports,
              reportsLoading: true,
            };
          }
        }
      });
  }

  async handleEmitData(payload) {
    if (payload.type === 'viewReport') {
      const data: NavigationExtras = {
        queryParams: {
          id: payload.reportId,
        },
      };
      this.router.navigate(['/secure/bank/view/report'], data);
    } else if (payload.type === 'updateExport') {
      this.fincenFacade.updateFincenStore(payload.updatedReports);
      const selectedExport = this.exports.find(e => e.id === payload.exportId);

      selectedExport.tracking_id = payload.trackingId;
      if (payload.complete) {
        //trick to keep this row shown in the pending table until the user leaves the page and returns
        selectedExport.pending_acknowledgement_file_upload = 'no';
        selectedExport.acknowledgement_file_name = payload.fileName;
      }

      this.props = {
        ...this.props,
      };
    }
  }
}
