<div class="wrapper">
  <div class="title">
    <svg *ngIf="isDispensary" fill="none" height="76" width="76" xmlns="http://www.w3.org/2000/svg">
      <path d="M38 75a37 37 0 1 0 0-74 37 37 0 0 0 0 74z" fill="#F9FAFD" stroke="#E0E5F0" />
      <path
        d="M57.8 36.2l-1.2-4.8c.3 0 .6-.4.6-.7v-.8a3 3 0 0 0-3-3h-5.5v-1.6c0-.8-.7-1.5-1.5-1.5H19.6c-.9 0-1.5.7-1.5 1.5v13a.7.7 0 0 0 1.4 0v-13h27.7v13a.7.7 0 1 0 1.5 0v-.7h8.5c1 0 2 .7 2.2 1.6h-2.2c-.4 0-.7.3-.7.7v1.6c0 1.2 1 2.2 2.2 2.2h.9V47h-2a4.6 4.6 0 0 0-8.5 0h-.4v-5.4a.7.7 0 0 0-1.4 0v5.4h-15a4.6 4.6 0 0 0-8.6 0h-4.1v-1.6h3a.7.7 0 0 0 0-1.5h-6.9a.7.7 0 0 0 0 1.5h2.4v1.5c0 .9.6 1.5 1.5 1.5h3.9a4.6 4.6 0 0 0 9 0h16.3a4.6 4.6 0 0 0 9.1 0h2.4c.4 0 .7-.3.7-.7V40c0-1.9-1.4-3.5-3.2-3.7zm-9-7.8H54c1 0 1.6.7 1.6 1.5h-7v-1.5zm0 7.7v-4.7H55l1.2 4.7h-7.6zM28 51.5a3.1 3.1 0 1 1 0-6.3 3.1 3.1 0 0 1 0 6.3zm25.4 0a3.1 3.1 0 1 1 0-6.3 3.1 3.1 0 0 1 0 6.3zm6.2-9.2h-.9a.8.8 0 0 1-.8-.8v-.9h1.7v1.7z"
        fill="#02B45B"
      />
      <path
        d="M28 46.9a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM53.4 46.9a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM45 43.8H34.1a.7.7 0 0 0 0 1.5h10.7a.7.7 0 0 0 0-1.5zM26.5 40.7h-9.2a.7.7 0 1 0 0 1.5h9.2a.7.7 0 1 0 0-1.5zM40 31a.7.7 0 0 0-1 0l-6.4 6.4-3.3-3.4a.7.7 0 1 0-1 1L32 39a.7.7 0 0 0 1 0l7-7c.2-.2.2-.7 0-1z"
        fill="#02B45B"
      />
    </svg>
    <svg *ngIf="!isDispensary" fill="none" height="76" width="76" xmlns="http://www.w3.org/2000/svg">
      <path d="M38 75a37 37 0 1 0 0-74 37 37 0 0 0 0 74z" fill="#F9FAFD" stroke="#E0E5F0" />
      <path
        clip-rule="evenodd"
        d="M15.1 51.7l21.5-37.2a1 1 0 0 1 1.7 0l21.5 37.2a1 1 0 0 1-.9 1.5H16a1 1 0 0 1-.9-1.5z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M15.1 51.7l21.5-37.2a1 1 0 0 1 1.7 0l21.5 37.2a1 1 0 0 1-.9 1.5H16a1 1 0 0 1-.9-1.5z"
        stroke="#F5A623"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        clip-rule="evenodd"
        d="M36 40.1V26.4a1.4 1.4 0 0 1 2.9 0v13.7a1.4 1.4 0 0 1-2.9 0zM36 45.3a2 2 0 1 1 3.9 0 2 2 0 0 1-3.9 0z"
        fill="#F5A623"
        fill-rule="evenodd"
      />
    </svg>
  </div>
  <div class="innerContent">
    <h3>{{title}}</h3>
    <span>{{subtitle}}</span>
  </div>
  <div class="btnWrapper">
    <gcv-button (clickEvent)="handleCancel()" buttonText="Cancel" type="secondary"> </gcv-button>
    <gcv-button (clickEvent)="handleContinue()" buttonText="Continue" type="primary"> </gcv-button>
  </div>
</div>
