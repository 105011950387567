import styled from 'styled-components';
import { $danger } from '../../util/styleVariables';

interface Props {
  status: string;
}

const colorMap = {
  good: '#02b45b',
  warning: '#C5F6DE',
  danger: $danger,
  none: '#C4C4C4',
};

export const VerticalCenter = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StatusIcon = styled.div<Props>`
    display: inline-block;
    margin-left: 10px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${props => colorMap[props.status]};
  }
`;
