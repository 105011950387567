import { Routes } from '@angular/router';
import { TermsOfServiceComponent } from './terms-of-service.component';
import { CanActivateTermsOfService } from './terms-of-service.can-activate';
import { termsOfServiceRoutesNames } from './terms-of-service.routes.names';

export const TERMS_OF_SERVICE_ROUTES: Routes = [
  {
    path: termsOfServiceRoutesNames.TERMS_OF_SERVICE,
    component: TermsOfServiceComponent,
    canActivate: [CanActivateTermsOfService],
    pathMatch: 'full',
  },
];
