import { Bank, FincenExportMapping, FincenExportRecord, User } from '@gcv/shared';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { api } from 'libs/react-ui/src/api';
import { getEndpoint, useApi } from 'libs/react-ui/src/hooks';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  GcvButton,
  GcvDataTable,
  GcvInputFile,
  GcvInputForm,
  GcvLoading,
  GcvModal,
  GcvZeroState,
  GcvSearchInput,
  GcvInputSelectNew,
} from '../../../lib';
import {
  bsaIdExport,
  exportId,
  exportedBy,
  exportedOn,
  numberOfReports,
  trackingId,
  account,
  amount,
  dateOfTransaction,
  dueDate,
  sarType,
  bsaId,
} from './columns';
import { DispensaryMap, getTableDataCtr, getTableDataSar } from './reports-util';
import {
  Options,
  Title,
  ModalTextSmall,
  ModalTextError,
  ModalTextSuccess,
  SelectContainer,
  SubOptions,
} from './styles';

type ModalType = 'acknowledgement' | 'update-tracking';

interface Props {
  emitData: (payload) => void;
  reports: any[];
  bank: Bank;
  exports: FincenExportRecord[];
  dispensaryMap: DispensaryMap;
  exportsLoading: Boolean;
  reportsLoading: Boolean;
  users: User[];
}

export const ExportsTable = ({
  emitData,
  reports,
  bank,
  exports,
  dispensaryMap,
  exportsLoading,
  reportsLoading,
  users,
}: Props) => {
  const apiClient = api();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalError, setModalError] = useState<string>(undefined);
  const [modalSuccess, setModalSuccess] = useState<string>(undefined);
  const { handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });
  const [viewSar, setViewSar] = useState<boolean>(false);
  const [showKnownBsaIds, setShowKnownBsaIds] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedExportId, setSelectedExportId] = useState<string>();
  const [query, setQuery] = useState('');

  const getExports = () => {
    if (exports) {
      let shownExports = viewSar
        ? exports.filter(exportRecord => exportRecord.reports_type === 'SAR')
        : exports.filter(exportRecord => exportRecord.reports_type === 'CTR');
      if (query && query != '') {
        shownExports = shownExports.filter(
          e => e.tracking_id && e.tracking_id.toLowerCase().includes(query.toLowerCase())
        );
      }
      shownExports = shownExports.filter(e =>
        showKnownBsaIds ? e.pending_acknowledgement_file_upload != 'x' : e.pending_acknowledgement_file_upload
      );
      return shownExports.map(shownExport => {
        const exportedByUser = users.find(user => user.id === shownExport.exported_by);
        const exportedByName = exportedByUser ? `${exportedByUser.firstName} ${exportedByUser.lastName}` : '';
        return {
          ...shownExport,
          trackingId: shownExport.tracking_id,
          exportedOn: shownExport.date_exported,
          exported_by: exportedByName,
        };
      });
    }
  };
  const [filteredExports, setFilteredExports] = useState(getExports());

  useEffect(() => {
    setFilteredExports(getExports());
  }, [exports, users, viewSar, showKnownBsaIds, query]);

  const onSearchInput = e => {
    setQuery(e.target.value);
  };

  const handleSwitchType = viewSar => {
    setSelectedExportId(undefined);
    setViewSar(viewSar);
  };

  const handleSwitchShowBsaIds = showKnown => {
    setSelectedExportId(undefined);
    setShowKnownBsaIds(showKnown);
  };

  const getReports = () => {
    if (selectedExportId) {
      const exportReports = exports
        .find(e => e.id === selectedExportId)
        .report_mappings.map(mapping => mapping.fincen_report_id);
      const selectedReports = reports.filter(report => {
        return exportReports.includes(report.id);
      });
      return viewSar
        ? getTableDataSar(selectedReports, dispensaryMap)
        : getTableDataCtr(selectedReports, dispensaryMap);
    } else {
      return [];
    }
  };

  const handleExportRowClick = row => {
    setSelectedExportId(row.id);
  };

  const handleReportRowClick = row => {
    emitData({ type: 'viewReport', reportId: row.id });
  };

  const handleModalConfirm = async formData => {
    if (modalType === 'acknowledgement') {
      await toggleModal();
    } else if (modalType === 'update-tracking') {
      await handleTrackingUpdateSubmit(formData);
    }
  };

  const handleAcknowledgeMentSubmit = async files => {
    try {
      setModalLoading(true);
      setModalError(undefined);
      setModalSuccess(undefined);
      setFiles(files);

      if (files[0]) {
        //parse xml file and get matching nodes
        const fileText = await files[0].text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(fileText, 'text/xml');
        const bsaNodes = xml.getElementsByTagName('EFilingActivityXML');

        //validate the number of nodes is equal to number of files in the export report
        const selectedExport = exports.find(e => e.id === selectedExportId);
        if (selectedExport.number_of_reports != bsaNodes.length) {
          setModalError(
            `The number of reports listed in the acknowledgement file does not match the number of reports contained in the batch (${selectedExport.number_of_reports}). 
            Please ensure you are using the correct file for this export and try again.`
          );
        } else {
          //figure out the mapping of BSA IDs to fincen report IDs
          const mapping = getFincenMapping(bsaNodes, selectedExport.report_mappings);

          //make api call
          const postBody = {
            exportId: selectedExportId,
            trackingId: selectedExport.tracking_id ? selectedExport.tracking_id : files[0].name.replace('.xml', ''),
            reportMappings: mapping,
            fileName: files[0].name,
          };
          const updatedReports = await apiClient.fincen.updateFincenExportReports(bank.id, postBody, () => {});

          setModalSuccess(
            `Successfully updated the BSA IDs for ${updatedReports.length} report${
              updatedReports.length > 1 ? 's' : ''
            }.`
          );
          emitData({
            type: 'updateExport',
            exportId: selectedExportId,
            trackingId: postBody.trackingId,
            fileName: postBody.fileName,
            complete: true,
            updatedReports: updatedReports,
          });
          setFilteredExports(getExports());
        }
      }
    } catch (e) {
      setModalError(`We're sorry, something went wrong. Please contact support for further assistance.`);
    } finally {
      setModalLoading(false);
    }
  };

  const handleTrackingUpdateSubmit = async formData => {
    //make api call
    const postBody = {
      exportId: selectedExportId,
      trackingId: formData['trackingId'],
    };
    const updatedReports = await apiClient.fincen.updateFincenExportTracking(bank.id, postBody, () => {});

    emitData({ type: 'updateExport', exportId: selectedExportId, trackingId: postBody.trackingId, complete: false });
    toggleModal();
    setFilteredExports(getExports());
  };

  const getFincenMapping = (xmlMappings: HTMLCollectionOf<Element>, exportMappings: FincenExportMapping[]) => {
    const reportMappings = [];
    for (let i = 0; i < xmlMappings.length; i++) {
      const xmlMapping = xmlMappings.item(i);
      const seqNum = xmlMapping.getAttribute('SeqNum');
      const matchingFincenExport = exportMappings.find(mapping => mapping.xml_seq_num === seqNum);

      if (matchingFincenExport) {
        for (let i = 0; i < xmlMapping.children.length; i++) {
          if (xmlMapping.children.item(i).nodeName == 'BSAID') {
            const bsaId = xmlMapping.children.item(i).innerHTML;
            reportMappings.push({ fincenReportId: matchingFincenExport.fincen_report_id, bsaId: bsaId });
            break;
          }
        }
      }
    }

    return reportMappings;
  };

  const toggleModal = (type?: ModalType, rowInfo?: any) => {
    if (type) {
      setModalType(type);
      if (rowInfo) {
        setSelectedExportId(rowInfo.id);
      }
    }
    setFiles([]);
    setModalError(undefined);
    setModalSuccess(undefined);
    setModalOpen(!modalOpen);
  };

  const exportColumns = [
    exportId('Export ID'),
    exportedOn('Exported On'),
    exportedBy('Exported By'),
    numberOfReports('Reports'),
    trackingId('Tracking ID', toggleModal),
    bsaIdExport('Acknowledgement File', toggleModal),
  ];

  const columnsCtr = [
    account('Account'),
    dueDate('Due Date'),
    amount('Amount'),
    dateOfTransaction('Transaction Date'),
    bsaId('BSA ID'),
  ];

  const columnsSar = [account('Account'), sarType('Type'), dueDate('Due Date'), amount('Amount'), bsaId('BSA ID')];

  return (
    <>
      <GcvModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        backButton={<GcvButton onClick={() => toggleModal()}>Cancel</GcvButton>}
        continueButton={
          <GcvButton
            primary={true}
            disabled={modalType === 'acknowledgement' ? files.length == 0 || modalError != undefined : false}
            onClick={handleSubmit(handleModalConfirm)}
          >
            {modalType === 'acknowledgement' ? 'Finish' : 'Update'}
          </GcvButton>
        }
        title={modalType === 'acknowledgement' ? 'Upload Acknowledgement File' : 'Update Tracking ID'}
        loading={modalLoading}
      >
        {
          <>
            {modalType === 'acknowledgement' ? (
              <>
                <ModalTextSmall>
                  Upload the acknowledgement file received from FinCEN containing the BSA IDs of the individual reports
                  included in your batch. This file should be in XML format.
                </ModalTextSmall>

                {modalSuccess ? (
                  <>
                    <ModalTextSuccess>{modalSuccess}</ModalTextSuccess>
                  </>
                ) : (
                  <GcvInputFile
                    updateFileState={handleAcknowledgeMentSubmit}
                    acceptedTypes={['xml']}
                    files={files}
                    multiple={false}
                  ></GcvInputFile>
                )}
                {modalError ? (
                  <>
                    <ModalTextError>{modalError}</ModalTextError>
                  </>
                ) : null}
              </>
            ) : null}
            {modalType === 'update-tracking' ? (
              <>
                <ModalTextSmall>
                  This will update the tracking IDs on each report contained in the batch.
                </ModalTextSmall>
                <GcvInputForm {...form} name="trackingId" label={`Enter the Tracking ID`} />
              </>
            ) : null}
          </>
        }
      </GcvModal>
      <Options>
        <SubOptions style={{ width: '50%' }}>
          <SelectContainer>
            <GcvInputSelectNew
              label="Type"
              options={[
                { label: 'Currency Transaction Reports', value: 'CTR' },
                { label: 'Suspicious Activity Reports', value: 'SAR' },
              ]}
              onChange={e => handleSwitchType(e.value === 'SAR')}
              dropWidth={'260px'}
              labelWidth={'60px'}
              dropCharLength={35}
            />
          </SelectContainer>
          <SelectContainer>
            <GcvInputSelectNew
              label="BSA IDs"
              options={[
                { label: 'Known', value: 'Known' },
                { label: 'Unknown', value: 'Unknown' },
              ]}
              onChange={e => handleSwitchShowBsaIds(e.value === 'Known')}
              dropWidth={'125px'}
              labelWidth={'75px'}
              defaultValueDrop={'Unknown'}
            />
          </SelectContainer>
        </SubOptions>
        <GcvSearchInput
          style={{ width: 250, marginTop: 0 }}
          onChange={onSearchInput}
          value={query}
          placeholder="Search Tracking ID"
          label="Search Tracking ID"
          id="search"
          data-cy="search-tracking-id"
        />
      </Options>
      {exportsLoading ? (
        <GcvLoading></GcvLoading>
      ) : (
        <GcvDataTable
          data={filteredExports}
          columns={exportColumns}
          onRowClicked={handleExportRowClick}
          defaultSortField={'exportedOn'}
          defaultSortAsc={false}
          keyField="id"
          noDataComponent={<GcvZeroState subText={'There are no exports that currently match your filters.'} />}
          subHeaderAlign="left"
          paginationPerPage={10}
        />
      )}
      <>
        <Title style={{ margin: '1rem 0' }}>Reports Included in Export:</Title>
        {reportsLoading ? (
          <GcvLoading></GcvLoading>
        ) : (
          <GcvDataTable
            data={getReports()}
            columns={viewSar ? columnsSar : columnsCtr}
            onRowClicked={handleReportRowClick}
            defaultSortField={'exported_on'}
            defaultSortAsc={true}
            keyField="id"
            noDataComponent={<GcvZeroState subText={'Select an Export above to view the reports attached.'} />}
            subHeaderAlign="left"
            paginationPerPage={10}
          />
        )}
      </>
    </>
  );
};
