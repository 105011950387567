import { Bank, Dispensary, DueDiligenceTemplate } from '@gcv/shared';
import { Grid } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../api';
import { GcvButton, GcvZeroState } from '../../lib';
import { DynamicUi } from '../../shared/DynamicUiGenerator/ui-factory';
import { doesBankHaveCustomFields } from '../../util';

interface Props {
  bankTemplate: DueDiligenceTemplate;
  dispensary: Dispensary;
  emitData: (type, data) => void;
}

export const CompanyProfile: React.FC<Props> = props => {
  const uiData = props.dispensary?.due_diligence?.bank_custom_fields?.responses
    ? props.dispensary.due_diligence.bank_custom_fields.responses
    : {};
  const uiJson = doesBankHaveCustomFields(props.bankTemplate) ? props.bankTemplate.custom_fields.template_json : '';
  const apiClient = api();
  const [loading, setLoading] = useState(false);

  const saveAdditionalInformation = async data => {
    const disp = await apiClient.dispensaries.upsertBankCustomFieldsResponses(
      props.dispensary.id,
      props.bankTemplate.id,
      data,
      setLoading
    );

    props.emitData('updateDispensary', disp); // unfortunate side effect is this is causing rerenders which reset the active index in the dynamicUi to 0 on save.

    return disp;
  };

  return (
    <>
      {!uiJson && (
        <GcvZeroState
          headerText="There is no additional information needed at this time."
          subText="If your banking partner requests any additional information, it will show up here."
          type="bankDash"
        />
      )}
      {uiJson && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DynamicUi
              uiData={JSON.stringify(uiData)}
              uiJson={uiJson}
              saveData={saveAdditionalInformation}
              readOnly={true}
              optionalEdit={true}
              loading={loading}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
