import { select, Store } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { Dispensary, Group } from '@gcv/shared';
import { Observable } from 'rxjs';
import { DispensaryState } from './dispensary.reducer';
import {
  selectDispensaryAll,
  selectDispensaryById,
  selectDispensaryEntities,
  selectCurrentDispensary,
  selectCurrentDispensaryId,
  selectGcvdueDiligenceTemplate,
} from './dispensary.selectors';
import {
  ApproveBankDueDiligence,
  ApproveBankLicense,
  ApproveGcvDueDiligence,
  CreateDispensary,
  DispensaryUpdateGroups,
  GetGcvDueDiligenceTemplate,
  LoadAllDispensary,
  LoadDispensariesByBankId,
  LoadDispensary,
  ResetDispensary,
  UpdateCurrentDispensary,
  ReviewDispensary,
  UpdateBankDueDiligenceStatus,
  UpdateDispensary,
  UpdateGcvDueDiligenceStatus,
  ApproveBankReviewSections,
  UpdateDispensaryBusinessDetails,
  UpdateDispensaryOperationalDetails,
  UpdateDispensaryAccountOwnersSectionComplete,
  UpdateDispensaryRequirement,
  InviteDispensaryUser,
  DispensaryLoaded,
} from './dispensary.actions';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

export interface IDispensaryFacade {
  getDispensarybyId(dispensaryId: string): void;

  getDispensariesbyBankId(dispensaryId: string): void;

  getAllDispensaries(): void;

  getDispensaryGcvDueDiligenceTemplate(dispensaryId: string): Observable<any>;

  selectDispensaryGcvDueDiligenceTemplate(): Observable<any>;

  putDispensary(dispensary: Dispensary): void;

  postDispensary(dispensary: Partial<Dispensary>): void;

  putDispensaryGroups(dispensaryId: string, groups: Group[]): void;

  putBankDueDiligence(dispensaryId: string, bankId: string, data: ApprovalRequest): void;

  putGcvDueDiligence(dispensaryId: string, data: { reviewer: string; status: string }): void;

  putReviewSections(
    dispensaryId: string,
    data: {
      operation_details?: boolean;
      account_owners?: boolean;
      due_diligence_documentation?: boolean;
      business_details?: boolean;
    }
  ): void;

  resetDispensary(): void;

  updateCurrentDispensary(dispensaryId: string): void;

  selectCurrentDispensaryId(dispensaryId: string): void;

  selectDispensaryById(dispensaryId): any;

  selectAllDispensaries(): any;

  selectCurrentDispensary(): any;

  selectDispensaryDictionary(): any;

  bankReviewDispensary(bankId: string, dispensaryId: string): void;

  updateGcvDueDiligenceStatus(dispensaryId: string): void;

  updateBankDueDiligenceStatus(dispensaryId: string): void;
}

export interface ApprovalRequest {
  identifier: string;
  reviewer: string;
  status: string;
  section: string;
  category: string;
  reviewer_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class DispensaryFacade implements IDispensaryFacade {
  dispensary$: Observable<Dictionary<Dispensary>>;

  constructor(private store: Store<DispensaryState>) {
    this.dispensary$ = store.pipe(select(selectDispensaryEntities));
  }

  updateGcvDueDiligenceStatus(dispensaryId: string): void {
    this.store.dispatch(new UpdateGcvDueDiligenceStatus({ dispensaryId }));
  }

  updateBankDueDiligenceStatus(dispensaryId: string): void {
    this.store.dispatch(new UpdateBankDueDiligenceStatus({ dispensaryId }));
  }

  getDispensarybyId(dispensaryId: string) {
    this.store.dispatch(new LoadDispensary({ dispensaryId }));
  }

  getDispensariesbyBankId(bankId: string) {
    this.store.dispatch(new LoadDispensariesByBankId({ bankId }));
  }

  getAllDispensaries() {
    this.store.dispatch(new LoadAllDispensary());
  }

  getDispensaryGcvDueDiligenceTemplate(dispensaryId: string) {
    this.store.dispatch(new GetGcvDueDiligenceTemplate({ id: dispensaryId }));
    return this.selectDispensaryGcvDueDiligenceTemplate();
  }

  selectDispensaryGcvDueDiligenceTemplate(): Observable<any> {
    return this.store.select(selectGcvdueDiligenceTemplate).pipe(filter(template => template !== null));
  }

  putDispensary(dispensary: Dispensary) {
    this.store.dispatch(new UpdateDispensary(dispensary));
  }

  postDispensary(dispensary: Partial<Dispensary>) {
    this.store.dispatch(new CreateDispensary(dispensary));
  }

  putDispensaryGroups(dispensaryId: string, groups: Group[]) {
    this.store.dispatch(new DispensaryUpdateGroups({ dispensaryId, groups }));
  }

  putBankDueDiligence(dispensaryId: string, bankId: string, data: ApprovalRequest) {
    this.store.dispatch(new ApproveBankDueDiligence({ dispensaryId, bankId, data }));
  }

  putGcvDueDiligence(dispensaryId: string, data: { reviewer: string; status: string }) {
    this.store.dispatch(new ApproveGcvDueDiligence({ dispensaryId, data }));
  }

  putReviewSections(
    dispensaryId: string,
    data: {
      operation_details?: boolean;
      account_owners?: boolean;
      due_diligence_documentation?: boolean;
      business_details?: boolean;
    }
  ) {
    this.store.dispatch(new ApproveBankReviewSections({ dispensaryId, data }));
  }

  putBankLicenseStatus(
    dispensaryId: string,
    data: {
      reviewer: string;
      status: 'approved' | 'rejected' | 'submitted';
      type: string;
      reviewer_id: string;
    }
  ) {
    this.store.dispatch(new ApproveBankLicense({ dispensaryId, data }));
  }

  resetDispensary() {
    this.store.dispatch(new ResetDispensary());
  }

  updateCurrentDispensary(id: string) {
    this.store.dispatch(new UpdateCurrentDispensary(id));
  }

  selectCurrentDispensaryId() {
    return this.store.pipe(select(selectCurrentDispensaryId));
  }

  selectDispensaryById(dispensaryId) {
    return this.store.pipe(select(selectDispensaryById, { dispensaryId }));
  }

  selectAllDispensaries() {
    return this.store.pipe(select(selectDispensaryAll));
  }

  selectCurrentDispensary() {
    return this.store.pipe(select(selectCurrentDispensary));
  }

  selectDispensaryDictionary() {
    return this.store.pipe(select(selectDispensaryEntities));
  }

  bankReviewDispensary(bankId: string, dispensaryId: string) {
    return this.store.dispatch(new ReviewDispensary({ bankId, dispensaryId }));
  }

  updateDispensaryBusinessDetails(dispensaryId: string, businessDetails: any) {
    return this.store.dispatch(new UpdateDispensaryBusinessDetails({ dispensaryId, businessDetails }));
  }

  updateDispensaryOperationalDetails(dispensaryId: string, operationalDetails: any) {
    return this.store.dispatch(new UpdateDispensaryOperationalDetails({ dispensaryId, operationalDetails }));
  }

  updateDispensaryAccountOwnersSectionComplete(dispensaryId: string, accountOwnersSectionComplete: any) {
    return this.store.dispatch(
      new UpdateDispensaryAccountOwnersSectionComplete({ dispensaryId, accountOwnersSectionComplete })
    );
  }

  updateDispensaryRequirement(dispensaryId: string, requirement: any) {
    return this.store.dispatch(new UpdateDispensaryRequirement({ dispensaryId, requirement }));
  }

  inviteDispensaryUser(dispensaryId: string, user: any) {
    return this.store.dispatch(new InviteDispensaryUser({ dispensaryId, user }));
  }

  updateDispensary(dispensary: any) {
    this.store.dispatch(new DispensaryLoaded(dispensary));
  }
}
