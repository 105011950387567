import React from 'react';

interface Props {
  height?: number;
  width?: number;
  style?: any;
  onClick?(event: React.MouseEvent<HTMLOrSVGElement>): void;
}

export const GreenPlusIcon = ({ height = 16, width = 16, style, onClick }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={style}
    data-cy="green-plus-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25046 3V7.28571H2.98535V8.71429H7.25046V13H8.67216V8.71429H12.9373V7.28571H8.67216V3H7.25046Z"
      fill="#02B45B"
    />
    <path
      d="M15.4231 8C15.4231 12.1444 12.0802 15.5 7.96154 15.5C3.84291 15.5 0.5 12.1444 0.5 8C0.5 3.85559 3.84291 0.5 7.96154 0.5C12.0802 0.5 15.4231 3.85559 15.4231 8Z"
      stroke="#02B45B"
    />
  </svg>
);
