import React, { useState, useEffect } from 'react';
import { api } from '../api';
import { GcvLoading, GcvInputSelectNew, GcvInputFile, GcvButton, GcvInputForm, GcvContent } from '../lib';
import { Bank } from '@gcv/shared';
import { environment } from 'apps/user-interface/src/environments/environment';
import { HorizontalCenter } from '../styles/theme';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { s3Util } from '../util/s3.util';

const Container = styled.div`
  margin: 0 1rem;
`;

const Header = styled.div`
  width: 288px;
  color: white;
  text-align: center;
  padding: 0.5rem 4rem;
  box-sizing: border-box;
  background-color: #37d890;
`;

const HeaderText = styled.h3`
  color: white;
  background-color: #37d890;
  font-size: 1.17em;
  font-weight: bold;
`;

const Block = styled.div`
  margin: 1rem;
`;

export const GcvEnterBankTheme = props => {
  const apiClient = api();
  const [loading, setLoading] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState<string>('');
  const [hexCode, setHexCode] = useState<string>('');
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [selectedBank, setSelectedBank] = useState<Bank>(null);

  const getBank = async () => {
    if (selectedBankId) {
      const bank = await apiClient.banks.getBankById(selectedBankId, () => {});
      setSelectedBank(bank);
    }
  };

  const checkFileDimensions = async () => {
    setError('');
    setLoading(true);

    const file = files[0];

    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.height !== img.width) {
        setError('icon needs to be square');
        setLoading(false);
      } else {
        onSubmit(file);
      }
    };
  };

  const onSubmit = async file => {
    const { s3_key } = await s3Util(apiClient).handleAddDocumentToS3(
      { blob: file, userType: 'gcv' },
      selectedBankId,
      undefined,
      `${environment.env}-gcv-shared-documents-file-bucket`
    );

    apiClient.banks
      .upsertBankTheme(selectedBankId, { color: hexCode, logo_s3_key: s3_key }, setLoading)
      .then(() => {
        alert('Successfully added theme');
      })
      .catch(e => {
        alert(`Error: ${e.message}`);
        console.log(e);
      });
  };

  return (
    <Container>
      <Header>
        <HeaderText>Enter Bank Theme</HeaderText>
      </Header>
      {loading ? (
        <GcvLoading></GcvLoading>
      ) : (
        <div>
          {selectedBank ? (
            <HorizontalCenter>
              <GcvContent type="h3" content={`You have selected: ${selectedBank.orgName}`} />
            </HorizontalCenter>
          ) : null}
          <Block>
            <TextField
              fullWidth
              label="Bank ID"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedBankId(e.target.value)}
              onBlur={getBank}
            />
          </Block>

          <Block>
            <TextField
              fullWidth
              label="Theme Color Hex Code"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHexCode(e.target.value)}
            />
          </Block>

          <Block>
            <GcvInputFile
              updateFileState={fileState => setFiles(fileState.allFiles)}
              acceptedTypes={['png', 'jpg', 'jpeg']}
              files={files}
              multiple={false}
            ></GcvInputFile>
          </Block>

          <Block>
            <HorizontalCenter>
              <GcvButton onClick={checkFileDimensions} disabled={!selectedBankId} primary={true}>
                Submit
              </GcvButton>
            </HorizontalCenter>
            {error ? (
              <HorizontalCenter style={{ color: 'red', marginTop: '1rem', fontSize: 10 }}>
                Error: {error}
              </HorizontalCenter>
            ) : null}
          </Block>
        </div>
      )}
    </Container>
  );
};
