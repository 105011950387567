export const FINCEN_FEATURE_MODULE_BLACKLIST = 'fincen-feature-module-blacklist';
const CREATE_NEW_FINCEN_REPORT = 'create-new-fincen-report';
export class FeatureFlags {
  static get FINCEN_FEATURE_MODULE_BLACKLIST() {
    return FINCEN_FEATURE_MODULE_BLACKLIST;
  }
  static get CREATE_NEW_FINCEN_REPORT() {
    return CREATE_NEW_FINCEN_REPORT;
  }
}
