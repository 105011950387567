import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface ErrorEvent {
  id: string;
  source: string;
  type: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorService {
  private errorEvent = new Subject<ErrorEvent>();
  currentError = this.errorEvent.asObservable();

  constructor() {}

  setErrorEvent(error: ErrorEvent) {
    this.errorEvent.next(error);
  }
}
