import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { v4 as uuid } from 'uuid';

import { useForm } from 'react-hook-form';
import { states } from 'libs/gcv-ui/src/lib/constants';
import {
  FormContent,
  Header,
  ModalLabel,
  ModalContent,
  PageFooter,
  Spacer,
  Tab,
  TabContainer,
  Title,
  List,
  ListItem,
} from './styles';
import { GcvButton, GcvInputForm, GcvInputSelect, GcvModal, GcvTabWizard } from '../../lib';
import { useApi } from '../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { MinifiedDispensary, Bank } from '@gcv/shared';
import { useHistory } from 'react-router-dom';
import { validateEmailAddress } from '../../util/validationHelpers';
import { api } from '../../api';

const AccountDetailsTab = ({ next, back, data }) => {
  const { handleSubmit, ...form } = useForm();
  const { watch } = form;
  const [formComplete, setFormComplete] = useState(false);
  const [invalidName, setInvalidName] = useState('');
  const [matchingNames, setMatchingNames] = useState([]);

  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setMatchingNames([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    const accountName = watch('accountName');
    const dispensaryNames = data.dispensaries.map(d => d.name);

    if (dispensaryNames.indexOf(accountName) > -1) {
      setInvalidName('An account with this name already exists');
      setFormComplete(false);
    } else {
      setInvalidName('');
      setFormComplete(watch('accountName') && watch('accountState'));
    }
  }, [watch('accountName'), watch('accountState')]);

  useEffect(() => {
    const accountName = watch('accountName');

    if (accountName === '') {
      setMatchingNames([]);
      return;
    }

    setMatchingNames(
      data.dispensaries
        .filter(d => d.name.toLowerCase().includes(accountName.toLowerCase()))
        .map(disp => {
          return disp.name;
        })
    );
  }, [watch('accountName')]);

  const options = states.map(s => {
    return { value: s.value, label: s.viewValue };
  });

  const onSubmit = payload => {
    next({ ...payload, accountState: payload.accountState.value });
  };
  const onBack = () => {
    back();
  };

  return (
    <>
      <TabContainer>
        <Tab>
          <h2>Account Details</h2>
          <form noValidate autoComplete="off">
            <FormContent>
              <GcvInputForm
                {...form}
                name={'accountName'}
                defaultValue={data['accountName']}
                rules={{ required: true }}
                label={'Account Name'}
                type="text"
              />
            </FormContent>
            {invalidName && (
              <div style={{ fontSize: '10px', padding: '2px 0px', color: 'red', height: '14px', marginTop: '-10px' }}>
                {invalidName}
              </div>
            )}
            {matchingNames.length > 0 && (
              <List ref={ref}>
                <ListItem>
                  <strong>Existing accounts with similar names:</strong>
                  <br />
                </ListItem>
                {matchingNames.map(n => {
                  return <ListItem key={uuid()}>{n}</ListItem>;
                })}
              </List>
            )}
            <FormContent style={{ margin: '25px 0px' }}>
              <GcvInputSelect
                {...form}
                name={'accountState'}
                label={'State'}
                options={options}
                rules={{ required: { value: true, message: 'is required' } }}
                defaultValue={options.find(o => o.value === data['accountState'])}
              />
            </FormContent>
          </form>
        </Tab>
      </TabContainer>

      <PageFooter>
        <GcvButton tertiary onClick={onBack}>
          Cancel
        </GcvButton>
        <Spacer />
        <GcvButton primary onClick={handleSubmit(onSubmit)} disabled={!formComplete}>
          Next
        </GcvButton>
      </PageFooter>
    </>
  );
};

const PrimaryContactTab = ({ next, back, data }) => {
  const { handleSubmit, ...form } = useForm();
  const { watch } = form;
  const [formComplete, setFormComplete] = useState(false);

  useEffect(() => {
    setFormComplete(watch('firstName') && watch('lastName') && watch('email') && validateEmailAddress(watch('email')));
  }, [watch('firstName') && watch('lastName') && watch('email')]);

  const onSubmit = payload => {
    next({ ...payload });
  };
  const onBack = () => {
    back();
  };

  return (
    <>
      <TabContainer>
        <Tab>
          <h2>Primary Contact</h2>
          <form noValidate autoComplete="off">
            <FormContent>
              <GcvInputForm
                {...form}
                name={'firstName'}
                defaultValue={data['firstName']}
                rules={{ required: true, maxLength: 30 }}
                label={'First Name'}
                type="text"
              />
            </FormContent>
            <FormContent>
              <GcvInputForm
                {...form}
                name={'lastName'}
                defaultValue={data['lastName']}
                rules={{ required: true, maxLength: 30 }}
                label={'Last Name'}
                type="text"
              />
            </FormContent>
            <FormContent>
              <GcvInputForm
                {...form}
                name={'email'}
                defaultValue={data['email']}
                rules={{
                  required: true,
                  maxLength: 60,
                  validate: {
                    future: value => validateEmailAddress(value) || 'Please enter a valid email address',
                  },
                }}
                label={'Email'}
                type="text"
              />
            </FormContent>
          </form>
        </Tab>
      </TabContainer>

      <PageFooter>
        <GcvButton tertiary onClick={onBack}>
          Back
        </GcvButton>
        <Spacer />
        <GcvButton primary onClick={handleSubmit(onSubmit)} disabled={!formComplete}>
          Review
        </GcvButton>
      </PageFooter>
    </>
  );
};

interface Data {
  accountName: string;
  accountState: string;
  firstName: string;
  lastName: string;
  email: string;
  dispensaries: MinifiedDispensary[];
}

export const AccountInvitePage: React.FC<{
  bank: Bank;
  dispensaries: MinifiedDispensary[];
  emitData: (type, data) => {};
}> = props => {
  const apiClient = api();
  const history = useHistory();
  const [inTransition, setInTransition] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Data>({
    accountName: '',
    accountState: '',
    firstName: '',
    lastName: '',
    email: '',
    dispensaries: props.dispensaries,
  });

  useEffect(() => {
    setInTransition(true);
  }, []);

  const tabs = [
    {
      component: AccountDetailsTab,
      name: 'Account Details',
    },
    {
      component: PrimaryContactTab,
      name: 'Primary Contact',
    },
  ];

  const updateData = newData => {
    setData({ ...data, ...newData });
  };

  const onComplete = () => {
    toggleModal();
  };

  const onCancel = () => {
    window.history.back();
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const inviteUser = async () => {
    setIsLoading(true);
    let historyState;

    await apiClient.banks
      .addDispensary(
        props.bank.id,
        {
          name: data.accountName.trim(),
          state: data.accountState,
          primaryContact: {
            firstName: data.firstName.trim(),
            lastName: data.lastName.trim(),
            email: data.email.trim(),
          },
        },
        () => {}
      )
      .then(response => {
        historyState = {
          snackBarMessage: `You've successfully invited ${data.accountName}!`,
          isError: false,
        };
        props.emitData('updateBank', response.bank);
        props.emitData('updateDispensary', response.dispensary);
      })
      .catch(e => {
        historyState = {
          snackBarMessage: `Could not invite account, please wait a moment and try again.`,
          isError: true,
        };
      })
      .finally(() => {
        toggleModal();
        setIsLoading(false);
        history.push({
          pathname: `/secure/bank/accounts/pending`,
          state: historyState,
        });
      });
  };

  return (
    <>
      <Header>
        <Title style={{ margin: 0, marginBottom: '1rem' }}>Invite Account</Title>
      </Header>

      <CSSTransition in={inTransition} timeout={300} classNames="slide" unmountOnExit>
        <GcvTabWizard tabs={tabs} updateData={updateData} onComplete={onComplete} onCancel={onCancel} data={data} />
      </CSSTransition>

      <GcvModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        backButton={
          <GcvButton tertiary onClick={toggleModal}>
            Back
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={inviteUser} primary={true}>
            Invite Account
          </GcvButton>
        }
        title={'Confirm Account Information'}
        loading={isLoading}
      >
        <ModalContent>
          <div style={{ padding: '0 2rem' }}>
            <ModalLabel>Account Name</ModalLabel>
            {data.accountName}
            <br />
            <br />

            <ModalLabel>State</ModalLabel>
            {data.accountState}
            <br />
            <br />

            <ModalLabel>First Name</ModalLabel>
            {data.firstName}
            <br />
            <br />

            <ModalLabel>Last Name</ModalLabel>
            {data.lastName}
            <br />
            <br />

            <ModalLabel>Email</ModalLabel>
            {data.email}
            <br />
            <br />
          </div>

          <p>This user will receive an email inviting them to this account.</p>
        </ModalContent>
      </GcvModal>
    </>
  );
};
