import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { RoleResolverService } from '../../services/role-resolver.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as base64arraybuffer from 'base64-arraybuffer';
import { openImageInPdfView } from '../../shared/io.util';
const filetype = require('file-type');

@Component({
  selector: 'app-review-multiple-documents-modal',
  templateUrl: './review-multiple-documents-modal.component.html',
  styleUrls: ['./review-multiple-documents-modal.component.scss'],
})
export class ReviewMultipleDocumentsModalComponent implements OnInit {
  @ViewChild('pdfView', { static: true })
  pdfView: ElementRef;
  documentName = '';
  isDispensary = false;
  docData;
  approved = false;
  docDescription = '';
  statusSelected = 'approved';
  type: string;
  index = 0;

  constructor(
    private roleResolver: RoleResolverService,
    private matDialogRef: MatDialogRef<ReviewMultipleDocumentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.docData = data;
    this.docDescription = data.docDescription ? data.docDescription : '';
    this.isDispensary = data.isDispensary;
  }

  ngOnInit() {
    this.documentName = this.docData.documentName;
    if (this.docData.status === 'approved') {
      this.approved = true;
    }
    if (this.docData.documentData.length > 0) {
      this.displayDocument();
    }
  }

  displayDocument() {
    const blob = this.docData.documentData[this.index];
    openImageInPdfView(blob, this.pdfView);
  }

  nextDocument() {
    if (this.index + 1 <= this.docData.documentData.length - 1) {
      this.index++;
      this.displayDocument();
    }
  }

  prevDocument() {
    if (this.index - 1 >= 0) {
      this.index--;
      this.displayDocument();
    }
  }
  cancel() {
    this.matDialogRef.close();
  }

  submit() {
    if (!this.isDispensary) {
      const results = {
        documentName: this.documentName,
        status: this.statusSelected,
      };

      this.documentName = '';
      this.matDialogRef.close({
        data: results,
      });
    }
  }

  isDisabled() {
    return this.roleResolver.checkPermission(['account_info_update']);
  }
}
