import { DateTime } from 'luxon';
import React from 'react';
import { formatMoney, formatStatusIndicator } from '../../util';
import { GcvLoading } from '../../lib/';

export const columns = clickHandler => [
  {
    name: 'Account',
    selector: 'account',
    sortable: true,
    grow: 2,
    left: true,
  },
  {
    name: 'Deposit Amount',
    selector: 'amount',
    sortable: true,
    left: true,
    format: row => formatMoney(row['amount']),
  },
  {
    name: 'Method of Transportation',
    selector: 'methodOfTransport',
    sortable: true,
    left: true,
  },
  {
    name: 'Anticipated Arrival',
    selector: 'plannedArrivalDate',
    sortable: true,
    left: true,
    format: row => DateTime.fromISO(row.plannedArrivalDate).toLocaleString(DateTime.DATE_MED),
  },
  {
    name: 'Arrived',
    selector: 'arrived_date',
    sortable: true,
    left: true,
    format: row => (row.arrived_date ? DateTime.fromISO(row.arrived_date).toLocaleString(DateTime.DATE_MED) : ''),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    left: true,
    format: row =>
      row.reconciling ? (
        <GcvLoading style={{ height: 'auto' }} small={true}></GcvLoading>
      ) : (
        formatStatusIndicator(row, clickHandler)
      ),
  },
];
