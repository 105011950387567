import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNamePipe',
})
export class FileNamePipe implements PipeTransform {
  transform(fileName: string): any {
    const maxLength = 28;
    const ellipsis = '...';
    const endingLength = 5;
    try {
      if (fileName && fileName.length > maxLength) {
        const [name, extension] = fileName.split('.');
        if (extension) {
          const remainingLength = maxLength - extension.length - ellipsis.length;
          const truncatedNameBeginning = name.slice(0, remainingLength - endingLength - 1);
          const truncatedNameEnding = name.slice(name.length - endingLength, name.length);
          return truncatedNameBeginning + ellipsis + truncatedNameEnding + '.' + extension;
        } else {
          return fileName.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
      } else {
        return fileName;
      }
    } catch (error) {
      console.log('error in FileNamePipe: ', error);
      return fileName;
    }
  }
}
