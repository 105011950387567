import { DataPersistence } from '../../utils/data.persistance';
import { SalesFileHash, SalesFileState } from './salesFile.reducer';
import { Effect } from '@ngrx/effects';
import { CheckSalesFileHash, SalesFileActionTypes, SalesFileHashChecked, SyncSales } from './salesFile.actions';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';

@Injectable({
  providedIn: 'root',
})
export class SalesFileEffect {
  @Effect() checkSalesFileHash$ = this.dataPersistance.fetch(SalesFileActionTypes.CheckSalesFileHash, {
    run: (action: CheckSalesFileHash, state: SalesFileState) => {
      return this.sdk.salesFileHashHashGet(action.payload.hash).pipe(
        map(
          (res: SalesFileHash) =>
            new SalesFileHashChecked({
              data_hash: action.payload.hash,
              isDuplicate: !!res.data_hash,
            })
        )
      );
    },
    onError: err => {
      return new AddError({ action: SalesFileActionTypes.CheckSalesFileHash, message: err });
    },
  });

  @Effect({ dispatch: false }) syncSales$ = this.dataPersistance.fetch(SalesFileActionTypes.SyncSales, {
    run: (action: SyncSales, state: SalesFileState) => {
      return this.sdk.dispensariesIdSalesSyncPut(action.payload.id, {});
    },
    onError: err => {
      return new AddError({ action: SalesFileActionTypes.SyncSales, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<SalesFileState>, private sdk: DefaultService) {}
}
