<div class="wrapper" data-cy="create-group-dialog.component">
  <div class="closeWrapper">
    <svg (click)="cancel()" height="32" version="1" width="32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle cx="15" cy="15" r="15" stroke="#DBE0EB" transform="translate(1 1)" />
        <path
          d="M19 12l-3 3-3-3a1 1 0 0 0-1 1l3 3-3 3v2h1l3-3 3 3a1 1 0 0 0 2-2l-3-3 3-3v-1h-2z"
          fill="#A5A8BA"
          stroke="#FFF"
        />
      </g>
    </svg>
  </div>
  <div class="header">
    <div class="titleWrapper">
      <span>Create a Group</span>
    </div>
  </div>
  <div class="inputWrapper">
    <gcv-mat-input
      [errors]="[]"
      [group]="groupDetails"
      [validators]="[]"
      control="name"
      data-cy="create-group-dialog.component.groupDetails"
      label="What will the group name be?"
      required="true"
      type="text"
      type="text"
    >
    </gcv-mat-input>
    <form [ngStyle]="{ width: '100%' }" class="filter-field">
      <mat-form-field [ngStyle]="{ width: '100%' }" appearance="standard">
        <mat-label>Who will be added to this group?</mat-label>
        <input
          (input)="filterItems()"
          [(ngModel)]="filterText"
          data-cy="create-group-dialog.component.filterName"
          matInput
          maxlength="100"
          name="filter"
          type="text"
        />
        <svg height="21.069" matSuffix viewBox="0 0 20.894 21.069" width="20.894" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.66,20.652,13.9,15.893a8.785,8.785,0,1,1,1.9-1.849l4.734,4.734a1.325,1.325,0,1,1-1.873,1.873ZM6.349,2.954A6.313,6.313,0,1,0,8.8,2.459a6.313,6.313,0,0,0-2.449.494Z"
            fill="#a5a8ba"
            transform="translate(-0.027 0.029)"
          />
        </svg>
      </mat-form-field>
    </form>
  </div>
  <div class="userWrapper">
    <div *ngFor="let user of filteredUsers; let i = index" class="user" data-cy="create-group-dialog.component.user">
      <gcv-initial-logo [firstName]="user.firstName" [lastName]="user.lastName"></gcv-initial-logo>
      <span [class.userActive]="selectedMap[user.id]" class="userName" data-cy="create-group-dialog.component.userName"
        >{{ user.firstName }} {{ user.lastName }}</span
      >
      <span class="userRole">{{ formatRole(user.role) }}</span>
      <mat-checkbox
        (change)="addUser(user, i)"
        [checked]="selectedMap[user.id]"
        color="primary"
        data-cy="create-group-dialog.component.userCheckbox"
      ></mat-checkbox>
    </div>
  </div>
  <span class="userCount" data-cy="create-group-dialog.component.userCount">{{ selectedCount() }} users selected</span>

  <div class="btnWrapper">
    <gcv-button
      (clickEvent)="cancel()"
      [buttonText]="selectedCount() > 0 ? 'Close Without Saving' : 'Cancel'"
      data-cy="create-group-dialog.component.cancelButton"
      type="tertiary"
    ></gcv-button>
    <gcv-button
      (clickEvent)="handleSaveGroup()"
      [disabled]="selectedCount() === 0 || !groupDetails.valid"
      buttonText="Save"
      data-cy="create-group-dialog.component.saveButton"
      type="primary"
    ></gcv-button>
  </div>
</div>
