import { Action } from '@ngrx/store';
import { User } from '@gcv/shared';

export enum UserActionTypes {
  LoginUser = '[User] Login User',
  LoadUser = '[User] Load User',
  UserLoaded = '[User] User Loaded',

  UpdateUser = '[User] Update User',
  UserUpdated = '[User] User Updated',

  LoadUserKba = '[User] Load User KBA',
  UserKbaLoaded = '[User] User Kba Loaded',

  VerifyUserKbaAnswers = '[User] Verify User Kba Answers',
  UserKbaAnswersVerified = '[User] User Kba Answers Verified',

  UpdateUserDocuments = '[User] Upload User Documents',
  UpdateUserIdentification = '[User] Upload User Identification',

  ResetUserKba = '[User] Reset User Kba',
  ResetUserState = '[User] Reset User State',

  DeleteUser = '[User] Delete User',
  UserDeleted = '[User] User Deleted',
}

export class LoadUser implements Action {
  readonly type = UserActionTypes.LoadUser;

  constructor(public payload: { userId: string; initialLogin?: Boolean; authUser?: any }) {}
}

export class UpdateUserDocuments implements Action {
  readonly type = UserActionTypes.UpdateUserDocuments;

  constructor(public payload: { userId: string }) {}
}

export class LoadUserKba implements Action {
  readonly type = UserActionTypes.LoadUserKba;

  constructor(public payload: { userId: string }) {}
}

export class UserKbaLoaded implements Action {
  readonly type = UserActionTypes.UserKbaLoaded;

  constructor(public payload: any) {}
}

export class VerifyUserKbaAnswers implements Action {
  readonly type = UserActionTypes.VerifyUserKbaAnswers;

  constructor(public payload: { userId: string }) {}
}

export class UserKbaAnswersVerified implements Action {
  readonly type = UserActionTypes.UserKbaAnswersVerified;

  constructor(public payload: User) {}
}

export class UserLoaded implements Action {
  readonly type = UserActionTypes.UserLoaded;

  constructor(public payload: User) {}
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;

  constructor(public payload: User) {}
}

export class UserUpdated implements Action {
  readonly type = UserActionTypes.UserUpdated;

  constructor(public payload: User) {}
}

export class ResetUser implements Action {
  readonly type = UserActionTypes.ResetUserState;
}

export class ResetUserKba implements Action {
  readonly type = UserActionTypes.ResetUserKba;
}

export class DeleteUser implements Action {
  readonly type = UserActionTypes.DeleteUser;

  constructor(public payload: { userId: string }) {}
}

export class UserDeleted implements Action {
  readonly type = UserActionTypes.UserDeleted;

  constructor(public payload: { userId: string }) {}
}

export class UpdateUserIdentification implements Action {
  readonly type = UserActionTypes.UpdateUserIdentification;

  constructor(public payload: { userId: string; identificationDetails: any }) {}
}

export type UserActions =
  | LoadUser
  | UserLoaded
  | UpdateUser
  | UserUpdated
  | ResetUser
  | LoadUserKba
  | VerifyUserKbaAnswers
  | UserKbaLoaded
  | UserKbaAnswersVerified
  | UpdateUserDocuments
  | UpdateUserIdentification
  | ResetUserKba
  | DeleteUser
  | UserDeleted;
