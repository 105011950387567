import React, { useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { $fontFamily } from '../../../util/styleVariables';

// customize style of react-modal
const customModalStyles = {
  overlay: {
    zIndex: 5,
    background: 'rgba(177, 178, 181, 0.7)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    width: '500px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    borderRadius: '3px',
    padding: '38px 45px',
    overflow: 'visible',
  },
};

const ExportActivityModalBody = styled.div`
  font-family: ${$fontFamily};
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #16181e;
  }

  h5 {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-top: 25px;
  }

  .button-container {
    text-align: center;
    margin-top: 25px;
  }
`;

interface Props {
  type?: boolean;
  active?: boolean;
  toggleModal: () => void;
  buttonComponent?: React.ReactNode;
}

const styles = {
  ...customModalStyles,
  content: { ...customModalStyles.content, width: '456px', height: '268px' },
};

export const success = {
  header: 'Your file is on its way',
  body:
    'Your file is being prepared now, When it’s ready, a link to your file will appear in your notifications. You’ll also receive a confirmation email.',
};
export const failure = {
  header: 'Oops, something went wrong',
  body: 'Please try the export again. For help, email us at support@greencheckverified.com.',
};

export const ExportActivityResponseModal = (props: Props) => {
  useEffect(() => {
    Modal.setAppElement('#modal-root');
  }, []);

  const header = props.type ? success.header : failure.header;
  const body = props.type ? success.body : failure.body;

  return (
    <Modal style={styles} isOpen={props.active} onRequestClose={props.toggleModal}>
      <ExportActivityModalBody>
        <h2>{header}</h2>
        <p>{body}</p>

        <div className="button-container">{props.buttonComponent}</div>
      </ExportActivityModalBody>
    </Modal>
  );
};
