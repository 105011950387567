import React, { ReactNode, useState } from 'react';

import { Dispensary, User } from '@gcv/shared';
import { useForm } from 'react-hook-form';

import { GcvUserInputContainer, GcvDrawer, GcvButton, GcvReadMore, dateFormatterFromISO } from '../../../lib';
import { formatISOToDateAtTime } from '../../../util/dateTimeHelpers';
import { BackButtonIcon } from '../../../icons/BackButtonIcon';
import { DocumentIcon } from '../../../icons/DocumentIcon';
import { RequirementMetadata, Document, DocumentMetadata } from '../types';
import { formatUserFirstAndLastName } from '../../../util/formatHelpers';

import {
  Header,
  SubTitle,
  HeaderContent,
  Block,
  Body,
  SubContent,
  VerticalCenter,
  HorizontalCenter,
  DrawerContent,
  ViewFile,
} from './styles';
import { GcvInputTextArea } from '../../../lib/GcvInputTextArea/GcvInputTextArea';
import { s3Util } from 'libs/react-ui/src/util/s3.util';
import { useApi } from 'libs/react-ui/src/hooks';
import { api } from 'libs/react-ui/src/api';

interface DocumentDrawerProps {
  onClose: () => void;
  document: Document;
  dispensary: Dispensary;
  open: boolean;
  userMap: {
    [id: string]: User;
  };
  documentType: 'license' | 'document';
  documentMetadata: DocumentMetadata;
  requirementMetadata: RequirementMetadata;
  header: ReactNode;
  children?: ReactNode;
}

export const DocumentDrawer = ({
  onClose,
  dispensary,
  documentMetadata,
  document,
  open,
  userMap,
  requirementMetadata,
  documentType,
  header,
  children,
}: DocumentDrawerProps) => {
  const apiClient = api();

  if (!open) {
    return <GcvDrawer open={false} onClose={onClose} />;
  }

  const handleViewFile = () => {
    const s3 = s3Util(apiClient);
    s3.viewDocument(document);
  };

  const uploadedByUsername = formatUserFirstAndLastName(userMap[document.uploaded_by]);

  return (
    <GcvDrawer open={open} onClose={onClose} style={{ width: '482px' }}>
      <DrawerContent data-cy="document-drawer">
        <Header>
          <div>
            <BackButtonIcon onClick={onClose} />
            <HeaderContent>
              File Details
              <SubTitle style={{ marginTop: '0px' }}>{dispensary.name}</SubTitle>
            </HeaderContent>
          </div>
          {header}
        </Header>
        <Body>
          <HorizontalCenter>
            <VerticalCenter style={{ cursor: 'pointer' }}>
              <DocumentIcon onClick={handleViewFile} />
              <ViewFile onClick={handleViewFile}>View File</ViewFile>
            </VerticalCenter>
          </HorizontalCenter>

          <SubTitle>File Name</SubTitle>
          <SubContent>
            <GcvUserInputContainer string={document.file_name} length={40} type="document" />
          </SubContent>

          <SubTitle>Uploaded On</SubTitle>
          <SubContent>{formatISOToDateAtTime(document.upload_date)}</SubContent>

          <SubTitle>Uploaded By</SubTitle>
          <SubContent>
            <GcvUserInputContainer string={document.uploaded_by ? uploadedByUsername : ''} length={40} />
          </SubContent>
          {documentType === 'license' ? (
            <>
              <SubTitle>{requirementMetadata.id === 'business_licenses' ? 'License Type' : 'Employee Name'}</SubTitle>
              <SubContent>{documentMetadata.license_name}</SubContent>

              <SubTitle>License Number</SubTitle>
              <SubContent>{documentMetadata.license_number}</SubContent>

              <SubTitle>Expiration Date</SubTitle>
              <SubContent>{dateFormatterFromISO(documentMetadata.expiration_date)}</SubContent>
            </>
          ) : null}

          <SubTitle>Requirement</SubTitle>
          <SubContent>
            <GcvUserInputContainer string={requirementMetadata.name} length={40} />
          </SubContent>

          {children}
        </Body>
      </DrawerContent>
    </GcvDrawer>
  );
};
