import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const dueDiligenceTemplatesClient = (API: APIClass) => {
  return {
    getLatestDueDiligenceTemplate: async (templateId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/due-diligence/templates/${templateId}/latest`, setIsLoading);
    },

    getAllDueDiligenceTemplateVersions: async (templateId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/due-diligence/templates/${templateId}`, setIsLoading);
    },

    createDueDiligenceTemplate: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/due-diligence/templates`, body, setIsLoading);
    },

    updateDueDiligenceTemplate: async (
      templateId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/due-diligence/templates/${templateId}`, body, setIsLoading);
    },
  };
};
