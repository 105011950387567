import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';

import { GcvInputForm, dateFormatterToISO, GcvButton, GcvInputSelect } from '../../../../lib';

import { Tab, Buttons, Label, FormContent, TabContainer } from './styles';

const options = {
  ctr: [
    { value: 'initialReport', label: 'Initial Report' },
    { value: 'backFiling', label: 'FinCEN Directed Backfiling' },
    { value: 'priorReport', label: 'Correct/Amend Prior Report' },
  ],
  sar: [
    { value: 'initialReport', label: 'Initial Report' },
    { value: 'continuingReport', label: 'Continuing Activity Report' },
    { value: 'priorReport', label: 'Correct/Amend Prior Report' },
    { value: 'priorContinuingReport', label: 'Continuing Activity Report + Correct/Amend Prior Report' },
  ],
};

export const Tab1 = ({ next, back, data }) => {
  const { handleSubmit, ...form } = useForm();
  const { watch } = form;
  const [formComplete, setFormComplete] = useState(false);
  const [generateReport, setGenerateReport] = useState(
    data.type === 'sar' && data.generateEvery90Days === undefined ? true : data.generateEvery90Days
  );

  const handleGenerateReportYesClick = () => {
    setGenerateReport(true);
  };
  const handleGenerateReportNoClick = () => {
    setGenerateReport(false);
  };

  const onSubmit = payload => {
    next({ generateEvery90Days: generateReport, ...payload });
  };
  const onBack = () => {
    back();
  };

  const currentType = watch('filingType')
    ? watch('filingType').value === 'priorReport' ||
      watch('filingType').value === 'continuingReport' ||
      watch('filingType').value === 'priorContinuingReport'
    : false;
  const prevSelectedType = data.filingType
    ? data.filingType.value === 'continuingReport' ||
      data.filingType.value === 'priorReport' ||
      data.filingType.value === 'priorContinuingReport'
    : false;
  const bsaIdEnabled = currentType || prevSelectedType;
  const completeBsaId = bsaIdEnabled ? watch('bsaID') : true;

  useEffect(() => {
    setFormComplete(watch('startDate') && watch('filingName') && watch('filingType') && completeBsaId);
  }, [watch('startDate'), watch('filingName'), watch('filingType'), completeBsaId]);

  return (
    <>
      <TabContainer>
        <Tab>
          <h1>Report Information</h1>
          <FormContent data-cy="activity-start-date">
            <GcvInputForm
              {...form}
              name={'startDate'}
              defaultValue={data['startDate']}
              rules={{
                required: true,
                validate: {
                  future: value =>
                    dateFormatterToISO(value) < DateTime.local().toISO() || "can't input a date in the future",
                },
                minLength: 10,
              }}
              label={'Activity Start Date (mm/dd/yyyy)'}
              type="date"
            />
          </FormContent>
          <FormContent data-cy="filing-name">
            <GcvInputForm
              {...form}
              name={'filingName'}
              defaultValue={data['filingName']}
              rules={{ required: true, maxLength: 30 }}
              label={'Filing Name'}
              type="text"
            />
          </FormContent>
          <FormContent style={{ margin: '25px 0px' }} data-cy="filing-type">
            <GcvInputSelect
              {...form}
              name={'filingType'}
              label={'Filing Type *'}
              options={options[data.type]}
              rules={{ required: { value: true, message: 'is required' } }}
              defaultValue={data['filingType']}
            />
          </FormContent>

          {bsaIdEnabled && (
            <FormContent data-cy="prior-bsa-id">
              <GcvInputForm
                {...form}
                name={'bsaID'}
                defaultValue={data['bsaID']}
                rules={{
                  required: true,
                  maxLength: 14,
                  minLength: 14,
                  pattern: { value: /^\d+$/, message: 'must contain only numbers' },
                }}
                label={'Prior Report BSA ID'}
              />
            </FormContent>
          )}

          {data.type === 'sar' ? (
            <Label data-cy="continuing-activity">
              <span>Generate a Continuing Activity Report in 90 Days? *</span>
              <span>
                <label>
                  <input
                    type="radio"
                    checked={generateReport}
                    onChange={handleGenerateReportYesClick}
                    data-cy="continuing-activity-yes"
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    checked={!generateReport}
                    onChange={handleGenerateReportNoClick}
                    data-cy="continuing-activity-no"
                  />
                  No
                </label>
              </span>
            </Label>
          ) : null}
        </Tab>
      </TabContainer>

      <Buttons>
        <GcvButton onClick={onBack} tertiary={true} data-cy="back-button">
          Cancel
        </GcvButton>
        <GcvButton onClick={handleSubmit(onSubmit)} primary={true} disabled={!formComplete} data-cy="next-button">
          Next
        </GcvButton>
      </Buttons>
    </>
  );
};
