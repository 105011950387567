import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gcv-due-diligence-details-dialog',
  templateUrl: './due-diligence-details-dialog.component.html',
  styleUrls: ['./due-diligence-details-dialog.component.scss'],
})
export class DueDiligenceDetailsDialogComponent {
  description = '';
  name = '';
  type: 'operational' | 'legal' | 'financial';

  constructor(
    private matDialogRef: MatDialogRef<DueDiligenceDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.description = data.description;
    this.type = data.type;
    this.name = data.name;
  }

  close() {
    this.matDialogRef.close();
  }
}
