import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const gcvClient = (API: APIClass) => {
  return {
    updateSalesAggregate: async (
      dispensaryId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/gcv/dispensary/${dispensaryId}/salesAggregate`, body, setIsLoading);
    },

    dataReset: async (setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/gcv/tools/data/reset`, setIsLoading);
    },

    posFaker: async (setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/gcv/faker`, setIsLoading);
    },

    migrateDispensaryDocuments: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/gcv/migrate-documents`, body, setIsLoading);
    },

    updateDepositGcv: async (
      dispensaryId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/gcv/dispensaries/${dispensaryId}/deposits`, body, setIsLoading);
    },

    deleteDeposit: async (dispensaryId: string, depositId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/gcv/dispensaries/${dispensaryId}/deposits/${depositId}`, setIsLoading);
    },

    generateFincenXml: async (
      body: { bankId: string; selectedReportIds: string[] },
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/gcv/fincen/xml`, body, setIsLoading);
    },

    manuallyTriggerSalesIngestion: async (
      dispId: string,
      body: { start_date: string; end_date: string },
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/gcv/dispensary/${dispId}/ingest-sales`, body, setIsLoading);
    },
  };
};
