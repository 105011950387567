import React, { useEffect, useState } from 'react';
import { GcvTimePeriodDropdown, GcvSearchDropDown, GcvZeroState, GcvCard, GcvLoading } from 'libs/react-ui/src/lib';
import { DateTime } from 'luxon';
import calculateTimeRange from '../../../util/calculateDateRange';
import { useApi } from 'libs/react-ui/src/hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import {
  MinifiedDispensary,
  DispensaryDepositOverview,
  DueDiligenceStatus,
  DispensaryDailySummaryOverview,
  Bank,
} from '@gcv/shared';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, Text, Label } from 'recharts';
import { $primary, $grey2 } from 'libs/react-ui/src/util/styleVariables';
import {
  ChartContainer,
  GreyTitle,
  BlackTitle,
  GreyText,
  SummaryLineContainer,
  LoadingContainer,
  Percentage,
  ToolTipContainer,
} from './styles';
import { cropInput, formatMoney, formatPercentage } from 'libs/react-ui/src/util/formatHelpers';
import { api } from 'libs/react-ui/src/api';
import { Snackbar } from '@material-ui/core';
interface Props {
  bank: Bank;
  dispensaries: { [id: string]: MinifiedDispensary };
}

interface Option {
  value: string;
  label: string;
}

interface DispensarySalesMap {
  [id: string]: DispensaryDailySummaryOverview;
}
interface DispensaryDepositsMap {
  [id: string]: DispensaryDepositOverview;
}

export const Overview = (props: Props) => {
  const [sales, setSales] = useState<DispensarySalesMap>(null);
  const [deposits, setDeposits] = useState<DispensaryDepositsMap>(null);
  const [timePeriod, setTimePeriod] = useState(calculateTimeRange('last30Days', props.bank.iana_timezone));
  const [loading, setLoading] = useState(true);
  const [distinctAccounts, setDistinctAccounts] = useState<Option[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState<string[]>([]);
  const [snackBar, setSnackBar] = useState(null);
  const apiClient = api();

  const fetchDashboardData = async () => {
    try {
      const startDay = DateTime.fromISO(timePeriod.start).toFormat('yyyy-MM-dd');
      const endDay = DateTime.fromISO(timePeriod.end).toFormat('yyyy-MM-dd');
      const {
        sales,
        deposits,
      }: {
        sales: DispensarySalesMap;
        deposits: DispensaryDepositsMap;
      } = await apiClient.banks.getOverviewDailySummary(props.bank.id, startDay, endDay, () => {});

      setSales(sales);
      setDeposits(deposits);
    } catch (e) {
      setSnackBar({ message: 'Unable to get dashboard data' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.bank.id) {
      fetchDashboardData();
    }
  }, [props.bank.id, timePeriod]);

  useEffect(() => {
    if (props.dispensaries) {
      const dispensaryOptions = Object.values(props.dispensaries)
        .filter(disp => disp.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED)
        .map((d: any) => {
          return { value: d.id, label: d.name };
        });
      setDistinctAccounts(dispensaryOptions);
    }
  }, [props.dispensaries]);

  const handleTimeChange = results => {
    const timeRange = calculateTimeRange(results.value, props.bank.iana_timezone);
    setLoading(true);
    setTimePeriod(timeRange);
  };

  const handleFilterAccounts = results => {
    setFilteredAccounts(results);
  };

  const { chartData, depositData, salesData } = filterAndFormatData(
    filteredAccounts,
    props.dispensaries,
    sales,
    deposits,
    timePeriod
  );

  const CustomizedAxisTick = props => {
    const { x, y, payload } = props;
    return (
      <Text x={x} y={y} textAnchor="end" verticalAnchor="end" angle={-45} width={200} style={{ fontSize: 12 }}>
        {chartData.length >= 30 ? null : cropInput(payload.value, 25)}
      </Text>
    );
  };

  const CustomTooltip = props => {
    const { active, payload, label } = props;
    if (active) {
      const { total, uncheckable_total, unverified_total, verified_total, dispensary_name } = payload[0]
        .payload as ChartData;
      return (
        <ToolTipContainer>
          <GreyTitle>{dispensary_name}</GreyTitle>
          <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
            <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
              {formatMoney(verified_total)}
              <Percentage>({formatPercentage(verified_total, total)})</Percentage>
            </BlackTitle>
            <GreyText>Verified</GreyText>
          </SummaryLineContainer>
          <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
            <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
              {formatMoney(unverified_total)}
              <Percentage>({formatPercentage(unverified_total, total)})</Percentage>
            </BlackTitle>
            <GreyText>Unverified</GreyText>
          </SummaryLineContainer>
          <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
            <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
              {formatMoney(uncheckable_total)}
              <Percentage>({formatPercentage(uncheckable_total, total)})</Percentage>
            </BlackTitle>
            <GreyText>Unchecked</GreyText>
          </SummaryLineContainer>
          <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex', borderBottom: '0' }}>
            <BlackTitle style={{ fontSize: '16px' }}>{formatMoney(total)}</BlackTitle>
            <GreyText style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</GreyText>
          </SummaryLineContainer>
        </ToolTipContainer>
      );
    }

    return null;
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!snackBar}
        autoHideDuration={4000}
        onClose={() => setSnackBar(null)}
        message={snackBar ? snackBar.message : ''}
      />
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '1rem' }}>
          <GcvSearchDropDown
            distinctItems={distinctAccounts}
            searchText={'Search Accounts'}
            itemLabel={'Account(s)'}
            filterReport={handleFilterAccounts}
          />
        </div>

        <GcvTimePeriodDropdown
          emitData={handleTimeChange}
          labelText={'Time Period'}
          newStyle={true}
          defaultValueDrop={'last30Days'}
        ></GcvTimePeriodDropdown>
      </div>
      {loading ? (
        <LoadingContainer>
          <GcvLoading></GcvLoading>
        </LoadingContainer>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <GcvCard style={{ width: '70%', margin: '1rem 1rem 1rem 0' }}>
            <GcvCard.Header
              title={
                <div style={{ marginBottom: '1rem', fontSize: '18px' }}>
                  <div>Total sales</div>
                  <div style={{ fontWeight: 'normal', color: $grey2, fontSize: '14px', marginTop: '.5rem' }}>
                    {DateTime.fromISO(timePeriod.start).toLocaleString(DateTime.DATE_MED)}
                    {DateTime.fromISO(timePeriod.start).toLocaleString(DateTime.DATE_MED) !==
                    DateTime.fromISO(timePeriod.end).toLocaleString(DateTime.DATE_MED)
                      ? ' - ' + DateTime.fromISO(timePeriod.end).toLocaleString(DateTime.DATE_MED)
                      : null}
                  </div>
                </div>
              }
              style={{ borderBottom: 'none' }}
            ></GcvCard.Header>
            <GcvCard.Body>
              {chartData.length ? (
                <ChartContainer>
                  <ResponsiveContainer width="100%" height={450}>
                    <BarChart data={chartData} margin={{ top: 15, right: 30, left: 20, bottom: 0 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="dispensary_name"
                        tick={<CustomizedAxisTick />}
                        height={120}
                        interval={0}
                        minTickGap={1}
                      ></XAxis>
                      <YAxis width={100} tickFormatter={tick => formatMoney(tick)} />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="total" fill={$primary} />
                    </BarChart>
                  </ResponsiveContainer>
                </ChartContainer>
              ) : (
                <GcvZeroState type="basic" />
              )}
            </GcvCard.Body>
          </GcvCard>
          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', margin: '1rem 1rem 1rem 0' }}>
            <GcvCard>
              <GcvCard.Header
                title={
                  <div style={{ marginBottom: '1rem', fontSize: '18px' }}>
                    <div>Summary</div>
                    <div style={{ fontWeight: 'normal', color: $grey2, fontSize: '14px', marginTop: '.5rem' }}>
                      {DateTime.fromISO(timePeriod.start).toLocaleString(DateTime.DATE_MED)}
                      {DateTime.fromISO(timePeriod.start).toLocaleString(DateTime.DATE_MED) !==
                      DateTime.fromISO(timePeriod.end).toLocaleString(DateTime.DATE_MED)
                        ? ' - ' + DateTime.fromISO(timePeriod.end).toLocaleString(DateTime.DATE_MED)
                        : null}
                    </div>
                  </div>
                }
                style={{ borderBottom: 'none' }}
              ></GcvCard.Header>
              <GcvCard.Body style={{ padding: '8px' }}>
                <GreyTitle>Sales</GreyTitle>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(salesData.verified)}
                    <Percentage>({formatPercentage(salesData.verified, salesData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Verified</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(salesData.unverified)}
                    <Percentage>({formatPercentage(salesData.unverified, salesData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Unverified</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(salesData.uncheckable)}
                    <Percentage>({formatPercentage(salesData.uncheckable, salesData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Unchecked</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex', borderBottom: '0' }}>
                  <BlackTitle style={{ fontSize: '16px' }}>
                    {formatMoney(salesData.uncheckable + salesData.unverified + salesData.verified)}
                  </BlackTitle>
                  <GreyText style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</GreyText>
                </SummaryLineContainer>
                <GreyTitle style={{ marginTop: '2rem' }}>Deposits</GreyTitle>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(depositData.reconciled)}
                    <Percentage>({formatPercentage(depositData.reconciled, depositData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Reconciled</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(depositData.pending)}
                    <Percentage>({formatPercentage(depositData.pending, depositData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Pending</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <BlackTitle style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {formatMoney(depositData.under_review)}
                    <Percentage>({formatPercentage(depositData.under_review, depositData.total)})</Percentage>
                  </BlackTitle>
                  <GreyText>Under Review</GreyText>
                </SummaryLineContainer>
                <SummaryLineContainer style={{ justifyContent: 'space-between', display: 'flex', borderBottom: '0' }}>
                  <BlackTitle style={{ fontSize: '16px' }}>{formatMoney(depositData.total)}</BlackTitle>
                  <GreyText style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</GreyText>
                </SummaryLineContainer>
              </GcvCard.Body>
            </GcvCard>
          </div>
        </div>
      )}
    </>
  );
};

interface ChartData {
  total: number;
  dispensary_name: string;
  unverified_total: number;
  verified_total: number;
  uncheckable_total: number;
}

const filterAndFormatData = (
  accountsToInclude: string[],
  dispensaries: { [id: string]: MinifiedDispensary },
  sales: DispensarySalesMap,
  deposits: DispensaryDepositsMap,
  timePeriod
) => {
  const dispensariesWithSalesTotals: {
    [id: string]: ChartData;
  } =
    dispensaries && sales
      ? Object.values(dispensaries)
          .filter(disp => disp.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED)
          .filter(disp => accountsToInclude.includes(disp.id))
          .reduce((acc, disp) => {
            acc[disp.id] = sales[disp.id];
            return acc;
          }, {})
      : {};

  const depositData: { reconciled?: number; pending?: number; total?: number; under_review?: number } = deposits
    ? Object.keys(deposits)
        .filter(dispId => accountsToInclude.includes(dispId))
        .reduce(
          (acc, dispId) => {
            const dep = deposits[dispId];
            acc.reconciled += dep.reconciled;
            acc.pending += dep.pending;
            acc.under_review += dep.under_review;
            acc.total += dep.total;
            return acc;
          },
          { reconciled: 0, pending: 0, total: 0, under_review: 0 }
        )
    : {};

  const salesData: { verified?: number; unverified?: number; uncheckable?: number; total?: number } = sales
    ? Object.keys(sales)
        .filter(dispId => accountsToInclude.includes(dispId))
        .reduce(
          (acc, dispId) => {
            const summary = sales[dispId];
            if (accountsToInclude.includes(dispId)) {
              acc.uncheckable += summary.uncheckable_total;
              acc.verified += summary.verified_total;
              acc.unverified += summary.unverified_total;
              acc.total += summary.uncheckable_total + summary.verified_total + summary.unverified_total;
            }
            return acc;
          },
          { verified: 0, unverified: 0, uncheckable: 0, total: 0 }
        )
    : {};

  const chartData = Object.values(dispensariesWithSalesTotals).sort((a, b) =>
    a.dispensary_name > b.dispensary_name ? 1 : -1
  );

  return { salesData, depositData, chartData };
};
