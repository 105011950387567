import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';

import { DrawerText, DrawerImage } from '../styles';

interface Props {
  form: any;
}

export const Portal42Form = ({ form }: Props) => {
  return (
    <>
      <DrawerText>To configure this integration, you’ll need your Portal42 client API key.</DrawerText>
      <DrawerText>
        Your client API key can be found in your Portal42 settings. You’ll want to make sure that the switch is toggled
        on.
      </DrawerText>
      <DrawerImage src="../../../assets/Portal42APIExample.png"></DrawerImage>
      <GcvInputForm
        {...form}
        name="clientApiKey"
        label="Client API Key"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
    </>
  );
};
