<div class="content" data-cy="user-group-overview.component">
  <div class="cardContainer">
    <div class="userTableWrapper col-9 large-12">
      <gcv-data-table
        (itemSelected)="itemSelected($event)"
        (rowClicked)="handleRowclicked($event)"
        [clickable]="true"
        [columnNames]="displayedColumns"
        [dataMap]="dataMap"
        [data]="data"
        [ngStyle]="{ width: '100%' }"
        [selectedItems]="selectedItems"
        data-cy="user-group-overview.component.app-data-table"
      >
      </gcv-data-table>
    </div>

    <div class="userCreateWrapper col-3 large-12" data-cy="user-group-overview.component.userCreateWrapper">
      <gcv-card class="card">
        <div card-body class="cardBody">
          <img [src]="imgSrc" alt="placeholder" class="logo" />
          <div class="contentWrapper">
            <span class="title" data-cy="user-group-overview.component.userCreateTitle">{{ title }}</span>
            <span class="subTitle" data-cy="user-group-overview.component.userCreateSubtitle">{{ subTitle }}</span>
          </div>
          <gcv-button
            (clickEvent)="handleButtonClicked()"
            [buttonText]="btnText"
            class="full-width"
            data-cy="user-group-overview.component.app-button"
            type="primary full-width"
          ></gcv-button>
        </div>
      </gcv-card>
    </div>
  </div>
</div>
