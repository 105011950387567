<div class="wrapper" data-cy="edit-user-dialog.component">
  <div class="header">
    <svg (click)="close({ isSaving: false })" height="32" version="1" width="32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle cx="15" cy="15" r="15" stroke="#DBE0EB" transform="translate(1 1)" />
        <path
          d="M19 12l-3 3-3-3a1 1 0 0 0-1 1l3 3-3 3v2h1l3-3 3 3a1 1 0 0 0 2-2l-3-3 3-3v-1h-2z"
          fill="#A5A8BA"
          stroke="#FFF"
        />
      </g>
    </svg>
  </div>
  <div class="formWrapper">
    <gcv-mat-input
      [errors]="userDetails.controls['firstName'].errors"
      [group]="userDetails"
      [validators]="[
        {
          type: 'required',
          message: 'A first name is required'
        }
      ]"
      control="firstName"
      data-cy="edit-user-dialog.component.firstName"
      label="First Name"
      type="text"
      type="text"
    >
    </gcv-mat-input>
    <gcv-mat-input
      [errors]="userDetails.controls['lastName'].errors"
      [group]="userDetails"
      [validators]="[
        {
          type: 'required',
          message: 'A last name is required'
        }
      ]"
      control="lastName"
      data-cy="edit-user-dialog.component.lastName"
      label="Last Name"
      type="text"
      type="text"
    >
    </gcv-mat-input>
    <gcv-mat-input
      [errors]="userDetails.controls['email'].errors"
      [group]="userDetails"
      [validators]="[
        {
          type: 'required',
          message: 'An email is required'
        }
      ]"
      control="email"
      data-cy="edit-user-dialog.component.email"
      label="Email Address"
      type="text"
      type="text"
    >
    </gcv-mat-input>
  </div>
  <div class="btnWrapper">
    <gcv-button
      (clickEvent)="saveUser()"
      [disabled]="!userDetails.valid"
      buttonText="Update"
      data-cy="edit-user-dialog.component.update"
      type="primary"
    ></gcv-button>
    <gcv-button
      (clickEvent)="archiveUser()"
      buttonText="Archive"
      data-cy="edit-user-dialog.component.disable"
      type="danger"
    ></gcv-button>
  </div>
</div>
