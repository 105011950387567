<div class="wrapper">
  <app-amplify-auth-sign-in-core
    class="center"
    [@slideInOut]
    *ngIf="authState.state === 'signedIn' || authState.state === 'signIn' || authState.state === 'signedOut'"
    [authState]="authState"
    (signingIn)="signingIn($event)"
  ></app-amplify-auth-sign-in-core>
  <app-amplify-auth-forgot-password-core
    [@slideInOut]
    class="center"
    *ngIf="authState.state === 'forgotPassword'"
    [authState]="authState"
  ></app-amplify-auth-forgot-password-core>
</div>
<gcv-spinner [@slideInOut] *ngIf="loading"></gcv-spinner>
