import { ReactWrapperComponent, WrapperProps, acceptOrApproveDeposit } from '../../../util';
import { CreateNewFincenReport } from './CreateNewFincenReport';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  DispensaryFacade,
  DepositsFacade,
  NotificationsFacade,
  BankFacade,
  StaffFacade,
  FincenFacade,
} from '@user-interface/gcv-state';
import { DefaultService } from '@gcv/generated-services';

import { UtilsService } from '@user-interface/gcv-ui';
import {
  getCurrentRouteForFincen,
  getOpenRouteForFincen,
} from 'apps/user-interface/src/app/bank/fincen/fincen.routes.names';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'create-new-fincen-report',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></ app-react-wrapper>',
})
export class CreateNewFincenReportComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Output() clickEmitter = new EventEmitter<any>();

  component = CreateNewFincenReport;

  constructor(
    private router: Router,
    private dispFacade: DispensaryFacade,
    private depositsFacade: DepositsFacade,
    private notificationFacade: NotificationsFacade,
    private utils: UtilsService,
    private staffFacade: StaffFacade,
    private bankFacade: BankFacade,
    private fincenFacade: FincenFacade,
    private sdk: DefaultService
  ) {
    super();
    this.handleEmitData = this.handleEmitData.bind(this);
  }

  ngOnInit() {
    this.setUpStreams();
  }

  setUpStreams() {
    this.dispFacade
      .selectDispensaryDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(dispensaries => {
        this.props = {
          ...this.props,
          dispensaries: dispensaries,
        };
      });

    this.depositsFacade
      .selectAllDeposits()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(deposits => {
        this.props = {
          ...this.props,
          deposits: deposits,
        };
      });

    const bank$ = this.bankFacade.selectCurrentBank();
    bank$.pipe(takeUntil(this.unsubscribe$)).subscribe(bank => {
      this.props = {
        ...this.props,
        bankId: bank.id,
      };
    });

    const staff$ = this.staffFacade.selectStaffDictionary();
    staff$.pipe(takeUntil(this.unsubscribe$)).subscribe(staffMap => {
      this.props = { ...this.props, staff: staffMap };
    });

    this.staffFacade.getOrganizationStaffByOrgId(this.props.bankId);
  }

  async handleEmitData(payload) {
    if (payload === 'cancel') {
      this.router.navigate([getCurrentRouteForFincen()]);
    } else if (payload.data) {
      let report =
        payload.data.type === 'ctr'
          ? this.sdk.fincenReportsCtrPost(payload.data)
          : this.sdk.fincenReportsSarPost(payload.data);
      report.pipe(takeUntil(this.unsubscribe$)).subscribe(report => {
        this.fincenFacade.updateFincenStore([report]);
        this.router.navigate([getOpenRouteForFincen()]);
      });
    } else if (payload.dispensary_id) {
      this.depositsFacade.resetDeposits();
      this.depositsFacade.getDepositsByDispensaryId(payload.dispensary_id);
    } else if (payload.reconcileDeposit) {
      acceptOrApproveDeposit(payload.reconcileDeposit.deposit, payload.reconcileDeposit.bankId, {
        utils: this.utils,
        notificationFacade: this.notificationFacade,
        depositsFacade: this.depositsFacade,
      });
    }
  }
}
