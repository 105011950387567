import React from 'react';
import { DailySummariesDetailView } from '../../shared/DailySummaries';

interface Props {
  bankId: string;
  daysStartDate: string;
  daysEndDate: string;
}

export const DailySummaryDetail = (props: Props) => {
  return (
    <>
      <DailySummariesDetailView backClickLink={'/secure/bank/dashboard/daily-summaries'}></DailySummariesDetailView>
    </>
  );
};
