import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-dispensary-due-diligence',
  templateUrl: './dispensary-due-diligence.component.html',
  styleUrls: ['./dispensary-due-diligence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispensaryDueDiligenceComponent implements OnInit {
  @Input() title: string;
  @Input() break = false;
  @Input() subTitle: string;
  @Input() buttonVisible = false;
  @Input() number: number;
  @Input() complete = false;
  @Input() instance = 'business';
  @Output() handleButtonPressed = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  buttonPressed() {
    this.handleButtonPressed.emit(this.number);
  }
}
