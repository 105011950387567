// fx
import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Fab, Grid, Icon, makeStyles, TextField } from '@material-ui/core';
// app
import { Sale } from '@gcv/shared';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { GcvButton } from '../../../lib';
import { useApi } from 'libs/react-ui/src/hooks';

const useStyles = makeStyles(theme => ({
  alertBox: {
    border: '1px solid #3f51b5',
    borderRadius: '4px',
    backgroundColor: 'rgba(105, 150, 250, 0.1)',
    minHeight: '25px',
    textAlign: 'center',
    marginBottom: '1rem',
  },
}));

interface Props {
  sale: Sale;
  updateSale: any;
}

export const EditSaleCard: React.FC<Props> = props => {
  const { API } = useApi();
  const classes = useStyles(props);

  const [msg, setMsg] = useState(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let change = {};
    change[e.target.id] = e.target.value;

    props.updateSale({ ...props.sale, ...change });
  };

  const handleComplianceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let prop;

    if (e.target.id.indexOf('compliance_exceptions_') > -1) {
      prop = e.target.id.split('compliance_exceptions_');

      if (e.target.value) {
        props.sale.compliance_exceptions[prop[1]] = e.target.value;
      } else {
        props.sale.compliance_exceptions.splice(prop[1], 1);
      }
    }

    if (e.target.id.indexOf('compliance_warnings_') > -1) {
      prop = e.target.id.split('compliance_warnings_');

      if (e.target.value) {
        props.sale.compliance_warnings[prop[1]] = e.target.value;
      } else {
        props.sale.compliance_warnings.splice(prop[1], 1);
      }
    }

    props.updateSale({ ...props.sale });
  };

  const saveSale = async () => {
    setMsg(null);

    await API.patch(
      GREEN_CHECK_SERVICES_AMPLIFY_NAME,
      `/dispensaries/${props.sale.dispensary_id}/sales/${props.sale.sale_id}`,
      {
        body: props.sale,
      }
    )
      .then(() => {
        props.updateSale({ ...props.sale, gcv_version_num: props.sale.gcv_version_num + 1 });
        setMsg(`Sale updated - ${props.sale.sale_id}`);

        setTimeout(() => {
          setMsg(null);
        }, 5000);
      })
      .catch(e => {
        setMsg(e.message);
        console.log(e);
      });
  };

  return (
    <>
      {msg && <div className={classes.alertBox}>{msg}</div>}

      {!props.sale && (
        <Card>
          <CardHeader title="No Sale Selected" />
        </Card>
      )}

      {props.sale && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Record" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="filled"
                        id="dispensary_id"
                        label="dispensary_id"
                        value={props.sale.dispensary_id}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField fullWidth variant="filled" id="sale_id" label="sale_id" value={props.sale.sale_id} />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="filled"
                        id="customer_id"
                        label="customer_id"
                        value={props.sale.customer_id}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="filled"
                        id="receipt_date"
                        label="receipt_date"
                        value={props.sale.receipt_date}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="filled"
                        id="s3_date_uploaded"
                        label="s3_date_uploaded"
                        value={props.sale.s3_date_uploaded}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="filled"
                        id="deposit_id"
                        label="deposit_id"
                        value={props.sale.deposit_id}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Sale" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="employee_first"
                        label="employee_first"
                        value={props.sale.employee_first}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="employee_id"
                        label="employee_id"
                        value={props.sale.employee_id}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="medical_or_adult"
                        label="medical_or_adult"
                        value={props.sale.medical_or_adult}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="status"
                        label="status"
                        value={props.sale.status}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="customer_showed_id"
                        label="customer_showed_id"
                        value={props.sale.customer_showed_id}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="payment_type"
                        label="payment_type"
                        value={props.sale.payment_type}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="sale_type"
                        label="sale_type"
                        value={props.sale.sale_type}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="total_paid"
                        label="total_paid"
                        type="number"
                        value={props.sale.total_paid}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="price"
                        label="price"
                        type="number"
                        value={props.sale.price}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="tax"
                        label="tax"
                        type="number"
                        value={props.sale.tax}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="cash_paid"
                        label="cash_paid"
                        type="number"
                        value={props.sale.cash_paid}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="debit_paid"
                        label="debit_paid"
                        type="number"
                        value={props.sale.debit_paid}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="credit_paid"
                        label="credit_paid"
                        type="number"
                        value={props.sale.credit_paid}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="check_paid"
                        label="check_paid"
                        type="number"
                        value={props.sale.check_paid}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="ach_paid"
                        label="ach_paid"
                        type="number"
                        value={props.sale.ach_paid}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="wire_paid"
                        label="wire_paid"
                        type="number"
                        value={props.sale.wire_paid}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Compliance" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <button
                        onClick={() => {
                          props.sale.compliance_exceptions.push('');
                          saveSale();
                        }}
                      >
                        Add Compliance Exception
                      </button>
                      <br />
                      <br />
                      {props.sale.compliance_exceptions.map((text, index) => {
                        return (
                          <TextField
                            key={index}
                            fullWidth
                            id={`compliance_exceptions_${index}`}
                            label="compliance_exceptions"
                            value={text}
                            onChange={handleComplianceChange}
                          />
                        );
                      })}
                    </Grid>

                    <Grid item xs={6}>
                      <button
                        onClick={() => {
                          props.sale.compliance_warnings.push('');
                          saveSale();
                        }}
                      >
                        Add Compliance Warning
                      </button>
                      <br />
                      <br />
                      {props.sale.compliance_warnings.map((text, index) => {
                        return (
                          <TextField
                            key={index}
                            fullWidth
                            id={`compliance_warnings_${index}`}
                            label="compliance_warnings"
                            value={text}
                            onChange={handleComplianceChange}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Customer" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="company_name"
                        label="company_name"
                        //@ts-ignore
                        value={props.sale.company_name}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="customer_dob"
                        label="customer_dob"
                        value={props.sale.customer_dob}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="id_type"
                        label="id_type"
                        value={props.sale.id_type}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="id_expiration_date"
                        label="id_expiration_date"
                        value={props.sale.id_expiration_date}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <GcvButton primary={true} onClick={saveSale}>
              Save
            </GcvButton>
          </Grid>
        </>
      )}
    </>
  );
};
