export const isFileExtensionPdf = file => {
  return file && doesValueHaveFileExtensionPdf(file.name);
};

export const isFileExtensionJpegOrPng = file => {
  return file && (doesValueHaveFileExtensionJpeg(file.name) || doesValueHaveFileExtensionPng(file.name));
};

export const doesValueHaveFileExtensionPdf = value => {
  return /(.pdf)/i.test(value);
};

export const doesValueHaveFileExtensionPng = value => {
  return /(.png)/i.test(value);
};

export const doesValueHaveFileExtensionJpeg = value => {
  return /(.jpe?g)/i.test(value);
};

// hand-picked common file types to whitelist from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const acceptedFileTypes = [
  'text/csv', //.csv	Comma-separated values (CSV)
  'application/msword', //.doc	Microsoft Word
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //.docx	Microsoft Word (OpenXML)
  'image/gif', //.gif	Graphics Interchange Format (GIF)
  'image/jpeg', //.jpeg .jpg	JPEG images
  'application/json', //.json	JSON format
  'application/vnd.oasis.opendocument.presentation', //.odp	OpenDocument presentation document
  'application/vnd.oasis.opendocument.spreadsheet', //.ods	OpenDocument spreadsheet document
  'application/vnd.oasis.opendocument.text', //.odt	OpenDocument text document
  'image/png', //.png	Portable Network Graphics
  'application/pdf', //.pdf	Adobe Portable Document Format (PDF)
  'application/vnd.ms-powerpoint', //.ppt	Microsoft PowerPoint
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', //.pptx	Microsoft PowerPoint (OpenXML)
  'application/rtf', //.rtf	Rich Text Format (RTF)
  'image/svg+xml', //.svg	Scalable Vector Graphics (SVG)
  'image/tiff', //.tif .tiff	Tagged Image File Format (TIFF)
  'text/plain', //.txt	Text, (generally ASCII or ISO 8859-n)
  'application/vnd.visio', //.vsd	Microsoft Visio
  'application/vnd.ms-excel', //.xls	Microsoft Excel
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //.xlsx	Microsoft Excel (OpenXML)
  'application/xml', //.xml	XML
  'text/xml', //.xml	XML	application/xml is recommended as of RFC 7303 (section 4.1), but text/xml is still used sometimes. You can assign a specific MIME type to a file with .xml extension depending on how its contents are meant to be interpreted. For instance, an Atom feed is application/atom+xml, but application/xml serves as a valid default.
];

export const getAllAcceptedFileTypes = () => acceptedFileTypes.join(',');
