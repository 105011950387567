import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { Dispensary, DueDiligenceStatus, Bank } from '@gcv/shared';
import { BodySubContainer, BodySubTitle, BodySubContent } from './styles';
import { GcvCard, GcvButton, GcvLoading, GcvZeroState } from '../../../lib';
import { doesBankHaveCustomFields, formatMissingDash } from '../../../util';
import { Grid, Typography } from '@material-ui/core';
import { SectionTypes } from '../Account-Review-Overview.page';
import { entitytypes } from 'libs/gcv-ui/src/lib';
import { Header, ReviewText, Title, VerticalCenter } from '../styles';
import { BackButtonIcon } from 'libs/react-ui/src/icons/BackButtonIcon';
import { DynamicUi } from 'libs/react-ui/src/shared/DynamicUiGenerator/ui-factory';
import { useForm } from 'react-hook-form';
import { api } from 'libs/react-ui/src/api';

interface Props {
  dispensary?: Dispensary;
  bank: Bank;
  onSave: (type: SectionTypes) => void;
  onBack: () => void;
  isActiveAccount?: boolean;
}

export const AccountAdditionalInformation = ({ dispensary, bank, onBack, onSave, isActiveAccount }: Props) => {
  const uiData = dispensary?.due_diligence?.bank_custom_fields?.responses
    ? dispensary.due_diligence.bank_custom_fields.responses
    : {};
  const uiJson = bank?.due_diligence_template?.custom_fields?.template_json
    ? bank.due_diligence_template.custom_fields.template_json
    : '';
  const { register, handleSubmit, ...form } = useForm({ mode: 'onChange', defaultValues: uiData });
  const apiClient = api();
  const [loading, setLoading] = useState(false);

  const isAwaitingReview = dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_AWAITING_REVIEW;
  const isAlreadyReviewed = dispensary.due_diligence.additional_information_section_review_complete;
  const hasCustomFields = doesBankHaveCustomFields(bank.due_diligence_template);

  return (
    <>
      {!isActiveAccount && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Header style={{ justifyContent: 'start' }}>
              <BackButtonIcon onClick={onBack} style={{ cursor: 'pointer' }} />
              <VerticalCenter style={{ marginLeft: '1rem' }}>
                <Title>Additional Information</Title>
              </VerticalCenter>
            </Header>
            <ReviewText>
              Review the additional information provided by this business. Any custom fields that were added after the
              business submitted their application may not be complete.
            </ReviewText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontFamily: 'Lato, Helvetica, Arial, san-serif' }}>
              You are reviewing: <strong>{dispensary.name}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!hasCustomFields ? (
            <GcvZeroState
              headerText="Your institution has not added any custom fields to your requirements."
              subText={
                <>
                  <span>
                    To add custom fields, please email {''}
                    <a href="mailto:support@greencheckverified.com">support@greencheckverified.com</a>
                  </span>
                </>
              }
              type="bankDash"
            />
          ) : (
            <GcvCard>
              <GcvCard.Header title={<strong>Additional Information</strong>} />
              <GcvCard.Body>
                <DynamicUi uiData={JSON.stringify(uiData)} uiJson={uiJson} readOnly={true}></DynamicUi>
              </GcvCard.Body>
            </GcvCard>
          )}
        </Grid>

        {!isActiveAccount && (
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {loading ? (
              <VerticalCenter>
                <GcvLoading></GcvLoading>
              </VerticalCenter>
            ) : isAwaitingReview && !isAlreadyReviewed ? (
              <GcvButton
                primary={true}
                onClick={() => onSave('additional-information')}
                data-cy="button.component.save"
              >
                Save
              </GcvButton>
            ) : null}
          </Grid>
        )}
      </Grid>
    </>
  );
};
