import {
  $accent,
  $black,
  $danger,
  $blue,
  $fontFamily,
  $primary,
  $grey2,
  $grey1,
  $grey8,
  $primaryBorder,
  $white,
} from '../../../util/styleVariables';
import { mediaQuery } from '../../../styles/mediaQuery';
import styled, { createGlobalStyle, CSSProperties } from 'styled-components';
import FloatingLabelInput from 'react-floating-label-input';
import { Styles } from 'react-select';

interface AccountItemProps {
  isSelected?: boolean;
}

interface Props {
  unverified?: boolean;
}

// customize style of react-modal
export const customModalStyles = {
  overlay: {
    zIndex: 5,
    background: 'rgba(177, 178, 181, 0.7)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    width: '500px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    borderRadius: '3px',
    padding: '38px 45px',
    overflow: 'visible',
  },
};

// customize style of react-select
export const noIndicatorSeparator = { indicatorSeparator: () => ({}) };

export const searchBarStyles = {
  control: (): CSSProperties => ({
    border: 'none',
    borderBottom: '1px solid #E0E5F0',
    display: 'flex',
  }),
  menu: (): CSSProperties => ({
    border: 'none',
  }),
  ...noIndicatorSeparator,
};

// add animation to react-modal and colors to floating label input
export const ModalStyleOverrides = createGlobalStyle`

    .ReactModal__Content {
        height: unset !important;
        /* overflow-y: hidden !important; */
    }
    .ReactModalPortal > div {
        opacity: 0;
     }
     .ReactModalPortal .ReactModal__Overlay {
         transition: opacity 100ms ease-in-out;
         background: rgba(0, 0, 0, 0.6);
     }
     .ReactModalPortal .ReactModal__Overlay--after-open {
        opacity: 1;
     };
     .ReactModalPortal .ReactModal__Overlay--before-close {
        opacity: 0;
     }
`;

export const ExportActivityModalBody = styled.div`
  font-family: ${$fontFamily};
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #16181e;
  }

  h5 {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-top: 25px;
  }

  .button-container {
    text-align: center;
    margin-top: 25px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  position: relative;
`;

export const RadioButtons = styled.div`
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 33%;

  label {
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: -0.21875px;
    color: ${$grey1};
    margin-left: 5px;
  }

  input,
  label {
    cursor: pointer;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchInputContainerActivityModal = styled.div`
  position: relative;
  height: 18px;
  margin-top: 35px;
`;

export const SearchInput = styled(FloatingLabelInput)`
  padding: 5px 0;
  width: 100%;
  font-size: 16px;
  color: ${$grey1};

  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }

  &::placeholder {
    font-size: 16px;
    line-height: 19px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
  }
`;

export const InputIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-bottom: 5px;
`;

export const AccountsContainer = styled.div`
  height: 200px;
  overflow-y: scroll;
  margin-top: 30px;

  &::-webkit-scrollbar {
    /* -webkit-appearance: none; */
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${$grey8};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const AccountItem = styled.div<AccountItemProps>`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  padding: 16px 0;

  label {
    width: 450px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: ${props => (props.isSelected ? $primary : '#16181e')};
    cursor: pointer;
  }
`;

export const AccountsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  margin-top: 1rem;

  .accounts-selected {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${$grey8};
  }

  .select-all {
    font-size: 14px;
    line-height: 15px;
    color: ${$blue};
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  margin-top: 2rem;
`;

export const HyperTextButton = styled.div`
  cursor: pointer;
  color: #6896fb;
  font-size: 10pt;
  padding: 0.5rem;
  transition: color 0.1s ease;
  &:hover {
    color: blue;
  }
  &:active {
    color: grey;
  }
`;

export const CenterHorizontal = styled.div`
  margin: 0 auto;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.5rem 0;
  position: relative;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 4rem;
  height: auto;
  margin-bottom: 0.5rem;
`;

export const Heading = styled.h2`
  color: ${$black};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin: 0;
  margin-bottom: 1.5rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
`;

export const VerifiedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 1rem;
`;

export const VerifiedText = styled.span<Props>`
  color: ${props => (props.unverified ? $danger : $black)};
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
`;

export const VerifiedLabel = styled.span`
  color: ${$accent};
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
`;

export const ReviewText = styled.span`
  padding: 0px 1rem;
  color: #3a3c45;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  overflow-y: auto;
  max-height: 65vh;

  ${mediaQuery.sm`
     grid-template-columns: unset;
  `}
`;

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ToggleCardTableView = styled.div`
  display: flex;
`;
export const Table = styled.div`
  padding: 10px 5px;
  border: 1px solid #a5a8ba;
  box-sizing: border-box;
  border-radius: 0px 5px 5px 0px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;
export const Cards = styled.div`
  padding: 10px 5px;
  border: 1px solid #a5a8ba;
  box-sizing: border-box;
  border-radius: 5px 0px 0px 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;

export const ActivityModal = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: -1rem;
`;

export const HeaderSubContent = styled.div`
  margin-left: 2rem;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: ${$grey1};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardContainer = styled.div`
  margin: 1.5rem;
  margin-left: 0;
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BodySubTitle = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 1.25;
  color: ${$grey2};
`;

export const BodySubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 12.5rem;
`;

export const BodySubContent = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const HoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid ${$primaryBorder};
`;

export const Day = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
`;

export const Time = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
`;

export const ButtonIcon = styled.span`
  color: #e0e0e0;
  &:hover {
    cursor: pointer;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55vh;
`;

export const ChartContainer = styled.div``;

export const CheckboxContainer = styled.div`
  display: flex;
  width: 350px;
  justify-content: space-evenly;
`;

export const GreyTitle = styled.div`
  color: ${$grey2};
  font-size: 14px;
  font-weight: 700;
  font-family: Lato, Helvetica, Arial, san-serif;
`;

export const GreyText = styled.div`
  color: ${$grey2};
  font-size: 12px;
  font-family: Lato, Helvetica, Arial, san-serif;
`;

export const BlackTitle = styled.div`
  color: ${$black};
  font-size: 16px;
  font-weight: 700;
  font-family: Lato, Helvetica, Arial, san-serif;
  display: flex;
`;

export const SummaryLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${$primaryBorder};
  padding-bottom: 0.5rem;
  margin: 0.5rem 0;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const Percentage = styled.div`
  color: ${$grey2};
  font-size: 14px;
  font-family: Lato, Helvetica, Arial, san-serif;
  margin-left: 5px;
`;

export const ToolTipContainer = styled.div`
  background-color: ${$white};
  padding: 1rem;
  font-size: 14px;
  font-family: Lato, Helvetica, Arial, san-serif;
  margin-left: 5px;
  border: 1px solid ${$primaryBorder};
  min-width: 16rem;
`;
