<div class="wrapper" data-cy="nav-menu-wrapper">
  <div class="menu-wrapper">
    <button
      (click)="handleMenuItemClicked(i)"
      *ngFor="let item of menuItems; let i = index"
      [id]="getButtonId(item)"
      [attr.data-cy]="getButtonId(item)"
    >
      <svg
        [attr.class]="item.complete ? 'complete-circle' : 'incomplete-circle'"
        viewBox="0 0 26 26"
        width="21px"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="status-icon"
      >
        <g clip-rule="evenodd" fill-rule="evenodd">
          <circle cx="13" cy="13" r="13" />
          <path
            d="M15.9 10.3l-4.3 4-1.4-1.4c-.5-.5-1.3-.5-1.8 0s-.5 1.3 0 1.8l1.7 1.7c.4.4.9.6 1.5.6.5 0 1-.2 1.4-.6l4.6-4.2c.5-.5.5-1.3.1-1.8-.5-.5-1.3-.5-1.8-.1"
            fill="#fff"
          />
        </g>
      </svg>
      <span [class.active]="isActive === i" class="menu-item-title"> {{ i + 1 }}. {{ item.text }} </span>
    </button>
  </div>
  <div class="menu-button">
    <gcv-button
      id="complete-setup-text"
      (clickEvent)="handleButtonClicked()"
      [buttonText]="completeSetupText"
      [type]="isComplete ? 'primary ' : 'secondary'"
    ></gcv-button>
  </div>
</div>
