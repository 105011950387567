import { ReactWrapperComponent, WrapperProps, CompanyProfile } from '@user-interface/react-ui';

import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';

import { Dispensary, DueDiligenceTemplate } from '@gcv/shared';

import { slideInAnimation } from '../../app/animations/router-animation';

import { DispensaryFacade } from '@user-interface/gcv-state';

import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'additional-information',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEvent"></app-react-wrapper>',
  animations: [trigger('routeAnimations', [transition('active <=> pending', [useAnimation(slideInAnimation)])])],
})
export class CrbAdditionalInformationComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = CompanyProfile;
  bankTemplate: DueDiligenceTemplate;
  dispensary: Dispensary;

  constructor(private dispFacade: DispensaryFacade) {
    super();
    this.handleEvent = this.handleEvent.bind(this);

    this.props = {
      ...this.props,
    };
  }

  ngOnInit() {
    this.setUpStreams();
  }

  setUpStreams() {
    this.dispFacade
      .selectCurrentDispensary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((disp: Dispensary) => {
        this.dispensary = disp;
        this.props = { ...this.props, dispensary: disp };
      });

    this.dispFacade
      .selectDispensaryGcvDueDiligenceTemplate()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(template => {
        this.bankTemplate = template?.bank;
        this.props = { ...this.props, bankTemplate: template?.bank };
      });
  }

  handleEvent(type, data) {
    if (type === 'updateDispensary' && data) {
      this.dispFacade.updateDispensary(data);
    }
  }
}
