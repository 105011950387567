import { ReactWrapperComponent, WrapperProps } from '../../util';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResendUserInvite } from './ResendUserInvite';

@Component({
  selector: 'app-resend-user-invite',
  // TODO remove use of "styleUrls" and pull those styles into component or something like libs/react-ui/src/lib/styles/AmplifyScssStyledPort.ts
  styleUrls: ['../../../../../apps/user-interface/src/app/auth/cognito/cognito.component.scss'],
  template:
    '<app-react-wrapper class="wrapper" [wrappedComponent]="component" [wrappedProps]="props"></ app-react-wrapper>',
})
export class ResendUserInviteComponent extends ReactWrapperComponent implements OnInit {
  @Input() props: WrapperProps;
  component = ResendUserInvite;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    const email = queryParams.email;
    this.props = { email: email };
  }
}
