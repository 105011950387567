import { ReactWrapperComponent, WrapperProps, PurchaseRecordDrawer } from '@user-interface/react-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sale } from '@gcv/shared';

@Component({
  selector: 'sales-drawer',
  template: '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props"></app-react-wrapper>',
})
export class SalesDrawerComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Input() opened = false;
  @Input() items: Sale;
  @Output() toggled = new EventEmitter<boolean>();
  component = PurchaseRecordDrawer;

  constructor() {
    super();
  }

  ngOnInit() {
    this.updateProps();
  }

  ngOnChanges() {
    this.updateProps();
  }

  updateProps() {
    this.props = {
      open: this.opened,
      data: this.items,
      onClose: () => {
        this.toggled.emit(false);
      },
    };
  }
}
