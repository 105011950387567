import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-onboarding-progress-bar',
  templateUrl: './onboarding-progress-bar.component.html',
  styleUrls: ['./onboarding-progress-bar.component.scss'],
})
export class OnboardingProgressBarComponent implements OnInit {
  // `currentStep` is the step the user needs to complete next
  // `selected` is the step the user has selected right now
  @Input() stepStatus;
  @Output() clickEmitter = new EventEmitter<{
    prevStep: number;
    nextStep: number;
  }>();

  constructor() {}

  ngOnInit() {}

  handleClick(index) {
    let prevStep = 1;
    this.stepStatus.forEach((step, i) => {
      if (step && step.selected) {
        prevStep = i;
      }
    });
    if (
      this.stepStatus[index].currentStep ||
      this.stepStatus[index].complete ||
      this.stepStatus[Math.max(index - 1, 1)].complete
    ) {
      this.clickEmitter.emit({ prevStep: prevStep, nextStep: index });
    }
  }
}
