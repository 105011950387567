import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';

import { ReactWrapperComponent, WrapperProps, FincenRouter, acceptOrApproveDeposit } from '@user-interface/react-ui';

import {
  Deposit,
  Dispensary,
  User,
  Notification,
  FincenCtrReport,
  FincenSarReport,
  DueDiligenceStatus,
} from '@gcv/shared';
import { slideInAnimation } from '../../app/animations/router-animation';
import {
  DispensaryFacade,
  DepositsFacade,
  StaffFacade,
  BankFacade,
  NotificationsFacade,
  FincenFacade,
  AggregatesFacade,
  UserFacade,
} from '@user-interface/gcv-state';
import { Dictionary } from '@ngrx/entity';
import { UtilsService } from '@user-interface/gcv-ui';
import { take, takeUntil, tap } from 'rxjs/operators';
import { determineStatus, ViewStates } from '../../app/bank/bank.util';
import { BankAppFamilyService } from '../../services/bank.service';
import { NavigationExtras, Router } from '@angular/router';
import { DefaultService } from '@gcv/generated-services';
import { getOpenRouteForFincen, getCurrentRouteForFincen } from '../../app/bank/fincen/fincen.routes.names';

@Component({
  selector: 'bank-fincen',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></app-react-wrapper>',
  animations: [trigger('routeAnimations', [transition('active <=> pending', [useAnimation(slideInAnimation)])])],
})
export class BankFincenComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = FincenRouter;
  deposits: Deposit[];
  viewStatus: string;
  bank: any;
  reports: FincenCtrReport[] | FincenSarReport[];
  fetchedReports: boolean = false;

  constructor(
    private bankAppFamilyService: BankAppFamilyService,
    private dispFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private fincenFacade: FincenFacade,
    private router: Router,
    private depositsFacade: DepositsFacade,
    private notificationFacade: NotificationsFacade,
    private utils: UtilsService,
    private staffFacade: StaffFacade,
    private aggFacade: AggregatesFacade,
    private sdk: DefaultService,
    private userFacade: UserFacade
  ) {
    super();
    this.handleEmitData = this.handleEmitData.bind(this);
  }

  ngOnInit() {
    this.props = { ...this.props, reportsLoading: true };
    this.setUpStreams();
  }

  setUpStreams() {
    let dispensaries: Dictionary<Dispensary>;

    this.userFacade.selectCurrentUser().subscribe(user => {
      this.props = {
        ...this.props,
        user,
      };
    });

    this.bankFacade.selectCurrentBank().subscribe(bank => {
      this.bank = bank;
      //only fetch all reports if we don't have any in the store to prevent race condition after updating report
      this.fincenFacade
        .selectAllFincen()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(reports => {
          if (!this.fetchedReports && reports.length <= 1) {
            this.fincenFacade.getFincenByBankId(bank.id);
            this.fetchedReports = true;
          }
        });
      this.aggFacade.loadSarReportPeriodAggregate(bank.id);
      this.staffFacade.getOrganizationStaffByOrgId(bank.id);
      this.viewStatus = determineStatus(bank.due_diligence.due_diligence_status);
      this.props = { ...this.props, bank, bankId: bank.id, viewStatus: this.viewStatus, reportsLoading: true };
    });

    this.aggFacade.selectSarReportPeriodAggregates().subscribe(sarPeriods => {
      this.props = { ...this.props, sarPeriods: sarPeriods.length === 0 ? undefined : sarPeriods };
    });

    this.dispFacade
      .selectDispensaryDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(d => {
        dispensaries = d;
        this.props = {
          ...this.props,
          dispensaries,
          reportsLoading:
            Object.values(dispensaries).some(
              (d: Dispensary) => d.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED
            ) &&
            this.props.reports &&
            this.props.reports.length === 0,
        };
      });

    this.fincenFacade
      .selectAllFincen()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(reports => {
        this.props = {
          ...this.props,
          reports,
          reportsLoading:
            Object.values(this.props.dispensaries).some(
              (d: Dispensary) => d.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED
            ) && reports.length === 0,
        };
      });

    this.depositsFacade
      .selectAllDeposits()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(deposits => {
        this.props = {
          ...this.props,
          deposits: deposits,
        };
      });

    this.staffFacade
      .selectStaffDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(staffMap => {
        this.props = { ...this.props, staff: staffMap };
      });
  }

  async handleEmitData(payload) {
    if (payload.type === 'viewReport') {
      const data: NavigationExtras = {
        queryParams: {
          id: payload.reportId,
        },
      };
      this.router.navigate(['/secure/bank/view/report'], data);
    } else if (payload.type === 'getReports') {
      this.fincenFacade.getFincenByBankId(payload.bankId);
    } else if (payload.type === 'updateReport') {
      this.fincenFacade.updateFincenStore(payload.reports);
    } else if (payload.type === 'deleteReport') {
      this.fincenFacade.removeFincenReport(payload.id);
    } else if (payload.type === 'resetDeposits') {
      this.depositsFacade.resetDeposits();
      this.depositsFacade.getDepositsByDispensaryId(payload.dispensary_id);
    } else if (payload.type === 'reconcileDeposit') {
      acceptOrApproveDeposit(payload.reconcileDeposit.deposit, payload.reconcileDeposit.bankId, {
        utils: this.utils,
        notificationFacade: this.notificationFacade,
        depositsFacade: this.depositsFacade,
      });
    } else if (payload.type === 'updateExport') {
      this.fincenFacade.updateFincenStore(payload.updatedReports);
    }
    if (payload.type === 'goToOnboarding') {
      if (this.viewStatus === ViewStates.DueDiligenceZeroState) {
        this.bankFacade.putUpdateBankGCVDueDiligenceStatus(this.bank.id);
      }
      this.bankAppFamilyService.view.next({ view: '/secure/bank/onboarding' });
    }
  }
}
