import { select, Store } from '@ngrx/store';
import { FincenState } from './fincen.reducer';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { FincenCtrReport, FincenSarReport } from '@gcv/shared';
import {
  selectFincenAll,
  selectFincenByBankId,
  selectFincenByDispensaryId,
  selectFincenByTypeAndStatus,
  selectFincenEntities,
  selectFincenReportById,
} from './fincen.selectors';
import {
  FincenDeleted,
  FincenLoaded,
  LoadFincenByBankAndReportId,
  LoadFincen,
  LoadFincenByBankId,
  ResetFincen,
  UpdateFincen,
} from './fincen.actions';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FincenFacade {
  fincen$: Observable<Dictionary<FincenCtrReport | FincenSarReport>>;

  constructor(private store: Store<FincenState>) {
    this.fincen$ = store.pipe(select(selectFincenEntities));
  }

  getFincenByBankAndDispensaryId(bankId: string, dispensaryId: string, forceFetch?: boolean) {
    this.store.dispatch(new LoadFincen({ bankId, dispensaryId, forceFetch }));
  }

  getFincenByBankId(bankId: string) {
    this.store.dispatch(new LoadFincenByBankId({ bankId }));
  }

  getFincenByBankAndReportId(bankId: string, reportId: string) {
    this.store.dispatch(new LoadFincenByBankAndReportId({ bankId, reportId }));
  }

  removeFincenReport(reportId: string) {
    this.store.dispatch(new FincenDeleted(reportId));
  }

  putFincenReport(report: FincenCtrReport | FincenSarReport) {
    this.store.dispatch(new UpdateFincen(report));
  }

  updateFincenStore(reports: (FincenCtrReport | FincenSarReport)[]) {
    this.store.dispatch(new FincenLoaded(reports));
  }

  resetFincen() {
    this.store.dispatch(new ResetFincen());
  }

  selectFincenReportById(fincenId: string) {
    return this.store.pipe(select(selectFincenReportById, { fincenId }));
  }

  selectFincenByDispensaryId(dispensaryId: string) {
    return this.store.pipe(select(selectFincenByDispensaryId, { dispensaryId }));
  }

  selectFincenByBankId(bankId: string) {
    return this.store.pipe(select(selectFincenByBankId, { bankId }));
  }

  selectFincenByTypeAndStatus(type: 'CTR' | 'SAR', status: 'open' | 'in progress' | 'complete') {
    return this.store.pipe(select(selectFincenByTypeAndStatus, { status, type }));
  }

  selectAllFincen() {
    return this.store.pipe(select(selectFincenAll));
  }
}
