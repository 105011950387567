import React, { useState, useEffect } from 'react';
import { GcvTabWizard, dateFormatterToISO } from '../../../lib';
import { Dispensary, Deposit } from '@gcv/shared';
import { Tab1, Tab2, Tab3 } from './Tabs/';
import { Title } from './styles';
import { useHistory } from 'react-router';
import { useApi } from 'libs/react-ui/src/hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { api } from 'libs/react-ui/src/api';

interface Props {
  emitData: (payload) => void;
  dispensaries: { [id: string]: Dispensary };
  deposits: Deposit[];
  bankId: string;
  staff: any;
}

interface Data {
  startDate?: string;
  type?: string; // TODO: Change this to FincenReportType enum
  filingName?: string;
  filingType?: { label: string; value: string };
  generateEvery90Days?: boolean;
  account?: { label: string; value: string };
  selectedDeposits?: Deposit[];
  loadDispensaryData?: (dispensary_id, startDate?, endDate?) => void;
  reconcileDeposit?: (type, deposit, bankId) => void;
  dispensaries?: { [id: string]: Dispensary };
  deposits?: Deposit[];
  bankId?: string;
  staff?: any;
  bsaID?: string;
  priorReport?: string;
}

export const CreateNewFincenReport = ({ emitData, dispensaries, deposits, bankId, staff, ...props }: Props) => {
  const history = useHistory();
  const apiClient = api();

  const tabs = [
    {
      component: Tab1,
      name: 'Report Information',
    },
    {
      component: Tab2,
      name: 'Account',
    },
    {
      component: Tab3,
      name: 'Transactions',
    },
  ];

  const reportTypeFromRoute = window.location.pathname.split('/').pop();

  const loadDispensaryData = (dispensary_id, startDate, endDate) => {
    let payload: any = { dispensary_id };

    if (startDate && endDate) {
      payload = { ...payload, startDate, endDate };
    }

    emitData(payload);
  };

  const reconcileDeposit = (type, deposit, bankIdForDeposit) => {
    emitData({ reconcileDeposit: { deposit, bankId: bankIdForDeposit } });
  };

  const [data, setData] = useState<Data>({
    type: reportTypeFromRoute,
    loadDispensaryData,
    reconcileDeposit,
    dispensaries,
    deposits,
    bankId,
    staff,
  });
  let currentData: Data = {};

  const updateData = newData => {
    currentData = { ...data, ...newData };
    setData({ ...data, ...newData });
  };

  const onComplete = async () => {
    const {
      type,
      startDate,
      filingName,
      filingType,
      generateEvery90Days,
      account,
      selectedDeposits,
      bsaID,
      priorReport,
      bankId,
    } = currentData;

    const dispensaryId = account && account.value;
    const filingTypeValue = filingType && filingType.value;

    const cleanData = {
      type,
      filingName,
      generateEvery90Days,
      priorReport,
      dispensaryId,
      selectedDeposits,
      bsaID,
      bankId,
      startDate: dateFormatterToISO(startDate),
      filingType: filingTypeValue,
    };
    const updateStoreWithReport = report => emitData({ type: 'fincenReport', report });

    if (type === 'ctr') {
      await apiClient.fincen.fincenReportCtrPostHandler(cleanData, () => {}).then(updateStoreWithReport);
    } else {
      await apiClient.fincen.fincenReportSarPostHandler(cleanData, () => {}).then(updateStoreWithReport);
    }

    history.push(`secure/bank/fincen/open`);
  };

  const onCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    setData({ ...data, dispensaries, deposits, bankId, staff });
  }, [dispensaries, deposits, bankId, staff]);

  return (
    <span data-cy="create-new-fincen-report-wrapper">
      <Title>Create New FinCEN Report</Title>
      <GcvTabWizard tabs={tabs} updateData={updateData} onComplete={onComplete} onCancel={onCancel} data={data} />
    </span>
  );
};
