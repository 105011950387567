import React from 'react';
import { StatusContainer, VerticalCenter, StatusIcon } from './styles';

interface Props {
  status: 'good' | 'warning' | 'danger' | 'none';
  text: string;
  handleClick?: () => void;
  style?: {};
}

export const GcvStatusIndicator = (props: Props) => {
  if (!props.status) {
    return null;
  }

  return (
    <StatusContainer onClick={props.handleClick} style={props.style} data-cy="status-indicator">
      {props.text}
      <VerticalCenter>
        <StatusIcon status={props.status} data-cy="status-icon" />
      </VerticalCenter>
    </StatusContainer>
  );
};
