import React, { useEffect, useState, useRef } from 'react';
import { DateTime } from 'luxon';
import { Dispensary, getReportWithGreenCheckTransactionsAsListOfIdsInsteadOfObjects } from '@gcv/shared';

import { formatDateOrEmpty, formatMoney, formatStatusIndicator } from '../../../util/formatHelpers';
import { GcvUserInputContainer } from '../../../lib/GcvUserInputContainer/GcvUserInputContainer';
import { $primary, $blue, $black } from '../../../util/styleVariables';
import { useApi, getEndpoint } from '../../../hooks/';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import styled from 'styled-components';

export const EnterID = styled.button`
  border: none;
  padding: 10px 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background-color: white;
  cursor: pointer;
`;

interface DispensaryMap {
  [id: string]: Dispensary;
}

export const account = (name: string) => ({
  name: name,
  selector: 'account',
  sortable: true,
  grow: 1,
});

export const dueDate = (name: string) => ({
  name: name,
  selector: 'dueDate',
  sortable: true,
  grow: 1,
  format: row => {
    if (row.dueDate) {
      return formatDateOrEmpty(row.dueDate);
    }
  },
});

export const dateOfTransaction = (name: string) => ({
  name: name,
  selector: 'dateOfTransaction',
  sortable: true,
  grow: 1,
  format: row => {
    return formatDateOrEmpty(row.dateOfTransaction);
  },
});

export const amount = (name: string) => ({
  name: name,
  selector: 'amount',
  sortable: true,
  grow: 1,
  format: row => {
    return formatMoney(row.amount);
  },
});

export const exportedOn = (name: string) => ({
  name: name,
  selector: 'exportedOn',
  sortable: true,
  grow: 1,
  format: row => formatDateOrEmpty(row.exportedOn),
});

export const sarType = (name: string) => ({
  name: name,
  selector: 'reportType',
  sortable: true,
  grow: 1,
});

export const reportStatus = (name, clickHandler) => ({
  name: name,
  selector: 'status',
  sortable: true,
  grow: 1,
  format: row => {
    let color = '';
    let status = '';

    if (row.status === 'open') {
      color = $blue;
      status = 'Start Report';
    } else if (row.status === 'in progress') {
      status = 'Resume';
      color = $primary;
    } else {
      status = 'Completed';
      color = $black;
    }

    const onClick = () => clickHandler(row);
    return (
      <div style={{ color }} onClick={onClick}>
        {status}
      </div>
    );
  },
});

export const bsaIdUpdate = (name: string, openModal) => ({
  name: name,
  selector: 'bsaId',
  sortable: true,
  grow: 1,
  cell: row => {
    return (
      <EnterID
        onClick={() => openModal('update-bsa', row)}
        style={{
          color: !row.bsaId ? $primary : $black,
        }}
      >
        {row.bsaId ? row.bsaId : 'Edit BSA ID'}
      </EnterID>
    );
  },
});

export const trackingId = (name: string, openModal) => ({
  name: name,
  selector: 'trackingId',
  sortable: true,
  grow: 1,
  cell: row => {
    return (
      <EnterID
        onClick={() => openModal('update-tracking', row)}
        style={{
          color: !row.trackingId ? $primary : $black,
        }}
      >
        {row.trackingId ? row.trackingId : 'Edit Tracking ID'}
      </EnterID>
    );
  },
});

export const exportId = (name: string) => ({
  name: name,
  selector: 'id',
  sortable: true,
  grow: 1,
});

export const exportedBy = (name: string) => ({
  name: name,
  selector: 'exported_by',
  sortable: true,
  grow: 1,
});

export const numberOfReports = (name: string) => ({
  name: name,
  selector: 'number_of_reports',
  sortable: true,
  grow: 1,
});

export const bsaIdExport = (name: string, openModal) => ({
  name: name,
  selector: 'bsaId',
  sortable: true,
  grow: 1,
  cell: row => {
    return !row.acknowledgement_file_name ? (
      <EnterID
        onClick={() => openModal('acknowledgement', row)}
        style={{
          color: $primary,
        }}
      >
        Upload Acknowledgement File
      </EnterID>
    ) : (
      row.acknowledgement_file_name
    );
  },
});

export const bsaId = (name: string) => ({
  name: name,
  selector: 'bsaId',
  sortable: true,
  grow: 1,
});

export const finalDeposit = name => ({
  name: name,
  selector: 'final_deposit',
  sortable: true,
  grow: 2,
  center: true,
  format: row => formatMoney(row.final_deposit),
});

export const dateCreated = name => ({
  name: name,
  selector: 'date_created',
  sortable: true,
  grow: 2,
  center: true,
  format: row => DateTime.fromISO(row.date_created).toLocaleString(DateTime.DATE_MED),
});

export const createdBy = (name, staff) => ({
  name: name,
  selector: row =>
    Object.keys(staff).length && staff[row.created_by]
      ? `${staff[row.created_by]['firstName']} ${staff[row.created_by]['lastName']}`
      : '',
  sortable: true,
  grow: 2,
  center: true,
});

export const expectedArrivalDate = name => ({
  name: name,
  selector: 'expected_arrival_date',
  sortable: true,
  grow: 2,
  center: true,
  format: row => formatDateOrEmpty(row.expected_arrival_date),
});

export const arrivalDate = name => ({
  name: name,
  selector: 'arrived_date',
  sortable: true,
  grow: 2,
  center: true,
  format: row => formatDateOrEmpty(row.arrived_date),
});

export const status = (name, clickHandler) => ({
  name: name,
  selector: 'status',
  sortable: true,
  grow: 2,
  center: true,
  format: row => formatStatusIndicator(row, clickHandler),
});

export const crbAccount = (name, dispensaries) => ({
  name: name,
  selector: row => dispensaries[row.dispensary_id]['name'],
  sortable: true,
  grow: 2,
  center: true,
});

export const methodOfTransportation = name => ({
  name: name,
  selector: row => row['delMethod']['name'],
  sortable: true,
  grow: 2,
  center: true,
});
