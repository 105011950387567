import { Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { BackButtonIcon } from '../../icons/BackButtonIcon';
import { SubTitle, Title } from './styles';
import { VerticalCenter, FlexBox } from '../../styles/theme';

interface Props {
  handleBackClick?: () => void;
  title?: ReactNode;
  subTitle?: ReactNode;
  actions?: ReactNode;
  tabs?: ReactNode;
}

export const GcvPageHeader: React.FC<Props> = props => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={props.actions ? 6 : 12}>
        <Grid container>
          <Grid item xs={12}>
            <FlexBox>
              {props.handleBackClick ? (
                <BackButtonIcon onClick={props.handleBackClick} style={{ marginRight: '1rem' }} />
              ) : null}
              <VerticalCenter>
                <Title onClick={props.handleBackClick} data-cy="title">
                  {props.title}
                </Title>
              </VerticalCenter>
            </FlexBox>
          </Grid>
          {props.subTitle ? (
            <Grid item xs={12}>
              <SubTitle data-cy="subTitle">{props.subTitle}</SubTitle>
            </Grid>
          ) : null}
          {props.tabs ? (
            <Grid item xs={12} style={{ margin: '1rem 0 .5rem 0' }}>
              {props.tabs}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {props.actions ? (
        <Grid item xs={12} sm={12} md={6}>
          {props.actions}
        </Grid>
      ) : null}
      {props.children ? (
        <Grid item xs={12}>
          {props.children}
        </Grid>
      ) : null}
    </Grid>
  );
};
