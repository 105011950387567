import { createGlobalStyle } from 'styled-components';
import {
  $accent,
  $white,
  $black,
  $fontFamily,
  $grey2,
  $grey4,
  $grey6,
  $primaryBorder,
} from '../../util/styleVariables';

export const DataTableStyleOverrides = createGlobalStyle`
.rdt_Table {
  border-right: 1px solid #EBEEF7;
  border-left: 1px solid #EBEEF7;
  border-bottom: none;
  border-radius: 10px;
}

.rdt_Table, .rdt_TableFooter {
  font-family: ${$fontFamily};

}

.rdt_TableHeader {
  display: none;
}

.rdt_TableRow {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${$black};
  background: #fff;
  
  &:not(:first-child) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: ${$primaryBorder};
  }
}

.rdt_TableHeadRow,
.rdt_TableFooter {
  border-bottom-color: ${$primaryBorder};
}

.rdt_TableHeadRow {
  background: ${$grey4};
  border: 1px solid ${$grey6};
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${$accent};
  height: 40px;
}

.rdt_TableFooter, .rdt_Pagination {
  justify-content: center;

  color: ${$grey2};

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  svg {
    fill: ${$grey2};
  }
}
`;
