import { Environment } from './env-interface';
import { GreenCheckAwsEnvironmentName } from '@gcv/shared';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  amplify: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:1fd6dad7-8ab3-4370-9879-72ba79de1714',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_BZ33HXqWU',
    aws_user_pools_web_client_id: '433jh5d25md39a6mrk1ps0p6cd',
  },
  gcvConfig: {
    launchDarklyClientSideId: '5c12828be662e0172dd11e7f',
    webSocketUrl: 'wss://4vymdgbb46.execute-api.us-east-1.amazonaws.com/Prod',
  },
  env: GreenCheckAwsEnvironmentName.INTEGRATION,
  basePath: 'https://integrationapi.greencheckverified.com',
  storageConfig: {
    salesFile: 'integration-new-data-file-bucket',
    document: 'integration-gcv-company-document-store',
    regulatoryGuide: '',
    coreTransactions: 'integration-core-transaction-data',
    userDocument: 'integration-user-documents-file-bucket',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
