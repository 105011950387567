<mat-form-field [formGroup]="group" class="form-field">
  <input
    [formControlName]="control"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    data-cy="autocomplete-select.component"
    matInput
    type="text"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
    <mat-option
      [attr.data-cy]="'autocomplete-select.component.options'"
      *ngFor="let option of (filteredOptions | async)"
      [value]="option"
    >
      {{ option.viewValue }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
