<gcv-card class="col-4" data-cy="provided-services-transportation">
  <div card-body class="innerWrapper">
    <div class="headerWrapper">
      <div class="header">
        <h4 data-cy="title">{{ title }}</h4>
        <span data-cy="subtext">{{ subtitle }}</span>
      </div>
      <div class="editWrapper">
        <svg
          (click)="edit()"
          *ngIf="editActive"
          class="editCard"
          fill="none"
          height="16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
          data-cy="active-edit-toggle"
        >
          <path
            clip-rule="evenodd"
            d="M0 12v3.3c0 .2 0 .4.2.5l.5.2H4c.2 0 .3 0 .5-.2L15.8 4.5c.2-.3.2-.7 0-1L12.5.2a.7.7 0 0 0-1 0L9.3 2.5l-9 9c-.2.1-.3.3-.3.5zM12 1.7L14.3 4 13 5.2 10.8 3 12 1.7zM1.4 12.3L9.8 4 12 6.2l-8.3 8.4H1.4v-2.3z"
            fill="#00BC66"
            fill-rule="evenodd"
          />
        </svg>
        <svg
          (click)="edit()"
          *ngIf="!editActive"
          class="editCard"
          fill="none"
          height="16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
          data-cy="inactive-edit-toggle"
        >
          <path
            clip-rule="evenodd"
            d="M0 12v3.3c0 .2 0 .4.2.5l.5.2H4c.2 0 .3 0 .5-.2L15.8 4.5c.2-.3.2-.7 0-1L12.5.2a.7.7 0 0 0-1 0L9.3 2.5l-9 9c-.2.1-.3.3-.3.5zM12 1.7L14.3 4 13 5.2 10.8 3 12 1.7zM1.4 12.3L9.8 4 12 6.2l-8.3 8.4H1.4v-2.3z"
            fill="#DBE1ED"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div *ngIf="!editActive; else editTemplate" class="mainWrapper">
      <div
        *ngFor="let vendor of vendors; let i = index"
        data-cy="vendor-bank"
        [ngClass]="i === vendors.length - 1 ? 'lastItem' : 'item'"
        class="userWrapper"
      >
        <span class="active" data-cy="vendor-name">{{ vendor.name }}</span>
        <div class="iconWrapper">
          <svg
            (click)="handleFavorite(vendor, i)"
            *ngIf="vendor.favorite"
            class="starImg imgActive"
            fill="none"
            height="18"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
            data-cy="active-favorite-toggle"
          >
            <path
              d="M7.6 1a1 1 0 0 1 1.8 0l2 4.3 4.7.7a1 1 0 0 1 .5 1.7L13.2 11 14 16a1 1 0 0 1-1.4 1l-4.1-2.2-4 2.2A1 1 0 0 1 3 16l.8-4.9L.4 7.7A1 1 0 0 1 .9 6l4.7-.7 2-4.4z"
              fill="#02B45B"
            />
          </svg>
          <svg
            (click)="handleFavorite(vendor, i)"
            *ngIf="!vendor.favorite"
            class="starImg imgActive"
            fill="none"
            height="18"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
            data-cy="inactive-favorite-toggle"
          >
            <path
              d="M10.9 5.5l.1.3h.3l4.7.8c.3 0 .4.4.2.6l-3.4 3.5-.2.2v.3l.8 4.9c.1.3-.2.5-.5.4l-4.1-2.3-.3-.1-.3.1-4 2.3c-.4.1-.7-.1-.6-.4l.7-4.9V11l-.1-.2L.8 7.2a.4.4 0 0 1 .2-.6l4.7-.8H6l.1-.3 2-4.3c.2-.3.6-.3.8 0l2 4.3z"
              stroke="#DBE1ED"
              stroke-width="1.2"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</gcv-card>

<ng-template #editTemplate>
  <div class="mainWrapper">
    <div *ngFor="let vendor of allVendors; let i = index" class="userWrapper item">
      <span class="active">{{ vendor.name }}</span>
      <div class="iconWrapper">
        <svg
          (click)="toggleActive(vendor, i)"
          *ngIf="isAlreadyAdded(vendor)"
          class="imgActive remove"
          fill="none"
          height="22"
          viewBox="0 0 22 22"
          width="22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" fill="#DBE1ED" r="11" />
          <path
            d="M7.03906 11.4399L14.7391 11.4399"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.5"
          />
        </svg>
        <svg
          (click)="toggleActive(vendor, i)"
          *ngIf="!isAlreadyAdded(vendor)"
          class="imgActive add"
          fill="none"
          height="22"
          viewBox="0 0 22 22"
          width="22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" fill="#02B45B" r="11" />
          <path
            d="M7.03906 11.4399L14.7391 11.4399"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.5"
          />
          <path
            d="M10.8906 15.29L10.8906 7.59004"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.5"
          />
        </svg>
      </div>
    </div>

    <div class="userWrapper lastItem">
      <span data-cy="add-another-method" class="favorite">Add Another Method</span>
      <div class="iconWrapper">
        <svg
          (click)="addNewMethod()"
          class="imgActive addNew"
          fill="none"
          height="22"
          viewBox="0 0 22 22"
          width="22"
          xmlns="http://www.w3.org/2000/svg"
          data-cy="add-another-method-button"
        >
          <circle cx="11" cy="11" fill="#02B45B" r="11" />
          <path
            d="M7.03906 11.4399L14.7391 11.4399"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.5"
          />
          <path
            d="M10.8906 15.29L10.8906 7.59004"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.5"
          />
        </svg>
      </div>
    </div>
  </div>
</ng-template>
