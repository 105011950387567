import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TermsOfServiceComponent } from './terms-of-service.component';
import { GcvCommonModule } from '../../components/common.module';
import { TERMS_OF_SERVICE_ROUTES } from './terms-of-service.routes';
import { CanActivateTermsOfService } from './terms-of-service.can-activate';

@NgModule({
  declarations: [TermsOfServiceComponent],
  imports: [CommonModule, GcvCommonModule, RouterModule.forChild(TERMS_OF_SERVICE_ROUTES)],
  providers: [CanActivateTermsOfService],
  exports: [TermsOfServiceComponent, RouterModule],
})
export class TermsOfServiceModule {}
