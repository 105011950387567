import React from 'react';
import { MinifiedDispensary, DueDiligenceStatus } from '@gcv/shared';
import { AccountCardContainer } from './AccountCardContainer';
import { name, city, licenseType, reviewedOn, complianceScore } from '../columns';

interface Props {
  dispensaries: MinifiedDispensary[];
  tableView: boolean;
  isFiltered: boolean;
  emitData: (e) => void;
}

export const Active = ({ dispensaries, tableView, isFiltered, emitData }: Props) => {
  const activeDispensaries = dispensaries.filter(
    dispensary => dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED
  );

  const emitDataActive = dispensaryId => {
    emitData({ type: 'active', dispensaryId });
  };

  const columns = [
    name('Name'),
    city('City'),
    licenseType('License Type'),
    reviewedOn('Reviewed On'),
    complianceScore('Compliance Score', emitDataActive),
  ];

  return (
    <AccountCardContainer
      accounts={activeDispensaries}
      tableView={tableView}
      emitData={emitDataActive}
      tableColumns={columns}
      sortKey={'compliance_score'}
      noAccountsZeroStateText={'You currently have no active accounts'}
      isFiltered={isFiltered}
    />
  );
};
