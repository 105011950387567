import styled from 'styled-components';

import { $black2 } from '../../util/styleVariables';

export const Wrapper = styled.div`
   {
    position: relative;
  }
`;

export const InitialContainer = styled.div`
   {
    position: relative;
    width: 37px;
    height: 37px;
    background: #f0f2f9;
    border: 1px solid #dbe1ed;
    box-sizing: border-box;
    border-radius: 50%;
    color: ${$black2};
    font-weight: 700;
    line-height: 16px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: default;
  }
`;
