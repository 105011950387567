import { NgModule } from '@angular/core';
import { CardComponent } from './components/card/card.component';
import { ButtonComponent } from './components/button/button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AutocompleteSelectComponent } from './components/autocomplete-select/autocomplete-select.component';
import { GcvVendorModule } from './vendors/vendor.module';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CollapsingShelfComponent } from './components/collapsing-shelf/collapsing-shelf.component';
import { MatInputComponent } from './components/material-input/mat-input.component';
// tslint:disable-next-line:max-line-length
import { CreateUserDialogComponent } from './components/create-user-dialog/create-user-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirmDialog.component';
import { SupportCardComponent } from './components/support-card/support-card.component';
import { ViewTransportationVendorComponent } from './components/transportation/view-transportation-vendor/view-transportation-vendor.component';
import { DispensaryDueDiligenceComponent } from './components/dispensary-due-diligence/dispensary-due-diligence.component';
import { DueDiligenceWrapperComponent } from './components/due-diligence-wrapper/due-diligence-wrapper.component';
import { EditUserComponent } from './components/edit-user/edit-user-dialog.component';
import { PipesModule } from './pipes/pipes.module';
import { EditableCardComponent } from './components/editable-card/editable-card.component';
import { EditableCardHoursComponent } from './components/editable-card-hours/editable-card-hours.component';
import { InputComponent } from './components/input/input.component';
import { ImageUploadButtonComponent } from './components/image-upload-button/image-upload-button.component';
import { InitialDashboardComponent } from './components/initial-dashboard/initial-dashboard.component';
import { MotDetailDialogComponent } from './components/mot-detail-dialog/mot-detail-dialog.component';
import { MotWarningDialogComponent } from './components/mot-warning-dialog/mot-warning-dialog';
import { NavigationButtonComponent } from './components/navigation-button/navigation-button.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SpinnerIconComponent } from './components/spinner-icon/spinner-icon.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { TabGroupComponent } from './components/tab-group/tab-group.component';
import { TimefilterComponent } from './components/timefilter/timefilter.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
// tslint:disable-next-line:max-line-length
import { BankTransportationVendorsComponent } from './components/transportation/bank-transportation-vendors/bank-transportation-vendors.component';
// tslint:disable-next-line:max-line-length
import { EditableBankTransportVendorsComponent } from './components/transportation/editable-bank-transport-vendors/editable-bank-transport-vendors.component';
import { VerifiedUsersTransportationComponent } from './components/transportation/verified-users/verified-users-transportation.component';
import { TransportationVendorsComponent } from './components/transportation/transportation-vendors/transportation-vendors.component';
import { PaginationControlComponent } from './components/pagination-control/pagination-control.component';
import { InitialLogoComponent } from './components/initial-logo/initial-logo.component';
import { SalesSideDrawerComponent } from './components/sales-side-drawer/sales-side-drawer.component';
import { UserWrapperComponent } from './components/user-groups/user-wrapper/user-wrapper.component';
import { UserGroupOverviewComponent } from './components/user-groups/user-overview/user-group-overview.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { UserGroupArchiveComponent } from './components/user-groups/user-archive/user-group-archive.component';
import { UserGroupCardComponent } from './components/user-groups/user-group/group-card/user-group-card.component';
import { UserGroupComponent } from './components/user-groups/user-group/user-group.component';
import { CreateGroupCardComponent } from './components/user-groups/user-group/create-group-card/create-group-card.component';
import { CreateGroupDialogComponent } from './components/user-groups/user-group/create-group-dialog/create-group-dialog.component';
// tslint:disable-next-line:max-line-length
import { EditGroupAddUserDialogComponent } from './components/user-groups/user-group/edit-group-wrapper/edit-group-add-user-dialog/edit-group-add-user-dialog.component';
import { EditGroupWrapperComponent } from './components/user-groups/user-group/edit-group-wrapper/edit-group-wrapper.component';
import { FileButtonComponent } from './components/button/file-button.component';
import { ZeroStateComponent } from './components/zero-state/zero-state.component';
import { DashboardWrapperComponent } from './components/dispensary-dashboard-wrapper/dashboard-wrapper.component';
import { UploadButtonComponent } from './components/document-upload-button/upload-button.component';
import { CreateTransportationVendorComponent } from './components/create-transportation-vendor/create-transportation-vendor.component';
import { DataChartComponent } from './components/data-chart/data-chart.component';
import { OnboardingProgressBarComponent } from './components/onboarding-progress-bar/onboarding-progress-bar.component';
import { DueDiligenceDetailsDialogComponent } from './components/due-diligence-details-dialog/due-diligence-details-dialog.component';
import { OnboardingChecklistMenuComponent } from './components/onboarding-checklist-menu/onboarding-checklist-menu.component';
import { ReviewAccountOwnerCardComponent } from './components/review-account-owner-card/review-account-owner-card.component';
import { DueDiligenceRequirementCardComponent } from './components/due-diligence-requirement-card/due-diligence-requirement-card.component';
import { BankStatusIndicatorComponent } from './components/bank-status-indicator/bank-status-indicator.component';
import { CreateNewDdModalComponent } from './components/create-new-dd-modal/create-new-dd-modal.component';
import { DueDiligenceRequirementTableComponent } from './components/due-diligence-requirement-table/due-diligence-requirement-table.component';
import { ReviewAccountDocumentsTableComponent } from './components/review-account-documents-table/review-account-documents-table.component';
import { OnboardingPreviewWrapperComponent } from './components/onboarding-preview-wrapper/onboarding-preview-wrapper.component';
import { DueDiligenceRequirementListComponent } from './components/due-diligence-requirement-list/due-diligence-requirement-list.component';
import { OnboardingEndWrapperComponent } from './components/onboarding-end-wrapper/onboarding-end-wrapper.component';
import { EditableCardViewIdComponent } from './components/editable-card-view-id/editable-card-view-id.component';
import { ViewAccountDocumentsTableComponent } from './components/view-account-documents-table/view-account-documents-table.component';
import { ViewLicenseTableComponent } from './components/view-license-table/view-license-table.component';
import { HelpTextDialogComponent } from './components/help-text-dialog/help-text-dialog.component';
import { ReviewDocumentCommentsComponent } from './components/review-document-comments/review-document-comments.component';
import { ComplianceScoreBadgeComponent } from './components/compliance-score-badge/compliance-score-badge.component';
@NgModule({
  declarations: [
    HelpTextDialogComponent,
    CardComponent,
    ButtonComponent,
    DashboardWrapperComponent,
    InitialLogoComponent,
    AutocompleteSelectComponent,
    CheckboxComponent,
    UserGroupCardComponent,
    OnboardingChecklistMenuComponent,
    CollapsingShelfComponent,
    CreateTransportationVendorComponent,
    MatInputComponent,
    CreateUserDialogComponent,
    UploadButtonComponent,
    DispensaryDueDiligenceComponent,
    DueDiligenceWrapperComponent,
    EditUserComponent,
    ConfirmDialogComponent,
    EditableCardComponent,
    EditableCardHoursComponent,
    DataChartComponent,
    DueDiligenceRequirementListComponent,
    InputComponent,
    ImageUploadButtonComponent,
    UserGroupComponent,
    CreateGroupCardComponent,
    InitialDashboardComponent,
    MotDetailDialogComponent,
    MotWarningDialogComponent,
    NavigationButtonComponent,
    EditGroupAddUserDialogComponent,
    SalesSideDrawerComponent,
    NotFoundComponent,
    SpinnerIconComponent,
    SpinnerComponent,
    SupportCardComponent,
    CreateGroupDialogComponent,
    EditGroupWrapperComponent,
    NotificationCardComponent,
    ProfileCardComponent,
    TabGroupComponent,
    TimefilterComponent,
    TimeoutComponent,
    BankTransportationVendorsComponent,
    ViewTransportationVendorComponent,
    VerifiedUsersTransportationComponent,
    EditableBankTransportVendorsComponent,
    TransportationVendorsComponent,
    PaginationControlComponent,
    FileButtonComponent,
    ZeroStateComponent,
    UserWrapperComponent,
    DataTableComponent,
    UserGroupArchiveComponent,
    UserGroupOverviewComponent,
    OnboardingProgressBarComponent,
    DueDiligenceDetailsDialogComponent,
    ReviewAccountOwnerCardComponent,
    DueDiligenceRequirementCardComponent,
    BankStatusIndicatorComponent,
    CreateNewDdModalComponent,
    DueDiligenceRequirementTableComponent,
    ReviewAccountDocumentsTableComponent,
    OnboardingPreviewWrapperComponent,
    OnboardingEndWrapperComponent,
    EditableCardViewIdComponent,
    ViewAccountDocumentsTableComponent,
    ViewLicenseTableComponent,
    ReviewDocumentCommentsComponent,
    ComplianceScoreBadgeComponent,
  ],
  imports: [ReactiveFormsModule, FormsModule, GcvVendorModule, CommonModule, PipesModule],
  exports: [
    HelpTextDialogComponent,
    CardComponent,
    BankStatusIndicatorComponent,
    DashboardWrapperComponent,
    DueDiligenceRequirementListComponent,
    ZeroStateComponent,
    DueDiligenceRequirementCardComponent,
    UserGroupCardComponent,
    CreateGroupDialogComponent,
    ReviewAccountOwnerCardComponent,
    FileButtonComponent,
    UploadButtonComponent,
    CreateGroupCardComponent,
    InitialLogoComponent,
    OnboardingChecklistMenuComponent,
    ReviewDocumentCommentsComponent,
    UserGroupComponent,
    DataChartComponent,
    EditGroupWrapperComponent,
    UserGroupArchiveComponent,
    DataTableComponent,
    OnboardingProgressBarComponent,
    UserGroupOverviewComponent,
    EditGroupAddUserDialogComponent,
    UserWrapperComponent,
    SalesSideDrawerComponent,
    TimefilterComponent,
    PaginationControlComponent,
    EditableBankTransportVendorsComponent,
    TransportationVendorsComponent,
    VerifiedUsersTransportationComponent,
    TimeoutComponent,
    BankTransportationVendorsComponent,
    ViewTransportationVendorComponent,
    TabGroupComponent,
    NotificationCardComponent,
    ProfileCardComponent,
    SupportCardComponent,
    SpinnerComponent,
    SpinnerIconComponent,
    MotWarningDialogComponent,
    MotDetailDialogComponent,
    InitialDashboardComponent,
    ButtonComponent,
    AutocompleteSelectComponent,
    CheckboxComponent,
    CollapsingShelfComponent,
    CreateTransportationVendorComponent,
    MatInputComponent,
    CreateUserDialogComponent,
    DispensaryDueDiligenceComponent,
    DueDiligenceWrapperComponent,
    EditUserComponent,
    ConfirmDialogComponent,
    EditableCardComponent,
    EditableCardHoursComponent,
    EditableCardViewIdComponent,
    InputComponent,
    ImageUploadButtonComponent,
    NavigationButtonComponent,
    NotFoundComponent,
    DueDiligenceRequirementTableComponent,
    ReviewAccountDocumentsTableComponent,
    OnboardingPreviewWrapperComponent,
    OnboardingEndWrapperComponent,
    ViewAccountDocumentsTableComponent,
    ViewLicenseTableComponent,
    ComplianceScoreBadgeComponent,
  ],
  entryComponents: [
    HelpTextDialogComponent,
    CreateTransportationVendorComponent,
    ViewTransportationVendorComponent,
    CreateUserDialogComponent,
    EditUserComponent,
    ReviewDocumentCommentsComponent,
    ConfirmDialogComponent,
    MotWarningDialogComponent,
    MotDetailDialogComponent,
    CreateGroupDialogComponent,
    EditGroupAddUserDialogComponent,
    DueDiligenceDetailsDialogComponent,
    CreateNewDdModalComponent,
  ],
})
export class GcvUiModule {}
