import { Injectable } from '@angular/core';
import { Action, Bank, Dispensary, OrganizationRoleResolver, User, Role } from '@gcv/shared';
import { BehaviorSubject } from 'rxjs';
import { BankFacade, DispensaryFacade, UserFacade } from '@user-interface/gcv-state';
import { filter, takeWhile, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoleResolverService {
  public updateUI = new BehaviorSubject(false);
  private organization: Bank | Dispensary;
  private orgType: string;
  private user: User;

  constructor(private userFacade: UserFacade, private bankFacade: BankFacade, private dispFacade: DispensaryFacade) {
    this.userFacade
      .selectCurrentUser()
      .pipe(
        filter((user: User) => user !== undefined),
        tap((user: User) => {
          this.orgType = user.companies[0].companyType;
        })
      )
      .subscribe((user: User) => (this.user = user));

    this.bankFacade
      .selectCurrentBank()
      .pipe(
        filter((bank: Bank) => bank !== undefined),
        takeWhile(() => this.orgType === 'bank')
      )
      .subscribe((bank: Bank) => {
        this.organization = bank;
        this.updateUI.next(true);
      });

    this.dispFacade
      .selectCurrentDispensary()
      .pipe(
        filter((disp: Dispensary) => disp !== undefined),
        takeWhile(() => this.orgType === 'dispensary')
      )
      .subscribe((disp: Dispensary) => {
        this.organization = disp;
        this.updateUI.next(true);
      });
  }

  checkPermission(permissions: Action[]) {
    const resolver = new OrganizationRoleResolver();
    let hasPermission = false;
    if (permissions && this.user && this.organization) {
      permissions.forEach((permission: Action) => {
        if (resolver.userCanDoAction(this.organization.groups, this.user, permission)) {
          hasPermission = true;
          return;
        }
        return;
      });
      return hasPermission;
    } else {
      return hasPermission;
    }
  }

  hasRole(role: Role) {
    const resolver = new OrganizationRoleResolver();
    if (resolver.userHasRole(this.organization.groups, this.user.id, role)) {
      return true;
    } else {
      return false;
    }
  }
}
