import styled, { css } from 'styled-components';
import { $blue } from '../../util/styleVariables';

export const ReadMoreButton = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${$blue};
  &: hover {
    cursor: pointer;
  }
`;
