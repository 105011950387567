import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { isUndefined } from 'underscore';

@Component({
  selector: 'gcv-create-new-dd-modal',
  templateUrl: './create-new-dd-modal.component.html',
  styleUrls: ['./create-new-dd-modal.component.scss'],
})
export class CreateNewDdModalComponent implements OnInit {
  section = 0;
  results: FormGroup;
  category = '';
  staticCategory = false;
  edit = false;
  canDelete = true;
  types = [
    { value: 'legal', viewValue: 'Legal' },
    { value: 'financial', viewValue: 'Financial' },
    { value: 'operational', viewValue: 'Operational' },
  ];

  constructor(
    private matDialogRef: MatDialogRef<CreateNewDdModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  ngOnInit() {
    if (this.data) {
      const { category, staticCategory, edit, canDelete } = this.data;
      if (category) {
        this.category = category;
        this.staticCategory = staticCategory;
      }
      if (edit) {
        this.edit = edit;
      }
      if (!isUndefined(canDelete)) {
        this.canDelete = canDelete;
      }
    }
    this.results = new FormGroup({
      name: new FormControl(
        this.data && this.data.name ? this.data.name : '',
        Validators.compose([Validators.minLength(3), Validators.maxLength(30), Validators.required])
      ),
      description: new FormControl(
        this.data && this.data.description ? this.data.description : '',
        Validators.compose([Validators.minLength(5), Validators.maxLength(300), Validators.required])
      ),
      category: new FormControl(this.data && this.data.category ? this.data.category : '', Validators.required),
    });
  }

  commentInput(e) {
    this.results.controls.description.setValue(e.srcElement.value);
  }

  cancelClick() {
    this.matDialogRef.close();
  }

  deleteRequirement() {
    this.matDialogRef.close({
      ...this.results.getRawValue(),
      delete: true,
    });
  }

  createClick() {
    this.matDialogRef.close({
      ...this.results.getRawValue(),
    });
  }
}
