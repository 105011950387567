<gcv-card class="col-4" data-cy="professional-services-transportation">
  <div card-body class="innerWrapper">
    <div class="header">
      <h4 data-cy="title">Professional Services</h4>
      <span data-cy="subtext">These are the service providers that are managed by the business.</span>
    </div>
    <div class="mainWrapper">
      <div
        *ngFor="let vendor of vendors; let i = index"
        data-cy="vendor-service"
        [ngClass]="i === vendors.length - 1 ? 'lastItem' : 'item'"
        class="userWrapper"
      >
        <span data-cy="vendor-name" [ngClass]="vendor.active ? 'active' : 'inactive'">{{ vendor.name }}</span>
        <div class="iconWrapper">
          <svg
            (click)="handleEdit(vendor)"
            *ngIf="dispensaryView && vendor.active"
            class="imgActive"
            fill="none"
            height="16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M0 12v4h4L16 4V3l-3-3h-1L9 2 0 12zM12 2l2 2-1 1-2-2 1-1zM1 12l9-8 2 2-8 9H1v-3z"
              fill="#DBE1ED"
              fill-rule="evenodd"
            />
          </svg>
          <svg
            (click)="handleFavorite(vendor, i)"
            *ngIf="dispensaryView && vendor.active && vendor.favorite"
            class="starImg imgActive"
            fill="none"
            height="18"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.6 1a1 1 0 0 1 1.8 0l2 4.3 4.7.7a1 1 0 0 1 .5 1.7L13.2 11 14 16a1 1 0 0 1-1.4 1l-4.1-2.2-4 2.2A1 1 0 0 1 3 16l.8-4.9L.4 7.7A1 1 0 0 1 .9 6l4.7-.7 2-4.4z"
              fill="#02B45B"
            />
          </svg>
          <svg
            (click)="handleFavorite(vendor, i)"
            *ngIf="dispensaryView && vendor.active && !vendor.favorite"
            [attr.data-cy]="'transportation-vendors.component.defaultStar.' + vendor.name"
            class="starImg imgActive"
            fill="none"
            height="18"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9 5.5l.1.3h.3l4.7.8c.3 0 .4.4.2.6l-3.4 3.5-.2.2v.3l.8 4.9c.1.3-.2.5-.5.4l-4.1-2.3-.3-.1-.3.1-4 2.3c-.4.1-.7-.1-.6-.4l.7-4.9V11l-.1-.2L.8 7.2a.4.4 0 0 1 .2-.6l4.7-.8H6l.1-.3 2-4.3c.2-.3.6-.3.8 0l2 4.3z"
              stroke="#DBE1ED"
              stroke-width="1.2"
            />
          </svg>
          <svg
            *ngIf="dispensaryView && !vendor.active && !vendor.favorite"
            [attr.data-cy]="'transportation-vendors.component.defaultStar.' + vendor.name"
            class="starImg imgInactive"
            fill="none"
            height="18"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5.5l.1.2h.3l4.6.8c.4 0 .6.5.3.8l-3.4 3.5-.2.2v.2l.8 4.8c.1.5-.3.8-.7.6l-4-2.3-.3-.1-.2.1-4.1 2.3a.5.5 0 0 1-.7-.6l.7-4.8v-.4L.6 7.3a.5.5 0 0 1 .3-.8l4.6-.8H6l.1-.2L8 1c.2-.3.8-.3 1 0l2 4.4z"
              stroke="#F0F2F9"
            />
          </svg>
          <svg
            (click)="handleView(vendor)"
            *ngIf="!dispensaryView"
            [attr.data-cy]="'transportation-vendors.component.defaultStar.' + vendor.name"
            class="imgActive"
            fill="none"
            height="17"
            width="23"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 8c-2-4-6-7-10-7C6 1 2 5 1 8v1c2 3 6 7 11 7s9-4 10-7V8zm-10 6c-4 0-7-2-9-6 2-3 5-5 9-5 3 0 6 2 8 5-2 4-5 6-8 6zm0-10a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 7a2 2 0 1 1 0-5 2 2 0 0 1 0 5z"
              fill="#3a3c45"
              stroke="#fff"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</gcv-card>
