<h2 class="title">{{ title }}</h2>
<mat-table [dataSource]="transformedDocs" class="table" matSort>
  <ng-container matColumnDef="license_name">
    <mat-header-cell *matHeaderCellDef class="requirement" mat-sort-header>License Name</mat-header-cell>
    <mat-cell *matCellDef="let doc" class="requirement">{{
      dispensary ? doc.license_name : (doc.license_name | titlecase)
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="license_number">
    <mat-header-cell *matHeaderCellDef mat-sort-header>License Number</mat-header-cell>
    <mat-cell *matCellDef="let doc" class="type">{{ doc.license_number }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="expiration_date">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Date</mat-header-cell>
    <mat-cell *matCellDef="let doc">{{ doc.expiration_date | date: 'shortDate' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="file_name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>File Name</mat-header-cell>
    <mat-cell *matCellDef="let doc" class="type">{{ doc.file_name | fileNamePipe }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="upload_date">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Date Added</mat-header-cell>
    <mat-cell *matCellDef="let doc">{{ doc.upload_date | date: 'shortDate' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="user_uploaded">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Added By</mat-header-cell>
    <mat-cell *matCellDef="let doc">{{ doc.user_uploaded }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" class="row table-header"></mat-header-row>
  <mat-row
    (click)="handleRowClick(row)"
    *matRowDef="let row; columns: displayedColumns"
    class="row shadowHover"
  ></mat-row>
</mat-table>
