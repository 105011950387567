import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { GcvButton, GcvCard, GcvInputForm } from 'libs/react-ui/src/lib';
import { EditIcon } from 'libs/react-ui/src/icons/EditIcon';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { useApi } from 'libs/react-ui/src/hooks';
import { Bank } from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';

interface Props {
  bank: Bank;
  canEditDetails: boolean;
}

export const RegulatoryReporting: React.FC<Props> = props => {
  const apiClient = api();
  const { handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });
  const { watch } = form;

  const [editOrganizationForm, setEditOrganizationForm] = useState(true);

  useEffect(() => {
    // edit state must be true on first render in order for rules to work
    // on second render 'useEffect lifecycle' we set it to false
    setEditOrganizationForm(false);
  }, []);

  const saveOrganizationDetails = async payload => {
    await apiClient.banks.updateBankRegulatoryReporting(
      props.bank.id,
      {
        ein: watch('ein'),
        regulatorContactOffice: watch('regulatorContactOffice'),
        primaryRegulatorPhone: watch('primaryRegulatorPhone'),
        transmitter_control_code: watch('transmitter_control_code'),
      },
      () => {}
    );

    setEditOrganizationForm(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <GcvCard>
          <GcvCard.Header
            title={'Organization'}
            actions={
              !editOrganizationForm && props.canEditDetails ? (
                <EditIcon onClick={() => setEditOrganizationForm(true)} />
              ) : (
                undefined
              )
            }
          />
          <GcvCard.Body>
            <GcvInputForm
              {...form}
              name="ein"
              label="Employer Identification Number"
              type="text"
              defaultValue={props.bank.ein}
              readonly={!editOrganizationForm}
              rules={{
                required: editOrganizationForm,
                minLength: 9,
                maxLength: 9,
              }}
            />
            <GcvInputForm
              {...form}
              name="regulatorContactOffice"
              label="Contact Office"
              type="text"
              defaultValue={props.bank.regulatorContactOffice}
              readonly={!editOrganizationForm}
              rules={{
                required: editOrganizationForm,
              }}
            />

            <GcvInputForm
              {...form}
              name="primaryRegulatorPhone"
              label="Contact Office Number"
              type="phone"
              defaultValue={props.bank.primaryRegulatorPhone}
              readonly={!editOrganizationForm}
              rules={{
                required: editOrganizationForm,
              }}
            />

            <GcvInputForm
              {...form}
              name="transmitter_control_code"
              label="Transmitter Control Code"
              type="text"
              defaultValue={props.bank.transmitter_control_code}
              readonly={!editOrganizationForm}
              rules={{
                required: editOrganizationForm,
              }}
            />

            {editOrganizationForm && (
              <Grid container spacing={2}>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <GcvButton tertiary onClick={() => setEditOrganizationForm(false)}>
                    Cancel
                  </GcvButton>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <GcvButton primary disabled={!formState.isValid} onClick={handleSubmit(saveOrganizationDetails)}>
                    Save
                  </GcvButton>
                </Grid>
              </Grid>
            )}
          </GcvCard.Body>
        </GcvCard>
      </Grid>
    </Grid>
  );
};
