import React from 'react';
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon } from './styles';

interface CheckboxProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  name?: string;
  ref?: any;
  onChange?: any;
}

export const GcvCheckbox = React.forwardRef(
  ({ className, checked, ...props }: CheckboxProps, ref: React.Ref<HTMLInputElement>) => (
    <CheckboxContainer className={className} data-cy="gcv-checkbox">
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked} ref={ref} disabled={props.disabled}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  )
);

export default GcvCheckbox;
