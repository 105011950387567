import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orgSearch' })
export class OrgFilterPipe implements PipeTransform {
  public transform(orgs: any[], searchText: any): any {
    if (!searchText || !orgs) {
      return orgs;
    }

    return orgs.filter(o => {
      let name = '';

      if (o.orgType === 'dispensary') {
        name = o.name || '';
      } else if (o.orgType === 'bank') {
        name = o.orgName || '';
      }

      return name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    });
  }
}
