import * as fromDispensary from './dispensary.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationRoleResolver } from '@gcv/shared';

export const selectDispensaryState = createFeatureSelector<fromDispensary.DispensaryState>('dispensary');

export const selectDispensaryIds = createSelector(selectDispensaryState, fromDispensary.selectDispensaryIds);

export const selectDispensaryEntities = createSelector(selectDispensaryState, fromDispensary.selectDispensaryEntities);

export const selectDispensaryAll = createSelector(selectDispensaryState, fromDispensary.selectAllDispensary);

export const selectDispensaryById = createSelector(selectDispensaryEntities, (dispensaryDictionary, props) => {
  return dispensaryDictionary[props.dispensaryId];
});

export const selectDispensaryAccountOwnerIds = createSelector(selectDispensaryById, dispensary => {
  if (dispensary) {
    return new OrganizationRoleResolver().getGroupsWithRole(dispensary.groups, 'dispensary_account_owner')[0];
  } else {
    throw new Error(`unable to lookup dispensary in store with id: ${selectDispensaryById}`);
  }
});

export const selectGcvdueDiligenceTemplate = createSelector(selectDispensaryState, state => state.gcvDueDiligence);

export const selectCurrentDispensaryId = createSelector(selectDispensaryState, state => state.currentDispensary);

export const selectCurrentDispensary = createSelector(selectDispensaryState, state =>
  state.currentDispensary ? state.entities[state.currentDispensary] : state.entities[Object.keys(state.entities)[0]]
);
