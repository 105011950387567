import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
`;

export const LinkContainer = styled.div`
  margin: 0 1.5rem 0 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  font-family: Lato, Helvetica, Arial, san-serif;
`;
