import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Bank, DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE, DueDiligenceRequirementTemplate } from '@gcv/shared';

import * as base64arraybuffer from 'base64-arraybuffer';
import mime from 'mime-types';
import {
  GcvDataTable,
  GcvInputTextArea,
  GcvDrawer,
  GcvReadMore,
  GcvButton,
  GcvInputSelect,
  GcvInputForm,
  GcvInputFile,
  GcvLoading,
  GcvContent,
} from '../../../lib';
import { BackButtonIcon } from '../../../icons/BackButtonIcon';
import styled, { createGlobalStyle } from 'styled-components';
import { $blue, $grey2, $grey5 } from '../../../util/styleVariables';
import { api } from 'libs/react-ui/src/api';
import { s3Util } from 'libs/react-ui/src/util/s3.util';
import { environment } from 'apps/user-interface/src/environments/environment';
import { HorizontalCenter } from 'libs/react-ui/src/styles/theme';

export const Container = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
`;

export const SubContainer = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin: 1rem 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

export const Description = styled.div`
  margin: 1rem 0;
`;

export const ButtonContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: flex-end;
`;

export const ArchiveContainer = styled.div`
  margin: 24px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

export const Archive = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ArchiveText = styled.span`
  color: ${$blue};
  margin-left: 5px;
`;

export const DescriptionContainer = styled.span`
  margin: 1rem 0;
  height: 8rem;
`;

export const PosIcon = styled.img`
  margin: 10px auto;
`;

export const Spacer = styled.div`
  margin: 20px 0px;
`;

export const SubTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: ${$grey2};
`;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

export const DrawerText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 5px 0 20px 0;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 65px;
  height: 91vh;
  overflow-y: auto;
  box-sizing: border-box;
  width: 482px;
`;

export const DrawerHeader = styled.div`
  height: 9vh;
  padding: 23px;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f2f9;
  position: sticky;
  top: 0;
  background-color: white;
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Warning = styled.div`
  font-size: 10px;
  padding: 2px 0px;
  color: red;
  height: 14px;
`;

interface Props {
  template: DueDiligenceRequirementTemplate;
  open: boolean;
  updateReqStatus: (status: string, requirement_ids: string[]) => void;
  onClose: (drawerType: 'custom' | 'standard') => void;
  bank: Bank;
  updateData: () => void;
  drawerType: 'custom' | 'standard';
  canEditDetails: boolean;
}

export const TemplateDrawer = ({
  updateReqStatus,
  template,
  open,
  onClose,
  bank,
  updateData,
  drawerType,
  canEditDetails,
}: Props) => {
  const apiClient = api();
  const { handleSubmit, ...form } = useForm();
  const [editView, setEditView] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);

  useEffect(() => {
    if (open && !files.length && template && template.attachments) {
      getExistingFiles(template.attachments);
      setFiles(template.attachments.map(d => ({ id: d.id, name: d.file_name })));
    }
  }, [open]);

  const getExistingFiles = async (docs: { id: string; file_name: string }[]) => {
    setLoadingDocuments(true);
    try {
      const files = await Promise.all(
        docs.map(async doc => {
          const document = await getDocumentById(bank.id, doc.id);
          const { s3LinkPath } = await s3Util(apiClient).getPresignedUrl(
            document.s3_key,
            'get',
            'bank',
            `${environment.env}-gcv-shared-documents-file-bucket`
          );

          // get extension from filename
          var re = /(?:\.([^.]+))?$/;
          const ext = re.exec(document.file_name)[1].toLowerCase();
          const type = mime.lookup(ext);

          const result = await fetch(s3LinkPath);
          const base64 = await result.text();
          const base64ArrayBuffer = base64arraybuffer.decode(base64);

          return new File([base64ArrayBuffer], document.file_name, { type });
        })
      );

      setFiles(files);
      setLoadingDocuments(false);
    } catch (e) {
      setLoadingDocuments(false);
    }
  };

  const getDocumentsByOrgIdAndId = async (orgId, documentId) => {
    return await apiClient.documents.getOrgDocumentById(orgId, documentId, () => {});
  };

  const getDocumentById = async (orgId, documentId) => {
    try {
      const result = await getDocumentsByOrgIdAndId(orgId, documentId);
      if (Array.isArray(result)) {
        return result[0];
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const closeDrawer = () => {
    setEditView(false);
    setFiles([]);
    onClose(drawerType);
  };

  const categoryOptions = [
    { value: 'legal', label: 'Legal' },
    { value: 'operational', label: 'Operational' },
    { value: 'financial', label: 'Financial' },
  ];

  const restoreReq = () => {
    updateReqStatus('not archived', [template.requirement_id]);
    closeDrawer();
  };

  const archiveReq = () => {
    updateReqStatus('archived', [template.requirement_id]);
    closeDrawer();
  };

  const editReq = () => {
    setEditView(true);
  };

  const clearEditReq = () => {
    setEditView(false);
  };

  const saveReq = data => {
    upsertData(data);
    closeDrawer();
  };

  const createReq = data => {
    upsertData(data);
    closeDrawer();
  };

  const upsertData = async data => {
    const newRequirement = {
      name: data.name,
      description: data.description,
      id: template ? template.requirement_id : false,
    };

    const documents = await Promise.all(
      files.map(async file => {
        const s3File = await s3Util(apiClient).handleAddDocumentToS3(
          { blob: file, userType: 'bank' },
          bank.id,
          null,
          `${environment.env}-gcv-shared-documents-file-bucket`
        );

        return apiClient.documents.createDocument(
          {
            orgId: bank.id,
            s3Key: s3File.s3_key,
            fileName: s3File.filename,
          },
          () => {}
        );
      })
    );

    const attachments = documents.map(doc => ({ id: doc.id, file_name: doc.file_name }));

    await apiClient.banks
      .upsertTemplateRequirement(
        bank.id,
        { requirement: newRequirement, category: data.category.value, attachments },
        () => {}
      )
      .then(() => {
        updateData();
      })
      .catch(e => {});
  };

  const defaultSelectValue =
    template && template.category ? categoryOptions.find(cat => cat.value === template.category) : categoryOptions[0];

  const defaultCategoryValue =
    template && template.category ? categoryOptions.find(cat => cat.value === template.category) : '';

  return (
    <>
      <GcvDrawer open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <VerticalCenter>
                <BackButtonIcon onClick={() => closeDrawer()}></BackButtonIcon>
              </VerticalCenter>
              <VerticalCenter style={{ marginLeft: '13.5px' }}>
                <Title>
                  {template &&
                  (template.name === 'Business Formation Document(s)' ||
                    template.name === 'Business Licenses' ||
                    template.name === 'Employee Licenses' ||
                    template.name === 'Balance Sheet') ? (
                    <>Standard Requirement</>
                  ) : (
                    <>Custom Requirement</>
                  )}
                </Title>
                <SubTitle>{template ? template.name : null}</SubTitle>
              </VerticalCenter>
            </div>
            <div>
              <VerticalCenter>
                <Title>{template ? (!template.archived ? 'Active' : 'Archived') : null}</Title>
              </VerticalCenter>
            </div>
          </div>
        </DrawerHeader>
        <DrawerContent>
          <div>
            {(!template || editView) && canEditDetails ? (
              <>
                <Spacer style={{ marginBottom: '28px', marginTop: '19px' }}>
                  <GcvInputForm
                    {...form}
                    name={'name'}
                    defaultValue={template ? template.name : ''}
                    rules={{ required: true }}
                    label={'Name'}
                  />
                </Spacer>

                <Spacer style={{ marginTop: '28px', marginBottom: '11px' }}>
                  <GcvInputSelect
                    {...form}
                    options={categoryOptions}
                    label={'Category'}
                    name={'category'}
                    defaultValue={defaultSelectValue}
                    rules={{ required: true }}
                  />
                </Spacer>

                <Spacer style={{ marginTop: '11px' }}>
                  <GcvInputTextArea
                    rules={{ required: true }}
                    defaultValue={template ? template.description : ''}
                    name={'description'}
                    label={'Description'}
                    {...form}
                  ></GcvInputTextArea>
                </Spacer>
              </>
            ) : (
              <>
                <Spacer>
                  <SubTitle>Name</SubTitle>
                  <DrawerText>{template ? template.name : ''}</DrawerText>
                </Spacer>
                <Spacer>
                  <SubTitle>Category</SubTitle>
                  <DrawerText>{defaultCategoryValue ? defaultCategoryValue.label : ''}</DrawerText>
                </Spacer>
                <Spacer>
                  <SubTitle>Description</SubTitle>
                  <DrawerText>
                    <GcvReadMore string={template ? template.description : ''} length={300}></GcvReadMore>
                  </DrawerText>
                </Spacer>
              </>
            )}
            <div>
              {loadingDocuments ? (
                <HorizontalCenter>
                  <GcvLoading></GcvLoading>
                </HorizontalCenter>
              ) : (
                <>
                  <HorizontalCenter style={{ marginBottom: '1rem' }}>
                    <GcvContent
                      type="p1"
                      content={
                        !((!template || editView) && canEditDetails)
                          ? 'Attachments'
                          : 'Attach a form or file (optional)'
                      }
                    />
                  </HorizontalCenter>
                  <GcvInputFile
                    updateFileState={fileState => setFiles(fileState.allFiles)}
                    acceptedTypes={['all']}
                    files={files}
                    multiple={true}
                    maxHeight={'10rem'}
                    large={true}
                    viewFilesOnly={!((!template || editView) && canEditDetails)}
                  ></GcvInputFile>
                </>
              )}
            </div>
          </div>
          <Footer>
            {drawerType === 'standard' || !canEditDetails ? (
              <></>
            ) : template ? (
              template.archived ? (
                <GcvButton onClick={restoreReq} secondary={true}>
                  Restore
                </GcvButton>
              ) : (
                <>
                  {editView ? (
                    <>
                      <GcvButton onClick={clearEditReq} secondary={true}>
                        Cancel
                      </GcvButton>
                      <GcvButton onClick={handleSubmit(saveReq)} primary={true}>
                        Save
                      </GcvButton>
                    </>
                  ) : (
                    <>
                      <GcvButton onClick={archiveReq} secondary={true}>
                        Archive
                      </GcvButton>
                      <GcvButton onClick={editReq} primary={true}>
                        Edit
                      </GcvButton>
                    </>
                  )}
                </>
              )
            ) : (
              <>
                <GcvButton onClick={closeDrawer} secondary={true}>
                  Cancel
                </GcvButton>
                <GcvButton onClick={handleSubmit(createReq)} primary={true}>
                  Create
                </GcvButton>
              </>
            )}
          </Footer>
        </DrawerContent>
      </GcvDrawer>
    </>
  );
};
