import React, { useEffect } from 'react';

import Select, { ValueType } from 'react-select';
import { Controller } from 'react-hook-form';

import { CustomValueContainer } from './CustomValueContainer';
import { errorMessages } from '../../util/validationHelpers';

import { customSelectStyles, Warning } from './styles';

interface Props {
  noWarning?: boolean;
  options: any[];
  label: string;
  name: string;
  control: any;
  errors: any;
  rules?: any;
  defaultValue?: any;
  onChangeCallback?: (selected) => void;
  testDataTag?: string;
  readonly?: boolean;
}

export const GcvInputSelect = ({
  errors,
  control,
  defaultValue,
  options,
  label,
  name,
  rules = {},
  onChangeCallback,
  testDataTag,
  noWarning,
  readonly = false,
}: Props) => {
  return (
    <div data-cy={testDataTag}>
      <Controller
        as={
          <Select
            options={options}
            placeholder={label}
            styles={customSelectStyles}
            isSearchable={false}
            isDisabled={readonly}
            components={{
              ValueContainer: CustomValueContainer,
            }}
          />
        }
        control={control}
        rules={rules}
        onChange={([selected]) => {
          if (onChangeCallback) {
            onChangeCallback(selected);
          }
          return { value: selected };
        }}
        defaultValue={defaultValue}
        name={name}
      />
      {!noWarning ? (
        <Warning data-testid={`warning`}>
          {errors[name] ? `* ${label} ${errors[name].message || errorMessages[errors[name].type]}` : ''}
        </Warning>
      ) : null}
    </div>
  );
};
