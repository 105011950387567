import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { GcvModal, ModalProps } from '../GcvModal/GcvModal';
import { GcvDrawer, DrawerProps } from '../GcvDrawer/GcvDrawer';

interface Props {
  header: ReactNode;
  body: ReactNode;

  loader?: ReactNode;
  classes?: string;
  drawer?: {
    content: JSX.Element;
    props: DrawerProps;
  };
  modal?: {
    content: JSX.Element;
    props: ModalProps;
  };
}

export const GcvPage: React.FC<Props> = props => {
  return (
    <>
      {props.loader && <>{props.loader}</>}
      {!props.loader && (
        <>
          <Grid container spacing={2} className={props.classes}>
            <Grid item xs={12}>
              {props.header}
            </Grid>
            <Grid item xs={12}>
              {props.body}
            </Grid>
          </Grid>

          {props.drawer && <GcvDrawer {...props.drawer.props}>{props.drawer.content}</GcvDrawer>}
          {props.modal && <GcvModal {...props.modal.props}>{props.modal.content}</GcvModal>}
        </>
      )}
    </>
  );
};
