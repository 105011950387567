import * as _ from 'underscore';
import { User } from '@gcv/shared';
import { DateTime } from 'luxon';

export function filterOutEmptyProperties(obj: object): object {
  const filteredObj = _.pick(obj, (value: any) => {
    const keep = value !== null && value !== undefined && value !== '';
    return keep;
  });
  return filteredObj;
}

export const getDefaultCompany = (companies: User['companies']) => {
  return companies[0];
};

export const formatReportDate = (dueDate: any, totalTime?: number) => {
  if (dueDate) {
    const dueDateUTC = DateTime.fromISO(dueDate, { zone: 'utc' });
    const todaysDateUTC = DateTime.utc();
    const diff = todaysDateUTC.diff(dueDateUTC, 'days').toObject();
    const isPast = dueDateUTC.toSeconds() - todaysDateUTC.toSeconds() < 0;
    const value = isPast ? totalTime + Math.abs(Math.floor(diff.days)) : totalTime - Math.abs(Math.floor(diff.days));
    return value < 0 ? 0 : value;
  } else {
    return 0;
  }
};

/**
 *
 * @param {string} stringToStrip
 * @return
 */
export const stripAllNonWordCharacters = (stringToStrip: string) => {
  return stringToStrip.replace(/[^\w]/g, '');
};
