import React from 'react';

export const YellowWarningIcon = ({ height = 26, width = 26, style = {} }) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="yellow-warning-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
      fill="#F49B20"
    />
    <line
      x1="13.05"
      y1="19.5083"
      x2="13.05"
      y2="19.5001"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="13.0499"
      y1="14.3219"
      x2="13.0499"
      y2="6.49999"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
