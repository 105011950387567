<div class="card">
  <div class="header">
    <div class="headerContent">
      <div>
        <span class="nameHeader" data-cy="account-owner-name">{{ user.firstName }} {{ user.lastName }}</span>
      </div>
      <div>
        <span data-cy="account-owner-email">{{ user.email || '(No Email Provided)' }}</span>
      </div>
      <div>
        <span data-cy="account-owner-phone">{{ (user.phone | formatPhonePipe) || '(No Phone Provided)' }}</span>
      </div>
    </div>
    <div class="border"></div>
  </div>
  <div class="body">
    <div class="wrapper">
      <div class="labelContainer">
        <span class="label">Address</span>
        <span class="content" data-cy="account-owner-address">{{ user.address || 'Not Provided' }}</span>
      </div>
      <div class="labelContainer">
        <span class="label">Date of Birth</span>
        <span class="content" data-cy="account-owner-dob">{{ user.dateOfBirth | date: 'shortDate' }}</span>
      </div>
    </div>
    <div class="wrapper">
      <div class="labelContainer">
        <span class="label">City</span>
        <span class="content" data-cy="account-owner-city">{{ user.city || 'Not Provided' }}</span>
      </div>
      <div class="labelContainer">
        <span class="label">ID Type</span>
        <span class="content" data-cy="account-owner-id-type">{{
          user.identification ? getIdType(user.identification.idType) : 'Not Provided'
        }}</span>
      </div>
    </div>
    <div class="wrapper">
      <div class="labelContainer">
        <span class="label">State</span>
        <span class="content" data-cy="account-owner-state">{{ user.state || 'Not Provided' }}</span>
      </div>
      <div class="labelContainer">
        <span class="label">ID Number</span>
        <span class="content" data-cy="account-owner-id-number">{{
          user.identification ? user.identification.idNumber : 'Not Provided'
        }}</span>
      </div>
    </div>
    <div class="wrapper">
      <div class="labelContainer">
        <span class="label">Zip Code</span>
        <span class="content" data-cy="account-owner-zip">{{ user.zipcode || 'Not Provided' }}</span>
      </div>
      <div class="labelContainer">
        <gcv-button
          (clickEvent)="handleReviewButtonClicked()"
          [buttonText]="
            loading
              ? 'Loading Document...'
              : user.identification && user.identification.idFront.status === 'review'
              ? 'Review ID Document'
              : 'View ID Document'
          "
          [type]="user.identification && user.identification.idFront.status === 'review' ? 'primary' : 'secondary'"
          data-cy="account-owner-view-document-button"
        ></gcv-button>
      </div>
    </div>
  </div>
</div>
