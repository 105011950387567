import { ReactWrapperComponent, WrapperProps } from '../../util';
import { NavigationExtras, Router } from '@angular/router';
import { SalesAndDepositsRouter } from './SalesAndDepositsRouter';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { acceptOrApproveDeposit } from '../../util/angularHelpers';
import {
  BankFacade,
  DepositsFacade,
  DispensaryFacade,
  NotificationsFacade,
  StaffFacade,
} from '@user-interface/gcv-state';
import { LaunchDarklyService } from 'apps/user-interface/src/services/launch-darkley/launchdarkley.service';

import { UtilsService } from '@user-interface/gcv-ui';
import { DispensaryAppFamilyService } from '../../../../../apps/user-interface/src/services/dispensary.service';
import { DateTime } from 'luxon';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sales-deposits-router',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="salesAndDepositsProps" [emitData]="handleClick"></app-react-wrapper>',
})
export class SalesAndDepositsRouterContainerComponent extends ReactWrapperComponent implements OnChanges, OnInit {
  @Input() props: WrapperProps;
  bank = this.router.url.includes('bank');
  dispensary = this.router.url.includes('dispensary');
  component = SalesAndDepositsRouter;
  defaultVendorValue;
  summaryData: any;
  staff: any;
  salesAndDepositsProps = {
    ...this.props,
    loading: true,
    crbId: '',
    startDate: null,
    endDate: null,
  } as any;

  constructor(
    private bankFacade: BankFacade,
    private launchDarklyService: LaunchDarklyService,
    private dispensaryFacade: DispensaryFacade,
    private staffFacade: StaffFacade,
    private router: Router,
    private depositsFacade: DepositsFacade,
    private notificationFacade: NotificationsFacade,
    private utils: UtilsService,
    private dispAppFamilyService: DispensaryAppFamilyService
  ) {
    super();
    this.handleRowClicked = this.handleRowClicked.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(type, deposit, bankId) {
    // feature flag deposit-detail-drawer
    if (type === 'acceptDeposit') {
      acceptOrApproveDeposit(deposit, bankId, {
        utils: this.utils,
        notificationFacade: this.notificationFacade,
        depositsFacade: this.depositsFacade,
      });
    } else if (type === 'viewDeposit') {
      this.handleRowClicked(deposit);
    }
  }

  handleRowClicked(deposit) {
    const data: NavigationExtras = {
      queryParams: {
        id: deposit.deposit_id,
        dispensaryId: deposit.dispensary_id,
      },
    };
    if (this.bank) {
      this.router.navigate(['/secure/bank/deposit/details'], data);
    } else if (this.dispensary) {
      this.router.navigate(['/secure/dispensary/viewDeposit'], data);
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.dispAppFamilyService.timeFilter.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      const { start, end } = this.utils.getLocalDateTimeRange(value);
      this.salesAndDepositsProps.startDate = DateTime.fromISO(start).toString();
      this.salesAndDepositsProps.endDate = DateTime.fromISO(end).toString();
      this.salesAndDepositsProps = { ...this.salesAndDepositsProps };
    });

    this.dispensaryFacade
      .selectCurrentDispensaryId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        this.salesAndDepositsProps = { ...this.salesAndDepositsProps, crbId: r };
      });

    if (this.launchDarklyService.flags['deposit-detail-drawer']) {
      this.salesAndDepositsProps = { ...this.salesAndDepositsProps, depositDetailFlag: true };
    }

    if (this.bank) {
      const staff$ = this.staffFacade.selectStaffDictionary();
      staff$.pipe(takeUntil(this.unsubscribe$)).subscribe(staffMap => {
        this.salesAndDepositsProps = { ...this.salesAndDepositsProps, staff: staffMap };
        this.checkIfLoaded();
      });

      const bank$ = this.bankFacade.selectCurrentBank();
      bank$.pipe(takeUntil(this.unsubscribe$)).subscribe(bank => {
        this.staffFacade.getOrganizationStaffByOrgId(bank.id);

        this.salesAndDepositsProps = {
          ...this.salesAndDepositsProps,
          bankId: bank.id,
        };
        this.checkIfLoaded();
      });
    } else {
      const dispensary$ = this.dispensaryFacade.selectDispensaryDictionary();
      dispensary$.pipe(takeUntil(this.unsubscribe$)).subscribe(dispensaryMap => {
        this.salesAndDepositsProps = {
          ...this.salesAndDepositsProps,
          dispensaries: dispensaryMap,
        };
      });
      this.checkIfLoaded();
    }
    this.checkIfLoaded();
  }

  checkIfLoaded() {
    if (this.bank) {
      if (
        this.salesAndDepositsProps['staff'] &&
        this.salesAndDepositsProps['dispensaries'] &&
        this.salesAndDepositsProps['deposits'] &&
        this.salesAndDepositsProps['bankId']
      ) {
        this.salesAndDepositsProps.loading = false;
      }
    } else {
      if (
        this.salesAndDepositsProps['staff'] &&
        this.salesAndDepositsProps['dispensaries'] &&
        this.salesAndDepositsProps['deposits']
      ) {
        this.salesAndDepositsProps.loading = false;
      }
    }
  }

  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    this.salesAndDepositsProps = {
      ...this.salesAndDepositsProps,
      ...changes.props.currentValue,
    };
    this.checkIfLoaded();
  }
}
