import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const documentManagementClient = (API: APIClass) => {
  return {
    getDispensaryRequirementActivities: async (
      orgId: string,
      reqId: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).get(
        `/document-management/dispensaries/${orgId}/requirements/${reqId}/activities`,
        setIsLoading
      );
    },

    createDocAndUpdateStatus: async (
      orgId: string,
      reqId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/document-management/dispensaries/${orgId}/requirements/${reqId}`,
        body,
        setIsLoading
      );
    },

    trackSalesUpload: async (orgId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(
        `/document-management/dispensaries/${orgId}/track/sales/upload`,
        body,
        setIsLoading
      );
    },

    updateRequirementStatus: async (
      orgId: string,
      reqId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).patch(
        `/document-management/dispensaries/${orgId}/requirements/${reqId}/status`,
        body,
        setIsLoading
      );
    },

    updateDocumentStatus: async (
      orgId: string,
      reqId: string,
      docId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).patch(
        `/document-management/dispensaries/${orgId}/requirements/${reqId}/documents/${docId}/status`,
        body,
        setIsLoading
      );
    },

    addCommentToRequirement: async (
      orgId: string,
      reqId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).patch(
        `/document-management/dispensaries/${orgId}/requirements/${reqId}/add-comment`,
        body,
        setIsLoading
      );
    },

    requestFileUpload: async (
      orgId: string,
      reqId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).patch(
        `/document-management/dispensaries/${orgId}/requirements/${reqId}/request-upload`,
        body,
        setIsLoading
      );
    },
  };
};
