import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    leftAlign: {
      textAlign: 'left',
    },
    rightAlign: {
      textAlign: 'right',
    },
    bold: {
      fontWeight: 900,
    },
    borderBottom: {
      borderBottom: '1px solid #DBE0EB',
    },
    boldBorderBottom: {
      borderBottom: '3px solid #DBE0EB',
    },
    horizontalCenter: {
      textAlign: 'center',
    },
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    dense: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
    flex: {
      display: 'flex',
    },
    flexGrow: {
      flex: 1,
    },
    gutterBottom: {
      paddingBottom: '1rem',
    },
    gutterTop: {
      paddingTop: '1rem',
    },
    marginAuto: {
      margin: 'auto',
    },
    sparse: {
      paddingTop: '1rem !important',
      paddingBottom: '1rem !important',
    },
  };
});

type CardSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface ItemProps {
  size?: [CardSize, CardSize, CardSize, CardSize, CardSize] | [CardSize];
  xs?: CardSize;
  sm?: CardSize;
  md?: CardSize;
  lg?: CardSize;
  xl?: CardSize;

  lAlign?: boolean;
  rAlign?: boolean;
  bold?: boolean;
  borderBottom?: boolean;
  hCenter?: boolean;
  vCenter?: boolean;
  dense?: boolean;
  flex?: boolean;
  sparse?: boolean;
}

const Item: React.FC<ItemProps> = props => {
  const classes = useStyles();

  let xs = props.xs ? props.xs : 12,
    sm = props.sm ? props.sm : 12,
    md = props.md ? props.md : 12,
    lg = props.lg ? props.lg : 12,
    xl = props.xl ? props.xl : 12;

  if (props.size && props.size.length === 5) {
    xs = props.size[0];
    sm = props.size[1];
    md = props.size[2];
    lg = props.size[3];
    xl = props.size[4];
  } else if (props.size && props.size.length === 1) {
    xs = props.size[0];
    sm = props.size[0];
    md = props.size[0];
    lg = props.size[0];
    xl = props.size[0];
  }

  return props.flex ? (
    <div
      className={clsx(
        classes.flexGrow,
        props.lAlign ? classes.leftAlign : '',
        props.rAlign ? classes.rightAlign : '',
        props.bold ? classes.bold : '',
        props.borderBottom ? (props.bold ? classes.boldBorderBottom : classes.borderBottom) : '',
        props.hCenter ? classes.horizontalCenter : '',
        props.vCenter ? classes.verticalCenter : '',
        props.dense ? classes.dense : '',
        props.sparse ? classes.sparse : ''
      )}
    >
      {props.children}
    </div>
  ) : (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      className={clsx(
        props.lAlign ? classes.leftAlign : '',
        props.rAlign ? classes.rightAlign : '',
        props.bold ? classes.bold : '',
        props.borderBottom ? (props.bold ? classes.boldBorderBottom : classes.borderBottom) : '',
        props.hCenter ? classes.horizontalCenter : '',
        props.vCenter ? classes.verticalCenter : '',
        props.dense ? classes.dense : '',
        props.sparse ? classes.sparse : ''
      )}
    >
      {props.children}
    </Grid>
  );
};

interface ContainerProps {
  flex?: boolean;
  gutterBottom?: boolean;
  gutterTop?: boolean;
  hCenter?: boolean;
  width?: number;
}

export const Container: React.FC<ContainerProps> & {
  Item: React.FC<ItemProps>;
} = props => {
  const classes = useStyles();

  return props.flex ? (
    <div
      className={clsx(
        classes.flex,
        props.gutterBottom ? classes.gutterBottom : '',
        props.gutterTop ? classes.gutterTop : '',
        props.hCenter ? classes.marginAuto : ''
      )}
      style={{ width: props.width ? props.width + 'px' : '' }}
    >
      {props.children}
    </div>
  ) : (
    <Grid
      container
      spacing={2}
      className={clsx(
        props.gutterBottom ? classes.gutterBottom : '',
        props.gutterTop ? classes.gutterTop : '',
        props.hCenter ? classes.marginAuto : ''
      )}
      style={{ width: props.width ? props.width + 'px' : '' }}
    >
      {props.children}
    </Grid>
  );
};

Container.Item = Item;
