import { DataPersistence } from '../../utils/data.persistance';
import { BankState } from './bank.reducer';
import { Effect } from '@ngrx/effects';
import {
  AddDispensaryToBank,
  AllBanksLoaded,
  BankActionTypes,
  BankCreated,
  BankGCVDueDiligenceStatusUpdated,
  BankLoaded,
  BankPartialLoaded,
  BankUpdated,
  CreateBank,
  DispensaryAddedToBank,
  GroupsLoaded,
  GroupsUpdated,
  LoadAllBanks,
  LoadBank,
  LoadBankPartial,
  LoadGroups,
  UpdateBank,
  UpdateBankGCVDueDiligenceStatus,
  UpdateGroups,
  RemoveDispensaryFromBank,
  DispensaryRemovedFromBank,
  UpdateBankOrganizationalDetails,
  UpdateBankCannabisProgram,
  UpdateBankRegulatoryReporting,
  UpdateBankTransportationOptOut,
  UpdateBankDueDiligenceUsersSection,
  InviteBankUser,
  UpsertBankDueDiligenceTemplateRequirement,
  UpdateBankDueDiligenceSectionComplete,
  DeleteBankDueDiligenceTemplateRequirement,
} from './bank.actions';
import { map, switchMap } from 'rxjs/operators';
import { Bank, Group } from '@gcv/shared';
import { Injectable } from '@angular/core';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';
import { API } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { from } from 'rxjs';
import { OrganizationStaffCreated } from '../staff/staff.actions';

@Injectable({
  providedIn: 'root',
})
export class BankEffect {
  @Effect() loadBank$ = this.dataPersistance.fetch(BankActionTypes.LoadBank, {
    run: (action: LoadBank, state: BankState) => {
      return this.sdk.banksIdGet(action.payload.bankId).pipe(map((res: Bank) => new BankLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.LoadBank, message: err });
    },
  });

  @Effect() updateBank$ = this.dataPersistance.fetch(BankActionTypes.UpdateBank, {
    run: (action: UpdateBank, state: BankState) => {
      return this.sdk.banksIdPut(action.payload.id, action.payload).pipe(map((res: Bank) => new BankUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.UpdateBank, message: err });
    },
  });

  @Effect() loadBankPartial$ = this.dataPersistance.fetch(BankActionTypes.LoadBankPartial, {
    run: (action: LoadBankPartial, state: BankState) => {
      return this.sdk
        .dispensariesIdBankGet(action.payload.dispensaryId)
        .pipe(map((res: Partial<Bank>) => new BankPartialLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.LoadBankPartial, message: err });
    },
  });

  @Effect() createBank$ = this.dataPersistance.fetch(BankActionTypes.CreateBank, {
    run: (action: CreateBank, state: BankState) => {
      return this.sdk.banksPost(action.payload).pipe(map((res: Bank) => new BankCreated(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.CreateBank, message: err });
    },
  });

  @Effect() updateBankGCVDueDiligenceStatus$ = this.dataPersistance.fetch(
    BankActionTypes.UpdateBankGCVDueDiligenceStatus,
    {
      run: (action: UpdateBankGCVDueDiligenceStatus, state: BankState) => {
        return this.sdk
          .banksIdGcvDueDiligenceStatusPut(action.payload.bankId)
          .pipe(map((res: Bank) => new BankGCVDueDiligenceStatusUpdated(res)));
      },
      onError: (action, sdkError) => {
        return new AddError({ action: BankActionTypes.UpdateBankGCVDueDiligenceStatus, message: sdkError });
      },
    }
  );

  @Effect() loadAllBanks$ = this.dataPersistance.fetch(BankActionTypes.LoadAllBanks, {
    run: (action: LoadAllBanks, state: BankState) => {
      return this.sdk.banksGet().pipe(map((res: Bank[]) => new AllBanksLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.LoadAllBanks, message: err });
    },
  });

  @Effect() loadGroups$ = this.dataPersistance.fetch(BankActionTypes.LoadGroups, {
    run: (action: LoadGroups, state: BankState) => {
      return this.sdk.groupsOrgIdGet(action.payload.orgId).pipe(map((res: Group) => new GroupsLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.LoadGroups, message: err });
    },
  });

  @Effect() updateGroups$ = this.dataPersistance.fetch(BankActionTypes.UpdateGroups, {
    run: (action: UpdateGroups, state: BankState) => {
      return this.sdk
        .groupsOrgIdPut(action.payload.bankId, action.payload.groups)
        .pipe(map((res: Bank) => new GroupsUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.UpdateGroups, message: err });
    },
  });

  @Effect() addDispensaryToBank$ = this.dataPersistance.fetch(BankActionTypes.AddDispensaryToBank, {
    run: (action: AddDispensaryToBank, state: BankState) => {
      return this.sdk
        .banksIdDispensariesDispensaryIdPut(action.payload.dispensaryId, action.payload.bankId)
        .pipe(map((res: Bank) => new DispensaryAddedToBank(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.AddDispensaryToBank, message: err });
    },
  });

  @Effect() removeDispensartyFromBank$ = this.dataPersistance.fetch(BankActionTypes.RemoveDispensaryFromBank, {
    run: (action: RemoveDispensaryFromBank, state: BankState) => {
      return this.sdk
        .banksIdDispensariesDispensaryIdDelete(action.payload.dispensaryId, action.payload.bankId)
        .pipe(map((res: Bank) => new DispensaryRemovedFromBank(res)));
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.RemoveDispensaryFromBank, message: err });
    },
  });

  @Effect() updateBankOrganizationalDetails$ = this.dataPersistance.fetch(
    BankActionTypes.UpdateBankOrganizationalDetails,
    {
      run: (action: UpdateBankOrganizationalDetails, state: BankState) => {
        return from(
          API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${action.payload.bankId}/organizational-details`, {
            body: action.payload.organizationalDetails,
          })
        ).pipe(map((res: Bank) => new BankUpdated(res)));
      },
      onError: (action, sdkError) => {
        return new AddError({ action: BankActionTypes.UpdateBankOrganizationalDetails, message: sdkError });
      },
    }
  );

  @Effect() updateBankCannabisProgram$ = this.dataPersistance.fetch(BankActionTypes.UpdateBankCannabisProgram, {
    run: (action: UpdateBankCannabisProgram, state: BankState) => {
      return from(
        API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${action.payload.bankId}/cannabis-program`, {
          body: action.payload.cannabisProgram,
        })
      ).pipe(map((res: Bank) => new BankUpdated(res)));
    },
    onError: (action, sdkError) => {
      return new AddError({ action: BankActionTypes.UpdateBankCannabisProgram, message: sdkError });
    },
  });

  @Effect() updateBankDueDiligenceStatusComplete$ = this.dataPersistance.fetch(
    BankActionTypes.UpdateBankDueDiligenceSectionComplete,
    {
      run: (action: UpdateBankDueDiligenceSectionComplete, state: BankState) => {
        return from(
          API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${action.payload.bankId}/due-diligence-section-complete`, {
            body: { due_diligence_section_complete: action.payload.due_diligence_section_complete },
          })
        ).pipe(map((res: Bank) => new BankUpdated(res)));
      },
      onError: (action, sdkError) => {
        return new AddError({ action: BankActionTypes.UpdateBankDueDiligenceSectionComplete, message: sdkError });
      },
    }
  );

  @Effect() updateBankRegulatoryReporting$ = this.dataPersistance.fetch(BankActionTypes.UpdateBankRegulatoryReporting, {
    run: (action: UpdateBankRegulatoryReporting, state: BankState) => {
      return from(
        API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${action.payload.bankId}/regulatory-reporting`, {
          body: action.payload.regulatoryReporting,
        })
      ).pipe(map((res: Bank) => new BankUpdated(res)));
    },
    onError: (action, sdkError) => {
      return new AddError({ action: BankActionTypes.UpdateBankRegulatoryReporting, message: sdkError });
    },
  });

  @Effect() updateBankTransportationOptOut$ = this.dataPersistance.fetch(
    BankActionTypes.UpdateBankTransportationOptOut,
    {
      run: (action: UpdateBankTransportationOptOut, state: BankState) => {
        return from(
          API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${action.payload.bankId}/transport-opt-out`, {
            body: { transport_opt_out: action.payload.transport_opt_out },
          })
        ).pipe(map((res: Bank) => new BankUpdated(res)));
      },
      onError: (action, sdkError) => {
        return new AddError({ action: BankActionTypes.UpdateBankTransportationOptOut, message: sdkError });
      },
    }
  );

  @Effect() updateBankDueDiligenceUsersSection$ = this.dataPersistance.fetch(
    BankActionTypes.UpdateBankDueDiligenceUsersSection,
    {
      run: (action: UpdateBankDueDiligenceUsersSection, state: BankState) => {
        return from(
          API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${action.payload.bankId}/users-opt-out`, {
            body: {
              users_opt_out: action.payload.users_opt_out,
              users_section_complete: action.payload.users_section_complete,
            },
          })
        ).pipe(map((res: Bank) => new BankUpdated(res)));
      },
      onError: (action, sdkError) => {
        return new AddError({ action: BankActionTypes.UpdateBankDueDiligenceUsersSection, message: sdkError });
      },
    }
  );

  @Effect() inviteBankUser$ = this.dataPersistance.fetch(BankActionTypes.InviteBankUser, {
    run: (action: InviteBankUser) => {
      return from(
        API.post(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${action.payload.bankId}/users`, {
          body: action.payload.user,
        })
      ).pipe(
        switchMap((res: { user: any; org: any }) => {
          return [new BankUpdated(res.org), new OrganizationStaffCreated(res.user)];
        })
      );
    },
    onError: err => {
      return new AddError({ action: BankActionTypes.UpdateBank, message: err });
    },
  });

  @Effect() UpsertBankDueDiligenceTemplateRequirement$ = this.dataPersistance.fetch(
    BankActionTypes.UpsertBankDueDiligenceTemplateRequirement,
    {
      run: (action: UpsertBankDueDiligenceTemplateRequirement, state: BankState) => {
        return from(
          API.post(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/banks/${action.payload.bankId}/diligence/requirement`, {
            body: {
              requirement: action.payload.requirement,
              category: action.payload.category,
              attachments: action.payload.attachments,
            },
          })
        ).pipe(map((res: Bank) => new BankUpdated(res)));
      },
      onError: (action, sdkError) => {
        return new AddError({ action: BankActionTypes.UpsertBankDueDiligenceTemplateRequirement, message: sdkError });
      },
    }
  );

  @Effect() DeleteBankDueDiligenceTemplateRequirement$ = this.dataPersistance.fetch(
    BankActionTypes.DeleteBankDueDiligenceTemplateRequirement,
    {
      run: (action: DeleteBankDueDiligenceTemplateRequirement, state: BankState) => {
        return from(
          API.del(
            GREEN_CHECK_SERVICES_AMPLIFY_NAME,
            `/banks/${action.payload.bankId}/diligence/requirement/${action.payload.requirementId}`,
            {}
          )
        ).pipe(map((res: Bank) => new BankUpdated(res)));
      },
      onError: (action, sdkError) => {
        return new AddError({ action: BankActionTypes.DeleteBankDueDiligenceTemplateRequirement, message: sdkError });
      },
    }
  );

  constructor(private dataPersistance: DataPersistence<BankState>, private sdk: DefaultService) {}
}
