<div [attr.data-cy]="'navigation-shell.component'" class="shellWrapper">
  <div (click)="goToDashboard()" [attr.data-cy]="'navigation-shell.component.logo'" class="logoContainer">
    <svg height="24.012" viewBox="0 0 191.901 24.012" width="191.901" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.887,0l1.786,1.786L9.526,23.973,0,14.606,1.786,12.78l7.74,7.66Z" fill="#267832" />
      <path d="M41.272,30.586H37.7L57.386,10.9l1.786,1.746Z" fill="#88c933" transform="translate(-22.737 -6.574)" />
      <g transform="translate(23.973 8.454)">
        <path d="M63.972,36.739H60.4L75.8,21.3l1.786,1.786Z" fill="#88c933" transform="translate(-60.4 -21.3)" />
      </g>
      <g transform="translate(32.982 12.74)">
        <path d="M86.672,43.293H83.1L94.253,32.1l1.786,1.786Z" fill="#88c933" transform="translate(-83.1 -32.1)" />
      </g>
      <g transform="translate(55.923 5.318)">
        <path
          d="M275.29,26.418l-5.279-6.073-2.62,2.7v3.413h-1.349l-.079-12.979h1.349V21.3l7.581-7.819h1.588l-5.6,5.874,5.993,7.065Zm-16.749.119A6.572,6.572,0,1,1,258.5,13.4a6.368,6.368,0,0,1,4.723,1.865l-.873.873a5.114,5.114,0,0,0-3.81-1.508,5.235,5.235,0,0,0-5.438,5.358,5.315,5.315,0,0,0,5.477,5.358,5.089,5.089,0,0,0,3.81-1.548l.873.873a6.246,6.246,0,0,1-4.723,1.865Zm-16.313-1.23h7.779V26.5h-9.129l-.04-12.939h8.851v1.191h-7.5v4.6h6.668V20.5h-6.668l.04,4.8Zm-6.747-11.748h1.349l.04,12.939h-1.349V20.544h-8.1V26.5h-1.349l-.04-12.939h1.349v5.755h8.1ZM218.573,26.617a6.572,6.572,0,1,1-.04-13.137,6.368,6.368,0,0,1,4.723,1.865l-.873.873a5.114,5.114,0,0,0-3.81-1.508,5.235,5.235,0,0,0-5.438,5.358,5.315,5.315,0,0,0,5.477,5.358,5.089,5.089,0,0,0,3.81-1.548l.873.873A6.246,6.246,0,0,1,218.573,26.617Zm-15.6-12.979h1.349l.04,12.939h-1.111L194.918,16.1l.04,10.478h-1.349l-.079-12.939h1.111l8.335,10.478Zm-20.2,11.788h7.779v1.191h-9.129l-.04-12.939h8.851v1.191h-7.5v4.6H189.4v1.151h-6.668Zm-12.145.04h7.779v1.191h-9.129l-.04-12.939h8.851v1.191h-7.5v4.6h6.668v1.151h-6.668l.04,4.8ZM160.9,21.378c2.58,0,3.929-1.191,3.929-3.255s-1.389-3.255-3.969-3.255h-3.453v6.469l3.493.04Zm5.517,5.279h-1.508l-2.937-4.167a8.633,8.633,0,0,1-1.032.04H157.49v4.088h-1.349l-.04-12.939h4.842c3.294,0,5.279,1.667,5.279,4.406a4.068,4.068,0,0,1-2.937,4.088l3.136,4.485Zm-15.281-2.1V20.187h1.31v4.922a7.01,7.01,0,0,1-4.723,1.667,6.574,6.574,0,1,1,0-13.137,6.427,6.427,0,0,1,4.8,1.865l-.833.873a5.453,5.453,0,1,0-3.89,9.208,5.357,5.357,0,0,0,3.334-1.032Z"
          fill="#267832"
          transform="translate(-140.9 -13.4)"
        />
      </g>
    </svg>
  </div>
  <div class="spacer"></div>
  <div class="userContainer">
    <div
      (click)="handleNotificationIconClick()"
      class="notificationWrapper"
      data-cy="navigation-shell.component.notificationWrapper"
    >
      <svg height="20" version="1" width="18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 8c-2-5-4-7-8-7L4 0 3 2c-4 3-3 5-2 11 1 3-1 3-1 5 1 2 6 3 11 1 4-2 8-6 7-7-1-3-3-1-4-4zm-4 9c-4 2-8 1-8 1 0-1 1-4 6-5 5-2 8-2 8-1 1 1-2 4-6 5zm-1-4l-5 3h3c2 0 3-2 3-3H9z"
          fill="#A5A8BA"
          fill-rule="evenodd"
        />
      </svg>
      <div
        *ngIf="notificationsIncomplete.length > 0"
        class="notificationCount"
        data-cy="navigation-shell.component.notificationsIncomplete"
      >
        <span>{{ notificationsIncomplete.length }}</span>
      </div>
    </div>
    <div
      #profileContent
      (mouseout)="toggleProfileDrawer(false)"
      (mouseover)="toggleProfileDrawer(true)"
      class="profileContent"
      data-cy="navigation-shell.component.toggleProfileDrawer"
      *ngIf="companies && companies[0].companyType === 'bank'"
    >
      <span class="username" data-cy="navigation-shell.component.username">{{ firstName }} {{ lastName }}</span>
      <div class="imgAndBtnContainer">
        <gcv-initial-logo
          [firstName]="firstName"
          [lastName]="lastName"
          data-cy="navigation-shell.component.profileHeader"
          type="profileHeader"
        ></gcv-initial-logo>
        <svg class="profileBtn" height="8" version="1" width="15" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 0h2v2L8 8 0 2V0h2l6 5 5-5z" fill="#A5A8BA" fill-rule="nonzero" />
        </svg>
        <div [ngClass]="accountOpen ? 'accountDropDown open' : 'accountDropDown '">
          <div [ngClass]="accountOpen ? 'accountDropDownDetailsOpen' : 'accountDropDownDetailsClose'">
            <ul class="accountOptionsList">
              <li (click)="handleClick({ settings: true })">
                <svg height="20.1" width="20.1" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.6 7.7l1.4.2a1.2 1.2 0 0 1 1 1.2V11a1.2 1.2 0 0 1-1 1.2l-1.4.3a7.9 7.9 0 0 1-.5 1.3l.8 1.2a1.2 1.2 0 0 1-.1 1.6l-1.3 1.2a1.2 1.2 0 0 1-1.6.2l-1.2-.8a8 8 0 0 1-1.3.5L12 19a1.2 1.2 0 0 1-1.2 1H9.1A1.2 1.2 0 0 1 8 19l-.2-1.4a8 8 0 0 1-1.3-.5l-1.2.8a1.2 1.2 0 0 1-.7.2 1.2 1.2 0 0 1-1-.3l-1.2-1.3a1.2 1.2 0 0 1 0-1.6l.7-1.1a7.8 7.8 0 0 1-.5-1.4L1 12.2A1.2 1.2 0 0 1 0 11V9.2A1.2 1.2 0 0 1 1 8l1.4-.3A7.8 7.8 0 0 1 3 6.4L2 5.2a1.2 1.2 0 0 1 .2-1.6l1.2-1.3a1.2 1.2 0 0 1 1.6-.1l1.2.8a7.8 7.8 0 0 1 1.3-.5L8 1A1.2 1.2 0 0 1 9 0H11A1.2 1.2 0 0 1 12 1l.2 1.4a7.9 7.9 0 0 1 1.5.6l1.1-.8a1.2 1.2 0 0 1 .7-.3 1.2 1.2 0 0 1 1 .4l1.2 1.3a1.2 1.2 0 0 1 0 1.6l-.7 1.1a8 8 0 0 1 .5 1.4zm1.2 3.3a.1.1 0 0 0 .1 0V9L17 8.6a.6.6 0 0 1-.5-.4 6.7 6.7 0 0 0-.7-1.7.6.6 0 0 1 0-.6l1-1.5a.1.1 0 0 0 0-.1L15.8 3a.1.1 0 0 0-.1 0l-1.5 1a.6.6 0 0 1-.6 0 6.8 6.8 0 0 0-1.8-.7.6.6 0 0 1-.4-.4L11 1.2a.1.1 0 0 0-.1 0H9L8.6 3a.6.6 0 0 1-.4.4 6.6 6.6 0 0 0-1.8.8.6.6 0 0 1-.6 0l-1.4-1a.1.1 0 0 0-.2 0L3.1 4.3a.1.1 0 0 0 0 .1L4 6a.6.6 0 0 1 0 .6 6.7 6.7 0 0 0-.7 1.8.6.6 0 0 1-.4.4L1.2 9V11l1.8.4a.6.6 0 0 1 .4.4 6.6 6.6 0 0 0 .8 1.7.6.6 0 0 1 0 .7l-1 1.4a.1.1 0 0 0 0 .1L4.3 17a.1.1 0 0 0 .1 0L6 16a.6.6 0 0 1 .3-.1.6.6 0 0 1 .3 0 7 7 0 0 0 1.7.7.6.6 0 0 1 .4.5l.3 1.7a.1.1 0 0 0 .1.1H11a.1.1 0 0 0 .1 0l.3-1.8a.6.6 0 0 1 .4-.5 6.7 6.7 0 0 0 1.8-.7.6.6 0 0 1 .6 0l1.5 1a.1.1 0 0 0 .1 0l1.3-1.2a.1.1 0 0 0 0-.1L16 14a.6.6 0 0 1-.1-.6 6.7 6.7 0 0 0 .7-1.8.6.6 0 0 1 .5-.4z"
                    fill="#a5a8ba"
                    fill-rule="evenodd"
                  />
                  <path
                    d="M5.7 10a4.3 4.3 0 1 1 4.3 4.4A4.3 4.3 0 0 1 5.7 10zm1.1 0A3.2 3.2 0 1 0 10 6.8 3.2 3.2 0 0 0 6.8 10z"
                    fill="#a5a8ba"
                    fill-rule="evenodd"
                  />
                </svg>
                <span [attr.data-cy]="'navigation-shell.component.acctSettings'">Account Settings</span>
              </li>

              <li (click)="handleClick({ logout: true })">
                <svg height="15.639" viewBox="0 0 19.804 15.639" width="19.804" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(-979.927 -475.102)">
                    <g transform="translate(985.185 477.167)">
                      <line
                        fill="none"
                        stroke="#a5a8ba"
                        stroke-linecap="round"
                        stroke-width="1.75"
                        transform="translate(0 5.881)"
                        x2="12.69"
                      />
                      <g transform="translate(7.428)">
                        <line
                          fill="none"
                          stroke="#a5a8ba"
                          stroke-linecap="round"
                          stroke-width="1.75"
                          x2="5.881"
                          y2="5.881"
                        />
                        <line
                          fill="none"
                          stroke="#a5a8ba"
                          stroke-linecap="round"
                          stroke-width="1.75"
                          transform="translate(0 5.881)"
                          x2="5.881"
                          y1="5.881"
                        />
                      </g>
                    </g>
                    <g transform="translate(980.802 475.979)">
                      <g transform="translate(0)">
                        <path
                          d="M986.539,475.4h-5.363s-1.063-.1-1.063,1.174v6.791"
                          fill="none"
                          stroke="#a5a8ba"
                          stroke-linecap="round"
                          stroke-width="1.75"
                          transform="translate(-980.113 -475.403)"
                        />
                        <path
                          d="M986.539,483.368h-5.363s-1.063.1-1.063-1.174V475.4"
                          fill="none"
                          stroke="#a5a8ba"
                          stroke-linecap="round"
                          stroke-width="1.75"
                          transform="translate(-980.113 -469.485)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <span [attr.data-cy]="'navigation-shell.component.logout'">Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="profileContentReact" *ngIf="companies && companies[0].companyType === 'dispensary'">
      <app-profile-dropdown
        [props]="{
          companies: dispensaries$ | async,
          currentCompany: currentDispensary$ | async,
          firstName: firstName,
          lastName: lastName,
          email: email
        }"
        (clickEmitter)="handleClick($event)"
      ></app-profile-dropdown>
    </div>
  </div>

  <div [attr.data-cy]="'navBar'" class="leftSideNav">
    <ng-content data-cy="navigation-shell.component.leftSideNav" select="[sideNav]"></ng-content>
  </div>
  <div class="mainContent" data-cy="navigation-shell.component.mainContent">
    <div class="contentInner routerWrapperFullWidth" data-cy="navigation-shell.component.contentInner">
      <ng-content select="[routerOutlet]"></ng-content>
    </div>
  </div>

  <div class="footer"></div>
  <div class="mobileMenu" data-cy="navigation-shell.component.mobileMenu">
    <svg class="mobileMenuIcon" height="35px" viewBox="0 0 100 100" width="35px" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 34h56a4 4 0 0 0 4-4 4 4 0 0 0-4-4H22a4 4 0 0 0-4 4 4 4 0 0 0 4 4zm56 12H22a4 4 0 0 0-4 4 4 4 0 0 0 4 4h56a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm0 20H22a4 4 0 0 0-4 4 4 4 0 0 0 4 4h56a4 4 0 0 0 4-4 4 4 0 0 0-4-4z"
        fill="#828599"
      />
    </svg>
  </div>
</div>
<mat-sidenav-container [hasBackdrop]="true" class="sideNavContainer" fixedInViewport="true">
  <mat-sidenav
    #sidenav
    [(opened)]="opened"
    [ngClass]="opened ? 'sideNavComponent open' : 'sideNavComponent'"
    data-cy="navigation-shell.component.mat-sidenav"
    id="sideNavComponent"
    mode="side"
    position="end"
  >
    <div class="notificationContent">
      <div class="notificationHeader">
        <svg
          class="notificationBackButton"
          (click)="handleDismissNotificationDrawer()"
          height="32"
          version="1"
          width="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
            <circle cx="15" cy="15" fill="#FFF" r="15" stroke="#DBE0EB" />
            <path d="M18 20a1 1 0 0 1-1 1l-6-6 6-6a1 1 0 0 1 1 1l-5 5 5 5z" fill="#A5A8BA" fill-rule="nonzero" />
          </g>
        </svg>
        <div class="notificationHeaderWrapper">
          <span class="notificationHeaderText">Notifications</span
          ><a class="notificationClearAll" (click)="clearNotifications()">Clear all</a>
        </div>
      </div>
      <div class="notificationTabWrapper">
        <div class="tabWrapper">
          <div
            (click)="handleNotificationTabClicked(0)"
            [ngClass]="tabIndex === 0 ? 'tabActive' : 'tabInactive'"
            class="tab"
          >
            <span>New</span>
          </div>
        </div>
      </div>
      <div class="notificationBodyWrapper">
        <div *ngIf="tabIndex === 0">
          <gcv-notification-card
            (notificationClicked)="handleNotificationClicked($event)"
            *ngFor="let notification of notificationsIncomplete"
            [notification]="notification"
            data-cy="navigation-shell.component.notificationsIncomplete"
          ></gcv-notification-card>
        </div>
        <div *ngIf="tabIndex === 1">
          <gcv-notification-card
            *ngFor="let notification of notificationsComplete"
            [notification]="notification"
            data-cy="navigation-shell.component.notificationsComplete"
          ></gcv-notification-card>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
