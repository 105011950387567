import { EventEmitter, Output, Directive } from '@angular/core';
import { UtilsService } from '@user-interface/gcv-ui';
import * as _ from 'lodash';
import { Bank, Dispensary } from '@gcv/shared';

@Directive()
export class SaveContent {
  @Output() public saveEmitter = new EventEmitter();

  constructor(public utils: UtilsService) {}

  /*
   * Use nestedProperty to handle when results of forms are nested like
   * bank.primary_point_of_contact = {}
   * Pass the nested property as the argument. Argument is optional.
   */

  saveContent(e, org: Bank | Dispensary, nestedProperty?: string) {
    /* Checks if save data is from an editable hours card */
    if (e.hasOwnProperty('monday')) {
      const newOrg = this.utils.filterOutEmptyProperties({ ...org, businessHours: e });
      if (!_.isEqual(newOrg, org)) {
        this.saveEmitter.emit(newOrg);
      }
    } else if (nestedProperty) {
      const newOrg = this.utils.filterOutEmptyProperties({ ...org, [nestedProperty]: e });
      if (!_.isEqual(newOrg, org)) {
        this.saveEmitter.emit(newOrg);
      }
    } else {
      const newOrg = this.utils.filterOutEmptyProperties({ ...org, ...e });
      if (!_.isEqual(newOrg, org)) {
        this.saveEmitter.emit(newOrg);
      }
    }
  }
}
