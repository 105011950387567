<gcv-card class="card" data-cy="editable-card.component">
  <div *ngIf="!editable" card-body class="staticCard" data-cy="editable-card.component.staticCard">
    <div class="editButtonWrapper">
      <span class="title" data-cy="editable-card.component.title">{{ title }}</span>
      <div *ngIf="showEdit">
        <div (click)="edit()" class="editIcon" data-cy="editable-card.component.editPermissions">
          <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 12v3.3c0 .2 0 .4.2.5l.5.2H4c.2 0 .3 0 .5-.2L15.8 4.5a1 1 0 0 0 0-1L12.5.2a.7.7 0 0 0-1 0L9.3 2.5l-9 9c-.2.1-.3.3-.3.5zM12 1.7L14.3 4 13 5.2 10.8 3 12 1.7zM1.4 12.3L9.8 4 12 6.2l-8.3 8.4H1.4v-2.3z"
              fill="#A5A8BA"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
    <div *ngIf="!addressContent" class="formWrapper" data-cy="editable-card.component.formWrapper">
      <div *ngFor="let key of formKey" [ngClass]="['innerInputWrapper', key.value]">
        <span class="innerInputText" data-cy="editable-card.component.innerInputText">{{ key.viewValue }}</span>
        <span
          *ngIf="
            key.value !== 'establishedDate' &&
            key.value !== 'entityType' &&
            key.value !== 'cannabisLicenseExpirationDate' &&
            key.value !== 'business_type'
          "
          class="innerInputValue"
          data-cy="editable-card.component.innerInputValue"
          >{{ key.prefixValue }}{{ data.controls[key.value].value }}{{ key.suffixValue }}</span
        >
        <span
          *ngIf="key.value === 'establishedDate' || key.value === 'cannabisLicenseExpirationDate'"
          class="innerInputValue"
          data-cy="editable-card.component.establishedDate"
          >{{ data.controls[key.value].value | date }}</span
        >
        <span *ngIf="key.value === 'entityType'" class="innerInputValue" data-cy="editable-card.component.entityType">{{
          entityNameMap[data.controls[key.value].value]
        }}</span>
        <span
          *ngIf="key.value === 'business_type'"
          class="innerInputValue"
          data-cy="editable-card.component.business_type"
          >{{
            data.controls[key.value].value === 'retail'
              ? 'Retail (Business-to-Consumer)'
              : 'Wholesale (Business-to-Business)'
          }}</span
        >
      </div>
    </div>
    <div *ngIf="addressContent" class="formWrapper" data-cy="editable-card.component.addressContent">
      <div class="addressWrapper">
        <span>{{
          data.controls['streetAddress'] ? data.controls['streetAddress'].value : data.controls['address'].value
        }}</span>
        <span *ngIf="data.controls['city'].value"
          >{{ data.controls['city'].value }}, {{ data.controls['state'].value }}
          {{ data.controls['postalCode'] ? data.controls['postalCode'].value : data.controls['zipcode'].value }}</span
        >
        <span>{{ data.controls['country'].value }}</span>
        <span *ngIf="data.controls['iana_timezone']">{{ data.controls['iana_timezone'].value }}</span>
      </div>
    </div>
    <ng-content select="[card-body]"></ng-content>
  </div>

  <div *ngIf="editable" card-body class="editableCard" data-cy="editable-card.component.editableCard">
    <h2 class="title" data-cy="editable-card.component.title">{{ title }}</h2>
    <div class="contentWrapper">
      <div *ngFor="let key of formKey" class="inputWrapper" data-cy="editable-card.component.inputWrapper">
        <gcv-mat-input
          (blurCalled)="onBlurCalled(key.value)"
          (focusCalled)="onFocusCalled(key.value)"
          (value)="handleInput($event)"
          *ngIf="
            key.value !== 'country' &&
            key.value !== 'state' &&
            key.value !== 'establishedDate' &&
            key.value !== 'primaryReg' &&
            key.value !== 'entityType' &&
            key.value !== 'description' &&
            key.value !== 'posName' &&
            key.value !== 'business_type' &&
            key.value !== 'iana_timezone'
          "
          [control]="key.value"
          [errors]="tempData.controls[key.value].errors ? tempData.controls[key.value].errors : []"
          [group]="tempData"
          [label]="key.viewValue"
          [prefixValue]="key.prefixValue"
          [prefix]="key.prefix"
          [required]="key.required"
          [suffixValue]="key.suffixValue"
          [suffix]="key.suffix"
          [autoFill]="'off'"
          [validators]="key.validatorText ? key.validatorText : []"
          data-cy="editable-card.component.app-mat-input"
          [type]="key.type ? key.type : 'text'"
        ></gcv-mat-input>
        <div *ngIf="key.value === 'posName'">
          <gcv-mat-input
            [group]="data"
            control="posName"
            type="text"
            label="POS"
            [errors]="data.controls['posName'].errors"
            [validators]="key.validatorText ? key.validatorText : []"
          >
          </gcv-mat-input>
        </div>
        <div *ngIf="key.value === 'country'">
          <mat-form-field>
            <mat-select
              [(value)]="tempData.controls[key.value].value"
              [required]="key.required"
              data-cy="editable-card.component.countrySelect"
              placeholder="Country"
              (selectionChange)="selectionChanged($event, key.value)"
            >
              <mat-option *ngFor="let item of countries" [value]="item.viewValue" data-cy="editable-card.country">
                {{ item.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="key.value === 'state' && !isDispensary">
          <mat-form-field>
            <mat-select
              [disabled]="lockStates || (formKey[stateIndex] && formKey[stateIndex].disabled)"
              [(value)]="tempData.controls[key.value].value"
              [required]="key.required"
              data-cy="editable-card.component.stateSelect"
              placeholder="State"
              (selectionChange)="selectionChanged($event, key.value)"
            >
              <mat-option *ngFor="let item of states" [value]="item.value" data-cy="editable-card.component.state">
                {{ item.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="key.value === 'state' && isDispensary">
          <mat-form-field>
            <mat-select
              [(value)]="tempData.controls[key.value].value"
              [disabled]="lockStates || (formKey[stateIndex] && formKey[stateIndex].disabled)"
              [required]="key.required"
              data-cy="editable-card.component.stateSelect"
              placeholder="State"
              (selectionChange)="selectionChanged($event, key.value)"
            >
              <mat-option
                *ngFor="let item of supportDispensaryStates"
                [value]="item.value"
                data-cy="editable-card.component.state"
              >
                {{ item.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="key.value === 'business_type'">
          <mat-form-field>
            <mat-select
              [(value)]="tempData.controls[key.value].value"
              [required]="key.required"
              placeholder="Business Type"
              (selectionChange)="selectionChanged($event, key.value)"
              data-cy="editable-card.component.business-type"
            >
              <mat-option [value]="'retail'" data-cy="retail">
                Retail (Business-to-Consumer)
              </mat-option>
              <mat-option [value]="'wholesale'" data-cy="wholesale">
                Wholesale (Business-to-Business)
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="key.value === 'iana_timezone'">
          <mat-form-field>
            <mat-select
              [(value)]="tempData.controls[key.value].value"
              [required]="key.required"
              data-cy="editable-card.component.timezone"
              placeholder="Timezone"
              (selectionChange)="selectionChanged($event, key.value)"
            >
              <mat-option
                *ngFor="let iana_timezone of iana_timezones"
                [value]="iana_timezone"
                data-cy="editable-card.component.state"
              >
                {{ iana_timezone }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="key.value === 'primaryReg'">
          <mat-form-field>
            <mat-select
              [(value)]="tempData.controls[key.value].value"
              [required]="key.required"
              data-cy="editable-card.component.regulatorSelect"
              placeholder="Primary Regulator"
              (selectionChange)="selectionChanged($event, key.value)"
            >
              <mat-option
                *ngFor="let item of primaryReg"
                [value]="item.value"
                data-cy="editable-card.component.regulator"
              >
                {{ item.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="key.value === 'establishedDate'">
          <mat-form-field>
            <input
              (focus)="picker.open()"
              [formControl]="tempData.controls[key.value]"
              [matDatepicker]="picker"
              [required]="key.required"
              class="fullWidth"
              data-cy="editable-card.component.establishedDate"
              matInput
              placeholder="Date Established"
            />
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker data-cy="editable-card.component.mat-datepicker"></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="key.value === 'description'">
          <mat-form-field class="textarea" [formGroup]="tempData">
            <textarea
              formControlName="description"
              matInput
              (change)="commentInput($event)"
              (keyup)="commentInput($event)"
              [attr.data-cy]="'cannabis-program-bank-onboarding-presenter.component.description'"
              cols="30"
              required
              placeholder="Description"
              rows="2"
            ></textarea>
          </mat-form-field>
        </div>
        <div *ngIf="key.value === 'entityType'">
          <mat-form-field>
            <mat-select
              [(value)]="tempData.controls[key.value].value"
              [required]="key.required"
              data-cy="editable-card.component.entityTypeSelect"
              placeholder="Entity Type"
              (selectionChange)="selectionChanged($event, key.value)"
            >
              <mat-option
                *ngFor="let item of entityType"
                [value]="item.value"
                data-cy="editable-card.component.entityType"
              >
                {{ item.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="btnWrapper">
      <gcv-button
        (clickEvent)="cancel()"
        buttonText="Cancel"
        data-cy="editable-card.component.cancelButton"
        type="tertiary fullWidth"
      ></gcv-button>
      <gcv-button
        (clickEvent)="save()"
        [disabled]="!tempData.valid"
        buttonText="Save"
        data-cy="editable-card.component.saveButton"
        type="primary fullWidth"
      ></gcv-button>
    </div>
  </div>
</gcv-card>
