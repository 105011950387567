import { Bank, FincenExportMapping, FincenExportRecord, User, SarPeriodAggregate } from '@gcv/shared';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { getEndpoint, useApi } from 'libs/react-ui/src/hooks';
import React, { useEffect, useState } from 'react';
import { GcvLoading } from '../../../lib';

import styled, { createGlobalStyle } from 'styled-components';
import {
  $white,
  $grey6,
  $primaryBorder,
  $accent,
  $grey2,
  $primary,
  $black,
  $danger,
} from 'libs/react-ui/src/util/styleVariables';
import { formatMoney, getFriendlyDate } from 'libs/react-ui/src/util/formatHelpers';

const ReportContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid ${$grey6};
  overflow: hidden;
  background: ${$white};
`;
const ProgressBar = styled.div`
  height: 0.75rem;
  width: 100%;
  border-radius: 10px;
  background-color: ${$primaryBorder};
  overflow: hidden;
  margin: 0.25rem 0;
}
`;
const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-right: 0.5px solid ${$grey6};
  padding: 0 0.5rem;
  box-sizing: border-box;
  min-width: 250px;
`;
const Title = styled.div`
  color: $grey1;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  flex: 1;
  border-right: 0.5px solid ${$grey6};
  min-width: 240px;
`;
const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
const Label = styled.div`
  color: ${$accent};
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
`;
const Date = styled.div`
  color: ${$grey2};
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
`;
const Subtitle = styled.div`
  color: ${$primary};
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
`;
const MainContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 2 200px;
  min-width: 240px;
  border-radius: none;
`;
const Wrapper = styled.div`
  flex: 1;
  display: flex;
  min-width: 200px;
  flex-flow: row nowrap;
`;
const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0.5rem 0.25rem;
`;
const SubContainerText = styled.div`
  font-weight: 400;
  color: ${$black};
  font-size: 16px;
  line-height: 18px;
`;

// .altTitle {
//   border: none;
// }
interface Props {
  sarPeriods: { [id: string]: SarPeriodAggregate };
}

export const FincenTabManagement = (props: Props) => {
  if (!props.sarPeriods) {
    return <GcvLoading></GcvLoading>;
  }

  const calculateProgress = period => {
    const widthPercentage = 100 * (period.num_days_into_period / 90);
    let color = '';
    if (widthPercentage < 25 && widthPercentage > 1) {
      color = '#00BC66';
    } else if (widthPercentage > 25 && widthPercentage <= 50) {
      color = '#6896FB';
    } else if (widthPercentage > 50 && widthPercentage <= 75) {
      color = '#F5A623';
    } else {
      color = '#FF4E50';
    }
    const style = { background: color, height: '100%', width: `${widthPercentage}%` };
    return style;
  };

  return (
    <>
      {Object.values(props.sarPeriods).map(period => (
        <ReportContainer key={period.dispensary_id}>
          <TitleContainer>
            <Title>{period.dispensary_name}</Title>
            <Subtitle>SAR Period {period.sar_period}</Subtitle>
          </TitleContainer>
          <ProgressContainer>
            <LabelWrapper>
              <Label> {period.num_days_into_period} / 90 days</Label>
              <Date>
                {getFriendlyDate(period.period_start_date)} - {getFriendlyDate(period.period_end_date)}
              </Date>
            </LabelWrapper>
            <ProgressBar>
              <div style={calculateProgress(period)}></div>
            </ProgressBar>
          </ProgressContainer>
          <MainContainer>
            <Wrapper>
              <Subcontainer>
                <Label>Total Deposited</Label>
                <SubContainerText>{formatMoney(period.total_deposited_dollars)}</SubContainerText>
              </Subcontainer>
              <Subcontainer>
                <Label>Deposits</Label> <SubContainerText>{period.total_num_deposits}</SubContainerText>
              </Subcontainer>
            </Wrapper>
            <Wrapper>
              <Subcontainer>
                <Label>Open Reports</Label>
                <SubContainerText style={{ color: $danger }}>{period.total_open_reports}</SubContainerText>
              </Subcontainer>
              <Subcontainer>
                <Label>CTRs</Label> <SubContainerText>{period.total_ctr_in_period}</SubContainerText>
              </Subcontainer>
            </Wrapper>
          </MainContainer>
        </ReportContainer>
      ))}
    </>
  );
};
