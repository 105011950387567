import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, Grid, TextField } from '@material-ui/core';
import { GcvPage } from '../../lib/GcvPage/GcvPage';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { GcvButton, GcvCheckbox, GcvDataTable, GcvInputSelectNew, GcvLoading, GcvZeroState } from '../../lib';
import { api } from 'libs/react-ui/src/api';
import { FincenCtrReport, FincenReport, FincenSarReport, IANATimezones } from '@gcv/shared';
import { DispensaryMap, getTableDataCtr, getTableDataSar } from '../../bank/fincen/reports-util';
import {
  account,
  amount,
  bsaIdUpdate,
  dateOfTransaction,
  dueDate,
  exportedOn,
  reportStatus,
  sarType,
  trackingId,
} from '../../bank/fincen/components/columns';
import ReactTooltip from 'react-tooltip';

export const GcvFincen: React.FC = props => {
  const apiClient = api();
  const [bankId, setBankId] = useState('');
  const [dispensaries, setDispensaries] = useState<DispensaryMap>();
  const [openReports, setOpenReports] = useState({
    ctrs: [],
    sars: [],
  });
  const [filedReports, setFiledReports] = useState({
    ctrs: [],
    sars: [],
  });
  const [reportType, setReportType] = useState('CTR');
  const [reportStatusType, setReportStatusType] = useState('open');
  const [reportsLoading, setReportsLoading] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState<FincenCtrReport[] | FincenSarReport[]>([]);
  const [toggledClearRows, setToggledClearRows] = useState<boolean>(false);

  const onSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRowsData(selectedRows);
  };

  const exportReports = async () => {
    await apiClient.gcv
      .generateFincenXml({ bankId, selectedReportIds: (selectedRowsData as FincenReport[]).map(r => r.id) }, () => {})
      .then(({ fileName, fileData }) => {
        const linkSource = `data:application/xml;base64,${fileData}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    setSelectedRowsData([]);
    setToggledClearRows(true);
  };

  const loadReports = async () => {
    setSelectedRowsData([]);
    const dispensaries = await apiClient.banks.getBankMinifiedDispensaries(bankId, setReportsLoading);
    setDispensaries(dispensaries);

    const reports = await apiClient.fincen.fincenReportByBankIdGetHandler(bankId, setReportsLoading);
    const openReports = {
      ctrs: [],
      sars: [],
    };
    const filedReports = {
      ctrs: [],
      sars: [],
    };

    reports.forEach(report => {
      if (report.report_type === 'SAR') {
        if (report.exported_on) {
          filedReports.sars.push(report);
        } else {
          openReports.sars.push(report);
        }
      } else if (report.report_type === 'CTR') {
        if (report.exported_on) {
          filedReports.ctrs.push(report);
        } else {
          openReports.ctrs.push(report);
        }
      }
    });

    setOpenReports(openReports);
    setFiledReports(filedReports);
  };

  const tableDataOpenCtr = getTableDataCtr(openReports.ctrs, dispensaries, IANATimezones.America_NewYork);
  const tableDataOpenSar = getTableDataSar(openReports.sars, dispensaries, IANATimezones.America_NewYork);
  const tableDataFiledCtr = getTableDataCtr(filedReports.ctrs, dispensaries, IANATimezones.America_NewYork);
  const tableDataFiledSar = getTableDataSar(filedReports.sars, dispensaries, IANATimezones.America_NewYork);

  const columnsOpenCtr = [
    account('Account'),
    dueDate('Due Date'),
    amount('Amount'),
    dateOfTransaction('Transaction Date'),
    reportStatus('Status', () => {}),
  ];

  const columnsFiledCtr = [
    account('Account'),
    exportedOn('Exported On'),
    amount('Amount'),
    dateOfTransaction('Transaction Date'),
    trackingId('Tracking ID', () => {}),
    bsaIdUpdate('BSA ID', () => {}),
  ];

  const columnsOpenSar = [
    account('Account'),
    sarType('Type'),
    dueDate('Due Date'),
    amount('Amount'),
    reportStatus('Status', () => {}),
  ];

  const columnsFiledSar = [
    account('Account'),
    sarType('Type'),
    exportedOn('Exported On'),
    amount('Amount'),
    trackingId('Tracking ID', () => {}),
    bsaIdUpdate('BSA ID', () => {}),
  ];

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    const toolTip = 'Fincen Report must be complete before exporting';
    return props.disabled ? (
      <label data-tip={toolTip}>
        <GcvCheckbox ref={ref} {...props} />
        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
      </label>
    ) : (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  return (
    <>
      <GcvPage
        header={<GcvPageHeader title={'FinCEN'} />}
        body={
          <>
            <Card>
              <CardHeader title="Load FinCEN Reports By Bank" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Bank ID"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBankId(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GcvButton primary={true} onClick={loadReports}>
                      Load Reports
                    </GcvButton>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions></CardActions>
            </Card>

            {dispensaries ? (
              <div style={{ margin: '1rem 0', display: 'flex' }}>
                <GcvInputSelectNew
                  label="Type"
                  options={[
                    { label: 'Currency Transaction Reports', value: 'CTR' },
                    { label: 'Suspicious Activity Reports', value: 'SAR' },
                  ]}
                  onChange={e => {
                    setReportType(e.value);
                    setSelectedRowsData([]);
                    setToggledClearRows(true);
                  }}
                  dropWidth={'260px'}
                  labelWidth={'60px'}
                  dropCharLength={35}
                  defaultValueDrop={reportType}
                />

                <GcvInputSelectNew
                  label="Status"
                  options={[
                    { label: 'Open', value: 'open' },
                    { label: 'Filed', value: 'filed' },
                  ]}
                  onChange={e => {
                    setReportStatusType(e.value);
                    setSelectedRowsData([]);
                    setToggledClearRows(true);
                  }}
                  dropWidth={'260px'}
                  labelWidth={'60px'}
                  dropCharLength={35}
                  defaultValueDrop={reportStatusType}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <GcvButton primary={true} disabled={selectedRowsData.length === 0} onClick={exportReports}>
                    Export
                  </GcvButton>
                </div>
              </div>
            ) : null}

            {reportsLoading ? (
              <GcvLoading></GcvLoading>
            ) : reportStatusType === 'open' ? (
              <GcvDataTable
                data={reportType === 'SAR' ? tableDataOpenSar : tableDataOpenCtr}
                columns={reportType === 'SAR' ? columnsOpenSar : columnsOpenCtr}
                defaultSortField={'due_date'}
                defaultSortAsc={true}
                keyField="id"
                noDataComponent={<GcvZeroState type="basic" headerText={'Enter a bank ID above to load reports'} />}
                subHeaderAlign="left"
                selectableRows={true}
                onSelectedRowsChange={onSelectedRowsChange}
                paginationPerPage={10}
                selectableRowsComponent={renderSelectComponent}
                clearSelectedRows={toggledClearRows}
              />
            ) : (
              <GcvDataTable
                data={reportType === 'SAR' ? tableDataFiledSar : tableDataFiledCtr}
                columns={reportType === 'SAR' ? columnsFiledSar : columnsFiledCtr}
                defaultSortField={'due_date'}
                defaultSortAsc={true}
                keyField="id"
                noDataComponent={<GcvZeroState type="basic" headerText={'Enter a bank ID above to load reports'} />}
                subHeaderAlign="left"
                selectableRows={true}
                onSelectedRowsChange={onSelectedRowsChange}
                paginationPerPage={10}
                selectableRowsComponent={renderSelectComponent}
                clearSelectedRows={toggledClearRows}
              />
            )}
          </>
        }
      ></GcvPage>
    </>
  );
};
