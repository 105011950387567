import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { Bank } from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';
import { GcvButton, GcvDataTable, GcvLoading, GcvSearchInput, GcvZeroState } from 'libs/react-ui/src/lib';
import { PeerGroupDrawer } from './PeerGroupDrawer';

interface Props {
  bank: Bank;
  canEditDetails: boolean;
}

export const PeerGroups = (props: Props) => {
  const apiClient = api();
  const history = useHistory();
  const { handleSubmit, formState, ...form } = useForm({ mode: 'onChange' });

  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchableGroups, setSearchableGroups] = useState([]);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

  useEffect(() => {
    apiClient.peerGroups.getPeerGroups(props.bank.id, setIsLoading).then(groups => {
      const peerGroups = Object.values(groups.peer_groups).map((g: Record<string, unknown>) => {
        return { ...g, accounts: Object.keys(g.dispensaries).length };
      });

      setSearchableGroups(peerGroups);
      setFilteredGroups(peerGroups);
    });
  }, [props.bank.id]);

  useEffect(() => {
    if (!isLoading) {
      setFilteredAccountsFromQuery();
    }
  }, [query]);

  const groupColumns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      left: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      left: true,
    },
    {
      name: 'Accounts',
      selector: 'accounts',
      sortable: true,
      left: true,
    },
  ];

  const onSearchGroupsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const setFilteredAccountsFromQuery = () => {
    if (query) {
      setFilteredGroups(searchableGroups.filter(a => a.name.toLowerCase().includes(query.toLowerCase())));
    } else {
      setFilteredGroups(searchableGroups);
    }
  };

  const createGroup = data => {
    apiClient.peerGroups.createPeerGroup(props.bank.id, data, setIsLoading).then(group => {
      setCreateDrawerOpen(false);
      history.push(`peer-groups/${group.id}`);
    });
  };

  const handleRowClick = row => {
    history.push(`peer-groups/${row.id}`);
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: '-7.5rem' }}>
        <Grid item xs={7} sm={8} md={9}></Grid>
        <Grid item xs={5} sm={4} md={3} style={{ textAlign: 'right' }}>
          <>
            <GcvButton
              primary
              onClick={() => setCreateDrawerOpen(true)}
              data-cy="create-group-button"
              disabled={!props.canEditDetails}
            >
              Create Group
            </GcvButton>
            <br />
            <br />
          </>
          <GcvSearchInput
            label="Search Groups"
            placeholder="Search Groups"
            id="searchGroups"
            value={query}
            style={{ height: 'auto', marginTop: '0px', textAlign: 'left' }}
            onChange={onSearchGroupsChange}
            data-cy="search-groups"
          />
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <GcvLoading></GcvLoading>
          ) : (
            <GcvDataTable
              data={filteredGroups ? filteredGroups : []}
              columns={groupColumns}
              onRowClicked={row => {
                handleRowClick(row);
              }}
              defaultSortField={'name'}
              defaultSortAsc={true}
              keyField="id"
              noDataComponent={
                query ? (
                  <GcvZeroState
                    type="basic"
                    headerText={'There are no peer groups that match your search'}
                    subText={'Please try changing your search above to show more peer groups'}
                  />
                ) : (
                  <GcvZeroState
                    type="basic"
                    headerText={"You don't have any peer groups created yet"}
                    subText={'Create your first peer group using the button above'}
                  />
                )
              }
              subHeaderAlign="left"
              paginationPerPage={10}
              data-cy="peer-groups-table"
            />
          )}
        </Grid>
      </Grid>

      <PeerGroupDrawer
        toggleDrawer={() => setCreateDrawerOpen(false)}
        openDrawer={createDrawerOpen}
        onFormComplete={createGroup}
        completeButtonText={'Create'}
        loading={isLoading}
        headerText={'Create Group'}
      ></PeerGroupDrawer>
    </>
  );
};
