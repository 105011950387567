import * as React from 'react';
import { DateTime } from 'luxon';
import { Dispensary, User } from '@gcv/shared';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';

import { GcvCard, GcvButton, GcvModal } from '../../../lib/';
import { $grey11 } from 'libs/react-ui/src/util';
import { environment } from '../../../../../../apps/user-interface/src/environments/environment';
import { HeaderSubContent, BodyContainer } from './styles';
import { BodySubTitle, BodySubContainer, BodySubContent } from './styles';
import { getEndpoint, useApi } from 'libs/react-ui/src/hooks';
import { useState } from 'react';
import { formatMissingDash } from '../../../util';
import { Grid } from '@material-ui/core';
import { ModalContent } from '../styles';
import { ForwardArrowIcon } from 'libs/react-ui/src/icons/ForwardArrowIcon';
import { idtypes } from 'libs/gcv-ui/src/lib/constants';
import { api } from 'libs/react-ui/src/api';
import { s3Util } from 'libs/react-ui/src/util/s3.util';

interface Props {
  dispensary: Dispensary;
  owner: User;
  approve: () => {};
}

export const AccountOwnersCard = (props: Props) => {
  const apiClient = api();
  const s3 = s3Util(apiClient);

  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState('');
  const [fileType, setFileType] = useState('');
  const [toggleIdSide, setToggleIdSide] = useState(false);

  const hasIdentification =
    props.owner.identification && props.owner.identification.idFront && props.owner.identification.idBack;
  const identificationApproved =
    props.owner.identification &&
    props.owner.identification.idFront &&
    props.owner.identification.idBack &&
    props.owner.identification.idFront.status === 'approved' &&
    props.owner.identification.idBack.status === 'approved';

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getDocumentsByUserIdAndId = async (userId, documentId) => {
    return await apiClient.documents.getUserDocumentById(userId, documentId, () => {});
  };

  const getUserDocumentById = async (userId, documentId) => {
    try {
      const result = await getDocumentsByUserIdAndId(userId, documentId);
      if (Array.isArray(result)) {
        return result[0];
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const getUserPresignedUrl = async s3Key => {
    const body = {
      bucket: `${environment.env}-user-documents-file-bucket`,
      key: s3Key,
      action: 'get',
      userType: 'bank',
    };

    return await apiClient.permissions.getS3Permission(body, () => {});
  };

  const viewId = async () => {
    const { idFront, idBack } = props.owner.identification;
    const id = toggleIdSide ? idBack : idFront;
    const { s3_key } = await getUserDocumentById(props.owner.id, id.document_id);
    const { s3LinkPath } = await getUserPresignedUrl(s3_key);

    // get extension from filename
    var re = /(?:\.([^.]+))?$/;
    const ext = re.exec(id.filename)[1].toLowerCase();
    setFileType(ext);

    fetch(s3LinkPath).then(async r => {
      const base64 = await r.text();
      setFile(base64);
    });

    setToggleIdSide(!toggleIdSide);

    if (!modalOpen) {
      toggleModal();
    }
  };

  const approveIdentification = () => {
    props.approve();
    toggleModal();
  };

  const getIdType = idType => {
    return idtypes.find(type => type.value === idType).viewValue
      ? idtypes.find(type => type.value === idType).viewValue
      : 'Not Provided';
  };

  const viewDocument = () => {
    const blob = new Blob([file]);
    s3.openUrlFromBlob(blob, 'account-owner-id-document');
  };

  return (
    <>
      <GcvCard data-cy="account-owner-card">
        <GcvCard.Header
          title={
            <>
              <strong data-cy="account-owner-name">
                {formatMissingDash(props.owner.firstName + ' ' + props.owner.lastName)}
              </strong>
              <HeaderSubContent data-cy="account-owner-email">{formatMissingDash(props.owner.email)}</HeaderSubContent>
              <HeaderSubContent data-cy="account-owner-phone">{formatMissingDash(props.owner.phone)}</HeaderSubContent>
            </>
          }
        />
        <GcvCard.Body>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <BodySubTitle>Address</BodySubTitle>
              <BodySubContent data-cy="account-owner-address">{formatMissingDash(props.owner.address)}</BodySubContent>
            </Grid>
            <Grid item xs={2}>
              <BodySubTitle>City</BodySubTitle>
              <BodySubContent data-cy="account-owner-city">{formatMissingDash(props.owner.city)}</BodySubContent>
            </Grid>
            <Grid item xs={2}>
              <BodySubTitle>State</BodySubTitle>
              <BodySubContent data-cy="account-owner-state">{formatMissingDash(props.owner.state)}</BodySubContent>
            </Grid>
            <Grid item xs={2}>
              <BodySubTitle>Zip Code</BodySubTitle>
              <BodySubContent data-cy="account-owner-zip">{formatMissingDash(props.owner.zipcode)}</BodySubContent>
            </Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={2}>
              <BodySubTitle>Date of Birth</BodySubTitle>
              <BodySubContent data-cy="account-owner-dob">{formatMissingDash(props.owner.dateOfBirth)}</BodySubContent>
            </Grid>
            <Grid item xs={2}>
              <BodySubTitle>ID Type</BodySubTitle>
              <BodySubContent data-cy="account-owner-id-type">
                {props.owner.identification ? formatMissingDash(getIdType(props.owner.identification.idType)) : '--'}
              </BodySubContent>
            </Grid>
            <Grid item xs={2}>
              <BodySubTitle>ID Number</BodySubTitle>
              <BodySubContent data-cy="account-owner-id-number">
                {props.owner.identification ? formatMissingDash(props.owner.identification.idNumber) : '--'}
              </BodySubContent>
            </Grid>
            <Grid item xs={2}>
              <BodySubTitle>Last Login</BodySubTitle>
              <BodySubContent data-cy="account-owner-last-login">
                {props.owner.lastLogin ? DateTime.fromISO(props.owner.lastLogin).toLocaleString() : '--'}
              </BodySubContent>
            </Grid>

            <Grid item xs={4}>
              {hasIdentification ? (
                <GcvButton
                  secondary={identificationApproved}
                  primary={!identificationApproved}
                  onClick={viewId}
                  data-cy="account-owner-view-id"
                >
                  {identificationApproved ? 'View ID Document' : 'Review ID Document'}
                </GcvButton>
              ) : null}
            </Grid>
          </Grid>
        </GcvCard.Body>
      </GcvCard>

      <GcvModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        backButton={<GcvButton onClick={toggleModal}>Close</GcvButton>}
        continueButton={
          identificationApproved ? null : (
            <GcvButton onClick={approveIdentification} primary={true} data-cy="approve-button">
              Approve
            </GcvButton>
          )
        }
        title={'View ID Document'}
      >
        <ModalContent data-cy="account-owner-documents-modal">
          <Grid container spacing={1}>
            <Grid
              item
              xs={1}
              onClick={viewId}
              style={{ cursor: 'pointer', transform: 'rotate(180deg)', margin: 'auto' }}
            >
              <ForwardArrowIcon></ForwardArrowIcon>
            </Grid>
            <Grid item xs={10}>
              {(fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') && (
                <img
                  src={`data:image/${fileType};base64,${file}`}
                  height="100%"
                  width="100%"
                  data-cy="account-owner-document"
                />
              )}
              {fileType === 'pdf' && (
                <iframe
                  src={`data:application/pdf;base64,${file}`}
                  height="100%"
                  width="100%"
                  data-cy="account-owner-document"
                ></iframe>
              )}
              <div
                onClick={viewDocument}
                style={{ cursor: 'pointer', margin: 'auto', textAlign: 'center', color: '#6896fb' }}
              >
                Open File in New Window
              </div>
            </Grid>
            <Grid item xs={1} onClick={viewId} style={{ cursor: 'pointer', margin: 'auto' }}>
              <ForwardArrowIcon></ForwardArrowIcon>
            </Grid>
          </Grid>
        </ModalContent>
      </GcvModal>
    </>
  );
};
