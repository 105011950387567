import { API } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { versions } from '../../environments/versions';

// X-Green-Check-Client-Sha
@Injectable({
  providedIn: 'root',
})
export class AuthHttpClient extends HttpClient {
  public injectedGitHash: string;

  constructor(httpHandler: HttpHandler) {
    super(httpHandler);
    this.setupAmplify();
    this.injectedGitHash = versions.revision;
  }

  setupAmplify() {
    API.configure({
      endpoints: [
        {
          name: GREEN_CHECK_SERVICES_AMPLIFY_NAME,
          endpoint: environment.basePath,
          custom_header: async () => {
            return { 'X-Green-Check-Client-Hash': versions.revision };
          },
        },
      ],
    });
  }

  get<T>(url: string, data): Observable<any> {
    const init: any = {
      header: data.headers,
      response: false,
    };

    if (data.params !== null && data.params !== undefined) {
      init.queryStringParameters = {};
      if (data.params.updates) {
        data.params.updates.forEach(paramData => {
          init.queryStringParameters[paramData.param] = paramData.value;
        });
      }
    }
    return from(API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, url.split(environment.basePath)[1], init));
  }

  put<T>(url: string, body, data) {
    const init: any = {
      header: data.headers,
      body,
      response: false,
    };

    if (data.params !== null && data.params !== undefined) {
      init.queryStringParameters = {};
      data.params.updates.forEach(paramData => {
        init.queryStringParameters[paramData.param] = paramData.value;
      });
    }

    return from(API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, url.split(environment.basePath)[1], init));
  }

  post<T>(url: string, body, data) {
    const init: any = {
      header: data.headers,
      body,
      response: false,
    };
    if (data.params !== null && data.params !== undefined) {
      init.queryStringParameters = {};
      data.params.updates.forEach(paramData => {
        init.queryStringParameters[paramData.param] = paramData.value;
      });
    }
    return from(API.post(GREEN_CHECK_SERVICES_AMPLIFY_NAME, url.split(environment.basePath)[1], init));
  }

  delete<T>(url: string, data) {
    const init: any = {
      header: data.headers,
      response: false,
    };

    if (data.params !== null && data.params !== undefined) {
      init.queryStringParameters = {};
      data.params.updates.forEach(paramData => {
        init.queryStringParameters[paramData.param] = paramData.value;
      });
    }

    return from(API.del(GREEN_CHECK_SERVICES_AMPLIFY_NAME, url.split(environment.basePath)[1], init));
  }
}
