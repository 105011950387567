export const tooltip = (title: string, rows: { label: string; value: string }[]) => {
  let body = '';

  rows.forEach((r, i) => {
    body += `<tr><td style="font-size: 14px; font-weight: 500; color: #3A3C45;">${r.value}</td><td style="text-align: right; color: #828599; font-size: 12px; font-weight: 600;">${r.label}</td></tr>`;

    if (i < rows.length - 1) {
      body += `<tr><td colspan="2" style="color: #E0E5F0;"><hr/></td></tr>`;
    }
  });

  return `<div style="padding: 10px;  min-width: 175px;"><b style="color: #3A3C45; font-size: 16px; font-weight: 700;">${title}</b><br/><br/><table style="width: 100%">${body}</table></div>`;
};
