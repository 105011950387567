import styled, { css } from 'styled-components';
import { $fontFamily } from '../../util/styleVariables';

interface DrawerProps {
  open?: boolean;
}

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 100;
  top: 0;
  right: 0;
`;

export const SlideDrawer = styled.div<DrawerProps>`
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  min-width: 420px;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  font-family: ${$fontFamily};
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${props =>
    props.open
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(100%);
        `}
  transition: transform 0.3s ease-out;
`;
