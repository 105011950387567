<div class="header cardContainer" data-cy="edit-group-wrapper.component" *ngIf="group">
  <div class="leftHeaderWrapper col-9">
    <div class="leftSide">
      <div class="backWrapper">
        <svg (click)="handleBack()" height="32" version="1" width="32" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
            <circle cx="15" cy="15" fill="#FFF" r="15" stroke="#DBE0EB" />
            <path d="M18 20a1 1 0 0 1-1 1l-6-6 6-6a1 1 0 0 1 1 1l-5 5 5 5z" fill="#A5A8BA" fill-rule="nonzero" />
          </g>
        </svg>
      </div>

      <div *ngIf="!editingTitle" class="headerRow">
        <h1 data-cy="edit-group-wrapper.component.groupName">{{ group.name }}</h1>
        <button
          (click)="handleEditClicked($event)"
          *ngIf="
            !(
              group.type === 'administration' ||
              group.type === 'account_owner' ||
              group.type === 'standard_users' ||
              group.type === 'deposit_recorders' ||
              group.type === 'deposit_transport'
            )
          "
          class="titleIcon edit "
          data-cy="edit-group-wrapper.component.editButton"
        >
          <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 12v4h5L16 5V4l-3-4a1 1 0 0 0-1 0L9 3l-9 9zM12 2l2 2-1 1-2-2 1-1zM1 12l9-8 2 2-8 9H1v-3z"
              fill="#A5A8BA"
              fill-rule="evenodd"
            />
          </svg>
        </button>
        <div
          *ngIf="
            group.type === 'administration' ||
            group.type === 'account_owner' ||
            group.type === 'standard_users' ||
            group.type === 'deposit_recorders' ||
            group.type === 'deposit_transport'
          "
          class="titleIcon"
          data-cy="edit-group-wrapper.component.titleIcon"
        >
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
              fill="#a5a8ba"
            />
          </svg>
        </div>
      </div>
      <div *ngIf="editingTitle" class="editingTitle">
        <gcv-mat-input
          [group]="editTitle"
          control="title"
          data-cy="edit-group-wrapper.component.editTitle"
          label="Group Name"
          required="true"
          type="text"
        >
        </gcv-mat-input>
        <gcv-button
          (clickEvent)="handleEditSaveClicked($event)"
          buttonText="Save"
          data-cy="edit-group-wrapper.component.saveButton"
        ></gcv-button>
      </div>
    </div>
    <gcv-button
      (clickEvent)="handleButtonClicked()"
      buttonText="Manage Users"
      [disabled]="buttonDisabled"
      data-cy="edit-group-wrapper.component.addRemoveUsers"
      type="secondary no-max-width"
    ></gcv-button>
  </div>
  <div class="rightHeaderWrapper col-3">
    <form [ngStyle]="{ width: '100%' }" class="filter-field">
      <mat-form-field [ngStyle]="{ width: '100%' }" appearance="standard">
        <mat-label>Search Users</mat-label>
        <input
          (input)="filterItems()"
          [(ngModel)]="filterText"
          data-cy="edit-group-wrapper.component.filterInput"
          matInput
          maxlength="100"
          name="filter"
          type="text"
        />
        <svg height="21.069" matSuffix viewBox="0 0 20.894 21.069" width="20.894" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.66,20.652,13.9,15.893a8.785,8.785,0,1,1,1.9-1.849l4.734,4.734a1.325,1.325,0,1,1-1.873,1.873ZM6.349,2.954A6.313,6.313,0,1,0,8.8,2.459a6.313,6.313,0,0,0-2.449.494Z"
            fill="#a5a8ba"
            transform="translate(-0.027 0.029)"
          />
        </svg>
      </mat-form-field>
    </form>
  </div>
</div>
<div class="content cardContainer" *ngIf="group">
  <div class="col-9">
    <gcv-data-table
      [columnNames]="displayedColumns"
      [dataMap]="dataMap"
      [data]="filteredUsers"
      data-cy="edit-group-wrapper.component.filteredUsers"
    ></gcv-data-table>
  </div>
  <div class="sidebar col-3">
    <div *ngIf="group.history" class="historyWrapper">
      <div class="header">
        <span>History of Changes</span>
      </div>
      <div
        *ngFor="let historyItem of group.history"
        class="historyItem"
        data-cy="edit-group-wrapper.component.historyItem"
      >
        <span class="date">{{ historyItem.date }}</span>
        <span class="name">{{ historyItem.name }}</span>
        <span class="details">{{ historyItem.details }}</span>
      </div>
    </div>
    <div
      *ngIf="
        group.type === 'administration' ||
        group.type === 'account_owner' ||
        group.type === 'standard_users' ||
        group.type === 'deposit_recorders' ||
        group.type === 'deposit_transport'
      "
      class="permissionsWrapper"
    >
      <div class="header">
        <span class="title">Roles</span>
        <span *ngIf="group.type === 'administration'" class="permissionCopy">
          Users in this Group have full and complete access to all Green Check features and information, including the
          ability to invite and manage other users.
        </span>
        <span *ngIf="group.type === 'account_owner'" class="permissionCopy">
          Users in this Group have the same access as Admin users and will receive any account-related communications
          from Green Check.
        </span>
        <span *ngIf="group.type === 'standard_users'" class="permissionCopy">
          Users in this Group have view-only access to the information on Green Check.
        </span>
        <span *ngIf="group.type === 'deposit_transport'" class="permissionCopy">
          This group lets you manage the users that are verified to transport your deposits. The members of this group
          will appear as options in the Method of Transportation field when creating a new deposit.
        </span>
        <span *ngIf="group.type === 'deposit_recorders'" class="permissionCopy">
          This group lets you manage the users that have the ability to view and record deposits in Green Check.
        </span>
      </div>
    </div>
    <div
      *ngIf="
        !(
          group.type === 'administration' ||
          group.type === 'account_owner' ||
          group.type === 'standard_users' ||
          group.type === 'deposit_recorders' ||
          group.type === 'deposit_transport'
        )
      "
      class="permissionsWrapper"
      data-cy="edit-group-wrapper.component.permissionsWrapper"
    >
      <div class="header">
        <span class="title">Roles</span>
        <span class="permissionCopy">
          Set the role that members will assume by being in this group.
        </span>
      </div>
      <div class="permissionContent">
        <div class="permissionItem">
          <div class="toggleWrapper">
            <mat-form-field>
              <mat-label>Choose Role</mat-label>
              <mat-select
                multiple
                placeholder="Role"
                [formControl]="roleForm"
                (selectionChange)="chooseRole($event)"
                data-cy="edit-group-wrapper.component.roleSelect"
              >
                <mat-option *ngFor="let role of roles" [value]="role.value">
                  {{ role.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
