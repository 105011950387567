import React, { useContext } from 'react';

import { DispensaryAccountReviewWithData, PeerGroupPeriodSummary, PeriodSummary } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { VerticalBarConfig } from './chart-options';
import { tooltip } from './Tooltip';
import { formatChangePercentage, formatMoney, formatPercentage } from 'libs/react-ui/src/util';
import { AccountMonitoringContext } from '../../account-monitoring-context';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const SalesSummaryWholesaleTransactionsChart: React.FC<Props> = props => {
  const context = useContext(AccountMonitoringContext);
  return <GcvChart type="verticalBar" config={chartDataAdapter(props.review, context.peerGroupId)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData, peerGroupId: string) => {
  return dataAdapter(review.data.aggregated_sales_data, peerGroupId);
};

export const dataAdapter = (
  data: {
    current_period: PeriodSummary;
    previous_period: PeriodSummary;
    previous_year: PeriodSummary;
    peer_groups: {
      [id: string]: PeerGroupPeriodSummary;
    };
  },
  peerGroupId: string
): VerticalBarConfig => {
  return {
    data: [
      {
        label: '', // This Period
        value: data.current_period.total_number_of_transactions,
        color: '#00BC66',
        tooltext: tooltip('Transactions', [
          {
            value: `${data.current_period.total_number_of_transactions}`,
            label: 'This Period',
          },
          {
            value: `${data.previous_year.total_number_of_transactions}`,
            label: 'Prev Year Avg',
          },
          {
            value: formatChangePercentage(
              data.previous_period.total_number_of_transactions,
              data.current_period.total_number_of_transactions
            ),
            label: 'Change Over Last Period',
          },
          {
            value: (
              data.peer_groups[peerGroupId]?.avg_number_of_verified_transactions +
              data.peer_groups[peerGroupId]?.avg_number_of_unverified_transactions +
              data.peer_groups[peerGroupId]?.avg_number_of_unchecked_transactions
            ).toString(),
            label: 'Peer Group Avg',
          },
        ]),
      },
      {
        label: '', // Previous Year Average
        value: data.previous_year.total_number_of_transactions,
        color: '#1270FC',
        tooltext: tooltip('Transactions', [
          {
            value: `${data.current_period.total_number_of_transactions}`,
            label: 'This Period',
          },
          {
            value: `${data.previous_year.total_number_of_transactions}`,
            label: 'Prev Year Avg',
          },
          {
            value: formatChangePercentage(
              data.previous_period.total_number_of_transactions,
              data.current_period.total_number_of_transactions
            ),
            label: 'Change Over Last Period',
          },
          {
            value: (
              data.peer_groups[peerGroupId]?.avg_number_of_verified_transactions +
              data.peer_groups[peerGroupId]?.avg_number_of_unverified_transactions +
              data.peer_groups[peerGroupId]?.avg_number_of_unchecked_transactions
            ).toString(),
            label: 'Peer Group Avg',
          },
        ]),
      },
      {
        label: '', // Peer Group Average
        value:
          data.peer_groups[peerGroupId]?.avg_number_of_verified_transactions +
          data.peer_groups[peerGroupId]?.avg_number_of_unverified_transactions +
          data.peer_groups[peerGroupId]?.avg_number_of_unchecked_transactions,
        color: '#F5A623',
        tooltext: tooltip('Transactions', [
          {
            value: `${data.current_period.total_number_of_transactions}`,
            label: 'This Period',
          },
          {
            value: `${data.previous_year.total_number_of_transactions}`,
            label: 'Prev Year Avg',
          },
          {
            value: formatChangePercentage(
              data.previous_period.total_number_of_transactions,
              data.current_period.total_number_of_transactions
            ),
            label: 'Change Over Last Period',
          },
          {
            value: (
              data.peer_groups[peerGroupId]?.avg_number_of_verified_transactions +
              data.peer_groups[peerGroupId]?.avg_number_of_unverified_transactions +
              data.peer_groups[peerGroupId]?.avg_number_of_unchecked_transactions
            ).toString(),
            label: 'Peer Group Avg',
          },
        ]),
      },
    ],
  };
};
