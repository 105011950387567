import styled from 'styled-components';
import { $grey12, $primary } from 'libs/react-ui/src/util';

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const VerticalCenter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const FilterButton = styled.div`
  display: flex;
  cursor: pointer;
  padding: 5px;
  flex-grow: 2;
  background-color: white;
  height: 34px;
  box-sizing: border-box;
  border: 1px solid #ebeef7;
  border-left: none;
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: space-between;
`;

export const Option = styled.div`
  display: block;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  overflow: visible;

  &:hover {
    background-color: ${$grey12};
  }
`;

export const PermanentDisplay = styled.div`
  flex-direction: column;
  display: flex;
  z-index: 10000;
  position: absolute;
  background-color: white;
  border: 1px solid #ebeef7;
  border-radius: 0 0 4px 4px;
`;

export const FilterLabel = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: #828599;
  background-color: #f2f4fa;
  border: 1px solid #ebeef7;
  border-right: none;
  border-radius: 4px 0 0 4px;
  padding: 6px 8px;
  flex-grow: 1;
  text-align: center;
  height: 34px;
  box-sizing: border-box;
`;

export const WidthSetter = styled.div`
  visibility: hidden;
  padding: 0px 8px;
  height: 1px;
`;

export const DropContainer = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: white;
  border: 1px solid #ebeef7;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  overflow: auto;
  max-height: 15rem;
`;

export const DropButton = styled.div`
  box-sizing: border-box;
  height: 34px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: white;
  border: 1px solid #ebeef7;
  font-family: Lato;
  font-style: normal;
  font-size: 16px;
  line-height: 14px;
  color: black;
`;

export const ContainerDiv = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
`;
// export const ContainerDiv = styled.div`
//   position: relative;
//   display: inline-block;

//   &:hover ${DropContainer} {
//     display: block;
//   }
// `;
