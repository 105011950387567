import { ReactWrapperComponent, WrapperProps, AdditionalInformation } from '@user-interface/react-ui';

import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Dispensary, DueDiligenceTemplate } from '@gcv/shared';

import { slideInAnimation } from '../../app/animations/router-animation';

import { DispensaryFacade } from '@user-interface/gcv-state';

import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'additional-information',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="emitData"></app-react-wrapper>',
  animations: [trigger('routeAnimations', [transition('active <=> pending', [useAnimation(slideInAnimation)])])],
})
export class AdditionalInformationComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = AdditionalInformation;
  bankTemplate: DueDiligenceTemplate;
  dispensary: Dispensary;

  constructor(private dispFacade: DispensaryFacade, private router: Router) {
    super();

    this.props = {
      ...this.props,
      emitBack: this.emitBack,
      emitNext: this.emitNext,
      emitData: this.emitData,
    };

    this.emitData.bind(this);
  }

  ngOnInit() {
    this.setUpStreams();
  }

  setUpStreams() {
    this.dispFacade
      .selectCurrentDispensary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((disp: Dispensary) => {
        this.dispensary = disp;
        this.props = { ...this.props, dispensary: disp };
      });

    this.dispFacade
      .selectDispensaryGcvDueDiligenceTemplate()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(template => {
        this.props = { ...this.props, bankTemplate: template?.bank };
      });
  }

  emitBack = () => {
    if (this.dispensary.due_diligence.due_diligence_status === 'bank_in_progress') {
      this.router.navigate(['/secure/dispensary/found-bank']).then(() => {
        this.dispFacade.getDispensarybyId(this.dispensary.id);
      });
    } else {
      this.router.navigate(['/secure/dispensary/onboarding/verify-identity']).then(() => {
        this.dispFacade.getDispensarybyId(this.dispensary.id);
      });
    }
  };

  emitData = (data?) => {
    if (data) {
      this.dispFacade.updateDispensary(data);
    }
  };

  emitNext = () => {
    if (this.dispensary.due_diligence.due_diligence_status === 'bank_in_progress') {
      this.router.navigate(['/secure/dispensary/found-bank/documentation']).then(() => {
        this.dispFacade.getDispensarybyId(this.dispensary.id);
      });
    } else {
      this.router.navigate(['/secure/dispensary/onboarding/account-owners']).then(() => {
        this.dispFacade.getDispensarybyId(this.dispensary.id);
      });
    }
  };
}
