import React, { useMemo, useEffect, useState } from 'react';
import { Oval } from 'svg-loaders-react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { GcvDataTable } from '../../../lib/GcvDataTable/GcvDataTable';
import { $primary } from '../../../util/styleVariables';
import { LoadingContainer } from './styles';
import { useApi } from '../../../hooks/useApi';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '../../../../../common-ui/src';
import { formatMoney } from '../../../util/formatHelpers';
import { GcvSearchDropDown, GcvTimePeriodDropdown, GcvZeroState } from '../../../lib';
import calculateTimeRange from '../../../util/calculateDateRange';
import { api } from 'libs/react-ui/src/api';
import { IANATimezones } from '@gcv/shared';

interface Props {
  bankId: string;
  dispensaries;
}

interface Option {
  value: string;
  label: string;
}

export const DailySummariesFi = (props: Props) => {
  const [summaries, setSummaries] = useState(null);
  const [filteredSummaries, setFilteredSummaries] = useState(null);
  const [timePeriod, setTimePeriod] = useState(calculateTimeRange('last30Days', IANATimezones.America_NewYork)); //unused component, dummy timezone
  const [loading, setLoading] = useState(true);
  const [distinctAccounts, setDistinctAccounts] = useState<Option[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState<Option[]>([]);
  const { url } = useRouteMatch();
  const history = useHistory();
  const apiClient = api();

  const columns = useMemo(
    () => [
      {
        name: 'Account',
        selector: 'dispensary_name',
        sortable: true,
        grow: 2,
        center: true,
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
        center: true,

        format: row => DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_MED),
      },
      {
        name: 'Verified',
        selector: 'verified_percent',
        sortable: true,
        center: true,
        format: row => `${Math.floor(row.verified_percent) || 0}%`,
      },
      {
        name: 'Total Sales',
        selector: 'total_sales',
        sortable: true,
        right: true,
        format: row => formatMoney(row['total_sales']),
      },
      {
        name: 'Cash Total',
        selector: 'total_cash',
        sortable: true,
        right: true,
        format: row => formatMoney(row['total_cash']),
      },
      {
        name: 'Debit Total',
        selector: 'total_debit',
        sortable: true,
        right: true,
        format: row => formatMoney(row['total_debit']),
      },
    ],
    []
  );

  const fetchDailySummaries = async () => {
    try {
      const startDay = DateTime.fromISO(timePeriod.start).toFormat('yyyy-MM-dd');
      const endDay = DateTime.fromISO(timePeriod.end).toFormat('yyyy-MM-dd');
      const summaries = await apiClient.banks.getDailySummary(props.bankId, startDay, endDay, () => {});

      setSummaries(summaries);

      if (filteredAccounts.length > 0) {
        const filteredSummaries = summaries.filter(s => filteredAccounts.includes(s.dispensary_id));
        setFilteredSummaries(filteredSummaries);
      } else {
        setFilteredSummaries(summaries);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDailySummaries();
  }, [timePeriod]);

  useEffect(() => {
    if (summaries) {
      const filteredSummaries = summaries.filter(s => filteredAccounts.includes(s.dispensary_id));
      setFilteredSummaries(filteredSummaries);
    }
  }, [filteredAccounts]);

  useEffect(() => {
    if (props.dispensaries) {
      const dispensaryOptions = Object.values(props.dispensaries).map((d: any) => {
        return { value: d.id, label: d.name };
      });
      setDistinctAccounts(dispensaryOptions);
    }
  }, [props.dispensaries]);

  const handleFilterAccounts = results => {
    setFilteredAccounts(results);
  };

  const handleTimeChange = results => {
    const timeRange = calculateTimeRange(results.value, IANATimezones.America_NewYork); //unused component, dummy timezone
    setTimePeriod(timeRange);
  };

  const handleRowClick = rowData => {
    const { date, dispensary_id } = rowData;
    history.push(`${url}/detail/${dispensary_id}/${date}`);
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Oval height={75} width={75} stroke={$primary} viewBox="0 0 38 38" />
      </LoadingContainer>
    );
  }

  return (
    <>
      <div style={{ marginBottom: '1rem', display: 'flex' }}>
        <div style={{ marginRight: '1rem' }}>
          <GcvSearchDropDown
            distinctItems={distinctAccounts}
            searchText={'Search Accounts'}
            itemLabel={'Account(s)'}
            filterReport={handleFilterAccounts}
          />
        </div>
        <GcvTimePeriodDropdown
          emitData={handleTimeChange}
          labelText={'Time Period'}
          newStyle={true}
          defaultValueDrop={'last30Days'}
        ></GcvTimePeriodDropdown>
      </div>
      <GcvDataTable
        data={filteredSummaries}
        columns={columns}
        onRowClicked={handleRowClick}
        keyField="react_key"
        defaultSortField="date"
        defaultSortAsc={false}
        noDataComponent={
          <GcvZeroState
            type="bankDash"
            headerText="There is no sales data for the selected accounts in this time period"
            subText="Please try changing your filters to show more records"
          />
        }
      />
    </>
  );
};
