import React, { BaseSyntheticEvent } from 'react';

import { GcvButton } from '../../lib';
import { Footer, VerticalCenter } from './styles';

interface Props {
  type: string;
  formComplete: boolean;
  handleSubmit: (e: BaseSyntheticEvent<object, any, any>) => Promise<void>;
}

export const DepositDetailDrawerFooter: React.FC<Props> = props =>
  props.type === 'bank' ? (
    <Footer>
      <VerticalCenter>
        <GcvButton
          disabled={!props.formComplete}
          primary={true}
          onClick={props.handleSubmit}
          data-cy="deposit-reconcile"
        >
          Reconcile Deposit
        </GcvButton>
      </VerticalCenter>
    </Footer>
  ) : null;
