import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { countries, states } from '../../constants';
import { filterOutEmptyProperties } from '../../utils';
import { Router } from '@angular/router';
import { EinPipe, FormatPhonePipe, ZipPipe } from '../../pipes';
import {
  einAndTinValidators,
  requiredAddressPatternValidator,
  requiredCitiesPatternValidator,
  requiredPhoneNumberValidator,
  requiredZipCodePatternValidator,
} from '../../../../../../apps/user-interface/src/validators/commonValidators';
import { DispensaryFacade } from '@user-interface/gcv-state';
import { Dispensary } from '@gcv/shared';

@Component({
  selector: 'gcv-create-transportation-vendor',
  templateUrl: './create-transportation-vendor.html',
  styleUrls: ['./create-transportation-vendor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTransportationVendorComponent {
  transportationGroup: FormGroup;
  section = 0;
  states = states;
  countries = countries;
  vendor: any;
  dispensary: Dispensary;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private matDialogRef: MatDialogRef<CreateTransportationVendorComponent>,
    private phonePipe: FormatPhonePipe,
    private zipPipe: ZipPipe,
    private einPipe: EinPipe,
    private dispFacade: DispensaryFacade
  ) {
    this.vendor = data.edit;
    this.transportationGroup = new FormGroup({
      name: new FormControl(this.vendor && this.vendor.name ? this.vendor.name : '', [
        Validators.required,
        Validators.pattern("[a-zA-Z 0-9 ,&':;.#!-]{2,50}"),
      ]),
      street: new FormControl(
        this.vendor && this.vendor.street ? this.vendor.street : '',
        requiredAddressPatternValidator
      ),
      city: new FormControl(this.vendor && this.vendor.city ? this.vendor.city : '', requiredCitiesPatternValidator),
      state: new FormControl(this.vendor && this.vendor.state ? this.vendor.state : '', Validators.required),
      zipCode: new FormControl(
        this.vendor && this.vendor.zipCode ? this.vendor.zipCode : '',
        requiredZipCodePatternValidator
      ),
      country: new FormControl('unitedstates', Validators.required),
      tin: new FormControl(this.vendor && this.vendor.tin ? this.vendor.tin : '', einAndTinValidators),
      phone: new FormControl(this.vendor && this.vendor.phone ? this.vendor.phone : '', requiredPhoneNumberValidator),
    });
    this.section = data.section;
  }

  ngOnInit() {
    this.dispFacade.selectCurrentDispensary().subscribe(disp => {
      this.dispensary = disp;
    });
  }

  goToTransportation() {
    this.router.navigate(['/secure/dispensary/users/groups']);
  }

  cancel() {
    this.matDialogRef.close();
  }

  save() {
    this.matDialogRef.close({
      data: { ...this.vendor, ...filterOutEmptyProperties(this.transportationGroup.getRawValue()) },
    });
  }

  formatPhone() {
    const defaultValue = this.transportationGroup.get('phone').value;
    this.transportationGroup.get('phone').setValue(this.phonePipe.transform(defaultValue));
  }

  unformatPhone() {
    const defaultValue = this.transportationGroup.get('phone').value;
    if (defaultValue !== undefined) {
      this.transportationGroup.get('phone').setValue(this.phonePipe.parse(defaultValue));
    }
  }

  formatLongZip() {
    const defaultValue = this.transportationGroup.get('zipCode').value;
    if (defaultValue !== undefined && defaultValue.length === 9) {
      this.transportationGroup.get('zipCode').setValue(this.zipPipe.transform(defaultValue));
    }
  }

  unformatLongZip() {
    const defaultValue = this.transportationGroup.get('zipCode').value;
    if (defaultValue !== undefined && defaultValue.length === 10) {
      this.transportationGroup.get('zipCode').setValue(this.zipPipe.parse(defaultValue));
    }
  }

  formatEin() {
    const defaultValue = this.transportationGroup.get('tin').value;
    if (defaultValue !== undefined && defaultValue.length === 9) {
      this.transportationGroup.get('tin').setValue(this.einPipe.transform(defaultValue));
    }
  }

  unformatEin() {
    const defaultValue = this.transportationGroup.get('tin').value;
    if (defaultValue !== undefined && defaultValue.length === 10) {
      this.transportationGroup.get('tin').setValue(this.einPipe.parse(defaultValue));
    }
  }

  addUser() {
    const transportGroupId = this.dispensary.groups.find(group => group.type === 'deposit_transport').id;
    this.matDialogRef.close();
    this.router.navigate([`secure/dispensary/users/group/edit`], { queryParams: { id: transportGroupId } });
  }
}
