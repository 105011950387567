import { DueDiligenceStatus } from '@gcv/shared';

export enum ViewStates {
  DueDiligenceZeroState = 'DueDiligenceZeroState',
  DueDiligenceInProgressState = 'DueDiligenceInProgressState',
  DefaultState = 'DefaultState',
}

export function determineStatus(dueDiligenceStatus) {
  if (dueDiligenceStatus === DueDiligenceStatus.GCV_PENDING) {
    return ViewStates.DueDiligenceZeroState;
  }

  if (dueDiligenceStatus === DueDiligenceStatus.GCV_IN_PROGRESS) {
    return ViewStates.DueDiligenceInProgressState;
  }

  return ViewStates.DefaultState;
}
