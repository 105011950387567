import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { BankFacade, DispensaryFacade, UserFacade } from '@user-interface/gcv-state';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Dispensary, User } from '@gcv/shared';
import { isGcvCustomerSupportUserAndNeedsToSignIn } from './support-user-login.util';
import { getDispensaryDashboardOverviewCommand } from '../app.routes.names';
import { Bank } from '@gcv/shared';

@Injectable()
export class CanActivateSupportUserLogin implements CanActivate, CanActivateChild {
  constructor(
    private userFacade: UserFacade,
    private dispensaryFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userFacade.selectCurrentUser().pipe(
      map((user: User) => {
        // need to make sure we have a user available or this will break
        if (!user) {
          this.router.navigate(['/']);
          return false;
        }
        return user;
      }),
      take(1),
      tap((user: User) => {
        if (user.companies[0].companyType === 'dispensary') {
          this.dispensaryFacade.getDispensarybyId(user.companies[0].id);
        } else {
          this.bankFacade.getBankById(user.companies[0].id);
        }
      }),
      mergeMap((user: User) => {
        if (user.companies[0].companyType === 'dispensary') {
          return this.dispensaryFacade.selectDispensaryById(user.companies[0].id).pipe(
            filter((dispensary: Dispensary) => dispensary !== undefined),
            take(1),
            map((dispensary: Dispensary) => {
              const routeToSupportLogin = user && isGcvCustomerSupportUserAndNeedsToSignIn(user);

              return { routeToSupportLogin, orgName: dispensary.name };
            })
          );
        } else {
          return this.bankFacade.selectBankById(user.companies[0].id).pipe(
            filter((bank: Bank) => bank !== undefined),
            take(1),
            map((bank: Bank) => {
              const routeToSupportLogin = user && isGcvCustomerSupportUserAndNeedsToSignIn(user);

              return { routeToSupportLogin, orgName: bank.orgName };
            })
          );
        }
      }),
      map(mappedObject => {
        let canActivate: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
        if (mappedObject.routeToSupportLogin) {
          if (!this.router.getCurrentNavigation().extras.state) {
            this.router.getCurrentNavigation().extras.state = {};
          }

          this.router.getCurrentNavigation().extras.state.orgName = mappedObject.orgName;
          canActivate = true;
        } else {
          canActivate = false;
        }

        return canActivate;
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    throw this.canActivate(childRoute, state);
  }
}
