<div class="identityWrapper">
  <div class="orgWrapper">
    <div class="header">
      <h3>Identity Verification</h3>
    </div>
    <div class="body">
      <p class="label">Primary Contact</p>
      <p class="data">{{ user.firstName }} {{ user.lastName }}</p>
      <p class="label">Driver's License</p>
      <p class="data">{{ user.identification.idNumber }}</p>
      <p class="label">Date of Birth</p>
      <p class="data">
        {{ user.dateOfBirth | date: 'shortDate':'UTC' }}
      </p>
      <p class="label">Address</p>
      <p class="data noskip">{{ user.address | titlecase }}</p>
      <p class="data">{{ user.city | titlecase }}, {{ user.state | titlecase }} {{ user.zipcode }}</p>
    </div>
    <div class="idWrapper" *ngIf="user.identification">
      <app-view-document-button
        [dispensaryId]="dispensary.id"
        [docKey]="{ key: 'idFront' }"
        [isGcv]="false"
        [s3Key]="user.identification.idFront.s3Key"
        class="full-width pdf-button"
        documentName="idFront"
        [multipleDocuments]="true"
        [documentIds]="[user.identification.idFront.document_id]"
        type="image"
      >
      </app-view-document-button>
      <app-view-document-button
        [dispensaryId]="dispensary.id"
        [docKey]="{ key: 'idBack' }"
        [isGcv]="false"
        [s3Key]="user.identification.idBack.s3Key"
        [multipleDocuments]="true"
        [documentIds]="[user.identification.idBack.document_id]"
        class="full-width pdf-button"
        documentName="idBack"
        type="image"
      >
      </app-view-document-button>
    </div>
    <div class="btnWrapper">
      <gcv-button
        type="primary"
        (clickEvent)="close()"
        buttonText="Close"
        [attr.data-cy]="'view-account-mot.component.manuallyVerify'"
      ></gcv-button>
    </div>
  </div>
</div>
