import React from 'react';
import { MinifiedDispensary, DueDiligenceStatus } from '@gcv/shared';
import { AccountCardContainer } from './AccountCardContainer';
import { name, city, licenseType, submittedOn, reviewStatus } from '../columns';

interface Props {
  dispensaries: MinifiedDispensary[];
  tableView: boolean;
  isFiltered: boolean;
  emitData: (e) => void;
}

export const AwaitingReview = ({ dispensaries, tableView, isFiltered, emitData }: Props) => {
  const activeDispensaries = dispensaries.filter(
    dispensary => dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_AWAITING_REVIEW
  );

  const emitDataActive = dispensaryId => {
    emitData({ type: 'awaiting-review', dispensaryId });
  };

  const columns = [
    name('Name'),
    city('City'),
    licenseType('License Type'),
    submittedOn('Submitted On'),
    reviewStatus('Review Status', emitDataActive),
  ];

  return (
    <AccountCardContainer
      accounts={activeDispensaries}
      tableView={tableView}
      emitData={emitDataActive}
      tableColumns={columns}
      sortKey={'submitted_date'}
      noAccountsZeroStateText={'You currently have no accounts awaiting review'}
      isFiltered={isFiltered}
    />
  );
};
