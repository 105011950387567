import { ReactWrapperComponent, WrapperProps } from '../../../../util';
import { CreateNewFincenReportButton } from './CreateNewFincenReportButton';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  FINCEN_ROUTES_NAMES,
  getCreateRouteForFincenReportType,
} from 'apps/user-interface/src/app/bank/fincen/fincen.routes.names';
import { BankFacade } from '@user-interface/gcv-state';
import { Bank } from '@gcv/shared';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'create-new-fincen-report-button',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleClick"></app-react-wrapper>',
})
export class CreateNewFincenReportButtonComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Output() clickEmitter = new EventEmitter<any>();

  component = CreateNewFincenReportButton;

  constructor(private router: Router, private bankFacade: BankFacade) {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  ngOnInit() {
    this.props = {};
    const bank$ = this.bankFacade.selectCurrentBank();

    bank$.pipe(takeUntil(this.unsubscribe$)).subscribe((bank: Bank) => {
      this.props.bankId = bank.id;
    });
  }

  handleClick(path: typeof FINCEN_ROUTES_NAMES.SAR | typeof FINCEN_ROUTES_NAMES.CTR) {
    this.router.navigate([getCreateRouteForFincenReportType(path)]);
  }
}
