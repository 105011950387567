import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MinifiedDispensary, Deposit, IANATimezones } from '@gcv/shared';
import { CreateReportTab1, CreateReportTab2, CreateReportTab3 } from './components';
import styled from 'styled-components';
import { $grey1 } from '../../util';
import { GcvPage } from '../../lib/GcvPage/GcvPage';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { GcvTabWizard, dateFormatterToISO } from '../../lib';
import { api } from '../../api';

interface Props {
  emitData: (payload) => void;
  dispensaries: { [id: string]: MinifiedDispensary };
  deposits: Deposit[];
  bankId: string;
  timezone: IANATimezones;
  staff: any;
}

interface Data {
  startDate?: string;
  type?: 'sar' | 'ctr';
  filingName?: string;
  filingType?: { label: string; value: string };
  generateEvery90Days?: boolean;
  account?: { label: string; value: string };
  selectedDeposits?: Deposit[];
  loadDispensaryData?: (dispensary_id, startDate?, endDate?) => void;
  reconcileDeposit?: (deposit, bankId) => void;
  dispensaries?: { [id: string]: MinifiedDispensary };
  deposits?: Deposit[];
  bankId?: string;
  staff?: any;
  bsaID?: string;
  priorReport?: string;
  timezone?: IANATimezones;
}

export const NewReportPage: React.FC<Props> = ({ dispensaries, deposits, bankId, staff, emitData, timezone }) => {
  const history = useHistory();
  const apiClient = api();

  const tabs = [
    {
      component: CreateReportTab1,
      name: 'Report Information',
    },
    {
      component: CreateReportTab2,
      name: 'Account',
    },
    {
      component: CreateReportTab3,
      name: 'Transactions',
    },
  ];

  const reportTypeFromRoute = window.location.pathname.split('/').pop() as 'sar' | 'ctr';

  const loadDispensaryData = dispensary_id => {
    emitData({ type: 'resetDeposits', dispensary_id });
  };

  const reconcileDeposit = (deposit, bankIdForDeposit) => {
    emitData({ type: 'reconcileDeposit', reconcileDeposit: { deposit, bankId: bankIdForDeposit } });
  };

  const [data, setData] = useState<Data>({
    type: reportTypeFromRoute,
    loadDispensaryData,
    reconcileDeposit,
    dispensaries,
    deposits,
    bankId,
    staff,
    timezone,
  });
  let currentData: Data = {};

  const updateData = newData => {
    currentData = { ...data, ...newData };
    setData({ ...data, ...newData });
  };

  const onComplete = async () => {
    const {
      type,
      startDate,
      filingName,
      filingType,
      generateEvery90Days,
      account,
      selectedDeposits,
      bsaID,
      priorReport,
      bankId,
    } = currentData;

    const dispensaryId = account && account.value;
    const filingTypeValue = filingType && filingType.value;

    const cleanData = {
      type,
      filingName,
      generateEvery90Days,
      priorReport,
      dispensaryId,
      selectedDeposits,
      bsaID,
      bankId,
      startDate: dateFormatterToISO(startDate),
      filingType: filingTypeValue,
    };

    if (type === 'sar') {
      await apiClient.fincen
        .fincenReportSarPostHandler(cleanData, () => {})
        .then(report => {
          emitData({ type: 'updateReport', reports: [report] });
          emitData({ type: 'viewReport', reportId: report.id });
        });
    } else {
      await apiClient.fincen
        .fincenReportCtrPostHandler(cleanData, () => {})
        .then(report => {
          emitData({ type: 'updateReport', reports: [report] });
          emitData({ type: 'viewReport', reportId: report.id });
        });
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    setData({
      ...data,
      dispensaries,
      deposits,
      bankId,
      staff,
      timezone,
    });
  }, [dispensaries, deposits, bankId, staff]);

  return (
    <GcvPage
      header={<GcvPageHeader title={'Create New FinCEN Report'} handleBackClick={onCancel} />}
      body={
        <span data-cy="create-new-fincen-report-wrapper">
          <GcvTabWizard tabs={tabs} updateData={updateData} onComplete={onComplete} onCancel={onCancel} data={data} />
        </span>
      }
    />
  );
};
