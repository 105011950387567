import styled, { createGlobalStyle } from 'styled-components';

export const IconContainer = styled.div`
  height: 60px;
  margin-bottom: 35px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
`;
export const SubText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 35px 0;
`;
export const Status = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
`;
export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #ebeef7;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 40px;
  cursor: pointer;
  margin: 0px 10px 10px 0px;
  flex: 1 0 31%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 250px;
`;
export const CardRowContainer = styled.div`
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
`;

export const PosIcon = styled.img`
  margin: 10px auto;
`;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 13.5px;
`;

export const DrawerText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`;
export const DrawerList = styled.li`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
`;

export const DrawerImage = styled.img`
  margin: 35px 0px;
  width: 350px;
  height: 88px;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 28px 65px;
  height: 77vh;
  overflow-y: auto;
  box-sizing: border-box;
`;

export const DrawerHeader = styled.div`
  height: 9vh;
  padding: 23px;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f2f9;
  position: sticky;
  top: 0;
  background-color: white;
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const FooterContent = styled.div`
  height: 14vh;
  padding: 14px 65px;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  border-top: 1px solid #f0f2f9;
  position: sticky;
  bottom: 0;
  background-color: white;
`;
