import React from 'react';
import { PeerGroups } from './components/PeerGroups';
import { TransactionCodes } from './components/TransactionCodes';
import { GcvTabs } from '../../lib/GcvTabs/GcvTabs';
import { RouteObject } from '../../util/types';
import { Route } from 'react-router-dom';
import { Header } from './styles';
import { Bank, OrganizationRoleResolver, User } from '@gcv/shared';
import { GcvLoading } from '../../lib';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { GcvPage } from '../../lib/GcvPage/GcvPage';

interface Props {
  bank: Bank;
  user: User;
}

const Tabs: RouteObject[] = [
  {
    path: '/secure/bank/settings/peer-groups',
    render: props => <PeerGroups {...props}></PeerGroups>,
  },
  {
    path: '/secure/bank/settings/transaction-codes',
    render: props => <TransactionCodes {...props}></TransactionCodes>,
  },
];

export const Settings = (props: Props) => {
  const orgResolver = new OrganizationRoleResolver();
  const canEditDetails = props.bank
    ? orgResolver.userCanDoAction(props.bank.groups, props.user, 'peer_groups_edit')
    : true;

  const settingsTabs = [
    { path: `/secure/bank/settings/peer-groups`, label: `Peer Groups` },
    { path: `/secure/bank/settings/transaction-codes`, label: `Transaction Codes` },
  ];

  return (
    <GcvPage
      loader={!props.bank && <GcvLoading></GcvLoading>}
      header={
        <>
          <GcvPageHeader
            title={'Settings'}
            subTitle={
              <div style={{ margin: '1rem 0', justifyContent: 'space-between', display: 'flex' }}>
                <GcvTabs routes={settingsTabs}></GcvTabs>
              </div>
            }
          ></GcvPageHeader>
        </>
      }
      body={
        <>
          {Tabs.map(({ path, render }) => {
            return (
              <Route exact path={path} key={path}>
                {({ match }) => (match != null ? render({ ...props, canEditDetails }) : <></>)}
              </Route>
            );
          })}
        </>
      }
    />
  );
};
