import React, { useEffect, useState } from 'react';
import { VerticalCenter } from './styles';
import { Dispensary, Bank, User, OrganizationRoleResolver } from '@gcv/shared';
import { useHistory, Route, useParams } from 'react-router-dom';
import { RouteObject } from '../../util/types';
import { AccountOwners } from './components/AccountOwners';
import { AccountGeneral } from './components/AccountGeneral';
import { AccountOperational } from './components/AccountOperational';
import { AccountDocuments } from './components/AccountDocuments';
import { SectionTypes } from './Account-Review-Overview.page';
import { GcvLoading } from '../../lib';
import { api } from '../../api';
import { AccountAdditionalInformation } from './components/AccountAdditionalInformation';

interface Props {
  bank: Bank;
  user: User;
  emitData: (type: string, data) => void;
}

const Sections: RouteObject[] = [
  {
    path: '/secure/bank/account/:dispensary_id/review/account-owners',
    render: props => <AccountOwners {...props} />,
  },
  {
    path: '/secure/bank/account/:dispensary_id/review/business',
    render: props => <AccountGeneral {...props} />,
  },
  {
    path: '/secure/bank/account/:dispensary_id/review/operational',
    render: props => <AccountOperational {...props} />,
  },
  {
    path: '/secure/bank/account/:dispensary_id/review/documentation',
    render: props => <AccountDocuments {...props} />,
  },
  {
    path: '/secure/bank/account/:dispensary_id/review/additional-information',
    render: props => <AccountAdditionalInformation {...props} />,
  },
];

export const ReviewAccountSection = ({ bank, user, emitData }: Props) => {
  const history = useHistory();
  const apiClient = api();
  const { dispensary_id } = useParams<{ dispensary_id: string }>();
  const [loading, setLoading] = useState(false);
  const [dispensaryUsers, setDispensaryUsers] = useState<User[]>([]);
  const [selectedDispensary, setSelectedDispensary] = useState<Dispensary>();

  const getDispensary = async () => {
    const dispensary = await apiClient.banks.getDispensary(bank.id, dispensary_id, () => {});
    setSelectedDispensary(dispensary);
  };

  const getUsers = async () => {
    const users = await apiClient.organizations.getUsersForOrg(dispensary_id, () => {});
    setDispensaryUsers(users);
  };

  useEffect(() => {
    getDispensary();
    getUsers();
  }, []);

  if (!selectedDispensary) {
    return <GcvLoading></GcvLoading>;
  }

  const onBack = () => {
    history.push(`/secure/bank/account/${dispensary_id}/review/overview`);
  };

  const onSave = async (type: SectionTypes) => {
    const operation_details =
      type === 'operational' ? true : selectedDispensary.due_diligence.operational_details_section_bank_review_complete;
    const account_owners =
      type === 'account-owners' ? true : selectedDispensary.due_diligence.account_owners_section_bank_review_complete;
    const business_details =
      type === 'business' ? true : selectedDispensary.due_diligence.business_details_section_bank_review_complete;
    const due_diligence_documentation =
      type === 'documentation'
        ? true
        : selectedDispensary.due_diligence.due_diligence_documentation_section_bank_review_complete;
    const additional_information =
      type === 'additional-information'
        ? true
        : selectedDispensary.due_diligence.additional_information_section_review_complete;

    const body = {
      operation_details,
      account_owners,
      due_diligence_documentation,
      business_details,
      additional_information,
    };

    await apiClient.dispensaries
      .updateDispensarySectionDueDiligenceStatus(dispensary_id, body, setLoading)
      .then(dispensary => {
        history.push(`/secure/bank/account/${dispensary_id}/review/overview`);
        setSelectedDispensary(dispensary);
        emitData('updateDispensary', dispensary);
      });
  };

  const canUserApproveAccounts = new OrganizationRoleResolver().userCanDoAction(
    bank.groups,
    user,
    'account_info_update'
  );

  return (
    <>
      {loading ? (
        <VerticalCenter style={{ width: '100%', margin: '0 auto' }}>
          <GcvLoading></GcvLoading>
        </VerticalCenter>
      ) : (
        Sections.map(({ path, render }) => {
          return (
            <Route exact path={path} key={path}>
              {({ match }) =>
                match != null ? (
                  <>
                    {render({
                      dispensary: selectedDispensary,
                      bank,
                      onSave,
                      onBack,
                      dispensaryUsers,
                      canUserApproveAccounts,
                      isActiveAccount: false,
                    })}
                  </>
                ) : (
                  <></>
                )
              }
            </Route>
          );
        })
      )}
    </>
  );
};
