/*
/// SAMPLE UI JSON ///

{
  "type": "Form",
  "title": "Section 1",
  "components": [
    {
      "type": "Grid",
      "spacing": 3,
      "components": [
        {
          "type": "GridItem",
          "size": 6,
          "components": [
            {
              "type": "TextField",
              "id": "firstName",
              "label": "First Name"             
            }
          ]
        },
        {
          "type": "GridItem",
          "size": 6,
          "components": [
            {
              "type": "TextField",
              "id": "lastName",
              "label": "Last Name"
            }
          ]
        },
        {
          "type": "GridItem",
          "size": 12,
          "components": [
            {
              "type": "TextArea",
              "id": "customerDescription",
              "label": "Description"
            }
          ]
        },
        {
          "type": "GridItem",
          "size": 12,
          "components": [
            {
              "type": "Dropdown",
              "id": "customerType",
              "label": "Type",
              "items": [
                {
                  "label": "Bronze",
                  "value": "bronze"
                },
                {
                  "label": "Silver",
                  "value": "silver"
                },
                {
                  "label": "Gold",
                  "value": "gold"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
} 
  
*/

import React, { ReactNode, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItemIcon,
  ListItem,
  List,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { UiState } from './ui-state';
import { GcvCheckbox, GcvContent, GcvInputForm, GcvInputSelect, GcvInputTextArea, GcvButton } from '../../lib';
import { useFormContext, useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
import { HorizontalCenter } from '../../styles/theme';
import { $grey2, $primaryBorder, $primary } from '../../util';
import { Dispensary } from '@gcv/shared';

const GreenCheckbox = withStyles({
  root: {
    color: '',
    '&$checked': {
      color: $primary,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export type ComponentType = 'Checkbox' | 'Dropdown' | 'Form' | 'Grid' | 'GridItem' | 'Text' | 'TextArea' | 'TextField';

export interface UiComponent {
  type: ComponentType;
  components?: DynamicComponent[];
}

interface NavLinks {
  name: string | number;
  text: string;
  isComplete?: boolean;
}

export interface CheckboxComponent extends UiComponent {
  type: 'Checkbox';
  id: string;
  label: string;
  value: boolean;
  rules?: { required: boolean };
}

export interface DropdownComponent extends UiComponent {
  type: 'Dropdown';
  id: string;
  label: string;
  value: string;
  items: { label: string; value: string }[];
  rules?: { required: boolean };
}

export interface GridComponent extends UiComponent {
  type: 'Grid';
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

export interface GridItemComponent extends UiComponent {
  type: 'GridItem';
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto';
}

export interface TextComponent extends UiComponent {
  type: 'Text';
  id: string;
  content: string;
}

export interface TextAreaComponent extends UiComponent {
  type: 'TextArea';
  id: string;
  label: string;
  value: string;
  rows?: number;
  rules?: { required: boolean };
}

export interface TextFieldComponent extends UiComponent {
  type: 'TextField';
  id: string;
  label: string;
  value: string;
  rules?: { required: boolean };
}

export interface FormComponent extends UiComponent {
  type: 'Form';
  title: string;
}

export type DynamicComponent =
  | CheckboxComponent
  | DropdownComponent
  | FormComponent
  | GridComponent
  | GridItemComponent
  | TextComponent
  | TextAreaComponent
  | TextFieldComponent;

export const DynamicUi: React.FC<{
  uiJson: string;
  uiData: string;
  readOnly?: boolean;
  saveData?: (data) => Promise<Dispensary>;
  optionalEdit?: boolean;
  loading?: boolean;
}> = props => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [completedSections, setCompletedSections] = useState(null);
  const [defaultFormValues, setDefaultFormValues] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(props.readOnly);
  const form = useForm();

  const uiJsonObject = JSON.parse(props.uiJson ? props.uiJson : '[]');
  const uiDataObject = props.uiData
    ? UiState.populate(uiJsonObject, JSON.parse(props.uiData ? props.uiData : '[]'))
    : uiJsonObject;

  const updateCompletedSections = customFieldsResponses => {
    const newCompletedSections = UiState.checkCompletedSections(
      (uiJsonObject as unknown) as FormComponent[],
      customFieldsResponses
    );
    setCompletedSections(newCompletedSections);
    setDefaultFormValues(customFieldsResponses);
  };

  useEffect(() => {
    if (props.uiJson && !completedSections) {
      updateCompletedSections(props.uiData ? JSON.parse(props.uiData) : {});
    }
  }, [props.uiData, props.uiJson]);

  if (!uiDataObject || !Array.isArray(uiDataObject) || !completedSections) {
    return <></>;
  }

  const navLinks: NavLinks[] = uiDataObject.map((form, index) => {
    return {
      name: index,
      text: form.title,
      isComplete: completedSections[form.title],
    };
  });

  const onNavigate = async (activeLink: string | number, data) => {
    if (!isReadOnly) {
      setActiveIndex(Number(activeLink));
    } else {
      setActiveIndex(Number(activeLink));
    }
  };

  const onSave = async (values?) => {
    try {
      const formValues = values ? values : form.getValues();
      const dispensary: Dispensary = await props.saveData(formValues);

      if (dispensary) {
        updateCompletedSections(dispensary.due_diligence.bank_custom_fields.responses);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onBack = async () => {
    if (activeIndex > 0) {
      await onSave();
      setActiveIndex(activeIndex - 1);
    }
  };

  const onNext = async values => {
    if (activeIndex < uiDataObject.length - 1) {
      await onSave(values);
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <Card style={{ maxHeight: '585px', border: `1px solid ${$primaryBorder}`, boxShadow: '0px' }}>
      {props.optionalEdit && (
        <CardHeader
          title={<GcvContent type="h3" content={'Additional Information'} />}
          disableTypography={true}
          action={
            <div
              style={{ cursor: 'pointer', padding: '1.5rem', visibility: isReadOnly ? 'visible' : 'hidden' }}
              onClick={() => setIsReadOnly(false)}
            >
              <a>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0164558 11.9998L0 15.2911C0 15.4766 0.0675108 15.662 0.202743 15.7973C0.337976 15.9325 0.505909 16 0.692196 16L3.96647 15.9835C4.15191 15.9835 4.32069 15.9158 4.45592 15.7808L15.7636 4.47232C16.0338 4.20291 16.0338 3.76367 15.7636 3.47675L12.523 0.202691C12.2528 -0.0675635 11.8144 -0.0675635 11.5277 0.202691L9.26628 2.48139L0.219199 11.5103C0.101266 11.6453 0.0164558 11.8143 0.0164558 11.9998ZM12.0336 1.68772L14.3123 3.96642L13.03 5.2487L10.7513 2.97L12.0336 1.68772ZM1.41752 12.3038L9.75488 3.96642L12.0336 6.24512L3.69621 14.5652L1.40106 14.5825L1.41752 12.3038Z"
                    fill="#A5A8BA"
                  />
                </svg>
              </a>
            </div>
          }
        />
      )}
      <CardContent style={{ overflow: 'auto', maxHeight: '455px' }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <form key={JSON.stringify(uiDataObject[activeIndex])}>
              <UiGenerator
                dynamicUi={uiDataObject[activeIndex]}
                readOnly={isReadOnly}
                form={form}
                register={form.register}
                defaultFormValues={defaultFormValues}
              />
            </form>
          </Grid>

          <Grid
            item
            xs={4}
            style={{
              borderLeft: `1px solid ${$grey2}`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {uiDataObject.length > 1 ? (
              <>
                <div>
                  <GcvContent type="h1" content={'Sections'} style={{ marginLeft: '1rem' }} />
                  <ListNav
                    activeLinkName={activeIndex}
                    navLinks={navLinks}
                    onNavigate={onNavigate}
                    clickable={true}
                    handleSubmit={form.handleSubmit}
                  />
                </div>
              </>
            ) : null}
            {!isReadOnly ? (
              <div style={{ position: 'sticky', bottom: '-32px', padding: '1rem', backgroundColor: 'white' }}>
                <HorizontalCenter>
                  <GcvButton
                    secondary
                    onClick={async () => {
                      await onSave();
                      if (props.optionalEdit) {
                        setIsReadOnly(true);
                      }
                    }}
                    style={{ width: '100%' }}
                    isLoading={props.loading}
                  >
                    Save
                  </GcvButton>
                </HorizontalCenter>
                {uiDataObject.length > 1 ? (
                  <HorizontalCenter style={{ justifyContent: 'space-evenly', marginTop: '1rem' }}>
                    <GcvButton onClick={onBack}>Back</GcvButton>
                    <GcvButton primary onClick={form.handleSubmit(onNext)}>
                      Next
                    </GcvButton>
                  </HorizontalCenter>
                ) : null}
              </div>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const UiGenerator: React.FC<{
  form: any;
  register: any;
  dynamicUi: DynamicComponent;
  readOnly?: boolean;
  defaultFormValues?: Record<string, string>;
}> = props => {
  if (!props.dynamicUi) {
    return <></>;
  }

  switch (props.dynamicUi.type) {
    case 'Checkbox':
      return (() => {
        const existingValue = props.defaultFormValues[props.dynamicUi.id];
        return (
          <>
            <Controller
              // @ts-ignore
              as={<GreenCheckbox disabled={props.readOnly} />}
              rules={props.dynamicUi.rules}
              name={props.dynamicUi.id}
              control={props.form.control}
              defaultValue={!!existingValue}
              style={{ margin: '1rem 0 1rem 0' }}
            />
            <GcvContent
              type="l1"
              content={props.dynamicUi.label}
              style={{ display: 'inline-block', marginLeft: '1rem' }}
            />
          </>
        );
      })();
    case 'Dropdown':
      return (
        <div style={{ margin: '1rem 0 1rem 0' }}>
          <GcvInputSelect
            {...props.form}
            label={props.dynamicUi.label}
            name={props.dynamicUi.id}
            key={props.dynamicUi.id}
            options={props.dynamicUi.items}
            rules={props.dynamicUi.rules}
            readonly={props.readOnly}
            defaultValue={props.defaultFormValues[props.dynamicUi.id]}
          />
        </div>
      );
    case 'Form':
      return (
        <>
          <br />
          <GcvContent type="h3" content={props.dynamicUi.title} />
          <br />
          {props.dynamicUi.components.map(component => {
            return (
              <UiGenerator
                form={props.form}
                register={props.register}
                dynamicUi={component}
                readOnly={props.readOnly}
                defaultFormValues={props.defaultFormValues}
                key={JSON.stringify(component)}
              />
            );
          })}
        </>
      );
    case 'Grid':
      return (
        <Grid container spacing={props.dynamicUi.spacing}>
          {props.dynamicUi.components.map(component => {
            return (
              <UiGenerator
                form={props.form}
                defaultFormValues={props.defaultFormValues}
                register={props.register}
                dynamicUi={component}
                readOnly={props.readOnly}
                key={JSON.stringify(component)}
              />
            );
          })}
        </Grid>
      );
    case 'GridItem':
      return (
        <Grid item xs={props.dynamicUi.size}>
          {props.dynamicUi.components.map(component => {
            return (
              <UiGenerator
                form={props.form}
                register={props.register}
                dynamicUi={component}
                readOnly={props.readOnly}
                key={JSON.stringify(component)}
                defaultFormValues={props.defaultFormValues}
              />
            );
          })}
        </Grid>
      );
    case 'Text':
      return <GcvContent type="p1" content={props.dynamicUi.content} style={{ margin: '1rem 0 1rem 0' }} />;
    case 'TextArea':
      return (
        <GcvInputTextArea
          {...props.form}
          register={props.register}
          name={props.dynamicUi.id}
          key={props.dynamicUi.id}
          label={props.dynamicUi.label}
          rules={props.dynamicUi.rules}
          defaultValue={props.defaultFormValues[props.dynamicUi.id]}
          readOnly={props.readOnly}
          style={{ margin: '1rem 0 1rem 0' }}
        />
      );
    case 'TextField':
      return (
        <div style={{ margin: '2rem 0 2rem 0' }}>
          <GcvInputForm
            {...props.form}
            name={props.dynamicUi.id}
            key={props.dynamicUi.id}
            label={props.dynamicUi.label}
            rules={props.dynamicUi.rules}
            defaultValue={props.defaultFormValues[props.dynamicUi.id]}
            readonly={props.readOnly}
            style={{ margin: '1rem 0 1rem 0' }}
          />
        </div>
      );
    default:
      return <></>;
  }
};

const ListNav = (props: {
  activeLinkName: string | number;
  clickable: boolean;
  navLinks: NavLinks[];
  handleSubmit: any;
  onNavigate: (activeLinkName: string | number, data) => void;
}) => {
  return (
    <List>
      {props.navLinks
        ? props.navLinks.map((n, i) => {
            return (
              <ListNavItem
                key={n.name}
                name={n.name}
                text={n.text}
                index={i}
                activeLinkName={props.activeLinkName}
                isComplete={n.isComplete}
                onClick={props.onNavigate}
                clickable={props.clickable}
                handleSubmit={props.handleSubmit}
              />
            );
          })
        : null}
    </List>
  );
};

const ListNavItem = (props: {
  name: string | number;
  text: string;
  activeLinkName: string | number;
  isComplete: boolean;
  clickable: boolean;
  handleSubmit: any;
  index: number;
  onClick: (activeLinkName: string | number, data?) => void;
}) => {
  const isActive = props.activeLinkName === props.name;

  return (
    <ListItem
      style={{
        color: props.isComplete || isActive ? '#00BC66' : '#A5A8BA',
        textDecoration: isActive ? 'underline' : '',
        textDecorationColor: '#00BC66',
        cursor: props.clickable ? 'pointer' : null,
        paddingBottom: '12px',
      }}
      onClick={() => (props.clickable ? props.onClick(props.name) : null)}
    >
      {props.index + 1}. {props.text}
    </ListItem>
  );
};
