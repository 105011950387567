import React from 'react';
import { formatDateOrEmpty, formatMissingDash } from '../../../util/formatHelpers';
import { GcvUserInputContainer } from '../../../lib/GcvUserInputContainer/GcvUserInputContainer';
import { $primary, $blue } from '../../../util/styleVariables';
import { DueDiligenceRequirementStatus } from '@gcv/shared';

export const type = name => ({
  name: name,
  selector: 'type',
  sortable: true,
  grow: 1,
});

export const lastUpdated = name => ({
  name: name,
  selector: 'lastUpdated',
  sortable: true,
  grow: 1,
  format: row => formatMissingDash(formatDateOrEmpty(row.lastUpdated)),
});

export const requirement = (name, clickHandler) => ({
  name: name,
  selector: 'requirementName',
  sortable: true,
  grow: 3,
  format: row => {
    const onClick = () => clickHandler(row);
    return (
      <div>
        <GcvUserInputContainer string={row.requirementName} length={50} clickHandler={onClick} type="document" />
      </div>
    );
  },
});

export const status = (name, clickHandler) => ({
  name: name,
  selector: 'status',
  sortable: true,
  grow: 1,
  format: row => {
    const onClick = () => clickHandler(row);
    return (
      <div
        style={{ color: row.status === DueDiligenceRequirementStatus.REVIEWED ? $primary : $blue }}
        onClick={onClick}
      >
        {row.status === DueDiligenceRequirementStatus.REVIEWED ? 'Reviewed' : 'Needs Review'}
      </div>
    );
  },
});
