import { User } from '@gcv/shared';

export function isInitialDispensaryUserAndStillNeedsToSignTermsOfService(user: User): boolean {
  return (
    user.companies[0].companyType === 'dispensary' &&
    user.initial_user &&
    !hasInitialDispensaryUserSignedTermsOfService(user)
  );
}

function hasInitialDispensaryUserSignedTermsOfService(user: User): boolean {
  return user.termsOfService;
}
