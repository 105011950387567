import React, { useContext } from 'react';
import parse from 'html-react-parser';

import { DispensaryAccountReviewWithData, PeerGroupPeriodSummary, PeriodSummary } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { BulletConfig } from './chart-options';
import { createStyles, makeStyles, Popover, Theme } from '@material-ui/core';
import { tooltip } from './Tooltip';
import { formatMoney } from 'libs/react-ui/src/util';
import { AccountMonitoringContext } from '../../account-monitoring-context';

interface Props {
  review: DispensaryAccountReviewWithData;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(0.25),
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 10%)',
      border: '1px solid #E0E5F0',
      borderRadius: 0,
    },
  })
);

export const SalesActivityOrderSizeBulletChart: React.FC<Props> = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const context = useContext(AccountMonitoringContext);

  return (
    <>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {parse(
          tooltip('Order Size Average ($)', [
            {
              label: 'This Period',
              value: formatMoney(props.review.data.aggregated_sales_data.current_period.avg_sale_size),
            },
            {
              label: 'Previous Period',
              value: formatMoney(props.review.data.aggregated_sales_data.previous_period.avg_sale_size),
            },
            {
              label: 'Previous Year',
              value: formatMoney(props.review.data.aggregated_sales_data.previous_year.avg_sale_size),
            },
            {
              label: 'Peer Group Avg',
              value: formatMoney(
                props.review.data.aggregated_sales_data.peer_groups[context.peerGroupId].avg_sale_size
              ),
            },
          ])
        )}
      </Popover>

      <div onMouseEnter={e => handlePopoverOpen(e)} onMouseLeave={handlePopoverClose}>
        <GcvChart type="bullet" height={65} config={chartDataAdapter(props.review, context.peerGroupId)} />
      </div>
    </>
  );
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData, peerGroupId: string) => {
  return dataAdapter(review.data.aggregated_sales_data, peerGroupId);
};

export const dataAdapter = (
  data: {
    current_period: PeriodSummary;
    previous_period: PeriodSummary;
    previous_year: PeriodSummary;
    peer_groups: {
      [id: string]: PeerGroupPeriodSummary;
    };
  },
  peerGroupId: string
): BulletConfig => {
  const prevPeriod = data.previous_period.avg_sale_size / 100;
  const currPeriod = data.current_period.avg_sale_size / 100;
  const value = data.previous_year.avg_sale_size / 100;
  const peerGroup = data.peer_groups[peerGroupId];
  const target = peerGroup.avg_sale_size / 100;

  const max = Math.max(prevPeriod, currPeriod, value, target);

  return {
    colorRange: {
      color: [
        {
          minValue: 0,
          // sales previous period
          maxValue: prevPeriod,
          code: '#1270FC',
          alpha: 60,
        },
        {
          minValue: 0,
          // sales this period
          maxValue: currPeriod,
          code: '#00BC66',
          alpha: 60,
        },
        {
          minValue: 0,
          // fill space to max
          maxValue: max,
          code: '#000000',
          alpha: 15,
        },
      ],
    },
    // previous year avg
    value: value,
    // peer group avg
    target: target,
  };
};
