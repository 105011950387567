import { ReactWrapperComponent, WrapperProps } from '../../util';
import { ProfileDropdown } from './ProfileDropdown';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-profile-dropdown',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleClick"></ app-react-wrapper>',
})
export class ProfileDropdownComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Output() clickEmitter = new EventEmitter<any>();

  component = ProfileDropdown;

  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(payload) {
    this.clickEmitter.emit(payload);
  }
}
