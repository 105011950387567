import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';
import { ViewMoreIcon } from '../../../icons/ViewMoreIcon';

import { DrawerText, DrawerImage, DrawerList } from '../styles';
import { VerticalCenter } from '../../SalesAndDeposits/Deposits/depositDetailDrawer/styles';

interface Props {
  form: any;
}

export const GreenBitsForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To share your API token with Green Check, you will need to set up a new Role and User account in Greenbits that
        Green Check will use to access the token.
      </DrawerText>
      <DrawerText>
        <a
          target="_blank"
          href="https://support.greencheckverified.com/en/knowledge/connect-greenbits-to-green-check"
          style={{ display: 'flex' }}
        >
          <VerticalCenter>Step-by-step instructions</VerticalCenter>
          <VerticalCenter style={{ marginLeft: '5px' }}>
            <ViewMoreIcon></ViewMoreIcon>
          </VerticalCenter>
        </a>
      </DrawerText>
      <DrawerText style={{ marginBottom: '13px' }}>
        Make sure that the new Role has the following permissions:{' '}
      </DrawerText>
      <DrawerList>View Products</DrawerList>
      <DrawerList>Access Backoffice</DrawerList>
      <DrawerList>View product types</DrawerList>
      <DrawerList>View brands</DrawerList>
      <DrawerList>View strains</DrawerList>
      <DrawerList>View strains</DrawerList>
      <DrawerList>View suppliers</DrawerList>
      <DrawerText style={{ marginTop: '36px' }}>
        After you create the Green Check User account with the new Role, provide the login credentials below. The
        integration may take 2-3 days.
      </DrawerText>
      <GcvInputForm
        {...form}
        name="username"
        label="Email"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="password"
        label="Password"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
    </>
  );
};
