<div class="wrapper">
  <div (click)="toggle()" class="header" data-cy="collapsing-shelf.component">
    <span>{{ title }}</span>
    <div class="hr"></div>
    <svg *ngIf="!toggled" height="8" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 .3c.4-.4 1.2-.4 1.6 0 .5.4.5 1 0 1.5L7.5 8 .4 1.8A1 1 0 0 1 .4.3C.8 0 1.6 0 2 .3L7.5 5 12.9.3z"
        fill="#A5A8BA"
        fill-rule="nonzero"
      />
    </svg>
    <svg *ngIf="toggled" height="8" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6 7.2A1 1 0 1 0 12 5.8L6 0 .3 5.8a1 1 0 1 0 1.4 1.4l4.4-4.4 4.5 4.4z"
        fill="#A5A8BA"
        fill-rule="nonzero"
      />
    </svg>
  </div>
  <div [ngClass]="toggled ? 'wrapperOpened' : 'wrapperClosed'">
    <ng-content select="[shelf-content]"></ng-content>
  </div>
</div>
