import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BankState } from './bank.reducer';
import { Observable } from 'rxjs';
import { selectAllBank, selectBankById, selectBankEntities, selectBankState } from './bank.selectors';
import { Bank, Group } from '@gcv/shared';
import { Dictionary } from '@ngrx/entity';
import {
  AddDispensaryToBank,
  CreateBank,
  LoadAllBanks,
  LoadBank,
  LoadBankPartial,
  LoadGroups,
  ResetBank,
  UpdateBank,
  UpdateBankGCVDueDiligenceStatus,
  UpdateGroups,
  RemoveDispensaryFromBank,
  UpdateBankOrganizationalDetails,
  UpdateBankRegulatoryReporting,
  UpdateBankCannabisProgram,
  UpdateBankTransportationOptOut,
  UpdateBankDueDiligenceUsersSection,
  InviteBankUser,
  UpsertBankDueDiligenceTemplateRequirement,
  UpdateBankDueDiligenceSectionComplete,
  DeleteBankDueDiligenceTemplateRequirement,
  BankUpdated,
} from './bank.actions';
import { map } from 'rxjs/operators';

export interface IBankFacade {
  getBankById(bankId: string): void;

  postBank(bank: Partial<Bank>): void;

  putBank(bank: Bank): void;

  getBankPartial(dispensaryId: string): void;

  getAllBanks(): void;

  getBankGroups(orgId: string): void;

  putBankGroups(bankId: string, groups: Group[]): void;

  putAddDispensaryToBank(bankId: string, dispensaryId: string): void;

  resetBankState(): void;

  selectBankById(bankId: string): any;

  selectAllBanks(): any;

  selectCurrentBank(): any;

  selectBankPartial(): any;

  selectBankDictionary(): any;

  putUpdateBankGCVDueDiligenceStatus(bankId: string, startedDate?: string): void;
}

@Injectable({
  providedIn: 'root',
})
export class BankFacade implements IBankFacade {
  banks$: Observable<Dictionary<Bank>>;

  constructor(private store: Store<BankState>) {
    this.banks$ = store.pipe(select(selectBankEntities));
  }

  getBankById(bankId: string) {
    this.store.dispatch(new LoadBank({ bankId }));
  }

  postBank(bank: Partial<Bank>) {
    this.store.dispatch(new CreateBank(bank));
  }

  putBank(bank: Bank) {
    this.store.dispatch(new UpdateBank(bank));
  }

  getBankPartial(dispensaryId: string) {
    this.store.dispatch(new LoadBankPartial({ dispensaryId }));
  }

  getAllBanks() {
    this.store.dispatch(new LoadAllBanks());
  }

  getBankGroups(orgId: string) {
    this.store.dispatch(new LoadGroups({ orgId }));
  }

  putBankGroups(bankId: string, groups: Group[]) {
    this.store.dispatch(new UpdateGroups({ bankId, groups }));
  }

  putAddDispensaryToBank(bankId: string, dispensaryId: string) {
    this.store.dispatch(new AddDispensaryToBank({ dispensaryId, bankId }));
  }

  removeDispensaryFromBank(bankId: string, dispensaryId: string) {
    this.store.dispatch(new RemoveDispensaryFromBank({ dispensaryId, bankId }));
  }

  putUpdateBankGCVDueDiligenceStatus(bankId: string) {
    this.store.dispatch(new UpdateBankGCVDueDiligenceStatus({ bankId }));
  }

  updateBankOrganizationalDetails(bankId: string, organizationalDetails: any) {
    this.store.dispatch(new UpdateBankOrganizationalDetails({ bankId, organizationalDetails }));
  }

  updateBankCannabisProgram(bankId: string, cannabisProgram: any) {
    this.store.dispatch(new UpdateBankCannabisProgram({ bankId, cannabisProgram }));
  }

  updateBankDueDiligenceSectionComplete(bankId: string, due_diligence_section_complete: boolean) {
    this.store.dispatch(new UpdateBankDueDiligenceSectionComplete({ bankId, due_diligence_section_complete }));
  }

  updateBankRegulatoryReporting(bankId: string, regulatoryReporting: any) {
    this.store.dispatch(new UpdateBankRegulatoryReporting({ bankId, regulatoryReporting }));
  }

  updateBankTransportOptOut(bankId: string, transport_opt_out: boolean) {
    this.store.dispatch(new UpdateBankTransportationOptOut({ bankId, transport_opt_out }));
  }

  updateBankDueDiligenceUsersSection(bankId: string, users_opt_out: boolean, users_section_complete: boolean) {
    this.store.dispatch(new UpdateBankDueDiligenceUsersSection({ bankId, users_opt_out, users_section_complete }));
  }

  upsertBankDueDiligenceTemplateRequirement(
    bankId: string,
    requirement: any,
    category: string,
    attachments: { id: string; file_name: string }[]
  ) {
    this.store.dispatch(new UpsertBankDueDiligenceTemplateRequirement({ bankId, requirement, category, attachments }));
  }

  deleteBankDueDiligenceTemplateRequirement(bankId: string, requirementId: string) {
    this.store.dispatch(new DeleteBankDueDiligenceTemplateRequirement({ bankId, requirementId }));
  }

  inviteBankUser(bankId: string, user: any) {
    this.store.dispatch(new InviteBankUser({ bankId, user }));
  }

  resetBankState() {
    this.store.dispatch(new ResetBank());
  }

  selectBankById(bankId: string) {
    return this.store.pipe(select(selectBankById, { bankId }));
  }

  selectAllBanks() {
    return this.store.pipe(select(selectAllBank));
  }

  selectCurrentBank() {
    return this.store.pipe(
      select(selectAllBank),
      map((banks: Bank[]) => banks[0])
    );
  }

  selectBankPartial() {
    return this.store.pipe(
      select(selectBankState),
      map((state: BankState) => state.partialBank)
    );
  }
  selectBankDictionary() {
    return this.store.pipe(select(selectBankEntities));
  }

  updateBank(bank: any) {
    this.store.dispatch(new BankUpdated(bank));
  }
}
