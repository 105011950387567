<div *ngIf="data.type === 'vendor' || data.type === 'bank'" class="container">
  <h1>Service Provider Details</h1>
  <h2>{{ data.name }}</h2>
  <div mat-dialog-content>
    <div class="form-container address">
      <p class="label address">Address</p>
      <p class="data address">
        {{ data.street }}<br />
        {{ data.city | titlecase }}, {{ data.state }} {{ data.zipCode }}<br />
      </p>
    </div>
    <div class="form-container phone">
      <p class="label phone">Phone</p>
      <p class="data phone">{{ data.phone }}</p>
    </div>
    <div class="form-container tin">
      <p class="label tin">TIN</p>
      <p class="data tin">{{ data.tin }}</p>
    </div>
  </div>
  <div mat-dialog-actions>
    <gcv-button (clickEvent)="handleClose()" buttonText="Close" type="tertiary"></gcv-button>
  </div>
</div>

<div *ngIf="data.type === 'user'" class="container">
  <h1>Service Provider Details</h1>
  <h2>{{ data.firstName }} {{ data.lastName }}</h2>
  <div mat-dialog-content>
    <div class="form-container address">
      <p class="label address">Address</p>
      <p class="data address">
        {{ data.address }}<br />
        {{ data.city | titlecase }}, {{ data.state }} {{ data.zipcode }}<br />
      </p>
    </div>
    <div class="form-container phone">
      <p class="label phone">Phone</p>
      <p class="data phone">{{ data.phone }}</p>
    </div>
    <div class="form-container tin">
      <p class="label tin">Email</p>
      <p class="data tin">{{ data.email }}</p>
    </div>
  </div>
  <div mat-dialog-actions>
    <gcv-button (clickEvent)="handleClose()" buttonText="Close" type="tertiary"></gcv-button>
  </div>
</div>
