import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { Bank, Dispensary, User } from '@gcv/shared';
import { BankFacade, UserFacade, DispensaryFacade, StaffFacade } from '@user-interface/gcv-state';
import { ReactWrapperComponent, WrapperProps, AccountMonitoringRouter } from '@user-interface/react-ui';

@Component({
  selector: 'fi-account-monitoring',
  template: '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props"></app-react-wrapper>',
})
export class AccountMonitoringComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;

  public dispensaries: { [id: string]: Dispensary };
  public bank: Bank;
  public user: User;
  public bankUsers: { [id: string]: User };
  component = AccountMonitoringRouter;

  constructor(
    private bankFacade: BankFacade,
    private userFacade: UserFacade,
    private dispFacade: DispensaryFacade,
    private staffFacade: StaffFacade
  ) {
    super();
  }

  ngOnInit() {
    this.setUpStreams();
    this.updateProps();
  }

  setUpStreams() {
    this.bankFacade
      .selectCurrentBank()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(b => {
        this.bank = b;
      });

    this.userFacade
      .selectCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.user = user;
        this.updateProps();
      });

    this.dispFacade
      .selectDispensaryDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(disps => {
        this.dispensaries = disps;
        this.updateProps();
      });

    this.staffFacade
      .selectStaffDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(users => {
        this.bankUsers = users;
        this.updateProps();
      });
  }

  updateProps() {
    this.props = {
      ...this.props,
      bank: this.bank,
      dispensaries: this.dispensaries,
      user: this.user,
      bankUsers: this.bankUsers,
    };
  }
}
