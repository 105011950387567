import React, { useState, useEffect } from 'react';

import {
  GcvDataTable,
  GcvButton,
  GcvTimePeriodDropdown,
  GcvSearchDropDown,
  GcvZeroState,
  GcvInputSelectNew,
} from '../../lib';
import { columns } from './ReportColumns';
import {
  Deposit,
  FilterRequest,
  FilterOptions,
  SearchAccountsFilter,
  MethodOfTransportFilter,
  StatusFilter,
  DateTimeFilter,
  useFilter,
} from './ReportFilters';
import { DepositDetailDrawer } from './DepositDetailDrawer';
import { Dictionary } from '@ngrx/entity';
import { Dispensary, IANATimezones, User } from '@gcv/shared';
import useQueryString from '../../hooks/useQueryString';

interface Props {
  deposits: Deposit[];
  bankId: string;
  dispensaries: Dictionary<Dispensary>;
  userMap: Dictionary<User>;
  viewStatus: string;
  emitData: any;
}

interface Option {
  value: string;
  label: string;
}

export const DepositsReport: React.FC<Props> = props => {
  const [initialFilterCompleted, setInitialFilterCompleted] = useState(false);
  const [deposits, setDeposits] = useState([]);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const [loadingDeposits, setLoadingDeposits] = useState([]);
  const [timeSelectType, setTimeSelectType] = useState<string>('created');
  const [timeSelect, setTimeSelect] = useState<string>('all');
  const [selectedDepositId, setSelectedDepositId] = useQueryString('id', undefined);
  const [distinctMots, setDistinctMots] = useState<Option[]>([{ value: 'All', label: 'All' }]);
  const [distinctAccounts, setDistinctAccounts] = useState<Option[]>([]);
  const [filterRequest, setFilterRequest] = useState<FilterRequest<Deposit>>({
    data: props.deposits,
    searchTerms: {},
  });

  const filterReport = useFilter(filterRequest, setFilterRequest, props.deposits);

  // initial populate deposits when props are ready
  useEffect(() => {
    setDeposits(props.deposits);
    if (props.deposits.length && !initialFilterCompleted) {
      filterReport({ value: 'Pending' }, FilterOptions.Status);
      setInitialFilterCompleted(true);
    }

    // find the distinct methods of transportation
    // for use in the filter drop down list
    const mots = findDistinctMots();
    setDistinctMots(mots);

    // find the distinct accounts
    // for use in the account search drop down list
    const accounts = findDistinctAccounts();
    setDistinctAccounts(accounts);

    loadingDeposits.forEach(loadingDeposit => {
      const updatedDeposit = props.deposits.find(d => d.id === loadingDeposit);
      if (updatedDeposit && updatedDeposit.status === 'pending') {
        updatedDeposit.reconciling = true;
      } else if (updatedDeposit && updatedDeposit.status === 'reconciled') {
        setLoadingDeposits(loadingDeposits.filter(d => d != updatedDeposit.id));
      }
    });
  }, [props.deposits]);

  // watch for state changes in a report filter request
  useEffect(() => {
    handleSearchFilters();
  }, [filterRequest]);

  const clearSelectedDepositId = () => {
    setSelectedDepositId('');
  };

  const handleRowClick = rowData => {
    setSelectedDepositId(rowData.deposit_id);
  };

  const handleSearchFilters = () => {
    const searchFilter = new SearchAccountsFilter(setFilteredDeposits);
    const motFilter = new MethodOfTransportFilter(setFilteredDeposits);
    const statusFilter = new StatusFilter(setFilteredDeposits);
    const dateTimeFilter = new DateTimeFilter(setFilteredDeposits, IANATimezones.America_NewYork); //component is not used anymore, using dummy timezone

    searchFilter.Next(motFilter);
    motFilter.Next(statusFilter);
    statusFilter.Next(dateTimeFilter);

    searchFilter.Handle(filterRequest);
  };

  const handleFilterAccounts = results => {
    filterReport({ value: results }, FilterOptions.SearchAccounts);
  };

  const findDistinctMots = (): Option[] => {
    const uniqueMots = [];
    const mots = [{ value: 'All', label: 'All' }];

    for (let i = 0; i < props.deposits.length; i++) {
      if (props.deposits[i].methodOfTransport && !uniqueMots[props.deposits[i].methodOfTransport]) {
        mots.push({ value: props.deposits[i].methodOfTransport, label: props.deposits[i].methodOfTransport });
        uniqueMots[props.deposits[i].methodOfTransport] = 1;
      }
    }

    return mots;
  };

  const findDistinctAccounts = (): Option[] => {
    const uniqueAccounts = [];
    const accounts = [];

    for (let i = 0; i < props.deposits.length; i++) {
      if (props.deposits[i].account && !uniqueAccounts[props.deposits[i].account]) {
        accounts.push({ value: props.deposits[i].dispensary_id, label: props.deposits[i].account });
        uniqueAccounts[props.deposits[i].account] = 1;
      }
    }

    return accounts;
  };

  const handleTimeTypeChange = e => {
    setTimeSelectType(e.value);
    filterReport({ value: timeSelect, timeSelectType: e.value }, FilterOptions.DateTimeFilter);
  };

  const handleTimeChange = e => {
    setTimeSelect(e.value);
    filterReport({ value: e.value, timeSelectType }, FilterOptions.DateTimeFilter);
  };

  const reconcileDeposit = (deposit, bankId) => {
    props.deposits.find(d => d.id === deposit.deposit_id).reconciling = true;
    setLoadingDeposits([...loadingDeposits, deposit.deposit_id]);
    props.emitData({ type: 'acceptDeposit', deposit, bankId });
  };

  const selectedDeposit = deposits.find(d => d.deposit_id === selectedDepositId);

  const statusOptions: Option[] = [
    { value: 'All', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Reconciled', label: 'Reconciled' },
  ];

  const filterOptions: Option[] = [
    { value: 'created', label: 'Created' },
    { value: 'plannedArrivalDate', label: 'Planned Arrival' },
    { value: 'arrived_date', label: 'Arrived' },
  ];

  return (
    <>
      <h1 style={{ marginBottom: 0 }}>Deposits</h1>
      {props.viewStatus !== 'DefaultState' ? (
        <>
          <GcvZeroState
            headerText="You currently have no deposits to view"
            dashboardSubText="To begin accepting applications from cannabis businesses please finish setting up your company profile."
            type="basic"
            button={
              <GcvButton
                secondary={props.viewStatus === 'DueDiligenceInProgressState'}
                primary={props.viewStatus === 'DueDiligenceZeroState'}
                onClick={() => props.emitData({ type: 'ddButtonClicked' })}
              >
                {props.viewStatus === 'DueDiligenceZeroState' ? "Let's Go" : 'Continue'}
              </GcvButton>
            }
          ></GcvZeroState>
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              margin: '2rem 0 1rem 0',
              width: '100%',
            }}
          >
            <GcvSearchDropDown
              distinctItems={distinctAccounts}
              searchText={'Search Accounts'}
              itemLabel={'Account(s)'}
              filterReport={handleFilterAccounts}
            />
            <GcvTimePeriodDropdown
              selectableLabel={true}
              emitData={handleTimeChange}
              newStyle={true}
              labelOptions={filterOptions}
              onLabelChange={handleTimeTypeChange}
              includeAllOption={true}
              defaultValueDrop={'all'}
            />
            <GcvInputSelectNew
              label="MOT"
              options={distinctMots}
              onChange={e => filterReport({ value: e.value }, FilterOptions.MethodOfTransport)}
              dropWidth={'175px'}
              labelWidth={'100px'}
              dropCharLength={20}
            />
            <GcvInputSelectNew
              label="Status"
              options={statusOptions}
              onChange={e => filterReport({ value: e.value }, FilterOptions.Status)}
              dropWidth={'175px'}
              labelWidth={'100px'}
              dropCharLength={20}
              defaultValueDrop={'Pending'}
            />
          </div>
          <div>
            <GcvDataTable
              data={filteredDeposits}
              columns={columns(handleRowClick)}
              onRowClicked={handleRowClick}
              keyField="react_key"
              defaultSortField="plannedArrivalDate"
              defaultSortAsc={false}
              subHeaderAlign="left"
              noDataComponent={
                <GcvZeroState
                  type="bankDash"
                  headerText={'There are no deposits to show with the selected filters.'}
                  subText={'Please try changing your filters to show more records.'}
                />
              }
            />
          </div>

          <DepositDetailDrawer
            deposit={selectedDeposit}
            open={selectedDepositId && selectedDeposit}
            onClose={clearSelectedDepositId}
            emitData={reconcileDeposit}
            bankId={props.bankId}
            userMap={props.userMap}
            type={'bank'}
            dispensaryMap={props.dispensaries}
          />
        </>
      )}
    </>
  );
};
