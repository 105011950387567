import React from 'react';

export const BlackXIcon = ({ height = 17, width = 17, style = {}, onClick }) => (
  <svg
    onClick={onClick}
    fill="none"
    height={height}
    viewBox="0 0 17 17"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    data-cy="black-x-icon"
  >
    <circle cx="8.5" cy="8.5" fill="#F0F2F9" r="8" stroke="#DBE1ED" />
    <path d="M6 6L10.7472 10.7472" stroke="#9B9EB1" strokeLinecap="round" />
    <path d="M6.00098 10.7471L10.7482 5.99984" stroke="#9B9EB1" strokeLinecap="round" />
  </svg>
);
