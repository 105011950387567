import { StoreModule, StoreRootModule } from '@ngrx/store';
import { ModuleWithProviders } from '@angular/core';

const DEFAULT_RUNTIME_CHECKS = {
  runtimeChecks: {
    strictStateImmutability: false,
    strictActionImmutability: false,
    strictStateSerializability: false,
    strictActionSerializability: false,
  },
};

const SUPER_STRICT_RUNTIME_CHECKS = {
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictStateSerializability: true,
    strictActionSerializability: true,
  },
};

export class NgRxUtil {
  public static getStoreModuleForRootWithDefaultNgRxRuntimeChecks(): ModuleWithProviders<StoreRootModule> {
    return StoreModule.forRoot(
      {},
      {
        ...DEFAULT_RUNTIME_CHECKS,
      }
    );
  }
}
