import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const reportingClient = (API: APIClass) => {
  return {
    getFiWeeklyTotals: async (apiKey: string, endDate: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/fi-weekly-totals/${apiKey}/${endDate}`, setIsLoading);
    },

    getSalesTotals: async (
      apiKey: string,
      startDate: string,
      endDate: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).get(`/reporting/sales-totals/${apiKey}/${startDate}/${endDate}`, setIsLoading);
    },

    getSalesTotalsToday: async (apiKey: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/sales-totals-today/${apiKey}`, setIsLoading);
    },

    getOrganizations: async (apiKey: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/organizations/${apiKey}`, setIsLoading);
    },

    getOrganizationLicenses: async (apiKey: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/organizations/licenses/${apiKey}`, setIsLoading);
    },

    getCrbDebits: async (apiKey: string, endDate: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/crb-debits/${apiKey}/${endDate}`, setIsLoading);
    },

    getUsers: async (apiKey: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/users/${apiKey}`, setIsLoading);
    },

    getBillingReport: async (apiKey: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/billing/${apiKey}`, setIsLoading);
    },

    getValleyReport: async (apiKey: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/valley/${apiKey}`, setIsLoading);
    },

    getDeposits: async (apiKey: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/reporting/deposits-today/${apiKey}`, setIsLoading);
    },
  };
};
