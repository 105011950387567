import React from 'react';

interface Props {
  height?: number;
  width?: number;
  style?: any;
  onClick?(event: React.MouseEvent<HTMLOrSVGElement>): void;
}

export const DocumentIcon = ({ height = 77, width = 94, style, onClick }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 77 94"
    fill="none"
    version="1.1"
    style={style}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    data-cy="document-icon"
  >
    <path
      d="M63.22 92.6101H4.43001C4.1109 92.6101 3.79493 92.5473 3.50011 92.4251C3.20529 92.303 2.9374 92.124 2.71175 91.8984C2.48611 91.6727 2.30711 91.4049 2.18499 91.11C2.06287 90.8152 2.00002 90.4992 2.00002 90.1801V13.8101C1.9987 13.4902 2.06059 13.1731 2.18212 12.8771C2.30365 12.5812 2.48243 12.3121 2.70821 12.0854C2.93399 11.8587 3.20232 11.6788 3.49779 11.556C3.79327 11.4333 4.11006 11.3701 4.43001 11.3701H63.16C63.8266 11.3701 64.4661 11.6342 64.9384 12.1047C65.4107 12.5751 65.6774 13.2135 65.68 13.8801V90.1601C65.6827 90.4835 65.6208 90.8041 65.498 91.1032C65.3752 91.4023 65.194 91.6739 64.9649 91.9021C64.7358 92.1302 64.4634 92.3104 64.1638 92.432C63.8642 92.5536 63.5433 92.6141 63.22 92.6101Z"
      fill="#E3FFE6"
    />
    <path
      d="M63.22 92.6101H4.43001C4.1109 92.6101 3.79493 92.5473 3.50011 92.4251C3.20529 92.303 2.9374 92.124 2.71175 91.8984C2.48611 91.6727 2.30711 91.4049 2.18499 91.11C2.06287 90.8152 2.00002 90.4992 2.00002 90.1801V13.8101C1.9987 13.4902 2.06059 13.1731 2.18212 12.8771C2.30365 12.5812 2.48243 12.3121 2.70821 12.0854C2.93399 11.8587 3.20232 11.6788 3.49779 11.556C3.79327 11.4333 4.11006 11.3701 4.43001 11.3701H63.16C63.8266 11.3701 64.4661 11.6342 64.9384 12.1047C65.4107 12.5751 65.6774 13.2135 65.68 13.8801V90.1601C65.6827 90.4835 65.6208 90.8041 65.498 91.1032C65.3752 91.4023 65.194 91.6739 64.9649 91.9021C64.7358 92.1302 64.4634 92.3104 64.1638 92.432C63.8642 92.5536 63.5433 92.6141 63.22 92.6101Z"
      stroke="#00BC66"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1602 4.46V80.75C11.1602 81.4007 11.418 82.0249 11.8771 82.4859C12.3363 82.947 12.9595 83.2073 13.6102 83.21H72.3701C73.0226 83.21 73.6483 82.9508 74.1096 82.4895C74.571 82.0281 74.8301 81.4024 74.8301 80.75V22.31H56.9802C56.6537 22.31 56.3305 22.2451 56.0294 22.119C55.7283 21.9929 55.4553 21.8081 55.2264 21.5754C54.9974 21.3427 54.817 21.0668 54.6958 20.7637C54.5746 20.4606 54.5148 20.1364 54.5201 19.81V2H13.6102C12.9595 2.00265 12.3363 2.26299 11.8771 2.72404C11.418 3.1851 11.1602 3.8093 11.1602 4.46Z"
      fill="white"
    />
    <path
      d="M56.03 5.81006V23.6901C56.0287 24.0139 56.0914 24.3349 56.2144 24.6345C56.3375 24.9341 56.5185 25.2065 56.747 25.436C56.9756 25.6655 57.2472 25.8476 57.5463 25.9718C57.8455 26.0961 58.1661 26.1601 58.49 26.1601H74.83V22.3101L56.03 5.81006Z"
      fill="#9AF4AD"
    />
    <path
      d="M72.4001 83.21H13.5901C12.9457 83.21 12.3276 82.954 11.8719 82.4983C11.4162 82.0425 11.1602 81.4245 11.1602 80.78V4.43C11.1602 3.78552 11.4162 3.16744 11.8719 2.71173C12.3276 2.25602 12.9457 2 13.5901 2H54.5201L74.8301 22.31V80.81C74.8222 81.4493 74.5628 82.0597 74.1079 82.5089C73.653 82.9582 73.0395 83.21 72.4001 83.21V83.21Z"
      stroke="#00BC66"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.52 2V19.87C54.5227 20.5243 54.7838 21.151 55.2464 21.6136C55.709 22.0763 56.3357 22.3374 56.99 22.34H74.83L54.52 2Z"
      fill="white"
    />
    <path
      d="M54.52 2V19.87C54.5227 20.5243 54.7838 21.151 55.2464 21.6136C55.709 22.0763 56.3357 22.3374 56.99 22.34H74.83L54.52 2Z"
      stroke="#00BC66"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M58.4001 31.46H27.5901" stroke="#00BC66" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M58.4001 40.52H27.5901" stroke="#00BC66" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M58.4001 49.5703H27.5901"
      stroke="#00BC66"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.8101 58.6299H27.5901"
      stroke="#00BC66"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.8301 61.2202V63.3802"
      stroke="#00BC66"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M46.8201 83.21H48.9001" stroke="#00BC66" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M42.99 92.6104H45.09" stroke="#00BC66" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
