import React from 'react';
import { RouteObject } from '../../util/types';
import { DailySummaryDetail } from './Daily-Summary-Detail.page';
import { Dashboard } from './Dashboard.page';

export const routes: RouteObject[] = [
  {
    path: '/secure/bank/dashboard/daily-summaries/detail/:dispensaryId/:date',
    render: props => <DailySummaryDetail {...props} />,
  },
  {
    path: '/secure/bank/dashboard/:section',
    render: props => <Dashboard {...props} />,
  },
];
