import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gcv-onboarding-preview-wrapper',
  templateUrl: './onboarding-preview-wrapper.component.html',
  styleUrls: ['./onboarding-preview-wrapper.component.scss'],
})
export class OnboardingPreviewWrapperComponent implements OnInit {
  @Input() requirements: { name: string }[];
  @Input() section: string;
  @Input() details: string;
  @Input() dueDiligenceDocs = false;
  @Input() estimate: string;
  @Input() helpDescription: string;
  @Input() helpLink: string;
  @Input() showNeedHelpLinks = false;
  @Input() gcvRequirements?: any;
  @Output() helpClickEvent = new EventEmitter<void>();
  requirementKeys = [];
  constructor() {}

  ngOnInit() {
    if (this.gcvRequirements) {
      this.requirementKeys = Object.keys(this.gcvRequirements);
    }
  }

  helpHandler() {}
}
