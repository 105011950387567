import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { routes } from './routes';

export const ReportsRouter = props => {
  return (
    <Router>
      <Switch>
        {routes.map(({ path, render }) => {
          return <Route path={path} render={() => render(props)} key={path} />;
        })}
      </Switch>
    </Router>
  );
};
