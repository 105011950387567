import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from '../auth/route-guards/auth.service';
import { DefaultService } from '@gcv/generated-services';
import { UserFacade } from '@user-interface/gcv-state';
import { appRoutesNames, getDispensaryDashboardOverviewCommand } from '../app.routes.names';
import { setAppReady } from '../../cypress-tools';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent implements OnInit {
  private orgName: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private defaultService: DefaultService,
    private userFacade: UserFacade
  ) {}

  ngOnInit() {
    this.orgName = history.state.orgName;
    setAppReady();
  }

  getDisplayName(): string {
    return this.orgName;
  }

  accept() {
    this.defaultService
      .tosPut({
        orgName: this.orgName,
      })
      .pipe(take(1))
      .subscribe(
        agreedToTermsOfServiceEvent => {
          if (agreedToTermsOfServiceEvent) {
            const {
              data: { updatedUser },
            } = agreedToTermsOfServiceEvent;
            // update user in the store to reflect the tos acceptance status
            this.userFacade
              .updateUserOptimistic(updatedUser)
              .pipe(take(1))
              .subscribe(() => {
                this.router.navigate([getDispensaryDashboardOverviewCommand()]).then(r => r);
              });
          } else {
            this.routeToLogin();
          }
        },
        error => {
          console.error(error);
          this.logout();
        }
      );
  }

  private routeToLogin() {
    this.router.navigate([`/${appRoutesNames.LOGIN}`]).then(() => window.location.reload());
  }

  logout() {
    this.authService.logout();
  }
}
