import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';

import { ReactWrapperComponent, WrapperProps, DepositsRouter } from '@user-interface/react-ui';

import { Deposit, Dispensary, User, Notification, DepositFilter } from '@gcv/shared';
import { slideInAnimation } from '../../app/animations/router-animation';
import {
  DispensaryFacade,
  DepositsFacade,
  StaffFacade,
  BankFacade,
  NotificationsFacade,
} from '@user-interface/gcv-state';
import { Dictionary } from '@ngrx/entity';
import { UtilsService } from '@user-interface/gcv-ui';
import { take, takeUntil, tap } from 'rxjs/operators';
import { determineStatus, ViewStates } from '../../app/bank/bank.util';
import { BankAppFamilyService } from '../../services/bank.service';
import { DateTime } from 'luxon';
import calculateDateRange from 'libs/react-ui/src/util/calculateDateRange';

@Component({
  selector: 'bank-deposits',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></app-react-wrapper>',
  animations: [trigger('routeAnimations', [transition('active <=> pending', [useAnimation(slideInAnimation)])])],
})
export class BankDepositsComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = DepositsRouter;
  deposits: Deposit[];
  viewStatus: string;
  bank: any;

  constructor(
    private dispFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private depositsFacade: DepositsFacade,
    private staffFacade: StaffFacade,
    private notificationFacade: NotificationsFacade,
    private bankAppFamilyService: BankAppFamilyService,
    private utils: UtilsService
  ) {
    super();
    this.handleNotification = this.handleNotification.bind(this);
    this.acceptOrApproveDeposit = this.acceptOrApproveDeposit.bind(this);
    this.handleDueDiligenceButtonClicked = this.handleDueDiligenceButtonClicked.bind(this);
    this.handleEmitData = this.handleEmitData.bind(this);
  }

  ngOnInit() {
    this.setUpStreams();
  }

  setUpStreams() {
    let dispensaries: Dictionary<Dispensary>;
    let bankId = '';
    let userMap: Dictionary<User>;

    this.dispFacade
      .selectDispensaryDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(d => {
        dispensaries = d;
        this.props = { ...this.props, dispensaries, loadingDepositsTable: true };
      });

    this.staffFacade
      .selectStaffDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(staffMap => {
        userMap = staffMap;
        this.props = { ...this.props, userMap };
      });

    this.bankFacade
      .selectCurrentBank()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(bank => {
        this.staffFacade.getOrganizationStaffByOrgId(bank.id);
        this.bank = bank;
        bankId = bank.id;
        const range = calculateDateRange('last30Days', bank.iana_timezone);
        this.depositsFacade.getDepositsByBankId(bankId, range.start, range.end, DepositFilter.DateCreated);
        this.viewStatus = determineStatus(bank.due_diligence.due_diligence_status);
      });

    this.depositsFacade
      .selectAllDeposits()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(deps => {
        const deposits = deps.map(d => {
          return {
            id: d.deposit_id,
            deposit_id: d.deposit_id,
            dispensary_id: d.dispensary_id,
            created: d.date_created,
            account: d['dispensary_name'],
            amount: d.final_deposit,
            methodOfTransport: d.delMethod.name,
            plannedArrivalDate: d.expected_arrival_date,
            arrived_date: d.arrived_date,
            status: d.status,
            delMethod: d.delMethod,
            expected_arrival_date: d.expected_arrival_date,
            posted_date: d.posted_date,
            created_by: d.created_by,
            max_deposit: d.max_deposit,
            bank_comments: d.bank_comments,
            comments: d.comments,
            deposit_date: d.deposit_date,
            accepted_by_user: d.accepted_by_user,
            final_deposit: d.final_deposit,
            accepted_date: d.accepted_date,
            anticipated_deposit_amount: d.anticipated_deposit_amount,
            ...d,
          };
        });

        this.props = {
          ...this.props,
          deposits,
          dispensaries,
          bankId,
          userMap,
          viewStatus: this.viewStatus,
          timezone: this.bank.iana_timezone,
          loadingDepositsTable: !this.props.loadingDepositsTable,
        };
      });
  }

  markNotificationAsComplete(notification: Notification): Notification {
    return { ...notification, completed: true };
  }

  handleNotification(incompleteNotificaitons: Notification[], depositId: string) {
    const notificationForDeposit = incompleteNotificaitons.find(
      (notification: Notification) => notification.data.deposit_id === depositId
    );

    if (notificationForDeposit) {
      this.notificationFacade.completeNotification(this.markNotificationAsComplete(notificationForDeposit));
    }
  }

  acceptOrApproveDeposit(deposit) {
    this.depositsFacade.updateDeposit(deposit);

    this.notificationFacade
      .selectIncompleteNotifications()
      .pipe(
        take(1),
        tap((incompleteNotifications: Notification[]) => {
          this.handleNotification(incompleteNotifications, deposit.deposit_id);
        })
      )
      .subscribe();
  }

  handleDueDiligenceButtonClicked() {
    if (this.viewStatus === ViewStates.DueDiligenceZeroState) {
      this.bankFacade.putUpdateBankGCVDueDiligenceStatus(this.bank.id);
    }
    this.bankAppFamilyService.view.next({ view: '/secure/bank/onboarding' });
  }

  fetchDeposits(startDate: string, endDate: string, filter: DepositFilter) {
    this.depositsFacade.getDepositsByBankId(this.bank.id, startDate, endDate, filter);
  }

  handleEmitData(payload) {
    if (payload['type'] === 'acceptDeposit') {
      this.acceptOrApproveDeposit(payload.deposit);
    } else if (payload['type'] === 'updateDeposit') {
      this.depositsFacade.updateDeposit(payload.deposit);
    } else if (payload['type'] === 'ddButtonClicked') {
      this.handleDueDiligenceButtonClicked();
    } else if (payload['type'] === 'fetchDeposits') {
      this.fetchDeposits(payload['start'], payload['end'], payload['filter']);
    }
  }
}
