import styled from 'styled-components';
import { $grey1, $grey2, $primaryBorder, $black, $grey9, $grey4 } from '../../util';

export const TabContainer = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  background: #ffffff;
  border: 1px solid #ebeef7;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 45px;
  width: 60%;
  margin: 0 auto;

  h1 {
    margin-bottom: 30px;
  }
`;

export const PageFooter = styled.div`
  bottom: 6rem;
  display: flex;
  position: absolute;
  width: 70%;
  max-width: 1140px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const TransitionEffects = styled.div`
  width: 100%;

  .slide-enter {
    transition: transform 300ms ease-out;
  }

  .slide-enter {
    transform: translateX(-100%);
  }

  .slide-enter-active {
    transform: translateX(0%);
  }

  .slide-exit {
    display: none;
  }

  .slide-exit-active {
    display: none;
  }
`;

export const InviteAccountContainer = styled.div`
  margin-right: 2rem;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.25px;
  color: ${$grey1};
`;

export const LargeTitle = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-top: 1rem;
  color: ${$black};
`;

export const ReviewText = styled.div`
  margin-top: 1rem;
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: ${$grey1};
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 14rem;
`;

export const Progress = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
`;

export const LinkContainer = styled.div`
  margin: 0 1.5rem 0 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  font-family: Lato, Helvetica, Arial, san-serif;
`;

export const ToggleCardTableView = styled.div`
  display: flex;
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Table = styled.div`
  padding: 10px;
  border: 1px solid #a5a8ba;
  box-sizing: border-box;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardIconDivider = styled.div`
  margin: 0.653px auto;
  display: flex;
  flex-direction: row;
`;

export const CardIcon = styled.div`
  width: 4.76px;
  height: 4.76px;
  border-radius: 1px;
  margin-left: 1.36px;
  background-color: ${$grey9};
`;

export const TableIconLine = styled.div`
  width: 19px;
  height: 3px;
  border-radius: 3px;
  background-color: ${$grey9};
  margin: 1.5px auto;
`;

export const Cards = styled.div`
  margin-left: 10px;
  padding: 10px 10px;
  border: 1px solid #a5a8ba;
  box-sizing: border-box;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SearchInputContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  margin-top: -1rem;
`;

export const FormContent = styled.div`
  margin: 5px 0px;
`;

export const ModalContent = styled.div`
  padding: 0 2rem;
`;

export const ModalLabel = styled.div`
  font-size: 0.85rem;
  color: grey;
`;

export const List = styled.ul`
  display: inline-block;
  position: absolute;
  list-style-type: none;
  border: 1px solid #dbe0eb;
  background-color: #ffffff;
  border-top: none;
  max-height: 250px;
  width: 443px;
  margin-top: -1.4rem;
  padding: 0;
  overflow: hidden;
  z-index: 10000;
`;

export const ListItem = styled.li`
  padding: 0.5rem 1rem;

  &:hover {
    cursor: not-allowed;
  }
`;

export const CardContainer = styled.div`
  margin: 1.5rem;
  margin-left: 0;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55vh;
`;

export const Header = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.25px;
  margin: 0;
  margin-bottom: 1rem;
  color: ${$grey1};
`;
