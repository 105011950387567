import { DateTime } from 'luxon';
import { formatMoney, formatDateOrEmpty, formatStatusIndicator } from '../../../util/formatHelpers';

export const finalDeposit = name => ({
  name: name,
  selector: 'final_deposit',
  sortable: true,
  grow: 2,
  center: true,
  format: row => formatMoney(row.final_deposit),
});

export const dateCreated = name => ({
  name: name,
  selector: 'date_created',
  sortable: true,
  grow: 2,
  center: true,
  format: row => DateTime.fromISO(row.date_created).toLocaleString(DateTime.DATE_MED),
});

export const createdBy = (name, staff) => ({
  name: name,
  selector: row =>
    Object.keys(staff).length && staff[row.created_by]
      ? `${staff[row.created_by]['firstName']} ${staff[row.created_by]['lastName']}`
      : '',
  sortable: true,
  grow: 2,
  center: true,
});

export const expectedArrivalDate = name => ({
  name: name,
  selector: 'expected_arrival_date',
  sortable: true,
  grow: 2,
  center: true,
  format: row => formatDateOrEmpty(row.expected_arrival_date),
});

export const arrivalDate = name => ({
  name: name,
  selector: 'arrived_date',
  sortable: true,
  grow: 2,
  center: true,
  format: row => formatDateOrEmpty(row.arrived_date),
});

export const status = (name, clickHandler) => ({
  name: name,
  selector: 'status',
  sortable: true,
  grow: 2,
  center: true,
  format: row => formatStatusIndicator(row, clickHandler),
});

export const crbAccount = (name, dispensaries) => ({
  name: name,
  selector: row => dispensaries[row.dispensary_id]['name'],
  sortable: true,
  grow: 2,
  center: true,
});

export const methodOfTransportation = name => ({
  name: name,
  selector: row => row['delMethod']['name'],
  sortable: true,
  grow: 2,
  center: true,
});
