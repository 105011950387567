// fx
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

// app
import { Dispensary, DispensaryAccountReviewWithData } from '@gcv/shared';

import { Card } from './Card';
import { Container } from './Container';
import { Legend } from './Legend';
import { GcvLoading } from 'libs/react-ui/src/lib';

import { TransactionDataRadarChart } from './charts/TransactionDataRadarChart';
import { SalesActivitySalesBulletChart } from './charts/SalesActivitySalesBulletChart';
import { SalesActivityOrderSizeBulletChart } from './charts/SalesActivityOrderSizeBulletChart';
import { SalesActivityDailySalesDollarsBulletChart } from './charts/SalesActivityDailySalesDollarsBulletChart';
import { SalesActivityDailySalesCountBulletChart } from './charts/SalesActivityDailySalesCountBulletChart';
import { SalesActivityVerifiedBulletChart } from './charts/SalesActivityVerifiedBulletChart';
import { GcvContent } from 'libs/react-ui/src/lib/GcvContent/GcvContent';
import { formatMissingDash } from 'libs/react-ui/src/util';

interface Props {
  review: DispensaryAccountReviewWithData;
}

const ReviewOverview: React.FC<Props> = ({ review }: Props) => {
  return (
    <Container>
      <Container.Item size={[12, 12, 6, 6, 6]}>
        <Card height={355}>
          <Card.Header title="Account Information" />
          <Card.Content>
            <Container>
              <Container.Item>
                <GcvContent type="l1" grey2 content="Name" />
                <GcvContent type="p1" content={review.dispensary.name} />
              </Container.Item>

              <Container.Item>
                <GcvContent type="l1" grey2 content="EIN" />
                <GcvContent type="p1" content={formatMissingDash(review.dispensary.ein)} />
              </Container.Item>

              <Container.Item>
                <GcvContent type="l1" grey2 content="Account Opened" />
                <GcvContent
                  type="p1"
                  content={
                    review.dispensary_metadata?.value?.account_open_date
                      ? DateTime.fromISO(review.dispensary_metadata?.value?.account_open_date).isValid
                        ? DateTime.fromISO(review.dispensary_metadata?.value?.account_open_date).toLocaleString()
                        : review.dispensary_metadata?.value?.account_open_date
                      : DateTime.fromISO(review.dispensary.due_diligence.bank_reviewed_on).toLocaleString()
                  }
                />
              </Container.Item>

              <Container.Item>
                <GcvContent type="l1" grey2 content="Type" />
                <GcvContent
                  type="p1"
                  content={formatMissingDash(review.dispensary.business_type === 'wholesale' ? 'Wholesale' : 'Retail')}
                />
              </Container.Item>

              <Container.Item>
                <GcvContent type="l1" grey2 content="Status" />
                <GcvContent type="p1" content={'Active'} />
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>
      <Container.Item size={[12, 12, 6, 6, 6]}>
        <Card height={355}>
          <Card.Header
            title="Transaction Data"
            actions={[
              <Legend
                items={[
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#00BC66" />
                      </svg>
                    ),
                    label: 'This Period',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#6896FB" />
                      </svg>
                    ),
                    label: 'Previous Period',
                  },
                ]}
              />,
            ]}
          />
          <Card.Content>
            <TransactionDataRadarChart review={review} />
          </Card.Content>
        </Card>
      </Container.Item>

      <Container.Item>
        <Card>
          <Card.Header
            title="Sales Activity"
            actions={[
              <Legend
                inline
                items={[
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#00BC66" />
                      </svg>
                    ),
                    label: 'This Period',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#6896FB" />
                      </svg>
                    ),
                    label: 'Previous Period',
                  },
                  {
                    icon: (
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ borderRadius: '6px' }}
                      >
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#ffffff" stroke="#000000" />
                      </svg>
                    ),
                    label: 'Previous Year',
                  },
                  {
                    icon: (
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#3A3C45" />
                      </svg>
                    ),
                    label: 'Peer Group Avg (current period)',
                  },
                ]}
              />,
            ]}
          />
          <Card.Content>
            <Container>
              <Container.Item vCenter size={[2]}>
                <GcvContent type="p1" content="Sales" />
                <GcvContent type="l1" grey2 content="Total" />
              </Container.Item>
              <Container.Item size={[10]}>
                <SalesActivitySalesBulletChart review={review} />
              </Container.Item>

              <Container.Item vCenter size={[2]}>
                <GcvContent type="p1" content="Order Size" />
                <GcvContent type="l1" grey2 content="Dollar average" />
              </Container.Item>
              <Container.Item size={[10]}>
                <SalesActivityOrderSizeBulletChart review={review} />
              </Container.Item>

              <Container.Item vCenter size={[2]}>
                <GcvContent type="p1" content="Daily Sales" />
                <GcvContent type="l1" grey2 content="Avg $ per day" />
              </Container.Item>
              <Container.Item size={[10]}>
                <SalesActivityDailySalesDollarsBulletChart review={review} />
              </Container.Item>

              <Container.Item vCenter size={[2]}>
                <GcvContent type="p1" content="Daily Sales" />
                <GcvContent type="l1" grey2 content="Avg # per day" />
              </Container.Item>
              <Container.Item size={[10]}>
                <SalesActivityDailySalesCountBulletChart review={review} />
              </Container.Item>

              <Container.Item vCenter size={[2]}>
                <GcvContent type="p1" content="Verified" />
                <GcvContent type="l1" grey2 content="% of total sales" />
              </Container.Item>
              <Container.Item size={[10]}>
                <SalesActivityVerifiedBulletChart review={review} />
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>
    </Container>
  );
};

export default ReviewOverview;
