import { ReactWrapperComponent, WrapperProps, AccountsRouter } from '@user-interface/react-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DispensaryFacade, BankFacade, DispensaryAggregate, UserFacade } from '@user-interface/gcv-state';
import { Bank, Dispensary, User } from '@gcv/shared';
import { takeUntil } from 'rxjs/operators';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'fi-accounts',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></ app-react-wrapper>',
})
export class AccountsComponent extends ReactWrapperComponent {
  public dispensaries: Dispensary[];
  public dispensaryAggregates: { [k: string]: DispensaryAggregate };
  public bank: Bank;
  public user: User;
  @Input() props: WrapperProps;
  @Input() reports: any;
  @Output() clickEmitter = new EventEmitter<any>();

  component = AccountsRouter;

  constructor(
    private dispFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private userFacade: UserFacade,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.setUpStreams();
    this.updateProps();
  }

  setUpStreams() {
    this.bankFacade
      .selectCurrentBank()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(b => {
        this.bank = b;
        this.updateProps();
      });
    this.dispFacade
      .selectAllDispensaries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(disps => {
        this.dispensaries = disps;
        this.updateProps();
      });
    this.userFacade
      .selectCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.user = user;
        this.updateProps();
      });
  }

  updateProps() {
    this.props = {
      ...this.props,
      bank: this.bank,
      dispensaries: this.dispensaries,
      user: this.user,
    };
  }

  ngOnChanges() {
    this.updateProps();
  }

  handleEmitData = (type, data) => {
    if (type === 'updateDispensary') {
      this.dispFacade.updateDispensary(data);
    } else if (type === 'updateBank') {
      this.bankFacade.updateBank(data);
    } else if (type === 'openReviews') {
      const params: NavigationExtras = {
        queryParams: {
          dispensaryName: data.name,
        },
      };
      this.router.navigate(['/secure/bank/account-monitoring/open-reviews'], params);
    } else if (type === 'openReports') {
      const params: NavigationExtras = {
        queryParams: {
          dispensaryId: data.id,
          report_type: data.type,
        },
      };
      this.router.navigate(['/secure/bank/fincen/open'], params);
    }
  };
}
