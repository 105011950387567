import { getEndpoint, getV2Endpoint } from './getEndpoint';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '../../../common-ui/src/';

import { User } from '@gcv/shared';

export const getData = {
  getDueDiligenceTemplate: (API, storeData, dispensaryId) => {
    API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, getEndpoint.dueDiligenceTemplateGet(dispensaryId), {})
      .then(template => {
        storeData(template);
      })
      .catch(() => {
        storeData('Template Not Found');
      });
  },
  getActivities: (API, storeData, dispensaryId, reqId) => {
    API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, getEndpoint.activitiesGet(dispensaryId, reqId), {})
      .then(requirements => {
        storeData(requirements);
      })
      .catch(() => {
        storeData('Requirement Not Found');
      });
  },
  getDispensary: (API, storeData, dispensaryId) => {
    API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, getEndpoint.dispensaryGet(dispensaryId), {})
      .then(dispensary => {
        storeData(dispensary);
      })
      .catch(() => {
        storeData('Dispensary Not Found');
      });
  },
  getDocuments: (API, storeData, dispensaryId) => {
    API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, getEndpoint.documentsGet(dispensaryId), {})
      .then(documentsMap => {
        storeData(documentsMap);
      })
      .catch(() => {
        storeData('Documents Not Found');
      });
  },
  getUsers: (API, storeData, dispensaryId, bankId) => {
    const createUserMapFromArray = userArray => {
      const userMap = {};
      userArray.forEach(user => {
        userMap[user.id] = user;
      });
      return userMap;
    };
    if (dispensaryId && bankId) {
      API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, getEndpoint.usersGet(dispensaryId), {})
        .then((dispensaryUserArray: User[]) => {
          API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, getEndpoint.usersGet(bankId), {}).then((bankUserArray: User[]) => {
            const bankUserMap = createUserMapFromArray(bankUserArray);
            const dispensaryUserMap = createUserMapFromArray(dispensaryUserArray);
            storeData({ ...dispensaryUserMap, ...bankUserMap });
          });
        })
        .catch(() => {
          storeData('Users Not Found');
        });
    } else
      API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, getEndpoint.usersGet(dispensaryId ? dispensaryId : bankId), {})
        .then((userArray: User[]) => {
          const userMap = createUserMapFromArray(userArray);
          storeData(userMap);
        })
        .catch(() => {
          storeData('Users Not Found');
        });
  },
};
