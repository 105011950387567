<div class="wrapper">
  <section class="header">
    <h1>{{ edit ? 'Edit' : 'Create New' }} Required {{ category | titlecase }} Document</h1>
  </section>
  <section class="form">
    <gcv-mat-input
      [errors]="results.controls['name'].errors"
      [group]="results"
      [validators]="[
        {
          type: 'required',
          message: 'You must enter a name'
        },
        {
          type: 'maxLength',
          message: 'Your name must have fewer than 30 characters'
        },
        {
          type: 'minLength',
          message: 'Your name must have greater than 3 characters'
        }
      ]"
      class="input"
      control="name"
      label="Document Title (30 characters max)"
      placeholder="Document Title (30 characters max)"
      required="true"
      type="text"
      type="text"
      [autoFill]="'off'"
    ></gcv-mat-input>
    <mat-form-field [attr.data-cy]="'create-new-dd-modal.component.description'" class="textarea" [formGroup]="results">
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        formControlName="description"
        (change)="commentInput($event)"
        (keyup)="commentInput($event)"
        [attr.data-cy]="'create-new-dd-modal.component.description'"
        cols="30"
        required
        matInput
        placeholder="Description (300 characters max)"
        rows="1"
      ></textarea>
    </mat-form-field>
    <form *ngIf="!staticCategory" [formGroup]="results">
      <mat-form-field>
        <mat-label>Category</mat-label>
        <mat-select [attr.data-cy]="'create-new-dd-modal.component.category'" formControlName="category" class="select">
          <mat-option *ngFor="let type of types" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </section>

  <section class="button-section back-button">
    <gcv-button (clickEvent)="cancelClick()" buttonText="Cancel" class="button" type="tertiary back"></gcv-button>
  </section>
  <section class="button-section  next-button">
    <gcv-button
      (clickEvent)="createClick()"
      [disabled]="!results.valid"
      [buttonText]="edit ? 'Save' : 'Create'"
      class="button"
      type="primary"
    ></gcv-button>
  </section>
  <section class="button-section delete-button" *ngIf="edit && canDelete">
    <gcv-button (clickEvent)="deleteRequirement()" buttonText="Delete" class="button" type="danger"></gcv-button>
  </section>
</div>
