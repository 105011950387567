import styled, { createGlobalStyle, CSSProperties } from 'styled-components';
import FloatingLabelInput from 'react-floating-label-input';
import { $blue, $fontFamily, $primary, $grey2, $grey1, $grey8 } from '../../util/styleVariables';
import { Styles } from 'react-select';

interface AccountItemProps {
  isSelected?: boolean;
}

// customize style of react-modal
export const customModalStyles = {
  overlay: {
    zIndex: 5,
    background: 'rgba(177, 178, 181, 0.7)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    width: '500px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    borderRadius: '3px',
    padding: '38px 45px',
    overflow: 'visible',
  },
};

// customize style of react-select
export const noIndicatorSeparator = { indicatorSeparator: () => ({}) };

export const searchBarStyles = {
  control: (): CSSProperties => ({
    border: 'none',
    borderBottom: '1px solid #E0E5F0',
    display: 'flex',
  }),
  menu: (): CSSProperties => ({
    border: 'none',
  }),
  ...noIndicatorSeparator,
};

// add animation to react-modal and colors to floating label input
export const ModalStyleOverrides = createGlobalStyle`

    .ReactModal__Content {
        height: unset !important;
        /* overflow-y: hidden !important; */
    }
    .ReactModalPortal > div {
        opacity: 0;
     }
     .ReactModalPortal .ReactModal__Overlay {
         transition: opacity 100ms ease-in-out;
         background: rgba(0, 0, 0, 0.6);
     }
     .ReactModalPortal .ReactModal__Overlay--after-open {
        opacity: 1;
     };
     .ReactModalPortal .ReactModal__Overlay--before-close {
        opacity: 0;
     }
`;

export const ExportActivityModalBody = styled.div`
  font-family: ${$fontFamily};
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #16181e;
  }

  h5 {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-top: 25px;
  }

  .button-container {
    text-align: center;
    margin-top: 25px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  position: relative;
`;

export const RadioButtons = styled.div`
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 33%;

  label {
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: -0.21875px;
    color: ${$grey1};
    margin-left: 5px;
  }

  input,
  label {
    cursor: pointer;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  height: 18px;
  margin-top: 35px;
`;

export const SearchInput = styled(FloatingLabelInput)`
  padding: 5px 0;
  width: 100%;
  font-size: 16px;
  color: ${$grey1};

  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }

  &::placeholder {
    font-size: 16px;
    line-height: 19px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
  }
`;

export const InputIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-bottom: 5px;
`;

export const AccountsContainer = styled.div`
  height: 200px;
  overflow-y: scroll;
  margin-top: 30px;

  &::-webkit-scrollbar {
    /* -webkit-appearance: none; */
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${$grey8};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const AccountItem = styled.div<AccountItemProps>`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  padding: 16px 0;

  label {
    width: 450px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: ${props => (props.isSelected ? $primary : '#16181e')};
    cursor: pointer;
  }
`;

export const AccountsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  margin-top: 1rem;

  .accounts-selected {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${$grey8};
  }

  .select-all {
    font-size: 14px;
    line-height: 15px;
    color: ${$blue};
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  margin-top: 2rem;
`;

export const HyperTextButton = styled.div`
  cursor: pointer;
  color: #6896fb;
  font-size: 10pt;
  padding: 0.5rem;
  transition: color 0.1s ease;
  &:hover {
    color: blue;
  }
  &:active {
    color: grey;
  }
`;

export const CenterHorizontal = styled.div`
  margin: 0 auto;
`;
