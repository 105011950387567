import { DataPersistence } from '../../utils/data.persistance';
import { NotificationState } from './notifications.reducer';
import { Effect } from '@ngrx/effects';
import {
  CompleteNotification,
  LoadNotifications,
  MarkNotificationSeen,
  NotificationActionTypes,
  NotificationCompleted,
  NotificationSeen,
  NotificationsLoaded,
  NotificationUpdated,
  UpdateNotification,
} from './notifications.actions';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Notification } from '@gcv/shared';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';
import { API } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsEffect {
  @Effect() loadNotifications$ = this.dataPersistance.fetch(NotificationActionTypes.LoadNotifications, {
    run: (action: LoadNotifications, state: NotificationState) => {
      const { userId, orgId, timeStamp } = action.payload;
      return from(
        API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/notifications/${orgId}/${userId}?time_stamp=${timeStamp}`, {})
      ).pipe(map((res: Notification[]) => new NotificationsLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: NotificationActionTypes.LoadNotifications, message: err });
    },
  });

  @Effect() updateNotification$ = this.dataPersistance.pessimisticUpdate(NotificationActionTypes.UpdateNotification, {
    run: (action: UpdateNotification, state: NotificationState) => {
      return this.sdk
        .notificationsIdPut(action.payload.id, action.payload)
        .pipe(map((res: Notification) => new NotificationUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: NotificationActionTypes.UpdateNotification, message: err });
    },
  });

  @Effect() completeNotification$ = this.dataPersistance.pessimisticUpdate(
    NotificationActionTypes.CompleteNotification,
    {
      run: (action: CompleteNotification, state: NotificationState) => {
        const completedNotification = { ...action.payload, completed: true };
        return this.sdk
          .notificationsIdDelete(action.payload.id)
          .pipe(map(() => new NotificationCompleted(completedNotification)));
      },
      onError: err => {
        return new AddError({ action: NotificationActionTypes.CompleteNotification, message: err });
      },
    }
  );

  @Effect() markNotificationSeen$ = this.dataPersistance.pessimisticUpdate(
    NotificationActionTypes.MarkNotificationSeen,
    {
      run: (action: MarkNotificationSeen, state: NotificationState) => {
        const { notificationId } = action.payload;
        return from(API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/notifications/${notificationId}/seen`, {})).pipe(
          map(() => new NotificationSeen({ notificationId }))
        );
      },
      onError: err => {
        return new AddError({ action: NotificationActionTypes.MarkNotificationSeen, message: err });
      },
    }
  );

  constructor(private dataPersistance: DataPersistence<NotificationState>, private sdk: DefaultService) {}
}
