import React, { useState, useEffect } from 'react';
import { Grid, Snackbar } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';

import {
  Dispensary,
  Bank,
  DispensaryRiskRating,
  BankDispensaryMetadataData,
  OrganizationRoleResolver,
  User,
  MaxDeposit,
  convertStringNumberToWholeCents,
} from '@gcv/shared';
import {
  GcvCard,
  GcvButton,
  GcvInputForm,
  GcvLoading,
  GcvInputSelect,
  GcvInputFile,
  GcvContent,
  GcvModal,
  dateFormatterToISO,
  dateFormatterFromISO,
} from '../../../lib';
import { EditIcon } from 'libs/react-ui/src/icons/EditIcon';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { api } from 'libs/react-ui/src/api';
import { VerticalCenter } from 'libs/react-ui/src/lib/GcvInputFile/styles';
import { HorizontalCenter } from 'libs/react-ui/src/styles/theme';
import { dispensary_rating_options, dispensary_frequency_options } from 'libs/react-ui/src/constants/Accounts';
import { s3Util } from 'libs/react-ui/src/util/s3.util';
import mime from 'mime-types';
import * as base64arraybuffer from 'base64-arraybuffer';
import { formatDateOrDash, formatPercentage } from 'libs/react-ui/src/util';

const HeaderSubContent = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`;

const Label = styled.div`
  font-size: 13px;
  margin-top: 0px;
  padding: 5px 0;
  color: #a5a8ba;
  font-family: Lato, Helvetica, Arial, san-serif;
  line-height: 1.25;
`;

interface Props {
  dispensary: Dispensary;
  bank: Bank;
  user: User;
}

export const AccountInternal = ({ dispensary, bank, user }: Props) => {
  const apiClient = api();
  const { handleSubmit, reset, ...form } = useForm();

  const [editAccountInfo, setEditAccountInfo] = useState(false);
  const [editOversight, setEditOversight] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAccountInfo, setLoadingAccountInfo] = useState(false);
  const [loadingOversight, setLoadingOversight] = useState(false);
  const [riskRating, setRiskRating] = useState<{ label: string; value: DispensaryRiskRating }>({
    label: 'No Rating',
    value: 'none',
  });
  const [accountOpenDate, setAccountOpenDate] = useState('');
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [files, setFiles] = useState<{ file: File; docId: string }[]>([]);
  const [maxCashDeposit, setMaxCashDeposit] = useState(undefined);
  const [lastManualUpdate, setLastManualUpdate] = useState(undefined);
  const [editMaxCashDeposit, setEditMaxCashDeposit] = useState(true);
  const [loadingSetMaxDeposit, setLoadingSetMaxDeposit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    getDispensaryMetadata();
    getExistingFiles();
    getMaxDeposit();

    // edit state must be true on first render in order for rules to work
    // on second render 'useEffect lifecycle' we set it to false
    setEditAccountInfo(false);
    setEditOversight(false);
    setEditMaxCashDeposit(false);
  }, []);

  const getDispensaryMetadata = async () => {
    try {
      const res = await apiClient.banks.getDispensaryBankMetadata(bank.id, dispensary.id, () => {});

      if (res) {
        const metadata: BankDispensaryMetadataData = res.value;
        const values = {
          internalId: metadata.internal_id,
          reviewFrequency: dispensary_frequency_options.find(v => v.value === metadata.review_frequency),
          accountOpenDate: dateFormatterFromISO(metadata.account_open_date),
          accountApprovedOnDate: dateFormatterFromISO(dispensary.due_diligence.bank_reviewed_on),
        };
        //@ts-ignore
        reset(values);
        const option = metadata.risk_rating
          ? dispensary_rating_options.find(v => v.value === metadata.risk_rating)
          : dispensary_rating_options.find(v => v.value === 'none');
        setRiskRating(option);
        setAccountOpenDate(metadata.account_open_date);
      } else {
        const option = dispensary_rating_options.find(v => v.value === 'none');
        setRiskRating(option);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const saveOversight = async payload => {
    await apiClient.banks.updateDispensaryBankMetadata(
      bank.id,
      dispensary.id,
      {
        review_frequency: payload.reviewFrequency.value,
      },
      setLoadingOversight
    );
    const values = {
      internalId: payload.internal_id,
      reviewFrequency: dispensary_frequency_options.find(v => v.value === payload.reviewFrequency.value),
    };
    //@ts-ignore
    reset(values);
    setEditAccountInfo(false);
    setEditOversight(false);
    setEditMaxCashDeposit(false);
  };

  const saveAccountIdentification = async payload => {
    await apiClient.banks.updateDispensaryBankMetadata(
      bank.id,
      dispensary.id,
      {
        internal_id: payload.internalId,
        account_open_date: payload.accountOpenDate ? dateFormatterToISO(payload.accountOpenDate) : undefined,
      },
      setLoadingAccountInfo
    );
    setAccountOpenDate(payload.accountOpenDate);
    setEditAccountInfo(false);
    setEditOversight(false);
    setEditMaxCashDeposit(false);
  };

  const frequencyValue = dispensary_frequency_options.find(v => v.value === form.watch('reviewFrequency'));

  const updateFileState = async (fileState: { allFiles: File[]; newFiles: File[]; removedFiles: File[] }) => {
    if (fileState.newFiles.length > 0) {
      await uploadDocuments(fileState.newFiles);
    }
    if (fileState.removedFiles.length > 0) {
      const existingFile = files.find(f => f.file === fileState.removedFiles[0]);
      if (existingFile) {
        const newFiles = [...files];
        newFiles.splice(files.indexOf(existingFile), 1);
        apiClient.documents.deleteOrgDocument(bank.id, existingFile.docId, () => {});
        setFiles(newFiles);
      }
    }
  };

  const uploadDocuments = async (blobs, data?) => {
    setLoadingDocuments(true);
    const createdDocumentIds = [];
    const createdDocuments = [];
    for (const blobIndex in blobs) {
      const blob = blobs[blobIndex];
      const document = await s3Util(apiClient)
        .handleAddDocumentToS3({ blob: blob, userType: 'bank' }, bank.id, `${bank.id}/${dispensary.id}/${blob.name}`)
        .then(async file => {
          return apiClient.documents.createDocument(
            {
              orgId: bank.id,
              s3Key: file.s3_key,
              fileName: file.filename,
            },
            () => {}
          );
        })
        .catch(err => {
          setLoadingDocuments(false);
          alert('Error uploading document to S3, please contact support@greencheckverified.com' + err);
          return [];
        });
      createdDocuments.push({ file: blob, docId: document.id });
      createdDocumentIds.push(document.id);
    }

    setFiles([...files, ...createdDocuments]);
    setLoadingDocuments(false);
    return createdDocumentIds;
  };

  const getExistingFiles = async () => {
    setLoadingDocuments(true);
    try {
      const documents = await apiClient.banks.getInternalBankDispensaryDocuments(bank.id, dispensary.id, () => {});
      if (documents) {
        const files = await Promise.all(
          documents.map(async document => {
            const { s3LinkPath } = await s3Util(apiClient).getPresignedUrl(document.s3_key, 'get', 'bank');

            // get extension from filename
            var re = /(?:\.([^.]+))?$/;
            const ext = re.exec(document.file_name)[1].toLowerCase();
            const type = mime.lookup(ext);

            const result = await fetch(s3LinkPath);
            const base64 = await result.text();
            const base64ArrayBuffer = base64arraybuffer.decode(base64);

            return { file: new File([base64ArrayBuffer], document.file_name, { type }), docId: document.id };
          })
        );

        setFiles(files);
        setLoadingDocuments(false);
      } else {
        setLoadingDocuments(false);
      }
    } catch (e) {
      setLoadingDocuments(false);
    }
  };

  const getMaxDeposit = async () => {
    try {
      const currentMaxDeposit: MaxDeposit = await apiClient.dispensaries.getMaxDepositAggregate(
        dispensary.id,
        setLoading
      );
      setMaxCashDeposit(currentMaxDeposit.max_deposit ?? 0);
      setLastManualUpdate(currentMaxDeposit.last_updated);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const saveMaxCashDeposit = data => {
    const newMaxDeposit = convertStringNumberToWholeCents(data.newMaxDeposit ? data.newMaxDeposit : '0');

    apiClient.dispensaries
      .setMaxDeposit(dispensary.id, newMaxDeposit, setLoadingSetMaxDeposit)
      .then(() => {
        setMaxCashDeposit(newMaxDeposit);
        form.setValue(
          'maxCashDeposit',
          (newMaxDeposit / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
        );
      })
      .catch(e => {
        setSnackBarOpen(true);
      })
      .finally(() => {
        setEditMaxCashDeposit(false);
        toggleModal();
        setLoadingSetMaxDeposit(false);
      });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const canUserUpdateMetaData = new OrganizationRoleResolver().userCanDoAction(
    bank.groups,
    user,
    'account_info_update'
  );

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  return loading ? (
    <HorizontalCenter>
      <VerticalCenter>
        <GcvLoading></GcvLoading>
      </VerticalCenter>
    </HorizontalCenter>
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <GcvCard height={335}>
            <GcvCard.Header
              title={<strong>Account Information</strong>}
              actions={
                !editAccountInfo && canUserUpdateMetaData ? (
                  <EditIcon
                    onClick={() => {
                      setEditAccountInfo(true);
                      setEditOversight(false);
                      setEditMaxCashDeposit(false);
                    }}
                  />
                ) : null
              }
            ></GcvCard.Header>
            <GcvCard.Body>
              <label data-tip="ID is used to identify which core transactions should be mapped to this account">
                <GcvInputForm
                  {...form}
                  name="internalId"
                  label={
                    <>
                      Internal ID &nbsp;&nbsp;
                      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.95 2.75H6.05V3.85H4.95V2.75ZM4.95 4.95H6.05V8.25H4.95V4.95ZM5.5 0C2.464 0 0 2.464 0 5.5C0 8.536 2.464 11 5.5 11C8.536 11 11 8.536 11 5.5C11 2.464 8.536 0 5.5 0ZM5.5 9.9C3.0745 9.9 1.1 7.9255 1.1 5.5C1.1 3.0745 3.0745 1.1 5.5 1.1C7.9255 1.1 9.9 3.0745 9.9 5.5C9.9 7.9255 7.9255 9.9 5.5 9.9Z"
                          fill="#828599"
                        />
                      </svg>
                    </>
                  }
                  type="text"
                  readonly={!editAccountInfo}
                />
                <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
              </label>

              <br />

              <>
                <GcvInputForm
                  {...form}
                  name="accountApprovedOnDate"
                  label="Account Approved On Date"
                  type="date"
                  readonly={true}
                />
                <br />
                <GcvInputForm
                  {...form}
                  name="accountOpenDate"
                  label="Account Open Date"
                  type="date"
                  readonly={!editAccountInfo}
                />
              </>
              <br />
              {loadingAccountInfo ? (
                <HorizontalCenter>
                  <GcvLoading small={true} style={{ height: 'fit-content' }}></GcvLoading>
                </HorizontalCenter>
              ) : editAccountInfo && canUserUpdateMetaData ? (
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton tertiary onClick={() => setEditAccountInfo(false)}>
                      Cancel
                    </GcvButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <GcvButton primary onClick={handleSubmit(saveAccountIdentification)}>
                      Save
                    </GcvButton>
                  </Grid>
                </Grid>
              ) : null}
            </GcvCard.Body>
          </GcvCard>
        </Grid>

        <Grid item xs={4}>
          <GcvCard height={335}>
            <GcvCard.Header
              title={<strong>Oversight</strong>}
              actions={
                !editOversight && canUserUpdateMetaData ? (
                  <EditIcon
                    onClick={() => {
                      setEditAccountInfo(false);
                      setEditOversight(true);
                      setEditMaxCashDeposit(false);
                    }}
                  />
                ) : null
              }
            />
            <GcvCard.Body>
              <>
                {!editOversight ? (
                  <>
                    <Label>Risk Rating</Label>
                    <span>{riskRating.label}</span>

                    <div style={{ marginTop: '1rem' }}>
                      <Label style={{ marginTop: '.75rem' }}>Review Frequency</Label>
                      <span>{form.watch('reviewFrequency') ? form.watch('reviewFrequency').label : 'None'}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: '2rem' }}>
                      <label data-tip="Risk Rating can only be changed during a Review">
                        <GcvInputSelect
                          {...form}
                          name={'riskRating'}
                          label={'Risk Rating'}
                          options={dispensary_rating_options}
                          defaultValue={riskRating}
                          readonly={true}
                        />
                        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
                      </label>
                    </div>
                    <GcvInputSelect
                      {...form}
                      name={'reviewFrequency'}
                      label={'Review Frequency'}
                      options={dispensary_frequency_options}
                      rules={{ required: { value: true, message: 'is required' } }}
                      defaultValue={frequencyValue ? frequencyValue.value : ''}
                    />
                  </>
                )}

                <br />
                <GcvInputForm
                  {...form}
                  name="complianceScore"
                  label="Compliance Score"
                  type="text"
                  defaultValue={formatPercentage(dispensary.compliance_score, 100)}
                  readonly={true}
                />
                <br />

                {loadingOversight ? (
                  <HorizontalCenter>
                    <GcvLoading small={true} style={{ height: 'fit-content' }}></GcvLoading>
                  </HorizontalCenter>
                ) : editOversight && canUserUpdateMetaData ? (
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <GcvButton tertiary onClick={() => setEditOversight(false)}>
                        Cancel
                      </GcvButton>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <GcvButton primary onClick={handleSubmit(saveOversight)}>
                        Save
                      </GcvButton>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            </GcvCard.Body>
          </GcvCard>
        </Grid>
        <Grid item xs={4}>
          <GcvCard height={335}>
            <GcvCard.Header
              title={<strong>Max Deposit</strong>}
              actions={
                !editMaxCashDeposit && canUserUpdateMetaData ? (
                  <EditIcon
                    onClick={() => {
                      setEditAccountInfo(false);
                      setEditOversight(false);
                      setEditMaxCashDeposit(true);
                    }}
                  />
                ) : null
              }
            />
            <GcvCard.Body>
              {maxCashDeposit !== undefined && (
                <GcvInputForm
                  {...form}
                  name="maxCashDeposit"
                  label="Max Cash Deposit"
                  type="currency"
                  defaultValue={
                    !maxCashDeposit || maxCashDeposit == 0
                      ? '$0.00'
                      : (maxCashDeposit / 100).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })
                  }
                  readonly={true}
                />
              )}

              {editMaxCashDeposit && (
                <GcvInputForm {...form} name="newMaxDeposit" label="New Max Deposit" type="number" readonly={false} />
              )}

              <br />

              {lastManualUpdate !== undefined && (
                <GcvInputForm
                  {...form}
                  name="lastManualUpdate"
                  label="Last Manual Update"
                  type="text"
                  defaultValue={formatDateOrDash(lastManualUpdate)}
                  readonly={true}
                />
              )}

              {editMaxCashDeposit && (
                <>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <GcvButton tertiary onClick={() => setEditMaxCashDeposit(false)}>
                        Cancel
                      </GcvButton>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      {form.watch('newMaxDeposit') == null || form.watch('newMaxDeposit') == '' ? (
                        <label data-tip={'Enter a new max deposit to save this value'}>
                          <GcvButton
                            primary
                            onClick={toggleModal}
                            disabled={form.watch('newMaxDeposit') == null || form.watch('newMaxDeposit') == ''}
                          >
                            Save
                          </GcvButton>
                          <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
                        </label>
                      ) : (
                        <GcvButton
                          primary
                          onClick={toggleModal}
                          disabled={form.watch('newMaxDeposit') == null || form.watch('newMaxDeposit') == ''}
                        >
                          Save
                        </GcvButton>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </GcvCard.Body>
          </GcvCard>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <GcvCard height={335}>
            <GcvCard.Header title={'Internal Documentation'} />
            <GcvCard.Body>
              {loadingDocuments ? (
                <GcvLoading style={{ height: 'fit-content', marginTop: '5rem' }}></GcvLoading>
              ) : (
                <GcvInputFile
                  updateFileState={updateFileState}
                  acceptedTypes={['all']}
                  files={files.map(f => f.file)}
                  multiple={true}
                  maxHeight={'10rem'}
                  large={true}
                ></GcvInputFile>
              )}
            </GcvCard.Body>
          </GcvCard>
        </Grid>
      </Grid>

      <GcvModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title={`Confirm New Max Deposit`}
        backButton={
          <GcvButton tertiary onClick={toggleModal}>
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton
            primary
            isLoading={loadingSetMaxDeposit}
            disabled={loadingSetMaxDeposit}
            onClick={handleSubmit(saveMaxCashDeposit)}
          >
            Confirm
          </GcvButton>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <GcvContent type="l2" content={'Current Max Deposit'} />
            <GcvContent type="p1" content={form.watch('maxCashDeposit')} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <GcvContent type="l2" content={'New Max Deposit'} />
            <GcvContent
              type="p1"
              content={(convertStringNumberToWholeCents(form.watch('newMaxDeposit') ?? '0') / 100).toLocaleString(
                'en-US',
                {
                  style: 'currency',
                  currency: 'USD',
                }
              )}
            />
          </Grid>

          {form.watch('newMaxDeposit') &&
            (convertStringNumberToWholeCents(form.watch('newMaxDeposit')) > maxCashDeposit || !maxCashDeposit) && (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <strong>Note:</strong> The new Max Deposit is greater than the current.
              </Grid>
            )}
        </Grid>
      </GcvModal>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        message="There was an error updating the max deposit. Please try again or contact support@greencheckverified.com"
      />
    </>
  );
};
