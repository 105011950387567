import { Injectable } from '@angular/core';
import { DataPersistence } from '../../utils/data.persistance';
import { FincenState } from './fincen.reducer';
import { Effect } from '@ngrx/effects';
import {
  FincenActionTypes,
  FincenByBankIdLoaded,
  FincenLoaded,
  FincenUpdated,
  LoadFincen,
  LoadFincenByBankAndReportId,
  LoadFincenByBankId,
  UpdateFincen,
} from './fincen.actions';
import {
  FincenCtrReport,
  FincenSarReport,
  getReportWithGreenCheckTransactionsAsListOfIdsInsteadOfObjects,
} from '@gcv/shared';
import { map } from 'rxjs/operators';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';
import { AppState } from '../app.reducer';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';

@Injectable({
  providedIn: 'root',
})
export class FincenEffect {
  @Effect() loadFincen$ = this.dataPersistance.fetch(FincenActionTypes.LoadFincen, {
    run: (action: LoadFincen, state: FincenState) => {
      const appState = (state as unknown) as AppState;
      if (!appState.fincen.ids.length || action.payload.forceFetch) {
        return this.sdk
          .fincenReportsBankIdDispensaryIdGet(action.payload.bankId, action.payload.dispensaryId)
          .pipe(map((res: (FincenCtrReport | FincenSarReport)[]) => new FincenLoaded(res)));
      }
    },
    onError: err => {
      return new AddError({ action: FincenActionTypes.LoadFincen, message: err });
    },
  });

  @Effect() loadFincenByBankId$ = this.dataPersistance.fetch(FincenActionTypes.LoadFincenByBankId, {
    run: (action: LoadFincenByBankId) => {
      return this.sdk
        .fincenReportsBankIdGet(action.payload.bankId)
        .pipe(map((res: (FincenCtrReport | FincenSarReport)[]) => new FincenByBankIdLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: FincenActionTypes.LoadFincenByBankId, message: err });
    },
  });

  @Effect() loadFincenByBankAndReportId$ = this.dataPersistance.fetch(FincenActionTypes.LoadFincenByBankAndReportId, {
    run: (action: LoadFincenByBankAndReportId) => {
      return from(
        API.get(
          GREEN_CHECK_SERVICES_AMPLIFY_NAME,
          `/fincen-reports/${action.payload.bankId}/reports/${action.payload.reportId}`,
          {}
        )
      ).pipe(map((res: FincenCtrReport | FincenSarReport) => new FincenUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: FincenActionTypes.LoadFincenByBankId, message: err });
    },
  });

  @Effect() updateFincen$ = this.dataPersistance.fetch(FincenActionTypes.UpdateFincen, {
    run: (action: UpdateFincen, state: FincenState) => {
      const reportWithGreenCheckTransactionsAsListOfIdsInsteadOfObjects = getReportWithGreenCheckTransactionsAsListOfIdsInsteadOfObjects(
        action.payload
      );
      return this.sdk
        .fincenReportsBankIdPut(action.payload.id, reportWithGreenCheckTransactionsAsListOfIdsInsteadOfObjects)
        .pipe(map((res: FincenSarReport | FincenCtrReport) => new FincenUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: FincenActionTypes.UpdateFincen, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<FincenState>, private sdk: DefaultService) {}
}
