import {
  citiesPattern,
  einAndTinPattern,
  genericAlphanumericFPattern,
  organizationNamePattern,
  phoneNumberPattern,
  zipCodePattern,
} from '../validators/commonValidators';
import { DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE } from '@gcv/shared';

/* First Section */

export const organizationKey = [
  {
    value: 'orgName',
    viewValue: 'Organization Name',
    required: true,
    disabled: true,
    validators: [
      {
        type: 'pattern',
        match: organizationNamePattern,
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a legal entity name',
      },
      {
        type: 'pattern',
        message: 'Entity name must be valid',
      },
    ],
  },
  {
    value: 'certificateOrCharterNumber',
    viewValue: 'Certificate or Charter Number',
    required: true,
    validators: [],
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a certificate or charter number',
      },
    ],
  },
  {
    value: 'primaryReg',
    viewValue: 'Primary Regulator',
    required: true,
    validators: [],
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a primary regulator',
      },
    ],
  },
];

export const bankAddressKey = [
  {
    value: 'address',
    viewValue: 'Street',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: genericAlphanumericFPattern,
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'Invalid character entered',
      },
      {
        type: 'required',
        message: 'Please enter a valid street address',
      },
    ],
  },
  {
    value: 'city',
    viewValue: 'City',
    required: true,
    errors: [],
    validators: [{ type: 'pattern', match: citiesPattern }],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please enter a valid city',
      },
      {
        type: 'required',
        message: 'Please enter a valid city',
      },
    ],
  },
  {
    value: 'state',
    viewValue: 'State',
    required: true,
    disabled: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'Please select a state',
      },
    ],
  },
  {
    value: 'postalCode',
    viewValue: 'Zip Code',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: zipCodePattern,
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'You must enter a 5 or 9 digit zipcode',
      },
      {
        type: 'required',
        message: 'You must enter a 5 or 9 digit zipcode ',
      },
    ],
  },
];

/* Second Section */
export const orgDetailsKey = [
  {
    value: 'ein',
    viewValue: 'Employer Identification Number',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: einAndTinPattern,
      },
      {
        type: 'minLength',
        minLength: 9,
      },
      {
        type: 'maxLength',
        maxLength: 10,
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'EIN must be 9 digits',
      },
      {
        type: 'minLength',
        message: 'EIN must be 9 digits',
      },
      {
        type: 'maxLength',
        message: 'EIN must be 9 digits',
      },
    ],
  },
  {
    value: 'regulatorContactOffice',
    viewValue: 'Contact Office',
    required: true,
    errors: [],
    validators: [
      { type: 'required', message: 'Phone number is required' },
      { type: 'pattern', match: "[a-zA-Z 0-9 ,&'.#-]{2,50}" },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a contact office',
      },
      {
        type: 'pattern',
        message: 'Contact Office Name must be valid',
      },
    ],
  },
  {
    value: 'primaryRegulatorPhone',
    viewValue: 'Contact Office Phone',
    required: true,
    errors: [],
    validators: [{ type: 'pattern', match: phoneNumberPattern }],
    validatorText: [
      { type: 'required', message: 'Phone number is required' },
      { type: 'pattern', message: 'Phone number must be valid' },
    ],
  },
  {
    value: 'transmitter_control_code',
    viewValue: 'Transmitter Control Code',
    required: true,
    errors: [],
    validators: [{ type: 'required', message: 'Phone number is required' }],
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a Transmitter Control Code',
      },
    ],
  },
];

export const gcvDueDiligenceRequirements = () => {
  const reqs = JSON.parse(JSON.stringify(DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE.documentation.categories));
  reqs.legal.push(
    {
      name: 'Business Licenses',
      description:
        'A business may have multiple licenses issued by the state that allows them to conduct business activities in different business categories.',
      type: 'legal',
    },
    {
      name: 'Employee Licenses',
      description: 'An employee license establishes that an employee is legally allowed to work at the business.',
      type: 'legal',
    }
  );
  return reqs;
};

export const bankContactKey = [
  {
    value: 'phoneNumber',
    viewValue: 'Business Phone',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: phoneNumberPattern,
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'Phone number is required',
      },
      {
        type: 'pattern',
        message: 'Phone number must be valid',
      },
    ],
  },
  {
    value: 'website',
    viewValue: 'Business Website',
    errors: [],
    validatorText: [
      {
        type: 'pattern',
        message: 'Your website must start with http:// or https://',
      },
    ],
  },
];

export const cannabisProgramContactKey = [
  {
    value: 'firstName',
    viewValue: 'First Name',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: "[a-zA-Z ,'.-]{2,30}",
      },
    ],
    validatorText: [
      {
        type: 'pattern',
        message: 'Please enter a valid first name',
      },
      {
        type: 'required',
        message: 'Please enter a valid first name',
      },
    ],
  },
  {
    value: 'lastName',
    viewValue: 'Last Name',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: "[a-zA-Z ,'.-]{2,30}",
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'Please enter a valid last name',
      },
      {
        type: 'pattern',
        message: 'Please enter a valid last name',
      },
    ],
  },
  {
    value: 'title',
    viewValue: 'Title',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: "[a-zA-Z0-9 ,'.-]{1,30}",
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'Please enter a valid title',
      },
      {
        type: 'pattern',
        message: 'Please enter a valid title',
      },
    ],
  },
  {
    value: 'emailAddress',
    viewValue: 'Email',
    required: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'You must enter a valid email address ',
      },
    ],
  },
  {
    value: 'phoneNumber',
    viewValue: 'Business Phone',
    required: true,
    errors: [],
    validators: [
      {
        type: 'pattern',
        match: phoneNumberPattern,
      },
    ],
    validatorText: [
      {
        type: 'required',
        message: 'Phone number is required',
      },
      {
        type: 'pattern',
        message: 'Phone number must be valid',
      },
    ],
  },
];

export const bankDescriptionKey = [
  {
    value: 'description',
    viewValue: 'About Your Institution',
    required: true,
    errors: [],
    validatorText: [
      {
        type: 'required',
        message: 'Please enter a description of your company',
      },
    ],
  },
];
