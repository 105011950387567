<mat-table (matSortChange)="sortDocuments($event)" [dataSource]="sortedDocuments" class="table" matSort>
  <ng-container matColumnDef="requirement">
    <mat-header-cell *matHeaderCellDef class="requirement" mat-sort-header>Document Name</mat-header-cell>
    <mat-cell *matCellDef="let doc" class="requirement">{{ doc.name | truncate: 0:28 }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef class="type" mat-sort-header>
      Type
      <div (click)="stopPropagation($event)" class="filter">
        <button
          (click)="handleFilterToggle($event)"
          [class.toggled]="filterToggled"
          class="filter button"
          id="filter-button"
        >
          <svg fill="none" height="10" viewBox="0 0 16 10" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
            />
          </svg>
        </button>
        <div [class.toggled]="filterToggled" class="filter toolbox">
          <span>Filter</span>
          <mat-checkbox (change)="filterDocuments()" [(ngModel)]="filter.legal" class="filter checkbox" color="primary">
            Legal
          </mat-checkbox>
          <mat-checkbox
            (change)="filterDocuments()"
            [(ngModel)]="filter.financial"
            class="filter checkbox"
            color="primary"
          >
            Financial
          </mat-checkbox>
          <mat-checkbox
            (change)="filterDocuments()"
            [(ngModel)]="filter.operational"
            class="filter checkbox"
            color="primary"
          >
            Operational
          </mat-checkbox>
          <mat-checkbox (change)="filterDocuments()" [(ngModel)]="filter.other" class="filter checkbox" color="primary">
            Other
          </mat-checkbox>
        </div>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let doc" class="type">{{ doc.type | titlecase }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="files">
    <mat-header-cell *matHeaderCellDef mat-sort-header>File Name</mat-header-cell>

    <mat-cell *matCellDef="let doc" class="type">{{ doc.files | fileNamePipe }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="upload_date">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Date Added</mat-header-cell>
    <mat-cell *matCellDef="let doc">{{ doc.upload_date | date: 'shortDate' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="uploaded_by">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Added By</mat-header-cell>
    <mat-cell *matCellDef="let doc">{{ doc.user_uploaded }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" class="row table-header"></mat-header-row>
  <mat-row (click)="handleRowClick(row)" *matRowDef="let row; columns: displayedColumns" class="row shadowHover">
  </mat-row>
</mat-table>
