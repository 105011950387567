import styled from 'styled-components';
import { $accent, $grey1, $grey2, $black2, $grey11 } from '../../../../util/styleVariables';

interface Props {
  type: 'warning' | 'red_flag';
}

const colorMap = {
  warning: { background: 'rgba(244, 155, 32, 0.25)', border: '1px solid #F49B20' },
  red_flag: { background: 'rgba(255, 78, 80, 0.25)', border: '1px solid #ff4447' },
};

export const DrawerContainer = styled.div``;

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #f0f2f9;
  padding: 20px;
  height: 10vh;
  box-sizing: border-box;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  h4 {
    margin-left: 1rem;
  }
`;

export const HeaderText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: inline;
`;

export const GreyText = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #5a5e6b;
`;

export const DrawerBody = styled.div`
  max-height: 90vh;
  box-sizing: border-box;
  overflow: auto;
  padding: 20px;
`;

export const FakeTableRow = styled.div`
  padding: 0 45px;
  margin-top: 8px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MetaInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-top: 1rem;
`;

export const ExpansionSectionContainer = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${$grey11};

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: ${$grey1};
  padding-bottom: 1rem;
  padding-left: 26px;

  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    color: ${$grey2};
    margin-bottom: 5px;
  }
`;

export const WarningsExceptionsContainer = styled.div`
  margin-top: 2rem;
  padding: 0 20px;
`;

export const ExpansionContainer = styled.div`
  transition: transform 0.25s;
  overflow: hidden;
`;

export const SectionHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  cursor: pointer;
`;

export const WarningExceptionLabel = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 140px;
  padding: 10px 20px;
  ${props => ({ ...colorMap[props.type] })};
  border-radius: 15px;
  color: ${$black2};
  font-size: 14px;
  line-height: 16px;
`;

export const ExceptionsList = styled.ul`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.25px;
  padding-inline-start: 20px;
`;

export const PaymentTable = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;

  h5 {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${$accent};
    margin-left: 40px;
  }
`;
