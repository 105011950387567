import styled, { createGlobalStyle } from 'styled-components';
import FloatingLabelInput from 'react-floating-label-input';
import { $primary, $grey8, $grey10 } from '../../util/styleVariables';

export const UploadPad = styled.div`
  width: 100%;
  height: 100%;
  background: ${$grey10};
  border: 1px dashed ${$grey8};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;
export const Input = styled.input`
  font-size: 12px;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 1px;
  right: 0;
  bottom: 0px;
  opacity: 0;
  height: 120px;
`;

export const AddFile = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${$primary};
  cursor: pointer;
  margin: 10px 0;
  display: flex;
  height: 30px;
`;

export const LargeText = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: ${$grey8};
`;

export const Text = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: ${$grey8};
`;

export const UploadedFile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #dbe1ed;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(130, 133, 153, 0.2);
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0.5rem 0;
  cursor: default;
`;
export const RemoveFile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
  &:hover {
    cursor: pointer;
  }
`;

export const ViewFile = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #5a5e6b;
  cursor: default;
  display: -webkit-box;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
