import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IANATimezones } from '@gcv/shared';
import { countries, entitytypes, primaryFederalRegulators, states, supportDispensaryStates } from '../../constants';
import { FormatCurrencyCustom, FormatPhonePipe, ZipPipe } from '../../pipes';

@Component({
  selector: 'gcv-editable-card',
  templateUrl: './editable-card.component.html',
  styleUrls: ['./editable-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableCardComponent implements OnInit {
  editable = false;
  @Input()
  permissions = [''];
  @Input()
  showEdit = false;
  @Output()
  saveContent = new EventEmitter();
  @Input()
  customStyle: any;
  @Input()
  data: FormGroup;
  @Input()
  title: string;
  @Input()
  formKey: any;
  @Input()
  type = 'text';
  @Input()
  errors: any = [];
  @Input()
  validators: any = [];
  @Input()
  customStyleEdit: any;
  @Input()
  addressContent = true;
  @Input()
  isDispensary = false;
  @Input()
  lockStates = false;
  states = states;
  supportDispensaryStates = supportDispensaryStates;
  countries = countries;
  primaryReg = primaryFederalRegulators;
  entityType = entitytypes;
  stateIndex = null;
  entityNameMap = {
    sole: 'Sole Proprietorship',
    partnership: 'General Partnership',
    corporation: 'Corporation',
    llc: 'Limited Liability Company',
  };
  @Output()
  blurCalled = new EventEmitter();
  @Output()
  focusCalled = new EventEmitter();
  iana_timezones = Object.values(IANATimezones).sort();

  tempData: any;

  constructor(
    private currencyPipe: FormatCurrencyCustom,
    private zipPipe: ZipPipe,
    private phonePipe: FormatPhonePipe
  ) {}

  ngOnInit() {
    this.resetFormGroup();
  }

  edit() {
    this.editable = true;
    this.resetFormGroup();
  }

  cancel() {
    this.editable = false;
    this.resetFormGroup();
  }

  resetFormGroup() {
    const data = this.data.getRawValue();
    const controls = {};
    for (const key in data) {
      if (key) {
        const validatorArray = [];
        const keyIndex = this.formKey.findIndex(j => j.value === key);
        if (keyIndex >= 0 && this.formKey[keyIndex].validators) {
          const validators = this.formKey[keyIndex].validators;
          validators.forEach(validator => {
            if (validator.type === 'pattern') {
              validatorArray.push(Validators.pattern(validator.match));
            } else if (validator.type === 'required') {
              validatorArray.push(Validators.required);
            } else if (validator.type === 'min') {
              validatorArray.push(Validators.min(validator.min));
            } else if (validator.type === 'max') {
              validatorArray.push(Validators.max(validator.max));
            } else if (validator.type === 'minLength') {
              validatorArray.push(Validators.minLength(validator.minLength));
            } else if (validator.type === 'maxLength') {
              validatorArray.push(Validators.maxLength(validator.maxLength));
            }
          });
        }
        if (keyIndex >= 0 && this.formKey[keyIndex].required) {
          validatorArray.push(Validators.required);
        }
        validatorArray.length > 0
          ? (controls[key] = new FormControl(data[key], validatorArray))
          : (controls[key] = new FormControl(data[key]));
        if (key === 'posName' || (this.formKey[keyIndex] && this.formKey[keyIndex].disabled)) {
          this.stateIndex = keyIndex;
          controls[key] = new FormControl(
            {
              value: controls[key].value,
              disabled: true,
            },
            Validators.required
          );
        }
      }
    }
    this.tempData = new FormGroup(controls);
  }

  save() {
    for (const key in this.tempData) {
      if (key) {
        this.data = this.tempData;
      }
    }
    this.saveContent.emit(this.data.getRawValue());
    this.editable = false;
  }

  handleInput(e) {
    this.tempData.controls[e.property].setValue(e.value);
  }

  selectionChanged(e, property) {
    this.tempData.controls[property].setValue(e.value);
  }

  onFocusCalled(key) {
    if (key.toLowerCase().includes('zip') || key.toLowerCase().includes('postalcode')) {
      this.unformatZip(key);
    } else if (key.toLowerCase().includes('phone')) {
      this.unformatPhone(key);
    } else if (key.toLowerCase().includes('sales')) {
      this.unformatCurrency(key);
    }
  }

  onBlurCalled(key) {
    if (key.toLowerCase().includes('zip') || key.toLowerCase().includes('postalcode')) {
      this.formatZip(key);
    } else if (key.toLowerCase().includes('phone')) {
      this.formatPhone(key);
    } else if (key.toLowerCase().includes('sales')) {
      this.formatCurrency(key);
    }
  }

  commentInput(e) {
    this.tempData.controls['description'].setValue(e.srcElement.value);
  }

  formatCurrency(key) {
    const defaultValue = this.tempData.get(key).value;
    this.tempData.get(key).setValue(this.currencyPipe.transform(defaultValue, 2, false));
  }

  unformatCurrency(key) {
    const defaultValue = this.tempData.get(key).value;
    this.tempData.get(key).setValue(this.currencyPipe.parse(defaultValue));
  }

  formatPhone(key) {
    const defaultValue = this.tempData.get(key).value;
    this.tempData.get(key).setValue(this.phonePipe.transform(defaultValue));
  }

  unformatPhone(key) {
    const defaultValue = this.tempData.get(key).value;
    this.tempData.get(key).setValue(this.phonePipe.parse(defaultValue));
  }

  formatZip(key) {
    const defaultValue = this.tempData.get(key).value;
    if (defaultValue !== undefined && defaultValue.length === 9) {
      this.tempData.get(key).setValue(this.zipPipe.transform(defaultValue));
    }
  }

  unformatZip(key) {
    const defaultValue = this.tempData.get(key).value;
    if (defaultValue !== undefined && defaultValue.length === 10) {
      this.tempData.get(key).setValue(this.zipPipe.parse(defaultValue));
    }
  }
}
