import styled, { CSSProperties } from 'styled-components';
import { Styles } from 'react-select';

import { $primary, $grey2, $grey3 } from '../../util/styleVariables';

let isFocused = false;

export const noIndicatorSeparator = { indicatorSeparator: () => ({}) };

export const searchBarStyles = {
  control: (): CSSProperties => ({
    border: 'none',
    display: 'flex',
  }),
  menu: (): CSSProperties => ({
    border: 'none',
  }),
  ...noIndicatorSeparator,
};

export const customSelectStyles = {
  control: (base, state): CSSProperties => {
    isFocused = state.isFocused;
    return {
      border: 'none',
      borderBottom: `1px solid ${isFocused ? $primary : $grey3}`,
      width: '100%',
      display: 'flex',
      cursor: 'pointer',
    };
  },
  input: (provided, state) => ({
    ...provided,
    width: '100%',
    marginLeft: '0',
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    cursor: 'pointer',
    fontWeight: 500,
    backgroundColor: null,
    color: isFocused || isSelected ? 'hsl(0,0%,20%)' : null,
    ':hover': {
      backgroundColor: '#DEEBFF',
    },
  }),
  container: provided => ({
    ...provided,
    flex: 1,
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: '0',
  }),
  valueContainer: provided => ({
    ...provided,
    overflow: 'visible',
    paddingLeft: '0',
    marginLeft: '0',
    fontSize: '16px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    marginLeft: '0',
    paddingLeft: '0',
    top: state.hasValue || state.selectProps.inputValue ? -5 : '50%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    color: isFocused ? $primary : $grey2,
  }),
  ...noIndicatorSeparator,
};

export const Warning = styled.div`
  font-size: 10px;
  padding: 2px 0px;
  color: red;
  height: 14px;
`;
