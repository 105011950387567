import { DispensaryAccountReviewWithData, Dispensary, Bank, BankDispensaryMetadata } from '@gcv/shared';
import { formatDateOrEmpty, formatMoney } from 'libs/react-ui/src/util/formatHelpers';
import { DateTime } from 'luxon';

function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatName(unFormattedName: string) {
  const splitName = unFormattedName.split('_');

  const name: string[] = [];

  for (let x = 0; x < splitName.length; x++) {
    name.push(capitalize(splitName[x]));
  }

  return name.join(' ');
}

export const getBasicTemplate = (
  review: DispensaryAccountReviewWithData,
  dispensary: Dispensary,
  bank: Bank,
  dispensaryMetadata
) => {
  const { current_period } = review.data.aggregated_core_transaction_data;
  const paymentTypeList = () => {
    let list = '';

    Object.keys(current_period.deposit).map(cp => {
      if (!current_period.deposit[cp].a) {
        return;
      }

      list += `<li>${formatName(cp)} Deposits: ${formatMissingPlaceholder(
        `${formatName(cp)} deposits total for period`,
        formatMoney(current_period.deposit[cp].a)
      )}</li>`;
    });

    Object.keys(current_period.withdrawal).map(cp => {
      if (!current_period.withdrawal[cp].a) {
        return;
      }

      list += `<li>${formatName(cp)} Withdrawals: ${formatMissingPlaceholder(
        `${formatName(cp)} withdrawals total for period`,
        formatMoney(current_period.withdrawal[cp].a)
      )}</li>`;
    });

    return `<ul>${list}</ul>`;
  };

  return `
    <p>We are confirming the existing risk rating of ${formatMissingPlaceholder(
      'risk rating',
      dispensaryMetadata?.risk_rating
    )} on ${formatMissingPlaceholder('account name', dispensary.name)}, Account # ${formatMissingPlaceholder(
    'account number',
    dispensaryMetadata?.internal_id
  )}. The customer operates a business that is monitored under ${formatMissingPlaceholder(
    `institution's name`,
    bank.orgName
  )} cannabis banking program. This is a ${formatMissingPlaceholder(
    'business type',
    dispensary.business_type
  )} business located at ${formatMissingPlaceholder('business address', dispensary.streetAddress)}. </p>

    <p>Based on our ${formatMissingPlaceholder(
      'review frequency',
      dispensaryMetadata?.review_frequency
    )} review of account and sales activities, we’ve concluded that this account’s behavior is in line with the expectations based on historic and peer group performance.</p>

    <p>Account activity for ${formatMissingPlaceholder(
      'activity start date',
      DateTime.fromISO(review.review_period_start_date).toLocaleString({
        timeZone: dispensary.iana_timezone,
      })
    )} through ${formatMissingPlaceholder(
    'activity end date',
    DateTime.fromISO(review.review_period_end_date).toLocaleString({
      timeZone: dispensary.iana_timezone,
    })
  )} is as follows:</p>

    ${paymentTypeList()}

    <p>
No suspicious activity has been identified and no marijuana-specific red flags have been detected. The institution will continue to monitor this account for further activity and update the risk profile as needed.</p>`;
};

const formatMissingPlaceholder = (label, value) => {
  return value ? value : `<b>[${label}]</b>`;
};
