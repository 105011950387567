import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-user-modal',
  templateUrl: './confirm-delete-user-modal.component.html',
  styleUrls: ['./confirm-delete-user-modal.component.scss'],
})
//FIXME test the shit out of this piece of shit
export class ConfirmDeleteUserModalComponent {
  @Output() formEmitter = new EventEmitter();

  constructor(
    private matDialogRef: MatDialogRef<ConfirmDeleteUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  submit(deleteUser: boolean) {
    this.matDialogRef.close({ deleteUser: deleteUser });
  }
}
