import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { FlatfileButton } from '@flatfile/react';
import { DailySummaries } from './components/Dashboard-DailySummaries';
import { Sales } from './components/Dashboard-Sales';
import { GcvTabs } from '../../lib/GcvTabs/GcvTabs';
import { RouteObject } from '../../util/types';
import calculateTimeRange from '../../util/calculateDateRange';

import { Route, useHistory } from 'react-router-dom';
import {
  Dispensary,
  User,
  PosType,
  GreenCheckWebSocketMessage,
  DueDiligenceStatus,
  MaxDeposit,
  DailySummary,
} from '@gcv/shared';
import { GcvButton, GcvLoading, GcvZeroState, GcvContent, GcvModal } from '../../lib';
import { Deposits } from './components/Dashboard-Deposits';
import { GcvPageHeader } from '../../lib/GcvPageHeader/GcvPageHeader';
import { GcvPage } from '../../lib/GcvPage/GcvPage';
import { FlexBox, HorizontalCenter } from '../../styles/theme';
import { ActivityReporting } from './components/ActivityReportingCRB';
import { environment } from 'apps/user-interface/src/environments/environment';
import { checkPermissions } from '../../util';
import { retailFields, nonRetailFields, importerOptions, validationHook } from './flatfileUtil';
import * as md5 from 'md5';
import { v4 as uuid } from 'uuid';
import { api } from '../../api';
import { Snackbar } from '@material-ui/core';

import styled from 'styled-components';
import { DateTime } from 'luxon';
import { ZeroStateContainer } from './styles';

const SnackBarAction = styled.div`
  cursor: pointer;
  padding: 1rem;
`;

export interface TimePeriod {
  val: string;
  timeRange: { start: string; end: string };
}
interface Props {
  user: User;
  dispensary: Dispensary;
  daysStartDate: string;
  daysEndDate: string;
  emitData: (type: string, dispensaryId?: string) => any;
  viewStatus: string;
}

const Tabs: RouteObject[] = [
  {
    path: '/secure/dispensary/dashboard/sales',
    render: props => <Sales {...props}></Sales>,
  },
  {
    path: '/secure/dispensary/dashboard/daily-summaries',
    render: props => <DailySummaries {...props} />,
  },
  {
    path: '/secure/dispensary/dashboard/deposits',
    render: props => <Deposits {...props} />,
  },
];

const FLATFILE_LICENSE_KEY = '58aa3874-be33-436e-bf36-5b77f10b49ef';

export const Dashboard = (props: Props) => {
  if (!props.user || !props.dispensary) return <GcvLoading></GcvLoading>;

  const apiClient = api();
  const history = useHistory();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBar, setSnackBar] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flatFileInitialized, setflatFileInitialized] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [partialSuccessModalOpen, setPartialSuccessModalOpen] = useState(false);
  const [socketData, setSocketData] = useState(null);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [maxDeposit, setMaxDeposit] = useState(null);
  const [syncingSales, setSyncingSales] = useState(false);
  const [summaries, setSummaries] = useState<DailySummary[]>(null);
  const [timePeriod, setTimePeriod] = useState<TimePeriod>({
    val: 'last30Days',
    timeRange: calculateTimeRange('last30Days', props.dispensary.iana_timezone),
  });
  const [socket, setSocket] = useState<WebSocket>();

  const posType = props.dispensary.posConfig.posName;

  useEffect(() => {
    if (props.user.id) {
      if (!socket) {
        setSocket(new WebSocket(`${environment.gcvConfig.webSocketUrl}?token=${props.user.id}`));
      } else {
        initWebsocketConnection(props.dispensary.id);
      }
    }
  }, [props.user.id, socket]);

  useEffect(() => {
    if (props.dispensary.id) {
      getData();
    }
  }, [props.dispensary.id, timePeriod]);

  const dashboardTabs = [
    { path: `/secure/dispensary/dashboard/sales`, label: `Sales` },
    { path: `/secure/dispensary/dashboard/daily-summaries`, label: `Daily Summaries` },
  ];

  if (checkPermissions(['deposit_view'], props.user, props.dispensary)) {
    dashboardTabs.splice(1, 0, { path: `/secure/dispensary/dashboard/deposits`, label: `Deposits` });
  }

  const isRetail = props.dispensary.posConfig.posName === PosType.GcvFaker_GcvCsv;
  const flatFileFields = isRetail ? retailFields : nonRetailFields;
  const flatFileCustomer = {
    companyId: props.dispensary.id,
    userId: props.user.id || props.dispensary.id,
    name: props.user.firstName + ' ' + props.user.lastName,
    companyName: props.dispensary.name,
  };

  const getData = () => {
    setLoadingData(true);
    fetchDailySummaries();
    getMaxDeposit();
  };

  const fetchDailySummaries = async () => {
    try {
      const startDay = DateTime.fromISO(timePeriod.timeRange.start).toFormat('yyyy-MM-dd');
      const endDay = DateTime.fromISO(timePeriod.timeRange.end).toFormat('yyyy-MM-dd');
      const summaries = await apiClient.dispensaries.getDailySummaries(props.dispensary.id, startDay, endDay, () => {});

      setSummaries(summaries);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingData(false);
    }
  };

  const getMaxDeposit = async () => {
    try {
      const maxDeposit: MaxDeposit = await apiClient.dispensaries.getMaxDepositAggregate(
        props.dispensary.id,
        setLoading
      );
      setMaxDeposit(maxDeposit);
    } catch (e) {
      console.log(e);
    }
  };

  const onSnackBarClose = () => {
    if (snackBar.actionType === 'dismiss') {
      setSnackBarOpen(false);
    } else if (snackBar.actionType === 'partial_success') {
      setSnackBarOpen(false);
      setPartialSuccessModalOpen(true);
    } else if (snackBar.actionType === 'failure') {
      setSnackBarOpen(false);
      setFailureModalOpen(true);
    }
  };

  const onFlatFileComplete = async data => {
    setUploading(true);
    const transactionData = data.$data.map(row => {
      const { data } = row;
      if (data.customer_id_number) {
        data.customer_id_number = md5(data.customer_id_number);
      }
      return data;
    });

    let fileName = data.$meta.fileName;
    const stringifiedData = JSON.stringify(transactionData, null, 2);

    if (!fileName) fileName = 'hand_crafted_file';
    const uniqueId = uuid();
    const s3Key = `${props.dispensary.id}/sale/${fileName}-${uniqueId}`;
    const document = {
      orgId: props.dispensary.id,
      s3Key,
      fileName,
    };

    apiClient.dispensaries
      .trackSalesUpload(props.dispensary.id, { document }, setLoading)
      .then(result => {
        Storage.vault
          .put(s3Key, stringifiedData, {
            level: 'public',
            contentType: 'text/plain',
            bucket: environment.storageConfig.salesFile,
          })
          .then(() => {});
      })
      .catch(e => {
        setSnackBar({
          message: 'Something went wrong, please try again shortly. If the problem persists, contact support',
          actionType: 'dismiss',
          actionMessage: 'Dismiss',
        });
        setSnackBarOpen(true);
        setUploading(false);
      })
      .finally(() => {
        setflatFileInitialized(false);
      });
  };

  const launchFlatFile = launchCallback => {
    setFailureModalOpen(false);
    setPartialSuccessModalOpen(false);
    try {
      if (!flatFileInitialized) {
        setflatFileInitialized(true);
        launchCallback();
      }
    } catch (e) {
      setflatFileInitialized(false);
    }
  };

  const flatFileButton = (buttonText: string, secondary: boolean) => {
    return (
      <FlatfileButton
        licenseKey={FLATFILE_LICENSE_KEY}
        customer={flatFileCustomer}
        onRecordChange={validationHook(props.dispensary.iana_timezone)}
        onRecordInit={validationHook(props.dispensary.iana_timezone)}
        settings={importerOptions(flatFileFields, isRetail, props.dispensary.name)}
        onData={onFlatFileComplete}
        onCancel={() => setflatFileInitialized(false)}
        render={(importer, launch) => {
          return (
            <GcvButton
              primary={!secondary}
              secondary={secondary}
              disabled={uploading || flatFileInitialized}
              isLoading={uploading || flatFileInitialized}
              style={{ marginLeft: '1rem' }}
              onClick={() => launchFlatFile(launch)}
            >
              {uploading ? 'Uploading...' : buttonText}
            </GcvButton>
          );
        }}
      />
    );
  };

  const initWebsocketConnection = (dispId: string) => {
    socket.onmessage = (message): void => {
      const socketMessage = JSON.parse(message.data) as GreenCheckWebSocketMessage;
      if (socketMessage.action === 'sales_ingestion_success') {
        //delay 5 seconds to allow for aggregates to process. Fetch latest dispensary to get has_uploaded_sales flag
        setTimeout(() => {
          setUploading(false);
          setSyncingSales(false);
          setSnackBarOpen(true);
          setSnackBar({ message: `Sales Upload Successful`, actionType: 'dismiss', actionMessage: 'Dismiss' });
          props.emitData('getDispensary', dispId);
          getData();
        }, 5000);
      } else if (socketMessage.action === 'sales_ingestion_partial_success') {
        setSyncingSales(false);
        setUploading(false);
        setSnackBarOpen(true);
        setSnackBar({
          message: `Your sales upload had some errors`,
          actionType: 'partial_success',
          actionMessage: 'Details',
        });
        setSocketData(socketMessage.data);
      } else if (socketMessage.action === 'sales_ingestion_failure') {
        setSyncingSales(false);
        setUploading(false);
        setSnackBarOpen(true);
        setSnackBar({
          message: `There was an issue with your sales upload`,
          actionType: 'failure',
          actionMessage: 'Details',
        });
      }
    };
  };

  const fundsToDeposit =
    maxDeposit &&
    props.dispensary.due_diligence != null &&
    props.dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED
      ? maxDeposit.max_deposit
      : 0;

  const isPoSUnknownOrUnsupported = () => {
    const posName = props.dispensary.posConfig.posName;
    return posName === PosType.Unknown || posName === PosType.Unsupported;
  };

  const isUploadSalesFileButtonShown = () => {
    const posTypeIsNotUnknownOrUnsupported = !isPoSUnknownOrUnsupported();
    const fetchStrategyIsUpload =
      props.dispensary.posConfig.fetch && props.dispensary.posConfig.fetch.fetchStrategy === 'upload';
    return posTypeIsNotUnknownOrUnsupported && fetchStrategyIsUpload;
  };

  const isSyncSalesButtonShown = () => {
    const posTypeIsNotUnknownOrUnsupported = !isPoSUnknownOrUnsupported();
    const fetchStrategyIsAutomatic =
      props.dispensary.posConfig.fetch && props.dispensary.posConfig.fetch.fetchStrategy === 'automatic';
    return posTypeIsNotUnknownOrUnsupported && fetchStrategyIsAutomatic;
  };

  const syncSales = () => {
    setSyncingSales(true);
    setUploading(true);
    apiClient.dispensaries.syncSales(props.dispensary.id, {}, () => {});
  };

  let dashboardType: 'verify' | 'disp' | 'deposit' | '';
  switch (props.viewStatus) {
    case 'IdentificationVerification':
      dashboardType = 'verify';
      break;
    case 'StartDueDiligence':
    case 'ContinueDueDiligence':
      dashboardType = 'disp';
      break;
    case 'ComplianceZeroState':
      dashboardType = 'deposit';
      break;
    default:
      dashboardType = '';
      break;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        action={<SnackBarAction onClick={onSnackBarClose}>{snackBar ? snackBar.actionMessage : ''}</SnackBarAction>}
        open={snackBarOpen}
        autoHideDuration={10000}
        onClose={() => setSnackBarOpen(false)}
        message={snackBar ? snackBar.message : ''}
      />

      <GcvModal
        toggleModal={() => setFailureModalOpen(false)}
        modalOpen={failureModalOpen}
        backButton={
          <GcvButton tertiary onClick={() => setFailureModalOpen(false)}>
            Close
          </GcvButton>
        }
        continueButton={flatFileButton('Try Again', false)}
        title={'Your sales could not be uploaded'}
      >
        <FlexBox style={{ flexDirection: 'column' }}>
          <div style={{ padding: '0 2rem' }}>An error occured. Please try again.</div>
          <HorizontalCenter>
            <GcvContent type="p2" content="If the problem continues, please contact" />
            <a rel="noopener noreferrer" target="_blank" href="mailto:support@greencheckverified.com">
              support@greencheckverified
            </a>
          </HorizontalCenter>
        </FlexBox>
      </GcvModal>

      <GcvModal
        toggleModal={() => setPartialSuccessModalOpen(false)}
        modalOpen={partialSuccessModalOpen}
        backButton={
          <GcvButton tertiary onClick={() => setPartialSuccessModalOpen(false)}>
            Close
          </GcvButton>
        }
        continueButton={flatFileButton('Confirm', false)}
        title={
          socketData
            ? `${socketData.report.totalUnproccessableRows} out of ${socketData.report.totalRows} rows could not be uploaded`
            : ''
        }
      >
        <FlexBox style={{ flexDirection: 'column' }}>
          <HorizontalCenter>
            <GcvContent
              type="p2"
              content={
                socketData
                  ? `Sales upload on ${DateTime.fromISO(socketData.report.ingestionTime).toLocaleString()}`
                  : ''
              }
            />
          </HorizontalCenter>
          <HorizontalCenter>
            <h4>
              Please ensure that <u>every</u> sale has the following required values:
            </h4>
          </HorizontalCenter>
          <HorizontalCenter>
            <div>
              <ul>
                {isRetail ? (
                  <>
                    <li>Transaction ID</li>
                    <li>Customer ID</li>
                    <li>Receipt Date</li>
                  </>
                ) : (
                  <>
                    <li>Date</li>
                    <li>Customer</li>
                  </>
                )}
              </ul>
            </div>
          </HorizontalCenter>

          <HorizontalCenter>
            <h4>When you're ready, please try again</h4>
          </HorizontalCenter>
          <HorizontalCenter>
            <p>
              Need help? Visit our{' '}
              <a href="https://support.greencheckverified.com/en/knowledge/upload-non-retail-sales-manually">
                Support Center
              </a>{' '}
              for step-by-step instructions.
            </p>
          </HorizontalCenter>
        </FlexBox>
      </GcvModal>

      {props.viewStatus === 'DashboardState' &&
      !props.dispensary.has_uploaded_sales &&
      (!posType ||
        posType === PosType.GcvCsv_Quickbooks ||
        posType === PosType.GcvFaker_GcvCsv ||
        posType === PosType.Unknown ||
        posType === PosType.Unsupported) ? (
        <GcvPage
          header={<GcvPageHeader title={props.dispensary.name} />}
          body={
            <ZeroStateContainer>
              <div style={{ margin: '2rem 0', textAlign: 'center' }}>
                <img src="../../../assets/zero_state_server.png" alt="No Sales" />
              </div>

              <div style={{ margin: '0 5rem', textAlign: 'center' }}>
                <GcvContent type="h1" content="You’re ready to start uploading sales" />
                <br />
                <br />
                <GcvContent
                  type="p1"
                  content="Uploading sales will help your banking partner verify that the funds you are depositing can be tied back to legitimate sales. It’s a good idea to get a head start on this so that you’re fully set up once your application has been reviewed."
                />
              </div>

              <div style={{ margin: '2rem 5rem', textAlign: 'center' }}>
                <GcvButton primary onClick={() => props.emitData('integration')}>
                  View Integrations
                </GcvButton>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {flatFileButton('Upload Sales Manually', true)}
              </div>

              <div style={{ margin: '5rem 2rem 2rem 2rem', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  Recommended articles
                  <br />
                  <a
                    target="_blank"
                    href="https://support.greencheckverified.com/knowledge/a-guide-to-uploading-sales-in-green-check"
                  >
                    A guide to uploading sales in Green Check
                  </a>
                  <br />
                  <a
                    target="_blank"
                    href="https://support.greencheckverified.com/knowledge/sales-frequently-asked-questions"
                  >
                    Sales and Deposits | Frequently Asked Questions
                  </a>
                </div>
                <div style={{ display: 'inline-block', alignSelf: 'flex-end' }}>
                  Need help? Email us at &nbsp;
                  <a href="mailto:support@greencheckverified.com">support@greencheckverified.com</a>
                </div>
              </div>
            </ZeroStateContainer>
          }
        />
      ) : (
        <GcvPage
          header={
            <GcvPageHeader
              title={props.dispensary.name}
              tabs={props.viewStatus === 'DashboardState' ? <GcvTabs routes={dashboardTabs}></GcvTabs> : null}
              actions={
                props.viewStatus === 'DashboardState' ? (
                  <>
                    <FlexBox style={{ justifyContent: 'flex-end' }}>
                      {checkPermissions(['deposit_create'], props.user, props.dispensary) ? (
                        <GcvButton
                          primary={true}
                          onClick={() => props.emitData('deposit')}
                          disabled={!fundsToDeposit}
                          data-cy="record-deposit-button"
                        >
                          Record Deposit
                        </GcvButton>
                      ) : null}
                      {checkPermissions(['sales_file_upload'], props.user, props.dispensary) &&
                      isUploadSalesFileButtonShown() ? (
                        <>{flatFileButton('Upload Sales File', true)}</>
                      ) : null}
                      {checkPermissions(['sales_file_upload'], props.user, props.dispensary) &&
                      isSyncSalesButtonShown() ? (
                        <GcvButton
                          secondary={true}
                          disabled={syncingSales === true}
                          style={{ marginLeft: '1rem' }}
                          isLoading={uploading}
                          onClick={syncSales}
                        >
                          {syncingSales ? 'Syncing...' : 'Sync Sales'}
                        </GcvButton>
                      ) : null}
                    </FlexBox>
                    <FlexBox style={{ justifyContent: 'flex-end', marginTop: '.5rem' }}>
                      <ActivityReporting dispensary={props.dispensary}></ActivityReporting>
                    </FlexBox>
                  </>
                ) : null
              }
            />
          }
          body={
            props.viewStatus !== 'DashboardState' ? (
              <ZeroStateContainer>
                <GcvZeroState
                  headerText={
                    props.viewStatus === 'IdentificationVerification'
                      ? 'Your Company Profile is Complete!'
                      : props.viewStatus === 'ComplianceZeroState'
                      ? 'Your Company Onboarding is Complete!'
                      : props.viewStatus === 'StartDueDiligence'
                      ? 'Complete Your Company Profile'
                      : props.viewStatus === 'ContinueDueDiligence'
                      ? 'Complete Your Company Profile'
                      : ''
                  }
                  dashboardSubText={
                    props.viewStatus === 'IdentificationVerification'
                      ? `The last step is to add a few personal details to verify your identity. Banks need this information to
                    comply with their Know Your Customer (KYC) requirements.`
                      : props.viewStatus === 'ComplianceZeroState'
                      ? `You’ll receive an email when your banking partner has been identified. If you need to, you can update your
                    business information or upload additional documents from the Company Profile page.`
                      : props.viewStatus === 'StartDueDiligence'
                      ? `Adding basic company details and documentation will prepare your business for a sustainable banking
                    relationship.`
                      : props.viewStatus === 'ContinueDueDiligence'
                      ? `Adding basic company details and documentation will prepare your business for a sustainable banking
                    relationship.`
                      : ''
                  }
                  type={dashboardType}
                  button={
                    props.viewStatus !== 'ComplianceZeroState' ? (
                      <GcvButton
                        secondary={props.viewStatus === 'ContinueDueDiligence'}
                        primary={
                          props.viewStatus === 'StartDueDiligence' || props.viewStatus === 'IdentificationVerification'
                        }
                        onClick={() => props.emitData('goToOnboarding')}
                        data-cy="get-verified-button"
                      >
                        {props.viewStatus === 'IdentificationVerification'
                          ? 'Get Verified'
                          : props.viewStatus === 'StartDueDiligence'
                          ? `Let's Go`
                          : props.viewStatus === 'ContinueDueDiligence'
                          ? 'Continue'
                          : ''}
                      </GcvButton>
                    ) : (
                      undefined
                    )
                  }
                ></GcvZeroState>
              </ZeroStateContainer>
            ) : (
              <>
                {Tabs.map(({ path, render }) => {
                  return (
                    <Route exact path={path} key={path}>
                      {({ match }) =>
                        match != null ? (
                          render({ ...props, summaries, setTimePeriod, timePeriod, loadingData, maxDeposit })
                        ) : (
                          <></>
                        )
                      }
                    </Route>
                  );
                })}
              </>
            )
          }
        />
      )}
    </>
  );
};
