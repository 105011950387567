import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ReactWrapperComponent,
  ProfileDropdownComponent,
  AddNewDocumentComponent,
  CreateNewFincenReportButtonComponent,
  ActivityReportingCRBComponent,
  ActivityReportingFIComponent,
  ComplianceScoreBadgeComponent,
  ResendUserInviteComponent,
  SalesAndDepositsRouterContainerComponent,
  CreateNewFincenReportComponent,
} from '@user-interface/react-ui';

@NgModule({
  declarations: [
    ReactWrapperComponent,
    ProfileDropdownComponent,
    AddNewDocumentComponent,
    CreateNewFincenReportButtonComponent,
    ComplianceScoreBadgeComponent,
    ActivityReportingFIComponent,
    ActivityReportingCRBComponent,
    ResendUserInviteComponent,
    SalesAndDepositsRouterContainerComponent,
    CreateNewFincenReportComponent,
  ],
  imports: [CommonModule],
  exports: [
    ReactWrapperComponent,
    ProfileDropdownComponent,
    AddNewDocumentComponent,
    CreateNewFincenReportButtonComponent,
    ComplianceScoreBadgeComponent,
    ActivityReportingFIComponent,
    ActivityReportingCRBComponent,
    ResendUserInviteComponent,
    SalesAndDepositsRouterContainerComponent,
    CreateNewFincenReportComponent,
  ],
})
export class NgReactWrappersModule {}
