import { ActivityType } from '..';

export * from './ActivityReportingCRB';
export * from './ActivityReportingCRB.wrapper.component';

export interface ActivityReportingCRBPayload {
  startDate: string;
  endDate: string;
  activityType?: ActivityType;
}
