<div (click)="goToNotification()" class="notificationCard shadowHover" data-cy="notification-card.component">
  <div class="notificationIconWrapper">
    <svg height="28" width="28" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle cx="14" cy="14" fill="#FFF" r="14" />
        <path
          d="M18 9l-5.5 5.7-1.9-2c-.6-.7-1.7-.7-2.3 0a2 2 0 0 0 0 2.6l3 3.2v.1h.1v.1h.2v.1h.1v.1H12v.1H13l.1-.1h.1l.1-.1a1.7 1.7 0 0 0 .2-.2l6.9-6.9a2 2 0 0 0 0-2.6c-.6-.7-1.6-.7-2.3 0"
          fill="#00BC66"
        />
      </g>
    </svg>
  </div>
  <div class="notificationBodyWrapper">
    <div class="notificationHeader">
      <span [attr.data-cy]="'notification-card.component.awaitingReview'" class="notificationTitle">{{
        notification | dynamicNotification: 'title'
      }}</span>
    </div>
    <div class="notificationDetails">
      <span class="notificationDetails" data-cy="notification-card.component.notificationDetails">{{
        notification | dynamicNotification: 'subtitle'
      }}</span>
    </div>
  </div>
</div>
