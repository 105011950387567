import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';

import { DrawerText, DrawerImage } from '../styles';

interface Props {
  form: any;
}

export const CovaForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To configure this integration, Cova will need to add your API key to Green Check's Cova Partners account.
      </DrawerText>
      <DrawerText>
        Send the following email to{' '}
        <a rel="noopener noreferrer" target="_blank" href="mailto:support@covasoftware.com">
          support@covasoftware.com
        </a>
        :
      </DrawerText>
      <DrawerText>
        <b>
          Hello,
          <br />
          <br />I need some assistance. I would like to integrate our system with the Green Check system. Please help me
          by providing access to Green Check's Cova Partners account. This account is set up under
          engineering@greencheckverified.com.
        </b>
      </DrawerText>
      <DrawerText>
        After you send the email, enable and connect the Cova integration below. Once Cova provides us with your API
        key, we will complete the connection. The integration may take 2-3 days.
      </DrawerText>
    </>
  );
};
