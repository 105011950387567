import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { GcvModal, GcvButton, GcvInputForm } from '../../../lib';
import { SubTitle, UploadContainer, VerticalSpacer } from './styles';

import { useApi, getEndpoint } from '../../../hooks/';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { GcvInputTextArea } from '../../../lib/GcvInputTextArea/GcvInputTextArea';
import { api } from 'libs/react-ui/src/api';

interface RequestFileUploadProps {
  updateApplication: () => void;
  toggleModal: () => void;
  modalOpen: boolean;
  dispensaryId: string;
  requirementId: string;
}

export const RequestFileUploadModal = ({
  updateApplication,
  dispensaryId,
  requirementId,
  toggleModal,
  modalOpen,
}: RequestFileUploadProps) => {
  const apiClient = api();
  const { register, handleSubmit, ...form } = useForm();

  const [loading, setLoading] = useState(false);

  const requestUpload = comment => {
    const body = {
      comment,
    };

    apiClient.documentManagement
      .requestFileUpload(dispensaryId, requirementId, body, () => {})
      .then(() => {
        updateApplication();
        toggleModal();
        setLoading(false);
      })
      .catch(e => {
        alert('Error adding a comment, please contact support@greencheckverified.com');
        setLoading(false);
      });
  };

  const onSubmit = ({ comment }) => {
    requestUpload(comment);
    setLoading(true);
  };

  const title = `Request Upload`;

  return (
    <>
      <GcvModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        backButton={
          <GcvButton onClick={toggleModal} tertiary={true} data-cy="cancel-button">
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={handleSubmit(onSubmit)} primary={true} data-cy="request-upload-button">
            Request
          </GcvButton>
        }
        title={title}
        loading={loading}
      >
        <SubTitle>
          The business will receive a notification asking them to upload another file for this requirement
        </SubTitle>
        {
          <UploadContainer>
            <VerticalSpacer>
              <GcvInputTextArea
                {...form}
                register={register}
                name="comment"
                label="Add a comment (optional)"
                testDataTag="request-upload-textbox"
              />
            </VerticalSpacer>
          </UploadContainer>
        }
      </GcvModal>
    </>
  );
};
