import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as _ from 'underscore';
import { Dispensary, LicenseManager, User, USStateAbbreviation } from '@gcv/shared';

export interface UiDueDiligenceLicenseUpload {
  user_uploaded: string;
}

@Component({
  selector: 'gcv-view-license-table',
  templateUrl: './view-license-table.component.html',
  styleUrls: ['./view-license-table.component.scss'],
})
export class ViewLicenseTableComponent implements OnInit, OnChanges {
  @Input() documents: UiDueDiligenceLicenseUpload[];
  @Input() userMap: User[];
  @Input() title: string;
  @Input() dispensary: Dispensary;
  @Output() openDocumentEmitter = new EventEmitter<any>();

  displayDocuments;
  transformedDocs: any;
  filteredDocuments: any[];
  sortedDocuments: any[];
  licenseData: any;
  sort: { active: string; direction: 'asc' | 'desc' | '' } = { active: '', direction: '' };
  displayedColumns = ['license_name', 'license_number', 'expiration_date', 'file_name', 'upload_date', 'user_uploaded'];

  constructor() {}

  private initializeDisplayDocumentsAndTransformedDocs() {
    this.displayDocuments = this.documents.map(doc => {
      return { ...doc, license_name: this.prettifyLicenseName(doc) };
    });
    this.transformedDocs = this.makeDocumentsArray();
  }

  ngOnInit() {
    if (this.dispensary) {
      this.licenseData = this.getLicenseDataForState(this.dispensary.state);
    }
    this.initializeDisplayDocumentsAndTransformedDocs();
  }

  ngOnChanges() {
    this.initializeDisplayDocumentsAndTransformedDocs();
  }

  // create human-readable license display name
  // use viewValue if available, otherwise just remove snake case
  prettifyLicenseName(document: any): string {
    if (this.dispensary && this.licenseData) {
      const licenseInfo: { value: string; viewValue: string } = this.licenseData.business_license_types[
        document.license_name
      ];
      if (licenseInfo) {
        return licenseInfo.viewValue;
      }
    }

    if (document.license_name) {
      return document.license_name.split('_').join(' ');
    } else {
      return '';
    }
  }

  // grab license types for given state and transform them into a lookup table indexed by 'value'
  getLicenseDataForState(state: USStateAbbreviation) {
    const data = LicenseManager.getLicenseData(state);
    return { ...data, business_license_types: _.indexBy(data.business_license_types, 'value') };
  }

  makeDocumentsArray() {
    return this.displayDocuments.map(doc => {
      const uploader = this.userMap.filter((user: User) => user.id === doc.uploaded_by)[0];
      if (uploader) {
        doc.user_uploaded = `${uploader.firstName.trim()} ${uploader.lastName.trim()}`;
      }
      return doc;
    });
  }

  handleRowClick(row) {
    this.openDocumentEmitter.emit(row);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  sortDocuments(sort) {
    if (sort) {
      this.sort = sort;
    }
    const data = this.filteredDocuments.slice();
    if (!this.sort.active || this.sort.direction === '') {
      this.sortedDocuments = data;
      return;
    }
    this.sortedDocuments = data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'requirement':
          return compare(a.name, b.name, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'reviewed':
          return compare(a.status, b.status, isAsc);
        case 'reviewed_by':
          return compare(a.reviewed_by.name, b.reviewed_by.name, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
