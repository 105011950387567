import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { ExportActivityModalBody, customModalStyles } from '../styles';

interface Props {
  type?: boolean;
  active?: boolean;
  toggleModal: () => void;
  buttonComponent?: React.ReactNode;
}

const styles = {
  ...customModalStyles,
  content: { ...customModalStyles.content, width: '456px', height: '268px' },
};

export const success = {
  header: 'Your file is on its way',
  body:
    'Your file is being prepared now, When it’s ready, a link to your file will appear in your notifications. You’ll also receive a confirmation email.',
};
export const failure = {
  header: 'Oops, something went wrong',
  body: 'Please try the export again. For help, email us at support@greencheckverified.com.',
};

export const ExportActivityResponseModal = (props: Props) => {
  useEffect(() => {
    Modal.setAppElement('#modal-root');
  }, []);

  const header = props.type ? success.header : failure.header;
  const body = props.type ? success.body : failure.body;

  return (
    <Modal style={styles} isOpen={props.active} onRequestClose={props.toggleModal}>
      <ExportActivityModalBody>
        <h2>{header}</h2>
        <p>{body}</p>

        <div className="button-container">{props.buttonComponent}</div>
      </ExportActivityModalBody>
    </Modal>
  );
};
