<div class="wrapper">
  <div class="header">
    <h2 data-cy="title">{{ section }}</h2>
    <p data-cy="subTitle">This usually takes approximately {{ estimate }} minutes</p>
  </div>
  <div class="body">
    <div class="leftContainer">
      <div class="details">
        <h5>Why do we ask this?</h5>
        <p class="text" data-cy="helper-text">{{ details }}</p>
      </div>
      <div class="buttonWrapper">
        <ng-content select="[button-container]"></ng-content>
      </div>
    </div>
    <div class="rightContainer">
      <gcv-due-diligence-requirement-list
        *ngIf="!dueDiligenceDocs"
        [helpLink]="helpLink"
        (helpClickEvent)="helpHandler()"
        [helpDescription]="helpDescription"
        [requirements]="requirements"
        [showNeedHelpLinks]="showNeedHelpLinks"
      ></gcv-due-diligence-requirement-list>
      <div class="dueDiligenceDocs" *ngIf="dueDiligenceDocs">
        <p class="description">Documents you can already expect from every cannabis business:</p>
        <div *ngFor="let category of requirementKeys" class="dueDiligenceCategory">
          <div class="title">{{ category | titlecase }}</div>
          <div class="list">
            <ul>
              <li *ngFor="let req of gcvRequirements[category]">{{ req.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
