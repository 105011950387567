import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { TemplateRef } from '@angular/core';
import { User } from '@gcv/generated-services';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirmDialog.component';
import { CreateGroupDialogComponent } from './components/user-groups/user-group/create-group-dialog/create-group-dialog.component';

export const openDialog = <T, D = any, R = any>(
  matDialog: MatDialog,
  componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
  config?: MatDialogConfig<D>
): MatDialogRef<T, R> => {
  return matDialog.open(componentOrTemplateRef, config);
};

export const openConfirmDialogForArchivingUser = <R = any>(
  matDialog: MatDialog
): MatDialogRef<ConfirmDialogComponent, R> => {
  return openDialog(matDialog, ConfirmDialogComponent, {
    data: { message: 'Are you sure you wish to archive this user?' },
    width: '360px',
  });
};

export const openCreateGroupDialog = (matDialog: MatDialog, data: { allUsers: User[]; selectedUsers: User[] }) => {
  return openDialog(matDialog, CreateGroupDialogComponent, {
    width: '660px',
    height: '700px',
    data,
  });
};

export const openFincenAllSetDialog = (matDialog: MatDialog) => {
  return openDialog(matDialog, ConfirmDialogComponent, {
    width: '400px',
    data: {
      title: 'All Set?',
      message: `You won't be able to edit a report once it's been exported, so please confirm that you're ready to file this report by clicking the button below.`,
    },
  });
};
