import { ActivityType } from '..';

export * from './ActivityReportingFI';
export * from './ActivityReportingFI.wrapper.component';

export interface ActivityReportingFIPayload {
  dispensaryIds: string[];
  startDate: string;
  endDate: string;
  activityType?: ActivityType;
}
