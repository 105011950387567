import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';
import { GetPosLocationsResponse } from '@gcv/shared';

export const dispensariesClient = (API: APIClass) => {
  return {
    getAllDispensaries: async (setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get('/gcv/dispensaries', setIsLoading);
    },

    getDispensaryById: async (dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}`, setIsLoading);
    },

    getDailySummaries: async (
      dispId: string,
      startDate: string,
      endDate: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).get(
        `/dispensaries/${dispId}/dailySummary?startDate=${startDate}&endDate=${endDate}`,
        setIsLoading
      );
    },

    getDispensaryBank: async (dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/bank`, setIsLoading);
    },

    getDeposits: async (dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/deposits`, setIsLoading);
    },

    getDeposit: async (dispId: string, depositId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/deposits/${depositId}`, setIsLoading);
    },

    getMaxDepositAggregate: async (dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/maxDeposit`, setIsLoading);
    },

    setMaxDeposit: async (dispId: string, newMaxDeposit: number, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/maxDeposit`, { newMaxDeposit }, setIsLoading);
    },

    getTransportVendorById: async (vendorId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/transportVendor/${vendorId}`, setIsLoading);
    },

    getAllDispensarySales: async (
      dispId: string,
      startDate: string,
      endDate: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).get(
        `/dispensaries/${dispId}/sales?startDate=${startDate}&endDate=${endDate}`,
        setIsLoading
      );
    },

    getDispensarySaleById: async (dispId: string, saleId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/sales/${saleId}`, setIsLoading);
    },

    getAllDispensarySalesPaginated: async (dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/sales/paginated`, setIsLoading);
    },

    getDueDiligenceTemplate: async (dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/diligence/template`, setIsLoading);
    },

    getEventByType: async (dispId: string, eventType: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/event/${eventType}`, setIsLoading);
    },

    createDispensary: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post('/dispensaries', body, setIsLoading);
    },

    getDispensaryDepositsReport: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/reporting/deposits`, body, setIsLoading);
    },

    getDispensarySalesReport: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/reporting/sales`, body, setIsLoading);
    },

    getDispensaryDailySummariesReport: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/reporting/dailySummaries`, body, setIsLoading);
    },

    makeDeposit: async (dispId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/deposits`, body, setIsLoading);
    },

    addTransportVendor: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/transportVendor`, body, setIsLoading);
    },

    uploadNewRawSalesFile: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/salesfiles`, body, setIsLoading);
    },

    trackSalesUpload: async (dispId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(
        `/document-management/dispensaries/${dispId}/track/sales/upload`,
        body,
        setIsLoading
      );
    },

    approveGcvDueDiligence: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/diligence/gcv`, body, setIsLoading);
    },

    triggerSalesIngestion: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/sales/triggerIngestion`, body, setIsLoading);
    },

    reviewDispensary: async (
      bankId: string,
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/diligence/bank/${bankId}/dispensary/${dispId}/reviewed_on`,
        body,
        setIsLoading
      );
    },

    updateDispensary: async (dispId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/dispensaries/${dispId}`, body, setIsLoading);
    },

    updateDispensarySectionDueDiligenceStatus: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/review-sections`, body, setIsLoading);
    },

    setInitialMaxDeposit: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/initialMaxDeposit`, body, setIsLoading);
    },

    setPosConfig: async (dispId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/posConfig`, body, setIsLoading);
    },

    favoriteMethodOfTransport: async (
      dispId: string,
      vendorType: string,
      vendorId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(
        `/dispensaries/${dispId}/transportVendor/${vendorType}/${vendorId}/favorite`,
        body,
        setIsLoading
      );
    },

    updateTransportVendor: async (
      dispId: string,
      vendorType: string,
      vendorId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(
        `/dispensaries/${dispId}/transportVendor/${vendorType}/${vendorId}`,
        body,
        setIsLoading
      );
    },

    enqueueFetchDispensarySalesJob: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/sales/sync`, body, setIsLoading);
    },

    updateGcvDueDiligenceStatus: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/gcv-due-diligence-status`, body, setIsLoading);
    },

    updateBankDueDiligenceStatusForDispensary: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/bank-due-diligence-status`, body, setIsLoading);
    },

    upsertBankCustomFieldsResponses: async (
      dispId: string,
      formId: string,
      responses: { [id: string]: string },
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(
        `/dispensaries/${dispId}/bank-custom-fields`,
        { responses, formId },
        setIsLoading
      );
    },

    updateDispensarySale: async (
      dispId: string,
      saleId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).patch(`/dispensaries/${dispId}/sales/${saleId}`, body, setIsLoading);
    },

    deleteDispensarySale: async (
      dispId: string,
      saleId: string,
      secret: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).delete(`/dispensaries/${dispId}/sales/${saleId}?secret=${secret}`, setIsLoading);
    },

    deleteDispensary: async (dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/dispensaries/${dispId}`, setIsLoading);
    },

    removeTransportMethod: async (
      dispId: string,
      vendorType: string,
      vendorId: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).delete(
        `/dispensaries/${dispId}/transportVendor/${vendorType}/${vendorId}`,
        setIsLoading
      );
    },

    updateDispensaryBusinessDetails: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/business-details`, body, setIsLoading);
    },

    updateDispensaryOperationalDetails: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/operational-details`, body, setIsLoading);
    },

    updateDispensaryAccountOwnerSectionComplete: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/account-owners-section-complete`, body, setIsLoading);
    },

    updateDispensaryRequirement: async (
      dispId: string,
      requirementId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/requirements/${requirementId}`, body, setIsLoading);
    },

    inviteDispensaryUser: async (
      dispId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/dispensaries/${dispId}/users`, body, setIsLoading);
    },

    syncSales: async (dispId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/sales/sync`, body, setIsLoading);
    },

    checkHasUploadedSales: async (dispId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/dispensaries/${dispId}/hasUploadedSales`, setIsLoading);
    },

    getPosLocations: async (
      dispId: string,
      setIsLoading: (isLoading: boolean) => void
    ): Promise<GetPosLocationsResponse[]> => {
      return await apiClient(API).get(`/dispensaries/${dispId}/posConfig/locations`, setIsLoading);
    },

    putPosLocation: async (dispId: string, location_id: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/dispensaries/${dispId}/posConfig/locations`, { location_id }, setIsLoading);
    },
  };
};
