import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { TransportVendor } from '@gcv/shared';
import { select, Store } from '@ngrx/store';
import { VendorState } from './vendors.reducer';
import { selectVendorEntities } from './vendors.selectors';
import {
  AssignVendorToAllDispensaries,
  AssignVendorToSingleDispensary,
  CreateBankVendor,
  CreateDispensaryVendor,
  DeleteBankVendor,
  FavoriteDispensaryVendorByTypeAndId,
  LoadVendorById,
  ResetVendors,
  UnassignVendorToSingleDispensary,
  UpdateBankVendor,
  UpdateDispensaryVendor,
} from './vendors.actions';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface IVendorsFacade {
  getVendorById(vendorId: string): Observable<TransportVendor>;

  postDispensaryVendor(vendor: Partial<TransportVendor>, dispensaryId: string): void;

  putDispensaryVendor(
    vendorId: string,
    vendorType: 'user' | 'vendor',
    dispensaryId: string,
    vendor: TransportVendor
  ): void;

  postBankVendor(bankId: string, vendor: Partial<TransportVendor>, dispensaryId?: string): void;

  putBankVendor(vendorId: string, bankId: string, vendor: TransportVendor): void;

  deleteBankVendor(bankId: string, vendorId: string): void;

  favoriteDispensaryVendorByTypeAndId(vendorId: string, dispensaryId: string, type: 'user' | 'vendor'): void;

  assignVendorToSingleDispensary(bankId: string, vendorId: string, dispensaryId: string): void;

  unassignVendorToSingleDispensary(bankId: string, vendorId: string, dispensaryId: string): void;

  assignVendorToAllDispensaries(bankId: string, vendorId: string): void;

  resetVendors(): void;

  selectVendorById(vendorId): Observable<TransportVendor>;
}

@Injectable({
  providedIn: 'root',
})
export class VendorsFacade implements IVendorsFacade {
  vendors$: Observable<Dictionary<TransportVendor>>;

  constructor(private store: Store<VendorState>) {
    this.vendors$ = store.pipe(select(selectVendorEntities));
  }

  getVendorById(vendorId: string) {
    this.store.dispatch(new LoadVendorById({ vendorId }));
    return this.vendors$.pipe(map(vendorDictionary => vendorDictionary[vendorId]));
  }

  postDispensaryVendor(vendor: Partial<TransportVendor>, dispensaryId: string) {
    this.store.dispatch(new CreateDispensaryVendor({ dispensaryId, vendor }));
  }

  putDispensaryVendor(vendorId: string, vendorType: 'user' | 'vendor', dispensaryId: string, vendor: TransportVendor) {
    this.store.dispatch(new UpdateDispensaryVendor({ vendor, vendorType, vendorId, dispensaryId }));
  }

  postBankVendor(bankId: string, vendor: Partial<TransportVendor>, dispensaryId?: string) {
    this.store.dispatch(new CreateBankVendor({ bankId, vendor, dispensaryId }));
  }

  putBankVendor(vendorId: string, bankId: string, vendor: TransportVendor) {
    this.store.dispatch(new UpdateBankVendor({ vendor, vendorId, bankId }));
  }

  deleteBankVendor(bankId: string, vendorId: string) {
    this.store.dispatch(new DeleteBankVendor({ bankId, vendorId }));
  }

  favoriteDispensaryVendorByTypeAndId(vendorId: string, dispensaryId: string, type: 'user' | 'vendor') {
    this.store.dispatch(new FavoriteDispensaryVendorByTypeAndId({ vendorId, dispensaryId, type }));
  }

  assignVendorToSingleDispensary(bankId: string, vendorId: string, dispensaryId: string) {
    this.store.dispatch(new AssignVendorToSingleDispensary({ bankId, vendorId, dispensaryId }));
  }

  unassignVendorToSingleDispensary(bankId: string, vendorId: string, dispensaryId: string) {
    this.store.dispatch(new UnassignVendorToSingleDispensary({ bankId, vendorId, dispensaryId }));
  }

  assignVendorToAllDispensaries(bankId: string, vendorId: string) {
    this.store.dispatch(new AssignVendorToAllDispensaries({ bankId, vendorId }));
  }

  resetVendors() {
    this.store.dispatch(new ResetVendors());
  }

  selectVendorById(vendorId) {
    return this.vendors$.pipe(map(vendorDictionary => vendorDictionary[vendorId]));
  }
}
