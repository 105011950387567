import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';
import { ViewMoreIcon } from '../../../icons/ViewMoreIcon';

import { DrawerText, DrawerImage, DrawerList } from '../styles';
import { VerticalCenter } from '../../SalesAndDeposits/Deposits/depositDetailDrawer/styles';

interface Props {
  form: any;
}

export const FlowHub = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To configure this integration, you’ll need your Flowhub API credentials. To request your API key, sign and send
        the consent form below to Flowhub.
      </DrawerText>
      <DrawerText>
        <a
          target="_blank"
          href="https://f.hubspotusercontent20.net/hubfs/5237927/Flowhub-API-Consent-Form.pdf"
          style={{ display: 'flex' }}
        >
          <VerticalCenter> Download consent form</VerticalCenter>
          <VerticalCenter style={{ marginLeft: '5px' }}>
            <ViewMoreIcon></ViewMoreIcon>
          </VerticalCenter>
        </a>
      </DrawerText>
      <DrawerText>
        Send the following email to{' '}
        <a rel="noopener noreferrer" target="_blank" href="mailto:api@flowhub.com">
          api@flowhub.com
        </a>{' '}
        along with the signed consent form:
      </DrawerText>
      <DrawerText>
        <b>
          Hello, <br />
          <br />I need some assistance. I would like to integrate our system with the Green Check system. Please help me
          locate my API key so that I can provide this to the Green Check team to set up the integration.
        </b>
      </DrawerText>
      <DrawerText>
        Once Flowhub provides you with your API key, enter it below. Then, connect the integration.
      </DrawerText>
      <GcvInputForm
        {...form}
        name="key"
        label="API Key"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="clientId"
        label="Client ID"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <DrawerText style={{ marginTop: '1rem' }}>
        <b>NOTE: These credentials are unique to Green Check. Please do not share them anywhere else.</b>
      </DrawerText>
    </>
  );
};
