import * as fromFincen from './fincen.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectFincenState = createFeatureSelector<fromFincen.FincenState>('fincen');

export const selectFincenIds = createSelector(
  selectFincenState,
  fromFincen.selectFincenIds
);

export const selectFincenEntities = createSelector(
  selectFincenState,
  fromFincen.selectFincenEntities
);

export const selectFincenAll = createSelector(
  selectFincenState,
  fromFincen.selectAllFincen
);

export const selectFincenReportById = createSelector(
  selectFincenEntities,
  (report, props) => report[props.fincenId]
);

export const selectFincenByDispensaryId = createSelector(
  selectFincenAll,
  (reports, props) => reports.filter(report => report.dispensary_id === props.dispensaryId)
);

export const selectFincenByBankId = createSelector(
  selectFincenAll,
  (reports, props) => reports.filter(report => report.bank_id === props.bankId)
);

export const selectFincenByTypeAndStatus = createSelector(
  selectFincenAll,
  (reports, props) => reports.filter(report => report.report_type === props.type && report.status === props.status)
);
