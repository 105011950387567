import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { countries, entitytypes, primaryFederalRegulators, states } from '../../constants';

@Component({
  selector: 'gcv-editable-card-view-id',
  templateUrl: './editable-card-view-id.component.html',
  styleUrls: ['./editable-card-view-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableCardViewIdComponent implements OnInit {
  editable = false;
  @Input()
  permissions = [''];
  @Input()
  showEdit = false;
  @Output()
  saveContent = new EventEmitter();
  @Output()
  viewIdEmitter = new EventEmitter();
  @Input()
  customStyle: any;
  @Input()
  data: FormGroup;
  @Input()
  title: string;
  @Input()
  formKey: any;
  @Input()
  errors: any = [];
  @Input()
  validators: any = [];
  @Input()
  customStyleEdit: any;
  @Input()
  addressContent = true;
  states = states;
  countries = countries;
  primaryReg = primaryFederalRegulators;
  entityType = entitytypes;
  entityNameMap = {
    sole: 'Sole Proprietorship',
    partnership: 'General Partnership',
    corporation: 'Corporation',
    llc: 'Limited Liability Company',
  };
  @Output()
  blurCalled = new EventEmitter();
  @Output()
  focusCalled = new EventEmitter();

  tempData: any;

  constructor() {}

  ngOnInit() {
    this.resetFormGroup();
  }

  edit() {
    this.editable = true;
    this.resetFormGroup();
  }

  cancel() {
    this.editable = false;
    this.resetFormGroup();
  }

  resetFormGroup() {
    const data = this.data.getRawValue();
    const controls = {};
    for (const key in data) {
      if (key) {
        const validatorArray = [];
        const keyIndex = this.formKey.findIndex(j => j.value === key);
        if (keyIndex >= 0 && this.formKey[keyIndex].validators) {
          const validators = this.formKey[keyIndex].validators;
          validators.forEach(validator => {
            if (validator.type === 'pattern') {
              validatorArray.push(Validators.pattern(validator.match));
            } else if (validator.type === 'required') {
              validatorArray.push(Validators.required);
            } else if (validator.type === 'min') {
              validatorArray.push(Validators.min(validator.min));
            } else if (validator.type === 'max') {
              validatorArray.push(Validators.max(validator.max));
            } else if (validator.type === 'minLength') {
              validatorArray.push(Validators.minLength(validator.minLength));
            } else if (validator.type === 'maxLength') {
              validatorArray.push(Validators.maxLength(validator.maxLength));
            }
          });
        }
        if (keyIndex >= 0 && this.formKey[keyIndex].required) {
          validatorArray.push(Validators.required);
        }
        validatorArray.length > 0
          ? (controls[key] = new FormControl(data[key], validatorArray))
          : (controls[key] = new FormControl(data[key]));
        if (key === 'posName') {
          controls[key] = new FormControl(
            {
              value: controls[key].value,
              disabled: true,
            },
            Validators.required
          );
        }
      }
    }
    this.tempData = new FormGroup(controls);
  }

  save() {
    for (const key in this.tempData) {
      if (key) {
        this.data = this.tempData;
      }
    }
    this.saveContent.emit(this.data.getRawValue());
    this.editable = false;
  }

  handleInput(e) {
    this.tempData.controls[e.property].setValue(e.value);
  }

  selectionChanged(e, property) {
    this.tempData[property] = e.value[0];
  }

  viewIdClicked() {
    this.viewIdEmitter.emit();
  }
}
