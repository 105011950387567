import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';

import {
  AccountReviewStatus,
  Bank,
  Dispensary,
  DispensaryAccountReviewTableData,
  OrganizationRoleResolver,
  ReviewPeriodType,
  User,
} from '@gcv/shared';
import { api } from '../../../api';
import { GcvButton, GcvCheckbox, GcvDataTable, GcvLoading, GcvModal, GcvSearchInput, GcvZeroState } from '../../../lib';
import { CardData, CardIcon, CardIconDivider, CardLabel, Cards, Table, TableIconLine } from '../styles';
import { $grey13, $grey4, getReviewPeriodFriendlyName, formatMoney } from 'libs/react-ui/src/util';
import { GcvDropButton } from 'libs/react-ui/src/lib/GcvDropButton/GcvDropButton';
import { ModalTextSmall, ModalTextLarge } from 'libs/react-ui/src/components/fincen/Reports/styles';
import ReactTooltip from 'react-tooltip';

interface Props {
  bank: Bank;
  user: User;
  dispensaries: { [id: string]: Dispensary };
  updatedReviewId?: string;
  createdReviewId?: string;
}

const GcvTooltip = withStyles({
  tooltip: {
    color: '#ffffff',
    backgroundColor: '#000000',
    fontSize: '13px',
  },
  arrow: {
    '&::before': {
      color: '#000000',
    },
  },
})(Tooltip);

const OpenReviewCard = React.memo(
  (props: {
    filteredReview: DispensaryAccountReviewTableData;
    dispensaries: {
      [id: string]: Dispensary;
    };
  }) => {
    const history = useHistory();
    const navigate = () => history.push(`/secure/bank/account-monitoring/review/${props.filteredReview.id}/overview`);

    return (
      <Card
        style={{
          cursor: props.filteredReview.status != AccountReviewStatus.Processing ? 'pointer' : null,
          boxShadow: 'none',
          border: '1px solid #ebeef7',
        }}
        onClick={props.filteredReview.status != AccountReviewStatus.Processing ? navigate : null}
        data-cy="open-review-card"
      >
        <CardContent>
          <div style={{ display: 'flex', padding: '1rem' }}>
            <div style={{ width: '23%' }} data-cy="account-name">
              <CardData
                style={{
                  textAlign: 'left',
                  fontWeight: 400,
                  color: '#000000',
                  lineHeight: '24px',
                }}
              >
                <GcvTooltip title={props.filteredReview.dispensary_name} arrow>
                  <Typography
                    noWrap
                    style={{
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    {props.filteredReview.dispensary_name}
                  </Typography>
                </GcvTooltip>
              </CardData>
              <CardLabel
                style={{ color: '#00BC66', textAlign: 'left', fontWeight: 700, lineHeight: '15px' }}
                data-cy="review-period-friendly"
              >
                {props.filteredReview.review_period_type === ReviewPeriodType.Custom
                  ? 'Custom Review Period'
                  : getReviewPeriodFriendlyName(props.filteredReview)}
              </CardLabel>
            </div>
            <div style={{ width: '15.4%' }} data-cy="total-deposits">
              <CardLabel>Total Deposits</CardLabel>
              <CardData>{formatMoney(props.filteredReview.totals_deposits_amount)}</CardData>
            </div>
            <div style={{ width: '15.4%' }} data-cy="total-sales">
              <CardLabel>Total Sales</CardLabel>
              <CardData>{formatMoney(props.filteredReview.totals_sales_amount)}</CardData>
            </div>
            <div style={{ width: '15.4%' }} data-cy="total-withdrawals">
              <CardLabel>Total Withdrawals</CardLabel>
              <CardData>{formatMoney(props.filteredReview.totals_withdrawals_amount)}</CardData>
            </div>
            <div style={{ width: '15.4%' }} data-cy="status">
              <CardLabel>Status</CardLabel>
              {props.filteredReview.status === 'processing' && (
                <CardData style={{ color: '#6896FB' }}>Processing</CardData>
              )}
              {props.filteredReview.status === 'new' && <CardData style={{ color: '#6896FB' }}>New</CardData>}
              {props.filteredReview.status === 'in_progress' && (
                <CardData style={{ color: '#00BC66' }}>In Progress</CardData>
              )}
            </div>
            <div style={{ width: '15.4%' }} data-cy="review-period">
              <CardLabel>Review Period</CardLabel>
              <CardData>{props.filteredReview.review_period_timeframe}</CardData>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
);

export const OpenReviewsTab = (props: Props) => {
  const apiClient = api();
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [filteredReviews, setFilteredReviews] = useState<DispensaryAccountReviewTableData[]>([]);
  const [searchableReviews, setSearchableReviews] = useState<DispensaryAccountReviewTableData[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<DispensaryAccountReviewTableData[]>([]);
  const [tableView, setTableView] = useState(false);

  const orgResolver = new OrganizationRoleResolver();
  const userCanDeleteReviews = props.bank
    ? orgResolver.userCanDoAction(props.bank.groups, props.user, 'account_review_edit')
    : false;

  useEffect(() => {
    apiClient.accountMonitoring.getReviews(props.bank.id, 'open', setIsLoading).then(reviewList => {
      setFilteredReviews(reviewList);
      setSearchableReviews(reviewList);

      if (reviewList.length > 6) {
        setTableView(true);
      }
    });
  }, [props.bank]);

  useEffect(() => {
    if (!isLoading) {
      setFilteredTransactionsFromQuery();
    }
  }, [query]);

  //If routed here with a dispensaryName, auto filter for that dispensary
  const urlParams = new URLSearchParams(window.location.search);
  const dispName = urlParams.get('dispensaryName');

  useEffect(() => {
    if (dispName && !initialized && !isLoading && searchableReviews.length) {
      setQuery(dispName);
      setInitialized(true);
    }
  }, [dispName, isLoading, searchableReviews]);

  useEffect(() => {
    if (props.createdReviewId) {
      apiClient.accountMonitoring
        .getMinifiedReview(props.createdReviewId, () => {})
        .then(review => {
          const updatedReviews = [...filteredReviews];
          updatedReviews.push(review);
          setFilteredReviews(updatedReviews);
        });
    }
  }, [props.createdReviewId]);

  useEffect(() => {
    if (props.updatedReviewId) {
      apiClient.accountMonitoring
        .getMinifiedReview(props.updatedReviewId, () => {})
        .then(review => {
          const updatedReviews = [...filteredReviews];
          const existingReview = updatedReviews.findIndex(x => x.id === props.updatedReviewId);
          updatedReviews.splice(existingReview, 1, review);
          setFilteredReviews(updatedReviews);
        });
    }
  }, [props.updatedReviewId]);

  const columns = [
    {
      name: 'Account',
      selector: 'account',
      sortable: true,
      left: true,
    },
    {
      name: 'Total Deposits',
      selector: 'totalDeposits',
      sortable: true,
      left: true,
      format: r => formatMoney(r['totalDeposits']),
    },
    {
      name: 'Total Sales',
      selector: 'totalSales',
      sortable: true,
      left: true,
      format: r => formatMoney(r['totalSales']),
    },
    {
      name: 'Total Withdrawals',
      selector: 'totalWithdrawals',
      sortable: true,
      left: true,
      format: r => formatMoney(r['totalWithdrawals']),
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      left: true,
      format: r =>
        r['status'] === 'new'
          ? 'New'
          : r['status'] === 'in_progress'
          ? 'In Progress'
          : r['status'] === 'processing'
          ? 'Processing'
          : 'Complete',
    },
    {
      name: 'Review Period',
      selector: 'reviewPeriod',
      grow: 1.1,
      sortable: false,
      left: true,
    },
  ];

  const tableData = filteredReviews.map(review => {
    return {
      id: review.id,
      account: review.dispensary_name,
      totalDeposits: review.totals_deposits_amount,
      totalWithdrawals: review.totals_withdrawals_amount,
      totalSales: review.totals_sales_amount,
      status: review.status,
      reviewPeriod: review.review_period_timeframe,
    };
  });

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRowsData(selectedRows);
  };

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    const toolTip = 'You do not have permission to delete reviews';
    return !userCanDeleteReviews ? (
      <label data-tip={toolTip}>
        <GcvCheckbox ref={ref} {...props} />
        <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
      </label>
    ) : (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  const deleteButton = {
    title: 'Delete',
    onClick: () => setModalOpen(true),
    disabled: selectedRowsData.length === 0 && userCanDeleteReviews,
  };

  const batchDeleteReviews = () => {
    const reviewIds = selectedRowsData.map(r => r.id);
    apiClient.accountMonitoring.batchDeleteReviews(props.bank.id, reviewIds, setIsModalLoading).then(() => {
      setModalOpen(false);
      setSearchableReviews(searchableReviews.filter(r => !reviewIds.includes(r.id)));
      setFilteredReviews(filteredReviews.filter(r => !reviewIds.includes(r.id)));
      setSelectedRowsData([]);
    });
  };

  const setFilteredTransactionsFromQuery = () => {
    const filteredReviews = query
      ? searchableReviews.filter(r => r.dispensary_name.toLowerCase().includes(query.toLowerCase()))
      : searchableReviews;
    setFilteredReviews(filteredReviews);
  };

  const handleRowClick = row => {
    history.push(`/secure/bank/account-monitoring/review/${row.id}/overview`);
  };

  if (isLoading) {
    return <GcvLoading />;
  }

  return (
    <>
      <GcvModal
        modalOpen={modalOpen}
        toggleModal={() => setModalOpen(!modalOpen)}
        backButton={<GcvButton onClick={() => setModalOpen(false)}>Cancel</GcvButton>}
        continueButton={
          <GcvButton danger={true} onClick={batchDeleteReviews}>
            Delete
          </GcvButton>
        }
        title="Are you sure you want to delete these reviews?"
        loading={isModalLoading}
      >
        {
          <>
            <ModalTextSmall>
              Deleted reviews will be removed from this view and permanently deleted 30 days after. Permanently deleted
              reviews can not be recovered.
            </ModalTextSmall>
            <ModalTextLarge>Are you sure you want to delete the selected reviews?</ModalTextLarge>
          </>
        }
      </GcvModal>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={4} md={5}></Grid>
        <Grid item xs={9} sm={8} md={7} style={{ textAlign: 'right' }}>
          <Grid container spacing={4}>
            <Grid item xs={!tableView ? 6 : 2}></Grid>
            <Grid item xs={4}>
              <GcvSearchInput
                label="Search Accounts"
                placeholder="Search Accounts"
                id="searchAccounts"
                value={query}
                style={{ height: 'auto', marginTop: '0px', textAlign: 'left' }}
                onChange={onSearchChange}
                data-cy="search-accounts"
              />
            </Grid>
            {tableView ? (
              <Grid item xs={3}>
                <GcvDropButton
                  title={'Actions'}
                  secondary={true}
                  style={{
                    margin: '0',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '12rem',
                    boxSizing: 'border-box',
                  }}
                  buttons={[deleteButton]}
                ></GcvDropButton>
              </Grid>
            ) : null}
            <Grid item xs={!tableView ? 2 : 3}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Cards
                  onClick={() => {
                    setTableView(false);
                  }}
                  style={{ backgroundColor: !tableView ? $grey13 : $grey4 }}
                  data-cy="accounts-cards-toggle"
                >
                  <CardIconDivider>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                  </CardIconDivider>
                  <CardIconDivider>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                  </CardIconDivider>
                  <CardIconDivider>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                    <CardIcon></CardIcon>
                  </CardIconDivider>
                </Cards>
                <Table
                  onClick={() => {
                    setTableView(true);
                  }}
                  style={{ backgroundColor: tableView ? $grey13 : $grey4 }}
                  data-cy="accounts-table-toggle"
                >
                  <TableIconLine></TableIconLine>
                  <TableIconLine></TableIconLine>
                  <TableIconLine></TableIconLine>
                </Table>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {!tableView && (
            <>
              {filteredReviews.length > 0 ? (
                <Grid container spacing={3}>
                  {filteredReviews.map(filteredReview => {
                    return (
                      <Grid item key={filteredReview.id} xs={12}>
                        <OpenReviewCard filteredReview={filteredReview} dispensaries={props.dispensaries} />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : query ? (
                <GcvZeroState
                  type="bankDash"
                  headerText={'There are no open reviews that match your search'}
                  subText={'Please try changing your search to show more reviews'}
                />
              ) : (
                <GcvZeroState
                  type="bankDash"
                  headerText={'There are no open reviews'}
                  subText={'New reviews will be displayed here as soon as they are created'}
                />
              )}
            </>
          )}

          {tableView && (
            <GcvDataTable
              data={filteredReviews ? tableData : []}
              columns={columns}
              onRowClicked={row => {
                row.status === AccountReviewStatus.Processing ? null : handleRowClick(row);
              }}
              defaultSortField={'name'}
              defaultSortAsc={true}
              keyField="id"
              noDataComponent={
                query ? (
                  <GcvZeroState
                    type="bankDash"
                    headerText={'There are no open reviews that match your search'}
                    subText={'Please try changing your search to show more reviews'}
                  />
                ) : (
                  <GcvZeroState
                    type="bankDash"
                    headerText={'There are no open reviews'}
                    subText={'New reviews will be displayed here as soon as they are created'}
                  />
                )
              }
              selectableRows={true}
              onSelectedRowsChange={onSelectedRowsChange}
              selectableRowsComponent={renderSelectComponent}
              subHeaderAlign="left"
              paginationPerPage={10}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
