import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@gcv/shared';
import { idtypes } from '../../constants';

@Component({
  selector: 'gcv-review-account-owner-card',
  templateUrl: './review-account-owner-card.component.html',
  styleUrls: ['./review-account-owner-card.component.scss'],
})
export class ReviewAccountOwnerCardComponent implements OnInit {
  @Input() user: User;
  @Input() loading: boolean;
  @Output() reviewButtonClicked = new EventEmitter<User>();

  constructor() {}

  ngOnInit() {}

  handleReviewButtonClicked() {
    this.reviewButtonClicked.emit(this.user);
  }

  getIdType(idType) {
    return idtypes.find(type => type.value === idType).viewValue
      ? idtypes.find(type => type.value === idType).viewValue
      : 'Not Provided';
  }
}
