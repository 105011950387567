import { ReactWrapperComponent, WrapperProps, RequirementsTable } from '@user-interface/react-ui';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Dispensary, DueDiligenceTemplate } from '@gcv/shared';
import { DispensaryFacade } from '@user-interface/gcv-state';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'requirements-table',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleData"></app-react-wrapper>',
})
export class RequirementsTableComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Input() dispensaryId: string;
  @Input() template: DueDiligenceTemplate;
  @Output() uploadDocument = new EventEmitter<any>();
  @Output() updateRequirementsData = new EventEmitter<any>();
  companyType = this.router.url.includes('bank') ? 'bank' : 'dispensary';
  component = RequirementsTable;

  constructor(private router: Router, private dispFacade: DispensaryFacade) {
    super();
    this.handleData = this.handleData.bind(this);
  }

  ngOnInit() {
    this.dispFacade
      .selectDispensaryById(this.dispensaryId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dispensary: Dispensary) => {
        if (dispensary) {
          this.props = { dispensaryId: this.dispensaryId, dispensary, template: this.template };
        }
      });
  }

  handleData(type, reqData, dispensaryId) {
    if (type === 'rowClick') {
      this.handleClick(reqData, dispensaryId);
    } else if (type === 'updateReqs') {
      this.updateRequirementsData.emit(reqData);
    }
  }

  handleClick(reqId, dispensaryId) {
    const data: NavigationExtras = {
      queryParams: {
        account: dispensaryId,
        req: reqId,
      },
    };
    if (this.companyType === 'bank') {
      this.router.navigate(['/secure/bank/account/requirement'], data).then(() => {});
    } else if (this.companyType === 'dispensary')
      this.router.navigate(['/secure/dispensary/company/requirement'], data).then(() => {});
  }
}
