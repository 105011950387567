import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredGenericAlphanumericEPatternValidator } from '../../../../../../../../apps/user-interface/src/validators/commonValidators';

@Component({
  selector: 'gcv-create-group-dialog',
  templateUrl: './create-group-dialog.component.html',
  styleUrls: ['./create-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateGroupDialogComponent {
  groupDetails: FormGroup;
  filterText = '';
  users = [];
  filteredUsers = [];
  count = 0;
  selectedMap = {};

  constructor(
    private matDialogRef: MatDialogRef<CreateGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.allUsers && data.allUsers.length > 0) {
      this.users = data.allUsers;
      this.filteredUsers = data.allUsers;

      if (data.selectedUsers && data.selectedUsers.length > 0) {
        data.selectedUsers.forEach(user => {
          this.selectedMap[user.id] = user;
        });
      }
    } else {
      this.cancel();
    }
    this.groupDetails = new FormGroup({
      name: new FormControl('', requiredGenericAlphanumericEPatternValidator),
    });
  }

  addUser(selectedUser, i) {
    if (this.selectedMap[this.filteredUsers[i].id]) {
      delete this.selectedMap[this.filteredUsers[i].id];
    } else {
      this.selectedMap[this.filteredUsers[i].id] = this.filteredUsers[i];
    }
  }

  selectedCount() {
    return Object.keys(this.selectedMap).length;
  }

  filterItems() {
    if (this.filterText.length === 0) {
      this.filteredUsers = this.users;
    }
    this.filteredUsers = this.users.filter(user => {
      const filterLower = this.filterText.toLowerCase();
      if (user.firstName.toLowerCase().includes(filterLower) || user.lastName.toLowerCase().includes(filterLower)) {
        return user;
      }
    });
  }

  handleSaveGroup() {
    this.matDialogRef.close({
      data: {
        ...this.groupDetails.getRawValue(),
        users: Object.values(this.selectedMap),
      },
    });
  }

  cancel() {
    this.matDialogRef.close();
  }

  formatRole(role) {
    if (role === 'dispensary_admin' || role === 'bank_admin') {
      return 'Admin';
    } else if (role === 'dispensary_user' || role === 'bank_user') {
      return 'Standard';
    }
    return 'Standard';
  }
}
