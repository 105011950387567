import * as Rollbar from 'rollbar';
import { Environment } from '../../environments/env-interface';
import { environment } from '../../environments/environment';

const rollbarConfig = {
  accessToken: '31e48d6526254d2089099404af72d635',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: { environment: environment.env, person: {} },
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export function updateConfig(data: { userId: string; username: string; email: string; env: Environment['env'] }) {
  rollbarConfig['payload'] = {
    person: {
      id: data.userId,
      username: data.username,
      email: data.email,
    },
    environment: data.env,
  };
}
