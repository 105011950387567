import { ReactWrapperComponent, WrapperProps, PosConfigs } from '@user-interface/react-ui';
import { Router } from '@angular/router';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DispensaryFacade, BankFacade, StaffFacade } from '@user-interface/gcv-state';
import { Bank, Dispensary } from '@gcv/shared';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pos-configs',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></app-react-wrapper>',
})
export class PosConfigsComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = PosConfigs;
  dispensary: Dispensary;

  constructor(private dispFacade: DispensaryFacade, private staffFacade: StaffFacade, private router: Router) {
    super();
    this.handleEmitData = this.handleEmitData.bind(this);
  }

  ngOnInit() {
    this.setUpStreams();
  }

  setUpStreams() {
    this.dispFacade
      .selectCurrentDispensary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(dispensary => {
        this.dispensary = dispensary;
        this.props = {
          ...this.props,
          dispensary,
        };
      });
    this.staffFacade
      .selectStaffDictionary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userMap => {
        this.props = {
          ...this.props,
          userMap,
        };
      });
  }

  handleEmitData(type) {
    if (type === 'getDispensary') {
      this.dispFacade.getDispensarybyId(this.dispensary.id);
    } else if (type === 'reroute') {
      this.router.navigate(['/secure/dispensary/company/overview']).then(() => {
        this.dispFacade.getDispensarybyId(this.dispensary.id);
      });
    }
  }
}
