import styled from 'styled-components';
import { $primary, $grey2, $grey3 } from '../../util/styleVariables';

interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
}

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const StyledCheckbox = styled.div<CheckboxProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? $primary : '#fff')};
  border-radius: 2px;
  transition: all 150ms;
  border: 1px solid ${props => (props.disabled ? $grey3 : $grey2)};

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
