import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Dispensary, DueDiligenceTemplate, DocumentChecker } from '@gcv/shared';

import { GcvPage, GcvButton, GcvLoading, GcvContent, GcvCard } from '../../lib';
import { DynamicUi } from 'libs/react-ui/src/shared/DynamicUiGenerator/ui-factory';
import { api } from 'libs/react-ui/src/api';
import { VerticalCenter, FlexBox } from '../../styles/theme';

interface Props {
  dispensary?: Dispensary;
  bankTemplate: DueDiligenceTemplate;
  emitBack: () => void;
  emitNext: () => void;
  emitData: (disp?) => void;
}

export const AdditionalInformation = ({ dispensary, bankTemplate, emitBack, emitNext, emitData }: Props) => {
  const uiData = dispensary?.due_diligence?.bank_custom_fields?.responses
    ? dispensary.due_diligence.bank_custom_fields.responses
    : {};
  const uiJson = bankTemplate?.custom_fields?.template_json ? bankTemplate.custom_fields.template_json : '';
  const apiClient = api();
  const [updatedDispensary, setUpdatedDispensary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAdditionalInformationFulfilled, setIsAdditionalInformationFulfilled] = useState(false);

  const onBack = async res => {
    emitBack();
  };

  const onNext = async res => {
    emitNext();
  };

  useEffect(() => {
    //when component unmounts, trigger a dispensary update in the angular store
    return () => {
      emitData(updatedDispensary);
    };
  }, [updatedDispensary]);

  useEffect(() => {
    if (dispensary && bankTemplate) {
      checkFulfillmentStatus(dispensary);
    }
  }, [dispensary, bankTemplate]);

  const checkFulfillmentStatus = disp => {
    const fullfillmentStatus = DocumentChecker.getDispensaryDueDiligenceFulfillment(disp, bankTemplate);
    setIsAdditionalInformationFulfilled(fullfillmentStatus.fulfillmentMap.additional_information.complete);
  };

  const onSubmit = async (res): Promise<Dispensary> => {
    const updatedDispensary = await apiClient.dispensaries.upsertBankCustomFieldsResponses(
      dispensary.id,
      bankTemplate.id,
      res,
      setLoading
    );

    //cache the updated dispensary
    setUpdatedDispensary(updatedDispensary);

    checkFulfillmentStatus(updatedDispensary);

    return updatedDispensary;
  };

  return (
    <>
      <GcvPage
        header={
          <>
            <GcvContent type="h1" content="Additional Information" />
            <GcvContent
              type="p2"
              style={{ marginTop: '1rem' }}
              content="Please provide the additional information requested below. Please contact your financial institution if you have questions about the information in this section."
            />
            <div style={{ margin: '2rem 0', borderBottom: '1px solid #E0E5F0' }}></div>
          </>
        }
        body={
          <FlexBox style={{ justifyContent: 'space-between', flexDirection: 'column' }}>
            <DynamicUi
              uiData={JSON.stringify(uiData)}
              uiJson={uiJson}
              saveData={onSubmit}
              loading={loading}
            ></DynamicUi>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
              <>
                <GcvButton tertiary onClick={onBack}>
                  Back
                </GcvButton>

                <GcvButton primary={true} disabled={!isAdditionalInformationFulfilled} onClick={onNext}>
                  Continue to next section
                </GcvButton>
              </>
            </div>
          </FlexBox>
        }
      />
    </>
  );
};
