import { User } from '@gcv/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserActionTypes } from './user.actions';

// 01 Defined Interfaces for State Slices

export interface UserState extends EntityState<User> {
  kbaQuestions: any[];
}

// 02 Create Entity Adapters for Each Slice
export const adapterUser: EntityAdapter<User> = createEntityAdapter<User>();

// 03 Defined the Initial State for Each Slice
export const initialUserState = adapterUser.getInitialState({ kbaQuestions: null });

// 04 Set up reducer
export function userReducer(state = initialUserState, action): UserState {
  switch (action.type) {
    case UserActionTypes.UserLoaded:
      return adapterUser.addOne(action.payload, state);
    case UserActionTypes.UserUpdated:
      return adapterUser.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case UserActionTypes.UserKbaAnswersVerified:
      return adapterUser.updateOne({ id: action.payload.id, changes: action.payload }, state);
    case UserActionTypes.UserKbaLoaded:
      return {
        ...state,
        kbaQuestions: action.payload,
      };
    case UserActionTypes.ResetUserKba:
      return {
        ...state,
        kbaQuestions: [],
      };
    case UserActionTypes.ResetUserState:
      return initialUserState;
    case UserActionTypes.UserDeleted:
      return adapterUser.removeOne(action.payload.userId, state);
    default:
      return state;
  }
}

export const {
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUsers,
} = adapterUser.getSelectors();
