import { Validators, ValidatorFn, AbstractControl } from '@angular/forms';
// Generic alphanumeric A: Also accepts spaces, commas, slashes, apostrophes, periods, ampersands, dashes, and pound characters, from 2-50 in length
// Fields: Addresses
const addressPattern = "[a-zA-Z 0-9 ,'./#&-]{2,50}";
const addressPatternValidator = Validators.pattern(addressPattern);
const requiredAddressPatternValidator = [Validators.required, addressPatternValidator];

// Generic alphanumeric B: Also accepts spaces, commas, apostrophes, periods, ampersands, dashes, and pound characters, from 2-30 in length
// Fields: Company names, alternate names, filing institutions, group names, charter numbers
const genericAlphanumericBPattern = "[a-zA-Z 0-9 ,&'.#!-]{2,30}";
const genericAlphanumericBPatternValidator = Validators.pattern(genericAlphanumericBPattern);
const requiredGenericAlphanumericBPatternValidator = [Validators.required, genericAlphanumericBPatternValidator];

// Generic alphanumeric C: Also accepts spaces, commas, apostrophes, periods, ampersands, dashes, and pound characters, from 2-50 in length
// Fields: Company names, alternate names, filing institutions, group names, charter numbers
const genericAlphanumericCPattern = "[a-zA-Z 0-9 ,&'.#!-]{2,50}";
const organizationNamePatternValidator = Validators.pattern(genericAlphanumericCPattern);
const requiredOrganizationNamePatternValidator = [Validators.required, organizationNamePatternValidator];

// Generic alphabetical A: Also accepts spaces, commas, apostrophes, periods, dashes, from 2-30 in length
// Fields: First name, middle name, last name
const genericAlphabeticalAPattern = "[a-zA-Z ,'.-]{2,30}";
const genericAlphabeticalAPatternValidator = Validators.pattern(genericAlphabeticalAPattern);
const requiredGenericAlphabeticalAPatternValidator = [Validators.required, genericAlphabeticalAPatternValidator];

const middleInitialPattern = "[a-zA-Z ,'.-]{0,30}"; // ^[a-zA-Z '.-]{0,30}$
const middleInitialPatternValidator = Validators.pattern(middleInitialPattern);

// Generic alphabetical B: Also accepts spaces, commas, apostrophes, periods, ampersands, dashes, from 2-30 in length
// Fields: Cities
const citiesPattern = "[a-zA-Z ,&'.-]{2,30}";
const citiesPatternValidator = Validators.pattern(citiesPattern);
const requiredCitiesPatternValidator = [Validators.required, citiesPatternValidator];

// EIN: Accepts 9 digits, or 9 digits and a dash after the second digit, used in tandem with an EIN pipe in easy-to-reach spots
// Fields: EIN, TIN
const einAndTinPattern = '^[0-9]{2}(?:-?[0-9]{7})?$';
const einAndTinValidators = [Validators.minLength(9), Validators.maxLength(10), Validators.pattern(einAndTinPattern)];
const requiredEinAndTinValidators = [Validators.required, ...einAndTinValidators];

// EIN or SSN: Accepts 9 digits, or 9 digits and a dash after the second digit, or a nine-digit number with SSN formatting ({3}-{2}-{4})
// Fields: EIN/SSN identification field within FinCEN reports
// Validators:
// Validators.minLength(9), Validators.maxLength(10), Validators.pattern(^\d{3}-\d{2}-\d{4}$|[0-9]{9}|^[0-9]{2}(-?[0-9]{7})?$)

// Zipcode: Accepts 5 digits, 9 digits, or 5 digits, a dash, and 4 digits, used in tandem with a zip pipe in easy-to-reach spots
// Fields: Zipcodes, Postal codes
const zipCodePattern = '^[0-9]{5}(?:-?[0-9]{4})?$';
const zipCodePatternValidator = Validators.pattern(zipCodePattern);
const requiredZipCodePatternValidator = [Validators.required, zipCodePatternValidator];

const finCenZipCodePattern = '[0-9]{5,9}$';
const finCenZipCodePatternValidator = Validators.pattern(finCenZipCodePattern);
const requiredFinCenZipCodePatternValidators = [Validators.required, finCenZipCodePatternValidator];

// Email: Currently using Angular's generic builtin email validator, albeit it should be known that this format is accepted: test@test (no periods?)
// Perhaps in the future we may want to roll our own?
// Fields: Email
// Validators:
// Validators.email

// Phone: This is used with a pipe that is using the phonenumberlib package, which is currently implemented in
// tandem with this validator wherever formfield logic isn't too deeply embedded. The library rips out non-numeric characters / autoformats
// Fields: Phone numbers

const phoneNumberPattern = '^(1?([\\s.-]?)?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$';

const phoneNumberValidator = Validators.pattern(phoneNumberPattern);
const requiredPhoneNumberValidator = [Validators.required, phoneNumberValidator];

// Cash amounts: Accepts an option preceding $, digits, commas, and periods (if there are two digits proceeding the period)
// While FinCEN reporting guidelines stipulate that cents must be rounded up to the nearest whole dollar,
// in order for our app to be friendly from a UX perspective, we should allow the end user to input these however they'd like.
// Then, when ready to submit a report, this will go through logic that makes it FinCEN report
// This goes hand in hand with the EIN, phone number, and long zipcode fields, which require numbers only with no formatting
// Updated to include 0
// Fields: Currency fields
// Decimal and commas optional, see: https://stackoverflow.com/a/16242575
const currencyPattern = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
const currencyValidator = Validators.pattern(currencyPattern);
const requiredCurrencyValidator = [Validators.required, currencyValidator];

// Account number: Accepts between 5 to 20 digits, as well as dashes and spaces.
// Fields: Account numbers, routing numbers
// '[0-9 -]{5,20}'

// Phone number extension: Accepts between 2 and 7 characters, as well as dashes, x, or X
// Fields: Phone number extensions
// '[0-9 xX-]{2,7}'

// Routing number: Accepts 9 digits, numerical only
// Fields: Routing number
// '^[0-9]{9}$'

// See: https://github.com/angular/angular/issues/11159#issuecomment-308083390

const genericAlphanumericDPattern = "[a-zA-Z 0-9,'.#-]*";
const genericAlphanumericDPatternValidator = Validators.pattern(genericAlphanumericDPattern);
const requiredGenericAlphanumericDPatternValidator = [Validators.required, genericAlphanumericDPatternValidator];

const genericAlphanumericEPattern = "[a-zA-Z 0-9 ,&'.#-]{2,30}";
const genericAlphanumericEPatternValidator = Validators.pattern(genericAlphanumericEPattern);
const requiredGenericAlphanumericEPatternValidator = [Validators.required, genericAlphanumericEPatternValidator];

const genericAlphanumericFPattern = '[a-zA-Z 0-9,.#-]*';
const genericAlphanumericFPatternValidator = Validators.pattern(genericAlphanumericFPattern);
const requiredGenericAlphanumericFPatternValidator = [Validators.required, genericAlphanumericFPatternValidator];

const genericAlphabeticalBPattern = "^[a-zA-Z '.-]{2,30}$";
const genericAlphabeticalBPatternValidator = Validators.pattern(genericAlphabeticalBPattern);
const requiredGenericAlphabeticalBPatternValidator = [Validators.required, genericAlphabeticalBPatternValidator];

const idTypePattern = "[a-zA-Z 0-9,'.#-]{2,30}";
const idTypePatternValidator = Validators.pattern(idTypePattern);
const requiredIdTypePatternValidator = [Validators.required, idTypePatternValidator];

export const csvFileExtensionValidator = /(.csv|.CSV)/;
export const csvFileExtensionPatternValidator = Validators.pattern(csvFileExtensionValidator);

// FinCEN specific validators

const restrictStringPattern = /\S+( +\S+)*|/;
const restrictString2regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(2)];
const restrictString3regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(3)];
const restrictString6regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(6)];
const restrictString9regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(9)];
const restrictString15regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(15)];
const restrictString16regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(16)];
const restrictString25regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(25)];
const restrictString30regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(30)];
const restrictString39regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(39)];
const restrictString40regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(40)];
const restrictString50regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(50)];
const restrictString100regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(100)];
const restrictString150regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(150)];
const restrictString517regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(517)];
const restrictString4000regexValidator = [Validators.pattern(restrictStringPattern), Validators.maxLength(4000)];

const dateYYYYMMDDTypePattern = /(19|20)[0-9][0-9](0[1-9]|1[0-2]{2})(0[1-9]|1[0-9]|2[0-9]|3[01]{4})/;
const dateYYYYMMDDTypeValidator = Validators.pattern(dateYYYYMMDDTypePattern);

const dateYYYYMMDDOrBlankTypePattern = /((19|20)[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[01]))|^(?![\s\S])/;
const dateYYYYMMDDOrBlankTypeValidator = Validators.pattern(dateYYYYMMDDOrBlankTypePattern);

const validateIndicatorTypePattern = /^(?![\s\S])|Y/;
const validateIndicatorTypeValidator = Validators.pattern(validateIndicatorTypePattern);

const sarValidateActivityPartyCodeTypePattern = /(35)|(37)|(30)|(33)|(34)|(8)|(46)|(18)|(19)|(41)/;
const sarValidateActivityPartyCodeTypePatternValidator = Validators.pattern(sarValidateActivityPartyCodeTypePattern);

const validateFederalRegulatorCodeTypePattern = /(2)|(7)|(3)|(4)|(6)|((13)|(1))|((99)|(9))/;
const validateFederalRegulatorCodeTypePatternValidator = Validators.pattern(validateFederalRegulatorCodeTypePattern);

const validatePartyNameCodeTypePattern = /(L)|(AKA)|(DBA)/;
const validatePartyNameCodeTypePatternValidator = Validators.pattern(validatePartyNameCodeTypePattern);

const validatePhoneNumberCodeTypePattern = /(F)|(M)|(R)|(W)/;
const validatePhoneNumberCodeTypePatternValidator = Validators.pattern(validatePhoneNumberCodeTypePattern);

const sarValidatePartyIdentificationCodeTypePattern = /((10)|(11)|(12)|(13)|(14)|(1))|((28)|(29)|(2))|((32)|(33)|(3))|(999)/;
const sarValidatePartyIdentificationCodeTypePatternValidator = Validators.pattern(
  sarValidatePartyIdentificationCodeTypePattern
);

const validateOrganizationCodeTypePattern = /((11)|(12)|(1))|(2)|(3)|(4)|(5)|(999)/;
const validateOrganizationCodeTypePatternValidator = Validators.pattern(validateOrganizationCodeTypePattern);

const validateSuspiciousActivitySubtypeIdPattern = /(106)|(111)|(112)|(113)|(114)|(301)|(303)|(305)|(308)|(309)|(310)|(312)|(320)|(321)|(322)|(323)|(324)|(325)|(401)|(402)|(403)|(404)|(405)|(409)|(501)|(502)|(505)|(506)|(507)|(601)|(603)|(604)|(608)|(609)|(701)|(801)|(804)|(805)|(806)|(807)|(808)|(809)|(812)|(820)|(821)|(822)|(823)|(824)|(901)|(910)|(911)|(913)|(917)|(920)|(921)|(922)|(924)|(925)|(926)|(927)|(928)|(1001)|(1003)|(1005)|(1006)|(1007)|(1101)|(1102)|(1201)|(1202)|(1203)|(1204)|(1999)|(3999)|(4999)|(5999)|(6999)|(7999)|(8999)|(9999)|(10999)|(11999)|(12999)/;
const validateSuspiciousActivitySubtypeIdPatternValidator = Validators.pattern(
  validateSuspiciousActivitySubtypeIdPattern
);

const validateSuspiciousActivityTypeIdPattern = /((10)|(11)|(12)|(1))|(3)|(4)|(5)|(6)|(7)|(8)|(9)/;
const validateSuspiciousActivityTypeIdPatternValidator = Validators.pattern(validateSuspiciousActivityTypeIdPattern);

const validateActivityNarrativeSequenceNumberPattern = /(1)|(2)|(3)|(4)|(5)/;
const validateActivityNarrativeSequenceNumberPatternValidator = Validators.pattern(
  validateActivityNarrativeSequenceNumberPattern
);

const xsdLongValidator = [Validators.min(-9223372036854775808), Validators.max(9223372036854775807)];

const validateCurrencyTransactionActivityDetailCodeTypePattern = /(55)|(46)|(23)|(12)|(14)|(49)|(18)|(21)|(25)|(997)|(56)|(30)|(32)|(13)|(15)|(48)|(28)|(31)|(33)|(34)|(998)|(53)|(54)/;
const validateCurrencyTransactionActivityDetailCodeTypePatternValidator = Validators.pattern(
  validateCurrencyTransactionActivityDetailCodeTypePattern
);

const ctrValidatePartyIdentificationCodeTypePattern = /((10)|(11)|(12)|(13)|(14)|(1))|((28)|(2))|(4)|(5)|(6)|(7)|((999)|(9))/;
const ctrValidatePartyIdentificationCodeTypePatternValidator = Validators.pattern(
  ctrValidatePartyIdentificationCodeTypePattern
);

const validateOrganizationSubtypeCodeTypePattern = /(101)|(102)|(103)|(1999)/;
const validateOrganizationSubtypeCodeTypePatternValidator = Validators.pattern(
  validateOrganizationSubtypeCodeTypePattern
);

const validatePartyAccountAssociationCodeTypePattern = /(8)|(9)/;
const validatePartyAccountAssociationCodeTypePatternValidator = Validators.pattern(
  validatePartyAccountAssociationCodeTypePattern
);

const customSarAmountInvolvedValidator = (report): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isAmountInvolved =
      control.value || report.part4FormGroup.amountInvolvedInThisReport
        ? false
        : !(report.part4FormGroup.amountUnknown || report.part4FormGroup.noAmountInvolved);
    return isAmountInvolved ? { amountInvolvedInThisReport: { value: control.value } } : null;
  };
};

export {
  requiredAddressPatternValidator,
  requiredGenericAlphanumericBPatternValidator,
  genericAlphanumericCPattern as organizationNamePattern,
  organizationNamePatternValidator,
  requiredOrganizationNamePatternValidator,
  phoneNumberValidator,
  requiredPhoneNumberValidator,
  addressPattern,
  phoneNumberPattern,
  genericAlphabeticalAPattern,
  genericAlphabeticalAPatternValidator,
  requiredGenericAlphabeticalAPatternValidator,
  middleInitialPattern,
  middleInitialPatternValidator,
  citiesPattern,
  citiesPatternValidator,
  requiredCitiesPatternValidator,
  einAndTinPattern,
  einAndTinValidators,
  requiredEinAndTinValidators,
  currencyPattern,
  currencyValidator,
  requiredCurrencyValidator,
  zipCodePattern,
  zipCodePatternValidator,
  requiredZipCodePatternValidator,
  genericAlphanumericDPatternValidator,
  requiredGenericAlphanumericDPatternValidator,
  requiredGenericAlphabeticalBPatternValidator,
  idTypePatternValidator,
  requiredIdTypePatternValidator,
  requiredGenericAlphanumericEPatternValidator,
  genericAlphanumericEPattern,
  genericAlphanumericFPattern,
  requiredGenericAlphanumericFPatternValidator,
  finCenZipCodePatternValidator,
  requiredFinCenZipCodePatternValidators,
  validateIndicatorTypeValidator,
  dateYYYYMMDDTypeValidator,
  dateYYYYMMDDOrBlankTypeValidator,
  restrictStringPattern,
  restrictString2regexValidator,
  restrictString3regexValidator,
  restrictString6regexValidator,
  restrictString9regexValidator,
  restrictString15regexValidator,
  restrictString16regexValidator,
  restrictString25regexValidator,
  restrictString30regexValidator,
  restrictString39regexValidator,
  restrictString40regexValidator,
  restrictString50regexValidator,
  restrictString100regexValidator,
  restrictString150regexValidator,
  restrictString517regexValidator,
  restrictString4000regexValidator,
  sarValidateActivityPartyCodeTypePatternValidator,
  sarValidatePartyIdentificationCodeTypePatternValidator,
  validateFederalRegulatorCodeTypePatternValidator,
  validatePartyNameCodeTypePatternValidator,
  validatePhoneNumberCodeTypePatternValidator,
  validateSuspiciousActivitySubtypeIdPatternValidator,
  validateSuspiciousActivityTypeIdPatternValidator,
  validateActivityNarrativeSequenceNumberPatternValidator,
  xsdLongValidator,
  validateCurrencyTransactionActivityDetailCodeTypePatternValidator,
  ctrValidatePartyIdentificationCodeTypePatternValidator,
  validatePartyAccountAssociationCodeTypePatternValidator,
  validateOrganizationSubtypeCodeTypePatternValidator,
  validateOrganizationCodeTypePatternValidator,
  customSarAmountInvolvedValidator,
};
