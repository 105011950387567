<mat-sidenav-container *ngIf="items" [hasBackdrop]="true" class="sideNavContainer">
  <mat-sidenav
    #sidenavs
    (openedChange)="toggle()"
    [(opened)]="opened"
    [ngClass]="opened ? 'sideNavComponentOpened' : 'sideNavComponentClosed'"
    id="sideNavComponent2"
    mode="side"
    position="end"
  >
    <div class="sideContent">
      <div class="contentHeader">
        <button (click)="close()">
          <svg height="32" version="1" width="32" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
              <circle cx="15" cy="15" fill="#FFF" r="15" stroke="#DBE0EB" />
              <path d="M18 20a1 1 0 0 1-1 1l-6-6 6-6a1 1 0 0 1 1 1l-5 5 5 5z" fill="#A5A8BA" fill-rule="nonzero" />
            </g>
          </svg>
        </button>
        <div class="contentHeaderWrapper">
          <h1 class="contentHeaderText">Purchase Record</h1>
          <span class="datetime"
            >{{ items.receipt_date | date: 'shortDate' }} at {{ items.receipt_date | date: 'shortTime' }}</span
          >
        </div>
        <div class="purchaseRecordData">
          <div
            *ngIf="items.status === 'green_checked'"
            class="statusWrapper verified"
            data-cy="require-new-password.component.greenChecked"
          >
            <svg id="checkIcon" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
              <g clip-rule="evenodd" fill-rule="evenodd">
                <circle cx="13" cy="13" fill="#00bc66" r="13" />
                <path d="M16 10l-4 4-2-1H8v2l2 1a2 2 0 0 0 3 0l5-4v-2h-2" fill="#fff" />
              </g>
            </svg>
            <span>Verified</span>
          </div>
          <div
            *ngIf="items.status === 'red_flag'"
            class="statusWrapper rejected"
            data-cy="require-new-password.component.redFlagged"
          >
            <svg id="xIcon" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
              <circle clip-rule="evenodd" cx="13" cy="13" fill="#ff4e50" fill-rule="evenodd" r="13" />
              <path d="M15 13l2-2V9h-1l-3 2-2-2H9v1l2 3-2 2v2h1l3-2 2 2h2v-1l-2-3z" fill="#fff" />
            </svg>
            <span>Unverified</span>
          </div>
          <div
            *ngIf="items.status === 'warning'"
            class="statusWrapper warning"
            data-cy="require-new-password.component.warning"
          >
            <svg id="warningIcon" viewBox="0 0 26 26" width="26px" xmlns="http://www.w3.org/2000/svg">
              <style>
                .st1 {
                  fill: #fff;
                }
              </style>
              <circle
                clip-rule="evenodd"
                cx="13"
                cy="13"
                fill="#f5a623"
                fill-rule="evenodd"
                id="Oval-Copy-5_3_"
                r="13"
              />
              <path
                class="st1"
                d="M13 15.1c-.7 0-1.3-.6-1.3-1.3l-.5-6c0-1 1.1-1.3 1.8-1.3s1.8.3 1.8 1.3l-.5 6c0 .7-.6 1.3-1.3 1.3zM13 19.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"
              />
            </svg>
            <span>Warning</span>
          </div>
        </div>
      </div>

      <div class="itemWrapper">
        <div class="itemTableWrapper">
          <div class="tableHeader">
            <div class="productWrapper"><span>Product</span></div>
            <div class="itemHeaderWrapper"><span>Qty</span></div>
            <div class="itemHeaderWrapper"><span>Price/Unit</span></div>
            <div class="itemHeaderWrapper"><span>Total Price</span></div>
          </div>
          <div
            *ngFor="let item of items.products_sold"
            class="productLineItemWrapper"
            data-cy="require-new-password.component.item"
          >
            <div class="productWrapper">
              <span>{{ item.product_name ? item.product_name : item.product_id }}</span>
            </div>
            <div class="itemHeaderWrapper">
              <span>{{ item.num_units }}</span>
            </div>
            <div class="itemHeaderWrapper">
              <span>{{ item.price_per_unit / 100 | currency }}</span>
            </div>
            <div class="itemHeaderWrapper">
              <span>{{ (item.num_units * item.price_per_unit) / 100 | currency }}</span>
            </div>
          </div>
          <div class="productLineItemWrapper">
            <div class="productWrapper"><span>Sales Tax</span></div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper">
              <span>{{ items.tax / 100 | currency }}</span>
            </div>
          </div>
          <div class="productLineItemWrapper">
            <div id="productTotalWrapper"><span>Total</span></div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div id="itemTotalWrapper">
              <span>{{ items.total_paid / 100 | currency }}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="items.payment_type === 'multiple'" class="itemWrapper">
        <div class="itemTableWrapper">
          <span class="paymentHeader">Payment</span>
          <div *ngIf="items.cash_paid" class="productLineItemWrapper">
            <div class="productWrapper paymentBreakdown">
              <span>Cash</span>
            </div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper">
              {{ items.cash_paid / 100 | currency }}
            </div>
          </div>
          <div *ngIf="items.debit_paid" class="productLineItemWrapper">
            <div class="productWrapper paymentBreakdown">
              <span>Debit</span>
            </div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper">
              {{ items.debit_paid / 100 | currency }}
            </div>
          </div>
          <div *ngIf="items.check_paid" class="productLineItemWrapper">
            <div class="productWrapper paymentBreakdown">
              <span>Check</span>
            </div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper">
              {{ items.check_paid / 100 | currency }}
            </div>
          </div>
          <div *ngIf="items.credit_paid" class="productLineItemWrapper">
            <div class="productWrapper paymentBreakdown">
              <span>Credit</span>
            </div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper"><span></span></div>
            <div class="itemHeaderWrapper">
              {{ items.credit_paid / 100 | currency }}
            </div>
          </div>
        </div>
      </div>

      <div class="purchaseDetails">
        <div class="itemWrapper">
          <span class="header">Customer ID</span>
          <span class="item">{{ items.customer_id && items.customer_id.split('.')[1] | slice: 0:6 }}...</span>
        </div>
        <div class="itemWrapper">
          <span class="header">Employee ID</span>
          <span class="item">{{ items.employee_id | slice: 0:6 }}...</span>
        </div>
        <div class="itemWrapper">
          <span class="header">Payment Type</span>
          <span class="item">{{ items.payment_type | titlecase }}</span>
        </div>
      </div>

      <div *ngIf="items.status === 'warning' || items.status === 'red_flag'" class="exceptionContainer">
        <div *ngIf="items.compliance_warnings.length > 0">
          <div class="title"><span class="warning">Warnings</span></div>
          <ul>
            <li *ngFor="let warning of items.compliance_warnings">{{ warning }}</li>
          </ul>
        </div>
        <div *ngIf="items.compliance_exceptions.length > 0">
          <div class="title"><span class="exception">Issues</span></div>
          <ul>
            <li *ngFor="let exception of items.compliance_exceptions">{{ exception }}</li>
          </ul>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
