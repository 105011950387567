import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { Deposit, formatStringOrNumberToDollars } from '@gcv/shared';

import {
  GcvDrawer,
  GcvCheckbox,
  GcvButton,
  GcvInputForm,
  dateFormatterFromISO,
  dateFormatterToISO,
  GcvLoading,
  GcvInputSelect,
  GcvReadMore,
} from '../../../../lib';
import { BackButtonIcon } from '../../../../icons/BackButtonIcon';
import { GcvStatusIndicator } from '../../../../lib/GcvStatusIndicator/GcvStatusIndicator';
import { GcvUserInputContainer } from '../../../../lib/GcvUserInputContainer/GcvUserInputContainer';
import { formatMoney } from '../../../../util/formatHelpers';
import { formatISOToDateAtTime } from '../../../../util/dateTimeHelpers';

import {
  Header,
  Footer,
  SubTitle,
  HeaderContent,
  Block,
  Body,
  SubContent,
  VerticalCenter,
  Spacer,
  DrawerContent,
} from './styles';
import { GcvInputTextArea } from '../../../../lib/GcvInputTextArea/GcvInputTextArea';

interface Props {
  deposit: Deposit;
  bankId: string;
  type: string;
  userMap: any;
  dispensaryMap: any;
  emitData: (type, deposit, bankId) => any;
  onClose: () => void;
  open: boolean;
}

const getFormattedMethodsOfTransportation = methods => {
  const formattedMethods = [];
  Object.keys(methods).forEach(key => {
    methods[key].forEach(val => {
      if (val.active) {
        const reactSelectOption = { label: val.name, value: val };
        formattedMethods.push(reactSelectOption);
      }
    });
  });
  return formattedMethods;
};

export const DepositDetailDrawer = ({
  deposit,
  onClose,
  emitData,
  bankId,
  userMap,
  type,
  dispensaryMap,
  open,
}: Props) => {
  if (!open) {
    return <GcvDrawer open={false} onClose={onClose} />;
  }

  let initialdelivery_method;
  if (type === 'bank') {
    const methodsOfTransportation = dispensaryMap[deposit.dispensary_id].methodOfTransportation;
    const methodsOfTransportationFormatted = getFormattedMethodsOfTransportation(methodsOfTransportation);
    initialdelivery_method = methodsOfTransportationFormatted.find(val => val.value.id === deposit.delMethod.id);
  }

  const { register, handleSubmit, ...form } = useForm({
    defaultValues: {
      deliveryMethod: initialdelivery_method,
      dateReceived: dateFormatterFromISO(deposit.expected_arrival_date),
      datePosted: deposit.posted_date ? DateTime.fromISO(deposit.posted_date).toLocaleString(DateTime.DATE_MED) : '',
      additionalNotes: '',
    },
  });
  const { watch } = form;

  const [loadingReconciledDeposit, setLoadingReconciledDeposit] = useState(false);
  const [datePostedIsDateReceived, setDatePostedIsDateReceived] = useState(true);
  const [formComplete, setFormComplete] = useState<Boolean>(false);

  const formDateRules = {
    required: true,
    validate: {
      future: value => dateFormatterToISO(value) < DateTime.local().toISO() || "can't input a date in the future",
    },
    minLength: 10,
  };

  const toggleDatePostedIsDateReceived = () => {
    setDatePostedIsDateReceived(!datePostedIsDateReceived);
  };

  const onReconcileSubmit = (formData: any) => {
    deposit.accepted_date = DateTime.local().toISO();
    deposit.anticipated_delivery_method = deposit.delMethod;

    deposit.delMethod = formData.deliveryMethod.value;
    deposit.bank_comments = formData.additionalNotes;
    deposit.arrived_date = dateFormatterToISO(formData.dateReceived);
    deposit.posted_date = dateFormatterToISO(datePostedIsDateReceived ? formData.dateReceived : formData.datePosted);

    setLoadingReconciledDeposit(true);
    emitData('acceptDeposit', deposit, bankId);
  };

  useEffect(() => {
    if (deposit.status === 'accepted') {
      setLoadingReconciledDeposit(false);
    }
  }, [deposit.status]);

  useEffect(() => {
    setFormComplete(
      watch('deliveryMethod') && watch('dateReceived') && ((datePostedIsDateReceived || watch('datePosted')) as Boolean)
    );
  }, [watch('deliveryMethod'), watch('dateReceived'), watch('datePosted'), datePostedIsDateReceived]);

  if (
    loadingReconciledDeposit ||
    (userMap && !Object.keys(userMap).length) ||
    (dispensaryMap && !Object.keys(dispensaryMap).length)
  ) {
    return (
      <GcvDrawer open={true} onClose={onClose} style={{ width: '482px' }}>
        <GcvLoading />
      </GcvDrawer>
    );
  }

  const renderReconciledForm = () => {
    let formFields = [
      ['Method of Transportation', deposit.delMethod.name, 'mot'],
      ['Date Received', DateTime.fromISO(deposit.arrived_date).toLocaleString(DateTime.DATE_MED), 'date-received'],
      [
        'Date Posted',
        deposit.posted_date ? DateTime.fromISO(deposit.posted_date).toLocaleString(DateTime.DATE_MED) : '',
        'reconciled-date-posted',
      ],
      ['Reconciled On', formatISOToDateAtTime(deposit.accepted_date), 'reconciled-on'],
    ];

    if (type === 'bank') {
      formFields = formFields.concat([
        [
          'Internal Note',
          deposit.bank_comments ? deposit.bank_comments : 'No notes were made on this deposit',
          'internal-note',
        ],
        [
          'Reconciled By',
          `${userMap[deposit.accepted_by_user]['firstName']} ${userMap[deposit.accepted_by_user]['lastName']}`,
          'reconciled-by',
        ],
      ]);
    }

    return (
      <SubContent>
        {formFields.map(([title, string, testDataTag]) => (
          <div key={title}>
            <SubTitle data-cy={`${testDataTag}-title`}>{title}</SubTitle>
            <GcvReadMore string={string} length={50} data-cy={testDataTag}></GcvReadMore>
          </div>
        ))}
      </SubContent>
    );
  };

  const renderDepositForm = () => {
    if (type === 'bank') {
      const methodsOfTransportation = dispensaryMap[deposit.dispensary_id].methodOfTransportation;
      const methodsOfTransportationFormatted = getFormattedMethodsOfTransportation(methodsOfTransportation);

      return (
        <>
          <SubTitle style={{ fontWeight: 'bold', fontSize: '14px', margin: '20px 0px 40px 0px' }}>
            To reconcile this deposit, please confirm the details below:
          </SubTitle>

          <Spacer>
            <GcvInputSelect
              {...form}
              options={methodsOfTransportationFormatted}
              label={'Method of Transportation'}
              name={'deliveryMethod'}
              rules={{ required: true }}
              testDataTag="deposit-mot-select"
            />
          </Spacer>

          <Spacer>
            <GcvInputForm
              {...form}
              name={'dateReceived'}
              rules={formDateRules}
              label={'Date Received (mm/dd/yyyy)'}
              type="date"
              testDataTag="deposit-date-received"
            />
          </Spacer>

          <Spacer>
            <label>
              <GcvCheckbox
                onChange={toggleDatePostedIsDateReceived}
                checked={datePostedIsDateReceived}
                data-cy="posted-date-checkbox"
              />
              <SubTitle style={{ display: 'inline-block', marginLeft: '10px', marginTop: '0px' }}>
                Date posted is the same as date received
              </SubTitle>
            </label>
          </Spacer>

          {!datePostedIsDateReceived ? (
            <Spacer>
              <GcvInputForm
                {...form}
                name={'datePosted'}
                rules={formDateRules}
                label={'Date Posted (mm/dd/yyyy)'}
                type="date"
                testDataTag="date-posted"
              />
            </Spacer>
          ) : null}

          <GcvInputTextArea
            name={'additionalNotes'}
            label={'Add any notes you have about the deposit (optional)'}
            register={register}
            {...form}
            testDataTag="additional-notes"
          ></GcvInputTextArea>
        </>
      );
    }
  };

  const renderFooter = () =>
    type === 'bank' ? (
      <Footer>
        <VerticalCenter>
          <GcvButton
            disabled={!formComplete}
            primary={true}
            onClick={handleSubmit(onReconcileSubmit)}
            data-cy="deposit-reconcile"
          >
            Reconcile Deposit
          </GcvButton>
        </VerticalCenter>
      </Footer>
    ) : null;

  return (
    <GcvDrawer open={open} onClose={onClose} style={{ width: '482px' }}>
      <DrawerContent data-cy="deposit-detail-drawer">
        <Header>
          <div>
            <BackButtonIcon onClick={onClose} />
            <HeaderContent>
              Deposit Record
              <SubTitle style={{ marginTop: '0px' }}>
                <GcvUserInputContainer
                  string={dispensaryMap[deposit.dispensary_id].name}
                  length={36}
                  data-cy="deposit-dispensary-name"
                />
              </SubTitle>
            </HeaderContent>
          </div>
          <div>
            <SubContent style={{ margin: '0px', fontWeight: 'bold' }}>
              {deposit.status === 'accepted' ? 'Reconciled' : null}
            </SubContent>
          </div>
        </Header>
        <Body>
          <Block>
            <SubTitle>
              {deposit.status === 'accepted' ? 'Deposit Amount Received' : 'Anticipated Deposit Amount'}
            </SubTitle>
            <SubContent
              style={{
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '27px',
              }}
            >
              <GcvUserInputContainer string={formatMoney(deposit.final_deposit)} length={36} data-cy="deposit-amount" />
            </SubContent>
          </Block>
          <Block style={type === 'dispensary' && deposit.status !== 'accepted' ? { borderBottom: 'none' } : {}}>
            {deposit.status === 'accepted' ? (
              <>
                <SubTitle>Anticipated Deposit Amount</SubTitle>
                <SubContent>{formatMoney(deposit.anticipated_deposit_amount)}</SubContent>
              </>
            ) : null}

            <SubTitle data-cy="deposit-date-title">
              {type === 'bank' ? 'Anticipated Receipt Date' : 'Planned Arrival Date'}
            </SubTitle>
            <SubContent data-cy="deposit-date">
              {DateTime.fromISO(deposit.expected_arrival_date).toLocaleString(DateTime.DATE_MED)}
            </SubContent>

            <SubTitle data-cy="deposit-mot-title">
              {type === 'bank' ? 'Anticipated Method of Transportation' : 'Planned Method of Transportation'}
            </SubTitle>
            <SubContent>
              <GcvUserInputContainer string={deposit.delMethod.name} length={36} data-cy="deposit-mot-name" />
            </SubContent>

            {type === 'dispensary' ? (
              <>
                <SubTitle>Created By</SubTitle>
                <SubContent>
                  <GcvUserInputContainer
                    string={userMap[deposit.created_by]?.firstName + ' ' + userMap[deposit.created_by]?.lastName}
                    length={36}
                    data-cy="deposit-created-by-name"
                  ></GcvUserInputContainer>
                </SubContent>
              </>
            ) : null}

            <SubTitle>Max Deposit</SubTitle>
            <SubContent>
              <GcvUserInputContainer
                string={formatMoney(deposit.max_deposit)}
                length={36}
                data-cy="deposit-max-deposit"
              ></GcvUserInputContainer>
            </SubContent>

            <SubTitle data-cy="deposit-comment-title">{type === 'bank' ? 'Customer Comment' : 'Comment'}</SubTitle>
            <GcvReadMore
              string={deposit.comments ? deposit.comments : 'No comments were included with this deposit'}
              length={125}
              data-cy="comments"
            ></GcvReadMore>

            <SubTitle>Recorded On</SubTitle>
            <SubContent data-cy="deposit-header-date">{formatISOToDateAtTime(deposit.deposit_date)}</SubContent>

            <SubTitle>Deposit ID</SubTitle>
            <SubContent>
              <GcvUserInputContainer string={deposit.deposit_id} length={50} data-cy="deposit-id" />
            </SubContent>
          </Block>
          {deposit.status !== 'accepted' ? renderDepositForm() : renderReconciledForm()}
        </Body>
        {deposit.status !== 'accepted' ? renderFooter() : null}
      </DrawerContent>
    </GcvDrawer>
  );
};
