import { take, tap } from 'rxjs/operators';
import { Deposit, Notification } from '@gcv/shared';

const handleNotification = (incompleteNotificaitons: Notification[], depositId: string, facades) => {
  const notificationForDeposit = incompleteNotificaitons.find(
    (notification: Notification) => notification.data.deposit_id === depositId
  );

  if (notificationForDeposit) {
    facades.notificationFacade.completeNotification(markNotificationAsComplete(notificationForDeposit));
  }
};

const markNotificationAsComplete = (notification: Notification): Notification => {
  return { ...notification, completed: true };
};

export const acceptOrApproveDeposit = (deposit, bankId, facades) => {
  facades.depositsFacade.putAcceptDeposit({
    deposit: facades.utils.filterOutEmptyProperties(deposit) as Deposit,
    bankId: bankId,
  });

  facades.notificationFacade
    .selectIncompleteNotifications()
    .pipe(
      take(1),
      tap((incompleteNotifications: Notification[]) => {
        handleNotification(incompleteNotifications, deposit.deposit_id, facades);
      })
    )
    .subscribe();
};
