import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';

import { ReactWrapperComponent, WrapperProps, ReportsRouter } from '@user-interface/react-ui';
import { BankFacade, UserFacade } from 'libs/gcv-state/src/lib';
import { takeUntil } from 'rxjs/operators';

import { slideInAnimation } from '../../app/animations/router-animation';
import { determineStatus, ViewStates } from '../../app/bank/bank.util';
import { BankAppFamilyService } from '../../services/bank.service';

@Component({
  selector: 'reports',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleDueDiligenceButtonClicked"></app-react-wrapper>',
  animations: [trigger('routeAnimations', [transition('active <=> pending', [useAnimation(slideInAnimation)])])],
})
export class ReportsComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = ReportsRouter;
  viewStatus: string;
  bankId: string;

  constructor(
    private bankFacade: BankFacade,
    private userFacade: UserFacade,
    private bankAppFamilyService: BankAppFamilyService
  ) {
    super();
    this.props = {};
    this.handleDueDiligenceButtonClicked = this.handleDueDiligenceButtonClicked.bind(this);
  }

  ngOnInit() {
    this.bankFacade
      .selectCurrentBank()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(b => {
        this.bankId = b.id;
        this.props.bankId = b.id;
        this.props.timezone = b.iana_timezone;
        this.viewStatus = determineStatus(b.due_diligence.due_diligence_status);
        this.props.viewStatus = this.viewStatus;
      });

    this.userFacade
      .selectCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(u => {
        this.props.userId = u.id;
      });
  }

  handleDueDiligenceButtonClicked() {
    if (this.viewStatus === ViewStates.DueDiligenceZeroState) {
      this.bankFacade.putUpdateBankGCVDueDiligenceStatus(this.bankId);
    }
    this.bankAppFamilyService.view.next({ view: '/secure/bank/onboarding' });
  }
}
