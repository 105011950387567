import { ReactWrapperComponent, WrapperProps, BankDueDiligence } from '@user-interface/react-ui';

import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Dispensary, Bank, User, DueDiligenceStatus } from '@gcv/shared';

import { slideInAnimation } from '../../app/animations/router-animation';

import { DispensaryFacade, BankFacade, UserFacade } from '@user-interface/gcv-state';

import { S3FileManagementService } from '../../services/s3FileManagement.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bank-due-diligence',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleClick"></app-react-wrapper>',
  animations: [trigger('routeAnimations', [transition('active <=> pending', [useAnimation(slideInAnimation)])])],
})
export class BankDueDiligenceComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  component = BankDueDiligence;
  bank: Bank;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dispFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private s3FileManagement: S3FileManagementService,
    private userFacade: UserFacade
  ) {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  ngOnInit() {
    this.setUpStreams();
  }

  setUpStreams() {
    this.bankFacade
      .selectCurrentBank()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((bank: Bank) => {
        this.bank = bank;
        this.props = { ...this.props, bank };
      });
  }

  handleClick(type, data) {
    if ('getRequirements') {
      this.bankFacade.getBankById(this.bank.id);
    }
  }
}
