import React from 'react';

export const GreenCheckStatusIcon = ({ height = 26, width = 26, style = {} }) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="green-check-status-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
      fill="#00BC66"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8658 10.3381L11.5729 14.3105L10.1697 12.9073C9.67338 12.411 8.86858 12.4109 8.37221 12.9073C7.87593 13.4037 7.87593 14.2084 8.37221 14.7047L10.6399 16.9726C10.6508 16.9834 10.6626 16.9927 10.6737 17.0031C10.6892 17.0175 10.7047 17.0321 10.7208 17.0457C10.7383 17.0605 10.7564 17.0742 10.7747 17.0879C10.7914 17.1004 10.8078 17.113 10.8248 17.1246C10.8441 17.1377 10.8637 17.1495 10.8835 17.1614C10.9004 17.1716 10.9171 17.1822 10.9344 17.1915C10.9556 17.2029 10.9772 17.2129 10.9989 17.2231C11.0156 17.2309 11.0319 17.2393 11.0489 17.2463C11.0722 17.2561 11.096 17.2642 11.1198 17.2724C11.1357 17.278 11.1514 17.2842 11.1676 17.2891C11.1933 17.2969 11.2193 17.3029 11.2454 17.3091C11.2602 17.3126 11.2747 17.3169 11.2895 17.3199C11.3187 17.3257 11.3482 17.3295 11.3778 17.3333C11.3897 17.3348 11.4016 17.3372 11.4136 17.3384C11.4552 17.3425 11.4969 17.3447 11.5387 17.3447C11.5388 17.3447 11.5388 17.3447 11.5388 17.3447C11.5389 17.3447 11.539 17.3447 11.539 17.3447C11.5786 17.3447 11.6183 17.3428 11.6579 17.339C11.6694 17.3379 11.6809 17.3357 11.6924 17.3343C11.7204 17.3309 11.7482 17.3275 11.776 17.3222C11.7906 17.3194 11.8049 17.3155 11.8193 17.3122C11.8437 17.3067 11.8681 17.3014 11.8923 17.2945C11.9083 17.2898 11.924 17.2839 11.9398 17.2787C11.9619 17.2713 11.9841 17.2642 12.0058 17.2556C12.0226 17.249 12.039 17.2412 12.0556 17.2338C12.0758 17.2247 12.0961 17.2159 12.116 17.2057C12.1331 17.197 12.1497 17.1872 12.1665 17.1777C12.1851 17.1671 12.2038 17.1566 12.222 17.1449C12.2389 17.1341 12.2552 17.1224 12.2716 17.1108C12.2891 17.0984 12.3065 17.086 12.3234 17.0727C12.3393 17.0602 12.3546 17.0469 12.3699 17.0336C12.3805 17.0245 12.3916 17.0163 12.402 17.0067L17.5922 12.2039C18.1075 11.7271 18.1386 10.9229 17.6619 10.4077C17.1853 9.89257 16.381 9.86138 15.8658 10.3381Z"
      fill="white"
    />
  </svg>
);
