import React, { useState, useEffect } from 'react';
import { Bank, DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE, DueDiligenceRequirementTemplate } from '@gcv/shared';

import { useApi, getEndpoint } from '../../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { GcvDataTable, GcvZeroState, GcvDrawer, GcvButton, GcvCheckbox } from '../../../lib';
import { GcvUserInputContainer } from '../../../lib/GcvUserInputContainer/GcvUserInputContainer';
import { TemplateDrawer } from './TemplateDrawer';
import { VerticalCenter } from '../../../lib/GcvStatusIndicator/styles';

import styled from 'styled-components';
import { $blue, $grey2, $grey5 } from '../../../util/styleVariables';
import { api } from 'libs/react-ui/src/api';

export const Container = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
`;

export const SubContainer = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin: 1rem 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

export const Description = styled.div`
  margin: 1rem 0;
`;

export const ButtonContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: flex-end;
`;

export const ArchiveContainer = styled.div`
  margin: 24px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

export const Archive = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ArchiveText = styled.span`
  color: ${$blue};
  margin-left: 5px;
`;

export const DescriptionContainer = styled.span`
  margin: 1rem 0;
  height: 8rem;
`;

export const PosIcon = styled.img`
  margin: 10px auto;
`;

export const Spacer = styled.div`
  margin: 20px 0px;
`;

export const SubTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: ${$grey2};
`;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

export const DrawerText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 5px 0 20px 0;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 65px;
  height: 91vh;
  overflow-y: auto;
  box-sizing: border-box;
  width: 482px;
`;

export const DrawerHeader = styled.div`
  height: 9vh;
  padding: 23px;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f2f9;
  position: sticky;
  top: 0;
  background-color: white;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Warning = styled.div`
  font-size: 10px;
  padding: 2px 0px;
  color: red;
  height: 14px;
`;

const category = name => ({
  name: name,
  selector: 'category',
  sortable: true,
  grow: 1,
});

const requirement = (name, clickHandler, type) => ({
  name: name,
  selector: 'name',
  sortable: true,
  grow: 3,
  format: row => {
    const onClick = () => clickHandler(row, type);
    return (
      <div>
        <GcvUserInputContainer string={row.name} length={50} clickHandler={onClick} type="document" />
      </div>
    );
  },
});

interface Props {
  bank: Bank;
  canEditDetails: boolean;
  emitData: (type) => void;
}

const gcvDueDiligenceRequirements = () => {
  const reqs = JSON.parse(JSON.stringify(DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE.documentation.categories));
  reqs.legal.push(
    {
      name: 'Business Licenses',
      description:
        'A business may have multiple licenses issued by the state that allows them to conduct business activities in different business categories.',
      category: 'legal',
    },
    {
      name: 'Employee Licenses',
      description: 'An employee license establishes that an employee is legally allowed to work at the business.',
      category: 'legal',
    }
  );
  return reqs;
};

const flattenDueDiligenceRequirements = (categories: { [id: string]: DueDiligenceRequirementTemplate[] }) => {
  if (!categories) {
    return [];
  }
  const customReqs = [];
  Object.entries(categories).forEach(([category, template]) => {
    template.forEach(req => {
      req.category = category;
      customReqs.push(req);
    });
  });
  return customReqs;
};

export const DueDiligence = ({ bank, emitData, canEditDetails }: Props) => {
  const apiClient = api();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewArchived, setViewArchived] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<DueDiligenceRequirementTemplate>(null);
  const [toggleClearRows, setToggleClearRows] = useState<boolean>(false);
  const [drawerType, setDrawerType] = useState<'custom' | 'standard'>(null);

  const [selectedArchivedRows, setSelectedArchivedRows] = useState([]);
  const [selectedActiveRows, setSelectedActiveRows] = useState([]);

  const onSelectedRowsChange = props => {
    viewArchived ? setSelectedArchivedRows(props.selectedRows) : setSelectedActiveRows(props.selectedRows);
  };

  const updateData = () => {
    emitData('getRequirements');
  };

  const toggleDrawer = (type?: 'custom' | 'standard') => {
    setDrawerOpen(!drawerOpen);
    setDrawerType(type);
  };

  const toggleArchived = () => setViewArchived(!viewArchived);

  const standardReqs = flattenDueDiligenceRequirements(gcvDueDiligenceRequirements());

  const customReqs = bank.due_diligence_template.documentation
    ? flattenDueDiligenceRequirements(bank.due_diligence_template.documentation.categories)
    : [];
  const archivedReqs = customReqs.filter(req => req.archived);
  const activeReqs = customReqs.filter(req => !req.archived);

  const handleRowClick = (row, type) => {
    toggleDrawer(type);
    setCurrentTemplate(row);
  };

  const addNewRequirement = () => {
    setCurrentTemplate(null);
    toggleDrawer('custom');
  };

  const columnsCustom = [requirement('Requirement', handleRowClick, 'custom'), category('Type')];
  const columnsStandard = [requirement('Requirement', handleRowClick, 'standard'), category('Type')];

  const headerText = 'There are currently no custom requirements';

  const setReqStatus = () => {
    const requirement_ids = viewArchived
      ? selectedArchivedRows.map(req => req.requirement_id)
      : selectedActiveRows.map(req => req.requirement_id);
    const status = viewArchived ? 'active' : 'archived';
    saveReqStatus(status, requirement_ids);
  };

  const saveReqStatus = async (status, requirement_ids) => {
    await apiClient.banks
      .updateTemplateRequirementStatus(bank.id, { status, requirement_ids }, () => {})
      .then(() => {
        updateData();
        resetState();
      })
      .catch(e => {});
  };

  const resetState = () => {
    setToggleClearRows(!toggleClearRows);
    setSelectedArchivedRows([]);
    setSelectedActiveRows([]);
  };

  const renderSelectComponent = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <GcvCheckbox ref={ref} {...props} />
      </label>
    );
  });

  return (
    <>
      <Container>
        <SubContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Header>Custom Requirements</Header>
            {canEditDetails ? (
              <VerticalCenter>
                <GcvButton primary={true} onClick={addNewRequirement}>
                  Add New Requirement
                </GcvButton>
              </VerticalCenter>
            ) : null}
          </div>
          <DescriptionContainer>
            <Description>
              These are requirements that your institution currently asks for from all of your cannabis businesses. You
              can add or archive requirements at any time.
            </Description>
            {canEditDetails ? (
              <ArchiveContainer>
                <div>
                  {viewArchived && selectedArchivedRows.length > 0 ? (
                    <GcvButton primary={true} onClick={setReqStatus}>
                      Restore Requirements to Active
                    </GcvButton>
                  ) : null}
                  {!viewArchived && selectedActiveRows.length > 0 ? (
                    <GcvButton primary={true} onClick={setReqStatus}>
                      Archive Requirements
                    </GcvButton>
                  ) : null}
                </div>
                <Archive onClick={toggleArchived}>
                  <span>
                    Switch To:
                    <ArchiveText>
                      {viewArchived
                        ? `Active Requirements (${activeReqs.length})`
                        : `Archived Requirements (${archivedReqs.length})`}
                    </ArchiveText>
                  </span>
                </Archive>
              </ArchiveContainer>
            ) : null}
          </DescriptionContainer>
          <GcvDataTable
            data={viewArchived ? archivedReqs : activeReqs}
            columns={columnsCustom}
            onRowClicked={row => {
              handleRowClick(row, 'custom');
            }}
            defaultSortField={'type'}
            defaultSortAsc={false}
            keyField="requirement_id"
            noDataComponent={<GcvZeroState type="basic" headerText={headerText} />}
            subHeaderAlign="left"
            paginationPerPage={5}
            selectableRows={canEditDetails ? true : false}
            onSelectedRowsChange={onSelectedRowsChange}
            selectableRowsComponent={renderSelectComponent}
            clearSelectedRows={toggleClearRows}
          />
        </SubContainer>
        <SubContainer>
          <Header>Standard Requirements</Header>
          <DescriptionContainer>
            <Description>
              These are requirements that Green Check asks for from all cannabis businesses. You don't have to do
              anything here.
            </Description>
          </DescriptionContainer>
          <GcvDataTable
            data={standardReqs}
            columns={columnsStandard}
            onRowClicked={row => {
              handleRowClick(row, 'standard');
            }}
            defaultSortField={'type'}
            defaultSortAsc={false}
            keyField="name"
            subHeaderAlign="left"
            paginationPerPage={5}
          />
        </SubContainer>
      </Container>
      <TemplateDrawer
        open={drawerOpen}
        onClose={toggleDrawer}
        template={currentTemplate}
        updateReqStatus={saveReqStatus}
        bank={bank}
        updateData={updateData}
        drawerType={drawerType}
        canEditDetails={canEditDetails}
      ></TemplateDrawer>
    </>
  );
};
