import React, { useState, useEffect, useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import { Dispensary, DispensaryAccountReviewWithData } from '@gcv/shared';
import {
  formatMoney,
  formatOrdinalIndicator,
  formatPositiveNegativeColor,
  formatChangePercentage,
  doesReviewPeriodSpanMultipleMonths,
  getMonthsInTimeRange,
} from 'libs/react-ui/src/util';
import { Container } from './Container';
import { Card } from './Card';
import { Legend } from './Legend';
import { GcvInputSelectNew, GcvLoading } from 'libs/react-ui/src/lib';
import { DailySalesHeatmapChart } from './charts/DailySalesHeatmapChart';
import { SalesDollarsBarChar } from './charts/SalesDollarsBarChar';
import { TransactionCountBarChart } from './charts/TransactionCountBarChart';
import { PaymentBreakdownBarChart } from './charts/PaymentBreakdownBarChart';
import { ProductsSoldPieChart } from './charts/ProductsSoldPieChart';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { GcvContent } from 'libs/react-ui/src/lib/GcvContent/GcvContent';
import { SalesSummaryWholesaleSalesChart } from './charts/SalesSummaryWholesaleSalesChart';
import { SalesSummaryWholesaleTransactionsChart } from './charts/SalesSummaryWholesaleTransactionsChart';
import { AccountMonitoringContext } from '../account-monitoring-context';
import { FlexBox, HorizontalCenter } from 'libs/react-ui/src/styles/theme';
import { VerticalCenter } from 'libs/react-ui/src/components/DepositsReport/styles';

interface Props {
  review: DispensaryAccountReviewWithData;
}

const ReviewSales: React.FC<Props> = ({ review }: Props) => {
  const localReview = cloneDeep(review);
  const context = useContext(AccountMonitoringContext);
  const peerGroupSalesData = review.data.aggregated_sales_data.peer_groups[context.peerGroupId];
  const [filteredReview, setFilteredReview] = useState<DispensaryAccountReviewWithData>();
  const [customerCompare, setCustomerCompare] = useState('previous_period');

  const customerCompareOptions = [
    { value: 'previous_period', label: 'Change Over Last period' },
    { value: 'previous_year', label: 'Change Over Last year' },
  ];

  const [productsSoldData, setProductsSoldData] = useState<
    {
      [key in 'flower' | 'edible' | 'concentrate' | 'other']: {
        totalAmountCurrPeriod: number;
        totalAmountPrevPeriod: number;
        currentPeriod: number;
        previousPeriod: number;
        previousYear: number;
        peerGroups: number;
      };
    }
  >();
  const [productsSoldFormatDollars, setProductsSoldFormatDollars] = useState(true);
  const [confidenceScore, setConfidenceScore] = useState(0);
  const [reviewOptions, setReviewOptions] = useState([]);
  const [productsSoldOptions, setProductsSoldOptions] = useState([
    { label: 'Dollar Amount', value: 'dollarAmount' },
    { label: 'Units', value: 'units' },
    { label: 'Weight', value: 'weight' },
  ]);

  useEffect(() => {
    const options = getMonthsInTimeRange(review, review.dispensary.iana_timezone);

    setReviewOptions(options);
    onMonthChange(options[0]);

    onProductsSoldChange(productsSoldOptions[0]);
  }, [review]);

  const onMonthChange = month => {
    const newHourlySalesDistribution = {};

    Object.keys(review.data.hourly_sales_distribution)
      .filter(date => {
        return DateTime.fromFormat(date, 'yyyy-MM-dd').month === month.value;
      })
      .forEach(key => {
        newHourlySalesDistribution[key] = review.data.hourly_sales_distribution[key];
      });

    localReview.data.hourly_sales_distribution = newHourlySalesDistribution;

    setFilteredReview(localReview);
  };

  const onProductsSoldChange = e => {
    const measureType = e.value;
    const data = review.data.aggregated_sales_data;

    switch (measureType) {
      case 'dollarAmount':
        setProductsSoldFormatDollars(true);
        setConfidenceScore(100 - data.current_period.value_uncertainty);

        const totalDollarsCurrPeriod =
          data.current_period.flower_amount +
          data.current_period.edible_amount +
          data.current_period.other_amount +
          data.current_period.concentrate_amount;

        const totalDollarsPrevPeriod =
          data.previous_period.flower_amount +
          data.previous_period.edible_amount +
          data.previous_period.other_amount +
          data.previous_period.concentrate_amount;

        setProductsSoldData({
          flower: {
            totalAmountCurrPeriod: totalDollarsCurrPeriod,
            totalAmountPrevPeriod: totalDollarsPrevPeriod,
            currentPeriod: data.current_period.flower_amount,
            previousPeriod: data.previous_period.flower_amount,
            previousYear: data.previous_year.flower_amount,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_flower_amount,
          },
          edible: {
            totalAmountCurrPeriod: totalDollarsCurrPeriod,
            totalAmountPrevPeriod: totalDollarsPrevPeriod,
            currentPeriod: data.current_period.edible_amount,
            previousPeriod: data.previous_period.edible_amount,
            previousYear: data.previous_year.edible_amount,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_edible_amount,
          },
          concentrate: {
            totalAmountCurrPeriod: totalDollarsCurrPeriod,
            totalAmountPrevPeriod: totalDollarsPrevPeriod,
            currentPeriod: data.current_period.concentrate_amount,
            previousPeriod: data.previous_period.concentrate_amount,
            previousYear: data.previous_year.concentrate_amount,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_concentrate_amount,
          },
          other: {
            totalAmountCurrPeriod: totalDollarsCurrPeriod,
            totalAmountPrevPeriod: totalDollarsPrevPeriod,
            currentPeriod: data.current_period.other_amount,
            previousPeriod: data.previous_period.other_amount,
            previousYear: data.previous_year.other_amount,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_other_amount,
          },
        });
        break;
      case 'units':
        setProductsSoldFormatDollars(false);
        setConfidenceScore(100 - data.current_period.product_type_uncertainty);

        const totalUnitsCurrPeriod =
          data.current_period.number_of_flower_transactions +
          data.current_period.number_of_edible_transactions +
          data.current_period.number_of_other_transactions +
          data.current_period.number_of_concentrate_transactions;

        const totalUnitsPrevPeriod =
          data.previous_period.number_of_flower_transactions +
          data.previous_period.number_of_edible_transactions +
          data.previous_period.number_of_other_transactions +
          data.previous_period.number_of_concentrate_transactions;

        setProductsSoldData({
          flower: {
            totalAmountCurrPeriod: totalUnitsCurrPeriod,
            totalAmountPrevPeriod: totalUnitsPrevPeriod,
            currentPeriod: data.current_period.number_of_flower_transactions,
            previousPeriod: data.previous_period.number_of_flower_transactions,
            previousYear: data.previous_year.number_of_flower_transactions,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_number_of_flower_transactions,
          },
          edible: {
            totalAmountCurrPeriod: totalUnitsCurrPeriod,
            totalAmountPrevPeriod: totalUnitsPrevPeriod,
            currentPeriod: data.current_period.number_of_edible_transactions,
            previousPeriod: data.previous_period.number_of_edible_transactions,
            previousYear: data.previous_year.number_of_edible_transactions,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_number_of_edible_transactions,
          },
          concentrate: {
            totalAmountCurrPeriod: totalUnitsCurrPeriod,
            totalAmountPrevPeriod: totalUnitsPrevPeriod,
            currentPeriod: data.current_period.number_of_concentrate_transactions,
            previousPeriod: data.previous_period.number_of_concentrate_transactions,
            previousYear: data.previous_year.number_of_concentrate_transactions,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_number_of_concentrate_transactions,
          },
          other: {
            totalAmountCurrPeriod: totalUnitsCurrPeriod,
            totalAmountPrevPeriod: totalUnitsPrevPeriod,
            currentPeriod: data.current_period.number_of_other_transactions,
            previousPeriod: data.previous_period.number_of_other_transactions,
            previousYear: data.previous_year.number_of_other_transactions,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_number_of_other_transactions,
          },
        });
        break;
      case 'weight':
        setProductsSoldFormatDollars(false);
        setConfidenceScore(100 - data.current_period.weight_uncertainty);

        const totalWeightCurrPeriod =
          data.current_period.flower_weight +
          data.current_period.edible_weight +
          data.current_period.other_weight +
          data.current_period.concentrate_weight;

        const totalWeightPrevPeriod =
          data.previous_period.flower_weight +
          data.previous_period.edible_weight +
          data.previous_period.other_weight +
          data.previous_period.concentrate_weight;

        setProductsSoldData({
          flower: {
            totalAmountCurrPeriod: totalWeightCurrPeriod,
            totalAmountPrevPeriod: totalWeightPrevPeriod,
            currentPeriod: data.current_period.flower_weight,
            previousPeriod: data.previous_period.flower_weight,
            previousYear: data.previous_year.flower_weight,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_flower_weight,
          },
          edible: {
            totalAmountCurrPeriod: totalWeightCurrPeriod,
            totalAmountPrevPeriod: totalWeightPrevPeriod,
            currentPeriod: data.current_period.edible_weight,
            previousPeriod: data.previous_period.edible_weight,
            previousYear: data.previous_year.edible_weight,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_edible_weight,
          },
          concentrate: {
            totalAmountCurrPeriod: totalWeightCurrPeriod,
            totalAmountPrevPeriod: totalWeightPrevPeriod,
            currentPeriod: data.current_period.concentrate_weight,
            previousPeriod: data.previous_period.concentrate_weight,
            previousYear: data.previous_year.concentrate_weight,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_concentrate_weight,
          },
          other: {
            totalAmountCurrPeriod: totalWeightCurrPeriod,
            totalAmountPrevPeriod: totalWeightPrevPeriod,
            currentPeriod: data.current_period.other_weight,
            previousPeriod: data.previous_period.other_weight,
            previousYear: data.previous_year.other_weight,
            peerGroups: data.peer_groups[context.peerGroupId]?.avg_other_weight,
          },
        });
        break;
      default:
        break;
    }
  };

  const CustomerCompare = ({ initialValue, currentValue }) => {
    return (
      <FlexBox>
        <VerticalCenter>
          {currentValue - initialValue < 0 ? (
            <svg
              width="11"
              height="6"
              viewBox="0 0 11 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: '.25rem' }}
            >
              <path d="M5.39893 6L0.635786 0L10.1621 0L5.39893 6Z" fill="#FF4E50" />
            </svg>
          ) : currentValue - initialValue > 0 ? (
            <svg
              width="11"
              height="6"
              viewBox="0 0 11 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: '.25rem' }}
            >
              <path d="M5.7079 0L10.5234 6H0.892419L5.7079 0Z" fill="#00BC66" />
            </svg>
          ) : (
            <></>
          )}
        </VerticalCenter>
        {formatPositiveNegativeColor(formatChangePercentage(initialValue, currentValue))}
      </FlexBox>
    );
  };

  return (
    <Container>
      {review.dispensary.business_type === 'wholesale' && (
        <Container.Item>
          <Card>
            <Card.Header
              title="Sales Summary"
              actions={[
                <Legend
                  inline
                  items={[
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#00BC66" />
                        </svg>
                      ),
                      label: 'This Period',
                    },
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#1270FC" />
                        </svg>
                      ),
                      label: 'Prev Year Average',
                    },
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#F5A623" />
                        </svg>
                      ),
                      label: 'Peer Group Avg (current period)',
                    },
                  ]}
                />,
              ]}
            />
            <Card.Content>
              <Container>
                <Container.Item size={[6]}>
                  <SalesSummaryWholesaleSalesChart review={review} />
                </Container.Item>
                <Container.Item size={[6]}>
                  <SalesSummaryWholesaleTransactionsChart review={review} />
                </Container.Item>

                <Container.Item hCenter size={[6]}>
                  <GcvContent type="l1" content="Sales" />
                </Container.Item>
                <Container.Item hCenter size={[6]}>
                  <GcvContent type="l1" content="Transactions" />
                </Container.Item>
              </Container>
            </Card.Content>
          </Card>
        </Container.Item>
      )}

      {review.dispensary.business_type === 'retail' && (
        <Container.Item>
          <Card>
            <Card.Header
              title={'Daily Sales'}
              actions={[
                doesReviewPeriodSpanMultipleMonths(review, review.dispensary.iana_timezone) && reviewOptions.length ? (
                  <GcvInputSelectNew
                    label="Month"
                    options={reviewOptions}
                    onChange={onMonthChange}
                    dropWidth={'150px'}
                    labelWidth={'100px'}
                    dropCharLength={20}
                  />
                ) : (
                  undefined
                ),
              ]}
            />
            <Card.Content>
              {filteredReview ? (
                <>
                  <DailySalesHeatmapChart review={filteredReview} />

                  <Container hCenter gutterTop width={175}>
                    <Container.Item lAlign dense size={[6]}>
                      <GcvContent type="l2" content="Less Sales" />
                    </Container.Item>
                    <Container.Item rAlign dense size={[6]}>
                      <GcvContent type="l2" content="More Sales" />
                    </Container.Item>

                    <Container.Item hCenter>
                      <svg width="116" height="16" viewBox="0 0 116 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="94" width="21.5873" height="16" rx="2" fill="#00A77E" />
                        <rect x="70.7622" width="21.5873" height="16" rx="2" fill="#00BC66" />
                        <rect x="47.1748" width="21.5873" height="16" rx="2" fill="#44D359" />
                        <rect x="23.5874" width="21.5873" height="16" rx="2" fill="#96E688" />
                        <rect width="21.5873" height="16" rx="2" fill="#C1EEAA" />
                      </svg>
                    </Container.Item>
                  </Container>
                </>
              ) : (
                <>
                  <GcvLoading></GcvLoading>
                </>
              )}
            </Card.Content>
          </Card>
        </Container.Item>
      )}

      {review.dispensary.business_type === 'retail' && (
        <Container.Item>
          <Card>
            <Card.Header
              title={'Sales Summary'}
              actions={[
                <Legend
                  inline
                  items={[
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <ellipse cx="3.5" cy="3.5" rx="3.5" ry="3.5" fill="#00A77E" />
                        </svg>
                      ),
                      label: 'Verified',
                    },
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <ellipse cx="3.5" cy="3.5" rx="3.5" ry="3.5" fill="#A5A8BA" />
                        </svg>
                      ),
                      label: 'Unchecked',
                    },
                    {
                      icon: (
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <ellipse cx="3.5" cy="3.5" rx="3.5" ry="3.5" fill="#FF4E50" />
                        </svg>
                      ),
                      label: 'Unverified',
                    },
                  ]}
                />,
              ]}
            />
            <Card.Content>
              <br />
              <Container>
                <Container.Item dense size={[3]}>
                  <GcvContent type="l1" grey2 content="Total Sales" />
                  <GcvContent
                    type="p1"
                    content={formatMoney(review.data.aggregated_sales_data.current_period.total_sales)}
                  />
                </Container.Item>
                <Container.Item dense size={[3]}>
                  <GcvContent type="l1" grey2 content="Verified" />
                  <GcvContent
                    type="p1"
                    content={formatMoney(review.data.aggregated_sales_data.current_period.verified_total)}
                  />
                </Container.Item>
                <Container.Item dense size={[3]}>
                  <GcvContent type="l1" grey2 content="Unchecked" />
                  <GcvContent
                    type="p1"
                    content={formatMoney(review.data.aggregated_sales_data.current_period.uncheckable_total)}
                  />
                </Container.Item>
                <Container.Item dense size={[3]}>
                  <GcvContent type="l1" grey2 content="Unverified" />
                  <GcvContent
                    type="p1"
                    content={formatMoney(review.data.aggregated_sales_data.current_period.unverified_total)}
                  />
                </Container.Item>

                <Container.Item>
                  <SalesDollarsBarChar review={review} />
                </Container.Item>

                <Container.Item dense size={[3]}>
                  <GcvContent type="l1" grey2 content="Total Transactions" />
                  <GcvContent
                    type="p1"
                    content={review.data.aggregated_sales_data.current_period.total_number_of_transactions.toLocaleString()}
                  />
                </Container.Item>
                <Container.Item dense size={[3]}>
                  <GcvContent type="l1" grey2 content="Verified" />
                  <GcvContent
                    type="p1"
                    content={review.data.aggregated_sales_data.current_period.number_of_verified_transactions.toLocaleString()}
                  />
                </Container.Item>
                <Container.Item dense size={[3]}>
                  <GcvContent type="l1" grey2 content="Unchecked" />
                  <GcvContent
                    type="p1"
                    content={review.data.aggregated_sales_data.current_period.number_of_unchecked_transactions.toLocaleString()}
                  />
                </Container.Item>
                <Container.Item dense size={[3]}>
                  <GcvContent type="l1" grey2 content="Unverified" />
                  <GcvContent
                    type="p1"
                    content={review.data.aggregated_sales_data.current_period.number_of_unverified_transactions.toLocaleString()}
                  />
                </Container.Item>

                <Container.Item>
                  <TransactionCountBarChart review={review} />
                </Container.Item>
              </Container>
            </Card.Content>
          </Card>
        </Container.Item>
      )}

      <Container.Item>
        <Card>
          <Card.Header title={'Payment Breakdown'} />
          <Card.Content>
            <Container>
              <Container.Item>
                <PaymentBreakdownBarChart
                  review={review}
                  isWholesale={review.dispensary.business_type === 'wholesale'}
                />
              </Container.Item>
            </Container>
          </Card.Content>
        </Card>
      </Container.Item>

      {review.dispensary.business_type === 'retail' && (
        <Container.Item>
          <Card>
            <Card.Header
              title={'Products Sold'}
              actions={[
                <GcvInputSelectNew
                  label="Measure"
                  options={productsSoldOptions}
                  onChange={onProductsSoldChange}
                  dropWidth={'155px'}
                  labelWidth={'105px'}
                  dropCharLength={20}
                />,
              ]}
            />

            <Card.Content>
              {productsSoldData && (
                <ProductsSoldPieChart data={productsSoldData} formatDollars={productsSoldFormatDollars} />
              )}
            </Card.Content>

            <Card.Footer>
              <GcvContent type="l1" content={`Confidence score: ${confidenceScore}%`} />
              <label
                data-tip={
                  'Percentage of sales records containing all data points needed to measure product distribution'
                }
              >
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.95 2.75H6.05V3.85H4.95V2.75ZM4.95 4.95H6.05V8.25H4.95V4.95ZM5.5 0C2.464 0 0 2.464 0 5.5C0 8.536 2.464 11 5.5 11C8.536 11 11 8.536 11 5.5C11 2.464 8.536 0 5.5 0ZM5.5 9.9C3.0745 9.9 1.1 7.9255 1.1 5.5C1.1 3.0745 3.0745 1.1 5.5 1.1C7.9255 1.1 9.9 3.0745 9.9 5.5C9.9 7.9255 7.9255 9.9 5.5 9.9Z"
                    fill="#9B9EB1"
                  />
                </svg>
                <ReactTooltip place="top" type="dark" effect="solid" delayShow={250} />
              </label>
            </Card.Footer>
          </Card>
        </Container.Item>
      )}
      <Container.Item>
        <Card>
          <Card.Header
            title={'Customers'}
            actions={[
              <GcvInputSelectNew
                label="Compare"
                options={customerCompareOptions}
                onChange={e => setCustomerCompare(e.value)}
                dropWidth={'225px'}
                labelWidth={'105px'}
                dropCharLength={30}
              />,
            ]}
          />

          {review.data.aggregated_sales_data.current_period ? (
            <Card.Content>
              <FlexBox style={{ justifyContent: 'space-around', marginTop: '1rem' }}>
                <div>
                  <HorizontalCenter>
                    <GcvContent type="l1" grey2={true} content="UNIQUE CUSTOMERS" />
                  </HorizontalCenter>
                  <HorizontalCenter>
                    <GcvContent
                      type="p1"
                      content={review.data.aggregated_sales_data.current_period.number_of_unique_customers}
                    />
                  </HorizontalCenter>
                  <HorizontalCenter>
                    <CustomerCompare
                      currentValue={review.data.aggregated_sales_data.current_period.number_of_unique_customers}
                      initialValue={review.data.aggregated_sales_data[customerCompare].number_of_unique_customers}
                    />
                  </HorizontalCenter>

                  {peerGroupSalesData ? (
                    <HorizontalCenter style={{ marginTop: '1.5rem' }}>
                      <GcvContent
                        type="p1"
                        content={`${formatOrdinalIndicator(
                          peerGroupSalesData.percentiles.avg_number_of_unique_customers
                        )} percentile`}
                      />
                    </HorizontalCenter>
                  ) : null}
                </div>
                <div>
                  <HorizontalCenter>
                    <GcvContent type="l1" grey2={true} content="AVG SALES/CUSTOMER" />
                  </HorizontalCenter>
                  <HorizontalCenter>
                    <GcvContent
                      type="p1"
                      content={formatMoney(review.data.aggregated_sales_data.current_period.sales_amount_per_customer)}
                    />
                  </HorizontalCenter>
                  <HorizontalCenter>
                    <CustomerCompare
                      currentValue={review.data.aggregated_sales_data.current_period.sales_amount_per_customer}
                      initialValue={review.data.aggregated_sales_data[customerCompare].sales_amount_per_customer}
                    />
                  </HorizontalCenter>

                  {peerGroupSalesData ? (
                    <HorizontalCenter style={{ marginTop: '1.5rem' }}>
                      <GcvContent
                        type="p1"
                        content={`${formatOrdinalIndicator(
                          peerGroupSalesData.percentiles.avg_sales_amount_per_customer
                        )} percentile`}
                      />
                    </HorizontalCenter>
                  ) : null}
                </div>
                <div>
                  <HorizontalCenter>
                    <GcvContent type="l1" grey2={true} content="AVG TXS/CUSTOMER" />
                  </HorizontalCenter>
                  <HorizontalCenter>
                    <GcvContent
                      type="p1"
                      content={review.data.aggregated_sales_data.current_period.number_of_txn_per_customer}
                    />
                  </HorizontalCenter>
                  <HorizontalCenter>
                    <CustomerCompare
                      currentValue={review.data.aggregated_sales_data.current_period.number_of_txn_per_customer}
                      initialValue={review.data.aggregated_sales_data[customerCompare].number_of_txn_per_customer}
                    />
                  </HorizontalCenter>

                  {peerGroupSalesData ? (
                    <HorizontalCenter style={{ marginTop: '1.5rem' }}>
                      <GcvContent
                        type="p1"
                        content={`${formatOrdinalIndicator(
                          peerGroupSalesData.percentiles.avg_number_of_txn_per_customer
                        )} percentile`}
                      />
                    </HorizontalCenter>
                  ) : null}
                </div>
              </FlexBox>
            </Card.Content>
          ) : null}
        </Card>
      </Container.Item>
    </Container>
  );
};

export default ReviewSales;
