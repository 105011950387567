<div *ngIf="stepStatus" class="bar-container">
  <ng-container *ngFor="let step of stepStatus; let i = index">
    <div
      (click)="handleClick(i)"
      *ngIf="step"
      [ngClass]="{ clickable: step.complete || step.currentStep }"
      class="progress-item"
    >
      <div *ngIf="step" [class.complete]="step.complete" class="bar"></div>
      <div *ngIf="step" [class.selected]="step.selected" class="label">{{ step.label }}</div>
    </div>
  </ng-container>
</div>
