<div class="wrapper">
  <div class="header">
    <h1>
      {{ title }}
    </h1>
    <p class="headerText">
      {{ headerText }}
    </p>
  </div>
  <div class="body">
    <gcv-support-card
      *ngFor="let helpBox of helpBoxes"
      class="col-6"
      [cardTitle]="helpBox.header"
      [cardBody]="helpBox.content"
    ></gcv-support-card>
    <p class="margin-top footerText">
      {{ footerText }}
    </p>
  </div>
</div>
