import { Action } from '@ngrx/store';
import { FincenCtrReport, FincenSarReport } from '@gcv/shared';

export enum FincenActionTypes {
  LoadFincen = '[Fincen] Load Fincen',
  FincenLoaded = '[Fincen] Fincen Loaded',

  LoadFincenByBankId = '[Fincen] Load Fincen By BankId',
  FincenByBankIdLoaded = '[Fincen] Fincen By BankId Loaded',

  LoadFincenByBankAndReportId = '[Fincen] Load Fincen By Bank And Report Id',
  FincenByBankAndReportIdLoaded = '[Fincen] Fincen By Bank And Report Id Loaded',

  UpdateFincen = '[Fincen] Update Fincen',
  FincenUpdated = '[Fincen] Fincen Updated',

  ResetFincen = '[Fincen] Reset Fincen',

  FincenDeleted = '[Fincen] Fincen Deleted',
}

export class LoadFincen implements Action {
  readonly type = FincenActionTypes.LoadFincen;

  constructor(public payload: { bankId: string; dispensaryId: string; forceFetch?: boolean }) {}
}

export class LoadFincenByBankId implements Action {
  readonly type = FincenActionTypes.LoadFincenByBankId;
  constructor(public payload: { bankId: string }) {}
}

export class LoadFincenByBankAndReportId implements Action {
  readonly type = FincenActionTypes.LoadFincenByBankAndReportId;
  constructor(public payload: { bankId: string; reportId: string }) {}
}

export class FincenLoaded implements Action {
  readonly type = FincenActionTypes.FincenLoaded;

  constructor(public payload: (FincenCtrReport | FincenSarReport)[]) {}
}

export class FincenByBankIdLoaded implements Action {
  readonly type = FincenActionTypes.FincenByBankIdLoaded;
  constructor(public payload: (FincenCtrReport | FincenSarReport)[]) {}
}

export class UpdateFincen implements Action {
  readonly type = FincenActionTypes.UpdateFincen;

  constructor(public payload: FincenCtrReport | FincenSarReport) {}
}

export class FincenUpdated implements Action {
  readonly type = FincenActionTypes.FincenUpdated;

  constructor(public payload: FincenCtrReport | FincenSarReport) {}
}

export class ResetFincen implements Action {
  readonly type = FincenActionTypes.ResetFincen;
}

export class FincenDeleted implements Action {
  readonly type = FincenActionTypes.FincenDeleted;

  constructor(public payload: string) {}
}

export type FincenActions =
  | LoadFincen
  | FincenByBankIdLoaded
  | LoadFincenByBankAndReportId
  | LoadFincenByBankId
  | FincenLoaded
  | UpdateFincen
  | FincenUpdated
  | ResetFincen
  | FincenDeleted;
