import React, { useState } from 'react';

import { Dispensary, User } from '@gcv/shared';
import { useForm } from 'react-hook-form';

import { GcvUserInputContainer, GcvDrawer, GcvButton, GcvReadMore, dateFormatterFromISO } from '../../../lib';
import { formatISOToDateAtTime } from '../../../util/dateTimeHelpers';
import { BackButtonIcon } from '../../../icons/BackButtonIcon';
import { DocumentIcon } from '../../../icons/DocumentIcon';
import { RequirementMetadata, Document, DocumentMetadata } from '../types';
import { formatUserFirstAndLastName } from '../../../util/formatHelpers';

import {
  Header,
  SubTitle,
  HeaderContent,
  Block,
  Body,
  SubContent,
  VerticalCenter,
  HorizontalCenter,
  DrawerContent,
  ViewFile,
} from './styles';
import { GcvInputTextArea } from '../../../lib/GcvInputTextArea/GcvInputTextArea';

interface DocumentDrawerProps {
  onClose: () => void;
  documentMetadata: DocumentMetadata;
  document: Document;
  dispensary: Dispensary;
  open: boolean;
  userMap: {
    [id: string]: User;
  };
  ableToUpdate: boolean;
  companyType: string;
  documentType: 'license' | 'document';
  requirementMetadata: RequirementMetadata;
  updateDocument: (docId, status, note) => Promise<any>;
  viewFile: (docId: string, document: Document) => void;
  getRequirementData: (dispensary) => void;
}

export const DocumentDrawer = ({
  onClose,
  dispensary,
  documentMetadata,
  document,
  open,
  userMap,
  companyType,
  updateDocument,
  requirementMetadata,
  viewFile,
  getRequirementData,
  documentType,
  ableToUpdate,
}: DocumentDrawerProps) => {
  if (!open) {
    return <GcvDrawer open={false} onClose={onClose} />;
  }
  const { handleSubmit, ...form } = useForm({});

  const handleViewFile = () => {
    viewFile(document.id, document);
  };

  const approveDocument = async ({ note }) => {
    updateDocument(document.id, 'approved', note)
      .then(updatedDispensary => {
        onClose();
        getRequirementData(updatedDispensary);
      })
      .catch(err => {
        alert('error updating document status, please contact support@greencheckverified.com' + err);
      });
  };

  const reviewedUserName = formatUserFirstAndLastName(userMap[documentMetadata.reviewed_by]);
  const uploadedByUsername = formatUserFirstAndLastName(userMap[document.uploaded_by]);

  return (
    <GcvDrawer open={open} onClose={onClose} style={{ width: '482px' }}>
      <DrawerContent data-cy="document-drawer">
        <Header>
          <div>
            <BackButtonIcon onClick={onClose} />
            <HeaderContent>
              File Details
              <SubTitle style={{ marginTop: '0px' }}>{dispensary.name}</SubTitle>
            </HeaderContent>
          </div>
          {companyType === 'bank' ? (
            <div>
              <SubContent style={{ margin: '0px' }}>
                {documentMetadata.reviewed_by ? 'Active' : 'Needs Review'}
              </SubContent>

              <SubTitle style={{ marginTop: '0px' }}>
                <GcvUserInputContainer string={documentMetadata.id} length={10} />
              </SubTitle>
            </div>
          ) : null}
        </Header>
        <Body>
          <HorizontalCenter>
            <VerticalCenter style={{ cursor: 'pointer' }}>
              <DocumentIcon onClick={handleViewFile} />
              <ViewFile onClick={handleViewFile}>View File</ViewFile>
            </VerticalCenter>
          </HorizontalCenter>

          <SubTitle>File Name</SubTitle>
          <SubContent>
            <GcvUserInputContainer string={document.file_name} length={40} type="document" />
          </SubContent>

          <SubTitle>Uploaded On</SubTitle>
          <SubContent>{formatISOToDateAtTime(document.upload_date)}</SubContent>

          <SubTitle>Uploaded By</SubTitle>
          <SubContent>
            <GcvUserInputContainer string={document.uploaded_by ? uploadedByUsername : ''} length={40} />
          </SubContent>
          {documentType === 'license' ? (
            <>
              <SubTitle>{requirementMetadata.id === 'business_licenses' ? 'License Type' : 'Employee Name'}</SubTitle>
              <SubContent>{documentMetadata.license_name}</SubContent>

              <SubTitle>License Number</SubTitle>
              <SubContent>{documentMetadata.license_number}</SubContent>

              <SubTitle>Expiration Date</SubTitle>
              <SubContent>{dateFormatterFromISO(documentMetadata.expiration_date)}</SubContent>
            </>
          ) : null}

          <SubTitle>Requirement</SubTitle>
          <SubContent>
            <GcvUserInputContainer string={requirementMetadata.name} length={40} />
          </SubContent>

          {companyType === 'bank' ? (
            <>
              {documentMetadata.status === 'approved' || documentMetadata.status === 'archived' ? (
                <Block>
                  <SubTitle>Internal Comment</SubTitle>
                  <GcvReadMore
                    style={{ fontWeight: '500', fontSize: '14px', lineHeight: '20px' }}
                    string={
                      documentMetadata.bank_internal_note
                        ? documentMetadata.bank_internal_note
                        : 'No comments were made on this file'
                    }
                    length={100}
                  />

                  <SubTitle>Reviewed On</SubTitle>
                  <SubContent>{formatISOToDateAtTime(documentMetadata.review_date)}</SubContent>

                  <SubTitle>Reviewed By</SubTitle>
                  <SubContent>
                    <GcvUserInputContainer string={reviewedUserName} length={40} />
                  </SubContent>
                </Block>
              ) : (
                <VerticalCenter style={{ marginTop: 10 }}>
                  <GcvInputTextArea
                    {...form}
                    name={'note'}
                    label={'Add any notes you have about the document (optional)'}
                    testDataTag="document-notes-textbox"
                  ></GcvInputTextArea>

                  <HorizontalCenter style={{ margin: '0px' }}>
                    <GcvButton
                      onClick={handleSubmit(approveDocument)}
                      primary={true}
                      data-cy="mark-as-reviewed-button"
                      disabled={!ableToUpdate}
                    >
                      Mark as Reviewed
                    </GcvButton>
                  </HorizontalCenter>
                </VerticalCenter>
              )}
            </>
          ) : null}
        </Body>
      </DrawerContent>
    </GcvDrawer>
  );
};
