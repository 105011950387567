import { DynamicComponent, FormComponent } from './ui-factory';

export class UiState {
  public static populate(uiObject: DynamicComponent, data: Record<string, any>) {
    for (const key of Object.keys(data)) {
      const value = data[key];
      this.updateUi(uiObject, key, value);
    }

    return uiObject;
  }

  private static updateUi(uiObject: DynamicComponent, key: string, value: string) {
    if (uiObject.components) {
      for (const component of uiObject.components) {
        this.updateUi(component, key, value);
      }
    } else {
      switch (uiObject.type) {
        case 'Checkbox':
        case 'Dropdown':
        case 'TextArea':
        case 'TextField':
          if (uiObject.id === key) {
            uiObject.value = value;
          }
          break;
        default:
          break;
      }
    }

    return uiObject;
  }

  public static checkCompletedSections(forms: FormComponent[], data: Record<string, any>) {
    const sections = {};

    if (!forms || !Array.isArray(forms)) {
      return sections;
    }

    forms.forEach(form => {
      let isSectionComplete: boolean = true;

      const recursiveCheck = component => {
        if (component.components) {
          for (const c of component.components) {
            recursiveCheck(c);
          }
        } else {
          switch (component.type) {
            case 'Checkbox':
            case 'Dropdown':
            case 'TextArea':
            case 'TextField':
              if (component.rules && component.rules.required) {
                isSectionComplete = data[component.id] !== undefined && isSectionComplete;
              }
              break;
            default:
              break;
          }
        }
      };

      recursiveCheck(form);

      sections[form.title] = isSectionComplete;
    });

    return sections;
  }
}
