import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const peerGroupsClient = (API: APIClass) => {
  return {
    getPeerGroups: async (bankid: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/banks/${bankid}/peer-groups`, setIsLoading);
    },

    createPeerGroup: async (bankid: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/banks/${bankid}/peer-groups`, body, setIsLoading);
    },

    updatePeerGroupDispensaries: async (
      bankid: string,
      peerGroupId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/banks/${bankid}/peer-groups/${peerGroupId}/dispensaries`, body, setIsLoading);
    },

    removeDispensaryFromPeerGroup: async (
      bankId: string,
      peerGroupId: string,
      dispensaryId: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).delete(
        `/banks/${bankId}/peer-groups/${peerGroupId}/dispensary/${dispensaryId}`,
        setIsLoading
      );
    },

    deletePeerGroup: async (bankId: string, peerGroupId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/banks/${bankId}/peer-groups/${peerGroupId}`, setIsLoading);
    },

    updatePeerGroup: async (
      bankId: string,
      peerGroupId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/banks/${bankId}/peer-groups/${peerGroupId}`, body, setIsLoading);
    },
  };
};
