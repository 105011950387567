import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { Deposit, convertStringNumberToWholeCents, convertNumberToWholeCents, MinifiedDispensary } from '@gcv/shared';

import { BackButtonIcon } from '../../../icons/BackButtonIcon';
import { formatMoney } from '../../../util/formatHelpers';
import { formatISOToDateAtTime } from '../../../util/dateTimeHelpers';

import {
  GcvDrawer,
  dateFormatterFromISO,
  dateFormatterToISO,
  GcvLoading,
  GcvReadMore,
  GcvUserInputContainer,
  GcvInputSelectNew,
} from '../../../lib';

import { Header, SubTitle, HeaderContent, Block, Body, SubContent, DrawerContent } from './styles';
import { DepositDetailDrawerFooter } from './DepositDetailDrawerFooter';
import { DepositDetailDrawerDepositForm } from './DepositDetailDrawerDepositForm';
import { DepositDetailDrawerReconciledForm } from './DepositDetailDrawerReconciledForm';
import { GcvCollapsibleSection } from 'libs/react-ui/src/lib/GcvCollapsableSection/GcvCollapsibleSection';
import { api } from 'libs/react-ui/src/api';
import { Snackbar } from '@material-ui/core';
import { Dictionary } from '@ngrx/entity';

interface Props {
  depositId: string;
  bankId: string;
  type: string;
  userMap: any;
  dispensaryMap: Dictionary<MinifiedDispensary>;
  emitData: (deposit, type: 'updateDeposit' | 'acceptingDeposit' | 'acceptedDeposit') => any;
  onClose: () => void;
  open: boolean;
  depositStatus: string;
}

export const DepositDetailDrawer: React.FC<Props> = props => {
  if (!props.open) {
    return <GcvDrawer open={false} onClose={props.onClose} />;
  }
  const [loadingDeposit, setLoadingDeposit] = useState(false);
  const [datePostedIsDateReceived, setDatePostedIsDateReceived] = useState(true);
  const [dispUsersMap, setDispUsersMap] = useState({});
  const [formComplete, setFormComplete] = useState<boolean>(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [deposit, setDeposit] = useState<Deposit>();
  const [defaultValues, setDefaultValues] = useState<{
    deliveryMethod: any;
    dateReceived: string;
    datePosted: string;
    additionalNotes: string;
  }>();
  const [depositStatus, setDepositStatus] = useState<string>();
  const [snackBarMessage, setsnackBarMessage] = useState<string>();
  const apiClient = api();
  console.log('dispMap', props.dispensaryMap);
  useEffect(() => {
    getDepositInfo();
  }, []);

  const getDepositInfo = async () => {
    const deposit = await apiClient.banks.getDeposit(props.bankId, props.depositId, setLoadingDeposit);
    getDispensaryUsers(deposit.dispensary_id);
    setDeposit(deposit);
    setDepositStatus(deposit.status);
    const defaultValues = getFormDefaultValues(props.dispensaryMap, deposit);
    setDefaultValues(defaultValues);
    reset(defaultValues);
  };

  const getDispensaryUsers = async dispensaryId => {
    await apiClient.organizations
      .getUsersForOrg(dispensaryId, () => {})
      .then(res => {
        setDispUsersMap(
          res.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
          }, {})
        );
      });
  };

  const getFormattedMethodsOfTransportation = methods => {
    const formattedMethods = [];
    Object.keys(methods).forEach(key => {
      methods[key].forEach(val => {
        if (val.active) {
          const reactSelectOption = { label: val.name, value: val };
          formattedMethods.push(reactSelectOption);
        }
      });
    });
    return formattedMethods;
  };

  const getFormDefaultValues = (
    dispensaryMap,
    deposit: Deposit
  ): { deliveryMethod: any; dateReceived: string; datePosted: string; additionalNotes: string } => {
    const dispensary = dispensaryMap[deposit.dispensary_id];
    const methodsOfTransportation = dispensary ? dispensary.methodOfTransportation : {};
    const methodsOfTransportationFormatted = getFormattedMethodsOfTransportation(methodsOfTransportation);
    const initialdelivery_method = methodsOfTransportationFormatted.find(val => val.value.id === deposit.delMethod.id);

    const defaultValues = {
      deliveryMethod: initialdelivery_method,
      dateReceived: deposit.arrived_date
        ? dateFormatterFromISO(deposit.arrived_date)
        : dateFormatterFromISO(deposit.expected_arrival_date),
      datePosted: deposit.posted_date ? dateFormatterFromISO(deposit.posted_date) : '',
      additionalNotes: deposit.bank_comments ? deposit.bank_comments : '',
    };

    return defaultValues;
  };

  const { register, handleSubmit, reset, ...form } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (deposit && deposit.status === 'accepted') {
      setLoadingDeposit(false);
    }
    setDepositStatus(deposit?.status);
  }, [deposit?.status]);

  const { watch } = form;

  useEffect(() => {
    setFormComplete(
      watch('deliveryMethod') && watch('dateReceived') && ((datePostedIsDateReceived || watch('datePosted')) as boolean)
    );
  }, [watch('deliveryMethod'), watch('dateReceived'), watch('datePosted'), datePostedIsDateReceived]);

  const toggleDatePostedIsDateReceived = () => {
    setDatePostedIsDateReceived(!datePostedIsDateReceived);
  };

  const onReconcileSubmit = (formData: any) => {
    props.emitData(deposit, 'acceptingDeposit');
    deposit.accepted_date = DateTime.local()
      .toUTC()
      .toISO();
    deposit.final_deposit =
      typeof formData.depositAmountReceived === 'number'
        ? convertNumberToWholeCents(formData.depositAmountReceived)
        : convertStringNumberToWholeCents(formData.depositAmountReceived);
    deposit.delMethod = formData.deliveryMethod.value;
    deposit.bank_comments = formData.additionalNotes;
    deposit.arrived_date = dateFormatterToISO(formData.dateReceived);
    deposit.posted_date = dateFormatterToISO(datePostedIsDateReceived ? formData.dateReceived : formData.datePosted);

    setLoadingDeposit(true);
    apiClient.banks
      .acceptDeposit(props.bankId, deposit.dispensary_id, deposit.deposit_id, deposit, () => {})
      .then(updatedDeposit => {
        setDeposit(updatedDeposit);
        setDepositStatus(updatedDeposit.status);
        props.emitData(updatedDeposit, 'acceptedDeposit');
      })
      .catch(e => {
        setsnackBarMessage(`Could not reconcile deposit.`);
        setSnackBarOpen(true);
      })
      .finally(() => {
        setLoadingDeposit(false);
      });
  };

  if (
    loadingDeposit ||
    (props.userMap && !Object.keys(props.userMap).length) ||
    (props.userMap && !Object.keys(dispUsersMap).length) ||
    (props.dispensaryMap && !Object.keys(props.dispensaryMap).length)
  ) {
    return (
      <GcvDrawer open={true} onClose={props.onClose} style={{ width: '482px' }}>
        <GcvLoading />
      </GcvDrawer>
    );
  }

  const onChangeStatus = async status => {
    if (status.value === deposit.status) {
      return;
    }
    let endpoint;
    let body = {};
    if (status.value === 'under_review') {
      endpoint = 'under-review';
      body = { reviewStartDate: DateTime.utc().toISO() };
    } else if (status.value === 'pending') {
      endpoint = 'pending';
    }

    setLoadingDeposit(true);
    await apiClient.banks
      .updateDepositStatus(props.bankId, deposit.dispensary_id, deposit.deposit_id, endpoint, body, () => {})
      .then(response => {
        const defaultValues = getFormDefaultValues(props.dispensaryMap, response as Deposit);
        reset(defaultValues);
        props.emitData(response, 'updateDeposit');
        setDeposit(response);
        setDepositStatus(status.value);
        setsnackBarMessage(`Deposit status set to ${status.value === 'pending' ? 'Pending' : 'Under Review'}`);
        setSnackBarOpen(true);
        setLoadingDeposit(false);
      })
      .catch(e => {
        setsnackBarMessage(`Could not update status, please try again.`);
        setSnackBarOpen(true);
      })
      .finally(() => {
        setLoadingDeposit(false);
      });
  };

  const depositOptions = [
    { value: 'under_review', label: 'Under Review' },
    { value: 'pending', label: 'Pending' },
  ];

  if (deposit.status === 'accepted') {
    depositOptions.push({ value: 'accepted', label: 'Reconciled' });
  }

  return (
    <GcvDrawer open={props.open} onClose={props.onClose} style={{ width: '482px' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBarOpen}
        autoHideDuration={4200}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarMessage}
      />
      <DrawerContent data-cy="deposit-detail-drawer">
        <Header>
          <div>
            <BackButtonIcon onClick={props.onClose} />
            <HeaderContent>
              Deposit Record
              <SubTitle style={{ marginTop: '0px' }}>
                <GcvUserInputContainer
                  string={deposit['dispensary_name']}
                  length={36}
                  data-cy="deposit-dispensary-name"
                />
              </SubTitle>
            </HeaderContent>
          </div>
          <div>
            <SubContent style={{ margin: '0px', overflow: 'visible' }} data-cy="status-indicator">
              <GcvInputSelectNew
                options={depositOptions}
                onChange={onChangeStatus}
                defaultValueDrop={depositStatus}
                dropWidth={'150px'}
                dropCharLength={12}
                buttonStyleOverride={{ backgroundColor: '#f2f4fa' }}
              ></GcvInputSelectNew>
            </SubContent>
          </div>
        </Header>
        <Body>
          <Block style={{ paddingLeft: '26px' }}>
            <SubTitle>
              {deposit.status === 'accepted' ? `Deposit Amount Received` : `Anticipated Deposit Amount`}
            </SubTitle>
            <SubContent
              style={{
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '27px',
              }}
            >
              <GcvUserInputContainer string={formatMoney(deposit.final_deposit)} length={36} data-cy="deposit-amount" />
            </SubContent>
          </Block>
          <GcvCollapsibleSection label={'Customer Provided Details'}>
            <div style={{ marginLeft: '26px' }}>
              {deposit.status === 'accepted' ? (
                <>
                  <SubTitle>Anticipated Deposit Amount</SubTitle>
                  <SubContent>
                    {formatMoney(
                      deposit.anticipated_deposit_amount ? deposit.anticipated_deposit_amount : deposit.final_deposit
                    )}
                  </SubContent>
                </>
              ) : null}

              <SubTitle data-cy="deposit-date-title">
                {props.type === 'bank' ? 'Anticipated Receipt Date' : 'Planned Arrival Date'}
              </SubTitle>
              <SubContent data-cy="deposit-date">
                {DateTime.fromISO(deposit.expected_arrival_date).toLocaleString(DateTime.DATE_MED)}
              </SubContent>

              <SubTitle data-cy="deposit-mot-title">
                {props.type === 'bank' ? 'Anticipated Method of Transportation' : 'Planned Method of Transportation'}
              </SubTitle>
              <SubContent>
                <GcvUserInputContainer string={deposit.delMethod.name} length={36} data-cy="deposit-mot-name" />
              </SubContent>

              <SubTitle data-cy="deposit-comment-title">Customer Comment</SubTitle>
              <GcvReadMore
                string={deposit.comments ? deposit.comments : 'No comments were included with this deposit'}
                length={125}
                data-cy="comments"
              ></GcvReadMore>
            </div>
          </GcvCollapsibleSection>
          <GcvCollapsibleSection label={'Record Details'}>
            <div style={{ marginLeft: '26px' }}>
              <SubTitle>Max Deposit</SubTitle>
              <SubContent>
                <GcvUserInputContainer
                  string={formatMoney(deposit.max_deposit)}
                  length={36}
                  data-cy="deposit-max-deposit"
                ></GcvUserInputContainer>
              </SubContent>
              <SubTitle>Recorded By</SubTitle>
              <SubContent>
                <GcvUserInputContainer
                  string={
                    dispUsersMap[deposit.created_by]
                      ? `${dispUsersMap[deposit.created_by]['firstName']} ${
                          dispUsersMap[deposit.created_by]['lastName']
                        }`
                      : ''
                  }
                  length={36}
                  data-cy="reconciled-by"
                ></GcvUserInputContainer>
              </SubContent>
              <SubTitle>Recorded On</SubTitle>
              <SubContent data-cy="deposit-header-date">{formatISOToDateAtTime(deposit.deposit_date)}</SubContent>
              <SubTitle>Deposit ID</SubTitle>
              <SubContent>
                <GcvUserInputContainer string={deposit.deposit_id} length={50} data-cy="deposit-id" />
              </SubContent>
            </div>
          </GcvCollapsibleSection>

          {deposit.status !== 'accepted' ? (
            <div style={{ marginLeft: '26px' }}>
              <DepositDetailDrawerDepositForm
                dispensaryMap={props.dispensaryMap}
                datePostedIsDateReceived={datePostedIsDateReceived}
                deposit={deposit}
                form={form}
                getFormattedMethodsOfTransportation={getFormattedMethodsOfTransportation}
                register={register}
                toggleDatePostedIsDateReceived={toggleDatePostedIsDateReceived}
                type={props.type}
              />
            </div>
          ) : (
            <GcvCollapsibleSection label={'Reconciliation Details'} defaultOpen={true}>
              <div style={{ marginLeft: '26px' }}>
                <DepositDetailDrawerReconciledForm deposit={deposit} type={props.type} userMap={props.userMap} />
              </div>
            </GcvCollapsibleSection>
          )}
        </Body>

        {deposit.status !== 'accepted' ? (
          <DepositDetailDrawerFooter
            type={props.type}
            formComplete={formComplete}
            handleSubmit={handleSubmit(onReconcileSubmit)}
          />
        ) : null}
      </DrawerContent>
    </GcvDrawer>
  );
};
