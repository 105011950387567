import React from 'react';
import { Oval } from 'svg-loaders-react';
import { $primary } from '../../util/styleVariables';

import { LoadingContainer } from './styles';

interface LoadingProps {
  style?: any;
  small?: Boolean;
}

export const GcvLoading = (props: LoadingProps) => {
  return (
    <LoadingContainer style={props.style} data-cy="loading-spinner">
      <Oval height={props.small ? 40 : 75} width={props.small ? 40 : 75} stroke={$primary} viewBox="0 0 38 38" />
    </LoadingContainer>
  );
};
