import { NgModule } from '@angular/core';
import { FormatCurrencyCustom } from './format-currency/formatCurrency.pipe';
import { TimeFormatterPipe } from './format-time/time-formatter.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { FormatDynamicTitlePipe } from './format-dynamic-tittle/format-dynamic-title.pipe';
import { FormatPercentCustom } from './formatPercent/formatPercent.pipe';
import { DynamicNotificationPipe } from './format-dynamic-notification/format-dynamic-notification.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { EinPipe } from './format-ein/format-ein.pipe';
import { ZipPipe } from './format-zipcode/zip.pipe';
import { FileNamePipe } from './format-file-name/format-file-name.pipe';
import { IntegerPipe } from './format-integer/integer-formatter.pipe';
import { CalculateReportDaysRemaining } from './calculate-report-days/calculateReportDaysRemaining.pipe';
import { FormatReportDatePipe } from './format-report-date/format-report-date.pipe';
import { FormatPhonePipe } from './format-phone/phone-formatter.pipe';
import { FormatNamePipe } from './format-name/format-name.pipe';
import { CalculateTotalDepositedPipe } from './calculate-total-deposit/calculate-total-deposit.pipe';
import { FormatISODatePipe } from './format-iso-date/format-iso-date.pipe';

@NgModule({
  declarations: [
    CalculateTotalDepositedPipe,
    FormatNamePipe,
    FormatCurrencyCustom,
    TimeFormatterPipe,
    TruncatePipe,
    FormatDynamicTitlePipe,
    FormatPercentCustom,
    DynamicNotificationPipe,
    EinPipe,
    ZipPipe,
    FileNamePipe,
    IntegerPipe,
    CalculateReportDaysRemaining,
    FormatReportDatePipe,
    FormatPhonePipe,
    FormatISODatePipe,
  ],
  imports: [CommonModule],
  providers: [
    CalculateTotalDepositedPipe,
    FormatNamePipe,
    DatePipe,
    FormatCurrencyCustom,
    TimeFormatterPipe,
    TruncatePipe,
    FormatDynamicTitlePipe,
    FormatPercentCustom,
    DynamicNotificationPipe,
    EinPipe,
    ZipPipe,
    FileNamePipe,
    IntegerPipe,
    CalculateReportDaysRemaining,
    FormatReportDatePipe,
    FormatPhonePipe,
    FormatISODatePipe,
  ],
  exports: [
    CalculateTotalDepositedPipe,
    FormatNamePipe,
    FormatCurrencyCustom,
    TimeFormatterPipe,
    TruncatePipe,
    FormatDynamicTitlePipe,
    FormatPercentCustom,
    DynamicNotificationPipe,
    EinPipe,
    ZipPipe,
    FileNamePipe,
    IntegerPipe,
    CalculateReportDaysRemaining,
    FormatReportDatePipe,
    FormatPhonePipe,
    FormatISODatePipe,
  ],
})
export class PipesModule {}
