import React, { ReactNode } from 'react';
import {
  Card as CardContainer,
  CardHeader as CardHeaderContainer,
  CardContent as CardContentContainer,
  CardActions as CardFooterContainer,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: '0 1rem',
      overflow: 'visible',
      boxShadow: 'none',
      border: '1px solid rgb(219,224,235)',
      borderRadius: '7px',
    },
    title: {
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    header: {
      padding: '1rem',
    },
    actions: {
      fontSize: '12px',
      fontWeight: 400,
      top: '0.5rem',
      position: 'relative',
    },
    content: {
      padding: '0 1rem',
    },
    footer: {
      justifyContent: 'flex-end',
    },
  };
});

interface CardHeaderProps {
  title: string;
  actions?: ReactNode[];
}

const CardHeader: React.FC<CardHeaderProps> = props => {
  const classes = useStyles();

  return (
    <CardHeaderContainer
      title={props.title}
      titleTypographyProps={{ className: classes.title }}
      className={classes.header}
      action={
        <div className={classes.actions}>
          {props.actions &&
            props.actions.map((a, i) => {
              return <span key={i}>{a}</span>;
            })}
        </div>
      }
    />
  );
};

interface CardContentProps {}

const CardContent: React.FC<CardContentProps> = props => {
  const classes = useStyles();

  return <CardContentContainer className={classes.content}>{props.children}</CardContentContainer>;
};

interface CardProps {
  height?: string | number;
}

interface CardFooterProps {}

const CardFooter: React.FC<CardFooterProps> = props => {
  const classes = useStyles();

  return <CardFooterContainer className={classes.footer}>{props.children}</CardFooterContainer>;
};

export const Card: React.FC<CardProps> & {
  Header: React.FC<CardHeaderProps>;
  Content: React.FC<CardContentProps>;
  Footer: React.FC<CardFooterProps>;
} = props => {
  const classes = useStyles();

  return (
    <CardContainer className={classes.root} style={{ height: props.height ? props.height : 'auto' }}>
      {props.children}
    </CardContainer>
  );
};

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = CardFooter;
