import { css } from 'styled-components';

const sizes = {
  lg: 1000,
  md: 900,
  sm: 700,
  xs: 400,
};

export const mediaQuery: any = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${args}
    }
  `;
  return acc;
}, {});
