import React from 'react';
import { useForm } from 'react-hook-form';

import { GcvButton, GcvInputSelect } from '../../../lib';

import styled, { createGlobalStyle } from 'styled-components';

interface Props {
  current: boolean;
}

const TabContainer = styled.div`
  height: 30rem;
`;

const Tab = styled.div`
  background: #ffffff;
  border: 1px solid #ebeef7;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 45px;
  width: 60%;
  margin: 0 auto;

  h1 {
    margin-bottom: 30px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const Label = styled.label`
  margin: 5px 0px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;

  input {
    margin 0px 10px;
  }
`;

const FormContent = styled.div`
  margin: 5px 0px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CreateReportTab2 = ({ next, back, data }) => {
  const form = useForm();

  const options = Object.keys(data.dispensaries).map(dispensaryId => ({
    value: dispensaryId,
    label: data.dispensaries[dispensaryId].name,
  }));

  options.sort((a, b) => a.label.localeCompare(b.label));

  const onBack = () => {
    back();
  };

  return (
    <>
      <TabContainer>
        <Tab>
          <h1>Account</h1>
          <FormContent data-cy="account-select">
            <GcvInputSelect
              name={'account'}
              label={'Account'}
              options={options}
              rules={{ required: true }}
              defaultValue={data['account'] || ''}
              onChangeCallback={val => data.loadDispensaryData(val.value)}
              {...form}
            />
          </FormContent>
        </Tab>
      </TabContainer>
      <Buttons>
        <GcvButton onClick={onBack} tertiary={true} data-cy="back-button">
          Back
        </GcvButton>
        <GcvButton onClick={form.handleSubmit(next)} primary={true} data-cy="next-button">
          Next
        </GcvButton>
      </Buttons>
    </>
  );
};
