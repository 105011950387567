export const errorMessages = {
  required: 'is a required field',
  maxLength: 'exceeded maximum length',
  minLength: 'is too short',
  max: 'number is too high',
  min: 'number is too low',
  pattern: "doesn't match the correct pattern",
};

export const validateEmailAddress = email => {
  const pattern = new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
  return pattern.test(email);
};

export const moneyRegex = `^[+-]?[$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]?[0-9])?$`;
