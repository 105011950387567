import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { Dispensary, Bank, DueDiligenceStatus, DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE, DocumentChecker } from '@gcv/shared';
import { GcvButton } from '../../../lib';
import { RequirementsTable } from '../../../components/Requirements/RequirementsTable/RequirementsTable';
import { SectionTypes } from '../Account-Review-Overview.page';
import { Header, Title, VerticalCenter } from '../styles';
import { BackButtonIcon } from 'libs/react-ui/src/icons/BackButtonIcon';

interface Props {
  dispensary?: Dispensary;
  canUserApproveAccounts: boolean;
  bank: Bank;
  onSave: (type: SectionTypes) => void;
  onBack: () => void;
  isActiveAccount?: boolean;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const AccountDocuments = ({
  dispensary,
  bank,
  canUserApproveAccounts,
  onSave,
  onBack,
  isActiveAccount,
}: Props) => {
  const history = useHistory();
  const query = useQuery();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const returnPage = parseInt(query.get('returnPage'));

    if (returnPage) {
      setPage(returnPage);
    }
  }, []);

  const isAwaitingReview = dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_AWAITING_REVIEW;
  const isAlreadyReviewed = dispensary.due_diligence.due_diligence_documentation_section_bank_review_complete;

  let areAllDocumentsReviewed = DocumentChecker.isDueDiligenceApproved(
    dispensary,
    DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE,
    bank.due_diligence_template
  );

  const emitData = (type, reqId, dispensaryId, returnPage) => {
    if (type === 'pageChange') {
      setPage(returnPage);
    }

    if (type === 'rowClick') {
      history.push(`/secure/bank/account/${dispensaryId}/requirement/${reqId}?returnPage=${page}`);
    }
  };

  return (
    <>
      {!isActiveAccount && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Header style={{ justifyContent: 'start' }}>
              <BackButtonIcon onClick={onBack} style={{ cursor: 'pointer' }} />
              <VerticalCenter style={{ marginLeft: '1rem' }}>
                <Title>Due Diligence Documents</Title>
              </VerticalCenter>
            </Header>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontFamily: 'Lato, Helvetica, Arial, san-serif' }}>
              You are reviewing: <strong>{dispensary.name}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <RequirementsTable
            dispensary={dispensary}
            template={bank.due_diligence_template}
            emitData={emitData}
            page={page}
          ></RequirementsTable>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          {isAwaitingReview && !isAlreadyReviewed ? (
            <GcvButton
              primary={true}
              onClick={() => onSave('documentation')}
              disabled={!areAllDocumentsReviewed || !canUserApproveAccounts}
              data-cy="button.component.save"
            >
              Save
            </GcvButton>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
