import styled from 'styled-components';
import { $grey11 } from '../../util';

export const ExpansionSectionContainer = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${$grey11};

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

export const SectionHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  cursor: pointer;
`;
