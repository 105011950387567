import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const notificationsClient = (API: APIClass) => {
  return {
    getNotifications: async (userId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/notifications/${userId}`, setIsLoading);
    },

    getNotificationsSince: async (userId: string, dateSince: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/notifications/${userId}/${dateSince}`, setIsLoading);
    },

    updateNotification: async (
      notificationId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/notifications/${notificationId}`, body, setIsLoading);
    },

    deleteNotification: async (notificationId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/notifications/${notificationId}`, setIsLoading);
    },
  };
};
