import styled from 'styled-components';

import { $accent } from '../../../util/styleVariables';

export const SubTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  width: fit-content;
  margin: 26px auto 33px auto;
  color: ${$accent};
`;

export const UploadContainer = styled.div`
  width: 400px;
  margin: 20px auto;
`;

export const VerticalSpacer = styled.div`
  margin: 20px 0;
`;
