import React from 'react';
import { GcvUserInputContainer } from '../../lib/GcvUserInputContainer/GcvUserInputContainer';

export const category = name => ({
  name: name,
  selector: 'category',
  sortable: true,
  grow: 1,
});

export const requirement = (name, clickHandler, type) => ({
  name: name,
  selector: 'name',
  sortable: true,
  grow: 3,
  format: row => {
    const onClick = () => clickHandler(row, type);
    return (
      <div>
        <GcvUserInputContainer string={row.name} length={50} clickHandler={onClick} type="document" />
      </div>
    );
  },
});
