<div class="container">
  <div class="header">
    <div class="titleContainer">
      <h1 class="docName">{{ documentName }}</h1>
    </div>
    <div *ngIf="docDescription.length > 0" class="descriptionContainer">
      <p>{{ docDescription }}</p>
    </div>
  </div>
  <div class="content-wrapper">
    <!--    index === 0-->
    <img
      *ngIf="docData.documentData.length > 1"
      (click)="prevDocument()"
      alt="back-arrow"
      src="../../../assets/back-arrow.svg"
    />
    <div class="iframeWrapper">
      <iframe #pdfView allowfullscreen class="resp-iframe" frameborder="0"></iframe>
    </div>
    <!--    index === docData.documentData.length - 1-->
    <img
      *ngIf="docData.documentData.length > 1"
      (click)="nextDocument()"
      alt="forward-arrow"
      src="../../../assets/forward-arrow.svg"
    />
  </div>
  <div class="btnWrapper">
    <gcv-button
      (clickEvent)="cancel()"
      [attr.data-cy]="'review-account-document.component.cancelBtn'"
      buttonText="Cancel"
      type="tertiary"
    ></gcv-button>
    <gcv-button
      (clickEvent)="submit()"
      *ngIf="!isDispensary && !approved"
      [disabled]="!isDisabled()"
      [attr.data-cy]="'review-account-document.component.approveBtn'"
      buttonText="Approve"
      type="primary"
    ></gcv-button>
  </div>
</div>

<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>Document</title>
  </head>

  <body></body>
</html>
