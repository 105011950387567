<div class="wrapper">
  <div class="header">
    <h2 data-cy="title">{{ section }}</h2>
    <p data-cy="subTitle" class="headerDetails">{{ details }}</p>
  </div>
  <div class="body">
    <div class="leftContainer">
      <div class="details" data-cy="review-body">
        <ng-content select="[card-container]"></ng-content>
      </div>
      <div class="buttonWrapper">
        <ng-content select="[button-container]"></ng-content>
      </div>
    </div>
  </div>
</div>
