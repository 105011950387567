// tslint:disable
/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
// tslint:enable

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { PasswordStrengthValidator } from '../../validators/passwordStrength';
import { setAppReady } from '../../cypress-tools';
import { checkRequirements } from '../password-util';

@Component({
  selector: 'app-amplify-auth-require-new-password-core',
  templateUrl: './require-new-password.component.html',
  styleUrls: ['./require-new-password.component.scss'],
})
export class RequireNewPasswordComponent implements OnInit {
  newPassword: string;
  @Input() oldPassword: string;
  @Input() email: string;
  @Output() errorMessage = new EventEmitter();
  @Output() passwordChanged = new EventEmitter();
  @Output() isLoading = new EventEmitter();
  amplifyService: AmplifyService;
  changePasswordData: FormGroup;
  requirements = {
    length: false,
    special: false,
    number: false,
    lower: false,
    upper: false,
  };

  constructor(amplifyService: AmplifyService) {
    this.amplifyService = amplifyService;
  }

  ngOnInit() {
    const password = new FormControl('', [Validators.required, Validators.minLength(10), PasswordStrengthValidator]);
    const certainPassword = new FormControl('', CustomValidators.equalTo(password));
    this.changePasswordData = new FormGroup({
      oldPass: new FormControl(this.oldPassword, Validators.required),
      newPass: password,
      newPassCheck: certainPassword,
    });
    password.valueChanges.subscribe(pw => {
      this.requirements = checkRequirements(pw);
    });
    setAppReady();
  }

  isValid() {
    return this.changePasswordData.valid;
  }

  onSubmit() {
    this.isLoading.emit(true);
    const { oldPass, newPass } = this.changePasswordData.getRawValue();
    this.amplifyService
      .auth()
      .signIn(this.email.toLowerCase(), oldPass)
      .then(user => {
        this.amplifyService
          .auth()
          .changePassword(user, oldPass, newPass)
          .then(() => {
            this.passwordChanged.emit(true);
            this.amplifyService.setAuthState({ state: 'signedIn', user });
          })
          .catch(err => {
            this.isLoading.emit(false);
            this._setError(err);
          });
      })
      .catch(err => {
        this.isLoading.emit(false);
        this._setError(err);
      });
  }

  onAlertClose() {
    this._setError(null);
  }

  _setError(err) {
    if (!err) {
      return;
    }

    this.errorMessage.emit(err.message || err);
  }
}
