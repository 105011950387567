import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gcv-onboarding-end-wrapper',
  templateUrl: './onboarding-end-wrapper.component.html',
  styleUrls: ['./onboarding-end-wrapper.component.scss'],
})
export class OnboardingEndWrapperComponent implements OnInit {
  @Input() section: string;
  @Input() details = 'Take a minute to make sure the information you provided is correct.';

  constructor() {}

  ngOnInit() {}
}
