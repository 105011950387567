import styled from 'styled-components';
import { $primaryBorder } from '../../util';

export const HoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid ${$primaryBorder};
`;

export const HoursContainerLast = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const Day = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
`;

export const Time = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
`;

export const Label = styled.div`
  font-size: 13px;
  margin-top: 0px;
  padding: 5px 0;
  color: #a5a8ba;
  font-family: Lato, Helvetica, Arial, san-serif;
  line-height: 1.25;
`;
