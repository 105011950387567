import React from 'react';
import { DateTime } from 'luxon';

import { Dispensary, DueDiligenceStatus } from '@gcv/shared';
import { BodySubContainer, BodySubTitle, BodySubContent } from './styles';
import { GcvCard, GcvButton } from '../../../lib';
import { formatMissingDash } from '../../../util';
import { Grid, Typography } from '@material-ui/core';
import { SectionTypes } from '../Account-Review-Overview.page';
import { entitytypes } from 'libs/gcv-ui/src/lib';
import { Header, ReviewText, Title, VerticalCenter } from '../styles';
import { BackButtonIcon } from 'libs/react-ui/src/icons/BackButtonIcon';

interface Props {
  dispensary?: Dispensary;
  canUserApproveAccounts: boolean;
  onSave: (type: SectionTypes) => void;
  onBack: () => void;
  isActiveAccount?: boolean;
}

export const AccountGeneral = ({ dispensary, canUserApproveAccounts, onSave, onBack, isActiveAccount }: Props) => {
  const isAwaitingReview = dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_AWAITING_REVIEW;
  const isAlreadyReviewed = dispensary.due_diligence.business_details_section_bank_review_complete;

  return (
    <>
      {!isActiveAccount && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Header style={{ justifyContent: 'start' }}>
              <BackButtonIcon onClick={onBack} style={{ cursor: 'pointer' }} />
              <VerticalCenter style={{ marginLeft: '1rem' }}>
                <Title>Business Details</Title>
              </VerticalCenter>
            </Header>
            <ReviewText>Look over the business’s identification, address and contact details.</ReviewText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontFamily: 'Lato, Helvetica, Arial, san-serif' }}>
              You are reviewing: <strong>{dispensary.name}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <GcvCard data-cy="business-details-card">
            <GcvCard.Header title={<strong>Business Details</strong>} data-cy="business-details-title" />
            <GcvCard.Body>
              <BodySubContainer>
                <BodySubTitle>Legal Entity Name</BodySubTitle>
                <BodySubContent data-cy="business-details-entity-name">
                  {formatMissingDash(dispensary.name)}
                </BodySubContent>
              </BodySubContainer>
              <BodySubContainer>
                <BodySubTitle>Entity Type</BodySubTitle>
                <BodySubContent data-cy="business-details-entity-type">
                  {formatMissingDash(
                    entitytypes.find(t => t.value === dispensary.entityType)
                      ? entitytypes.find(t => t.value === dispensary.entityType).viewValue
                      : '--'
                  )}
                </BodySubContent>
              </BodySubContainer>
              <BodySubContainer>
                <BodySubTitle>Date of Business Establishment</BodySubTitle>
                <BodySubContent data-cy="business-details-established-date">
                  {dispensary.establishedDate ? DateTime.fromISO(dispensary.establishedDate).toLocaleString() : '--'}
                </BodySubContent>
              </BodySubContainer>
              <BodySubContainer>
                <BodySubTitle>Employer Identification Number (EIN)</BodySubTitle>
                <BodySubContent data-cy="business-details-ein">{formatMissingDash(dispensary.ein)}</BodySubContent>
              </BodySubContainer>
            </GcvCard.Body>
          </GcvCard>
        </Grid>

        <Grid item xs={4}>
          <GcvCard data-cy="address-card">
            <GcvCard.Header title={<strong>Address</strong>} data-cy="address-title" />
            <GcvCard.Body>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BodySubTitle>Address</BodySubTitle>
                  <BodySubContent data-cy="address-location">
                    {formatMissingDash(dispensary.streetAddress)}
                  </BodySubContent>
                </Grid>

                <Grid item xs={6}>
                  <BodySubTitle>City</BodySubTitle>
                  <BodySubContent data-cy="address-city">{formatMissingDash(dispensary.city)}</BodySubContent>
                </Grid>
                <Grid item xs={6}>
                  <BodySubTitle>Zip Code</BodySubTitle>
                  <BodySubContent data-cy="address-zip">{formatMissingDash(dispensary.postalCode)}</BodySubContent>
                </Grid>

                <Grid item xs={6}>
                  <BodySubTitle>State</BodySubTitle>
                  <BodySubContent data-cy="address-state">{formatMissingDash(dispensary.state)}</BodySubContent>
                </Grid>
                <Grid item xs={6}>
                  <BodySubTitle>Country</BodySubTitle>
                  <BodySubContent data-cy="address-country">United States</BodySubContent>
                </Grid>
              </Grid>
            </GcvCard.Body>
          </GcvCard>
        </Grid>

        <Grid item xs={4}>
          <GcvCard data-cy="contact-card">
            <GcvCard.Header title={<strong>Contact Information</strong>} data-cy="contact-title" />
            <GcvCard.Body>
              <BodySubContainer>
                <BodySubTitle>Phone</BodySubTitle>
                <BodySubContent data-cy="contact-phone">{formatMissingDash(dispensary.phone)}</BodySubContent>
              </BodySubContainer>
              <BodySubContainer>
                <BodySubTitle>Business Website</BodySubTitle>
                <BodySubContent data-cy="contact-website">{formatMissingDash(dispensary.website)}</BodySubContent>
              </BodySubContainer>
            </GcvCard.Body>
          </GcvCard>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          {isAwaitingReview && !isAlreadyReviewed ? (
            <GcvButton
              primary={true}
              disabled={!canUserApproveAccounts}
              onClick={() => onSave('business')}
              data-cy="button.component.save"
            >
              Save
            </GcvButton>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
