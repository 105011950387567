import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const permissionsClient = (API: APIClass) => {
  return {
    getS3Permission: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/permissions/s3`, body, setIsLoading);
    },
  };
};
