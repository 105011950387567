<div class="container">
  <div class="header">
    <div class="titleContainer">
      <h1 class="docName">{{ documentName }}</h1>
    </div>
    <div *ngIf="docDescription.length > 0" class="descriptionContainer">
      <p>{{ docDescription }}</p>
    </div>
  </div>
  <div class="iframeWrapper">
    {{ loading ? 'Loading Document...' : null }}
    <iframe #pdfView class="resp-iframe" frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="btnWrapper">
    <gcv-button
      (clickEvent)="cancel()"
      [attr.data-cy]="'review-account-document.component.cancelBtn'"
      buttonText="Cancel"
      type="tertiary"
    ></gcv-button>
    <gcv-button
      *ngIf="!approved && isBank"
      [disabled]="!isDisabled()"
      type="primary"
      (clickEvent)="submit()"
      buttonText="Approve"
      [attr.data-cy]="'review-account-document.component.approveBtn'"
    ></gcv-button>
  </div>
</div>
