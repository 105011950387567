import { $danger } from '../util/styleVariables';
import styled from 'styled-components';

export const AmplifyScssStyledPort = styled.div`
  body {
    font-family: var(--font-family);
  }

  h1 {
    height: 50px;
    font-weight: 400;
  }

  h2 {
    height: 42px;
    font-weight: 300;
  }

  h4 {
    height: 28px;
    font-weight: 400;
  }

  h5 {
    height: 21px;
    font-weight: 400;
  }

  p {
    height: 16px;
    font-weight: 300;
  }

  span {
    color: var(--color-gray);
  }

  input:disabled {
    background-color: #ededed;
  }

  .amplify-component {
    display: block;
  }

  .amplify-container {
    height: auto;
    padding-bottom: 1em;
  }

  /********************************************
 ** Auth
 *******************************************/

  .amplify-authenticator {
    width: var(--component-width-desktop);
    margin: 0 auto;
    border-radius: 6px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
  }

  .amplify-signup-container {
    height: 570px;
  }

  .amplify-reset-password-container {
    height: 270px;
  }

  .amplify-confirm-reset-password-container {
    height: 360px;
  }

  .amplify-confirm-signup-container {
    height: 370px;
  }

  .amplify-confirm-signin-container {
    height: 260px;
  }

  .amplify-form-actions-left {
    margin-top: 1em;
    color: var(--color-gray);
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .amplify-authenticator {
      width: var(--component-width-mobile);
      box-shadow: none;
    }
  }

  /********************************************
 ** Storage
 *******************************************/

  .amplify-photo-picker {
    width: var(--component-width-desktop);
    margin: 1em auto;
    border-radius: 6px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
  }

  .amplify-photo-picker-container {
    width: 400px;
    margin: 0 auto;
    padding: 1em;
  }

  .amplify-album {
    width: var(--component-width-desktop);
    margin: 1em auto;
    border-radius: 6px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
  }

  .amplify-album-container {
    width: 400px;
    margin: 0 auto;
    padding: 1em;
  }

  .amplify-photo-picker-upload {
    height: 212px;
    width: 392px;
    border: 2px dotted #979797;
    border-radius: 4px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABBCAYAAACUyynLAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAASASURBVHic7ZtZe7I6FEbfEMIMbe3p//+BfRyKzBk4F2pFvyJDMdiWda2bZD1JSHY2hHNeY2EQxtwN+Iks0kawSBvBIm0Ei7QRmLofyIXEerOHVOpbcahh4HUVgpl0opb1R/tI28Xpt4UBgFQKuzidoEXD0SqtLDmqSkwWr6oEypJPFq8vWqXFSf4jYnahTVpZclR8ulF2ouL6R5s2afccEbpHGxly9uRcoKg4pFSoB5xY67pGXlRj2tcb17FACOn9e0IASg04FgNjwzYRvaRVlcBun4HfYXo9AoyZeA49WFY/eZ3S0qyc7dWum+fIh+/Znb+7uablRfVnhAGHPWSfZaRVmlL1nxJ2YhenUOr2itUqLc2Lzj//RpSqkebFzd+0Sitm2Gk/Cl19b5UmhJy8MT+Frr7fXNP+KqPXtIV2tOfTvsJiJlzHgmWZYCb93NnXdQ0uJKpKIC+qu5xdxzCrNMsyEQUe7JadOCEEFjNhMROB76CsBOIkmzS9NIbZpD1HHnzPGfQf2zLxtoqQZgV2cXanlnWjXRohBK8v4cXoklIhy0sUJYeQEkrVMAwCk1I4NoPn2qD0vPz6ngPTNLHe7lEPyRxMhHZpq+fgU1hd14iTHEn672ZSqRqVEqi4QJzkCHwHUeB+rne2ZWL1HGC93WttP6D57RmFHhybATjk+N838ZfCviJJC7xv4ov7BcdmiELvLm29hTZplBoIjhmEuq6x3u7B+bANNOfynykZeJdTVwfanhaF3ufUipN8sLATnMuLTC0hRPto0yLNMAjc07SUqveUbCNJC0h5nqauzWAY/bO230WLNMc+p6KzvJwkZjMOIQSObU0Stw9apFnsfAs+VfbkOk7zGfdGizRKzx0ScprsyXWc5jPujRZppLHeTJU9uY5Dftuahkb/plqw/4mj8WCgRVrzTWdONI2u4zSfcW+0SOPinJU4nQi+y3Wc5jPujRZpzVoLz+2+V+zDdZyy0nenoWmkSfBj3p1SA4E/LCV0TeA7F0cnLuToE8YYZimAiQIXbOS+ijGKKHAvY+/15ta0SSuKCtVxCp1yakPFMUbx+hJeFLqUFdd+3ag1PbD5SKGOqR1qGHhbRb2nauA7eFtFoMa5yVIpbD/0VwFoTUJKqbDeJvhvdRgthBA8hR4CzxmUuQUO6aXNNtG61TihPXNbcYH3dYzVSwjzKIJSA2HgIrxaq9oQUmGz3X++XHQzy70nFxLv6w+kWTm4ODDNS7yvP2YTBsx4G3WqSkqyAoHnwHWs1iOWUodKyiQrHqJcYvbLYiEkdnGKXZzCNCmYSWEc347qeFn8CKKazC6tiXhAQV/RuqYNqPn9dXT1vVWazqTeo9HV91ZpjjVNNuIn0tX3VmlTZSN+Il19b5XGGB1coPIb8D2n80x8c3P7FLqtZVC/Edsy8RR2n0o6P76o6xof+xxp9r0L3kfH9xw8hW6vT4V6fxvFhUSWHQ7VUslBx59HhBCAGseEgGcP+kJ50AdlCweWQuURLNJGsEgbwSJtBIu0ESzSRrBIG8H/BsMLCwR4M6kAAAAASUVORK5CYII=')
      center no-repeat #fbfbfb;
  }

  .amplify-upload-input {
    margin-top: 0.5em;
  }

  .amplify-upload-button {
    margin: 0.5em auto !important;
    width: 100% !important;
  }

  .amplify-photo-picker-preview {
    width: 100%;
  }

  .amplify-image-container {
    display: inline;
  }

  .amplify-image {
    width: 30%;
    margin: 0.2em;
    border-radius: 6px;
    border: 2px solid var(--color-white);
    cursor: pointer;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .amplify-photo-picker {
      width: var(--component-width-mobile);
    }
    .amplify-image {
      width: var(--component-width-mobile);
    }
    .amplify-album {
      width: var(--component-width-mobile);
    }
    .amplify-album-container {
      width: var(--component-width-mobile);
    }
    .amplify-photo-picker-container {
      width: auto;
    }
    .amplify-photo-picker-upload {
      width: auto;
    }
  }

  /**********************************************
 ** Interactions
 **********************************************/

  .amplify-interactions {
    width: var(--component-width-desktop);
    margin: 1em auto;
    border-radius: 6px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
  }

  .amplify-interactions-container {
    width: 400px;
    margin: 0 auto;
    padding: 1em;
  }

  .amplify-interactions-button {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAG2SURBVFiF7dfPKwRhHMfx9+xqhaItIg5KikgOlER2TzhQ4i+QwtndQe4O4uhCSQ57sC6SkotfR1uK2nWgrGJr8yvsjsO2bWtb+8w+88zOYT6nmWfmeebV95lfj6bruo6N4yo1oFAcoGwcoGwcoGwcoGxsDyyz5CqxawhtwPsTNA1C5wxobqGumvJv8d0+HM5B4jvT1jICw1tC3dVO8ds9VNaD25PdHj6A6LnQEOqAkSBs90HsBsZ2wVOVfTweFhpGDTAShMPZ1LQeL+QiNQ1qu0sETOOSidS+ruciu6bB2yE0nLkPyV9c1pU08K9AQx9Ut4AmVhvzKvgfDlKVfDyFmlZhHJgFLIQDaJ+CoVXDQ8sDRXG+dUOVS0cOqBgHMkARXJscDooFiuL8cjgoBmghDoy+B+MR2BmA5I8lODBSQT0JiU/oX8x/jsk4MAJ8CcHeBDQNwcCSJTgwAoxewkcMgpO5SEU4MHIPHs3DbSC1XeGF8QA8nMDzFfjWlODAyC9/9Cyz/fUKF8swvAkuT/4+JkQc2DwKVY3Q0At1PeAuV8jKRP2aRDK2X3Y6QNk4QNk4QNnYHvgLzPueuQw6nCEAAAAASUVORK5CYII=')
      center no-repeat var(--color-white);
    border: none;
    cursor: pointer;
    width: 32px;
  }

  .amplify-interactions-actions {
    display: flex;
    border-top: var(--input-border);
    margin-bottom: -1em;
    margin-left: -1.9em;
    margin-right: -1.9em;
  }

  .amplify-interactions-actions > input[type='text'] {
    border: none;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .amplify-interactions-actions > input[type='text']:focus {
    border: 0px solid var(--color-white) !important;
  }

  .amplify-interactions-conversation {
    margin: 1em;
  }

  .amplify-interactions-input {
    padding: 1em;
    margin: 1em;
    width: 75%;
    margin-left: 5em;
    border-radius: 20px 20px 0 20px;
    background-color: #009ecf;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.1);
    color: var(--color-white);
    font-size: 13px;
    line-height: 16px;
  }

  .amplify-interactions-input-timestamp {
    color: #828282;
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-align: right;
  }

  .amplify-interactions-response-timestamp {
    color: #828282;
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin-left: 1.5em;
  }

  .amplify-interactions-response {
    padding: 1em;
    margin: 1em;
    width: 75%;
    border-radius: 20px 20px 20px 0;
    background-color: #dbdbdb;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: 13px;
    line-height: 16px;
    color: #4a4a4a;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .amplify-interactions {
      width: var(--component-width-mobile);
    }
    .amplify-interactions-container {
      width: 85%;
    }
  }

  /************************************
 ** Input Groups
 ************************************/

  .amplify-input-group {
    display: flex;
    margin-left: 0.3em;
    margin-top: 0.5em;
  }

  .amplify-input-group > div:first-of-type {
    float: left;
  }

  .amplify-input-group > div:last-child > input {
    border-left: none !important;
    width: 100%;
  }

  .amplify-input-group > div:last-child {
    display: flex;
    width: 100%;
  }

  .amplify-input-group > div {
    float: right;
  }

  .amplify-input-group-item > input {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .amplify-input-group-label {
    display: block;
  }

  .amplify-select-phone-country {
    height: 55px;
    width: 68px;
    border: var(--input-border);
    border-radius: 3px 0 0 3px;
    background-color: transparent;
  }

  /** Ionic styles for material design */
  .md .amplify-select-phone-country {
    border: none;
    border-bottom: var(--input-border);
    background-color: transparent;
    margin-top: 0em;
    min-width: 100% !important;
    height: 47px !important;
    padding-left: 0;
  }

  .amplify-form-input-phone-ionic {
    height: 36px;
    min-width: 100%;
    color: var(--color-accent-brown);
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 20px;
    border: none;
    border-bottom: var(--input-border);
    border-radius: 0px;
  }

  .amplify-ionic-grid-padding-left {
    padding-left: 0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .amplify-select-phone-country {
      margin-top: 0.1em;
      margin-right: 0em;
      height: 41px;
      width: auto;
    }

    /** Ionic styles for material design */
    .md .amplify-select-phone-country {
      margin-top: 0.3em;
    }
  }

  /***********************************
 ** Forms
 ***********************************/

  .amplify-form-container {
    height: 100%;
  }

  .amplify-form-header {
    color: var(--color-primary-accent);
    height: 28px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.49px;
    line-height: 16px;
    margin: 0.2em;
  }

  .amplify-form-text {
    width: 90%;
    color: var(--color-accent-brown);
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 20px;
  }

  .amplify-input-label {
    height: 18px;
    width: 367.92px;
    color: var(--color-accent);
    font-family: var(--font-family);
    font-size: 14px;
    margin: 0.5em;
    letter-spacing: 0.4px;
    line-height: 18px;
  }

  .amplify-form-input {
    height: 42px;
    width: 378px;
    font-size: 14px;
    letter-spacing: 0.4px;
    border: var(--input-border);
    border-radius: 3px;
    background-color: #ffffff;
    padding: var(--input-padding);
    margin: 0.5em;
  }

  /** Ionic styles for material design */
  .md .amplify-form-input {
    border: none;
    border-bottom: var(--input-border);
    border-radius: 0px;
  }

  .amplify-form-actions {
    margin-top: 1.8em;
    padding-bottom: 1.5em;
  }

  .amplify-signin-username {
    margin-top: 0.8em;
  }

  .amplify-form-row {
    margin-top: 1em;
  }

  .amplify-form-action {
    margin: 0.5em;
    font-size: 12px;
  }

  .amplify-form-signup {
    margin-top: 1em;
    color: var(--color-gray);
  }

  .amplify-form-body {
    padding: 1.5em;
  }

  .amplify-form-row {
    display: block;
  }

  .amplify-form-cell-left {
    float: left;
    margin: 8px 0 8px 8px;
  }

  .amplify-form-cell-right {
    float: right;
    margin: 8px 8px 8px 0;
  }

  .amplify-form-link {
    cursor: pointer;
    color: var(--color-primary);
  }

  .amplify-form-link:hover {
    color: var(--color-primary-highlight);
  }

  .amplify-form-button {
    margin: 0.4em 0 0 0.5em;
    padding: 0.5em;
    border: none;
    height: var(--button-height);
    width: 155px;
    color: white;
    text-transform: uppercase;
    background-color: var(--color-primary);
    cursor: pointer;
  }

  .amplify-form-button:hover {
    background-color: var(--color-primary-highlight);
  }

  .amplify-greeting {
    display: flex;
    padding: 1em;
    margin: 0 auto;
  }

  .amplify-greeting-flex-spacer {
    width: 30%;
  }

  .amplify-greeting-sign-out {
    flex: auto;
  }

  .amplify-footer {
    clear: both;
  }

  .amplify-input-invalid {
    border-color: red !important;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .amplify-form-input {
      width: 100%;
    }
  }

  /************************************
 ** Alert
 ************************************/

  .amplify-alert-close {
    float: right;
    cursor: pointer;
    color: var(--color-gray);
    font-size: 24px;
    line-height: 24px;
  }

  .amplify-alert-close:hover {
    color: var(--color-primary-accent);
  }

  .amplify-alert-icon {
    float: left;
    font-size: 24px;
    line-height: 24px;
    color: ${$danger};
  }

  .amplify-alert {
    /* position: absolute; */
    min-height: 52px;
    /* width: 460px; */
    margin-top: 1rem;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }

  .amplify-alert-body {
    padding: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .amplify-alert-message {
    max-width: 85%;
    display: inline-flex;
    color: var(--color-primary-accent);
    font-size: 15px;
    line-height: 24px;
    margin-left: 0.5em;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .amplify-alert {
      width: 92%;
      margin-top: 1em;
    }
  }

  /************************************
 ** Tooltip
 ************************************/

  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: var(--color-primary-accent);
    color: white;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    // position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltip-text {
    visibility: visible;
  }
`;
