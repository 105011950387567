import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { TabContainer, LinkContainer } from './styles';
import { $primary, $accent } from '../../util';

type Props = {
  routes: { path: string; label: string; query?: string }[];
};

export const GcvTabs = ({ routes }: Props) => {
  const CustomLink = ({ label, to, activeOnlyWhenExact }) => {
    const pathNoParams = to.pathname;

    let match = useRouteMatch({
      path: pathNoParams,
      exact: activeOnlyWhenExact,
    });

    return (
      <LinkContainer>
        <Link
          to={to}
          style={{
            textDecoration: 'none',
            color: match ? $primary : $accent,
            borderBottom: match ? `1.6px solid ${$primary}` : `0`,
            transition: 'color 0.5s ease',
            paddingBottom: '0.25rem',
          }}
          data-cy={`${label}-tab`}
        >
          {label}
        </Link>
      </LinkContainer>
    );
  };

  return (
    <>
      <nav style={{ zIndex: 1 }}>
        <TabContainer>
          {routes.map(route => {
            return (
              <CustomLink
                activeOnlyWhenExact={false}
                to={{ pathname: route.path, search: route.query }}
                label={route.label}
                key={route.path}
              ></CustomLink>
            );
          })}
        </TabContainer>
      </nav>
    </>
  );
};
