import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { GcvLoading, GcvZeroState, GcvUserInputContainer } from '../../../lib';
import { formatISOToDateAtTime } from '../../../util/';

import { useApi, getData } from '../../../hooks/';
import { Activity } from '@gcv/shared/dist/types/';

import styled from 'styled-components';

import { $blue, $primary, $primaryDark, $white } from '../../../util/styleVariables';

//#region styles

export const Header = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-left: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const SubHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0px;
  vertical-align: middle;
`;

export const Description = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 14px 0px 24px 0px;
`;

export const ContentContainerLeft = styled.div`
  width: 50%;
  padding-right: 40px;
`;

export const ContentContainerRight = styled.div`
  width: 50%;
  padding-left: 40px;
`;

export const TableContainer = styled.div`
  margin-right: 13px;
  width: 50%;
  box-sizing: border-box;
`;

export const ColumnContainer = styled.div`
  display: flex;
`;
export const ButtonContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: flex-end;
`;

export const ArchiveContainer = styled.div`
  margin: 24px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

export const Archive = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ArchiveText = styled.span`
  color: ${$blue};
  margin-left: 5px;
`;

export const ActivityContainer = styled.div`
  border-top: 1px solid #dbe1ed;
  padding: 15px 0px;
  margin: 5px;
  color: #33353c;
`;

export const ActivityFeedContainer = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e0e5f0;
  box-sizing: border-box;
  border-radius: 7px;
  margin-left: 13px;
`;

export const Activities = styled.div`
  overflow-y: auto;
  padding: 0px 20px 20px 20px;
  max-height: 442px;
  box-sizing: border-box;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
`;

export const Title = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  padding: 20px 20px 0px 20px;
  height: 39px;
  box-sizing: border-box;
`;

export const Date = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #828599;
`;

export const Comment = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`;

export const Document = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #6896fb;
  cursor: pointer;
`;

export const ContentContainerBottom = styled.div`
  margin-top: 25px;
  display: flex;
`;

export const UpdateButtonContainer = styled.div`
  width: 12rem;
  margin: 0 1rem;
  box-sizing: border-box;
`;

export const UpdateButton = styled.div`
  padding: 0 1rem;
  position: absolute;
  max-width: 18rem;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  min-width: 9rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1.5px solid ${$primary};
  background-color: ${$white};
  color: ${$primary};
  transition: translate 0.2s ease, transform 0.2s ease, background-color 0.4s ease, border 0.3s ease, position 0.2s ease;
  -webkit-transition: translate 0.2s ease, transform 0.2s ease, background-color 0.4s ease, border 0.3s ease,
    position 0.2s ease;

  &:hover {
    border-color: ${$primaryDark};
  }

  &:focus {
    outline: 0;
    border-color: ${$primaryDark};
  }
`;

export const ButtonInner = styled.div`
  height: 2.25rem;
  text-rendering: geometricPrecision;
  justify-content: center;
  display: flex;
  align-items: center;
  &:hover {
    color: ${$primaryDark};
  }
`;

//#endregion

const eventTypes = {
  BankUserUploadedFile: 'uploaded a file',
  BankReviewedFile: 'reviewed a file',
  BankArchivedFile: 'archived a file',
  BankRestoredFile: 'restored a file',
  BankUserAddedCommentOnRequirement: 'added a comment on the requirement',
  BankUserAddedInternalCommentOnRequirement: 'added an internal comment on the requirement',
  BankUserRequestedFile: 'requested an upload',
  BankUserApprovedRequirement: 'approved the requirement',
  CrbUserUploadedFile: 'uploaded a file',
  CrbUserAddedCommentOnRequirement: 'added a comment on the requirement',
  CrbUserSetRequirementDoNotHave: 'added a comment on the requirement',
  BankUserUndoApproveRequirement: 'unapproved the requirement',
};

interface ActivityFeedProps {
  openFile: (type) => void;
  dispensaryId: string;
  reqId: string;
  reloadData: boolean;
}

export const ActivityFeed = ({ openFile, dispensaryId, reqId, reloadData }: ActivityFeedProps) => {
  const { API } = useApi();
  const [activities, setActivities] = useState<Activity[]>(null);

  const headerText = 'No Activites Recorded';

  const formatActivities = (activities: Activity[]) => {
    const sortedActivities = _.orderBy(activities, ({ created_date }) => created_date, 'desc');
    setActivities(sortedActivities);
  };

  useEffect(() => {
    getData.getActivities(API, formatActivities, dispensaryId, reqId);
  }, [dispensaryId, reqId, reloadData]);

  if (!activities) {
    return <GcvLoading />;
  }

  const renderActivityData = (activity: Activity) => {
    if (activity.document_id) {
      const handleOpenFile = () => openFile(activity.document_id);
      return (
        <Document onClick={handleOpenFile} data-cy="activity-feed-document">
          <GcvUserInputContainer string={activity.document_name} length={50} type="document" />
        </Document>
      );
    } else if (activity.comment) {
      return <Comment data-cy="activity-feed-comment">"{activity.comment}"</Comment>;
    } else {
      return null;
    }
  };

  const areActivitiesEmpty = activities.length < 1;

  return (
    <ActivityFeedContainer style={areActivitiesEmpty ? { overflowY: 'hidden' } : {}} data-cy="activity-feed-table">
      <Title>Activity</Title>

      <Activities>
        {areActivitiesEmpty ? (
          <GcvZeroState type="scroll" headerText={headerText} />
        ) : (
          activities.map((activity: Activity) => {
            return (
              <ActivityContainer key={activity.created_date} data-cy="activity-feed-entry">
                <TitleContainer data-cy="activity-feed-title">
                  <div>
                    <b>{activity.uploader_name}</b>
                    {` ${eventTypes[activity.type]}`}
                  </div>
                  <Date>{formatISOToDateAtTime(activity.created_date)}</Date>
                </TitleContainer>
                {renderActivityData(activity)}
              </ActivityContainer>
            );
          })
        )}
      </Activities>
    </ActivityFeedContainer>
  );
};
