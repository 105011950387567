import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';

import { DrawerText, DrawerImage } from '../styles';

interface Props {
  form: any;
}

export const MjFreewayForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To configure this integration, MJ Freeway will need to add your API key to Green Check's MJ Freeway Partners
        account.
      </DrawerText>
      <DrawerText>
        Send the following email to{' '}
        <a rel="noopener noreferrer" target="_blank" href="mailto:partners@mjfreeway.com">
          partners@mjfreeway.com
        </a>
        :
      </DrawerText>
      <DrawerText>
        <b>
          Hello, <br />
          <br />I need some assistance. I would like to integrate our system with the Green Check system. Please help me
          by providing access to Green Check's MJ Freeway Partners account. This account is set up under
          engineering@greencheckverified.com.
        </b>
      </DrawerText>
      <DrawerText>
        After you send the email, enable and connect the MJ Freeway integration below. Once MJ Freeway provides us with
        your API key, we will complete the connection. The integration may take 2-3 days.
      </DrawerText>
    </>
  );
};
