import { ReactWrapperComponent, WrapperProps, DashboardRouterFI } from '@user-interface/react-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DispensaryFacade, BankFacade, UserFacade, StaffFacade } from '@user-interface/gcv-state';
import { Bank, Dispensary, User, Deposit } from '@gcv/shared';
import { BankAppFamilyService } from '../../services/bank.service';
import { determineStatus, ViewStates } from '../../app/bank/bank.util';

@Component({
  selector: 'fi-dashboard',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleEmitData"></ app-react-wrapper>',
})
export class DashboardComponent extends ReactWrapperComponent {
  public dispensaries: { [id: string]: Dispensary };
  public bank: Bank;
  public user: User;
  public staff: { [id: string]: User };
  @Input() props: WrapperProps;
  @Input() reports: any;
  @Output() clickEmitter = new EventEmitter<any>();
  viewStatus: string;

  component = DashboardRouterFI;

  constructor(
    private bankAppFamilyService: BankAppFamilyService,
    private dispFacade: DispensaryFacade,
    private bankFacade: BankFacade,
    private userFacade: UserFacade,
    private staffFacade: StaffFacade
  ) {
    super();
  }

  ngOnInit() {
    this.setUpStreams();
    this.updateProps();
  }

  setUpStreams() {
    this.staffFacade.selectStaffDictionary().subscribe(staffMap => {
      this.staff = staffMap;
      this.updateProps();
    });

    this.bankFacade.selectCurrentBank().subscribe(bank => {
      this.staffFacade.getOrganizationStaffByOrgId(bank.id);
      this.bank = bank;
      this.viewStatus = determineStatus(bank.due_diligence.due_diligence_status);
      this.updateProps();
    });

    this.dispFacade.selectDispensaryDictionary().subscribe(disps => {
      this.dispensaries = disps;
      this.updateProps();
    });

    this.userFacade.selectCurrentUser().subscribe(user => {
      this.user = user;
      this.updateProps();
    });
  }

  updateProps() {
    this.props = {
      ...this.props,
      bank: this.bank,
      staff: this.staff,
      dispensaries: this.dispensaries,
      user: this.user,
      viewStatus: this.viewStatus,
    };
  }

  ngOnChanges() {
    this.updateProps();
  }

  handleEmitData = (type, data) => {
    if (type === 'updateDispensary') {
      this.dispFacade.updateDispensary(data);
    }
    if (type === 'updateBank') {
      this.bankFacade.updateBank(data);
    }
    if (type === 'goToOnboarding') {
      if (this.viewStatus === ViewStates.DueDiligenceZeroState) {
        this.bankFacade.putUpdateBankGCVDueDiligenceStatus(this.bank.id);
      }
      this.bankAppFamilyService.view.next({ view: '/secure/bank/onboarding' });
    }
  };
}
