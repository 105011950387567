import React from 'react';

export const GreyQuestionIcon = ({ height = 26, width = 26 }) => (
  <div style={{ height, width, position: 'relative' }}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-cy="yellow-warning-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
        fill="#5A5E6B"
      />
    </svg>
    <img
      src={`../assets/WhiteQuestionMark.png`}
      style={{ width: width / 2, height: height / 2, position: 'absolute', top: '25%', left: '25%', zIndex: 5 }}
    ></img>
  </div>
);
