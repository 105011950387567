// TODO(pc): 2020.05.20 - pull this file out of react-ui to avoid circular dep on apps/user-interface
import { useEffect, useState } from 'react';
import { environment } from '../../../../apps/user-interface/src/environments/environment';
import { versions } from '../../../../apps/user-interface/src/environments/versions';
import { environment as integrationEnvironment } from '../../../../apps/user-interface/src/environments/environment.integration';
import Amplify, { API, Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '../../../common-ui/src';

const {
  STORYBOOK_IS_STORYBOOK: IS_STORYBOOK,
  CYPRESS_GREEN_CHECK_ADMIN_USERNAME,
  CYPRESS_GREEN_CHECK_ADMIN_PASSWORD,
} = process.env;

const environmentToUse = IS_STORYBOOK ? integrationEnvironment : environment;
const DEFAULT_REGION_NAME = 'us-east-1';
const greenCheckEnvironmentName = environmentToUse.env;

const endpoints = [
  {
    name: GREEN_CHECK_SERVICES_AMPLIFY_NAME,
    endpoint: `https://${greenCheckEnvironmentName}api.greencheckverified.com/`,
    region: DEFAULT_REGION_NAME,
    custom_header: async () => {
      return { 'X-Green-Check-Client-Hash': versions.revision };
    },
  },
];

export const useApi = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    configure();
  }, [isAuthenticated]);

  const checkAuthentication = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setIsAuthenticated(true);
      }
    } catch (e) {
      if (IS_STORYBOOK) {
        login();
      } else {
        setError(e);
      }
    }
  };

  const configure = () => {
    if (IS_STORYBOOK) {
      Amplify.configure(environmentToUse.amplify);
      AWS.config.region = environmentToUse.amplify.aws_cognito_region;

      API.configure({ endpoints });
    }

    setLoading(false);
  };

  const login = async () => {
    try {
      await Auth.signIn(CYPRESS_GREEN_CHECK_ADMIN_USERNAME, CYPRESS_GREEN_CHECK_ADMIN_PASSWORD, {});
      setIsAuthenticated(true);
    } catch (e) {
      setError(e);
    }
  };

  return { API, initializingApi: loading, apiError: error };
};
