import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { getDefaultCompany } from '../../utils';
import { CsvExportService } from '../../services/csv-export/csv-export.service';
import { LicenseManager } from '@gcv/shared';

@Component({
  selector: 'gcv-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Output()
  rowClicked = new EventEmitter();
  @Output()
  itemSelected = new EventEmitter();
  @Output()
  paginatedChanged = new EventEmitter();
  @Output()
  deleteClicked = new EventEmitter();
  @Input()
  data: any[];
  @Input()
  columnNames: any[];
  @Input()
  dataMap: any;
  @Input()
  selectedItems: any;
  @Input()
  clickable = false;
  @Input()
  exportable = false;
  @Input()
  deletable = false;
  @Input()
  deleteText = 'Delete Selected';
  @Input()
  altMap;
  @Input()
  type;
  @Input()
  dispensaryName = '';
  @Input()
  dispensaryState = '';
  @Input()
  dispensaryNameMap = {};
  @Input()
  userMap = {};
  @Input()
  organizationMap = {};
  @Input() selectionKey = 'upload_date';
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild('paginator', { static: true })
  paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  dataSource: any;
  displayedColumns: string[] = [];

  constructor(private cdr: ChangeDetectorRef, private exportAsCsv: CsvExportService) {}

  setDataSourceAndDisplayColumns() {
    this.displayedColumns = this.columnNames;
    this.dataSource = new MatTableDataSource(this.data);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'lastLogin':
          const loginDate = Date.parse(item.lastLogin);
          return isNaN(loginDate) ? Date.parse('01/01/0001, 00:00') : loginDate;
        default:
          return item[property];
      }
    };
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.setDataSourceAndDisplayColumns();
  }

  ngAfterViewInit(): void {
    this.setDataSourceAttributes();
  }

  ngOnChanges() {
    this.setDataSourceAndDisplayColumns();
    this.setDataSourceAttributes();

    if (this.selectedItems && this.selectedItems.length === 0) {
      this.selection.clear();
      this.cdr.markForCheck();
    }
  }

  cleanLicenseName(initialValue: string): string {
    const licenses = LicenseManager.getLicenseData(this.dispensaryState);
    licenses.business_license_types.map(licenseType => {
      if (initialValue === licenseType.value) {
        initialValue = licenseType.viewValue;
      }
    });
    return initialValue;
  }

  editProfile(user) {
    this.rowClicked.emit(user);
  }

  handleClick(data, i) {
    if (this.type) {
      data.index = i;
    }
    this.rowClicked.emit(data);
  }

  subscribeToPage($event) {
    this.paginatedChanged.emit($event);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => this.selection.select(row));
    }
    this.itemSelected.emit(this.selection.selected);
  }

  handleChange(e, data) {
    if (e) {
      this.selection.toggle(data);
      this.itemSelected.emit(this.selection.selected);
    }
  }

  export() {
    this.exportAsCsv.exportFileAsCsv(
      _.sortBy(this.selection.selected, 'dispensary_id'),
      'sales',
      this.dispensaryName,
      this.dispensaryNameMap
    );
    this.selection.clear();
  }

  deleteSelection(event) {
    event.stopPropagation();
    const map = {};
    this.selection.selected.forEach(selection => (map[selection[this.selectionKey]] = selection));
    this.deleteClicked.emit(map);
    this.selection.clear();
  }

  getCellContents() {
    const companies = this.data['companies'];
    return companies && getDefaultCompany(companies) && getDefaultCompany(companies).companyType
      ? this.organizationMap[getDefaultCompany(companies).companyType][getDefaultCompany(companies).id]
      : '';
  }
}
