// fx
import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardActions, Button, TextField, Grid, makeStyles } from '@material-ui/core';

// app
import { useApi } from 'libs/react-ui/src/hooks/useApi';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { GcvButton, GcvModal } from '../../../lib';
import { api } from 'libs/react-ui/src/api';

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'flex-end',
  },
  alertBox: {
    border: '1px solid #3f51b5',
    borderRadius: '4px',
    backgroundColor: 'rgba(105, 150, 250, 0.1)',
    minHeight: '25px',
    textAlign: 'center',
    marginBottom: '1rem',
  },
}));

interface Props {
  selectSale: any;
}

export const SearchSalesCard: React.FC<Props> = props => {
  const classes = useStyles(props);

  const apiClient = api();
  const [dispensaryId, setDispensaryId] = useState('');
  const [saleId, setSaleId] = useState('');
  const [msg, setMsg] = useState(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const buttonsDisabled = () => {
    return dispensaryId === '' || saleId === '';
  };
  const searchSales = async () => {
    setMsg(null);

    await apiClient.dispensaries
      .getDispensarySaleById(dispensaryId, saleId, () => {})
      .then(sale => {
        props.selectSale(sale);
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 404') {
          setMsg('Sale not found');
        }
      });
  };

  const deleteSale = async () => {
    setMsg(null);

    await apiClient.dispensaries
      .deleteDispensarySale(dispensaryId, saleId, 'e7a5fe7f-cd75-47ca-bc57-20379efabbc3', () => {})
      .then(() => {
        setMsg('Successfully deleted sale');
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 404') {
          setMsg('Sale not found');
        } else {
          setMsg(
            'Something went wrong in the delete sale call. Either the sale was not deleted, or we failed to update aggregates after deleting it.'
          );
        }
      });
    toggleModal();
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <GcvModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        backButton={
          <GcvButton onClick={toggleModal} secondary={true}>
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={deleteSale} danger={true}>
            Delete
          </GcvButton>
        }
        children={
          <>
            <p>Dispensary ID: {dispensaryId}</p>
            <p> Sale ID: {saleId}</p>
            <p>This action is final. There is no going back.</p>
            <p>Aggregates will be automatically updated after deleting the sale.</p>
          </>
        }
        title="Are you sure you want to delete this sale?"
      ></GcvModal>
      {msg && <div className={classes.alertBox}>{msg}</div>}

      <Card>
        <CardHeader title="Search Sales" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="dispensaryId"
                label="Dispensary ID"
                defaultValue={dispensaryId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDispensaryId(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                id="saleId"
                label="Sale ID"
                defaultValue={saleId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSaleId(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <GcvButton disabled={buttonsDisabled()} primary={true} onClick={searchSales}>
            Search
          </GcvButton>
          <GcvButton disabled={buttonsDisabled()} danger={true} onClick={toggleModal}>
            Delete
          </GcvButton>
        </CardActions>
      </Card>
    </>
  );
};
