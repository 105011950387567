import React from 'react';

import { GcvChart } from './GcvChart';
import { PieConfig } from './chart-options';
import { tooltip } from './Tooltip';
import { formatMoney, formatPercentage } from 'libs/react-ui/src/util';

interface Props {
  data: {
    [key in 'flower' | 'edible' | 'concentrate' | 'other']: {
      totalAmountCurrPeriod: number;
      totalAmountPrevPeriod: number;
      currentPeriod: number;
      previousPeriod: number;
      previousYear: number;
      peerGroups: number;
    };
  };
  formatDollars: boolean;
}

export const ProductsSoldPieChart: React.FC<Props> = props => {
  return <GcvChart type="pie" config={dataAdapter(props.data, props.formatDollars)} />;
};

export const dataAdapter = (
  data: {
    [key in 'flower' | 'edible' | 'concentrate' | 'other']: {
      totalAmountCurrPeriod: number;
      totalAmountPrevPeriod: number;
      currentPeriod: number;
      previousPeriod: number;
      previousYear: number;
      peerGroups: number;
    };
  },
  formatDollars: boolean
): PieConfig => {
  const formatDollarSign = (amount: number, isFormatted: boolean) => {
    return isFormatted ? formatMoney(amount) : amount;
  };

  return {
    data: [
      {
        displayValue: `Flower (${formatPercentage(data.flower.currentPeriod, data.flower.totalAmountCurrPeriod)})`,
        value: data.flower.currentPeriod,
        label: tooltip('Flower', [
          {
            label: 'This Period',
            value: `${formatDollarSign(data.flower.currentPeriod, formatDollars)} (${formatPercentage(
              data.flower.currentPeriod,
              data.flower.totalAmountCurrPeriod
            )})`,
          },
          {
            label: 'Last Period',
            value: `${formatDollarSign(data.flower.previousPeriod, formatDollars)} (${formatPercentage(
              data.flower.previousPeriod,
              data.flower.totalAmountPrevPeriod
            )})`,
          },
          {
            label: 'Change Over Last Period',
            value: `${formatPercentage(
              data.flower.currentPeriod - data.flower.previousPeriod,
              data.flower.previousPeriod
            )}`,
          },
          {
            label: 'Peer Group Avg',
            value: `${formatDollarSign(data.flower.peerGroups, formatDollars)}`,
          },
        ]),
        color: '#00A77E',
      },
      {
        displayValue: `Edible (${formatPercentage(data.edible.currentPeriod, data.edible.totalAmountCurrPeriod)})`,
        value: data.edible.currentPeriod,
        label: tooltip('Edible', [
          {
            label: 'This Period',
            value: `${formatDollarSign(data.edible.currentPeriod, formatDollars)} (${formatPercentage(
              data.edible.currentPeriod,
              data.edible.totalAmountCurrPeriod
            )})`,
          },
          {
            label: 'Last Period',
            value: `${formatDollarSign(data.edible.previousPeriod, formatDollars)} (${formatPercentage(
              data.edible.previousPeriod,
              data.edible.totalAmountPrevPeriod
            )})`,
          },
          {
            label: 'Change Over Last Period',
            value: `${formatPercentage(
              data.edible.currentPeriod - data.edible.previousPeriod,
              data.edible.previousPeriod
            )}`,
          },
          {
            label: 'Peer Group Avg',
            value: `${formatDollarSign(data.edible.peerGroups, formatDollars)}`,
          },
        ]),
        color: '#44D359',
      },
      {
        displayValue: `Other (${formatPercentage(data.other.currentPeriod, data.other.totalAmountCurrPeriod)})`,
        value: data.other.currentPeriod,
        label: tooltip('Other', [
          {
            label: 'This Period',
            value: `${formatDollarSign(data.other.currentPeriod, formatDollars)} (${formatPercentage(
              data.other.currentPeriod,
              data.other.totalAmountCurrPeriod
            )})`,
          },
          {
            label: 'Last Period',
            value: `${formatDollarSign(data.other.previousPeriod, formatDollars)} (${formatPercentage(
              data.other.previousPeriod,
              data.other.totalAmountPrevPeriod
            )})`,
          },
          {
            label: 'Change Over Last Period',
            value: `${formatPercentage(
              data.other.currentPeriod - data.other.previousPeriod,
              data.other.previousPeriod
            )}`,
          },
          {
            label: 'Peer Group Avg',
            value: `${formatDollarSign(data.other.peerGroups, formatDollars)}`,
          },
        ]),
        color: '#96E688',
      },
      {
        displayValue: `Concentrate (${formatPercentage(
          data.concentrate.currentPeriod,
          data.concentrate.totalAmountCurrPeriod
        )})`,
        value: data.concentrate.currentPeriod,
        label: tooltip('Concentrate', [
          {
            label: 'This Period',
            value: `${formatDollarSign(data.concentrate.currentPeriod, formatDollars)} (${formatPercentage(
              data.concentrate.currentPeriod,
              data.concentrate.totalAmountCurrPeriod
            )})`,
          },
          {
            label: 'Last Period',
            value: `${formatDollarSign(data.concentrate.previousPeriod, formatDollars)} (${formatPercentage(
              data.concentrate.previousPeriod,
              data.concentrate.totalAmountPrevPeriod
            )})`,
          },
          {
            label: 'Change Over Last Period',
            value: `${formatPercentage(
              data.concentrate.currentPeriod - data.concentrate.previousPeriod,
              data.concentrate.previousPeriod
            )}`,
          },
          {
            label: 'Peer Group Avg',
            value: `${formatDollarSign(data.concentrate.peerGroups, formatDollars)}`,
          },
        ]),
        color: '#00BC66',
      },
    ],
  };
};
