import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const usersClient = (API: APIClass) => {
  return {
    getAllUsers: async (setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/users`, setIsLoading);
    },

    getUserById: async (userId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/users/${userId}`, setIsLoading);
    },

    getUserKba: async (userId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/users/${userId}/kba`, setIsLoading);
    },

    updateUser: async (userId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).put(`/users/${userId}`, body, setIsLoading);
    },

    createUser: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post('/users', body, setIsLoading);
    },

    verifyUser: async (userId: string, body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/users/${userId}/verify`, body, setIsLoading);
    },

    deleteUser: async (userId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/users/${userId}`, setIsLoading);
    },

    addUserToOrg: async (
      userId: string,
      orgId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/users/${userId}/companies/${orgId}`, body, setIsLoading);
    },

    removeUserFromOrg: async (userId: string, orgId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/users/${userId}/companies/${orgId}`, setIsLoading);
    },

    updateUserIdentification: async (
      userId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/users/${userId}/identification`, body, setIsLoading);
    },
  };
};
