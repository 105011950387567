import { EventTypes } from '@gcv/shared';

export const getEndpoint = {
  permissionsS3Post: () => `/permissions/s3`,
  usersGet: orgId => `/organizations/${orgId}/users`,
  documentsGet: orgId => `/documents/${orgId}`,
  activitiesGet: (org_id, req_id) => `/document-management/dispensaries/${org_id}/requirements/${req_id}/activities`,
  requirementPost: (orgId, reqId) => `/document-management/dispensaries/${orgId}/requirements/${reqId}`,
  requirementsPatch: (orgId, reqId) => `/document-management/dispensaries/${orgId}/requirements/${reqId}/status`,
  requirementsGet: orgId => `/document-management/dispensaries/${orgId}/requirements`,
  getDispensaryByBankId: bankId => `/banks/${bankId}/dispensaries`,
  postFincenReportsXml: () => `/fincen-reports/xml`,
  getFincenExports: bankId => `/fincen-xml-exports/${bankId}`,
  updateFincenExport: bankId => `/fincen-xml-exports/${bankId}/bulk-update`,
  updateFincenExportTracking: bankId => `/fincen-xml-exports/${bankId}/tracking`,
  dispensaryGet: dispensaryId => `/dispensaries/${dispensaryId}`,
  documentsPatch: (orgId, reqId, docId) =>
    `/document-management/dispensaries/${orgId}/requirements/${reqId}/documents/${docId}/status`,
  dueDiligenceTemplateGet: orgId => `/dispensaries/${orgId}/diligence/template`,
  documentManagementRequestUploadPatch: (orgId, reqId) =>
    `/document-management/dispensaries/${orgId}/requirements/${reqId}/request-upload`,
  documentManagementAddCommentPatch: (orgId, reqId) =>
    `/document-management/dispensaries/${orgId}/requirements/${reqId}/add-comment`,
  deleteFincenReportById: reportId => `/fincen-reports/${reportId}`,
  putFincenReportById: reportId => `/fincen-reports/${reportId}`,
  putPosConfigById: dispId => `/dispensaries/${dispId}/posConfig`,
  getEventByOrgIdAndType: (orgId, eventType: EventTypes) => `/dispensaries/${orgId}/event/${eventType}`,
  upsertDiligenceRequirementTemplate: (bankId: string) => `/banks/${bankId}/diligence/requirement`,
  updateRequirementTemplateStatus: (bankId: string) => `/banks/${bankId}/diligence/requirement/status`,
  getQuickbooksAuth: () => `/quickbooks/authUri`,
  postQuickbooksAccess: (dispId: string) => `/quickbooks/${dispId}/accessToken`,
  getUserById: id => `/users/${id}`,
  getDocumentByOrgIdAndId: (orgId, docId) => `/documents/${orgId}/${docId}`,
  getVendorById: id => `/transportVendor/${id}`,
  putReviewSectionByDispensaryId: dispId => `/dispensaries/${dispId}/review-sections`,
  putBankDueDiligenceStatusByDispensaryId: dispId => `/dispensaries/${dispId}/bank-due-diligence-status`,
  postdueDiligenceBankReviewedOnByDispensaryIdandBankId: (bankId, dispId) =>
    `/diligence/bank/${bankId}/dispensary/${dispId}/reviewed_on`,
  putUserIdenfitication: userId => `/users/${userId}/identification`,
  putVendorByBankIdandVendorIdDispensaries: (bankId, vendorId) =>
    `/banks/${bankId}/transportVendor/${vendorId}/dispensaries`,
  putVendorByBankIdandVendorId: (bankId, vendorId) => `/banks/${bankId}/transportVendor/${vendorId}`,
  postVendorByBankId: bankId => `/banks/${bankId}/transportVendor`,
  posConfigGet: dispensaryId => `/dispensaries/${dispensaryId}/posConfig`,
};

export const getV2Endpoint = {
  activitiesGet: (org_id, req_id) => `/v2/document-management/dispensaries/${org_id}/requirements/${req_id}/activities`,
  requirementPost: (orgId, reqId) => `/v2/document-management/dispensaries/${orgId}/requirements/${reqId}`,
  requirementsPatch: (orgId, reqId) => `/v2/document-management/dispensaries/${orgId}/requirements/${reqId}/status`,
  documentsPatch: (orgId, reqId, docId) =>
    `/v2/document-management/dispensaries/${orgId}/requirements/${reqId}/documents/${docId}/status`,
  documentManagementRequestUploadPatch: (orgId, reqId) =>
    `/v2/document-management/dispensaries/${orgId}/requirements/${reqId}/request-upload`,
  documentManagementAddCommentPatch: (orgId, reqId) =>
    `/v2/document-management/dispensaries/${orgId}/requirements/${reqId}/add-comment`,
};
