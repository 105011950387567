<div class="wrapper" style="max-width: 500px">
  <div class="header">
    <h4>Are you sure???</h4>
  </div>
  <div class="subtitle">
    <p>
      You are going to DELETE this user. Are you really sure you want to do that? This is permanent. There's no going
      back.
    </p>
  </div>
  <div class="buttonWrapper">
    <gcv-button (clickEvent)="submit(false)" buttonText="Cancel" type="tertiary"></gcv-button>
    <gcv-button (clickEvent)="submit(true)" buttonText="Delete" type="danger"></gcv-button>
  </div>
</div>
