import { Routes } from '@angular/router';
import { CognitoComponent } from './auth/cognito/cognito.component';
import { NewPasswordComponent } from './auth/cognito/new-password/new.password.component';
import { AuthGuard } from './auth/route-guards/auth.guard';
import { appRoutesNames } from './app.routes.names';
import { termsOfServiceRoutesNames } from './terms-of-service/terms-of-service.routes.names';
import { supportUserLoginRoutesNames } from './support-user-login/support-user-login.routes.names';
import { ResendUserInviteComponent } from '@user-interface/react-ui';

export const APP_ROUTES: Routes = [
  { path: appRoutesNames.RESEND_INVITATION_EMAIL, component: ResendUserInviteComponent },
  { path: appRoutesNames.LOGIN, component: CognitoComponent },
  { path: appRoutesNames.COGNITO, component: CognitoComponent },
  {
    path: `${termsOfServiceRoutesNames.TERMS_OF_SERVICE}`,
    loadChildren: () => import('./terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule),
  },
  {
    path: `${supportUserLoginRoutesNames.SUPPORT_USER_LOGIN}`,
    loadChildren: () => import('./support-user-login/support-user-login.module').then(m => m.SupportUserLoginModule),
  },
  {
    path: appRoutesNames.USER,
    children: [
      {
        path: appRoutesNames.REGISTRATION,
        component: NewPasswordComponent,
      },
    ],
  },
  {
    path: appRoutesNames.SECURE,
    loadChildren: () => import('./secure.module').then(m => m.SecureModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: `/${appRoutesNames.LOGIN}`, pathMatch: 'full' },
  { path: '**', redirectTo: `/${appRoutesNames.LOGIN}`, pathMatch: 'full' },
];
