import { Component, Inject } from '@angular/core';
import { Deposit } from '@gcv/shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-deposit-history',
  templateUrl: './view-deposit-history.component.html',
  styleUrls: ['./view-deposit-history.component.scss'],
})
export class ViewDepositHistoryComponent {
  deposit: Deposit;
  created_by: string;
  accepted_by: string;
  dispensary_name: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<ViewDepositHistoryComponent>
  ) {
    this.deposit = data.deposit;
    this.created_by = data.created_by;
    this.accepted_by = data.accepted_by;
    this.dispensary_name = data.dispensary_name;
  }

  close() {
    this.matDialogRef.close();
  }
}
