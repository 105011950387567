import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultService } from '@gcv/generated-services';
import { AmplifyService } from 'aws-amplify-angular';
import { BankFacade, DispensaryFacade, UserFacade } from 'libs/gcv-state/src/lib';
import { interval } from 'rxjs';
import { last, take, throttle } from 'rxjs/operators';
import { appRoutesNames } from '../app.routes.names';
import { AuthService } from '../auth/route-guards/auth.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './support-user-login.component.html',
  styleUrls: ['./support-user-login.component.scss'],
})
export class SupportUserLoginComponent implements OnInit {
  public selectedDispensaries = [];
  public selectedBanks = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private amplifyService: AmplifyService,
    private crbFacade: DispensaryFacade,
    private fiFacade: BankFacade,
    private userFacade: UserFacade,
    private sdk: DefaultService
  ) {}

  authState = { state: 'signIn', user: null };
  user: any;
  loading = false;
  dispensaries: any[];
  banks: any[];
  orgId: string;
  orgType: string;

  ngOnInit() {
    this.crbFacade.getAllDispensaries();
    this.crbFacade.selectAllDispensaries().subscribe(crbs => {
      this.dispensaries = crbs.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
    });

    this.fiFacade.getAllBanks();
    this.fiFacade.selectAllBanks().subscribe(fis => {
      this.banks = fis.sort((a, b) => (a.orgName < b.orgName ? -1 : a.orgName > b.orgName ? 1 : 0));
    });
  }

  public onSelectedDispensariesChange(event) {
    this.selectedDispensaries = event;
  }

  public onSelectedBanksChange(event) {
    this.selectedBanks = event;
  }

  selectOrg(orgId: string, orgType: string) {
    this.orgId = orgId;
    this.orgType = orgType;
  }

  continueToDashboard() {
    this.userFacade
      .selectCurrentUser()
      .pipe(take(1))
      .subscribe(user => {
        if (user.role === 'gcv_customer_support') {
          this.sdk
            .usersIdPut(user.id, {
              ...user,
              initial_user: false,
              companies: [
                {
                  companyType: this.orgType as 'bank' | 'dispensary',
                  id: this.orgId,
                },
              ],
            })
            .subscribe(() => {
              this.completeSignIn();
            });
        } else {
          this.completeSignIn();
        }
      });
  }

  completeSignIn() {
    this.amplifyService.authStateChange$.pipe(throttle(() => interval(500))).subscribe(authState => {
      this.authState = authState;
      if (authState.state === 'signedIn' && !this.authService.loggin) {
        this.user = authState.user;
        this.authService.loggin = true;
        this.loading = true;
        this.authService.initLogin(authState.user, false).pipe(last());
      }
    });

    this.router.navigate(['/login']).then(() => window.location.reload());
  }
}
