import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { User } from '@gcv/shared';
import { map, switchMap } from 'rxjs/operators';
import { API } from 'aws-amplify';

import {
  DeleteUser,
  LoadUser,
  LoadUserKba,
  UpdateUser,
  UpdateUserDocuments,
  UpdateUserIdentification,
  UserActionTypes,
  UserDeleted,
  UserKbaAnswersVerified,
  UserKbaLoaded,
  UserLoaded,
  UserUpdated,
  VerifyUserKbaAnswers,
} from './user.actions';
import { DataPersistence } from '../../utils/data.persistance';
import { UserState } from './user.reducer';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';
import { from } from 'rxjs';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { OrganizationStaffUpdated } from '../staff/staff.actions';

@Injectable({
  providedIn: 'root',
})
export class UserEffect {
  @Effect() loadUser$ = this.dataPersistance.fetch(UserActionTypes.LoadUser, {
    run: (action: LoadUser, state: UserState) => {
      return from(
        API.get(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/users/${action.payload.userId}`, {}).catch(error => {
          console.log(error.response);
          throw error;
        })
      ).pipe(map((res: User) => new UserLoaded(res)));
    },
    onError: (action, err) => {
      if (action.payload.initialLogin) {
        return new AddError({
          action: UserActionTypes.LoginUser,
          message: { error: err, response: err.response, authUser: action.payload.authUser },
        });
      } else {
        return new AddError({ action: UserActionTypes.LoadUser, message: err });
      }
    },
  });

  @Effect() updateUser$ = this.dataPersistance.pessimisticUpdate(UserActionTypes.UpdateUser, {
    run: (action: UpdateUser, state: UserState) => {
      return this.sdk.usersIdPut(action.payload.id, action.payload).pipe(map((res: User) => new UserUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.UpdateUser, message: err });
    },
  });

  @Effect() verifyUserByKba$ = this.dataPersistance.fetch(UserActionTypes.LoadUserKba, {
    run: (action: LoadUserKba, state: UserState) => {
      return this.sdk.usersIdKbaGet(action.payload.userId).pipe(map((res: any) => new UserKbaLoaded(res)));
    },
    onError: (action, error) => {
      const payload = { status: error.response.status, message: error.toJSON().message };
      return new AddError({ action: UserActionTypes.LoadUserKba, message: payload });
    },
  });

  @Effect() verifyUserKbaAnswers = this.dataPersistance.fetch(UserActionTypes.VerifyUserKbaAnswers, {
    run: (action: VerifyUserKbaAnswers, state: UserState) => {
      return this.sdk
        .usersIdVerifyPost(action.payload.userId)
        .pipe(map((res: User) => new UserKbaAnswersVerified(res)));
    },
    onError: (action, response) => {
      return new AddError({ action: UserActionTypes.VerifyUserKbaAnswers, message: response });
    },
  });

  @Effect({ dispatch: false }) updateUserDocuments = this.dataPersistance.fetch(UserActionTypes.UpdateUserDocuments, {
    run: (action: UpdateUserDocuments, state: UserState) => {
      return this.sdk.usersIdDocumentsPut(action.payload.userId);
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.UpdateUserDocuments, message: err });
    },
  });

  @Effect() deleteUser$ = this.dataPersistance.fetch(UserActionTypes.DeleteUser, {
    run: (action: DeleteUser, state: UserState) => {
      return this.sdk
        .usersIdDelete(action.payload.userId, '374732e2-181f-4951-9306-ee1ed30da47c')
        .pipe(map(res => new UserDeleted({ userId: action.payload.userId })));
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.DeleteUser, message: err });
    },
  });

  @Effect() updateUserIdentification$ = this.dataPersistance.fetch(UserActionTypes.UpdateUserIdentification, {
    run: (action: UpdateUserIdentification, state: UserState) => {
      return from(
        API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/users/${action.payload.userId}/identification`, {
          body: action.payload.identificationDetails,
        })
      ).pipe(
        switchMap((res: User) => {
          return [new UserUpdated(res), new OrganizationStaffUpdated(res)];
        })
      );
    },
    onError: err => {
      return new AddError({ action: UserActionTypes.UpdateUserIdentification, message: err });
    },
  });

  constructor(private dataPersistance: DataPersistence<UserState>, private sdk: DefaultService) {}
}
