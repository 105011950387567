import React from 'react';
import { RouteObject } from '../../util/types';
import { Settings } from './Settings.page';
import { PeerGroupDetails } from './Peer-Group-Details.page';

export const routes: RouteObject[] = [
  {
    path: '/secure/bank/settings/peer-groups/:group_id',
    render: props => <PeerGroupDetails {...props}></PeerGroupDetails>,
  },
  {
    path: '/secure/bank/settings/:tab',
    render: props => <Settings {...props}></Settings>,
  },
];
