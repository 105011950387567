import { IANATimezones } from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';
import React, { useEffect, useState } from 'react';
import { Report } from '../Components/Report';

interface Props {
  bankId: string;
  userId: string;
  accountList: {
    value: string;
    label: string;
    id: string;
  }[];
  timezone: IANATimezones;
  setExportActivitySuccess: (active: boolean) => void;
  setExportActivityFailure: (active: boolean) => void;
  setResponseModalActive: (active: boolean) => void;
}

export const AccountSummaryReport: React.FC<Props> = props => {
  const apiClient = api();

  const [selectedAccounts, setSelectedAccounts] = useState([]);

  useEffect(() => {
    setSelectedAccounts(props.accountList.map(a => a.value));
  }, [props.accountList]);

  const handleExportAccountSummary = async () => {
    await apiClient.banks
      .getFiAccountSummaryReport(
        props.bankId,
        {
          orgId: props.bankId,
          userId: props.userId,
          reportData: {
            dispensaryIds: selectedAccounts,
            bankId: props.bankId,
          },
        },
        () => {}
      )
      .then(() => {
        props.setExportActivitySuccess(true);
      })
      .catch(() => {
        props.setExportActivityFailure(true);
      })
      .finally(() => {
        props.setResponseModalActive(true);
      });
  };

  return (
    <Report>
      <Report.Title size={6} align="left">
        Filters
      </Report.Title>

      <Report.ExportButton size={6} align="right" export={handleExportAccountSummary} />

      <Report.SearchDropdown
        size={6}
        onSelect={setSelectedAccounts}
        selectOptions={props.accountList}
        searchText={'Search Accounts'}
        itemLabel={'Account(s)'}
      />
    </Report>
  );
};
