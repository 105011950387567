import styled from 'styled-components';

import { $blue, $black, $danger, $primary } from '../../../util/styleVariables';

export const ReportSelector = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  width: 240px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: center;
  margin: 0 0 2rem 0;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 1rem 0 0;
`;

export const SubOptions = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  margin: 0.5rem 0 0 0;
`;

export const Title = styled.div`
  color: ${$black};
  font-weight: 600;
  margin: 0 1rem 0 0;
`;
export const ModalTextSmall = styled.div`
  color: ${$black};
  text-align: center;
  padding: 20px;
`;
export const ModalTextLarge = styled.div`
  color: ${$black};
  font-weight: 600;
  padding: 20px;
  text-align: center;
`;
export const ModalTextError = styled.div`
  color: ${$danger};
  text-align: center;
  padding: 20px;
`;
export const ModalTextSuccess = styled.div`
  color: ${$primary};
  text-align: center;
  padding: 20px;
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CurrentReport = styled.div`
  color: ${$blue};
  margin-left: 7px;
  display: inline-block;
  transition: color 1s;
`;

export const EnterID = styled.button`
  border: none;
  padding: 10px 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background-color: white;
  cursor: pointer;
`;
