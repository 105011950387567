import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { User } from '@gcv/shared';
import { map, switchMap } from 'rxjs/operators';

import { DataPersistence } from '../../utils/data.persistance';
import {
  AllStaffLoaded,
  CreateOrganizationStaff,
  LoadAllStaff,
  LoadOrganizationStaff,
  ManuallyVerifyStaff,
  OrganizationStaffCreated,
  OrganizationStaffLoaded,
  OrganizationStaffUpdated,
  StaffActionTypes,
  StaffManuallyVerified,
  UpdateOrganizationStaff,
  UpdateOrganizationStaffIdentification,
} from './staff.actions';
import { StaffState } from './staff.reducer';
import { AddError } from '../error';
import { DefaultService } from '@gcv/generated-services';
import { LoadBank } from '../bank/bank.actions';
import { LoadDispensary } from '../dispensary/dispensary.actions';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';

@Injectable({
  providedIn: 'root',
})
export class StaffEffect {
  @Effect() loadStaff$ = this.dataPersistance.fetch(StaffActionTypes.LoadOrganizationStaff, {
    run: (action: LoadOrganizationStaff, state: StaffState) => {
      return this.sdk
        .organizationsIdUsersGet(action.payload.orgId)
        .pipe(map((res: User[]) => new OrganizationStaffLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.LoadOrganizationStaff, message: err });
    },
  });

  @Effect() LoadAllStaff$ = this.dataPersistance.fetch(StaffActionTypes.LoadAllStaff, {
    run: (action: LoadAllStaff, state: StaffState) => {
      return this.sdk.usersGet().pipe(map((res: User[]) => new AllStaffLoaded(res)));
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.LoadAllStaff, message: err });
    },
  });

  @Effect() CreateOrganizationStaff$ = this.dataPersistance.fetch(StaffActionTypes.CreateOrganizationStaff, {
    run: (action: CreateOrganizationStaff, state: StaffState) => {
      return this.sdk.usersPost(action.payload).pipe(
        switchMap((res: User) => {
          const LoadOrganization =
            action.payload.companies[0].companyType === 'bank'
              ? new LoadBank({ bankId: action.payload.companies[0].id })
              : new LoadDispensary({ dispensaryId: action.payload.companies[0].id });

          return [new OrganizationStaffCreated(res), LoadOrganization];
        })
      );
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.CreateOrganizationStaff, message: err });
    },
  });

  @Effect() updateStaff$ = this.dataPersistance.pessimisticUpdate(StaffActionTypes.UpdateOrganizationStaff, {
    run: (action: UpdateOrganizationStaff, state: StaffState) => {
      return this.sdk
        .usersIdPut(action.payload.id, action.payload)
        .pipe(map((res: User) => new OrganizationStaffUpdated(res)));
    },
    onError: err => {
      return new AddError({ action: StaffActionTypes.UpdateOrganizationStaff, message: err });
    },
  });

  @Effect() updateStaffIdentification$ = this.dataPersistance.pessimisticUpdate(
    StaffActionTypes.UpdateOrganizationStaffIdentification,
    {
      run: (action: UpdateOrganizationStaffIdentification, state: StaffState) => {
        return from(
          API.put(GREEN_CHECK_SERVICES_AMPLIFY_NAME, `/users/${action.payload.userId}/identification`, {
            body: action.payload.identificationDetails,
          })
        ).pipe(map((res: User) => new OrganizationStaffUpdated(res)));
      },
      onError: err => {
        return new AddError({ action: StaffActionTypes.UpdateOrganizationStaffIdentification, message: err });
      },
    }
  );

  constructor(private dataPersistance: DataPersistence<StaffState>, private sdk: DefaultService) {}
}
