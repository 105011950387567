<div [formGroup]="group">
  <mat-form-field class="example-full-width">
    <input
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      [attr.data-cy]="'mat-input.component.' + control"
      [autocomplete]="autoFill"
      [formControlName]="control"
      [placeholder]="label"
      [required]="required"
      [type]="type"
      matInput
    />
    <span *ngIf="prefix" matPrefix>{{ prefixValue }} &nbsp;</span>
    <span *ngIf="suffix" matSuffix>{{ suffixValue }} &nbsp;</span>
    <mat-error *ngIf="group.controls[control]?.invalid" data-cy="mat-input.component.mat-error">
      <span>{{ getErrorMessage() }}</span>
    </mat-error>
  </mat-form-field>
</div>
