import React, { useContext } from 'react';

import { DispensaryAccountReviewWithData, PeerGroupPeriodSummary, PeriodSummary } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { HorizontalBarConfig } from './chart-options';
import { tooltip } from './Tooltip';
import { formatChangePercentage, formatMoney, formatPercentage } from 'libs/react-ui/src/util';
import { AccountMonitoringContext } from '../../account-monitoring-context';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const SalesDollarsBarChar: React.FC<Props> = props => {
  const context = useContext(AccountMonitoringContext);
  return <GcvChart type="horizontalBar" height={80} config={chartDataAdapter(props.review, context.peerGroupId)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData, peerGroupId: string) => {
  return dataAdapter(review.data.aggregated_sales_data, peerGroupId);
};

export const dataAdapter = (
  data: {
    current_period: PeriodSummary;
    previous_period: PeriodSummary;
    previous_year: PeriodSummary;
    peer_groups: {
      [id: string]: PeerGroupPeriodSummary;
    };
  },
  peerGroupId: string
): HorizontalBarConfig => {
  return {
    categories: [
      {
        category: [{}],
      },
    ],
    dataset: [
      {
        seriesname: 'Verified',
        color: '#00BC66',
        data: [
          {
            value: data.current_period.verified_total,
            displayValue: tooltip('Verified', [
              {
                value: `${formatMoney(data.current_period.verified_total)} (${formatPercentage(
                  data.current_period.verified_percent,
                  100
                )})`,
                label: 'This Period',
              },
              {
                value: `${formatMoney(data.previous_period.verified_total)} (${formatPercentage(
                  data.previous_period.verified_percent,
                  100
                )})`,
                label: 'Last Period',
              },
              {
                value: formatChangePercentage(data.previous_period.verified_total, data.current_period.verified_total),
                label: 'Change Over Last Period',
              },
              {
                value: `${formatMoney(data.peer_groups[peerGroupId]?.avg_verified_amount)} (${formatPercentage(
                  data.peer_groups[peerGroupId]?.avg_verified_percent,
                  100
                )})`,
                label: 'Peer Group Avg',
              },
            ]),
          },
        ],
      },
      {
        seriesname: 'Unchecked',
        color: '#A5A8BA',
        data: [
          {
            value: data.current_period.uncheckable_total,
            displayValue: tooltip('Unchecked', [
              {
                value: `${formatMoney(data.current_period.uncheckable_total)} (${formatPercentage(
                  data.current_period.uncheckable_percent,
                  100
                )})`,
                label: 'This Period',
              },
              {
                value: `${formatMoney(data.previous_period.uncheckable_total)} (${formatPercentage(
                  data.previous_period.uncheckable_percent,
                  100
                )})`,
                label: 'Last Period',
              },
              {
                value: formatChangePercentage(
                  data.previous_period.uncheckable_total,
                  data.current_period.uncheckable_total
                ),
                label: 'Change Over Last Period',
              },
              {
                value: `${formatMoney(data.peer_groups[peerGroupId]?.avg_unchecked_amount)} (${formatPercentage(
                  data.peer_groups[peerGroupId]?.avg_unchecked_percent,
                  100
                )})`,
                label: 'Peer Group Avg',
              },
            ]),
          },
        ],
      },
      {
        seriesname: 'Unverified',
        color: '#FF4E50',
        data: [
          {
            value: data.current_period.unverified_total,
            displayValue: tooltip('Unverified', [
              {
                value: `${formatMoney(data.current_period.unverified_total)} (${formatPercentage(
                  data.current_period.unverified_percent,
                  100
                )})`,
                label: 'This Period',
              },
              {
                value: `${formatMoney(data.previous_period.unverified_total)} (${formatPercentage(
                  data.previous_period.unverified_percent,
                  100
                )})`,
                label: 'Last Period',
              },
              {
                value: formatChangePercentage(
                  data.previous_period.unverified_total,
                  data.current_period.unverified_total
                ),
                label: 'Change Over Last Period',
              },
              {
                value: `${formatMoney(data.peer_groups[peerGroupId]?.avg_unverified_amount)} (${formatPercentage(
                  data.peer_groups[peerGroupId]?.avg_unverified_percent,
                  100
                )})`,
                label: 'Peer Group Avg',
              },
            ]),
          },
        ],
      },
    ],
  };
};
