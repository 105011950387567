<gcv-card class="card" data-cy="editable-card-hours.component">
  <div *ngIf="!editable" card-body class="staticCard" data-cy="editable-card-hours.component.staticCard">
    <div class="editButtonWrapper">
      <span class="title">{{ title }}</span>
      <div (click)="edit()" *ngIf="showEdit" class="editIcon" data-cy="editable-card-hours.component.editIcon">
        <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 12v3.3c0 .2 0 .4.2.5l.5.2H4c.2 0 .3 0 .5-.2L15.8 4.5a1 1 0 0 0 0-1L12.5.2a.7.7 0 0 0-1 0L9.3 2.5l-9 9c-.2.1-.3.3-.3.5zM12 1.7L14.3 4 13 5.2 10.8 3 12 1.7zM1.4 12.3L9.8 4 12 6.2l-8.3 8.4H1.4v-2.3z"
            fill="#A5A8BA"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="formWrapper">
      <div
        *ngFor="let key of formKey"
        class="innerInputWrapper"
        data-cy="editable-card-hours.component.innerInputWrapper"
      >
        <span class="innerInputText" data-cy="editable-card-hours.component.innerInputText">{{ key.viewValue }}</span>

        <div *ngIf="data.get(key.value).get('isOpen').value">
          <span class="timeValue" data-cy="editable-card-hours.component.openHour">{{
            data.get(key.value).get('openHour').value | timeFormatter
          }}</span>
          -
          <span class="timeValue" data-cy="editable-card-hours.component.closeHour">{{
            data.get(key.value).get('closeHour').value | timeFormatter
          }}</span>
        </div>
        <div *ngIf="!data.get(key.value).get('isOpen').value">
          <span class="timeValue" data-cy="editable-card-hours.component.closed">Closed</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="editable" card-body class="editableCard" data-cy="editable-card-hours.component.editableCard">
    <div card-body class="innerTimeWrapper">
      <span class="header hoursOfOperation">{{ title }}</span>
      <div class="hourFormWrapper">
        <div
          *ngFor="let day of formKey"
          [formGroup]="tempData.controls[day.value]"
          class="timeButtonWrapper"
          data-cy="editable-card-hours.component.day"
        >
          <gcv-button
            (clickEvent)="toggleDay(day.value)"
            [buttonText]="day.viewValue"
            data-cy="editable-card-hours.component.toggleOpen"
            [type]="
              tempData.controls[day.value].controls['isOpen'].value ? 'pill primary small' : 'pill tertiary small'
            "
          ></gcv-button>
          <div class="timeWrapper">
            <gcv-input
              (value)="timeInputHandled($event, day.value)"
              [disabled]="!tempData.controls[day.value].controls['isOpen'].value"
              [inputValue]="tempData.controls[day.value].controls['openHour'].value"
              [styles]="{ 'margin-bottom': '1rem', width: '105px' }"
              data-cy="editable-card-hours.component.openHour"
              label="Open"
              property="openHour"
              type="time"
            ></gcv-input>
            <div class="hyphen">-</div>
            <gcv-input
              (value)="timeInputHandled($event, day.value)"
              [disabled]="!tempData.controls[day.value].controls['isOpen'].value"
              [inputValue]="tempData.controls[day.value].controls['closeHour'].value"
              [styles]="{ 'margin-bottom': '1rem', width: '105px' }"
              data-cy="editable-card-hours.component.closeHour"
              label="Close"
              property="closeHour"
              type="time"
            ></gcv-input>
          </div>
        </div>
      </div>
    </div>
    <div class="btnWrapper">
      <gcv-button
        (clickEvent)="cancel()"
        buttonText="Cancel"
        data-cy="editable-card-hours.component.cancel"
        type="tertiary fullWidth"
      ></gcv-button>
      <gcv-button
        (clickEvent)="save()"
        [disabled]="!tempData.valid"
        buttonText="Save"
        data-cy="editable-card-hours.component.saveButton"
        type="primary fullWidth"
      ></gcv-button>
    </div>
  </div>
</gcv-card>
