import React from 'react';
import { components } from 'react-select';

export const CustomValueContainer = ({ children, ...props }: any) => {
  const { ValueContainer, Placeholder } = components;
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child => {
        return child && child.type !== Placeholder ? child : null;
      })}
    </ValueContainer>
  );
};
