import React from 'react';
import { formatDateOrEmpty } from '../../util/formatHelpers';
import { $primary, $warn, $danger } from '../../util/styleVariables';
import { $grey1 } from '../../util';
import { DateTime } from 'luxon';
import { GcvUserInputContainer } from '../../lib';

export const name = (name: string) => ({
  name: name,
  selector: 'name',
  sortable: true,
  grow: 1,
});

export const documents = (name: string) => ({
  name: name,
  selector: 'document_count',
  sortable: true,
  grow: 1,
  format: row => row.document_count,
});

export const city = (name: string) => ({
  name: name,
  selector: 'city',
  sortable: true,
  grow: 1,
  format: row => (row.city ? row.city : '--'),
});

export const createdOn = (name: string) => ({
  name: name,
  selector: 'date_created',
  sortable: true,
  grow: 1,
  format: row => formatDateOrEmpty(row.date_created),
});

export const licenseType = (name: string) => ({
  name: name,
  selector: 'license_type',
  sortable: true,
  grow: 1,
});

export const reviewedOn = (name: string) => ({
  name: name,
  selector: 'reviewed_date',
  sortable: true,
  grow: 1,
  format: row => formatDateOrEmpty(row.due_diligence.bank_reviewed_on),
});

export const submittedOn = (name: string) => ({
  name: name,
  selector: 'submitted_date',
  sortable: true,
  grow: 1,
  format: row => formatDateOrEmpty(row.due_diligence.bank_submitted_date),
});

export const lastUpdated = (name: string) => ({
  name: name,
  selector: 'date_updated', //not an accurate property to use
  sortable: true,
  grow: 1,
  format: row => formatDateOrEmpty(row.date_updated),
});

export const complianceScore = (name, clickHandler) => ({
  name: name,
  selector: 'compliance_score',
  sortable: true,
  grow: 1,
  format: row => {
    let color = getComplianceScoreColor(row.compliance_score);

    const onClick = () => clickHandler(row.id);
    return (
      <div style={{ color }} onClick={onClick}>
        {row.compliance_score ? row.compliance_score + '%' : 'No Score Available'}
      </div>
    );
  },
});

export const getComplianceScoreColor = (score: number) => {
  let color = '';
  if (score > 90) {
    color = $primary;
  } else if (score > 70) {
    color = $warn;
  } else if (score) {
    color = $danger;
  } else {
    color = $grey1;
  }

  return color;
};

export const reviewStatus = (name, clickHandler) => ({
  name: name,
  selector: 'bank_review_in_progress',
  sortable: true,
  grow: 1,
  format: row => {
    let color = '';
    const inProgress = row.bank_review_in_progress;

    if (inProgress) {
      color = $primary;
    } else {
      color = $grey1;
    }

    const onClick = () => clickHandler(row.id);
    return (
      <div style={{ color }} onClick={onClick}>
        {inProgress ? 'In Progress' : 'Not Started'}
      </div>
    );
  },
});

export const uploadedOn = name => ({
  name: name,
  selector: 'uploadDate',
  sortable: true,
  grow: 1,
  format: row => (row.uploadDate ? DateTime.fromISO(row.uploadDate).toLocaleString(DateTime.DATE_SHORT) : ''),
});

export const fileName = (name, clickHandler) => ({
  name: name,
  selector: 'fileName',
  sortable: true,
  grow: 3,
  format: row => {
    const onClick = () => clickHandler(row);
    return (
      <div>
        <GcvUserInputContainer string={row.documentName} length={35} clickHandler={onClick} type="document" />
      </div>
    );
  },
});

export const reviewedBy = (name, clickHandler) => ({
  name: name,
  selector: 'reviewedBy',
  sortable: true,
  grow: 2,
  format: row => {
    const approved = row.status !== 'pending' && row.status !== 'submitted';
    const onClick = () => clickHandler(row);
    return (
      <GcvUserInputContainer
        string={approved ? row.reviewedBy : 'Needs Review'}
        length={35}
        style={{ color: approved ? $grey1 : $primary }}
        clickHandler={onClick}
      />
    );
  },
});
