import React, { useState, useEffect } from 'react';
import { Dispensary, Portal42Auth } from '@gcv/shared';
import { GcvInputForm } from '../../../lib';
import { ViewMoreIcon } from '../../../icons/ViewMoreIcon';

import { DrawerText, DrawerImage, DrawerList } from '../styles';
import { VerticalCenter } from '../../SalesAndDeposits/Deposits/depositDetailDrawer/styles';

interface Props {
  form: any;
}

export const QuickBooksForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To configure this integration, you will need to log into your Quickbooks account to allow the connection.
      </DrawerText>
      <DrawerText>
        To enable the integration, press “Connect” below and you will be redirected to Quickbooks. Follow their
        instructions to allow the connection.
      </DrawerText>
    </>
  );
};
