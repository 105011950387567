import { Component, OnInit } from '@angular/core';
import { LaunchDarklyService } from '../services/launch-darkley/launchdarkley.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { GlobalErrorService } from '../services/globalError.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private launchDarklyService: LaunchDarklyService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private globalError: GlobalErrorService
  ) {}

  ngOnInit() {
    this.angulartics2GoogleAnalytics.startTracking();
    this.launchDarklyService.setFlags();
    this.globalError.currentError.subscribe((errorEvent) => console.log('errorEvent: ', errorEvent));
  }

  detectBrowser() {
    let sBrowser;
    const sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf('Firefox') > -1) {
      sBrowser = true;
    } else if (sUsrAg.indexOf('Opera') > -1) {
      sBrowser = true;
    } else if (sUsrAg.indexOf('Trident') > -1) {
      sBrowser = false;
    } else if (sUsrAg.indexOf('Edge') > -1) {
      sBrowser = true;
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = true;
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = false;
    } else {
      sBrowser = false;
    }
    return sBrowser;
  }
}
