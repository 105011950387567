import React from 'react';

export const RedXIcon = ({ height = 26, width = 26, style = {} }) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="red-x-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
      fill="#FF4447"
    />
    <line
      x1="1.5"
      y1="-1.5"
      x2="9.54519"
      y2="-1.5"
      transform="matrix(0.706207 0.708005 -0.706207 0.708005 8 10.1815)"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="1.5"
      y1="-1.5"
      x2="9.54519"
      y2="-1.5"
      transform="matrix(0.706207 -0.708005 0.706207 0.708005 10.1998 17.8201)"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
