import React, { ReactNode, useEffect } from 'react';
import { Backdrop, SlideDrawer } from './styles';

export interface DrawerProps {
  open?: boolean;
  data?: any;
  onClose: () => void;
  children?: ReactNode;
  style?: {};
}

export const GcvDrawer = (props: DrawerProps) => {
  const { open, onClose } = props;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <>
      {open && <Backdrop onClick={onClose} />}
      <SlideDrawer open={open} style={props.style}>
        {props.children}
      </SlideDrawer>
    </>
  );
};
