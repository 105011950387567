import React from 'react';
import ReactTooltip from 'react-tooltip';

import { cropInput } from '../../util/formatHelpers';

import { Container } from './styles';

interface Props {
  string: string;
  length: number;
  style?: {};
  type?: string;
  clickHandler?: () => void;
}

export const GcvUserInputContainer = ({ string, length, style, type, clickHandler, ...props }: Props) => {
  if (!string) {
    return null;
  }

  const inputStyle = clickHandler ? { cursor: 'pointer' } : {};

  return (
    <Container data-tip={string} style={{ ...inputStyle, ...style }} onClick={clickHandler} {...props}>
      {cropInput(string, length, type)}
      {string.length > length ? (
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          delayShow={1000}
          multiline={true}
          className="react-tool-tip"
        />
      ) : null}
    </Container>
  );
};
