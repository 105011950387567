<div class="outer-wrapper">
  <div [ngClass]="hasBank && bank && expanded ? 'expanded' : 'collapsed'" class="inner-wrapper">
    <div class="titleWrapper">
      <svg *ngIf="!hasBank || !bank" fill="none" height="26" width="40" xmlns="http://www.w3.org/2000/svg">
        <path fill="#F2F4FA" d="M5.8 14s2.5 0 3.6 1.6L8.7 17 6 15 5.8 14z" />
        <path
          fill="#F2F4FA"
          d="M4.7 1H2.3C1.6 1 1 1.6 1 2.3v14c0 .7.6 1.3 1.3 1.3h2.4c.7 0 1.3-.6 1.3-1.3v-14C6 1.6 5.4 1 4.7 1z"
        />
        <path
          fill="#E0E5F0"
          d="M4.7 15.6H2.3A1.3 1.3 0 0 1 1 14.3v2a1.3 1.3 0 0 0 1.3 1.3h2.4A1.3 1.3 0 0 0 6 16.3v-2a1.3 1.3 0 0 1-1.3 1.3z"
        />
        <path
          stroke="#9B9EB1"
          stroke-linejoin="round"
          d="M4.7 1H2.3C1.6 1 1 1.6 1 2.3v14c0 .7.6 1.3 1.3 1.3h2.4c.7 0 1.3-.6 1.3-1.3v-14C6 1.6 5.4 1 4.7 1z"
        />
        <path
          fill="#F2F4FA"
          d="M37.8 1h-2.4c-.7 0-1.3.6-1.3 1.3v14c0 .7.6 1.3 1.3 1.3h2.4c.7 0 1.3-.6 1.3-1.3v-14c0-.7-.6-1.3-1.3-1.3zM34.1 14.7l-3.6 2-1.4-1.4 5-2.5v2z"
        />
        <path
          fill="#E0E5F0"
          d="M37.8 15.6h-2.4a1.3 1.3 0 0 1-1.3-1.3v2a1.3 1.3 0 0 0 1.3 1.3h2.4a1.3 1.3 0 0 0 1.3-1.3v-2a1.3 1.3 0 0 1-1.3 1.3z"
        />
        <path
          stroke="#9B9EB1"
          stroke-linejoin="round"
          d="M37.8 1h-2.4c-.7 0-1.3.6-1.3 1.3v14c0 .7.6 1.3 1.3 1.3h2.4c.7 0 1.3-.6 1.3-1.3v-14c0-.7-.6-1.3-1.3-1.3zM6 2.7c7.6 0 11.4-3.4 16.1-.4"
        />
        <path fill="#F2F4FA" d="M26 9l-5.5.4-7 4.1s-1.4.3-2-.8c1 .2 2.2-.5 2.2-.5l5.7-3.8c.5-.3 1.1-.4 1.7-.3l4.9.8z" />
        <path fill="#E0E5F0" d="M14 13.8l7.2-3h3.2l-1.3-1.3-2.6-.1-6.4 4.4z" />
        <path fill="#F2F4FA" d="M30.5 16.6l-7.4-7h2.2l5.8 6.7-.6.3z" />
        <path
          stroke="#9B9EB1"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M34.1 2.3H22.7c-1.7 0-3.3.6-4.5 1.8l-6.5 6.3a1.9 1.9 0 0 0 2.3 3l6.5-4H26"
        />
        <path fill="#F2F4FA" d="M31 17.4a1.7 1.7 0 0 1-2.2 2.3 1.7 1.7 0 1 0 2.3-2.3z" />
        <path
          stroke="#9B9EB1"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 15.6s1.2 0 2.7 1.4M23.1 9.5l8.4 8.1a1.8 1.8 0 0 1 0 2.6 1.8 1.8 0 0 1-2.5 0l-4.8-4.7"
        />
        <path
          fill="#F2F4FA"
          d="M23.5 20a1.7 1.7 0 0 1 0 1.5 1.7 1.7 0 0 1-2.3.8 1.7 1.7 0 1 0 2.3-2.3zM27.6 19.2l.2.7a1.7 1.7 0 0 1-1.7 1.7l-.8-.1a1.7 1.7 0 1 0 2.3-2.3z"
        />
        <path
          stroke="#9B9EB1"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M24.2 15.5l3.9 3.7a1.8 1.8 0 1 1-2.5 2.6l-6-5.9"
        />
        <path
          stroke="#9B9EB1"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.7 15.9l4.3 4.3a1.8 1.8 0 1 1-2.5 2.6l-.7-.6"
        />
        <path
          fill="#F2F4FA"
          d="M20.5 21.6c0 .4-.1.8-.4 1l-.7 1c-.6.7-1.3 1.3-2.3.3-.2 0-.3-.2-.3-.3a1.7 1.7 0 0 0 .6 1.3 1.7 1.7 0 0 0 2.4-.2l.7-1a1.8 1.8 0 0 0 0-2zM17.8 19.6c0 .4 0 .7-.3 1l-.8 1c-.6.7-1.3 1.3-2.3.3l-.3-.4a1.7 1.7 0 0 0 .6 1.3 1.7 1.7 0 0 0 2.4-.2l.8-.9a1.8 1.8 0 0 0 0-2.1zM15.1 17.3c0 .4 0 .8-.3 1l-.8 1c-.5.7-1.3 1.3-2.2.3h-.1l-.3-.3a1.7 1.7 0 0 0 .6 1.3 1.7 1.7 0 0 0 2.5-.2l.7-1a1.8 1.8 0 0 0 0-2zM12.5 15.2c0 .4-.2.8-.4 1l-.7 1c-.6.7-1.4 1.3-2.3.3l-.4-.3a1.7 1.7 0 0 0 .7 1.3 1.7 1.7 0 0 0 2.4-.2l.7-1a1.8 1.8 0 0 0 0-2z"
        />
        <path
          stroke="#9B9EB1"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M30.5 16.6l3.6-1.9M17.6 19.3c-.8-.7-1.8-.6-2.4.2l-.7.9c-.6.7-.5 1.8.2 2.4.8.6 1.9.5 2.4-.3l.8-.9c.5-.7.4-1.8-.3-2.3z"
        />
        <path
          stroke="#9B9EB1"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M20.3 21.4c-.8-.6-1.9-.5-2.4.2l-.8 1c-.5.7-.4 1.7.3 2.3.8.6 1.8.5 2.4-.2l.7-1c.6-.7.5-1.7-.2-2.3zM12.2 15c-.7-.7-1.8-.5-2.4.2l-.7.9c-.6.7-.5 1.8.2 2.4h.1c.7.6 1.8.5 2.4-.3l.7-.9c.6-.7.5-1.8-.3-2.3z"
        />
        <path
          stroke="#9B9EB1"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15 17.1h-.1c-.8-.6-1.8-.5-2.4.2l-.7 1c-.6.7-.5 1.7.2 2.3.8.6 1.9.5 2.4-.2l.8-1c.6-.7.4-1.7-.3-2.3z"
        />
      </svg>
      <svg *ngIf="hasBank && bank && !imagePath" fill="none" height="45" width="37" xmlns="http://www.w3.org/2000/svg">
        <path fill="#F2F2F2" d="M34.56 19.3H2.7v8.3h31.86v-8.3z" />
        <path fill="#E0E5F0" d="M34.56 15.75H2.7v1.98h31.86v-1.98zM34.56 3.87H2.7v1.98h31.86V3.87z" />
        <path
          fill="#00BC66"
          d="M13.81 23.6a1.02 1.02 0 0 0 .56-1.03c0-.94-.66-1.17-1.32-1.17H11.7c-.23 0-.45.1-.45.3v4.21c0 .17.17.32.45.32h1.46c.75 0 1.33-.38 1.33-1.43-.02-.63-.12-1-.68-1.2zm-1.54-1.3h.7c.25 0 .37.23.37.46a.4.4 0 0 1-.37.45h-.73l.03-.92zm.63 2.99h-.66v-1.3h.66c.33 0 .53.15.53.6 0 .45-.08.7-.53.7zM17.26 21.68c-.15-.46-1.2-.46-1.35 0l-1.23 4.03c-.1.43.87.7.98.3l.23-.83h1.4l.22.83c.11.4 1.1.13.99-.3l-1.24-4.03zm-1.16 2.71l.48-1.77.49 1.77h-.97zM21.24 21.68v2.24l-1.08-2.04c-.25-.49-.38-.52-.75-.52-.26 0-.52.1-.52.31v4.2c0 .43 1.03.43 1.03 0v-2.24l1.26 2.29c.23.43 1.12.32 1.12-.04v-4.2c-.03-.41-1.06-.41-1.06 0zM25 23.46l1.09-1.36c.3-.4-.53-1-.82-.56l-1.23 1.75v-1.6c0-.23-.26-.32-.52-.32-.25 0-.51.1-.51.31v4.2c0 .43 1.03.43 1.03 0v-1.2l.28-.35 1.12 1.72c.3.49 1.2-.05.92-.5l-1.35-2.09z"
        />
        <path
          fill="#000"
          d="M20.66 35.58a.64.64 0 0 0-.64.65v2.19a.64.64 0 1 0 1.29 0v-2.2a.65.65 0 0 0-.65-.64zM16.61 35.58a.64.64 0 0 0-.64.65v2.19a.64.64 0 1 0 1.28 0v-2.2a.64.64 0 0 0-.64-.64z"
        />
        <path fill="#F2F2F2" d="M34.56 41.33H2.7v2.73h31.86v-2.73z" />
        <path stroke="#00BC66" stroke-linejoin="round" d="M34.56 3.87H2.7V13h31.86V3.87z" />
        <path fill="#F2F2F2" d="M35.37 13H5.17v2.87h30.2V13z" />
        <path fill="#E0E5F0" d="M35.37 13h-2.88v2.87h2.88V13z" />
        <path stroke="#00BC66" stroke-linejoin="round" d="M34.56 15.75H2.7v28.47h31.86V15.75z" />
        <path fill="#F2F2F2" d="M35.37 1H5.17v2.87h30.2V1z" />
        <path fill="#E0E5F0" d="M35.37 1h-2.88v2.87h2.88V1z" />
        <path stroke="#00BC66" stroke-linejoin="round" d="M35.37 1H2.13v2.87h33.24V1zM35.37 13H2.13v2.87h33.24V13z" />
        <path
          stroke="#00BC66"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.7 41.38h31.86M2.7 27.9h31.86M2.7 19.3h31.86M1 44.22h35"
        />
        <path fill="#F2F2F2" d="M25.93 30.83H11.22v13.39h14.71V30.83z" />
        <path
          stroke="#00BC66"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M26.03 30.83H11.32v13.39h14.71V30.83z"
        />
        <path stroke="#00BC66" stroke-linejoin="round" d="M18.68 44.22V30.83" />
        <path stroke="#00BC66" stroke-linecap="round" stroke-linejoin="round" d="M16.07 38.87V36.7M21.28 38.87V36.7" />
      </svg>
      <img *ngIf="imagePath" [src]="imagePath" style="width: 50px; height: 50px;" />

      <span [ngClass]="hasBank && bank ? 'active' : 'inactive'" [ngStyle]="logoTextStyles">{{
        hasBank && bank ? bank.orgName : 'Locating your banking partner'
      }}</span>
      <button class="expandButton" (click)="expandToolTip()">
        <svg
          *ngIf="hasBank && bank"
          [ngClass]="expanded ? 'expandedIcon' : 'collapsedIcon'"
          class="icon"
          id="expandIcon"
          fill="none"
          height="26"
          viewBox="0 0 22 22"
          width="26"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
            fill-rule="evenodd"
            [style.stroke]="logoTextStyles.color ? logoTextStyles.color : '#00BC66'"
          />
          <path
            d="M14.0609 13.165C14.3286 13.4437 14.7626 13.4437 15.0303 13.165C15.298 12.8863 15.298 12.4345 15.0303 12.1559L11.0003 7.96042L6.97029 12.1559C6.70261 12.4345 6.70261 12.8863 6.97029 13.165C7.23797 13.4437 7.67197 13.4437 7.93966 13.165L11.0003 9.97873L14.0609 13.165Z"
            [style.fill]="logoTextStyles.color ? logoTextStyles.color : '#02B45B'"
          />
        </svg>
      </button>
    </div>
    <div *ngIf="expanded" class="bankContent">
      <div class="border-top"></div>
      <div class="labelWrapper">
        <span class="label">Address</span>
        <span>{{ bank.address }}</span>
        <span>{{ bank.city }}, {{ bank.state }}</span>
        <span>{{ bank.postalCode }}</span>
      </div>
      <div *ngIf="bank.businessHours" class="labelWrapper">
        <span class="label">Hours</span>
        <table>
          <tr *ngFor="let day of dayArray">
            <td class="day">{{ day.viewValue }}</td>
            <td *ngIf="bank.businessHours[day.value].isOpen">
              {{ bank.businessHours[day.value].openHour }} - {{ bank.businessHours[day.value].closeHour }}
            </td>
            <td *ngIf="!bank.businessHours[day.value].isOpen">Closed</td>
          </tr>
        </table>
      </div>
      <div *ngIf="bank.primary_point_of_contact" class="labelWrapper">
        <span class="label">Contact</span>
        <span
          >{{ bank.primary_point_of_contact && bank.primary_point_of_contact.firstName }}
          {{ bank.primary_point_of_contact && bank.primary_point_of_contact.lastName }}</span
        ><br />
        <span>{{ bank.primary_point_of_contact && bank.primary_point_of_contact.title }}</span
        ><br />
        <span>{{ bank.primary_point_of_contact && bank.primary_point_of_contact.emailAddress }}</span
        ><br />
        <span>{{ bank.primary_point_of_contact && bank.primary_point_of_contact.phoneNumber | formatPhonePipe }}</span>
      </div>
      <div class="labelWrapper">
        <span class="label">Support</span>
        <span>{{ bank.phoneNumber | formatPhonePipe }}</span>
        <span>{{ bank.website }}</span>
      </div>
    </div>
  </div>
</div>
