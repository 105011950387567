import styled from 'styled-components';
import { $accent, $black, $danger, $grey2, $grey1, $primaryBorder } from '../../../util/styleVariables';
import { mediaQuery } from '../../../styles/mediaQuery';

interface Props {
  unverified?: boolean;
}

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.5rem 0;
  position: relative;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 4rem;
  height: auto;
  margin-bottom: 0.5rem;
`;

export const Heading = styled.h2`
  color: ${$black};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin: 0;
  margin-bottom: 1.5rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
`;

export const VerifiedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 1rem;
`;

export const VerifiedText = styled.span<Props>`
  color: ${props => (props.unverified ? $danger : $black)};
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
`;

export const VerifiedLabel = styled.span`
  color: ${$accent};
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
`;

export const ReviewText = styled.span`
  padding: 0px 1rem;
  color: #3a3c45;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  overflow-y: auto;
  max-height: 65vh;

  ${mediaQuery.sm`
     grid-template-columns: unset;
  `}
`;

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ToggleCardTableView = styled.div`
  display: flex;
`;
export const Table = styled.div`
  padding: 10px 5px;
  border: 1px solid #a5a8ba;
  box-sizing: border-box;
  border-radius: 0px 5px 5px 0px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;
export const Cards = styled.div`
  padding: 10px 5px;
  border: 1px solid #a5a8ba;
  box-sizing: border-box;
  border-radius: 5px 0px 0px 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;

export const SearchInputContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: -1rem;
`;

export const HeaderSubContent = styled.span`
  margin-left: 2rem;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: ${$grey1};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardContainer = styled.div`
  margin: 1.5rem;
  margin-left: 0;
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BodySubTitle = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 1.25;
  color: ${$grey2};
`;

export const BodySubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 12.5rem;
`;

export const BodySubContent = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const HoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid ${$primaryBorder};
`;

export const HoursContainerLast = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const Day = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
`;

export const Time = styled.div`
  font-family: Lato, Helvetica, Arial, san-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
`;

export const ButtonIcon = styled.span`
  color: #e0e0e0;
  &:hover {
    cursor: pointer;
  }
`;
