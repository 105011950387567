import React, { useState, useEffect } from 'react';
import styled, { CSSProperties } from 'styled-components';
import Modal from 'react-modal';
import { ValueType } from 'react-select';
import { GcvButton } from '../../../lib/GcvButton/GcvButton';
import { GcvTimePeriodDropdown } from '../../../lib/GcvTimePeriodDropdown/GcvTimePeriodDropdown';
import calculateDateRange from '../../../util/calculateDateRange';

import {
  ModalStyleOverrides,
  customModalStyles,
  ExportActivityModalBody,
  ButtonContainer,
  RadioGroup,
} from '../styles';
import { ActivityType } from '..';
import { IANATimezones } from '@gcv/shared';

interface Props {
  active: boolean;
  timezone: IANATimezones;
  toggleModal: () => void;
  onSubmit: (payload: Payload) => void;
}

interface Payload {
  startDate: string;
  endDate: string;
  activityType?: ActivityType;
}

const selectStyles = {
  input: provided => ({
    ...provided,
    width: '100%',
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
  }),
  control: (provided): CSSProperties => ({
    ...provided,
    width: '100%',
    outline: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    boxShadow: 'none',
    borderRadius: 0,
  }),
  container: provided => ({
    ...provided,
    margin: '40px auto',
    width: '70%',
  }),
};

export const RadioButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 40px auto;

  label {
    font-weight: bold;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: -0.21875px;
    color: #3a3c45;
    margin-left: 5px;
  }

  input,
  label {
    cursor: pointer;
  }
`;

const modalStyles = {
  ...customModalStyles,
  content: {
    ...customModalStyles.content,
    width: '450px',
    height: 'fit-content',
  },
};

export const ExportActivityModal = (props: Props) => {
  const [timePeriod, setTimePeriod] = useState(null);
  const [activityType, setActivityType] = useState<ActivityType>('sales');
  const [exportInProgress, setExportInProgress] = useState(false);

  useEffect(() => {
    Modal.setAppElement('#modal-root');
  }, []);

  useEffect(() => {
    if (!props.active) {
      // tslint:disable-next-line: no-use-before-declare
      resetFormState();
    }
  }, [props.active]);

  const resetFormState = () => {
    setActivityType('sales');
    setTimePeriod(null);
  };

  const onTimePeriodSelect = (value: any) => {
    setTimePeriod(value);
  };

  const onActivityTypeSelect: React.FormEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;

    if (value === 'sales' || value === 'deposits' || value === 'daily-summaries') {
      setActivityType(value);
    }
  };

  const createPayload = (): Payload => {
    const { start, end } = calculateDateRange(timePeriod.value, props.timezone);
    return {
      startDate: start,
      endDate: end,
      activityType,
    };
  };

  const handleSubmit = () => {
    const payload = createPayload();
    props.onSubmit(payload);
    setExportInProgress(true);
  };

  return (
    <>
      <ModalStyleOverrides />
      <Modal style={modalStyles} isOpen={props.active} onRequestClose={props.toggleModal}>
        <ExportActivityModalBody>
          <h2>Export Activity</h2>
          <h5>Please select the activity you'd like to export</h5>
          <div style={{ width: '250px' }}>
            <GcvTimePeriodDropdown emitData={onTimePeriodSelect} />
          </div>

          <RadioButtons>
            <RadioGroup>
              <input
                id="sales"
                type="radio"
                value="sales"
                onChange={onActivityTypeSelect}
                checked={activityType === 'sales'}
              />
              <label htmlFor="sales">Sales</label>
            </RadioGroup>
            <RadioGroup>
              <input
                id="deposits"
                type="radio"
                value="deposits"
                onChange={onActivityTypeSelect}
                checked={activityType === 'deposits'}
              />
              <label htmlFor="deposits">Deposits</label>
            </RadioGroup>
            <RadioGroup>
              <input
                id="daily-summaries"
                type="radio"
                value="daily-summaries"
                onChange={onActivityTypeSelect}
                checked={activityType === 'daily-summaries'}
              />
              <label htmlFor="daily-summaries">Daily Summaries</label>
            </RadioGroup>
          </RadioButtons>
          <ButtonContainer>
            <GcvButton tertiary onClick={props.toggleModal}>
              Cancel
            </GcvButton>
            <GcvButton primary disabled={!timePeriod || exportInProgress} onClick={handleSubmit}>
              Export
            </GcvButton>
          </ButtonContainer>
        </ExportActivityModalBody>
      </Modal>
    </>
  );
};
