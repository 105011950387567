export const appRoutesNames = {
  LOGIN: 'login',
  COGNITO: 'cognito',
  RESEND_INVITATION_EMAIL: 'auth/resend-invitation-email',
  USER: 'user',
  REGISTRATION: 'registration',
  SECURE: 'secure',
};

export function getDispensaryDashboardOverviewCommand() {
  return getDashboardOverviewCommandForOrgType('dispensary');
}

export function getDashboardOverviewCommandForOrgType(orgType: 'dispensary' | 'bank') {
  return `/${appRoutesNames.SECURE}/${orgType}/dashboard/${orgType === 'bank' ? 'overview' : 'sales'}`;
}
