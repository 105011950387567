import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gcv-compliance-score-badge',
  templateUrl: './compliance-score-badge.component.html',
  styleUrls: ['./compliance-score-badge.component.scss'],
})
export class ComplianceScoreBadgeComponent implements OnInit {
  @Input() score: number;
  public classes: {
    complianceScoreBadge: boolean;
    red: boolean;
    green: boolean;
    yellow: boolean;
  };
  public tooltipText: string;

  constructor() {}

  ngOnInit() {
    this.classes = {
      complianceScoreBadge: true,
      green: this.score === undefined || this.score >= 90,
      yellow: this.score >= 80 && this.score < 90,
      red: this.score < 80,
    };

    this.setTooltipText();
  }

  setTooltipText() {
    const noScoreText = 'This business has not run any sales through Green Check yet';
    const scoreText = `This business has ${this.score}% verified sales since using Green Check`;

    this.tooltipText = this.score === undefined ? noScoreText : scoreText;
  }
}
