import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gcv-transportation-vendors',
  templateUrl: './transportation-vendors.component.html',
  styleUrls: ['./transportation-vendors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationVendorsComponent {
  @Input() vendors;
  @Input() dispensaryView = true;
  @Input() title = 'Deposit Transportation Users';
  @Input() subtitle = 'These are users who belong to a group with the Method of Transportation role.';
  @Output() favoriteDispVendor = new EventEmitter();
  @Output() editVendor = new EventEmitter();
  @Output() dispatchView = new EventEmitter();

  constructor() {}

  handleFavorite(vendor, i) {
    this.favoriteDispVendor.emit({ type: 'dispensary', data: vendor });
  }

  handleEdit(e) {
    if (e.active) {
      this.editVendor.emit(e);
    }
  }

  handleView(vendor) {
    this.dispatchView.emit(vendor);
  }
}
