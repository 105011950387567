import { Action, Bank, Dispensary, OrganizationRoleResolver, User } from '@gcv/shared';

export const checkPermissions = (permissions: Action[], user: User, organization: Bank | Dispensary) => {
  const resolver = new OrganizationRoleResolver();

  let hasPermission = false;
  if (permissions && user && organization) {
    permissions.forEach((permission: Action) => {
      if (resolver.userCanDoAction(organization.groups, user, permission)) {
        hasPermission = true;
        return;
      }
      return;
    });
    return hasPermission;
  } else {
    return hasPermission;
  }
};
