import styled, { createGlobalStyle } from 'styled-components';
import FloatingLabelInput from 'react-floating-label-input';
import { $primary } from '../../util/styleVariables';

export const SearchInputContainer = styled.div`
  position: relative;
  height: 18px;
  margin-top: 35px;
  ${props => ({ ...props.style })}
`;

export const GlobalStyles = createGlobalStyle`
  div.floating-input {

      &:focus-within {
         border-bottom: 1px solid ${$primary};

         label {
            color: ${$primary};
         }
      }
    
  }`;

export const SearchInput = styled(FloatingLabelInput)`
  padding: 5px 0;
  width: 100%;
  font-size: 16px;
  color: #3a3c45;
  background: transparent;

  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }

  &::placeholder {
    font-size: 16px;
    line-height: 19px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
  }
`;

export const InputIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 14px;
  margin-bottom: 5px;
`;
