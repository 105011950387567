import React from 'react';

interface Props {
  height?: number;
  width?: number;
  style?: any;
  onClick?(event: React.MouseEvent<HTMLOrSVGElement>): void;
}

export const DocumentMiniIcon = ({ height = 18, width = 14, style, onClick }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 18"
    fill="none"
    version="1.1"
    style={style}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    data-cy="document-mini-icon"
  >
    <path d="M3.38614 3.62027V0.645996L0.322266 3.62027H3.38614Z" fill="#02B45B" />
    <path
      d="M13.4576 0.333008H4.45654V4.14691C4.45654 4.43153 4.20733 4.67346 3.91414 4.67346H0V17.1398C0 17.4244 0.249215 17.6663 0.542408 17.6663H13.4576C13.7508 17.6663 14 17.4244 14 17.1398V0.859554C14 0.574934 13.7508 0.333008 13.4576 0.333008ZM4.10471 5.95425H9.88063C10.1738 5.95425 10.423 6.19617 10.423 6.48079C10.423 6.76541 10.1738 7.00734 9.88063 7.00734H4.10471C3.81152 7.00734 3.5623 6.76541 3.5623 6.48079C3.5623 6.19617 3.81152 5.95425 4.10471 5.95425ZM4.10471 7.91812H9.88063C10.1738 7.91812 10.423 8.16005 10.423 8.44467C10.423 8.72929 10.1738 8.97121 9.88063 8.97121H4.10471C3.81152 8.97121 3.5623 8.72929 3.5623 8.44467C3.5623 8.14582 3.81152 7.91812 4.10471 7.91812ZM4.10471 9.86776H9.88063C10.1738 9.86776 10.423 10.1097 10.423 10.3943C10.423 10.6789 10.1738 10.9209 9.88063 10.9209H4.10471C3.81152 10.9209 3.5623 10.6789 3.5623 10.3943C3.5623 10.1097 3.81152 9.86776 4.10471 9.86776ZM4.10471 11.8316H8.45864C8.75183 11.8316 9.00105 12.0736 9.00105 12.3582C9.00105 12.6428 8.75183 12.8847 8.45864 12.8847H4.10471C3.81152 12.8847 3.5623 12.6428 3.5623 12.3582C3.5623 12.0593 3.81152 11.8316 4.10471 11.8316Z"
      fill="#02B45B"
    />
  </svg>
);
