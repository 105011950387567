import { Component, OnDestroy, OnInit } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { interval, Subscription } from 'rxjs';
import { catchError, last, throttle } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { AuthService } from '../route-guards/auth.service';
import { LoggerService } from '@user-interface/gcv-ui';
import { throwError } from 'rxjs';
import { ErrorFacade, UserActionTypes } from 'libs/gcv-state/src/lib';

@Component({
  selector: 'app-cognito',
  templateUrl: './cognito.component.html',
  styleUrls: ['./cognito.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ position: 'absolute' }),
        animate('200ms ease-in', style({ transform: 'translateX(200%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class CognitoComponent implements OnInit, OnDestroy {
  signedIn: boolean;
  user: any;
  loading = false;
  authState = { state: 'signIn', user: null };
  private authServ$: Subscription;
  private amplifyServ$: Subscription;

  constructor(
    private amplifyService: AmplifyService,
    private authService: AuthService,
    private errorFacade: ErrorFacade,
    private loggerService: LoggerService
  ) {}

  ngOnInit() {
    this.setupStreams();
  }

  ngOnDestroy() {
    if (this.authServ$) {
      this.authServ$.unsubscribe();
    }

    if (this.amplifyServ$) {
      this.amplifyServ$.unsubscribe();
    }
  }

  setupStreams() {
    try {
      this.authServ$ = this.authService.loading.subscribe(val => (this.loading = val));
      this.amplifyServ$ = this.amplifyService.authStateChange$.pipe(throttle(() => interval(500))).subscribe(
        authState => {
          this.authState = authState;
          if (authState.state === 'signedIn' && !this.authService.loggin) {
            this.user = authState.user;
            this.authService.loggin = true;
            this.loading = true;
            this.authService
              .initLogin(authState.user, false)
              .pipe(
                last(),
                catchError(e => {
                  this.errorFacade.addNewError(UserActionTypes.LoginUser, e);
                  return throwError(e);
                })
              )
              .subscribe(() => this.loggerService.log('Logged In'));
          }
        },
        e => {
          this.errorFacade.addNewError(UserActionTypes.LoginUser, e);
        }
      );
    } catch (e) {
      this.errorFacade.addNewError(UserActionTypes.LoginUser, e);
    }
  }

  signingIn(e) {
    this.loading = e;
  }
}
