import React from 'react';

import { DepositsCRB } from './Deposits/Deposits';
import { OrgType } from './Deposits/types';
import { DailySummariesFi, DailySummariesCrb, DailySummariesDetailView } from './DailySummaries';

const bank: OrgType = 'bank';
const dispensary: OrgType = 'dispensary';

const Routes = [
  {
    path: '/secure/bank/dashboard/daily-summaries',
    render: props => <DailySummariesFi {...props} />,
  },
  {
    path: '/secure/bank/dashboard/daily-summaries/detail/:dispensaryId/:date',
    render: props => <DailySummariesDetailView backClickLink={'/secure/bank/dashboard/daily-summaries'} />,
  },
  {
    path: '/secure/bank/dashboard/deposits',
    render: props => <DepositsCRB {...props} type={bank} />,
  },
  {
    path: '/secure/dispensary/dashboard/deposits',
    render: props => <DepositsCRB {...props} type={dispensary} />,
  },
  {
    path: '/secure/dispensary/dashboard/daily-summaries/detail/:dispensaryId/:date',
    render: props => <DailySummariesDetailView backClickLink={'/secure/dispensary/dashboard/daily-summaries'} />,
  },
  {
    path: '/secure/dispensary/dashboard/daily-summaries',
    render: props => <DailySummariesCrb {...props} />,
  },
];

export default Routes;
