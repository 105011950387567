import React from 'react';
import { useForm } from 'react-hook-form';

import { GcvButton, GcvInputSelect } from '../../../../lib';

import { Tab, Buttons, FormContent, TabContainer } from './styles';

export const Tab2 = ({ next, back, data }) => {
  const form = useForm();

  const options = Object.keys(data.dispensaries).map(dispensaryId => ({
    value: dispensaryId,
    label: data.dispensaries[dispensaryId].name,
  }));

  options.sort((a, b) => a.label.localeCompare(b.label));

  const onBack = () => {
    back();
  };

  return (
    <>
      <TabContainer>
        <Tab>
          <h1>Account</h1>
          <FormContent data-cy="account-select">
            <GcvInputSelect
              name={'account'}
              label={'Account'}
              options={options}
              rules={{ required: true }}
              defaultValue={data['account'] || ''}
              onChangeCallback={val => data.loadDispensaryData(val.value)}
              {...form}
            />
          </FormContent>
        </Tab>
      </TabContainer>
      <Buttons>
        <GcvButton onClick={onBack} tertiary={true} data-cy="back-button">
          Back
        </GcvButton>
        <GcvButton onClick={form.handleSubmit(next)} primary={true} data-cy="next-button">
          Next
        </GcvButton>
      </Buttons>
    </>
  );
};
