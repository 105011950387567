<div class="wrapper">
  <div class="innerWrapper">
    <app-amplify-auth-require-new-password-core
      class="center"
      [oldPassword]="password"
      [email]="email"
      (isLoading)="loading = $event"
      (passwordChanged)="passwordChanged($event)"
      (errorMessage)="setErrorMessage($event)"
    ></app-amplify-auth-require-new-password-core>
    <div class="amplify-alert" *ngIf="errorMessage">
      <div class="amplify-alert-body">
        <span class="amplify-alert-icon">&#9888;</span>
        <div class="amplify-alert-message">{{ errorMessage }}</div>
        <a class="amplify-alert-close" (click)="onAlertClose()">&times;</a>
      </div>
    </div>
  </div>
</div>
<gcv-spinner *ngIf="loading"></gcv-spinner>
