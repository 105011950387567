import { ReactWrapperComponent, WrapperProps } from '../../util';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddNewDocument } from './AddNewDocument';

@Component({
  selector: 'add-new-document-container',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="onUploadDocument"></app-react-wrapper>',
})
export class AddNewDocumentComponent extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Output() uploadDocument = new EventEmitter<any>();

  component = AddNewDocument;

  constructor() {
    super();
    this.onUploadDocument = this.onUploadDocument.bind(this);
  }

  onUploadDocument(document) {
    const data = {
      document: document,
      userType: 'dispensary',
    };
    this.uploadDocument.emit(data);
  }
}
