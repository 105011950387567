import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

export interface DocumentationReview {
  type: string;
  id: string;
  name: string;
  status: string;
  reviewed_by: string;
}

@Component({
  selector: 'gcv-review-account-documents-table',
  templateUrl: './review-account-documents-table.component.html',
  styleUrls: ['./review-account-documents-table.component.scss'],
})
export class ReviewAccountDocumentsTableComponent implements OnInit, OnChanges {
  @Input() documents: any;
  @Input() businessLicenses: any;
  @Input() employeeLicenses: any;
  transformedDocs: DocumentationReview[];
  filteredDocuments: DocumentationReview[];
  sortedDocuments: DocumentationReview[];
  sort: { active: string; direction: 'asc' | 'desc' | '' } = { active: '', direction: '' };
  filterToggled = false;
  displayedColumns: string[] = ['requirement', 'type', 'reviewed', 'reviewed_by'];
  filter = { financial: false, legal: false, operational: false, licenses: false, other: false };
  @Output() openDocumentEmitter = new EventEmitter<DocumentationReview>();
  @Output() openLicenseEmitter = new EventEmitter<DocumentationReview>();

  constructor() {}

  ngOnInit() {
    this.transformedDocs = this.makeDocumentsArray();
    this.filteredDocuments = this.transformedDocs.slice();
    this.sortedDocuments = this.filteredDocuments.slice();
  }

  ngOnChanges() {
    this.transformedDocs = this.makeDocumentsArray();
    this.filteredDocuments = this.transformedDocs.slice();
    this.sortedDocuments = this.filteredDocuments.slice();
  }

  makeDocumentsArray() {
    const docArray = [];
    for (const category in this.documents) {
      if (this.documents[category]) {
        const doc = this.documents[category] as DocumentationReview;
        doc.type = category;
        doc.id = this.documents[category].id;
        docArray.push(doc);
      }
    }

    if (this.employeeLicenses) {
      const employeeLicense = {
        ...this.employeeLicenses,
        type: 'employee_licenses',
        id: null,
        reviewed_by: this.employeeLicenses[0].reviewed_by,
        name: 'Employee Licenses',
        file_names: this.employeeLicenses.reduce((acc, item) => acc.concat([item.file_name]), []),
        status: this.employeeLicenses[0].status,
      };
      docArray.push(employeeLicense);
    }
    if (this.businessLicenses) {
      const businessLicense = {
        ...this.businessLicenses,
        type: 'business_licenses',
        id: null,
        name: 'Business Licenses',
        reviewed_by: this.businessLicenses[0].reviewed_by,
        file_names: this.businessLicenses.reduce((acc, item) => acc.concat([item.file_name]), []),
        status: this.businessLicenses[0].status,
      };
      docArray.push(businessLicense);
    }
    return docArray;
  }

  handleFilterToggle(event) {
    event.stopPropagation();
    this.filterToggled = !this.filterToggled;
  }

  handleRowClick(row) {
    if (row.type === 'employee_licenses' || row.type === 'business_licenses') {
      this.openLicenseEmitter.emit(row);
    } else {
      this.openDocumentEmitter.emit(row);
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  filterDocuments() {
    if (
      this.filter.financial ||
      this.filter.legal ||
      this.filter.operational ||
      this.filter.other ||
      this.filter.licenses
    ) {
      this.filteredDocuments = this.transformedDocs.filter(doc => {
        if (this.filter.financial && doc.type.toLowerCase() === 'financial') {
          return true;
        }
        if (this.filter.legal && doc.type.toLowerCase() === 'legal') {
          return true;
        }
        if (this.filter.operational && doc.type.toLowerCase() === 'operational') {
          return true;
        }
        if (this.filter.other && doc.type.toLowerCase() === 'other') {
          return true;
        }
        if (
          this.filter.licenses &&
          (doc.type.toLowerCase() === 'employee_licenses' || doc.type.toLowerCase() === 'business_licenses')
        ) {
          return true;
        }
        return false;
      });
    } else {
      this.filteredDocuments = this.transformedDocs.slice();
    }
    this.sortDocuments(this.sort);
  }

  sortDocuments(sort) {
    if (sort) {
      this.sort = sort;
    }
    const data = this.filteredDocuments.slice();
    if (!this.sort.active || this.sort.direction === '') {
      this.sortedDocuments = data;
      return;
    }
    this.sortedDocuments = data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'requirement':
          return compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'reviewed':
          return compare(a.status, b.status, isAsc);
        case 'reviewed_by':
          return compare(a.reviewed_by, b.reviewed_by, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
