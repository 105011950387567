<div
  [attr.data-cy]="'support-card.component.expandableCard'"
  [ngClass]="expanded ? 'expanded' : 'closed'"
  class="expandableCard"
>
  <div
    (click)="toggleExpand()"
    [attr.data-cy]="'support-card.component.expandableCardTitle'"
    class="expandableCardTitle"
  >
    <h2 [attr.data-cy]="'support-card.component.cardTitle'">{{ cardTitle }}</h2>
    <svg
      *ngIf="!expanded"
      [attr.data-cy]="'support-card.component.expandBtn'"
      height="8"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 .3c.4-.4 1.2-.4 1.6 0 .5.4.5 1 0 1.5L7.5 8 .4 1.8A1 1 0 0 1 .4.3C.8 0 1.6 0 2 .3L7.5 5 12.9.3z"
        fill="#A5A8BA"
        fill-rule="nonzero"
      />
    </svg>
    <svg
      *ngIf="expanded"
      [attr.data-cy]="'support-card.component.expandBtn'"
      height="8"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 .3c.4-.4 1.2-.4 1.6 0 .5.4.5 1 0 1.5L7.5 8 .4 1.8A1 1 0 0 1 .4.3C.8 0 1.6 0 2 .3L7.5 5 12.9.3z"
        fill="#A5A8BA"
        fill-rule="nonzero"
      />
    </svg>
  </div>
  <div [attr.data-cy]="'support-card.component.expandableContent'" class="expandableContent">
    <span *ngIf="!expanded"> {{ cardBody | truncate: 0:120 }} </span>
    <span *ngIf="expanded"> {{ cardBody }} </span>
  </div>
</div>
