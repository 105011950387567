import React, { useEffect, useState } from 'react';
import { useApi } from '../../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { GcvDataTable, GcvZeroState } from '../../../lib';
import { v4 as uuid } from 'uuid';
import { DateTime } from 'luxon';
import { LoadingContainer } from './styles';
import { $primary } from '../../../util/styleVariables';
import { formatMoney } from '../../../util/formatHelpers';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Oval } from 'svg-loaders-react';
import { api } from 'libs/react-ui/src/api';
import { Dispensary } from '@gcv/shared';

interface Props {
  crbId: string;
  startDate: string;
  endDate: string;
  dispensaries: { [k: string]: Dispensary };
}

export const DailySummariesCrb: React.FC<Props> = props => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const apiClient = api();

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const tableRows = [];
  const tableColumns = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      grow: 1,
      format: row => DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_MED),
    },
    {
      name: 'Verified',
      selector: 'verified_percent',
      sortable: true,
      grow: 1,
      format: row => `${Math.floor(row.verified_percent) || 0}%`,
    },
    {
      name: 'Total Sales',
      selector: 'total_sales',
      sortable: true,
      grow: 1,
      format: row => formatMoney(row.total_sales),
    },
    {
      name: 'Cash Total',
      selector: 'total_cash',
      sortable: true,
      grow: 1,
      format: row => formatMoney(row.total_cash),
    },
    {
      name: 'Debit Total',
      selector: 'total_debit',
      sortable: true,
      grow: 1,
      format: row => formatMoney(row.total_debit),
    },
  ];

  useEffect(() => {
    tableRows.length = 0;

    const startDay = DateTime.fromISO(props.startDate).toFormat('yyyy-MM-dd');
    const endDay = DateTime.fromISO(props.endDate).toFormat('yyyy-MM-dd');

    apiClient.dispensaries
      .getDailySummaries(props.crbId, startDay, endDay, () => {})
      .then(r => {
        r.forEach(o => {
          tableRows.push({
            id: uuid(),
            dispensary_name: o.dispensary_name,
            date: o.date,
            verified_percent: o.verified_percent,
            total_sales: o.total_sales,
            total_cash: o.total_cash,
            total_debit: o.total_debit,
          });
        });
      })
      .catch(e => {
        console.error('Failed to retrieve daily summary', e.message);
      })
      .then(() => {
        setTableData(tableRows);
        setLoading(false);
      });
  }, [props.startDate, props.endDate, props.crbId]);

  const handleRowClick = rowData => {
    const { date } = rowData;
    const timezone = Object.values(props.dispensaries).find(d => d.id === props.crbId).iana_timezone;
    history.push(`${url}/detail/${props.crbId}/${date}`, { timezone });
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Oval height={75} width={75} stroke={$primary} viewBox="0 0 38 38" />
      </LoadingContainer>
    );
  }

  if (!loading && (!tableData || !tableData.length)) {
    return (
      <GcvZeroState
        type="bankDash"
        headerText="There is no sales data available for this time period"
        subText="Try changing the time period for more results"
      />
    );
  }

  return (
    <GcvDataTable
      data={tableData}
      columns={tableColumns}
      defaultSortField={'date'}
      defaultSortAsc={false}
      keyField="id"
      subHeaderAlign="left"
      paginationPerPage={8}
      onRowClicked={handleRowClick}
    />
  );
};
