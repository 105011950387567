import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const fincenClient = (API: APIClass) => {
  return {
    fincenReportCtrPostHandler: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/fincen-reports/ctr`, body, setIsLoading);
    },

    fincenReportSarPostHandler: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/fincen-reports/sar`, body, setIsLoading);
    },

    fincenReportByIdPutHandler: async (
      reportId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/fincen-reports/${reportId}`, body, setIsLoading);
    },

    fincenReportByIdTrackingPutHandler: async (
      bankId: string,
      reportId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).put(`/fincen-reports/${bankId}/reports/${reportId}/tracking`, body, setIsLoading);
    },

    fincenReportDeleteById: async (reportId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).delete(`/fincen-reports/${reportId}`, setIsLoading);
    },

    fincenReportByBankIdGetHandler: async (bankId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/fincen-reports/${bankId}`, setIsLoading);
    },

    fincenReportSarPeriodByBankIdGetHandler: async (bankId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/fincen-reports/sar-periods/${bankId}`, setIsLoading);
    },

    fincenReportByBankIdAndDispensaryIdGetHandler: async (
      bankId: string,
      dispensaryId: string,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).get(`/fincen-reports/${bankId}/${dispensaryId}`, setIsLoading);
    },

    exportSummary: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/fincen-reports/export-summary`, body, setIsLoading);
    },

    getFincenExportRecords: async (bankId: string, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/fincen-xml-exports/${bankId}`, setIsLoading);
    },

    updateFincenExportReports: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/fincen-xml-exports/${bankId}/bulk-update`, body, setIsLoading);
    },

    updateFincenExportTracking: async (
      bankId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/fincen-xml-exports/${bankId}/tracking`, body, setIsLoading);
    },

    // XML /////////////////////////////////////////////////////

    fincenReportByIdXmlPostHandler: async (
      reportId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/xml/fincen-reports/${reportId}`, body, setIsLoading);
    },

    fincenReportXmlPostHandler: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/fincen-reports/xml`, body, setIsLoading);
    },
  };
};
