import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { TransitionEffects } from './styles';
import { RouteObject } from '../../util/types';
import { Accounts } from './Accounts-View.page';
import { AccountInvitePage } from './Account-Invite.page';
import { ViewAccount } from './Account-View.page';
import { ReviewAccount } from './Account-Review-Overview.page';
import { ReviewAccountSection } from './Account-Review-Section.page';
import { RequirementView } from './Account-Requirement.page';

export const routes: RouteObject[] = [
  {
    path: '/secure/bank/accounts',
    render: props => <Accounts {...props}></Accounts>,
  },
  {
    path: '/secure/bank/account/invite',
    render: props => <AccountInvitePage {...props} />,
  },
  {
    path: '/secure/bank/account/:dispensary_id/view/:section',
    render: props => <ViewAccount {...props}></ViewAccount>,
  },
  {
    path: '/secure/bank/account/:dispensary_id/review/overview',
    render: props => <ReviewAccount {...props} />,
  },
  {
    path: '/secure/bank/account/:dispensary_id/review/:section',
    render: props => <ReviewAccountSection {...props} />,
  },
  {
    path: '/secure/bank/account/:dispensary_id/requirement/:req_id',
    render: props => <RequirementView {...props} />,
  },
];
