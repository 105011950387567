import { Card, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';

// Header
interface HeaderProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  actions?: React.ReactNode;
  style?: React.CSSProperties;
}

const Header: React.FC<HeaderProps> = props => (
  <CardHeader
    title={props.title}
    disableTypography={true}
    subheader={props.subtitle}
    action={props.actions}
    style={{
      borderBottom: '1px solid #DBE1ED',
      marginBottom: '1rem',
      padding: '0.5rem',
      fontFamily: 'Lato, Helvetica, Arial, san-serif',
      fontSize: '16px',
      fontWeight: 'bold',
      ...props.style,
    }}
    {...props}
  />
);

// Body

interface BodyProps {
  style?: any;
}

const Body: React.FC<BodyProps> = props => (
  <CardContent style={{ padding: '0px', ...props.style }}>{props.children}</CardContent>
);

// Card

interface CardProps {
  style?: any;
  height?: number;
}

export const GcvCard: React.FC<CardProps> & {
  Body: React.FC<BodyProps>;
  Header: React.FC<HeaderProps>;
} = props => {
  return (
    <Card
      style={{
        background: '#ffffff',
        border: '1px solid #dbe0eb',
        borderRadius: '7px',
        boxSizing: 'border-box',
        boxShadow: 'none',
        padding: '1.5rem',
        overflow: 'visible',
        height: props.height ? `${props.height}px` : 'auto',
        ...props.style,
      }}
      {...props}
    >
      {props.children}
    </Card>
  );
};

GcvCard.Header = Header;
GcvCard.Body = Body;
