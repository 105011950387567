import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Bank } from '@gcv/shared';
import {
  genericAlphabeticalAPatternValidator,
  requiredEinAndTinValidators,
  requiredGenericAlphanumericDPatternValidator,
  requiredOrganizationNamePatternValidator,
  requiredPhoneNumberValidator,
  requiredZipCodePatternValidator,
} from '../validators/commonValidators';
import * as _ from 'lodash';

export const createOrgDetailsFormGroup = (bank: Bank) => {
  return new FormGroup({
    ein: new FormControl(bank.ein ? bank.ein : '', requiredEinAndTinValidators),
    regulatorContactOffice: new FormControl(bank.regulatorContactOffice ? bank.regulatorContactOffice : '', [
      Validators.required,
      Validators.pattern("^[a-zA-Z 0-9.'-]{3,30}$"),
    ]),
    primaryRegulatorPhone: new FormControl(
      bank.primaryRegulatorPhone ? bank.primaryRegulatorPhone : '',
      requiredPhoneNumberValidator
    ),
    transmitter_control_code: new FormControl(
      bank.transmitter_control_code ? bank.transmitter_control_code : '',
      Validators.required
    ),
  });
};

export const createBankAddressFormGroup = (bank: Bank) => {
  return new FormGroup({
    address: new FormControl(bank.address ? bank.address : '', requiredGenericAlphanumericDPatternValidator),
    city: new FormControl(bank.city ? bank.city : '', [Validators.required, Validators.pattern("[a-zA-z .'-]*")]),
    postalCode: new FormControl(bank.postalCode ? bank.postalCode : '', requiredZipCodePatternValidator),
    country: new FormControl('unitedstates'),
    state: new FormControl({ value: bank.state ? bank.state : '', disabled: true }, Validators.required),
  }) as FormGroup;
};

export const createOrganizationFormGroup = (bank: Bank) => {
  return new FormGroup({
    orgName: new FormControl(
      { value: bank.orgName ? bank.orgName : '', disabled: true },
      requiredOrganizationNamePatternValidator
    ),
    certificateOrCharterNumber: new FormControl(
      bank.certificateOrCharterNumber ? bank.certificateOrCharterNumber : '',
      Validators.required
    ),
    primaryReg: new FormControl(bank.primaryReg ? bank.primaryReg : '', Validators.required),
  }) as FormGroup;
};

export const createBankContactFormGroup = (org: Bank) => {
  return new FormGroup({
    website: new FormControl(org && org.website ? org.website : ''),
    phoneNumber: new FormControl(org && org.phoneNumber ? org.phoneNumber : '', requiredPhoneNumberValidator),
  });
};

export const createCannabisProgramContactFormGroup = (org: Bank) => {
  return new FormGroup({
    firstName: new FormControl(
      _.get(org, 'primary_point_of_contact.firstName', ''),
      genericAlphabeticalAPatternValidator
    ),
    lastName: new FormControl(
      _.get(org, 'primary_point_of_contact.lastName', ''),
      genericAlphabeticalAPatternValidator
    ),
    title: new FormControl(_.get(org, 'primary_point_of_contact.title', ''), genericAlphabeticalAPatternValidator),
    emailAddress: new FormControl(_.get(org, 'primary_point_of_contact.emailAddress', ''), Validators.email),
    phoneNumber: new FormControl(_.get(org, 'primary_point_of_contact.phoneNumber', ''), requiredPhoneNumberValidator),
  });
};

export const createBankDescriptionFormGroup = (org: Bank) => {
  return new FormGroup({
    description: new FormControl(org && org.description ? org.description : '', Validators.required),
  });
};
