import React from 'react';

import { CoreTransactionPaymentType, DispensaryAccountReviewWithData } from '@gcv/shared';
import { GcvChart } from './GcvChart';
import { RadarConfig } from './chart-options';

interface Props {
  review: DispensaryAccountReviewWithData;
}

export const TransactionDataRadarChart: React.FC<Props> = props => {
  return <GcvChart type="radar" config={chartDataAdapter(props.review)} />;
};

export const chartDataAdapter = (review: DispensaryAccountReviewWithData) => {
  const coreData = review.data.aggregated_core_transaction_data;

  return transactionDataRadarAdapter({
    current_period: {
      ...Object.keys(coreData.current_period.deposit).reduce((acc, curr) => {
        const nextValue = coreData.current_period.deposit[curr].a / 100;

        if (!nextValue) {
          return acc;
        }

        return { ...acc, [curr + '_in']: nextValue };
      }, {}),
      ...Object.keys(coreData.current_period.withdrawal).reduce((acc, curr) => {
        const nextValue = coreData.current_period.withdrawal[curr].a / 100;

        if (!nextValue) {
          return acc;
        }

        return { ...acc, [curr + '_out']: nextValue };
      }, {}),
    },
    previous_period: {
      ...Object.keys(coreData.previous_period.deposit).reduce((acc, curr) => {
        const nextValue = coreData.previous_period.deposit[curr].a / 100;

        if (!nextValue) {
          return acc;
        }

        return { ...acc, [curr + '_in']: nextValue };
      }, {}),
      ...Object.keys(coreData.previous_period.withdrawal).reduce((acc, curr) => {
        const nextValue = coreData.previous_period.withdrawal[curr].a / 100;

        if (!nextValue) {
          return acc;
        }

        return { ...acc, [curr + '_out']: nextValue };
      }, {}),
    },
  });
};

type TransactionRadarDataType = {
  current_period: {
    [key: string]: number;
  };

  previous_period: {
    [key: string]: number;
  };
};

function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatCategoryName(data: { [key: string]: number }) {
  const category = [];

  Object.keys(data).map(key => {
    if (!data[key]) {
      return;
    }

    const splitKey = key.split('_');

    const name: string[] = [];

    for (let x = 0; x < splitKey.length; x++) {
      name.push(capitalize(splitKey[x]));
    }

    category.push(name.join(' '));
  });

  return category.map(c => {
    return { label: c };
  });
}

function getCategories(data: TransactionRadarDataType) {
  const currentPeriod = formatCategoryName(data.current_period);
  const previousPeriod = formatCategoryName(data.previous_period);

  const categories: { label: string }[] = [];

  currentPeriod.forEach(cp => {
    if (!categories.find(c => c.label === cp.label)) {
      categories.push(cp);
    }
  });

  previousPeriod.forEach(pp => {
    if (!categories.find(c => c.label === pp.label)) {
      categories.push(pp);
    }
  });

  return categories;
}

export const transactionDataRadarAdapter = (data: TransactionRadarDataType): RadarConfig => {
  const dataSet = [];
  if (Object.values(data.current_period).length) {
    dataSet.push({
      seriesname: 'This Period',
      color: '#00BC66',
      anchorBgColor: '#00BC66',
      data: Object.values(data.current_period).map(cp => {
        return { value: cp };
      }),
    });
  }
  if (Object.values(data.previous_period).length) {
    dataSet.push({
      seriesname: 'Previous Period',
      color: '#6896FB',
      anchorBgColor: '#6896FB',
      data: Object.values(data.previous_period).map(pp => {
        return { value: pp };
      }),
    });
  }
  return {
    categories: [
      {
        category: getCategories(data),
      },
    ],
    dataset: dataSet.length ? dataSet : null,
  };
};
