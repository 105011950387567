import React from 'react';

import { DropdownComponent } from './DropdownComponent';
import { FilterContainer, Option, FilterLabel, VerticalCenter } from './styles';

interface FilterDropDownProps {
  label?: string;
  options: Option[];
  onChange: (option: Option) => void;
  dropWidth?: string;
  dropCharLength?: number;
  defaultValueDrop?: string;
  selectableLabel?: boolean;
  labelOptions?: Option[];
  onLabelChange?: (option: Option) => void;
  labelWidth?: string;
  labelCharLength?: number;
  defaultValueLabel?: string;
  buttonStyleOverride?: any;
  align?: string;
}

interface Option {
  value: string;
  label: string;
}

export const GcvInputSelectNew: React.FC<FilterDropDownProps> = props => {
  const hasLabel = props.selectableLabel || props.label;
  return (
    <FilterContainer style={{ justifyContent: props.align }}>
      {hasLabel ? (
        <div data-cy="gcv-input-select-label">
          {props.selectableLabel ? (
            <DropdownComponent
              onChange={props.onLabelChange}
              options={props.labelOptions}
              minWidth={props.labelWidth}
              buttonStyle={{
                borderRadius: '4px 0 0 4px',
                borderRight: 0,
                backgroundColor: '#f2f4fa',
                color: 'rgb(130, 133, 153)',
                fontWeight: 600,
              }}
              selectedCharLength={props.labelCharLength}
              defaultValue={props.defaultValueLabel}
            ></DropdownComponent>
          ) : (
            <FilterLabel style={{ width: props.labelWidth }}>
              <VerticalCenter style={{ height: '100%' }}>
                <div>{props.label}</div>
              </VerticalCenter>
            </FilterLabel>
          )}
        </div>
      ) : null}

      <div data-cy="gcv-input-select-dropdown">
        <DropdownComponent
          onChange={props.onChange}
          options={props.options}
          minWidth={props.dropWidth}
          buttonStyle={{ ...props.buttonStyleOverride, borderRadius: '0 4px 4px 0', borderLeft: hasLabel ? 0 : '' }}
          selectedCharLength={props.dropCharLength}
          defaultValue={props.defaultValueDrop}
        ></DropdownComponent>
      </div>
    </FilterContainer>
  );
};
