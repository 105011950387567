<div [attr.data-cy]="'user-group-card.component.' + group.name" class="group">
  <h3 data-cy="user-group-card.component.groupName">{{ group.name }}</h3>
  <div
    *ngIf="group.users.length > 0"
    [ngStyle]="{
      'width.px': group.users.length * 37 - 10 * group.users.length
    }"
    class="userWrapper"
    data-cy="user-group-card.component.userWrapper"
  >
    <div
      *ngFor="let user of (group.users | slice: 0:5); let i = index"
      [ngStyle]="{
        position: 'relative',
        'z-index': i + 1,
        'right.px': i * 10
      }"
      data-cy="user-group-card.component.user"
    >
      <gcv-initial-logo
        *ngIf="userMap[user]"
        [firstName]="userMap[user].firstName"
        [lastName]="userMap[user].lastName"
        borderColor="white"
        data-cy="user-group-card.component.userName"
        hover="true"
      >
      </gcv-initial-logo>
    </div>
    <gcv-initial-logo
      *ngIf="group.users.length > 5"
      [userMap]="userMap"
      [users]="group.users"
      data-cy="user-group-card.component.additionalUsers"
      hover="true"
      type="additionalUsers"
    >
    </gcv-initial-logo>
  </div>
  <div *ngIf="group.users.length === 0" class="emptyGroupText" data-cy="user-group-card.component.emptyGroup">
    <span>There are no members in this group</span>
  </div>
  <gcv-button
    (clickEvent)="handleViewGroup()"
    buttonText="View Group"
    data-cy="user-group-card.component.viewGroupButton"
    type="pill secondary"
  ></gcv-button>
</div>
