<div class="wrapper" data-cy="user-wrapper.component">
  <div
    [ngClass]="{
      'tab-0': selectedIndex === 0,
      'tab-1': selectedIndex === 1,
      'tab-2': selectedIndex === 2
    }"
    class="headerWrapper"
    data-cy="user-wrapper.component.headerWrapper"
  >
    <div class="nameContainer title " data-cy="user-wrapper.component.nameContainer">
      <h1>Users</h1>
    </div>
    <div class="tabs">
      <gcv-tab-group
        (handleTabChanged)="tabChanged($event)"
        [tabActive]="selectedIndex"
        [tabs]="tabs"
        data-cy="user-wrapper.component.app-tab-group"
      ></gcv-tab-group>
    </div>
    <div *ngIf="selectedIndex === 0" class="btnWrapper buttons">
      <gcv-button
        (clickEvent)="handleCreateGroup()"
        [disabled]="selectedUsers.length === 0"
        buttonText="Combine Into Group"
        data-cy="user-wrapper.component.combineIntoGroupButton"
        type="primary"
      ></gcv-button>
      <gcv-button
        (clickEvent)="handleArchiveUser()"
        [buttonText]="selectedUsers.length > 1 ? 'Archive Users' : 'Archive User'"
        [disabled]="selectedUsers.length === 0"
        data-cy="user-wrapper.component.archiveUsersButton"
        type="tertiary"
      ></gcv-button>
    </div>
    <div *ngIf="selectedIndex === 2" class="btnWrapper removeButton">
      <gcv-button
        (clickEvent)="handleRemoveClicked()"
        [disabled]="selectedUsers.length === 0"
        buttonText="Remove from Archives"
        data-cy="user-wrapper.component.removeFromArchivesButton"
        type="tertiary"
      ></gcv-button>
    </div>
    <div *ngIf="selectedIndex === 1" class="btnWrapper createButton">
      <gcv-button
        (clickEvent)="handleCreateGroup()"
        *ngIf="selectedIndex === 1"
        buttonText="Create Group"
        data-cy="user-wrapper.component.createGroup"
        type="primary"
      ></gcv-button>
    </div>
    <form *ngIf="selectedIndex === 0" class="filter-field filter">
      <mat-form-field class="full-width md-no-style">
        <mat-label class="full-width md-no-style">Filter users</mat-label>
        <input
          (input)="filterItems()"
          [(ngModel)]="filterText"
          class="full-width"
          data-cy="user-wrapper.component.inputFilter"
          matInput
          maxlength="100"
          name="filter"
          type="text"
        />
        <svg height="21.069" matSuffix viewBox="0 0 20.894 21.069" width="20.894" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.66,20.652,13.9,15.893a8.785,8.785,0,1,1,1.9-1.849l4.734,4.734a1.325,1.325,0,1,1-1.873,1.873ZM6.349,2.954A6.313,6.313,0,1,0,8.8,2.459a6.313,6.313,0,0,0-2.449.494Z"
            fill="#a5a8ba"
            transform="translate(-0.027 0.029)"
          />
        </svg>
      </mat-form-field>
    </form>
  </div>
  <div class="bodyWrapper">
    <div class="routerWrapperFullWidth tabContent" data-cy="user-wrapper.component.routerWrapper">
      <ng-content select="[routerOutlet]"></ng-content>
    </div>
  </div>
</div>
