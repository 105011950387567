import React from 'react';
import { Deposit, formatStringOrNumberToDollars } from '@gcv/shared';
import { DateTime } from 'luxon';
import { formatISOToDateAtTime } from '../../../util';
import { SubContent, SubTitle } from './styles';
import { GcvReadMore } from '../../../lib';

interface Props {
  deposit: Deposit;
  userMap: [];
  type: string;
}

export const DepositDetailDrawerReconciledForm: React.FC<Props> = props => {
  let formFields = [
    ['Method of Transportation', props.deposit.delMethod.name, 'mot'],
    ['Date Received', DateTime.fromISO(props.deposit.arrived_date).toLocaleString(DateTime.DATE_MED), 'date-received'],
    [
      'Date Posted',
      props.deposit.posted_date ? DateTime.fromISO(props.deposit.posted_date).toLocaleString(DateTime.DATE_MED) : '',
      'reconciled-date-posted',
    ],
    [
      'Internal Note',
      props.deposit.bank_comments ? props.deposit.bank_comments : 'No notes were made on this deposit',
      'internal-note',
    ],
    [
      'Reconciled By',
      props.userMap[props.deposit.accepted_by_user]
        ? `${props.userMap[props.deposit.accepted_by_user]['firstName']} ${
            props.userMap[props.deposit.accepted_by_user]['lastName']
          }`
        : '',
      'reconciled-by',
    ],
    ['Reconciled On', formatISOToDateAtTime(props.deposit.accepted_date), 'reconciled-on'],
  ];

  return (
    <SubContent>
      {formFields.map(([title, string, testDataTag]) => (
        <div key={title}>
          <SubTitle data-cy={`${testDataTag}-title`}>{title}</SubTitle>
          <GcvReadMore string={string} length={50} data-cy={testDataTag}></GcvReadMore>
        </div>
      ))}
    </SubContent>
  );
};
