<div class="wrapper" data-cy="requirements-list">
  <h3>What to have ready:</h3>
  <div *ngFor="let requirement of requirements" class="requirement-item">
    <svg fill="none" height="22" viewBox="0 0 26 26" width="21" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" fill="#02B45B" r="13" />
      <path
        d="M15.9 10.3l-4.3 4-1.4-1.4c-.5-.5-1.3-.5-1.8 0s-.5 1.3 0 1.8l1.7 1.7c.4.4.9.6 1.5.6.5 0 1-.2 1.4-.6l4.6-4.2c.5-.5.5-1.3.1-1.8-.5-.5-1.3-.5-1.8-.1"
        fill="#fff"
      />
    </svg>
    <span data-cy="requirement-name">
      {{ requirement.name }}
    </span>
  </div>
  <div class="footer" *ngIf="showNeedHelpLinks; else notShow">
    <h4>Need Help?</h4>
    <span (click)="handleHelpEvent()" id="helpItem" data-cy="help-link">{{ helpDescription }}</span>
  </div>
</div>

<ng-template #notShow>
  <div class="footer"></div>
</ng-template>
