import React, { useMemo, useEffect, useState } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import { Oval } from 'svg-loaders-react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { $primary } from '../../../util/styleVariables';
import { LoadingContainer } from './styles';
import { useApi } from '../../../hooks/useApi';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '../../../../../common-ui/src';
import { formatMoney } from '../../../util/formatHelpers';
import { GcvSearchDropDown, GcvTimePeriodDropdown, GcvZeroState, GcvDataTable } from '../../../lib';
import calculateTimeRange from '../../../util/calculateDateRange';
import { MinifiedDispensary, Bank } from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';

interface Props {
  bank: Bank;
  dispensaries: { [id: string]: MinifiedDispensary };
}

interface Option {
  value: string;
  label: string;
}

export const DailySummaries = (props: Props) => {
  const [summaries, setSummaries] = useState(null);
  const [filteredSummaries, setFilteredSummaries] = useState(null);
  const [timePeriod, setTimePeriod] = useState(calculateTimeRange('last30Days', props.bank.iana_timezone));
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [distinctAccounts, setDistinctAccounts] = useState<Option[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState<string[]>([]);
  const { url } = useRouteMatch();
  const history = useHistory();
  const apiClient = api();

  const columns = useMemo(
    () => [
      {
        name: 'Account',
        selector: 'dispensary_name',
        sortable: true,
        grow: 2,
        left: true,
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
        left: true,
        format: row => DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_MED),
      },
      {
        name: 'Verified',
        selector: 'verified_percent',
        sortable: true,
        left: true,
        format: row => `${Math.floor(row.verified_percent) || 0}%`,
      },
      {
        name: 'Total Sales',
        selector: 'total_sales',
        sortable: true,
        left: true,
        format: row => formatMoney(row.total_sales),
      },
      {
        name: 'Cash',
        selector: 'total_cash',
        sortable: true,
        left: true,
        format: row => formatMoney(row['total_cash']),
      },
      {
        name: 'Debit',
        selector: 'total_debit',
        sortable: true,
        left: true,
        format: row => formatMoney(row['total_debit']),
      },
      {
        name: 'Other',
        selector: 'total_other',
        sortable: true,
        left: true,
        format: row => formatMoney(row['total_sales'] - row['total_cash'] - row['total_debit']),
      },
    ],
    []
  );

  const fetchDailySummaries = async () => {
    try {
      const startDay = DateTime.fromISO(timePeriod.start).toFormat('yyyy-MM-dd');
      const endDay = DateTime.fromISO(timePeriod.end).toFormat('yyyy-MM-dd');
      const summaries = await apiClient.banks.getDailySummaryTable(props.bank.id, startDay, endDay, () => {});

      setSummaries(summaries);

      if (filteredAccounts.length > 0) {
        const filteredSummaries = summaries.filter(s => filteredAccounts.includes(s.dispensary_id));
        setFilteredSummaries(filteredSummaries);
      } else {
        setFilteredSummaries(summaries);
      }
    } catch (e) {
      setSnackBarMessage('Something went wrong. Please try again shortly.');
      setSnackbarOpen(true);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDailySummaries();
  }, [timePeriod]);

  useEffect(() => {
    if (summaries) {
      const filteredSummaries = summaries.filter(s => filteredAccounts.includes(s.dispensary_id));
      setFilteredSummaries(filteredSummaries);
    }
  }, [filteredAccounts]);

  useEffect(() => {
    if (props.dispensaries) {
      const dispensaryOptions = Object.values(props.dispensaries).map((d: any) => {
        return { value: d.id, label: d.name };
      });
      setDistinctAccounts(dispensaryOptions);
    }
  }, [props.dispensaries]);

  const handleFilterAccounts = results => {
    setFilteredAccounts(results);
  };

  const handleTimeChange = results => {
    const timeRange = calculateTimeRange(results.value, props.bank.iana_timezone);
    setLoading(true);
    setTimePeriod(timeRange);
  };

  const handleRowClick = rowData => {
    const { date, dispensary_id } = rowData;
    history.push(`${url}/detail/${dispensary_id}/${date}`, { timezone: props.bank.iana_timezone });
  };

  const snackBarAction = (
    <Button color="inherit" size="small" onClick={() => setSnackbarOpen(false)}>
      Dismiss
    </Button>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        action={snackBarAction}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackBarMessage}
        autoHideDuration={6000}
      />
      <div style={{ marginBottom: '1rem', display: 'flex' }}>
        <div style={{ marginRight: '1rem' }}>
          <GcvSearchDropDown
            distinctItems={distinctAccounts}
            searchText={'Search Accounts'}
            itemLabel={'Account(s)'}
            filterReport={handleFilterAccounts}
          />
        </div>
        <GcvTimePeriodDropdown
          emitData={handleTimeChange}
          labelText={'Time Period'}
          newStyle={true}
          defaultValueDrop={'last30Days'}
        ></GcvTimePeriodDropdown>
      </div>
      {loading ? (
        <LoadingContainer>
          <Oval height={75} width={75} stroke={$primary} viewBox="0 0 38 38" />
        </LoadingContainer>
      ) : (
        <GcvDataTable
          data={filteredSummaries}
          columns={columns}
          onRowClicked={handleRowClick}
          keyField="react_key"
          defaultSortField="date"
          defaultSortAsc={false}
          noDataComponent={
            <GcvZeroState
              type="bankDash"
              headerText="There is no sales data for the selected accounts in this time period"
              subText="Please try changing your filters to show more records"
            />
          }
        />
      )}
    </>
  );
};
