import React from 'react';
import { Deposit } from '@gcv/shared';

import { GcvInputTextArea, dateFormatterToISO, GcvCheckbox, GcvInputForm, GcvInputSelect } from '../../lib';
import { Spacer, SubTitle } from './styles';
import { DateTime } from 'luxon';

interface Props {
  type: string;
  dispensaryMap?: [];
  deposit: Deposit;
  toggleDatePostedIsDateReceived: () => void;
  getFormattedMethodsOfTransportation: (methods: any) => any[];
  form: any;
  datePostedIsDateReceived: boolean;
  register: any;
}

export const DepositDetailDrawerDepositForm: React.FC<Props> = props => {
  const formDateRules = {
    required: true,
    validate: {
      future: value => dateFormatterToISO(value) < DateTime.local().toISO() || "can't input a date in the future",
    },
    minLength: 10,
  };

  if (props.type === 'bank') {
    const methodsOfTransportation = props.dispensaryMap[props.deposit.dispensary_id].methodOfTransportation;
    const methodsOfTransportationFormatted = props.getFormattedMethodsOfTransportation(methodsOfTransportation);

    return (
      <>
        <SubTitle style={{ fontWeight: 'bold', fontSize: '14px', margin: '20px 0px 40px 0px' }}>
          To reconcile this deposit, please confirm the details below:
        </SubTitle>

        <Spacer>
          <GcvInputSelect
            {...props.form}
            options={methodsOfTransportationFormatted}
            label={'Method of Transportation'}
            name={'deliveryMethod'}
            rules={{ required: true }}
            testDataTag="deposit-mot-select"
          />
        </Spacer>

        <Spacer>
          <GcvInputForm
            {...props.form}
            name={'dateReceived'}
            rules={formDateRules}
            label={'Date Received (mm/dd/yyyy)'}
            type="date"
            testDataTag="deposit-date-received"
          />
        </Spacer>

        <Spacer>
          <label>
            <GcvCheckbox
              onChange={props.toggleDatePostedIsDateReceived}
              checked={props.datePostedIsDateReceived}
              data-cy="posted-date-checkbox"
            />
            <SubTitle style={{ display: 'inline-block', marginLeft: '10px', marginTop: '0px' }}>
              Date posted is the same as date received
            </SubTitle>
          </label>
        </Spacer>

        {!props.datePostedIsDateReceived ? (
          <Spacer>
            <GcvInputForm
              {...props.form}
              name={'datePosted'}
              rules={formDateRules}
              label={'Date Posted (mm/dd/yyyy)'}
              type="date"
              testDataTag="date-posted"
            />
          </Spacer>
        ) : null}

        <GcvInputTextArea
          name={'additionalNotes'}
          label={'Add any notes you have about the deposit (optional)'}
          register={props.register}
          {...props.form}
          testDataTag="additional-notes"
        ></GcvInputTextArea>
      </>
    );
  } else {
    return <></>;
  }
};
