import styled, { createGlobalStyle } from 'styled-components';

interface Props {
  current: boolean;
}

export const TabContainer = styled.div`
  height: 30rem;
`;

export const Tab = styled.div`
  background: #ffffff;
  border: 1px solid #ebeef7;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 45px;
  width: 60%;
  margin: 0 auto;

  h1 {
    margin-bottom: 30px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

export const Label = styled.label`
  margin: 5px 0px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;

  input {
    margin 0px 10px;
  }
`;

export const FormContent = styled.div`
  margin: 5px 0px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
