import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { formatMoney, formatNotAvailable } from '../../../util';
import { DateTime } from 'luxon';
import { GcvDataTable } from '../../../lib/GcvDataTable/GcvDataTable';
import { Container, CardBody, HeaderContainer } from './styles';
import { useAsync } from '../../../hooks/useAsync';
import { getUtcDateTimeRangeForDay } from '../../../util/dateTimeHelpers';
import { SalesSummaryCard, DataTableContainer, CardHeader, CardHeaderTotal, LineItem } from './styles';
import { GreenCheckStatusIcon } from '../../../icons/GreenCheckStatusIcon';
import { YellowWarningIcon } from '../../../icons/YellowWarningIcon';
import { BackButtonIcon } from '../../../icons/BackButtonIcon';
import { RedXIcon } from '../../../icons/RedXIcon';
import { PurchaseRecordDrawer } from '../PurchaseRecordDrawer/PurchaseRecordDrawer';
import { api } from 'libs/react-ui/src/api';

interface RouteParams {
  date?: string;
  dispensaryId?: string;
}

interface Props {
  backClickLink: string;
}

export const DailySummariesDetailView = ({ backClickLink }: Props) => {
  const [salesData, setSalesData] = useState([]);
  const [summary, setSummary] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const apiClient = api();
  const [selectedPurchaseRecord, setSelectedPurchaseRecord] = useState(null);
  const routeParams: RouteParams = useParams();
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        name: 'Purchase Date',
        selector: 'receipt_date',
        sortable: true,
        center: true,
        format: row => DateTime.fromISO(row.receipt_date).toLocaleString(DateTime.DATE_MED),
      },
      {
        name: 'Total Amount',
        selector: 'total_paid',
        sortable: true,
        center: true,
        format: row => formatNotAvailable(formatMoney(row.total_paid)),
      },
      {
        name: 'Payment Type',
        selector: 'payment_type',
        sortable: true,
        center: true,
        format: row =>
          row.payment_type
            ? row.payment_type.toLowerCase() === 'ach'
              ? 'ACH'
              : `${row.payment_type.charAt(0).toUpperCase()}${row.payment_type.slice(1)}`
            : formatNotAvailable(row.payment_type),
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
        center: true,
        format: row => {
          switch (row.status) {
            case 'green_checked':
              return <GreenCheckStatusIcon />;
            case 'warning':
              return <YellowWarningIcon />;
            case 'unchecked':
              return 'Unchecked';
            case 'red_flag':
              return <RedXIcon />;
            default:
              return 'Unknown';
          }
        },
      },
    ],
    []
  );

  const calculateDateRange = date => {
    const { start, end } = getUtcDateTimeRangeForDay(
      date,
      history.location.state ? history.location.state['timezone'] : Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    return { startAt: start, endAt: end };
  };

  const fetchSalesData = async () => {
    try {
      const { startAt, endAt } = calculateDateRange(routeParams.date);

      const data = await apiClient.dispensaries.getAllDispensarySales(
        routeParams.dispensaryId,
        startAt,
        endAt,
        () => {}
      );

      setSalesData(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchDailySummary = async () => {
    return await apiClient.dispensaries.getDailySummaries(
      routeParams.dispensaryId,
      routeParams.date,
      routeParams.date,
      () => {}
    );
  };

  const dailySummaryRequest = useAsync(fetchDailySummary);

  useEffect(() => {
    fetchSalesData();
    dailySummaryRequest.makeCall();
  }, []);

  useEffect(() => {
    if (dailySummaryRequest.result) {
      setSummary(dailySummaryRequest.result[0]);
    }
  }, [dailySummaryRequest.result]);

  const onPurchaseRecordSelected = data => {
    setSelectedPurchaseRecord(data);
  };

  const removeSelectedPurchaseRecord = () => {
    setSelectedPurchaseRecord(null);
  };

  const onBackClick = () => {
    history.push(backClickLink);
  };

  return (
    <>
      <HeaderContainer>
        <BackButtonIcon onClick={onBackClick} /> <h4 data-cy="title">{summary ? summary.dispensary_name : ''}</h4>{' '}
        <span>Daily Summary</span>
      </HeaderContainer>
      <div style={{ marginLeft: '2.8rem', fontSize: 14, color: '#5A5E6B' }} data-cy="date">
        {DateTime.fromISO(routeParams.date).toLocaleString()}
      </div>
      <Container>
        {summary ? (
          <SalesSummaryCard>
            <CardHeader>
              <h4>Sales Summary</h4>
              <CardHeaderTotal data-cy="total">
                {formatMoney(summary.total_sales)} <span>Total</span>
              </CardHeaderTotal>
            </CardHeader>

            <CardBody>
              <LineItem bottomBorder>
                Verified{' '}
                <div className="amount" data-cy="verified-total">
                  {formatMoney(summary.verified_total)} (
                  {summary.verified_percent < 0.1 && summary.verified_percent > 0
                    ? '< 0.1'
                    : summary.verified_percent.toFixed(1)}
                  %)
                </div>
              </LineItem>
              <LineItem indented bottomBorder>
                Cash{' '}
                <div className="amount" data-cy="verified-cash">
                  {formatMoney(summary.verified_cash)}
                </div>
              </LineItem>
              <LineItem
                indented
                bottomBorder={
                  summary.verified_credit || summary.verified_check || summary.verified_ach || summary.verified_wire
                }
              >
                Debit{' '}
                <div className="amount" data-cy="verified-debit">
                  {formatMoney(summary.verified_debit)}
                </div>
              </LineItem>
              {summary.verified_credit ? (
                <LineItem
                  indented
                  bottomBorder={summary.verified_check || summary.verified_ach || summary.verified_wire}
                >
                  Credit <div className="amount">{formatMoney(summary.verified_credit)}</div>
                </LineItem>
              ) : null}
              {summary.verified_check ? (
                <LineItem indented bottomBorder={summary.verified_ach || summary.verified_wire}>
                  Check <div className="amount">{formatMoney(summary.verified_check)}</div>
                </LineItem>
              ) : null}
              {summary.verified_ach ? (
                <LineItem indented bottomBorder={summary.verified_wire}>
                  ACH <div className="amount">{formatMoney(summary.verified_ach)}</div>
                </LineItem>
              ) : null}
              {summary.verified_wire ? (
                <LineItem indented>
                  Wire <div className="amount">{formatMoney(summary.verified_wire)}</div>
                </LineItem>
              ) : null}
              {summary.uncheckable_total != 0 ? (
                <>
                  <LineItem style={{ marginTop: '1rem' }} bottomBorder>
                    Unchecked
                    <div className="amount" data-cy="uncheckable-total">
                      {formatMoney(summary.uncheckable_total)} (
                      {summary.uncheckable_percent < 0.1 && summary.uncheckable_percent > 0
                        ? '< 0.1'
                        : summary.uncheckable_percent.toFixed(1)}
                      %)
                    </div>
                  </LineItem>
                  <LineItem indented bottomBorder>
                    Cash{' '}
                    <div className="amount" data-cy="uncheckable-cash">
                      {formatMoney(summary.uncheckable_cash)}
                    </div>
                  </LineItem>
                  <LineItem
                    indented
                    bottomBorder={
                      summary.uncheckable_credit ||
                      summary.uncheckable_check ||
                      summary.uncheckable_ach ||
                      summary.uncheckable_wire
                    }
                  >
                    Debit{' '}
                    <div className="amount" data-cy="uncheckable-debit">
                      {formatMoney(summary.uncheckable_debit)}
                    </div>
                  </LineItem>
                  {summary.uncheckable_credit ? (
                    <LineItem
                      indented
                      bottomBorder={summary.uncheckable_check || summary.uncheckable_ach || summary.uncheckable_wire}
                    >
                      Credit <div className="amount">{formatMoney(summary.uncheckable_credit)}</div>
                    </LineItem>
                  ) : null}
                  {summary.uncheckable_check ? (
                    <LineItem indented bottomBorder={summary.uncheckable_ach || summary.uncheckable_wire}>
                      Check <div className="amount">{formatMoney(summary.uncheckable_check)}</div>
                    </LineItem>
                  ) : null}
                  {summary.uncheckable_ach ? (
                    <LineItem indented bottomBorder={summary.uncheckable_wire}>
                      ACH <div className="amount">{formatMoney(summary.uncheckable_ach)}</div>
                    </LineItem>
                  ) : null}
                  {summary.uncheckable_wire ? (
                    <LineItem indented>
                      Wire <div className="amount">{formatMoney(summary.uncheckable_wire)}</div>
                    </LineItem>
                  ) : null}
                </>
              ) : null}

              <LineItem style={{ marginTop: '1rem' }} bottomBorder>
                Unverified
                <div className="amount" data-cy="unverified-total">
                  {formatMoney(summary.unverified_total)} (
                  {summary.unverified_percent < 0.1 && summary.unverified_percent > 0
                    ? '< 0.1'
                    : summary.unverified_percent.toFixed(1)}
                  %)
                </div>
              </LineItem>
              <LineItem indented bottomBorder>
                Cash{' '}
                <div className="amount" data-cy="unverified-cash">
                  {formatMoney(summary.unverified_cash)}
                </div>
              </LineItem>
              <LineItem
                indented
                bottomBorder={
                  summary.unverified_credit ||
                  summary.unverified_check ||
                  summary.unverified_ach ||
                  summary.unverified_wire
                }
              >
                Debit{' '}
                <div className="amount" data-cy="unverified-debit">
                  {formatMoney(summary.unverified_debit)}
                </div>
              </LineItem>
              {summary.unverified_credit ? (
                <LineItem
                  indented
                  bottomBorder={summary.unverified_check || summary.unverified_ach || summary.unverified_wire}
                >
                  Credit <div className="amount">{formatMoney(summary.unverified_credit)}</div>
                </LineItem>
              ) : null}
              {summary.unverified_check ? (
                <LineItem indented bottomBorder={summary.unverified_ach || summary.unverified_wire}>
                  Check <div className="amount">{formatMoney(summary.unverified_check)}</div>
                </LineItem>
              ) : null}
              {summary.unverified_ach ? (
                <LineItem indented bottomBorder={summary.unverified_wire}>
                  ACH <div className="amount">{formatMoney(summary.unverified_ach)}</div>
                </LineItem>
              ) : null}
              {summary.unverified_wire ? (
                <LineItem indented>
                  Wire <div className="amount">{formatMoney(summary.unverified_wire)}</div>
                </LineItem>
              ) : null}
            </CardBody>
          </SalesSummaryCard>
        ) : (
          <SalesSummaryCard />
        )}

        <DataTableContainer>
          <GcvDataTable
            data={salesData}
            columns={columns}
            onRowClicked={onPurchaseRecordSelected}
            progressPending={isFetching}
          />
        </DataTableContainer>
      </Container>

      <PurchaseRecordDrawer
        open={selectedPurchaseRecord !== null}
        data={selectedPurchaseRecord}
        onClose={removeSelectedPurchaseRecord}
      />
    </>
  );
};
