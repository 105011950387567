import {
  Dispensary,
  MinifiedDispensary,
  DueDiligenceDocumentStatus,
  DueDiligenceTemplate,
  LicenseManager,
  PosType,
  User,
} from '@gcv/shared';

export const getDispensaryType = (posName: PosType): 'retail' | 'non_retail' => {
  if (
    posName === 'Quickbooks' ||
    posName === 'GcvCsv_Quickbooks' ||
    posName === 'MjFreewayWholesale' ||
    posName === 'BioTrackSqlWholesale' ||
    posName === 'LeafLogixWholesale'
  ) {
    return 'non_retail';
  } else {
    return 'retail';
  }
};

//use this when we have a full dispensary
export const getDispensaryLicenseType = (dispensary: Dispensary) => {
  if (
    dispensary.due_diligence &&
    dispensary.due_diligence.requirements &&
    dispensary.due_diligence.requirements.business_licenses &&
    dispensary.due_diligence.requirements.business_licenses.documents &&
    Object.values(dispensary.due_diligence.requirements.business_licenses.documents).length > 0
  ) {
    const docs = Object.values(dispensary.due_diligence.requirements.business_licenses.documents);
    const docTypes = [];
    let hasActiveLicense = false;

    docs.forEach(d => {
      if (d.status !== DueDiligenceDocumentStatus.ARCHIVED) {
        hasActiveLicense = true;

        if (!docTypes.includes(d.license_name)) {
          docTypes.push(d.license_name);
        }
      }
    });

    if (!hasActiveLicense) {
      return '--';
    } else if (docTypes.length > 1) {
      return 'Multiple';
    } else {
      const activeDoc = docs.find(d => d.status !== DueDiligenceDocumentStatus.ARCHIVED);
      return getDispensaryLicenseName(activeDoc?.license_name, dispensary.state);
    }
  } else {
    return '--';
  }
};

//use this call when you have a minified dispensary
export const getMinifiedDispensaryLicenseType = (dispensary: MinifiedDispensary) => {
  if (dispensary?.license_summary?.has_active_license) {
    const docTypes = [];

    dispensary.license_summary.non_archived_docs.forEach(d => {
      if (!docTypes.includes(d.name)) {
        docTypes.push(d.name);
      }
    });

    if (docTypes.length > 1) {
      return 'Multiple';
    } else {
      return dispensary.license_summary.non_archived_docs[0].name;
    }
  } else {
    return '--';
  }
};

export const getDispensaryLicenseName = (licenseName: string, dispensaryState: string): string => {
  const licensesByState = LicenseManager.getLicenseData(dispensaryState);
  const licenseMap = licensesByState.business_license_types;
  const license = licenseMap.find(license => license.value === licenseName)?.viewValue ?? licenseName;
  return license;
};

export const getDispensaryMotUsers = (
  dispensary: Dispensary,
  dispensaryUsers: User[]
): {
  id: string;
  name: string;
  favorite: boolean;
  active: boolean;
}[] => {
  return dispensary.methodOfTransportation.user.reduce((filteredUsers, val) => {
    const motUser = dispensaryUsers.find(user => user.id === val.id);
    if (motUser && motUser.invitation_status !== 'archived' && val.active) {
      val.name = `${motUser.firstName} ${motUser.lastName}`;
      filteredUsers.push(val);
    }
    return filteredUsers;
  }, []);
};

//Check for existence of custom_fields AND for keys in fields object to handle empty objects
export const doesBankHaveCustomFields = (bankTemplate: DueDiligenceTemplate): boolean => {
  return bankTemplate?.custom_fields && Object.keys(bankTemplate.custom_fields.fields).length > 0;
};
