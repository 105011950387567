import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Dispensary, User } from '@gcv/shared';
import {
  genericAlphabeticalAPatternValidator,
  middleInitialPatternValidator,
  requiredAddressPatternValidator,
  requiredCitiesPatternValidator,
  requiredEinAndTinValidators,
  requiredIdTypePatternValidator,
  requiredPhoneNumberValidator,
  requiredZipCodePatternValidator,
} from '../validators/commonValidators';
import { CustomValidators } from 'ngx-custom-validators';
import { formatBirthday } from './date.util';

export const createGeneralFormGroup = (dispensary: Dispensary) => {
  return new FormGroup({
    name: new FormControl(dispensary && dispensary.name ? dispensary.name : '', Validators.required),
    entityType: new FormControl(dispensary && dispensary.entityType ? dispensary.entityType : '', Validators.required),
    establishedDate: new FormControl(
      dispensary && dispensary.establishedDate ? dispensary.establishedDate : '',
      Validators.required
    ),
    ein: new FormControl(dispensary && dispensary.ein ? dispensary.ein : '', requiredEinAndTinValidators),
  });
};

export const createDispensaryAddressFormGroup = (dispensary: Dispensary) => {
  return new FormGroup({
    streetAddress: new FormControl(
      dispensary && dispensary.streetAddress ? dispensary.streetAddress : '',
      requiredAddressPatternValidator
    ),
    city: new FormControl(dispensary && dispensary.city ? dispensary.city : '', requiredCitiesPatternValidator),
    state: new FormControl(dispensary && dispensary.state ? dispensary.state : '', Validators.required),
    country: new FormControl(
      dispensary && dispensary.country ? dispensary.country : 'unitedstates',
      Validators.required
    ),
    postalCode: new FormControl(
      dispensary && dispensary.postalCode ? dispensary.postalCode : '',
      requiredZipCodePatternValidator
    ),
    iana_timezone: new FormControl(
      dispensary && dispensary.iana_timezone
        ? dispensary.iana_timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone,
      Validators.required
    ),
  });
};

export const createFinancialFormGroup = (dispensary: Dispensary) => {
  return new FormGroup({
    monthlySales: new FormControl(
      dispensary && dispensary.monthlySales ? dispensary.monthlySales : '',
      Validators.required
    ),
    monthlyCustomers: new FormControl(
      dispensary && dispensary.monthlyCustomers ? dispensary.monthlyCustomers : '',
      Validators.required
    ),
    pos_name: new FormControl(dispensary && dispensary.pos_name ? dispensary.pos_name : '', Validators.required),
    business_type: new FormControl(
      dispensary && dispensary.business_type ? dispensary.business_type : '',
      Validators.required
    ),
  }) as FormGroup;
};

export const createEmployeesFormGroup = (dispensary: Dispensary) => {
  return new FormGroup({
    ftEmployees: new FormControl(
      dispensary && dispensary.ftEmployees ? dispensary.ftEmployees : '',
      Validators.required
    ),
    ptEmployees: new FormControl(
      dispensary && dispensary.ptEmployees ? dispensary.ptEmployees : '',
      Validators.required
    ),
  });
};

export const createIdPersonalFormGroup = (user: User, locale) => {
  let birthday: any = '';
  if (user.dateOfBirth) {
    birthday = formatBirthday(user.dateOfBirth, locale);
  }
  return new FormGroup({
    firstName: new FormControl(user.firstName ? user.firstName : '', [genericAlphabeticalAPatternValidator]),
    minitial: new FormControl(user.minitial ? user.minitial : '', middleInitialPatternValidator),
    lastName: new FormControl(user.lastName ? user.lastName : '', genericAlphabeticalAPatternValidator),
    email: new FormControl(user.email ? user.email : '', Validators.email),
    phone: new FormControl(user.phone ? user.phone : '', requiredPhoneNumberValidator),
    dateOfBirth: new FormControl(user.dateOfBirth ? birthday : '', [
      CustomValidators.maxDate('2001-09-09'),
      Validators.required,
    ]),
  });
};

export const createIdAddressFormGroup = (user: User) => {
  return new FormGroup({
    address: new FormControl(user.address ? user.address : '', requiredAddressPatternValidator),
    city: new FormControl(user.city ? user.city : '', requiredCitiesPatternValidator),
    state: new FormControl(user.state ? user.state : '', Validators.required),
    zipcode: new FormControl(user.zipcode ? user.zipcode : '', requiredZipCodePatternValidator),
    country: new FormControl(user.country ? user.country : 'unitedstates', Validators.required),
  });
};

export const createIdVerificationFormGroup = user => {
  return new FormGroup({
    idType: new FormControl(
      user.identification && user.identification.idType ? user.identification.idType : '',
      requiredIdTypePatternValidator
    ),
    idNumber: new FormControl(
      user.identification && user.identification.idNumber ? user.identification.idNumber : '',
      Validators.required
    ),
    idFront: new FormGroup(
      {
        name: new FormControl(`idFront-${user.id}`),
        filename: new FormControl(user.identification ? user.identification.idFront.filename : '', Validators.required),
        document_id: new FormControl(
          (user.identification && user.identification.idFront && user.identification.idFront.document_id) || ''
        ),
        status: new FormControl('review'),
        data: new FormControl(''),
      },
      Validators.required
    ),
    idBack: new FormGroup(
      {
        name: new FormControl(`idBack-${user.id}`),
        filename: new FormControl(user.identification ? user.identification.idBack.filename : '', Validators.required),
        document_id: new FormControl(
          (user.identification && user.identification.idBack && user.identification.idBack.document_id) || ''
        ),
        status: new FormControl('review'),
        data: new FormControl(''),
      },
      Validators.required
    ),
  });
};

export const createDispensaryContactFormGroup = (org: Dispensary) => {
  return new FormGroup({
    website: new FormControl(org && org.website ? org.website : ''),
    phone: new FormControl(org && org.phone ? org.phone : '', requiredPhoneNumberValidator),
  });
};
