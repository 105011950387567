import { DispensaryAccountReview, IANATimezones, ReviewPeriodType } from '@gcv/shared';
import { DateTime } from 'luxon';

export const doesReviewPeriodSpanMultipleMonths = (
  review: DispensaryAccountReview,
  timezone: IANATimezones
): boolean => {
  const startMonth = DateTime.fromISO(review.review_period_start_date, { zone: timezone }).month;
  const endMonth = DateTime.fromISO(review.review_period_end_date, { zone: timezone }).month;
  return (
    review.review_period_type === ReviewPeriodType.Quarterly ||
    review.review_period_type === ReviewPeriodType.Yearly ||
    (review.review_period_type === ReviewPeriodType.Custom && startMonth !== endMonth)
  );
};

export const getReviewPeriodFriendlyName = (review: DispensaryAccountReview): string => {
  return review.review_period_type === ReviewPeriodType.Custom
    ? 'Review Period'
    : review.review_period_type + ' Review';
};

export const getMonthsInTimeRange = (
  review: DispensaryAccountReview,
  timezone: IANATimezones
): { label: string; value: string }[] => {
  const startDate = DateTime.fromISO(review.review_period_start_date, { zone: timezone }).startOf('month');
  const endDate = DateTime.fromISO(review.review_period_end_date, { zone: timezone });
  const options = [];
  let rollingDate = startDate;

  while (rollingDate <= endDate) {
    options.push({
      label: rollingDate.monthLong,
      value: rollingDate.month,
    });
    rollingDate = rollingDate.plus({ months: 1 });
  }
  return options;
};
