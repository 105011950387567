import styled from 'styled-components';
import { $primary, $grey3, $grey2 } from '../../util/styleVariables';

interface PlaceHolderProps {
  tiny: boolean;
  focus: boolean;
}

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const TextArea = styled.textarea`
  flex-grow: 1;
  font-size: 16px;
  margin-top: 20px;
  border: 1px solid ${$grey3};
  background-color: white;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  width: 100%;
  height: 100px;

  &: focus {
    outline: none;
    border: 1px solid ${$primary};
  }
`;
export const InputContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const Warning = styled.div`
  font-size: 10px;
  padding: 2px 0px;
  color: red;
  height: 14px;
`;

export const PlaceHolder = styled.div<PlaceHolderProps>`
  font-size: ${props => (props.tiny ? '13px' : '16px')};
  margin-top: ${props => (props.tiny ? '0px' : '20px')};
  padding: ${props => (props.tiny ? '0px' : '5px')};
  transition-property: margin-top, font-size, padding;
  transition-duration: 0.5s;
  position: absolute;
  color: ${props => (props.focus ? $primary : $grey2)};
  pointer-events: none;
`;
