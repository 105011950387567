<div class="content" data-cy="user-group-archive.component">
  <div class="cardContainer">
    <div class="userTableWrapper col-8 large-12">
      <gcv-data-table
        (itemSelected)="itemSelected($event)"
        [columnNames]="displayedColumns"
        [dataMap]="dataMap"
        [data]="data"
        [selectedItems]="selectedItems"
        data-cy="user-group-archive.component.app-data-table"
      >
      </gcv-data-table>
    </div>

    <div class="userCreateWrapper col-4 large-12">
      <div class="titleWrapper">
        <h4>Archived Groups</h4>
        <span>
          Unlike users, groups cannot be unarchived and instead are simply listed for history purposes.
        </span>
      </div>
      <div class="archivedWrapper">
        <div *ngFor="let group of archivedGroups" data-cy="user-group-archive.component.groups">
          <span>{{ group.name }}</span>
          <span>{{ group.archive_date }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
