import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

interface Props {
  selectedIndex: number;
  handleListItemClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
}

const useStyles = makeStyles(() => ({
  ul: {
    overflow: 'auto',
    height: '80%',
  },
}));

export const ReportList: React.FC<Props> = props => {
  const { selectedIndex, handleListItemClick } = props;
  const classes = useStyles();
  return (
    <div style={{ height: '100%' }}>
      <h3>Report Type</h3>

      <List className={classes.ul}>
        <ListItem button disableRipple selected={selectedIndex === 0} onClick={e => handleListItemClick(e, 0)}>
          <ListItemText
            primary="Account Summary"
            secondary="Export a detailed summary of your accounts."
            primaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            secondaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            data-cy="account-summary"
          />
        </ListItem>
        <ListItem button disableRipple selected={selectedIndex === 1} onClick={e => handleListItemClick(e, 1)}>
          <ListItemText
            primary="Daily Summaries"
            secondary="Export a summary of sales totals by day over a period of time."
            primaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            secondaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            data-cy="daily-summaries"
          />
        </ListItem>
        <ListItem button disableRipple selected={selectedIndex === 2} onClick={e => handleListItemClick(e, 2)}>
          <ListItemText
            primary="Deposits"
            secondary="Export a detailed summary of every deposit over a period of time."
            primaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            secondaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            data-cy="deposits"
          />
        </ListItem>
        <ListItem button disableRipple selected={selectedIndex === 3} onClick={e => handleListItemClick(e, 3)}>
          <ListItemText
            primary="FinCEN CTR Summary"
            secondary="Export a detailed summary of every Currency Transaction Report over a period of time."
            primaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            secondaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            data-cy="ctr-summary"
          />
        </ListItem>
        <ListItem button disableRipple selected={selectedIndex === 4} onClick={e => handleListItemClick(e, 4)}>
          <ListItemText
            primary="FinCEN SAR Summary"
            secondary="Export a detailed summary of every Suspicious Activity Report over a period of time."
            primaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            secondaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            data-cy="sar-summary"
          />
        </ListItem>
        <ListItem button disableRipple selected={selectedIndex === 5} onClick={e => handleListItemClick(e, 5)}>
          <ListItemText
            primary="Raw Sales Data"
            secondary="Export raw sales data as it was received from the POS or invoice tracking system over a period of time."
            primaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            secondaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            data-cy="raw-sales-data"
          />
        </ListItem>
        <ListItem button disableRipple selected={selectedIndex === 6} onClick={e => handleListItemClick(e, 6)}>
          <ListItemText
            primary="Sales Activity"
            secondary="Export a detailed summary of every sale over a period of time."
            primaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            secondaryTypographyProps={{ style: { fontFamily: 'Lato, Helvetica, Arial, san-serif' } }}
            data-cy="sales-activity"
          />
        </ListItem>
      </List>
    </div>
  );
};
