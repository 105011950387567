import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { GcvLoading, GcvZeroState, GcvUserInputContainer } from '../../../lib';
import { formatISOToDateAtTime } from '../../../util/';

import {
  ActivityContainer,
  ActivityFeedContainer,
  Title,
  TitleContainer,
  Date,
  Document,
  Comment,
  Activities,
} from './styles';

import { useApi, getData } from '../../../hooks/';
import { Activity } from '@gcv/shared/dist/types/';

const eventTypes = {
  BankUserUploadedFile: 'uploaded a file',
  BankReviewedFile: 'reviewed a file',
  BankArchivedFile: 'archived a file',
  BankRestoredFile: 'restored a file',
  BankUserAddedCommentOnRequirement: 'added a comment on the requirement',
  BankUserAddedInternalCommentOnRequirement: 'added an internal comment on the requirement',
  BankUserRequestedFile: 'requested an upload',
  BankUserApprovedRequirement: 'approved the requirement',
  CrbUserUploadedFile: 'uploaded a file',
  CrbUserAddedCommentOnRequirement: 'added a comment on the requirement',
  CrbUserSetRequirementDoNotHave: 'added a comment on the requirement',
};

interface ActivityFeedProps {
  openFile: (type) => void;
  dispensaryId: string;
  reqId: string;
  reloadData: boolean;
}

export const ActivityFeed = ({ openFile, dispensaryId, reqId, reloadData }: ActivityFeedProps) => {
  const { API } = useApi();
  const [activities, setActivities] = useState<Activity[]>(null);

  const headerText = 'No Activites Recorded';

  const formatActivities = (activities: Activity[]) => {
    const sortedActivities = _.orderBy(activities, ({ created_date }) => created_date, 'desc');
    setActivities(sortedActivities);
  };

  useEffect(() => {
    getData.getActivities(API, formatActivities, dispensaryId, reqId);
  }, [dispensaryId, reqId, reloadData]);

  if (!activities) {
    return <GcvLoading />;
  }

  const renderActivityData = (activity: Activity) => {
    if (activity.document_id) {
      const handleOpenFile = () => openFile(activity.document_id);
      return (
        <Document onClick={handleOpenFile} data-cy="activity-feed-document">
          <GcvUserInputContainer string={activity.document_name} length={50} type="document" />
        </Document>
      );
    } else if (activity.comment) {
      return <Comment data-cy="activity-feed-comment">"{activity.comment}"</Comment>;
    } else {
      return null;
    }
  };

  const areActivitiesEmpty = activities.length < 1;

  return (
    <ActivityFeedContainer style={areActivitiesEmpty ? { overflowY: 'hidden' } : {}} data-cy="activity-feed-table">
      <Title>Activity</Title>

      <Activities>
        {areActivitiesEmpty ? (
          <GcvZeroState type="scroll" headerText={headerText} />
        ) : (
          activities.map((activity: Activity) => {
            return (
              <ActivityContainer key={activity.created_date} data-cy="activity-feed-entry">
                <TitleContainer data-cy="activity-feed-title">
                  <div>
                    <b>{activity.uploader_name}</b>
                    {` ${eventTypes[activity.type]}`}
                  </div>
                  <Date>{formatISOToDateAtTime(activity.created_date)}</Date>
                </TitleContainer>
                {renderActivityData(activity)}
              </ActivityContainer>
            );
          })
        )}
      </Activities>
    </ActivityFeedContainer>
  );
};
