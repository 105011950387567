import { FormControl, FormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditGroupAddUserDialogComponent } from './edit-group-add-user-dialog/edit-group-add-user-dialog.component';
import { Role } from '@gcv/shared';

@Component({
  selector: 'gcv-edit-group-wrapper',
  templateUrl: './edit-group-wrapper.component.html',
  styleUrls: ['./edit-group-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGroupWrapperComponent implements OnInit, OnDestroy, OnChanges {
  filterText = '';
  filteredUsers = [];
  users = [];
  userMap = {};
  @Input() group: any;

  @Input() allUsers: any;
  @Input() orgType = '';
  @Input() buttonDisabled: boolean;

  displayedColumns: string[] = ['firstName', 'lastName', 'invitation_status'];
  @Output() saveClicked = new EventEmitter();
  @Output() backClicked = new EventEmitter();
  roles: { value: Role; viewValue: string }[];
  roleGroup: FormGroup;
  dataMap = {
    firstName: 'First Name',
    lastName: 'Last Name',
    invitation_status: 'Status',
  };
  editingTitle = false;
  editTitle: FormGroup;
  roleForm: FormControl;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    if (this.orgType === 'dispensary') {
      this.roles = [
        { value: 'dispensary_account_owner', viewValue: 'Account Owner' },
        { value: 'dispensary_admin', viewValue: 'Admin' },
        { value: 'dispensary_user', viewValue: 'Standard User' },
        { value: 'dispensary_deposit_recorder', viewValue: 'Deposit Recorder' },
      ];
    } else if (this.orgType === 'bank') {
      this.roles = [
        { value: 'bank_primary_contact', viewValue: 'Account Owner' },
        { value: 'bank_admin', viewValue: 'Admin' },
        { value: 'bank_fincen_preparer', viewValue: 'Fincen Preparer' },
        { value: 'bank_fincen_approver', viewValue: 'Fincen Approver' },
        { value: 'bank_user', viewValue: 'Standard User' },
        { value: 'bank_document_preparer', viewValue: 'Document Preparer' },
        { value: 'bank_document_approver', viewValue: 'Document Approver' },
        { value: 'bank_deposit_approver', viewValue: 'Deposit Approver' },
        { value: 'bank_account_review_preparer', viewValue: 'Account Review Preparer' },
        { value: 'bank_account_review_approver', viewValue: 'Account Review Approver' },
        { value: 'bank_examiner', viewValue: 'Auditor / Examiner' },
      ];
    }

    this.setUpUsers();
  }

  setUpUsers() {
    if (this.allUsers) {
      this.allUsers.forEach(user => {
        this.userMap[user.id] = user;
      });
    }

    if (this.group) {
      this.filteredUsers = this.group.users
        .map(id => {
          if (this.userMap[id]) {
            return this.userMap[id];
          }
        })
        .filter(user => user && user.invitation_status !== 'archived'); // users disappear on refresh

      this.editTitle = new FormGroup({
        title: new FormControl(this.group.name),
      });
      this.roleForm = new FormControl(this.group.roles ? this.group.roles : []);
    }
  }

  ngOnDestroy() {
    this.saveClicked.emit(this.group);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allUsers && !changes.allUsers.firstChange) {
      this.setUpUsers();
    }

    if (changes.group && !changes.group.previousValue && changes.group.currentValue && !changes.group.firstChange) {
      this.filteredUsers = this.group.users
        .map(id => {
          if (this.userMap[id]) {
            return this.userMap[id];
          }
        })
        .filter(user => user.invitation_status !== 'archived');

      this.editTitle = new FormGroup({
        title: new FormControl(this.group.name),
      });
    }
  }

  chooseRole(e) {
    this.group.roles = e.value;
  }

  filterItems() {
    if (this.filterText.length === 0) {
      this.filteredUsers = this.group.users.map(id => this.userMap[id]);
    }
    this.filteredUsers = this.group.users
      .map(id => this.userMap[id])
      .filter(user => {
        const filterLower = this.filterText.toLowerCase();
        if (user.firstName.toLowerCase().includes(filterLower) || user.lastName.toLowerCase().includes(filterLower)) {
          return user;
        }
      });
  }

  handleBack() {
    this.backClicked.emit();
  }

  handleButtonClicked() {
    const dialog = this.dialog.open(EditGroupAddUserDialogComponent, {
      width: '660px',
      height: '700px',
      data: {
        allUsers: this.allUsers,
        group: this.group,
        userMap: this.userMap,
      },
    });
    dialog.afterClosed().subscribe(data => {
      if (data && data.group) {
        this.group = data.group;
        this.filteredUsers = this.group.users.map(id => this.userMap[id]);
        this.saveClicked.emit(this.group);
      }
    });
  }

  handleEditClicked(e) {
    this.editingTitle = true;
  }

  handleEditSaveClicked(e) {
    const newTitle = this.editTitle.controls.title.value;
    if (newTitle && newTitle.length > 1) {
      this.editingTitle = false;
      if (newTitle !== this.group.name) {
        this.group.name = newTitle;
        this.saveClicked.emit(this.group);
      }
    }
  }
}
