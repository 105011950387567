import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { Deposit, Dispensary } from '@gcv/shared';

import { BackButtonIcon } from '../../icons/BackButtonIcon';
import { formatMoney } from '../../util/formatHelpers';
import { formatISOToDateAtTime } from '../../util/dateTimeHelpers';

import {
  GcvDrawer,
  dateFormatterFromISO,
  dateFormatterToISO,
  GcvLoading,
  GcvReadMore,
  GcvStatusIndicator,
  GcvUserInputContainer,
} from '../../lib';

import { Header, SubTitle, HeaderContent, Block, Body, SubContent, DrawerContent } from './styles';
import { DepositDetailDrawerFooter } from './DepositDetailDrawerFooter';
import { DepositDetailDrawerDepositForm } from './DepositDetailDrawerDepositForm';
import { DepositDetailDrawerReconciledForm } from './DepositDetailDrawerReconciledForm';

interface Props {
  deposit: Deposit;
  bankId: string;
  type: 'bank' | 'dispensary';
  userMap: any;
  dispensary?: Dispensary;
  dispensaryMap?: any;
  emitData?: (deposit, bankId) => any;
  onClose: () => void;
  open: boolean;
}

export const DepositDetailDrawer: React.FC<Props> = props => {
  if (!props.open) {
    return <GcvDrawer open={false} onClose={props.onClose} />;
  }

  const dispensary = props.dispensary ? props.dispensary : props.dispensaryMap[props.deposit.dispensary_id];

  const [loadingReconciledDeposit, setLoadingReconciledDeposit] = useState(false);
  const [datePostedIsDateReceived, setDatePostedIsDateReceived] = useState(true);
  const [formComplete, setFormComplete] = useState<boolean>(false);

  const getFormattedMethodsOfTransportation = methods => {
    const formattedMethods = [];
    Object.keys(methods).forEach(key => {
      methods[key].forEach(val => {
        if (val.active) {
          const reactSelectOption = { label: val.name, value: val };
          formattedMethods.push(reactSelectOption);
        }
      });
    });
    return formattedMethods;
  };

  let initialdelivery_method: any;
  if (props.type === 'bank') {
    const methodsOfTransportation = dispensary.methodOfTransportation;
    const methodsOfTransportationFormatted = getFormattedMethodsOfTransportation(methodsOfTransportation);
    initialdelivery_method = methodsOfTransportationFormatted.find(val => val.value.id === props.deposit.delMethod.id);
  }

  const { register, handleSubmit, ...form } = useForm({
    defaultValues: {
      deliveryMethod: initialdelivery_method,
      dateReceived: dateFormatterFromISO(props.deposit.expected_arrival_date),
      datePosted: props.deposit.posted_date
        ? DateTime.fromISO(props.deposit.posted_date).toLocaleString(DateTime.DATE_MED)
        : '',
      additionalNotes: '',
    },
  });

  useEffect(() => {
    if (props.deposit.status === 'accepted') {
      setLoadingReconciledDeposit(false);
    }
  }, [props.deposit.status]);

  const { watch } = form;

  useEffect(() => {
    setFormComplete(
      watch('deliveryMethod') && watch('dateReceived') && ((datePostedIsDateReceived || watch('datePosted')) as boolean)
    );
  }, [watch('deliveryMethod'), watch('dateReceived'), watch('datePosted'), datePostedIsDateReceived]);

  const toggleDatePostedIsDateReceived = () => {
    setDatePostedIsDateReceived(!datePostedIsDateReceived);
  };

  const onReconcileSubmit = (formData: any) => {
    props.deposit.accepted_date = DateTime.local().toISO();
    props.deposit.anticipated_delivery_method = props.deposit.delMethod;

    props.deposit.delMethod = formData.deliveryMethod.value;
    props.deposit.bank_comments = formData.additionalNotes;
    props.deposit.arrived_date = dateFormatterToISO(formData.dateReceived);
    props.deposit.posted_date = dateFormatterToISO(
      datePostedIsDateReceived ? formData.dateReceived : formData.datePosted
    );

    setLoadingReconciledDeposit(true);
    props.emitData(props.deposit, props.bankId);
  };

  if (
    loadingReconciledDeposit ||
    (props.userMap && !Object.keys(props.userMap).length) ||
    (props.dispensaryMap && !Object.keys(props.dispensaryMap).length)
  ) {
    return (
      <GcvDrawer open={true} onClose={props.onClose} style={{ width: '482px' }}>
        <GcvLoading />
      </GcvDrawer>
    );
  }

  return (
    <GcvDrawer open={props.open} onClose={props.onClose} style={{ width: '482px' }}>
      <DrawerContent data-cy="deposit-detail-drawer">
        <Header>
          <div>
            <BackButtonIcon onClick={props.onClose} />
            <HeaderContent>
              Deposit Record
              <SubTitle style={{ marginTop: '0px' }}>
                <GcvUserInputContainer string={dispensary.name} length={36} data-cy="deposit-dispensary-name" />
              </SubTitle>
            </HeaderContent>
          </div>
          <div>
            <SubContent style={{ margin: '0px', fontWeight: 'bold' }}>
              {props.deposit.status === 'accepted' ? 'Reconciled' : null}
            </SubContent>
          </div>
        </Header>

        <Body>
          <Block>
            <SubTitle>
              {props.deposit.status === 'accepted' ? 'Deposit Amount Received' : 'Anticipated Deposit Amount'}
            </SubTitle>
            <SubContent
              style={{
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '27px',
              }}
            >
              <GcvUserInputContainer
                string={formatMoney(props.deposit.final_deposit)}
                length={36}
                data-cy="deposit-amount"
              />
            </SubContent>
          </Block>
          <Block
            style={props.type === 'dispensary' && props.deposit.status !== 'accepted' ? { borderBottom: 'none' } : {}}
          >
            {props.deposit.status === 'accepted' ? (
              <>
                <SubTitle>Anticipated Deposit Amount</SubTitle>
                <SubContent>{formatMoney(props.deposit.anticipated_deposit_amount)}</SubContent>
              </>
            ) : null}

            <SubTitle data-cy="deposit-date-title">
              {props.type === 'bank' ? 'Anticipated Receipt Date' : 'Planned Arrival Date'}
            </SubTitle>
            <SubContent data-cy="deposit-date">
              {DateTime.fromISO(props.deposit.expected_arrival_date).toLocaleString(DateTime.DATE_MED)}
            </SubContent>

            <SubTitle data-cy="deposit-mot-title">
              {props.type === 'bank' ? 'Anticipated Method of Transportation' : 'Planned Method of Transportation'}
            </SubTitle>
            <SubContent>
              <GcvUserInputContainer string={props.deposit.delMethod.name} length={36} data-cy="deposit-mot-name" />
            </SubContent>

            {props.type === 'dispensary' ? (
              <>
                <SubTitle>Created By</SubTitle>
                <SubContent>
                  <GcvUserInputContainer
                    string={
                      props.userMap[props.deposit.created_by]
                        ? props.userMap[props.deposit.created_by].firstName +
                          ' ' +
                          props.userMap[props.deposit.created_by].lastName
                        : ''
                    }
                    length={36}
                    data-cy="deposit-created-by-name"
                  ></GcvUserInputContainer>
                </SubContent>
              </>
            ) : null}

            <SubTitle>Max Deposit</SubTitle>
            <SubContent>
              <GcvUserInputContainer
                string={formatMoney(props.deposit.max_deposit)}
                length={36}
                data-cy="deposit-max-deposit"
              ></GcvUserInputContainer>
            </SubContent>

            <SubTitle data-cy="deposit-comment-title">
              {props.type === 'bank' ? 'Customer Comment' : 'Comment'}
            </SubTitle>
            <GcvReadMore
              string={props.deposit.comments ? props.deposit.comments : 'No comments were included with this deposit'}
              length={125}
              data-cy="comments"
            ></GcvReadMore>

            <SubTitle>Recorded On</SubTitle>
            <SubContent data-cy="deposit-header-date">{formatISOToDateAtTime(props.deposit.deposit_date)}</SubContent>

            <SubTitle>Deposit ID</SubTitle>
            <SubContent>
              <GcvUserInputContainer string={props.deposit.deposit_id} length={50} data-cy="deposit-id" />
            </SubContent>
          </Block>
          {props.deposit.status !== 'accepted' ? (
            <DepositDetailDrawerDepositForm
              dispensaryMap={props.dispensaryMap}
              datePostedIsDateReceived={datePostedIsDateReceived}
              deposit={props.deposit}
              form={form}
              getFormattedMethodsOfTransportation={getFormattedMethodsOfTransportation}
              register={register}
              toggleDatePostedIsDateReceived={toggleDatePostedIsDateReceived}
              type={props.type}
            />
          ) : (
            <DepositDetailDrawerReconciledForm deposit={props.deposit} type={props.type} userMap={props.userMap} />
          )}
        </Body>

        {props.deposit.status !== 'accepted' ? (
          <DepositDetailDrawerFooter
            type={props.type}
            formComplete={formComplete}
            handleSubmit={handleSubmit(onReconcileSubmit)}
          />
        ) : null}
      </DrawerContent>
    </GcvDrawer>
  );
};
