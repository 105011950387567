import React from 'react';
import { DateTime } from 'luxon';
import { GcvUserInputContainer } from '../../../lib/GcvUserInputContainer/GcvUserInputContainer';
import { $primary, $grey1 } from '../../../util/styleVariables';
import { formatUserFirstAndLastName } from '../../../util/formatHelpers';

export const uploadedOn = name => ({
  name: name,
  selector: 'uploadDate',
  sortable: true,
  grow: 1,
  format: row => (row.uploadDate ? DateTime.fromISO(row.uploadDate).toLocaleString(DateTime.DATE_SHORT) : ''),
});

export const fileName = (name, clickHandler) => ({
  name: name,
  selector: 'fileName',
  sortable: true,
  grow: 3,
  format: row => {
    const onClick = () => clickHandler(row);
    return (
      <div>
        <GcvUserInputContainer string={row.documentName} length={35} clickHandler={onClick} type="document" />
      </div>
    );
  },
});

export const reviewedBy = (name, clickHandler) => ({
  name: name,
  selector: 'reviewedBy',
  sortable: true,
  grow: 2,
  format: row => {
    const approved = row.status !== 'pending' && row.status !== 'submitted';
    const onClick = () => clickHandler(row);
    return (
      <GcvUserInputContainer
        string={approved ? row.reviewedBy : 'Needs Review'}
        length={35}
        style={{ color: approved ? $grey1 : $primary }}
        clickHandler={onClick}
      />
    );
  },
});
