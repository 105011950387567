import { APIClass } from 'aws-amplify';
import { apiClient } from './api-client';

export const quickbooksClient = (API: APIClass) => {
  return {
    getQuickbooksAuthUri: async (setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).get(`/quickbooks/authUri`, setIsLoading);
    },

    getQuickbooksAccessToken: async (
      orgId: string,
      body: Record<string, any>,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      return await apiClient(API).post(`/quickbooks/${orgId}/accessToken`, body, setIsLoading);
    },

    postQuickbooksWebhook: async (body: Record<string, any>, setIsLoading: (isLoading: boolean) => void) => {
      return await apiClient(API).post(`/quickbooks/webhook`, body, setIsLoading);
    },
  };
};
