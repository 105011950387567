import { Action } from '@ngrx/store';
import { Bank, Group } from '@gcv/shared';

export enum BankActionTypes {
  LoadBank = '[Bank] Load Bank',
  BankLoaded = '[Bank] Bank Loaded',

  LoadAllBanks = '[Bank] Load All Banks',
  AllBanksLoaded = '[Bank] All Banks Loaded',

  CreateBank = '[Bank] Create Bank',
  BankCreated = '[Bank] Bank Created',

  UpdateBank = '[Bank] Update Bank',
  BankUpdated = '[Bank] Bank Updated',

  LoadGroups = '[Bank] Load Groups',
  GroupsLoaded = '[Bank] Groups Loaded',

  UpdateGroups = '[Bank] Update Groups',
  GroupsUpdated = '[Bank] Groups Updated',

  LoadBankPartial = '[Bank] Load Bank Partial',
  BankPartialLoaded = '[Bank] Bank Partial Loaded',

  AddDispensaryToBank = '[Bank] Add Dispensary to Bank',
  DispensaryAddedToBank = '[Bank] Dispensary Added to Bank',

  RemoveDispensaryFromBank = '[Bank] Remove Dispensary from Bank',
  DispensaryRemovedFromBank = '[Bank] Dispensary Removed from Bank',

  UpdateBankGCVDueDiligenceStatus = '[Bank] Update Bank GCV Due Diligence Status',
  BankGCVDueDiligenceStatusUpdated = '[Bank] Bank GCV Due Diligence Status Updated',

  ResetBank = '[Bank] Reset Bank',

  UpdateBankOrganizationalDetails = '[Bank] Update Bank Organizational Details',
  UpdateBankCannabisProgram = '[Bank] Update Bank Cannabis Program',
  UpdateBankRegulatoryReporting = '[Bank] Update Bank Regulatory Reporting',
  UpdateBankDueDiligenceSectionComplete = '[Bank] Update Bank Due Diligence Section Complete',
  UpdateBankTransportationOptOut = '[Bank] Update Bank Transportation Opt Out',
  UpdateBankDueDiligenceUsersSection = '[Bank] Update Bank Due Diligence Users Section',

  InviteBankUser = '[Bank] Invite Bank User',

  UpsertBankDueDiligenceTemplateRequirement = '[Bank] Upsert Bank Due Diligence Template Requirement',
  DeleteBankDueDiligenceTemplateRequirement = '[Bank] Delete Bank Due Diligence Template Requirement',
}

export class AddDispensaryToBank implements Action {
  readonly type = BankActionTypes.AddDispensaryToBank;

  constructor(public payload: { dispensaryId: string; bankId: string }) {}
}

export class DispensaryAddedToBank implements Action {
  readonly type = BankActionTypes.DispensaryAddedToBank;

  constructor(public payload: Bank) {}
}

export class RemoveDispensaryFromBank implements Action {
  readonly type = BankActionTypes.RemoveDispensaryFromBank;

  constructor(public payload: { dispensaryId: string; bankId: string }) {}
}

export class DispensaryRemovedFromBank implements Action {
  readonly type = BankActionTypes.DispensaryRemovedFromBank;

  constructor(public payload: Bank) {}
}
export class LoadBank implements Action {
  readonly type = BankActionTypes.LoadBank;

  constructor(public payload: { bankId: string }) {}
}

export class BankLoaded implements Action {
  readonly type = BankActionTypes.BankLoaded;

  constructor(public payload: Bank) {}
}

export class LoadBankPartial implements Action {
  readonly type = BankActionTypes.LoadBankPartial;

  constructor(public payload: { dispensaryId: string }) {}
}

export class BankPartialLoaded implements Action {
  readonly type = BankActionTypes.BankPartialLoaded;

  constructor(public payload: Partial<Bank>) {}
}

export class CreateBank implements Action {
  readonly type = BankActionTypes.CreateBank;

  constructor(public payload: Partial<Bank>) {}
}

export class BankCreated implements Action {
  readonly type = BankActionTypes.BankCreated;

  constructor(public payload: Bank) {}
}

export class LoadAllBanks implements Action {
  readonly type = BankActionTypes.LoadAllBanks;
}

export class AllBanksLoaded implements Action {
  readonly type = BankActionTypes.AllBanksLoaded;

  constructor(public payload: Bank[]) {}
}

export class UpdateBank implements Action {
  readonly type = BankActionTypes.UpdateBank;

  constructor(public payload: Bank) {}
}

export class BankUpdated implements Action {
  readonly type = BankActionTypes.BankUpdated;

  constructor(public payload: Bank) {}
}

export class LoadGroups implements Action {
  readonly type = BankActionTypes.LoadGroups;

  constructor(public payload: { orgId: string }) {}
}

export class GroupsLoaded implements Action {
  readonly type = BankActionTypes.GroupsLoaded;

  constructor(public payload: Group) {}
}

export class UpdateGroups implements Action {
  readonly type = BankActionTypes.UpdateGroups;

  constructor(public payload: { bankId: string; groups: Group[] }) {}
}

export class GroupsUpdated implements Action {
  readonly type = BankActionTypes.GroupsUpdated;

  constructor(public payload: Bank) {}
}

export class UpdateBankGCVDueDiligenceStatus implements Action {
  readonly type = BankActionTypes.UpdateBankGCVDueDiligenceStatus;

  constructor(public payload: { bankId: string }) {}
}

export class BankGCVDueDiligenceStatusUpdated implements Action {
  readonly type = BankActionTypes.BankGCVDueDiligenceStatusUpdated;

  constructor(public payload: Bank) {}
}

export class UpdateBankOrganizationalDetails implements Action {
  readonly type = BankActionTypes.UpdateBankOrganizationalDetails;

  constructor(public payload: { bankId: string; organizationalDetails: any }) {}
}

export class UpdateBankCannabisProgram implements Action {
  readonly type = BankActionTypes.UpdateBankCannabisProgram;

  constructor(public payload: { bankId: string; cannabisProgram: any }) {}
}

export class UpdateBankDueDiligenceSectionComplete implements Action {
  readonly type = BankActionTypes.UpdateBankDueDiligenceSectionComplete;

  constructor(public payload: { bankId: string; due_diligence_section_complete: boolean }) {}
}

export class UpdateBankRegulatoryReporting implements Action {
  readonly type = BankActionTypes.UpdateBankRegulatoryReporting;

  constructor(public payload: { bankId: string; regulatoryReporting: any }) {}
}

export class UpdateBankTransportationOptOut implements Action {
  readonly type = BankActionTypes.UpdateBankTransportationOptOut;

  constructor(public payload: { bankId: string; transport_opt_out: boolean }) {}
}

export class UpdateBankDueDiligenceUsersSection implements Action {
  readonly type = BankActionTypes.UpdateBankDueDiligenceUsersSection;

  constructor(public payload: { bankId: string; users_opt_out: boolean; users_section_complete: boolean }) {}
}

export class InviteBankUser implements Action {
  readonly type = BankActionTypes.InviteBankUser;

  constructor(public payload: { bankId: string; user: any }) {}
}

export class UpsertBankDueDiligenceTemplateRequirement implements Action {
  readonly type = BankActionTypes.UpsertBankDueDiligenceTemplateRequirement;

  constructor(
    public payload: {
      bankId: string;
      requirement: any;
      category: string;
      attachments: { id: string; file_name: string }[];
    }
  ) {}
}

export class DeleteBankDueDiligenceTemplateRequirement implements Action {
  readonly type = BankActionTypes.DeleteBankDueDiligenceTemplateRequirement;

  constructor(public payload: { bankId: string; requirementId: string }) {}
}

export class ResetBank implements Action {
  readonly type = BankActionTypes.ResetBank;
}

export type BankActions =
  | LoadBank
  | BankLoaded
  | UpdateBank
  | BankUpdated
  | LoadGroups
  | GroupsLoaded
  | UpdateGroups
  | GroupsUpdated
  | ResetBank
  | AddDispensaryToBank
  | DispensaryAddedToBank
  | CreateBank
  | BankCreated
  | BankPartialLoaded
  | LoadBankPartial
  | LoadAllBanks
  | UpdateBankGCVDueDiligenceStatus
  | BankGCVDueDiligenceStatusUpdated
  | AllBanksLoaded
  | UpdateBankOrganizationalDetails
  | UpdateBankCannabisProgram
  | UpdateBankRegulatoryReporting
  | UpdateBankTransportationOptOut
  | UpdateBankDueDiligenceUsersSection
  | InviteBankUser
  | UpsertBankDueDiligenceTemplateRequirement
  | UpdateBankDueDiligenceSectionComplete
  | DeleteBankDueDiligenceTemplateRequirement;
