import React from 'react';
import { RouteObject } from '../../util/types';
import { Reports } from './Reports.page';

export const routes: RouteObject[] = [
  {
    path: '/secure/bank/reports',
    render: props => <Reports {...props}></Reports>,
  },
];
