<div class="wrapper">
  <div class="title">
    <h3>{{ document }}</h3>
    <p>
      {{ description }}
    </p>
  </div>
  <div [ngStyle]="{ padding: '1rem 0', textAlign: 'center' }" class="notice">
    <h3>
      There was nothing uploaded for this document type.
    </h3>
  </div>
  <div class="commentWrapper">
    <h3>
      Comments
    </h3>
    <p>
      {{ comment }}
    </p>
  </div>
  <div class="buttonWrapper">
    <gcv-button (clickEvent)="close()" buttonText="Cancel" type="tertiary"></gcv-button>
    <gcv-button
      (clickEvent)="approve()"
      *ngIf="!isDispensary && status === 'submitted'"
      buttonText="Approve"
      type="primary"
    ></gcv-button>
  </div>
</div>
