import React, { useEffect, useState } from 'react';
import { Dispensary, User } from '@gcv/shared';
import { BodySubTitle, ButtonIcon } from './styles';
import { GcvButton, GcvCard, GcvInputForm, GcvInputSelect, GcvLoading, GcvModal } from '../../../lib';
import { Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import { FormContent, ModalContent, ModalLabel } from '../styles';
import { useApi } from 'libs/react-ui/src/hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { states } from 'libs/gcv-ui/src/lib';
import { AddTransportationServiceModal } from 'libs/react-ui/src/shared/Transportation/AddTransportationService';
import { api } from 'libs/react-ui/src/api';
import { getDispensaryMotUsers, transformPhoneNumber } from 'libs/react-ui/src/util';

interface Props {
  dispensary?: Dispensary;
  emitData: (type: string, data) => void;
  dispensaryUsers: User[];
}

export const AccountTransportation = ({ dispensary, emitData, dispensaryUsers }: Props) => {
  const apiClient = api();

  const [motModalOpen, setMotModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [requireModalOpen, setRequireModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({
    id: '',
    type: '',
    name: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
  });

  if (!dispensary || !dispensaryUsers) {
    return <GcvLoading></GcvLoading>;
  }

  const motUsers = getDispensaryMotUsers(dispensary, dispensaryUsers);

  const toggleMotModal = async (vendorId?: string) => {
    if (vendorId) {
      let motType: string;
      const mot = dispensary.methodOfTransportation;

      Object.keys(mot).forEach(k => {
        Object.values(mot[k]).forEach(v => {
          if (v.id === vendorId) {
            motType = k;
          }
        });
      });

      await apiClient.dispensaries
        .getTransportVendorById(vendorId, () => {})
        .then(r => {
          setSelectedVendor({
            id: vendorId,
            type: motType,
            name: r.name,
            street: r.street,
            city: r.city,
            state: r.state,
            zipCode: r.zipCode,
            phone: r.phone,
          });
        });
    }

    setMotModalOpen(!motModalOpen);
  };

  const toggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };

  const toggleRequireModal = (vendorId?: string) => {
    if (vendorId) {
      setSelectedVendor({
        id: vendorId,
        type: '',
        name: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        phone: '',
      });
    }

    setRequireModalOpen(!requireModalOpen);
  };

  const addTransportationService = async payload => {
    await apiClient.banks
      .addTransportVendor(
        dispensary.bank_id,
        {
          city: payload.city,
          country: 'unitedstates',
          name: payload.name,
          phone: transformPhoneNumber(payload.phone),
          state: payload.state.value,
          street: payload.street,
          zipCode: payload.zipCode,
        },
        () => {}
      )
      .then(dispensary => {
        toggleAddModal();
        emitData('updateDispensary', dispensary);
      });
  };

  const requireTransportationService = async (vendorId: string) => {
    let motType: string;
    const mot = dispensary.methodOfTransportation;

    Object.keys(mot).forEach(k => {
      Object.values(mot[k]).forEach(v => {
        if (v.id === vendorId) {
          motType = k;
        }
      });
    });

    await apiClient.dispensaries
      .favoriteMethodOfTransport(dispensary.id, motType, vendorId, {}, () => {})
      .then(dispensary => {
        toggleRequireModal();
        emitData('updateDispensary', dispensary);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <GcvCard data-cy="deposit-transportation-users-card">
            <GcvCard.Header
              title={<strong data-cy="title">Deposit Transportation Users</strong>}
              subtitle={
                <p style={{ fontSize: '14px' }} data-cy="subtext">
                  These are users who belong to a group with the Method of Transportation Role.
                </p>
              }
            />
            <GcvCard.Body>
              <List>
                {motUsers.map((user, i) => {
                  const LineBreak = motUsers.length === i + 1 ? () => <></> : () => <Divider />;

                  return (
                    <div key={user.name}>
                      <>
                        <ListItem>
                          <ListItemText primary={user.name} data-cy="active-vendor-name" />
                        </ListItem>
                        <LineBreak />
                      </>
                    </div>
                  );
                })}
              </List>
            </GcvCard.Body>
          </GcvCard>
        </Grid>
        <Grid item xs={4}>
          <GcvCard data-cy="professional-services-transportation">
            <GcvCard.Header
              title={<strong data-cy="title">Professional Services</strong>}
              subtitle={
                <p style={{ fontSize: '14px' }} data-cy="subtext">
                  These are the service providers that are managed by the business.
                </p>
              }
            />
            <GcvCard.Body>
              <List>
                {dispensary.methodOfTransportation.vendor.map((vendor, i) => {
                  const LineBreak =
                    dispensary.methodOfTransportation.vendor.length === i + 1 ? () => <></> : () => <Divider />;

                  return (
                    <div key={vendor.name}>
                      <>
                        <ListItem>
                          <ListItemText primary={vendor.name} data-cy="active-vendor-name" />
                          <ListItemSecondaryAction>
                            <ButtonIcon className="material-icons" onClick={() => toggleMotModal(vendor.id)}>
                              remove_red_eye
                            </ButtonIcon>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <LineBreak />
                      </>
                    </div>
                  );
                })}
              </List>
            </GcvCard.Body>
          </GcvCard>
        </Grid>
        <Grid item xs={4}>
          <GcvCard data-cy="provided-services-transportation">
            <GcvCard.Header
              title={<strong data-cy="title">Provided Services</strong>}
              subtitle={
                <p style={{ fontSize: '14px' }} data-cy="subtext">
                  Choose which of your service providers are available to this business. Create a new service option in
                  Transportation Settings.
                </p>
              }
            />
            <GcvCard.Body>
              <List>
                {dispensary.methodOfTransportation.bank.map((mot, i) => {
                  return (
                    <div key={mot.name}>
                      <>
                        <ListItem>
                          <ListItemText primary={mot.name} data-cy="vendor-name" />
                          <ListItemSecondaryAction>
                            <ButtonIcon
                              className="material-icons"
                              style={{ color: mot.favorite ? '#00bc66' : '#e0e0e0' }}
                              onClick={() => toggleRequireModal(mot.id)}
                              data-cy={mot.favorite ? 'active-favorite-toggle' : 'inactive-favorite-toggle'}
                            >
                              star_outline
                            </ButtonIcon>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </>
                    </div>
                  );
                })}
                <>
                  <Divider />
                  <ListItem style={{ color: '#00bc66' }} data-cy="add-another-method">
                    <ListItemText primary={'Add Another Method'} />
                    <ListItemSecondaryAction>
                      <ButtonIcon
                        className="material-icons"
                        onClick={toggleAddModal}
                        style={{ color: '#00bc66' }}
                        data-cy="add-another-method-button"
                      >
                        add_circle
                      </ButtonIcon>
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              </List>
            </GcvCard.Body>
          </GcvCard>
        </Grid>
      </Grid>

      <GcvModal
        title={'Method of Transportation Details'}
        modalOpen={motModalOpen}
        toggleModal={toggleMotModal}
        backButton={
          <GcvButton tertiary onClick={() => toggleMotModal()}>
            Close
          </GcvButton>
        }
      >
        <ModalContent>
          <div style={{ padding: '0 2rem' }}>
            <div
              style={{
                flex: '1',
                width: '100%',
                alignSelf: 'center',
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {selectedVendor.name}
              <br />
              <br />
              {selectedVendor.type === 'user' && (
                <svg
                  style={{ height: '150px', width: '150px' }}
                  width="499"
                  height="381"
                  viewBox="0 0 499 381"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="#fff" d="M0 0h499v381H0z" />
                  <path
                    d="M378.3 216.8a5.8 5.8 0 0 0-8.4 0l-52.5 53.6-27.3-27.9a5.8 5.8 0 0 0-8.4 0 6.1 6.1 0 0 0 0 8.5l31.5 32.2a5.8 5.8 0 0 0 8.4 0l56.7-57.9a6.1 6.1 0 0 0 0-8.5z"
                    fill="#02B45B"
                  />
                  <path
                    d="M158 185l-43.6 14.3a40.5 40.5 0 0 0-17.8 11.2 24.7 24.7 0 0 0-6.6 16.3v66.7"
                    stroke="#02B45B"
                    strokeWidth="15"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M215 165c30.4 0 55-26.9 55-60s-24.6-60-55-60-55 26.9-55 60 24.6 60 55 60zM286.7 307l43.3 28 43.3-28a52.8 52.8 0 0 0 23.4-36l13.3-84.4c-24.2 0-80-21.6-80-21.6s-52.5 21.6-80 21.6l13.3 84.5a52.3 52.3 0 0 0 23.4 36z"
                    stroke="#02B45B"
                    strokeWidth="15"
                    strokeLinejoin="round"
                  />
                </svg>
              )}

              {selectedVendor.type === 'vendor' && (
                <svg
                  style={{ height: '150px', width: '150px' }}
                  width="499"
                  height="381"
                  viewBox="0 0 499 381"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M447.8 168.4l-12-47.7a7.2 7.2 0 0 0 5.8-7V106a30.3 30.3 0 0 0-30.3-30.3h-54.2V60c0-8.2-6.7-14.9-15-14.9H65.7c-8.2 0-14.9 6.7-14.9 14.9v130.6a7.2 7.2 0 1 0 14.4 0V59.9c0-.3.3-.5.5-.5h276.6c.3 0 .5.2.5.5v130.6a7.2 7.2 0 1 0 14.4 0v-8.2h85c10.4 0 19.3 6.9 22.4 16.4H442c-4 0-7.2 3.2-7.2 7.2v15.3a22.6 22.6 0 0 0 22.5 22.6h8.2v31.7h-18.8a45.7 45.7 0 0 0-86.3 0h-3.4v-54.3a7.2 7.2 0 0 0-14.4 0v54.3H193.2a45.7 45.7 0 0 0-86.2 0H65.6a.5.5 0 0 1-.5-.5v-15.8h31.2a7.2 7.2 0 0 0 0-14.5H27.2a7.2 7.2 0 0 0 0 14.5h23.5V275c0 8.2 6.7 14.9 15 14.9h38.8v.5a45.7 45.7 0 0 0 91.2 0v-.5H358v.5a45.7 45.7 0 0 0 91.3 0v-.5h23.5c4 0 7.2-3.2 7.2-7.2v-76.8a38 38 0 0 0-32.2-37.5zM357.1 90h54.2c8.8 0 15.9 7.1 15.9 15.9v.5H357V90zm0 77.8v-47h64l11.7 47h-75.7zM150 321.6a31.2 31.2 0 1 1 .1-62.5 31.2 31.2 0 0 1 0 62.5zm253.6 0a31.2 31.2 0 1 1 0-62.5 31.2 31.2 0 0 1 0 62.5zm61.9-92.2h-8.2a8.2 8.2 0 0 1-8.1-8.2v-8.1h16.3v16.3z"
                    fill="#02B45B"
                  />
                  <path
                    d="M150.1 275.5a14.9 14.9 0 1 0 0 29.8 14.9 14.9 0 0 0 0-29.8zM403.7 275.5a14.9 14.9 0 1 0 0 29.8 14.9 14.9 0 0 0 0-29.8zM319.1 244.8H211.6a7.2 7.2 0 0 0 0 14.4H319a7.2 7.2 0 1 0 0-14.4zM134.8 214H42.6a7.2 7.2 0 1 0 0 14.4h92.2a7.2 7.2 0 0 0 0-14.4zM270.5 116.3a7.2 7.2 0 0 0-10.2 0l-64 64-33.4-33.3a7.2 7.2 0 1 0-10.2 10.2l38.5 38.4a7.2 7.2 0 0 0 10.1 0l69.2-69.2a7.2 7.2 0 0 0 0-10.1z"
                    fill="#02B45B"
                  />
                </svg>
              )}

              {selectedVendor.type === 'bank' && (
                <svg
                  style={{ height: '150px', width: '150px' }}
                  width="499"
                  height="381"
                  viewBox="0 0 499 381"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M52.2 168.4l12-47.7a7.2 7.2 0 0 1-5.8-7V106a30.3 30.3 0 0 1 30.3-30.3h54.2V60c0-8.2 6.7-14.9 15-14.9h276.5c8.2 0 14.9 6.7 14.9 14.9v130.6a7.2 7.2 0 0 1-14.4 0V59.9c0-.3-.3-.5-.5-.5H157.8c-.3 0-.5.2-.5.5v130.6a7.2 7.2 0 1 1-14.4 0v-8.2H58a23.6 23.6 0 0 0-22.4 16.4H58c4 0 7.2 3.2 7.2 7.2v15.3a22.6 22.6 0 0 1-22.5 22.6h-8.2v31.7h18.8a45.7 45.7 0 0 1 86.3 0h3.4v-54.3a7.2 7.2 0 0 1 14.4 0v54.3h149.5a45.7 45.7 0 0 1 86.2 0h41.4c.2 0 .5-.2.5-.5v-15.8h-31.2a7.2 7.2 0 0 1 0-14.5h69.1a7.2 7.2 0 0 1 0 14.5h-23.5V275c0 8.2-6.7 14.9-15 14.9h-38.8v.5a45.7 45.7 0 0 1-91.2 0v-.5H142v.5a45.7 45.7 0 0 1-91.3 0v-.5H27.2c-4 0-7.2-3.2-7.2-7.2v-76.8a38 38 0 0 1 32.2-37.5zM142.9 90H88.7c-8.8 0-15.9 7.1-15.9 15.9v.5H143V90zm0 77.8v-47H79l-11.7 47h75.7zm207 153.7a31.2 31.2 0 1 0-.1-62.5 31.2 31.2 0 0 0 0 62.5zm-253.6 0a31.2 31.2 0 1 0 0-62.5 31.2 31.2 0 0 0 0 62.5zm-61.9-92.2h8.2c4.5 0 8.1-3.7 8.1-8.2v-8.1H34.4v16.3z"
                    fill="#02B45B"
                  />
                  <path
                    d="M349.9 275.5a14.9 14.9 0 1 1 0 29.8 14.9 14.9 0 0 1 0-29.8zM96.3 275.5a14.9 14.9 0 1 1 0 29.8 14.9 14.9 0 0 1 0-29.8zM180.9 244.8h107.5a7.2 7.2 0 1 1 0 14.4H181a7.2 7.2 0 0 1 0-14.4zM365.3 214h92.1a7.2 7.2 0 0 1 0 14.4h-92.1a7.2 7.2 0 0 1 0-14.4z"
                    fill="#02B45B"
                  />
                  <path
                    d="M292.8 155.3a22.8 22.8 0 1 1 22.9-22.9M292.8 155.3A22.8 22.8 0 1 1 270 178M292.9 109.6V95M292.9 215.6V201"
                    stroke="#02B45B"
                    strokeWidth="14"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}

              <div style={{ textAlign: 'center' }}>
                <ModalLabel>Address</ModalLabel>
                {selectedVendor.street}
                <br />
                {selectedVendor.city}, {selectedVendor.state} {selectedVendor.zipCode}
                <br />
                <br />
                <ModalLabel>Phone</ModalLabel>
                {selectedVendor.phone}
              </div>
            </div>
          </div>
        </ModalContent>
      </GcvModal>

      <AddTransportationServiceModal
        modalOpen={addModalOpen}
        toggleModal={toggleAddModal}
        onSubmit={addTransportationService}
      ></AddTransportationServiceModal>

      <GcvModal
        title={'Require service for this account?'}
        modalOpen={requireModalOpen}
        toggleModal={toggleRequireModal}
        backButton={
          <GcvButton tertiary onClick={() => toggleRequireModal()}>
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={() => requireTransportationService(selectedVendor.id)} primary={true}>
            Continue
          </GcvButton>
        }
      >
        <ModalContent>
          <div style={{ padding: '0 2rem' }}>
            <div style={{ textAlign: 'center' }}>
              <svg fill="none" height="76" width="76" xmlns="http://www.w3.org/2000/svg" className="ng-star-inserted">
                <path d="M38 75a37 37 0 1 0 0-74 37 37 0 0 0 0 74z" fill="#F9FAFD" stroke="#E0E5F0"></path>
                <path
                  clipRule="evenodd"
                  d="M15.1 51.7l21.5-37.2a1 1 0 0 1 1.7 0l21.5 37.2a1 1 0 0 1-.9 1.5H16a1 1 0 0 1-.9-1.5z"
                  fill="#fff"
                  fillRule="evenodd"
                ></path>
                <path
                  clipRule="evenodd"
                  d="M15.1 51.7l21.5-37.2a1 1 0 0 1 1.7 0l21.5 37.2a1 1 0 0 1-.9 1.5H16a1 1 0 0 1-.9-1.5z"
                  stroke="#F5A623"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
                <path
                  clipRule="evenodd"
                  d="M36 40.1V26.4a1.4 1.4 0 0 1 2.9 0v13.7a1.4 1.4 0 0 1-2.9 0zM36 45.3a2 2 0 1 1 3.9 0 2 2 0 0 1-3.9 0z"
                  fill="#F5A623"
                  fillRule="evenodd"
                ></path>
              </svg>
            </div>
            <br />
            <br />
            <p>
              By clicking continue, the business will be required to use this service provided by you to physically
              transfer deposits.
            </p>
          </div>
        </ModalContent>
      </GcvModal>
    </>
  );
};
