import React, { useState, useEffect } from 'react';
import {
  Header,
  VerticalCenter,
  Title,
  LargeTitle,
  ReviewText,
  CardContainer,
  Progress,
  BodyContainer,
  ModalContent,
} from './styles';
import { BackButtonIcon } from '../../icons/BackButtonIcon';
import {
  Dispensary,
  OrganizationRoleResolver,
  DocumentChecker,
  Bank,
  DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE,
  User,
  DueDiligenceRequirementStatus,
  DueDiligenceStatus,
  LicenseManager,
  DueDiligenceDocumentStatus,
} from '@gcv/shared';
import { useHistory, useParams } from 'react-router-dom';
import { GcvCard, GcvButton, GcvLoading, GcvModal, GcvInputForm, GcvInputSelect } from '../../lib';
import { $primary, $grey1 } from '../../util/styleVariables';
import { api } from '../../api';
import { useForm } from 'react-hook-form';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { dispensary_frequency_options, dispensary_rating_options } from '../../constants/Accounts';
import { doesBankHaveCustomFields } from '../../util';

interface Props {
  dispensaries: Dispensary[];
  bank: Bank;
  user: User;
  emitData: (type: string, data) => void;
}

type ProgressStatus = 'In Progress' | 'Not Started' | 'Complete' | 'Review In Progress' | 'Unreviewed' | 'Reviewed';
export type SectionTypes = 'account-owners' | 'operational' | 'business' | 'documentation' | 'additional-information';

export const ReviewAccount = ({ bank, user, emitData }: Props) => {
  const apiClient = api();
  const { handleSubmit, formState, ...form } = useForm({});

  const { dispensary_id } = useParams<{ dispensary_id: string }>();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const [dispensaryUsers, setDispensaryUsers] = useState<User[]>([]);
  const [selectedDispensary, setSelectedDispensary] = useState<Dispensary>();
  const [accountOwners, setAccountOwners] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [peerGroupOptions, setPeerGroupOptions] = useState([]);

  const getDispensary = async () => {
    const dispensary = await apiClient.banks.getDispensary(bank.id, dispensary_id, () => {});
    setSelectedDispensary(dispensary);
  };

  const getUsers = async () => {
    const users = await apiClient.organizations.getUsersForOrg(dispensary_id, () => {});
    setDispensaryUsers(users);
  };

  const canUserApproveAccounts = new OrganizationRoleResolver().userCanDoAction(
    bank.groups,
    user,
    'account_info_update'
  );

  useEffect(() => {
    getDispensary();
    getUsers();
    if (canUserApproveAccounts) {
      apiClient.accountMonitoring.getPeerGroups(bank.id, setLoading).then(r => {
        const peerGroups = Object.values(r.peer_groups) as any;
        setPeerGroupOptions(
          peerGroups.map(pg => {
            return { label: pg.name, value: pg.id, default_group: pg.default_group };
          })
        );
      });
    }
  }, []);

  const accountOwnersIds = selectedDispensary
    ? new OrganizationRoleResolver().getGroupsWithRole(selectedDispensary.groups, 'dispensary_account_owner')[0].users
    : [];

  const isAwaitingReview =
    selectedDispensary &&
    selectedDispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_AWAITING_REVIEW;

  const bankHasCustomFields = doesBankHaveCustomFields(bank.due_diligence_template);

  const isReadyToCompleteReview =
    isAwaitingReview &&
    selectedDispensary &&
    selectedDispensary.due_diligence.account_owners_section_bank_review_complete &&
    selectedDispensary.due_diligence.business_details_section_bank_review_complete &&
    selectedDispensary.due_diligence.due_diligence_documentation_section_bank_review_complete &&
    selectedDispensary.due_diligence.operational_details_section_bank_review_complete &&
    (!bankHasCustomFields ||
      (bankHasCustomFields && selectedDispensary.due_diligence.additional_information_section_review_complete));

  useEffect(() => {
    loadAccountOwners();
  }, [JSON.stringify(accountOwnersIds)]);

  const loadAccountOwners = async () => {
    const accountOwners = dispensaryUsers.filter(
      user => accountOwnersIds.includes(user.id) && user.identityVerified && user.invitation_status !== 'archived'
    );
    setAccountOwners(accountOwners);
  };

  const onBack = () => {
    if (selectedDispensary.due_diligence.due_diligence_status === 'bank_awaiting_review') {
      history.push(`/secure/bank/accounts/awaiting-review`);
    } else {
      history.push(`/secure/bank/accounts/pending`);
    }
  };

  const ProgressComponent = ({ status }: { status: ProgressStatus }) => {
    let color;

    if (status === 'In Progress' || status === 'Review In Progress') {
      color = '#F5A623';
    } else if (status === 'Not Started' || status === 'Unreviewed') {
      color = $grey1;
    } else if (status === 'Complete' || status === 'Reviewed') {
      color = $primary;
    }
    return (
      <Progress style={{ color }} data-cy="status">
        {status}
      </Progress>
    );
  };

  const onClick = (section: SectionTypes) => {
    history.push(`/secure/bank/account/${dispensary_id}/review/${section}`);
  };

  if (!selectedDispensary || loading) {
    return <GcvLoading></GcvLoading>;
  }

  const status = isAwaitingReview
    ? getAwaitingReviewStatus(selectedDispensary, accountOwners)
    : getPendingStatus(selectedDispensary, bank, accountOwners);

  const getButtonText = (type: SectionTypes) => {
    if (isAwaitingReview) {
      if (status[type] === 'Complete' || status[type] === 'Reviewed') {
        return 'Edit Details';
      } else if (status[type] === 'In Progress' || status[type] === 'Review In Progress') {
        return 'Continue';
      } else {
        return 'Get Started';
      }
    } else {
      return 'View';
    }
  };

  const getButtonFill = (type: SectionTypes) => {
    let button = {
      secondary: true,
      primary: false,
    };
    if (isAwaitingReview) {
      if (status[type] === 'Complete' || status[type] === 'Reviewed') {
        button.primary = false;
        button.secondary = true;
      } else if (status[type] === 'In Progress' || status[type] === 'Review In Progress') {
        button.primary = true;
        button.secondary = false;
      } else {
        button.primary = true;
        button.secondary = false;
      }
    } else {
      button.primary = false;
      button.secondary = true;
    }
    return button;
  };

  const completeReview = async () => {
    setLoading(true);
    await apiClient.dispensaries
      .reviewDispensary(bank.id, dispensary_id, {}, setLoading)
      .then((dispensary: Dispensary) => {
        setSelectedDispensary(dispensary);
        emitData('updateDispensary', dispensary);
        if (dispensary.due_diligence.due_diligence_status === DueDiligenceStatus.BANK_APPROVED) {
          setModalOpen(false);
          history.push({
            pathname: `/secure/bank/accounts/active`,
            state: { snackBarMessage: `You have confirmed ${selectedDispensary.name}!` },
          });
        } else {
          setModalOpen(false);
          setSnackBarMessage('Account not approved. Please review any new documentation that requires approval');
          setSnackBarOpen(true);
        }
      });
  };

  const addBankDispensaryMetadata = async payload => {
    await apiClient.banks.updateDispensaryBankMetadata(
      bank.id,
      dispensary_id,
      {
        internal_id: payload.internalId,
        account_open_date: payload.accountOpenDate,
        review_frequency: payload.reviewFrequency.value,
        risk_rating: payload.riskRating.value,
      },
      setLoading
    );

    await apiClient.banks
      .setDispensaryPeerGroup(bank.id, payload.peerGroup.value, dispensary_id, setLoading)
      .then(async () => {
        await completeReview();
      })
      .then(() => {
        setModalOpen(false);
      });
  };

  const snackBarDismiss = (
    <Button color="inherit" size="small" onClick={() => setSnackBarOpen(false)}>
      Dismiss
    </Button>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        action={snackBarDismiss}
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarMessage}
        data-cy="snackbar"
      />

      <GcvModal
        title={`Complete Review and Approve Account`}
        modalOpen={modalOpen}
        toggleModal={() => setModalOpen(!modalOpen)}
        backButton={
          <GcvButton tertiary={true} onClick={() => setModalOpen(false)} data-cy="cancel-button">
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton
            primary={true}
            onClick={handleSubmit(addBankDispensaryMetadata)}
            disabled={!form.watch('riskRating')}
            data-cy="approve-button"
          >
            Approve
          </GcvButton>
        }
        data-cy="approve-modal"
      >
        <ModalContent>
          <div style={{ textAlign: 'center' }}>
            Before you approve <b>{selectedDispensary.name}</b>, please enter the initial values for the account
            oversight fields below.
          </div>
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GcvInputForm
                {...form}
                name="internalId"
                label="Internal ID (optional)"
                type="text"
                testDataTag="internal-id"
              />
            </Grid>
            <Grid item xs={12}>
              <GcvInputForm
                {...form}
                name="accountOpenDate"
                label="Account Open Date (optional)"
                type="date"
                testDataTag="account-open-date"
              />
            </Grid>
            <Grid item xs={12}>
              <GcvInputSelect
                {...form}
                name={'riskRating'}
                label={'Risk Rating *'}
                options={dispensary_rating_options}
                rules={{ required: { value: true, message: 'is required' } }}
                testDataTag="risk-rating-dropdown"
              />
            </Grid>
            <Grid item xs={12}>
              <GcvInputSelect
                {...form}
                name={'reviewFrequency'}
                label={'Review Frequency *'}
                options={dispensary_frequency_options}
                defaultValue={{ label: 'Monthly (Default)', value: 'monthly' }}
                rules={{ required: { value: true, message: 'is required' } }}
                testDataTag="review-frequency-dropdown"
              />
            </Grid>
            <Grid item xs={12}>
              <GcvInputSelect
                {...form}
                name={'peerGroup'}
                label={'Peer Group *'}
                options={peerGroupOptions}
                rules={{ required: { value: true, message: 'is required' } }}
                defaultValue={{
                  label: 'All Accounts (Default)',
                  value: peerGroupOptions.filter(pg => pg.default_group)[0]?.value,
                }}
                testDataTag="peer-groups-dropdown"
              />
            </Grid>
          </Grid>
        </ModalContent>
      </GcvModal>

      <Header style={{ justifyContent: 'start' }}>
        <BackButtonIcon onClick={onBack} style={{ cursor: 'pointer' }} />
        <VerticalCenter style={{ marginLeft: '1rem' }}>
          <Title data-cy="title">{selectedDispensary ? selectedDispensary.name : ''}</Title>
        </VerticalCenter>
      </Header>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isAwaitingReview ? (
          <ReviewText data-cy="subtext">
            Please review this account for all required information and documentation.
          </ReviewText>
        ) : (
          <ReviewText data-cy="subtext">This account’s application is currently in progress.</ReviewText>
        )}
        <span>
          {isReadyToCompleteReview ? (
            <GcvButton
              primary={true}
              disabled={!canUserApproveAccounts}
              onClick={() => setModalOpen(true)}
              data-cy="button.component.complete-review"
            >
              Complete Review
            </GcvButton>
          ) : null}
        </span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
        <CardContainer style={{ width: '30%' }}>
          <GcvCard style={{ padding: '2rem' }} data-cy="accounts-owners-review-card">
            <GcvCard.Header
              title={
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <div>1.</div>
                  <VerticalCenter>
                    <ProgressComponent status={status['account-owners']} />
                  </VerticalCenter>
                </div>
              }
            ></GcvCard.Header>
            <GcvCard.Body>
              <BodyContainer>
                <LargeTitle data-cy="review-section-title">Account Owners</LargeTitle>
                <ReviewText data-cy="review-section-text">Review the individuals listed on this account.</ReviewText>
                <GcvButton
                  primary={getButtonFill('account-owners').primary}
                  secondary={getButtonFill('account-owners').secondary}
                  pill={true}
                  style={{ width: '100%' }}
                  onClick={() => onClick('account-owners')}
                  data-cy="gcv-card.component.getStarted"
                >
                  {getButtonText('account-owners')}
                </GcvButton>
              </BodyContainer>
            </GcvCard.Body>
          </GcvCard>
        </CardContainer>
        <CardContainer style={{ width: '30%' }} data-cy="business-details-review-card">
          <GcvCard style={{ padding: '2rem' }}>
            <GcvCard.Header
              title={
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <div>2.</div>
                  <VerticalCenter>
                    <ProgressComponent status={status.business} />
                  </VerticalCenter>
                </div>
              }
            ></GcvCard.Header>
            <GcvCard.Body>
              <BodyContainer>
                <LargeTitle data-cy="review-section-title">Business Details</LargeTitle>
                <ReviewText data-cy="review-section-text">Look over some basic details about this business.</ReviewText>
                <GcvButton
                  primary={getButtonFill('business').primary}
                  secondary={getButtonFill('business').secondary}
                  pill={true}
                  style={{ width: '100%' }}
                  onClick={() => onClick('business')}
                  data-cy="gcv-card.component.getStarted"
                >
                  {getButtonText('business')}
                </GcvButton>
              </BodyContainer>
            </GcvCard.Body>
          </GcvCard>
        </CardContainer>
        <CardContainer style={{ width: '30%' }} data-cy="operational-details-review-card">
          <GcvCard style={{ padding: '2rem' }}>
            <GcvCard.Header
              title={
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <div>3.</div>
                  <VerticalCenter>
                    <ProgressComponent status={status.operational} />
                  </VerticalCenter>
                </div>
              }
            ></GcvCard.Header>
            <GcvCard.Body>
              <BodyContainer>
                <LargeTitle data-cy="review-section-title">Operational Details</LargeTitle>
                <ReviewText data-cy="review-section-text">
                  Learn about this business’s day-to-day operations.
                </ReviewText>
                <GcvButton
                  primary={getButtonFill('operational').primary}
                  secondary={getButtonFill('operational').secondary}
                  pill={true}
                  style={{ width: '100%' }}
                  onClick={() => onClick('operational')}
                  data-cy="gcv-card.component.getStarted"
                >
                  {getButtonText('operational')}
                </GcvButton>
              </BodyContainer>
            </GcvCard.Body>
          </GcvCard>
        </CardContainer>
        {bankHasCustomFields ? (
          <CardContainer style={{ width: '30%' }} data-cy="additional-information-review-card">
            <GcvCard style={{ padding: '2rem' }}>
              <GcvCard.Header
                title={
                  <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                    <div>4.</div>
                    <VerticalCenter>
                      <ProgressComponent status={status['additional-information']} />
                    </VerticalCenter>
                  </div>
                }
              ></GcvCard.Header>
              <GcvCard.Body>
                <BodyContainer>
                  <LargeTitle data-cy="review-section-title">Additional Information</LargeTitle>
                  <ReviewText data-cy="review-section-text">
                    Review the additional information provided by this business.
                  </ReviewText>
                  <GcvButton
                    primary={getButtonFill('additional-information').primary}
                    secondary={getButtonFill('additional-information').secondary}
                    pill={true}
                    style={{ width: '100%' }}
                    onClick={() => onClick('additional-information')}
                    data-cy="gcv-card.component.getStarted"
                  >
                    {getButtonText('additional-information')}
                  </GcvButton>
                </BodyContainer>
              </GcvCard.Body>
            </GcvCard>
          </CardContainer>
        ) : null}

        <CardContainer style={{ width: '30%' }} data-cy="documentation-review-card">
          <GcvCard style={{ padding: '2rem' }}>
            <GcvCard.Header
              title={
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <div>{bankHasCustomFields ? '5.' : '4.'}</div>
                  <VerticalCenter>
                    <ProgressComponent status={status.documentation} />
                  </VerticalCenter>
                </div>
              }
            ></GcvCard.Header>
            <GcvCard.Body>
              <BodyContainer>
                <LargeTitle data-cy="review-section-title">Due Diligence Documentation</LargeTitle>
                <ReviewText data-cy="review-section-text">
                  Evaluate the account opening documents provided by this business.
                </ReviewText>
                <GcvButton
                  primary={getButtonFill('documentation').primary}
                  secondary={getButtonFill('documentation').secondary}
                  pill={true}
                  style={{ width: '100%' }}
                  onClick={() => onClick('documentation')}
                  data-cy="gcv-card.component.getStarted"
                >
                  {getButtonText('documentation')}
                </GcvButton>
              </BodyContainer>
            </GcvCard.Body>
          </GcvCard>
        </CardContainer>
      </div>
    </>
  );
};

interface GetStatus {
  ['additional-information']: ProgressStatus;
  ['account-owners']: ProgressStatus;
  business: ProgressStatus;
  operational: ProgressStatus;
  documentation: ProgressStatus;
}

const getAwaitingReviewStatus = (dispensary: Dispensary, accountOwners: User[]): GetStatus => {
  const getOwnersStatus = (): ProgressStatus => {
    if (dispensary.due_diligence.account_owners_section_bank_review_complete) {
      return 'Reviewed';
    } else {
      let status: ProgressStatus = 'Unreviewed';
      accountOwners.forEach(acc => {
        if (
          acc.identification &&
          (acc.identification.idBack.status === 'approved' || acc.identification.idFront.status === 'approved')
        ) {
          status = 'Review In Progress';
        }
      });
      return status;
    }
  };

  const getDocumentDetailsStatus = (): ProgressStatus => {
    if (dispensary.due_diligence.due_diligence_documentation_section_bank_review_complete) {
      return 'Reviewed';
    } else {
      let status: ProgressStatus = Object.keys(dispensary.due_diligence.requirements).some(reqId => {
        return (
          dispensary.due_diligence.requirements[reqId].status === DueDiligenceRequirementStatus.REVIEWED ||
          Object.keys(dispensary.due_diligence.requirements[reqId].documents).some(
            docId =>
              dispensary.due_diligence.requirements[reqId].documents[docId].status !==
              DueDiligenceDocumentStatus.PENDING
          )
        );
      })
        ? 'Review In Progress'
        : 'Unreviewed';
      return status;
    }
  };

  return {
    ['account-owners']: getOwnersStatus(),
    business: dispensary.due_diligence.business_details_section_bank_review_complete ? 'Reviewed' : 'Unreviewed',
    operational: dispensary.due_diligence.operational_details_section_bank_review_complete ? 'Reviewed' : 'Unreviewed',
    ['additional-information']: dispensary.due_diligence.additional_information_section_review_complete
      ? 'Reviewed'
      : 'Unreviewed',
    documentation: getDocumentDetailsStatus(),
  };
};

const getPendingStatus = (dispensary: Dispensary, bank: Bank, owners: User[]): GetStatus => {
  const bankDocChecker =
    bank && dispensary
      ? DocumentChecker.getDispensaryDueDiligenceFulfillment(dispensary, bank.due_diligence_template)
      : null;

  const gcvDocChecker = dispensary
    ? DocumentChecker.getDispensaryDueDiligenceFulfillment(dispensary, DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE)
    : null;

  const getOwnersStatus = (bankDocChecker, accountOwners): ProgressStatus => {
    if (
      bankDocChecker &&
      bankDocChecker.fulfillmentMap.account_owners &&
      accountOwners.some((owner: User) => owner.identityVerified)
    ) {
      return 'Complete';
    } else if (
      accountOwners.length &&
      accountOwners.some(owner => owner.address != undefined || owner.identification !== undefined)
    ) {
      return 'In Progress';
    } else {
      return 'Not Started';
    }
  };

  const getBusinessDetailsStatus = (bankDocChecker): ProgressStatus => {
    if (bankDocChecker && bankDocChecker.fulfillmentMap.business_details.complete) {
      return 'Complete';
    } else if (
      bankDocChecker &&
      Object.values(bankDocChecker.fulfillmentMap.business_details).some(details =>
        Object.values(details).some(req => req === true)
      )
    ) {
      return 'In Progress';
    } else {
      return 'Not Started';
    }
  };

  const getOperationalDetailsStatus = (bankDocChecker): ProgressStatus => {
    if (bankDocChecker && bankDocChecker.fulfillmentMap.operational_details.complete) {
      return 'Complete';
    } else if (
      bankDocChecker &&
      Object.values(bankDocChecker.fulfillmentMap.operational_details).some(details =>
        Object.values(details).some(req => req === true)
      )
    ) {
      return 'In Progress';
    } else {
      return 'Not Started';
    }
  };

  const getDocumentDetailsStatus = (bankDocChecker, gcvDocChecker): ProgressStatus => {
    if (
      bankDocChecker &&
      bankDocChecker.fulfillmentMap.documentation.complete &&
      gcvDocChecker &&
      gcvDocChecker.fulfillmentMap.documentation.complete
    ) {
      return 'Complete';
    } else if (
      (bankDocChecker &&
        Object.values(bankDocChecker.fulfillmentMap.documentation).some(categoryReqs =>
          Object.values(categoryReqs).some(req => req === true)
        )) ||
      (gcvDocChecker &&
        Object.values(gcvDocChecker.fulfillmentMap.documentation).some(categoryReqs =>
          Object.values(categoryReqs).some(req => req === true)
        )) ||
      (gcvDocChecker && gcvDocChecker.fulfillmentMap.business_license.complete) ||
      (gcvDocChecker &&
        LicenseManager.getLicenseData(dispensary.state).employee_license_required &&
        gcvDocChecker.fulfillmentMap.employee_license.complete)
    ) {
      return 'In Progress';
    } else {
      return 'Not Started';
    }
  };

  const getAdditionalInformationStatus = (bankDocChecker): ProgressStatus => {
    if (bankDocChecker && bankDocChecker.fulfillmentMap.additional_information.complete) {
      return 'Complete';
    } else if (
      dispensary.due_diligence.bank_custom_fields &&
      Object.values(dispensary.due_diligence.bank_custom_fields).length
    ) {
      return 'In Progress';
    } else {
      return 'Not Started';
    }
  };

  return {
    ['account-owners']: getOwnersStatus(bankDocChecker, owners),
    ['additional-information']: getAdditionalInformationStatus(bankDocChecker),
    business: getBusinessDetailsStatus(bankDocChecker),
    operational: getOperationalDetailsStatus(bankDocChecker),
    documentation: getDocumentDetailsStatus(bankDocChecker, gcvDocChecker),
  };
};
