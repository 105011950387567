import React, { useState, useEffect, useRef } from 'react';
import { User, DueDiligenceRequirementStatus } from '@gcv/shared';

import { UploadDocumentModal, RequestFileUploadModal, AddCommentModal } from '../Modals/';
import { useApi, getEndpoint } from '../../../hooks/';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { ButtonContainer } from './styles';

import { RequirementMetadata } from '../types';
import { GcvDropButton } from '../../../lib/GcvDropButton/GcvDropButton';
import { GcvButton } from '../../../lib';
import { api } from 'libs/react-ui/src/api';

interface ActivityButtonsProps {
  updateApplication: (dispensary?) => void;
  updateActivities: () => void;
  requirementMetadata: RequirementMetadata;
  dispensaryId: string;
  companyType: string;
  documentType: 'document' | 'license';
  userMap: { [id: string]: User };
  documentUpdateDisabled: boolean;
  requirementUpdateDisabled: boolean;
  dispensaryState: any;
  reqId: string;
}

export const ActivityButtons = ({
  documentUpdateDisabled,
  requirementUpdateDisabled,
  companyType,
  updateApplication,
  updateActivities,
  requirementMetadata,
  dispensaryId,
  userMap,
  documentType,
  dispensaryState,
  reqId,
}: ActivityButtonsProps) => {
  const apiClient = api();
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState<boolean>(false);
  const [addCommentModalOpen, setAddCommentModalOpen] = useState<boolean>(false);
  const [requestUploadModalOpen, setRequestUploadModalOpen] = useState<boolean>(false);
  const buttonContainerRef = useRef();
  const buttonInnerRef = useRef();

  const toggleAddCommentModal = () => {
    setAddCommentModalOpen(!addCommentModalOpen);
  };
  const toggleUploadFileModal = () => {
    setUploadFileModalOpen(!uploadFileModalOpen);
  };
  const toggleRequestUploadModal = () => {
    setRequestUploadModalOpen(!requestUploadModalOpen);
  };

  const handleViewMoreBlur = e => {
    if (buttonContainerRef && buttonInnerRef) {
      // @ts-ignore
      if (!buttonContainerRef.current.contains(e.target) && !buttonInnerRef.current.contains(e.target)) {
        setViewMore(false);
      }
    }
  };

  useEffect(() => {
    if (viewMore) {
      document.addEventListener('mousedown', handleViewMoreBlur);
      return () => {
        document.removeEventListener('mousedown', handleViewMoreBlur);
      };
    }
  }, [viewMore]);

  const toggleViewMore = () => {
    setViewMore(!viewMore);
  };

  const approveRequirement = async () => {
    const body = {
      status: DueDiligenceRequirementStatus.REVIEWED,
    };

    await apiClient.documentManagement
      .updateRequirementStatus(dispensaryId, reqId, body, () => {})
      .then(updatedDispensary => {
        updateApplication(updatedDispensary);
      })
      .catch(err => {
        alert('error approving requirement, please contact support@greencheckverified.com' + err);
      });
  };

  return (
    <ButtonContainer>
      {companyType === 'bank' ? (
        <>
          {!documentUpdateDisabled ? (
            <GcvDropButton
              title={'Update'}
              secondary={true}
              dataCy={'update-requirement-menu'}
              buttons={[
                { title: 'Upload File', onClick: toggleUploadFileModal, dataCy: 'upload-file-button' },
                { title: 'Request Upload', onClick: toggleRequestUploadModal, dataCy: 'request-upload-button' },
                { title: 'Add Comment', onClick: toggleAddCommentModal, dataCy: 'add-comment-button' },
              ]}
            ></GcvDropButton>
          ) : null}
          <GcvButton
            primary={true}
            onClick={approveRequirement}
            disabled={requirementUpdateDisabled}
            data-cy="approve-requirement"
          >
            {requirementMetadata.status === 'reviewed' ? 'Approved' : 'Approve'}
          </GcvButton>
          <RequestFileUploadModal
            updateApplication={updateActivities}
            dispensaryId={dispensaryId}
            requirementId={reqId}
            modalOpen={requestUploadModalOpen}
            toggleModal={toggleRequestUploadModal}
          />
        </>
      ) : null}

      {companyType === 'dispensary' ? (
        <>
          <GcvButton secondary={true} onClick={toggleAddCommentModal}>
            Add Comment
          </GcvButton>
          <GcvButton
            onClick={toggleUploadFileModal}
            primary={true}
            style={{ marginLeft: '10px' }}
            data-cy="upload-file-button"
          >
            Upload File
          </GcvButton>
        </>
      ) : null}

      <AddCommentModal
        companyType={companyType}
        updateApplication={updateActivities}
        dispensaryId={dispensaryId}
        requirementId={reqId}
        userMap={userMap}
        modalOpen={addCommentModalOpen}
        toggleModal={toggleAddCommentModal}
      />
      <UploadDocumentModal
        dispensaryState={dispensaryState}
        companyType={companyType}
        updateApplication={updateApplication}
        requirementName={requirementMetadata.name}
        dispensaryId={dispensaryId}
        requirementId={reqId}
        userMap={userMap}
        documentType={documentType}
        modalOpen={uploadFileModalOpen}
        toggleModal={toggleUploadFileModal}
      />
    </ButtonContainer>
  );
};
