import React, { useEffect, useState } from 'react';

import { ActivityReportType, IANATimezones } from '@gcv/shared';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '../../../../../common-ui/src/lib/common-ui';
import { useApi } from '../../../hooks';
import calculateTimeRange from '../../../util/calculateDateRange';
import { Report } from '../Components/Report';
import { api } from 'libs/react-ui/src/api';

interface Props {
  bankId: string;
  userId: string;
  accountList: Option[];
  timezone: IANATimezones;
  setExportActivitySuccess: (active: boolean) => void;
  setExportActivityFailure: (active: boolean) => void;
  setResponseModalActive: (active: boolean) => void;
}

interface Option {
  id: string;
  value: string;
  label: string;
}

export const PreComplianceReviewReport: React.FC<Props> = props => {
  const apiClient = api();

  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [timeSelectType, setTimeSelectType] = useState('created');
  const [timeSelect, setTimeSelect] = useState('today');

  useEffect(() => {
    setSelectedAccounts(props.accountList.map(a => a.value));
  }, [props.accountList]);

  const handleExportActivity = async () => {
    const timeRange = calculateTimeRange(timeSelect, props.timezone);

    await apiClient.banks
      .getPreComplianceReport(
        props.bankId,
        {
          orgId: props.bankId,
          userId: props.userId,
          reportData: {
            dispensaryIds: selectedAccounts,
            startDate: timeRange.start,
            endDate: timeRange.end,
            bankTimezone: props.timezone,
          },
        },
        () => {}
      )
      .then(() => {
        props.setExportActivitySuccess(true);
      })
      .catch(() => {
        props.setExportActivityFailure(true);
      })
      .finally(() => {
        props.setResponseModalActive(true);
      });
  };

  return (
    <Report>
      <Report.Title size={6} align="left">
        Filters
      </Report.Title>

      <Report.ExportButton size={6} align="right" export={handleExportActivity} />

      <Report.TimePeriodSelect
        size={6}
        onLabelChange={setTimeSelectType}
        onChange={setTimeSelect}
        selectOptions={[{ value: 'created', label: 'Created' }]}
        labelText="Time Period"
      />

      <Report.SearchDropdown
        size={6}
        onSelect={setSelectedAccounts}
        selectOptions={props.accountList}
        searchText={'Search Accounts'}
        itemLabel={'Account(s)'}
      />
    </Report>
  );
};
