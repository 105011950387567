import { Pipe, PipeTransform } from '@angular/core';
import { Notification, NotificationTypes } from '@gcv/shared';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dynamicNotification' })
export class DynamicNotificationPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(notification: Notification, type: string): string {
    if (type === 'title') {
      switch (notification.type) {
        case NotificationTypes.NEW_DISPENSARY_TO_REVIEW:
          return `${
            notification.email_data.dispensary_name
              ? notification.email_data.dispensary_name
              : notification.data.dispensary_name
          } is awaiting review`;
        case NotificationTypes.NEW_DEPOSIT:
          return `${notification.email_data.dispensary_name} has a new deposit`;
        case NotificationTypes.NEW_FINCEN_REPORT:
          return `${notification.email_data.dispensary_name} has a new ${
            notification.email_data.report_type ? notification.email_data.report_type : notification.data.report_type
          }`;
        case NotificationTypes.COMPLETED_DISPENSARY_REVIEW:
          return `Your Green Check account has been approved!`;
        case NotificationTypes.COMPLETE_EVS:
          return 'Please verify your identity';
        case NotificationTypes.ASSIGN_FINCEN_REPORT:
          return 'You have been assigned a FinCEN report';
        case NotificationTypes.BANKING_PARTNER_IDENTIFIED:
          return 'Banking Partner Identified';
        case NotificationTypes.ACTIVITY_REPORTING:
          return 'Your report is ready for download';
        case 'new_document_uploaded':
          return 'New Document Uploaded';
        case NotificationTypes.EXPIRED_BUSINESS_LICENSE:
          return 'Your business license has expired';
        case NotificationTypes.EXPIRED_EMPLOYEE_LICENSE:
          return 'Your employee license has expired';
        case NotificationTypes.EXPIRING_BUSINESS_LICENSE:
          return 'Your business license is about to expire';
        case NotificationTypes.EXPIRING_EMPLOYEE_LICENSE:
          return 'Your employee license is about to expire';
        case NotificationTypes.REQUEST_DOCUMENT_UPLOAD:
          return `${notification.email_data.bank_name} requested a document`;
        case NotificationTypes.NEW_COMMENT_ADDED_TO_REQUIREMENT:
          return `New Comment Added`;
        case NotificationTypes.SALES_INGESTION_FAILURE:
          return `There was an issue with your sales upload.  Click for details.`;
        case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_NON_RETAIL:
          return `Your sales upload had some errors. Click for details.`;
        case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_RETAIL:
          return `Your sales upload had some errors. Click for details.`;
        case NotificationTypes.MAX_DEPOSIT_UPDATED:
          return `${notification.email_data.bankName} has updated your Max Deposit`;
        default:
          return '';
      }
    } else {
      switch (notification.type) {
        case NotificationTypes.NEW_DISPENSARY_TO_REVIEW:
          return `Submitted On: ${this.datePipe.transform(notification.created_date)}`;
        case NotificationTypes.NEW_DEPOSIT:
          return `Submitted On: ${this.datePipe.transform(
            notification.email_data.deposit_date ? notification.email_data.deposit_date : notification.data.deposit_date
          )}`;
        case NotificationTypes.NEW_FINCEN_REPORT:
          return `Received On: ${this.datePipe.transform(
            notification.email_data.received_date
              ? notification.email_data.received_date
              : notification.data.received_date
          )}`;
        case NotificationTypes.COMPLETED_DISPENSARY_REVIEW:
          return `Approved On: ${this.datePipe.transform(notification.created_date)}`;
        case NotificationTypes.COMPLETE_EVS:
          return `Received On: ${this.datePipe.transform(notification.send_date)}`;
        case NotificationTypes.ASSIGN_FINCEN_REPORT:
          return `Received On: ${this.datePipe.transform(notification.send_date)}`;
        case NotificationTypes.BANKING_PARTNER_IDENTIFIED:
          return `${notification.email_data.bank_name ? notification.email_data.bank_name : notification.data.orgName}`;
        case NotificationTypes.ACTIVITY_REPORTING:
          return `Created On: ${this.datePipe.transform(notification.created_date)}`;
        case NotificationTypes.NEW_DOCUMENT_UPLOADED:
          return notification.email_data.name ? notification.email_data.name : notification.data.name;
        case NotificationTypes.EXPIRED_BUSINESS_LICENSE:
        case NotificationTypes.EXPIRED_EMPLOYEE_LICENSE:
        case NotificationTypes.EXPIRING_BUSINESS_LICENSE:
        case NotificationTypes.EXPIRING_EMPLOYEE_LICENSE:
          return notification.email_data.license_type ? notification.email_data.license_type : '';
        case NotificationTypes.REQUEST_DOCUMENT_UPLOAD:
          return `${notification.email_data.requirement_name} on ${this.datePipe.transform(notification.created_date)}`;
        case NotificationTypes.NEW_COMMENT_ADDED_TO_REQUIREMENT:
          return `${notification.email_data.org_name}`;
        case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_RETAIL:
          return `Uploaded On: ${this.datePipe.transform(notification.email_data.date_time)}`;
        case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_NON_RETAIL:
          return `Uploaded On: ${this.datePipe.transform(notification.email_data.date_time)}`;
        case NotificationTypes.SALES_INGESTION_FAILURE:
          return `Uploaded On: ${this.datePipe.transform(notification.email_data.date_time)}`;
        case NotificationTypes.MAX_DEPOSIT_UPDATED:
          return `Now: ${notification.email_data.newValue} on ${this.datePipe.transform(notification.email_data.date)}`;
        default:
          return '';
      }
    }
  }
}
