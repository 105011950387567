import React, { useState, useRef, useEffect } from 'react';
import {
  FilterContainer,
  FilterButton,
  Option,
  FilterLabel,
  VerticalCenter,
  PermanentDisplay,
  WidthSetter,
  DropButton,
  DropContainer,
  ContainerDiv,
} from './styles';
import { DownArrowIcon } from 'libs/react-ui/src/icons/DownArrowIcon';
import { GcvUserInputContainer } from '../GcvUserInputContainer/GcvUserInputContainer';

interface FilterDropDownProps {
  options: Option[];
  onChange: (option: Option) => void;
  minWidth?: string;
  buttonStyle?: any;
  selectedCharLength?: number;
  defaultValue: string;
}

interface Option {
  value: string;
  label: string;
}

export const DropdownComponent: React.FC<FilterDropDownProps> = ({
  options,
  onChange,
  minWidth,
  buttonStyle,
  selectedCharLength = 10,
  defaultValue,
}) => {
  const initial = defaultValue ? options.filter(o => o.value === defaultValue)[0] : options[0];
  const [selected, setSelected] = useState<Option>(initial);
  const [open, setOpen] = useState(false);
  const selectRef = useRef();

  const handleOptionSelect = (option: Option) => {
    setSelected(option);
    setOpen(false);
    onChange(option);
  };

  const handleSelectBlur = e => {
    if (selectRef) {
      // @ts-ignore
      if (!selectRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleSelectBlur);
      return () => {
        document.removeEventListener('mousedown', handleSelectBlur);
      };
    }
  }, [open]);

  return (
    <>
      <ContainerDiv ref={selectRef}>
        <DropButton onClick={() => setOpen(!open)} style={{ ...buttonStyle, width: minWidth }}>
          <VerticalCenter style={{ padding: '6px 8px', textAlign: 'left' }}>
            <GcvUserInputContainer
              string={selected.label}
              length={selectedCharLength}
              data-cy={'dropdown-selected-value'}
            />
          </VerticalCenter>

          <VerticalCenter style={{ padding: '0 .5rem' }}>
            <DownArrowIcon></DownArrowIcon>
          </VerticalCenter>
        </DropButton>
        <DropContainer style={{ display: open ? 'block' : 'none', width: minWidth }}>
          {options.map(o => {
            return (
              <Option key={o.value} onClick={() => handleOptionSelect(o)} className="" data-cy="dropdown-option">
                {o.label}
              </Option>
            );
          })}
        </DropContainer>
      </ContainerDiv>
    </>
  );
};
