import styled from 'styled-components';
import { $primary, $primaryBorder, $black2, $grey8 } from '../../util/styleVariables';

interface Props {
  complete?: boolean;
  current?: boolean;
}

export const Tab = styled.div`
  flex-grow: 1;
  min-width: 50px;
  max-width: 206px;
  margin: 0px 16.5px;
`;

export const IndexContainer = styled.div<Props>`
  background-color: ${props => (props.complete ? $primary : $primaryBorder)};
  height: 13px;
  width: 100%;
  border-radius: 9.5px;
`;

export const NameContainer = styled.div<Props>`
  color: ${props => (props.current ? $black2 : $grey8)};
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  margin-top: 8px;
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 956px;
  margin: 0 auto;
`;

export const CurrentTabContainer = styled.div`
  margin: 30px;
`;
