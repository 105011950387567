// tslint:disable
/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
// tslint:enable

import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AuthState } from 'aws-amplify-angular/dist/src/providers/auth.state';
import { AmplifyService } from 'aws-amplify-angular';
import { setAppReady } from '../../cypress-tools';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxValidators } from 'ngx-form-validators';
import { Actions, ofType } from '@ngrx/effects';
import { ErrorActionTypes, UserActionTypes } from 'libs/gcv-state/src/lib';
import { takeUntil, tap } from 'rxjs/operators';
import { Unsubscribe } from '@user-interface/ng-shared';
import { AuthService } from '../../app/auth/route-guards/auth.service';
import { RollbarService } from '../../services/rollbar/rollbar.service';

@Component({
  selector: 'app-amplify-auth-sign-in-core',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./amplify-auth-sign-in-core.scss'],
})
export class SignInComponent extends Unsubscribe {
  // tslint:disable-next-line:variable-name
  _authState: AuthState;
  // tslint:disable-next-line:variable-name
  _show: boolean;
  username: string;
  password: string;
  @Output() signingIn = new EventEmitter();
  userData = new FormGroup({
    username: new FormControl('', [Validators.required, NgxValidators.email]),
    password: new FormControl('', [Validators.required]),
  });
  errorMessage: string;
  amplifyService: AmplifyService;

  constructor(
    amplifyService: AmplifyService,
    private updates$: Actions,
    private authService: AuthService,
    private injector: Injector
  ) {
    super();
    localStorage.removeItem('syncSalesTime');
    this.updates$
      .pipe(
        ofType(ErrorActionTypes.AddError),
        takeUntil(this.unsubscribe$),
        tap((err: any) => {
          if (err.payload.action === UserActionTypes.LoginUser) {
            const rollbar = this.injector.get(RollbarService);
            rollbar.error(err);
            this.signingIn.emit(false);
            authService.loggin = false;
            this._setError('Something went wrong. Try again');
          }
        })
      )
      .subscribe();
    this.amplifyService = amplifyService;
    this.amplifyService
      .auth()
      .currentAuthenticatedUser()
      .then(user => {
        if (user) {
          this.amplifyService.setAuthState({ state: 'signedIn', user });
        }
      })
      .catch(err => {});
    setAppReady();
  }

  @Input()
  set authState(authState: AuthState) {
    this._authState = authState;
    const { username } = this.userData.getRawValue();
    if (authState.state === 'signedIn') {
      this.userData.controls.username.setValue(
        authState.user ? authState.user.signInUserSession.idToken.payload.email : ''
      );
    } else {
      this.userData.controls.username.setValue(authState.user ? authState.user.email : '');
    }
  }

  onSignIn() {
    this.signingIn.emit(true);
    const { username, password } = this.userData.getRawValue();
    this.amplifyService
      .auth()
      .signIn(username.toLowerCase(), password)
      .then(user => {
        if (user['challengeName'] === 'SMS_MFA' || user['challengeName'] === 'SOFTWARE_TOKEN_MFA') {
          this.amplifyService.setAuthState({ state: 'confirmSignIn', user });
        } else if (user['challengeName'] === 'NEW_PASSWORD_REQUIRED') {
          this.amplifyService.setAuthState({ state: 'requireNewPassword', user });
        } else {
          this.amplifyService.setAuthState({ state: 'signedIn', user });
        }
      })
      .catch(err => {
        this.signingIn.emit(false);
        this._setError('Incorrect username or password');
      });
  }

  onAlertClose() {
    this._setError(null);
  }

  onForgotPassword() {
    const { username } = this.userData.getRawValue();
    const user = username ? { username } : null;
    this.amplifyService.setAuthState({ state: 'forgotPassword', user });
  }

  _setError(err) {
    if (!err) {
      this.errorMessage = null;
      return;
    }

    this.errorMessage = err;
  }

  checkForEnter(e) {
    if (e.keyCode === 13 && this.userData.valid) {
      this.onSignIn();
    }
  }
}
