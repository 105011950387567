<div class="wrapper" data-cy="due-diligence-wrapper.component">
  <div class="header">
    <div class="headerWrapper">
      <h1 class="title" data-cy="due-diligence-wrapper.component.title">{{ title }}</h1>
      <span class="subTitle" data-cy="due-diligence-wrapper.component.subtitle">{{ subTitle }}</span>
    </div>
  </div>
  <div class="body">
    <ng-content select="[due-diligence-body]"></ng-content>
  </div>
  <div class="bottomBtnWrapper">
    <gcv-button
      (clickEvent)="back()"
      [buttonText]="previousText"
      [disabled]="!previousDisabled"
      data-cy="due-diligence-wrapper.component.previous"
      type="tertiary"
    ></gcv-button>
    <gcv-button
      (clickEvent)="btnPressed()"
      [buttonText]="buttonText"
      [type]="disabled ? 'secondary' : 'primary'"
      data-cy="due-diligence-wrapper.next"
    ></gcv-button>
  </div>
</div>
