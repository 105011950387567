import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { GcvCommonModule } from '../../components/common.module';
import { SupportUserLoginComponent } from './support-user-login.component';
import { SUPPORT_USER_LOGIN_ROUTES } from './support-user-login.routes';
import { CanActivateSupportUserLogin } from './support-user-login.can-activate';

import { OrgFilterPipe } from './org-search.pipe';

@NgModule({
  declarations: [SupportUserLoginComponent, OrgFilterPipe],
  imports: [CommonModule, GcvCommonModule, RouterModule.forChild(SUPPORT_USER_LOGIN_ROUTES)],
  providers: [CanActivateSupportUserLogin, OrgFilterPipe],
  exports: [SupportUserLoginComponent, RouterModule],
})
export class SupportUserLoginModule {}
