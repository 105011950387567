<div class="wrapper">
  <div class="header">
    <h3>Method of Transportation Details</h3>
    <span *ngIf="vendorType === 'bank' || vendorType === 'vendor'">{{ vendor.name | titlecase }}</span>
    <span *ngIf="vendorType === 'user'">{{ vendor.firstName }} {{ vendor.lastName }}</span>
  </div>
  <div class="body">
    <div class="logo">
      <svg
        *ngIf="vendorType === 'user'"
        class="icon"
        width="499"
        height="381"
        viewBox="0 0 499 381"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="#fff" d="M0 0h499v381H0z" />
        <path
          d="M378.3 216.8a5.8 5.8 0 0 0-8.4 0l-52.5 53.6-27.3-27.9a5.8 5.8 0 0 0-8.4 0 6.1 6.1 0 0 0 0 8.5l31.5 32.2a5.8 5.8 0 0 0 8.4 0l56.7-57.9a6.1 6.1 0 0 0 0-8.5z"
          fill="#02B45B"
        />
        <path
          d="M158 185l-43.6 14.3a40.5 40.5 0 0 0-17.8 11.2 24.7 24.7 0 0 0-6.6 16.3v66.7"
          stroke="#02B45B"
          stroke-width="15"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M215 165c30.4 0 55-26.9 55-60s-24.6-60-55-60-55 26.9-55 60 24.6 60 55 60zM286.7 307l43.3 28 43.3-28a52.8 52.8 0 0 0 23.4-36l13.3-84.4c-24.2 0-80-21.6-80-21.6s-52.5 21.6-80 21.6l13.3 84.5a52.3 52.3 0 0 0 23.4 36z"
          stroke="#02B45B"
          stroke-width="15"
          stroke-linejoin="round"
        />
      </svg>

      <svg
        *ngIf="vendorType === 'vendor'"
        class="icon"
        width="499"
        height="381"
        viewBox="0 0 499 381"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M447.8 168.4l-12-47.7a7.2 7.2 0 0 0 5.8-7V106a30.3 30.3 0 0 0-30.3-30.3h-54.2V60c0-8.2-6.7-14.9-15-14.9H65.7c-8.2 0-14.9 6.7-14.9 14.9v130.6a7.2 7.2 0 1 0 14.4 0V59.9c0-.3.3-.5.5-.5h276.6c.3 0 .5.2.5.5v130.6a7.2 7.2 0 1 0 14.4 0v-8.2h85c10.4 0 19.3 6.9 22.4 16.4H442c-4 0-7.2 3.2-7.2 7.2v15.3a22.6 22.6 0 0 0 22.5 22.6h8.2v31.7h-18.8a45.7 45.7 0 0 0-86.3 0h-3.4v-54.3a7.2 7.2 0 0 0-14.4 0v54.3H193.2a45.7 45.7 0 0 0-86.2 0H65.6a.5.5 0 0 1-.5-.5v-15.8h31.2a7.2 7.2 0 0 0 0-14.5H27.2a7.2 7.2 0 0 0 0 14.5h23.5V275c0 8.2 6.7 14.9 15 14.9h38.8v.5a45.7 45.7 0 0 0 91.2 0v-.5H358v.5a45.7 45.7 0 0 0 91.3 0v-.5h23.5c4 0 7.2-3.2 7.2-7.2v-76.8a38 38 0 0 0-32.2-37.5zM357.1 90h54.2c8.8 0 15.9 7.1 15.9 15.9v.5H357V90zm0 77.8v-47h64l11.7 47h-75.7zM150 321.6a31.2 31.2 0 1 1 .1-62.5 31.2 31.2 0 0 1 0 62.5zm253.6 0a31.2 31.2 0 1 1 0-62.5 31.2 31.2 0 0 1 0 62.5zm61.9-92.2h-8.2a8.2 8.2 0 0 1-8.1-8.2v-8.1h16.3v16.3z"
          fill="#02B45B"
        />
        <path
          d="M150.1 275.5a14.9 14.9 0 1 0 0 29.8 14.9 14.9 0 0 0 0-29.8zM403.7 275.5a14.9 14.9 0 1 0 0 29.8 14.9 14.9 0 0 0 0-29.8zM319.1 244.8H211.6a7.2 7.2 0 0 0 0 14.4H319a7.2 7.2 0 1 0 0-14.4zM134.8 214H42.6a7.2 7.2 0 1 0 0 14.4h92.2a7.2 7.2 0 0 0 0-14.4zM270.5 116.3a7.2 7.2 0 0 0-10.2 0l-64 64-33.4-33.3a7.2 7.2 0 1 0-10.2 10.2l38.5 38.4a7.2 7.2 0 0 0 10.1 0l69.2-69.2a7.2 7.2 0 0 0 0-10.1z"
          fill="#02B45B"
        />
      </svg>

      <svg
        *ngIf="vendorType === 'bank'"
        class="icon"
        width="499"
        height="381"
        viewBox="0 0 499 381"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M52.2 168.4l12-47.7a7.2 7.2 0 0 1-5.8-7V106a30.3 30.3 0 0 1 30.3-30.3h54.2V60c0-8.2 6.7-14.9 15-14.9h276.5c8.2 0 14.9 6.7 14.9 14.9v130.6a7.2 7.2 0 0 1-14.4 0V59.9c0-.3-.3-.5-.5-.5H157.8c-.3 0-.5.2-.5.5v130.6a7.2 7.2 0 1 1-14.4 0v-8.2H58a23.6 23.6 0 0 0-22.4 16.4H58c4 0 7.2 3.2 7.2 7.2v15.3a22.6 22.6 0 0 1-22.5 22.6h-8.2v31.7h18.8a45.7 45.7 0 0 1 86.3 0h3.4v-54.3a7.2 7.2 0 0 1 14.4 0v54.3h149.5a45.7 45.7 0 0 1 86.2 0h41.4c.2 0 .5-.2.5-.5v-15.8h-31.2a7.2 7.2 0 0 1 0-14.5h69.1a7.2 7.2 0 0 1 0 14.5h-23.5V275c0 8.2-6.7 14.9-15 14.9h-38.8v.5a45.7 45.7 0 0 1-91.2 0v-.5H142v.5a45.7 45.7 0 0 1-91.3 0v-.5H27.2c-4 0-7.2-3.2-7.2-7.2v-76.8a38 38 0 0 1 32.2-37.5zM142.9 90H88.7c-8.8 0-15.9 7.1-15.9 15.9v.5H143V90zm0 77.8v-47H79l-11.7 47h75.7zm207 153.7a31.2 31.2 0 1 0-.1-62.5 31.2 31.2 0 0 0 0 62.5zm-253.6 0a31.2 31.2 0 1 0 0-62.5 31.2 31.2 0 0 0 0 62.5zm-61.9-92.2h8.2c4.5 0 8.1-3.7 8.1-8.2v-8.1H34.4v16.3z"
          fill="#02B45B"
        />
        <path
          d="M349.9 275.5a14.9 14.9 0 1 1 0 29.8 14.9 14.9 0 0 1 0-29.8zM96.3 275.5a14.9 14.9 0 1 1 0 29.8 14.9 14.9 0 0 1 0-29.8zM180.9 244.8h107.5a7.2 7.2 0 1 1 0 14.4H181a7.2 7.2 0 0 1 0-14.4zM365.3 214h92.1a7.2 7.2 0 0 1 0 14.4h-92.1a7.2 7.2 0 0 1 0-14.4z"
          fill="#02B45B"
        />
        <path
          d="M292.8 155.3a22.8 22.8 0 1 1 22.9-22.9M292.8 155.3A22.8 22.8 0 1 1 270 178M292.9 109.6V95M292.9 215.6V201"
          stroke="#02B45B"
          stroke-width="14"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="content">
      <div class="valueWrapper">
        <span class="title">Address</span>
        <p class="value">{{ vendor['street'] ? vendor['street'] : '' }}{{ vendor.address }}</p>
        <!-- prettier-ignore -->
        <p class="value">{{ vendor.city }}, {{ vendor.state }} {{ vendor['zipCode'] ? vendor['zipCode'] : '' }}{{ vendor['zipcode'] ? vendor['zipcode'] : '' }}</p>
      </div>
      <div class="valueWrapper half">
        <span class="title">Phone</span>
        <span class="value">{{ vendor.phone }}</span>
      </div>
      <div *ngIf="vendor['tin']" class="valueWrapper half">
        <span class="title">TIN</span>
        <span class="value">{{ vendor['tin'] }}</span>
        <span (click)="showHelp = false" *ngIf="showHelp" class="defaultHelp">What's This?</span>
        <span *ngIf="!showHelp" class="expandedHelp">
          A Tax Identification Number (TIN) is a 9-digit number used to identify a business entity, and is used to
          maintain banking records. You should check service provider’s documentation or request their TIN directly.
          <span (click)="showHelp = true" class="defaultHelp">Hide</span>
        </span>
      </div>
      <div *ngIf="vendorType === 'user'" class="valueWrapper half">
        <span class="title">Email</span>
        <span class="value">{{ vendor['email'] ? vendor['email'] : '' }}</span>
      </div>
    </div>
  </div>
  <div class="buttonWrapper">
    <gcv-button (clickEvent)="close()" buttonText="Close" type="tertiary"></gcv-button>
  </div>
</div>
