import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Action } from '@gcv/shared';
import { Subscription } from 'rxjs';
import { RoleResolverService } from '../services/role-resolver.service';

/*
Current Set of Roles - 3/1/19
  | 'account_approve'
  | 'account_info_update'
  | 'account_info_view'
  | 'deposit_approve'
  | 'deposit_create'
  | 'deposit_view'
  | 'deposit_view_all'
  | 'fincen_report_approve'
  | 'fincen_report_prepare'
  | 'fincen_report_view'
  | 'personal_user_details_update'
  | 'personal_user_details_view'
  | 'organizational_details_update'
  | 'organizational_details_view'
  | 'sales_file_upload'
  | 'sales_file_view'
  | 'user_add'
  | 'user_remove';
*/

@Directive({
  selector: '[appHasRole]',
})
export class RoleDirective implements OnInit, OnDestroy {
  private permissions: Action[];
  private altCondition = true;
  private elseTemp: TemplateRef<any>;
  private roleResolver$: Subscription;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private roleResolver: RoleResolverService
  ) {}

  ngOnInit() {
    this.setupStream();
  }

  ngOnDestroy() {
    if (this.roleResolver$) {
      this.roleResolver$.unsubscribe();
    }
  }

  setupStream() {
    this.roleResolver$ = this.roleResolver.updateUI.subscribe(val => {
      if (val) {
        this.updateView();
      }
    });
  }

  @Input()
  set appHasRole(val) {
    this.permissions = val.permissions;
    if (val.hasOwnProperty('altCondition')) {
      this.altCondition = val.altCondition;
    }

    if (val.hasOwnProperty('else')) {
      this.elseTemp = val.else;
    }
  }

  private updateView() {
    this.viewContainer.clear();
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.elseTemp) {
      this.viewContainer.createEmbeddedView(this.elseTemp);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    return this.roleResolver.checkPermission(this.permissions) && this.altCondition;
  }
}
