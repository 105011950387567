import { Component, Input } from '@angular/core';
import { S3FileManagementService } from '../../services/s3FileManagement.service';
import { MatDialog } from '@angular/material/dialog';
import { ReviewAccountDocumentComponent } from '../../app/bank/accounts/review-account-document/review-account-document.component';
import { ReviewDocumentCommentsComponent } from '@user-interface/gcv-ui';
import { ReviewMultipleDocumentsModalComponent } from '../review-multiple-documents-modal/review-multiple-documents-modal.component';

@Component({
  selector: 'app-view-document-button',
  templateUrl: './view-document-button.component.html',
  styleUrls: ['./view-document-button.component.scss'],
})
export class ViewDocumentButtonComponent {
  @Input() documentType: string;
  @Input() documentName;
  @Input() documentStatus: string;
  @Input() docDescription: string;
  @Input() documentIds: string;
  @Input() multipleDocuments: boolean;
  @Input() doc;
  @Input() docKey;
  @Input() s3Key;
  @Input() doNotHaveComment: string;
  @Input() notProvided: boolean;
  @Input() dispensaryId: any;
  @Input() cognitoId: any;
  @Input() type;
  @Input() isGcv = false;

  constructor(private s3FileManagement: S3FileManagementService, private dialog: MatDialog) {}

  async fetchDocuments(docIds) {
    try {
      return await Promise.all(
        docIds.map(id => {
          if (this.documentType && this.documentType === 'user') {
            return this.s3FileManagement.viewUserDocument({ orgId: this.dispensaryId, userType: 'gcv' }, id);
          } else {
            return this.s3FileManagement.viewDocument({ orgId: this.dispensaryId, userType: 'gcv' }, id);
          }
        })
      );
    } catch (e) {
      console.log(e);
    }
  }

  async view() {
    if (this.notProvided) {
      this.dialog.open(ReviewDocumentCommentsComponent, {
        data: {
          description: '',
          comment: this.doNotHaveComment,
          document: this.documentName,
          status: this.documentStatus,
          isDispensary: false,
        },
        maxWidth: '800px',
        width: '800px',
      });
    } else if (this.multipleDocuments) {
      if (this.documentIds && this.documentIds.length > 0) {
        try {
          const data = {
            documentData: [],
            documentName: this.documentName,
            type: 'application/pdf',
            status: this.documentStatus,
          };

          data.documentData = await this.fetchDocuments(this.documentIds);

          this.dialog.open(ReviewMultipleDocumentsModalComponent, {
            data,
            maxWidth: '1280px',
            width: '1280px',
          });
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      const data = {};
      let s3Key = this.s3Key ? this.s3Key : '';
      if (!this.s3Key) {
        if (this.isGcv) {
          s3Key = `organizations/dispensaries/${this.dispensaryId}/documents/master/${this.docKey.key}`;
        } else {
          s3Key = `organizations/dispensaries/${this.dispensaryId}/documents/${this.cognitoId}/${this.docKey.key}`;
        }
      }
      this.s3FileManagement.get(s3Key).then(url => {
        data['s3KeyUrl'] = url;
        data['documentName'] = this.documentName;
        data['status'] = 'reviewed';
        const matDialog = this.dialog.open(ReviewAccountDocumentComponent, {
          data,
          maxWidth: '1280px',
          width: '1280px',
        });
        matDialog.afterClosed().subscribe(() => {});
      });
    }
  }
}
