<button [matMenuTriggerFor]="menu" class="timeFilterButton" data-cy="timefilter.component">
  {{ totalSalesMap[selectedRange] | titlecase }}
  <svg height="7" width="13" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 0a1 1 0 0 0-1 0H1a1 1 0 0 0-1 0 1 1 0 0 0 0 1l6 6a1 1 0 0 0 1 0l6-6a1 1 0 0 0 0-1z" fill="#3a3c45" />
  </svg>
</button>
<mat-menu #menu="matMenu" data-cy="timefilter.component.matMenu">
  <button
    (click)="dateTimeRangeChange(item)"
    *ngFor="let item of items"
    [attr.data-cy]="'timefilter.component.' + item"
    [value]="item"
    class="menuOption"
    mat-menu-item
  >
    {{ item }}
  </button>
</mat-menu>
