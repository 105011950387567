import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { Router } from '@angular/router';
import { DEFAULT_TIME_FILTER, LoggerService, UtilsService } from '@user-interface/gcv-ui';

/*
  This service provides communication for the Bank App family of components
  See: https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
*/

@Injectable({
  providedIn: 'root',
})
export class BankAppFamilyService implements OnDestroy {
  public view = new Subject();
  public timeFilter = new BehaviorSubject<string>(DEFAULT_TIME_FILTER);
  private previousRoute: any;

  // Streams
  private timeFilter$: Subscription;
  private view$: Subscription;

  constructor(private router: Router, private logger: LoggerService, private utils: UtilsService) {
    this.setupStream();
  }

  setupStream() {
    this.view$ = this.view.subscribe((data: any) => {
      if (data.hasOwnProperty('view')) {
        this.router.navigate([`${data.view}`]);
      }
    });
  }

  ngOnDestroy() {
    if (this.view$) {
      this.view$.unsubscribe();
    }
    if (this.timeFilter$) {
      this.timeFilter$.unsubscribe();
    }
  }

  setPreviousUrl(url) {
    this.previousRoute = url;
  }

  setDashboardTimeFilter(timePeriod) {
    const newFilter = this.utils.getNewTimeFilter(timePeriod);
    this.timeFilter.next(newFilter);
  }
}
