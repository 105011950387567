<gcv-card data-cy="verified-users-transportation">
  <div card-body class="innerWrapper">
    <div class="header">
      <h4 data-cy="title">{{ title }}</h4>
      <span data-cy="subtext">These are users who belong to a group with the Method of Transportation Role.</span
      ><span data-cy="manage-groups" *ngIf="dispensary">
        You can
        <a
          (click)="handleManageGroups()"
          [attr.data-cy]="'verified-users-transporation.component.manageGroups'"
          id="link"
        >
          manage your groups here</a
        >.
      </span>
    </div>
    <div *ngIf="dispensary; else bank" class="mainWrapper">
      <div
        *ngFor="let vendor of vendors; let i = index"
        data-cy="vendor-user"
        [ngClass]="i === vendors.length - 1 ? 'lastItem' : 'item'"
        class="userWrapper"
      >
        <span *ngIf="vendor.active" class="active" data-cy="active-vendor-name">{{ vendor.name }}</span>
        <span *ngIf="!vendor.active" class="inactive" data-cy="inactive-vendor-name">{{ vendor.name }}</span>
        <svg
          (click)="handleFavorite(vendor, i)"
          *ngIf="vendor.active && vendor.favorite"
          class="starImg imgActive"
          fill="none"
          height="18"
          width="17"
          xmlns="http://www.w3.org/2000/svg"
          data-cy="favorite-vendor-image"
        >
          <path
            d="M7.6 1a1 1 0 0 1 1.8 0l2 4.3 4.7.7a1 1 0 0 1 .5 1.7L13.2 11 14 16a1 1 0 0 1-1.4 1l-4.1-2.2-4 2.2A1 1 0 0 1 3 16l.8-4.9L.4 7.7A1 1 0 0 1 .9 6l4.7-.7 2-4.4z"
            fill="#02B45B"
          />
        </svg>
        <svg
          (click)="handleFavorite(vendor, i)"
          *ngIf="vendor.active && !vendor.favorite"
          class="starImg imgActive"
          fill="none"
          height="18"
          width="17"
          xmlns="http://www.w3.org/2000/svg"
          data-cy="active-vendor-image"
        >
          <path
            d="M10.9 5.5l.1.3h.3l4.7.8c.3 0 .4.4.2.6l-3.4 3.5-.2.2v.3l.8 4.9c.1.3-.2.5-.5.4l-4.1-2.3-.3-.1-.3.1-4 2.3c-.4.1-.7-.1-.6-.4l.7-4.9V11l-.1-.2L.8 7.2a.4.4 0 0 1 .2-.6l4.7-.8H6l.1-.3 2-4.3c.2-.3.6-.3.8 0l2 4.3z"
            stroke="#DBE1ED"
            stroke-width="1.2"
          />
        </svg>

        <svg
          *ngIf="!vendor.active && !vendor.favorite"
          class="starImg imgInactive"
          fill="none"
          height="18"
          width="17"
          xmlns="http://www.w3.org/2000/svg"
          data-cy="inactive-vendor-image"
        >
          <path
            d="M11 5.5l.1.2h.3l4.6.8c.4 0 .6.5.3.8l-3.4 3.5-.2.2v.2l.8 4.8c.1.5-.3.8-.7.6l-4-2.3-.3-.1-.2.1-4.1 2.3a.5.5 0 0 1-.7-.6l.7-4.8v-.4L.6 7.3a.5.5 0 0 1 .3-.8l4.6-.8H6l.1-.2L8 1c.2-.3.8-.3 1 0l2 4.4z"
            stroke="#F0F2F9"
          />
        </svg>
      </div>
    </div>
  </div>
</gcv-card>

<ng-template #bank>
  <div class="mainWrapper">
    <div
      *ngFor="let vendor of vendors; let i = index"
      data-cy="vendor-user"
      [ngClass]="i === vendors.length - 1 ? 'lastItem' : 'item'"
      class="userWrapper"
    >
      <span *ngIf="vendor.active" class="active" data-cy="active-vendor-name">{{ vendor.name }}</span>
      <span
        *ngIf="!vendor.active"
        class="inactive"
        data-cy="inactive-vendor-name"
        matTooltip="This user has not been verified and is not active."
        >{{ vendor.name }}</span
      >
    </div>
  </div>
</ng-template>
