import { Dispensary, EventTypes, PosConfig, PosOrganizationLocation, PosType, User } from '@gcv/shared';
import { Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../api';
import { BackButtonIcon } from '../../icons/BackButtonIcon';
import { GcvButton, GcvDrawer, GcvStatusIndicator } from '../../lib';
import { $grey2, formatISOToDateAtTime } from '../../util';
import { EnterPosConfig } from './EnterPosConfig';
import { EnterPosLocation } from './EnterPosLocation';
import {
  ButtonContainer,
  Card,
  CardRowContainer,
  DrawerContent,
  DrawerHeader,
  DrawerText,
  PosIcon,
  SubText,
  Title,
  VerticalCenter,
} from './styles';

export interface Pos {
  name: string;
  wholesaleName?: string;
  displayName?: string;
  isPending?: boolean;
  isConnected?: boolean;
}

export const posTypes: Pos[] = [
  {
    name: PosType.Portal42,
    displayName: 'Portal42',
  },
  {
    displayName: 'Leaf Logix',
    wholesaleName: PosType.LeafLogixWholesale,
    name: PosType.LeafLogix,
  },
  {
    displayName: 'Cova',
    name: PosType.Cova,
  },
  {
    name: PosType.MjFreeway,
    wholesaleName: PosType.MjFreewayWholesale,
    displayName: 'MJ Freeway',
  },
  {
    displayName: 'Treez',
    name: PosType.TreezIO,
  },
  {
    displayName: 'Flowhub',
    name: PosType.Flowhub,
  },
  {
    displayName: 'BioTrack',
    wholesaleName: PosType.BioTrackSqlWholesale,
    name: PosType.BioTrackSql,
  },
  {
    displayName: 'Greenbits',
    name: PosType.GreenBits,
  },
  {
    displayName: 'Blaze',
    name: PosType.Blaze,
  },
  {
    displayName: 'Quickbooks',
    name: PosType.Quickbooks,
  },
  {
    displayName: 'Growflow',
    name: PosType.GrowFlow,
  },
  {
    displayName: 'Sage Intacct',
    name: PosType.Intacct,
  },
];

const getFetchStrategy = (posType: PosType) => {
  return 'automatic';
};

const getHasConfiguredLocation = (posConfig: PosConfig<any>) => {
  return getHasLocationStep(posConfig.posName) ? !!posConfig.location_configured : true;
};
const getHasLocationStep = (posType: PosType) => {
  return (
    posType === PosType.GreenBits ||
    posType === PosType.GrowFlow ||
    posType === PosType.Flowhub ||
    posType === PosType.BioTrackSql ||
    posType === PosType.BioTrackSqlWholesale ||
    posType === PosType.Quickbooks ||
    posType === PosType.Intacct
  );
};

interface Props {
  dispensary: Dispensary;
  userMap: { [id: string]: User };
  emitData: (type) => void;
}

enum ConfigurationStep {
  EnterPosConfig = 'enter_pos_config',
  EnterPosLocation = 'enter_pos_location',
}

export const PosConfigs = ({ dispensary, emitData, userMap }: Props) => {
  const apiClient = api();

  const form = useForm({ mode: 'onChange' });
  const [error, setError] = useState<string>(null);
  const [currentStep, setCurrentStep] = useState<ConfigurationStep>(ConfigurationStep.EnterPosConfig);
  const [locations, setLocations] = useState<PosOrganizationLocation[]>([]);
  const [snackBar, setSnackBar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerPosType, setDrawerPosType] = useState<Pos>({
    name: '',
    displayName: '',
    isConnected: false,
    isPending: false,
  });
  const [eventData, setEventData] = useState(null);

  const reRoutedFromQuickBooks = window.location.href.includes('realmId');

  const hasLocationStep = getHasLocationStep(drawerPosType.name as PosType);

  const hasConfiguredPos =
    dispensary &&
    dispensary.posConfig &&
    dispensary.posConfig.posName &&
    dispensary.posConfig.posName !== PosType.Unsupported &&
    dispensary.posConfig.posName !== PosType.GcvCsv_Quickbooks &&
    dispensary.posConfig.posName !== PosType.GcvFaker_GcvCsv &&
    getHasConfiguredLocation(dispensary.posConfig);

  useEffect(() => {
    if (drawerPosType.isPending || drawerPosType.isConnected) {
      getPosInformation();
    }
  }, [drawerPosType]);

  useEffect(() => {
    if (reRoutedFromQuickBooks) {
      apiClient.quickbooks
        .getQuickbooksAccessToken(
          dispensary.id,
          {
            uri: window.location.href,
          },
          () => {}
        )
        .then(res => {
          if (res.accessToken.access_token) {
            const posConfig = {
              posName: PosType.Quickbooks,
              auth: {},
              fetch: { fetchStrategy: getFetchStrategy(PosType.Quickbooks) },
              status: 'Connected',
            };
            putPosConfig(posConfig, () => {});
          }
        })
        .catch(e => {
          alert(
            'Error integrating the POS System, please contact support@greencheckverified.com for further assistance'
          );
          closeDrawer();
        });
    }
  }, []);

  const getPosInformation = async () => {
    await apiClient.dispensaries
      .getEventByType(dispensary.id, EventTypes.UpdatedPosConfig, () => {})
      .then(data => {
        const latestConnection = data.reduce((acc, event) => (acc.date_created > event.date_created ? acc : event));
        setEventData(latestConnection);
      })
      .catch(e => {});
  };

  const selectPos = (posName?: Pos) => {
    setDrawerPosType(posName);
    openDrawer();
  };

  const openDrawer = (step?) => {
    setDrawerOpen(true);
    if (step) {
      setCurrentStep(step);
    }
  };
  const closeDrawer = () => {
    setCurrentStep(ConfigurationStep.EnterPosConfig);
    setError('');
    setDrawerOpen(false);
  };

  const renderPosTypes = () =>
    posTypes.map(({ name, displayName, wholesaleName }) => {
      const hasConfiguredLocation = getHasConfiguredLocation(dispensary.posConfig);
      const isPending =
        dispensary &&
        dispensary.posConfig &&
        (dispensary.posConfig.posName === name || dispensary.posConfig.posName === wholesaleName) &&
        hasConfiguredLocation;
      const isConnected = isPending && dispensary.posConfig.status === 'Connected';

      // if dispensary is wholesale, use the wholesale pos name if exists
      const integrationToUse = dispensary.business_type === 'wholesale' && wholesaleName ? wholesaleName : name;

      const isCardActive = () => {
        if (hasConfiguredPos) {
          if (isConnected || isPending) {
            return true;
          }
        } else {
          return true;
        }
      };

      return (
        <Card
          onClick={() =>
            isCardActive() ? selectPos({ name: integrationToUse, displayName, isConnected, isPending }) : null
          }
          key={name}
          style={{ cursor: isCardActive() ? 'pointer' : 'auto' }}
        >
          <PosIcon
            src={`../../assets/${name}Icon.png`}
            style={{ opacity: !hasConfiguredPos || isPending || isConnected ? 1 : 0.6 }}
          ></PosIcon>
          <GcvStatusIndicator
            text={isConnected ? 'Connected' : isPending ? 'Pending' : 'Not Connected'}
            status={isConnected ? 'good' : isPending ? 'warning' : 'none'}
            style={{
              width: 'fit-content',
              margin: '10px auto',
              opacity: !hasConfiguredPos || isPending || isConnected ? 1 : 0.3,
            }}
          />
        </Card>
      );
    });

  const onSubmitConfiguration = data => {
    const posConfig = {
      posName: PosType[drawerPosType.name],
      auth: data,
      fetch: { fetchStrategy: getFetchStrategy(PosType[drawerPosType.name]) },
      status: 'Not Connected',
    };

    if (drawerPosType.name === PosType.TreezIO) {
      posConfig.auth.dispensaryId = dispensary.id;
    }

    putPosConfig(posConfig, delayResetDispensaryData);
  };
  const onSubmitLocation = data => {
    putPosLocation(data?.location_id?.value);
  };
  const delayResetDispensaryData = () => setTimeout(() => emitData('getDispensary'), 1500); //delay the refresh of data so that the snackbar appears and drawer closes without interruption
  const putPosConfig = async (data, callback) => {
    setLoading(true);
    await apiClient.dispensaries
      .setPosConfig(dispensary.id, { posConfig: data }, () => {})
      .then(() => {
        setError(''); // clear out error if put call is successful
        if (getHasLocationStep(data.posName)) {
          getPosLocations(data.posName);
          if (data.posName === PosType.Quickbooks) {
            openDrawer(ConfigurationStep.EnterPosLocation);
          }
        } else {
          setSnackBar({ message: 'Successfuly Integrated!' });
          closeDrawer();
          callback();
        }
      })
      .catch(e => {
        setError('We were unable to connect using the credentials provided. Please check them and try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getPosLocations = async (posName: PosType) => {
    try {
      const res = await apiClient.dispensaries.getPosLocations(dispensary.id, () => {});
      if (res.length > 1) {
        setCurrentStep(ConfigurationStep.EnterPosLocation);
        setLocations(res);
      } else if (res.length === 1) {
        putPosLocation(res[0].id);
      } else {
        if (posName === PosType.Quickbooks) {
          putPosLocation('');
        } else {
          setError('We were unable to connect using the credentials provided. Please check them and try again.');
        }
      }
    } catch (e) {
      setError('We were unable to connect using the credentials provided. Please check them and try again.');
    }
  };
  const putPosLocation = async selectedLocationId => {
    setLoading(true);
    await apiClient.dispensaries
      .putPosLocation(dispensary.id, selectedLocationId, () => {})
      .then(() => {
        if (reRoutedFromQuickBooks) {
          emitData('reroute');
        } else {
          delayResetDispensaryData();
        }
        setSnackBar({ message: 'Successfuly Integrated!' });
        closeDrawer();
      })
      .catch(e => {
        setError('We were unable to connect using the credentials provided. Please check them and try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const integrateQuickbooks = async () => {
    setLoading(true);
    await apiClient.quickbooks
      .getQuickbooksAuthUri(() => {})
      .then(res => {
        window.open(res.authUri, '_self');
      })
      .catch(e => {
        setError('We were unable to connect using the credentials provided. Please check them and try again.');
      })
      .finally(() => {
        setLoading(false);
        closeDrawer();
      });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!snackBar}
        autoHideDuration={4000}
        onClose={() => setSnackBar(null)}
        message={snackBar ? snackBar.message : ''}
      />
      <SubText>
        Connecting your sales tracking system to Green Check allows you to automatically upload your sales activity.
      </SubText>
      <CardRowContainer>{renderPosTypes()}</CardRowContainer>
      <GcvDrawer onClose={() => closeDrawer()} open={drawerOpen} style={{ width: '482px', overflow: 'hidden' }}>
        <DrawerHeader>
          <VerticalCenter>
            <BackButtonIcon onClick={() => closeDrawer()}></BackButtonIcon>
          </VerticalCenter>
          <VerticalCenter>
            <Title>
              {drawerPosType.displayName} {!hasConfiguredPos ? 'Setup' : 'Connection'}
            </Title>
          </VerticalCenter>
        </DrawerHeader>
        {!hasConfiguredPos ? (
          <>
            {currentStep === ConfigurationStep.EnterPosConfig ? (
              <EnterPosConfig
                drawerPosType={drawerPosType}
                form={form}
                hasLocationStep={hasLocationStep}
                loading={loading}
                onSubmit={onSubmitConfiguration}
                integrateQuickbooks={integrateQuickbooks}
                error={error}
              />
            ) : currentStep === ConfigurationStep.EnterPosLocation ? (
              <EnterPosLocation
                drawerPosType={drawerPosType}
                form={form}
                loading={loading}
                onSubmit={onSubmitLocation}
                onBack={() => setCurrentStep(ConfigurationStep.EnterPosConfig)}
                locations={locations}
                currentAccount={dispensary.name}
                error={error}
              />
            ) : null}
          </>
        ) : (
          <DrawerContent>
            <DrawerText style={{ color: $grey2, marginBottom: '0' }}>Connected By</DrawerText>
            <DrawerText>
              {userMap && eventData && userMap[eventData.user_id]
                ? userMap[eventData.user_id].firstName + ' ' + userMap[eventData.user_id].lastName
                : ''}
            </DrawerText>
            <DrawerText style={{ color: $grey2, marginBottom: '0' }}>Connected On</DrawerText>
            <DrawerText>{eventData ? formatISOToDateAtTime(eventData.date_created) : ''}</DrawerText>
            <DrawerText style={{ marginTop: '30px', textAlign: 'center' }}>
              To disconnect this integration, please email{' '}
              <a rel="noopener noreferrer" target="_blank" href="mailto:support@greencheckverified.com">
                support@greencheckverified.com
              </a>
              .
            </DrawerText>
            {drawerPosType.name === PosType.Quickbooks ? (
              <ButtonContainer style={{ justifyContent: 'center' }}>
                <GcvButton primary={true} onClick={integrateQuickbooks}>
                  Refresh Quickbooks Token
                </GcvButton>
              </ButtonContainer>
            ) : null}
          </DrawerContent>
        )}
      </GcvDrawer>
      <div style={{ marginTop: '40px' }}>
        Don't see your sales tracking system? Contact us at{' '}
        <a rel="noopener noreferrer" target="_blank" href="mailto:support@greencheckverified.com">
          support@greencheckverified.com
        </a>
      </div>
    </>
  );
};
