import React from 'react';
import { RouteObject } from '../../util/types';
import { Fincen } from './Fincen.page';
import { NewReportPage } from './New-report.page';

export const routes: RouteObject[] = [
  {
    path: '/secure/bank/fincen/create/:reportType',
    render: props => <NewReportPage {...props}></NewReportPage>,
  },
  {
    path: '/secure/bank/fincen/:section',
    render: props => <Fincen {...props}></Fincen>,
  },
];
