import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'gcv-editable-card-hours',
  templateUrl: './editable-card-hours.component.html',
  styleUrls: ['./editable-card-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableCardHoursComponent implements OnInit {
  editable = false;
  @Output()
  saveContent = new EventEmitter();
  @Input()
  customStyle: any;
  @Input()
  permissions = [''];
  @Input()
  showEdit = false;
  @Input()
  data: FormGroup;
  @Input()
  title: string;
  @Input()
  formKey: any;
  @Input()
  customStyleEdit: any;
  @Output()
  editEmitter = new EventEmitter<boolean>();
  tempData: any;
  originalValues: any;

  constructor() {}

  ngOnInit() {
    this.originalValues = this.data.value;

    this.resetFormGroup();
  }

  edit() {
    this.editable = true;
    this.editEmitter.emit(this.editable);
  }

  cancel() {
    this.editable = false;
    this.resetFormGroup();
    this.editEmitter.emit(this.editable);
  }

  resetFormGroup() {
    const data = this.originalValues;

    this.tempData = this.createFormGroup(data);

    this.data = this.tempData;
  }

  createFormGroup(rawHoursData): FormGroup {
    const controls = {};

    for (const key in rawHoursData) {
      if (key) {
        controls[key] = new FormGroup({
          isOpen: new FormControl(rawHoursData[key] && rawHoursData[key].isOpen ? rawHoursData[key].isOpen : false),
          openHour: new FormControl(
            rawHoursData[key] && rawHoursData[key].openHour ? rawHoursData[key].openHour : '09:30',
            [Validators.required]
          ),
          closeHour: new FormControl(
            rawHoursData[key] && rawHoursData[key].closeHour ? rawHoursData[key].closeHour : '17:30',
            [Validators.required]
          ),
        });
      }
    }

    return new FormGroup(controls);
  }

  save() {
    for (const key in this.tempData) {
      if (key) {
        this.data = this.tempData;
        this.originalValues = this.tempData.getRawValue();
      }
    }
    const data = { businessHours: this.data.getRawValue() };

    this.setMissingHoursToDefault(data);

    this.saveContent.emit(data.businessHours);
    this.editable = false;
    this.editEmitter.emit(this.editable);
  }

  setMissingHoursToDefault(data: { businessHours: { [day: string]: { openHour: string; closeHour: string } } }) {
    Object.values(data.businessHours).forEach(hours => {
      if (!hours.openHour) {
        hours.openHour = '09:00';
      }
      if (!hours.closeHour) {
        hours.closeHour = '17:00';
      }
    });
  }

  handleInput(e) {
    this.tempData.controls[e.property].setValue(e.value);
  }

  selectionChanged(e, property) {
    this.tempData[property] = e.value[0];
  }

  isDisabled() {
    return !this.data.getRawValue();
  }

  timeInputHandled(e: any, property: any) {
    this.tempData.controls[property].controls[e.property].setValue(e.value);
  }

  toggleDay(dayName) {
    this.tempData.controls[dayName].controls['isOpen'].setValue(
      !this.tempData.controls[dayName].controls['isOpen'].value
    );
  }
}
