<div class="createGroup" data-cy="createGroup.component">
  <div class="header">
    <h3>{{ title }}</h3>
  </div>
  <div class="contentWrapper">
    <span>
      {{ subTitle }}
    </span>
  </div>
  <div class="btnWrapper">
    <gcv-button
      (clickEvent)="handleCreateGroup()"
      [buttonText]="btnText"
      data-cy="createGroup.component.createGroup"
      type="pill primary"
    ></gcv-button>
  </div>
</div>
