import React, { useState } from 'react';
import { GcvButton, GcvModal } from '../../../lib';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const Buttons = styled.div`
  width: 60%;
  margin: 50px auto 20px auto;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  emitData: (data) => void;
  bankId: string;
}

export const CreateReportButton = ({ emitData, ...props }: Props) => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const completeRequest = (type: 'sar' | 'ctr') => {
    history.push(`/secure/bank/fincen/create/${type}`);
  };

  return (
    <>
      <GcvButton onClick={toggleModal} primary={true} style={{ width: '12rem' }}>
        Create New Report
      </GcvButton>

      <GcvModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="What type of report do you want to create?"
        backButton={
          <GcvButton tertiary={true} onClick={toggleModal}>
            Cancel
          </GcvButton>
        }
      >
        <Buttons>
          <GcvButton onClick={() => completeRequest('sar')} primary={true}>
            SAR
          </GcvButton>
          <GcvButton onClick={() => completeRequest('ctr')} primary={true}>
            CTR
          </GcvButton>
        </Buttons>
      </GcvModal>
    </>
  );
};
