<div>
  <div *ngIf="showWarningLogo" class="logo">
    <svg fill="none" height="42" viewBox="0 0 48 42" width="48" xmlns="http://www.w3.org/2000/svg">
      <path
        clip-rule="evenodd"
        d="M2.13501 38.6882L23.607 1.49781C23.99 0.834063 24.9484 0.834063 25.332 1.49781L46.8039 38.6882C47.187 39.3519 46.708 40.1823 45.9409 40.1823H2.99751C2.2309 40.1823 1.75143 39.3519 2.13501 38.6882Z"
        fill="white"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M2.13501 38.6882L23.607 1.49781C23.99 0.834063 24.9484 0.834063 25.332 1.49781L46.8039 38.6882C47.187 39.3519 46.708 40.1823 45.9409 40.1823H2.99751C2.2309 40.1823 1.75143 39.3519 2.13501 38.6882Z"
        fill-rule="evenodd"
        stroke="#F5A623"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        clip-rule="evenodd"
        d="M23 27.108V13.425C23 12.638 23.638 12 24.425 12C25.212 12 25.85 12.638 25.85 13.425V27.108C25.85 27.895 25.212 28.533 24.425 28.533C23.638 28.533 23 27.895 23 27.108Z"
        fill="#F5A623"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M23 32.3463C23 31.2715 23.8716 30.3999 24.9464 30.3999C26.0213 30.3999 26.8929 31.2715 26.8929 32.3463C26.8929 33.4212 26.0213 34.2928 24.9464 34.2928C23.8716 34.2928 23 33.4212 23 32.3463Z"
        fill="#F5A623"
        fill-rule="evenodd"
      />
    </svg>
  </div>
  <div>
    <h3>{{ title }}</h3>
  </div>
  <div>
    <p>{{ message }}</p>
  </div>
  <div *ngIf="html" [innerHTML]="html"></div>
  <div *ngIf="image" style="display: flex; justify-content: center;">
    <img src="{{ image.src }}" style="{{ image.style }}" />
  </div>
  <div [class.centered]="!(firstButton.display && secondButton.display)" class="btnWrapper">
    <gcv-button
      (clickEvent)="close()"
      *ngIf="firstButton.display"
      [attr.data-cy]="'confirmDialog.component.firstBtn'"
      [buttonText]="firstButton.text"
      [type]="firstButton.type"
    ></gcv-button>
    <gcv-button
      (clickEvent)="approve()"
      *ngIf="secondButton.display"
      [attr.data-cy]="'confirmDialog.component.secondBtn'"
      [buttonText]="secondButton.text"
      [type]="secondButton.type"
    ></gcv-button>
  </div>
</div>
