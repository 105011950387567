import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

@Component({
  selector: 'gcv-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeoutComponent implements OnInit {
  idleState = false;
  countdown = 5;
  timedOut = false;
  height;
  lastPing?: Date = null;
  @Input() idletime = 5;
  @Input() timeout = 5;
  @Output() timeoutEmitter = new EventEmitter();

  constructor(private idle: Idle, private change: ChangeDetectorRef) {
    this.height = window.outerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = window.outerHeight;
  }

  ngOnInit() {
    this.idle.setIdle(this.idletime);
    this.idle.setTimeout(this.timeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = true;
      this.change.detectChanges();
    });

    this.idle.onIdleEnd.subscribe(() => {
      if (!this.timedOut) {
        this.idleState = false;
        this.change.detectChanges();
      }
    });

    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.timeoutEmitter.emit();
      this.change.detectChanges();
    });

    this.idle.onTimeoutWarning.subscribe(countdown => {
      this.countdown = countdown;
      this.change.detectChanges();
    });

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = false;
    this.timedOut = false;
  }

  dismiss() {
    this.reset();
  }
}
