import React from 'react';

export const InfoIcon = ({ height = 16, width = 16, style = {} }) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.95 2.75H6.05V3.85H4.95V2.75ZM4.95 4.95H6.05V8.25H4.95V4.95ZM5.5 0C2.464 0 0 2.464 0 5.5C0 8.536 2.464 11 5.5 11C8.536 11 11 8.536 11 5.5C11 2.464 8.536 0 5.5 0ZM5.5 9.9C3.0745 9.9 1.1 7.9255 1.1 5.5C1.1 3.0745 3.0745 1.1 5.5 1.1C7.9255 1.1 9.9 3.0745 9.9 5.5C9.9 7.9255 7.9255 9.9 5.5 9.9Z"
      fill="#828599"
    />
  </svg>
);
