<div class="dashboardContainer">
  <div class="mainContent">
    <ng-content select="[content]"></ng-content>
  </div>

  <div *ngIf="data" [attr.data-cy]="'dashboard-wrapper.component.detailContainer'" class="detailContainer sales card">
    <div class="detailHeaderWrapper">
      <span>Summary</span>
    </div>
    <div class="sectionWrapper">
      <div class="sectionSubHeader"><span>Sales</span></div>
      <div class="sectionContent">
        <div class="sectionContentSubWrapper">
          <div>
            <span [attr.data-cy]="'dashboard-wrapper.component.totalSaleAmount'" class="totalSaleAmount">{{
              data.totalSales / 100 | currency
            }}</span>
          </div>
        </div>
        <div class="sectionContentSubWrapper">
          <div>
            <span [attr.data-cy]="'dashboard-wrapper.component.verifiedSales'" class="contentSubText">{{
              data.verifiedSales / 100 | currency
            }}</span>
            <span [attr.data-cy]="'dashboard-wrapper.component.verifiedSalesPercent'" class="contentPercentText"
              >({{ data.verifiedSalesPercent | number: '1.0-0' }} %)</span
            >
          </div>
          <div>
            <div class="verifiedIcon"></div>
            <span class="summaryLabel">Verified</span>
          </div>
        </div>
        <div class="sectionContentSubWrapper">
          <div>
            <span [attr.data-cy]="'dashboard-wrapper.component.unverifiedSales'" class="contentSubText">{{
              data.unverifiedSales / 100 | currency
            }}</span>
            <span [attr.data-cy]="'dashboard-wrapper.component.unverifiedSalesPercent'" class="contentPercentText"
              >({{ data.unverifiedSalesPercent | number: '1.0-0' }} %)</span
            >
          </div>
          <div>
            <div class="unverifiedIcon"></div>
            <span class="summaryLabel">Unverified</span>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionWrapper">
      <div class="sectionSubHeader"><span>Purchases</span></div>
      <div class="sectionContent">
        <div class="sectionContentSubWrapper">
          <span [attr.data-cy]="'dashboard-wrapper.component.totalPurchases'" class="contentSubText">{{
            data.totalPurchases
          }}</span>
        </div>
        <div class="sectionContentSubWrapper">
          <div>
            <span [attr.data-cy]="'dashboard-wrapper.component.verifiedPurchases'" class="contentSubText">{{
              data.verifiedPurchases
            }}</span>
            <span [attr.data-cy]="'dashboard-wrapper.component.verifiedPurchasesPercent'" class="contentPercentText"
              >({{ data.verifiedSalesPercent | number: '1.0-0' }} %)</span
            >
          </div>
          <div>
            <div class="verifiedIcon"></div>
            <span class="summaryLabel">Verified</span>
          </div>
        </div>
        <div class="sectionContentSubWrapper">
          <div>
            <span [attr.data-cy]="'dashboard-wrapper.component.unverifiedPurchases'" class="contentSubText">{{
              data.unverifiedPurchases
            }}</span>
            <span [attr.data-cy]="'dashboard-wrapper.component.unverifiedSalesPercent'" class="contentPercentText"
              >({{ data.unverifiedSalesPercent | number: '1.0-0' }} %)</span
            >
          </div>
          <div>
            <div class="unverifiedIcon"></div>
            <span class="summaryLabel">Unverified</span>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionWrapper">
      <div class="sectionSubHeader"><span>Deposits</span></div>
      <div class="sectionContent">
        <div class="sectionContentSubWrapper">
          <div>
            <span [attr.data-cy]="'dashboard-wrapper.component.acceptedDeposits'" class="contentSubText">{{
              data.acceptedDeposits / 100 | currency
            }}</span>
          </div>
          <div><span class="summaryLabel">Accepted</span></div>
        </div>
        <div class="sectionContentSubWrapper">
          <div>
            <span [attr.data-cy]="'dashboard-wrapper.component.pendingDeposits'" class="contentSubText">{{
              data.pendingDeposits / 100 | currency
            }}</span>
          </div>
          <div><span class="summaryLabel">Pending</span></div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="data"
    [attr.data-cy]="'dashboard-wrapper.component.depositableContainer'"
    class="depositable detailContainer card"
  >
    <div class="detailHeaderWrapper">
      <span>Available for Deposit</span>
    </div>
    <div class="sectionWrapper depositable">
      <div class="sectionContent ">
        <div class="sectionContentSubWrapper depositable">
          <div>
            <span [attr.data-cy]="'dashboard-wrapper.component.depositableFunds'" class="depositableAmount">{{
              data.maxDeposit / 100 | currency
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
