import React from 'react';

export const SearchIcon = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.894"
    height="21.069"
    viewBox="0 0 20.894 21.069"
    data-cy="search-icon"
  >
    <path
      d="M18.66,20.652,13.9,15.893a8.785,8.785,0,1,1,1.9-1.849l4.734,4.734a1.325,1.325,0,1,1-1.873,1.873ZM6.349,2.954A6.313,6.313,0,1,0,8.8,2.459a6.313,6.313,0,0,0-2.449.494Z"
      transform="translate(-0.027 0.029)"
      fill="#a5a8ba"
    />
  </svg>
));
