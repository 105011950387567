import { ReactWrapperComponent, WrapperProps, GcvTimePeriodDropdown } from '@user-interface/react-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'time-filter-dropdown',
  template:
    '<app-react-wrapper [wrappedComponent]="component" [wrappedProps]="props" [emitData]="handleSelect"></app-react-wrapper>',
})
export class TimeFilterDropdown extends ReactWrapperComponent {
  @Input() props: WrapperProps;
  @Input() selectedRange;
  @Output() filterChanged = new EventEmitter();

  component = GcvTimePeriodDropdown;

  constructor() {
    super();
    this.handleSelect = this.handleSelect.bind(this);
  }

  ngOnChanges() {
    const reactRangeMap = [
      { value: 'today', label: 'Today' },
      { value: 'yesterday', label: 'Yesterday' },
      { value: 'thisWeek', label: 'This Week' },
      { value: 'thisMonth', label: 'This Month' },
      { value: 'thisQuarter', label: 'This Quarter' },
      { value: 'thisYear', label: 'This Year' },
      { value: 'lastWeek', label: 'Last Week' },
      { value: 'lastMonth', label: 'Last Month' },
      { value: 'lastQuarter', label: 'Last Quarter' },
      { value: 'lastYear', label: 'Last Year' },
      { value: 'last30Days', label: 'Last 30 Days' },
    ];

    const date = reactRangeMap.find(time => time.value === this.selectedRange);
    let value;
    let label;

    const convertDateShort = date =>
      DateTime.fromISO(new Date(date).toISOString()).toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });

    if (this.selectedRange.includes('-')) {
      const values = this.selectedRange.split(' - ');
      label = `${convertDateShort(values[0])} - ${convertDateShort(values[1])}`;
      value = 'custom';
    } else {
      value = this.selectedRange;
      label = date.label;
    }

    this.props = {
      ...this.props,
      value: { value, label },
    };
  }

  handleSelect(timePeriod) {
    if (timePeriod.label !== 'custom') {
      const capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
      const angularDate = capitalizeFirstLetter(timePeriod.label.toLowerCase());
      if (this.filterChanged) {
        this.filterChanged.emit(angularDate);
      }
    } else {
      if (this.filterChanged) {
        this.filterChanged.emit(timePeriod.value);
      }
    }
  }
}
