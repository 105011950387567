<div *ngIf="section === 1" class="wrapper" data-cy="add-transportation-vendor-modal">
  <div class="header">
    <h1>Add a Transportation Service</h1>
  </div>
  <div class="content">
    <div class="innerContent">
      <div class="row">
        <gcv-mat-input
          [group]="transportationGroup"
          [validators]="[
            {
              type: 'required',
              message: 'You must enter an entity name'
            },
            {
              type: 'pattern',
              message: 'Please enter a valid entity name'
            }
          ]"
          class="full-width"
          control="name"
          data-cy="create-transportation-vendor.component.name"
          label="Entity's Legal Name"
          required="true"
          type="text"
          [errors]="transportationGroup.controls['name'].errors"
          [autoFill]="'off'"
        >
        </gcv-mat-input>
      </div>
      <div class="row">
        <gcv-mat-input
          [group]="transportationGroup"
          [validators]="[
            {
              type: 'required',
              message: 'You must enter a street address'
            },
            {
              type: 'pattern',
              message: 'Street address must be valid'
            }
          ]"
          control="street"
          data-cy="create-transportation-vendor.component.street"
          label="Street Address"
          required="true"
          type="text"
          [errors]="transportationGroup.controls['street'].errors"
          [autoFill]="'off'"
        >
        </gcv-mat-input>
        <gcv-mat-input
          [group]="transportationGroup"
          [validators]="[
            {
              type: 'required',
              message: 'You must enter a city'
            },
            {
              type: 'pattern',
              message: 'City must be valid'
            }
          ]"
          control="city"
          data-cy="create-transportation-vendor.component.city"
          label="City"
          required="true"
          type="text"
          [errors]="transportationGroup.controls['city'].errors"
          [autoFill]="'off'"
        >
        </gcv-mat-input>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-select
            [formControl]="transportationGroup.controls['state']"
            data-cy="create-transportation-vendor.component.state-view"
            placeholder="State"
            required
          >
            <mat-option
              *ngFor="let state of states"
              [attr.data-cy]="'create-transportation-vendor.component.' + state.viewValue"
              [value]="state.value"
            >
              {{ state.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <gcv-mat-input
          [group]="transportationGroup"
          [validators]="[
            {
              type: 'required',
              message: 'You must enter a ZIP code'
            },
            {
              type: 'pattern',
              message: 'ZIP code must be valid'
            }
          ]"
          control="zipCode"
          data-cy="create-transportation-vendor.component.zipcode"
          label="Zip Code"
          required="true"
          type="text"
          [errors]="transportationGroup.controls['zipCode'].errors"
          [autoFill]="'off'"
          (blurCalled)="formatLongZip()"
          (focusCalled)="unformatLongZip()"
        >
        </gcv-mat-input>
      </div>
      <div class="row">
        <gcv-mat-input
          [group]="transportationGroup"
          [validators]="[
            {
              type: 'required',
              message: 'You must enter a phone number'
            },
            {
              type: 'pattern',
              message: 'Phone number must be valid'
            }
          ]"
          control="phone"
          data-cy="create-transportation-vendor.component.phone"
          label="Phone Number"
          required="true"
          type="text"
          (blurCalled)="formatPhone()"
          (focusCalled)="unformatPhone()"
          [errors]="transportationGroup.controls['phone'].errors"
          [autoFill]="'off'"
        >
        </gcv-mat-input>

        <div class="tinwrapper">
          <gcv-mat-input
            [group]="transportationGroup"
            [validators]="[
              {
                type: 'pattern',
                message: 'TIN must be valid'
              }
            ]"
            control="tin"
            data-cy="create-transportation-vendor.component.tin"
            label="TIN"
            required="false"
            type="text"
            [errors]="transportationGroup.controls['tin'].errors"
            [autoFill]="'off'"
            (blurCalled)="formatEin()"
            (focusCalled)="unformatEin()"
          >
          </gcv-mat-input>
          <span
            matTooltip="A Tax Identification Number (TIN) is a 9-digit number used to identify a business entity, and is used to
            maintain banking records. You should check your service provider’s documentation, or request their TIN
            directly."
            matTooltipPosition="above"
            matTooltipClass="gcv-tooltip"
            [attr.data-cy]="'create-transportation-vendor.component.defaultHelp'"
            class="defaultHelp"
            >What's This?</span
          >
        </div>
      </div>
    </div>
    <div class="btnWrapper">
      <gcv-button
        (clickEvent)="cancel()"
        buttonText="Cancel"
        data-cy="create-transportation-vendor.component.cancel"
        type="tertiary "
      ></gcv-button>
      <gcv-button
        (clickEvent)="save()"
        [disabled]="!transportationGroup.valid"
        buttonText="Submit"
        data-cy="create-transportation-vendor.component.submit"
        type="primary"
      ></gcv-button>
    </div>
  </div>
</div>

<div *ngIf="section === 0" class="wrapper">
  <div class="header">
    <h3>Add a Method of Transportation</h3>
  </div>
  <div class="altContent">
    <div class="innerAltcontent">
      <div class="logo">
        <svg fill="none" height="126" width="253" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path d="M199.3 118.5a53.6 53.6 0 1 0 0-107.3 53.6 53.6 0 0 0 0 107.3z" fill="#F2F4FA" />
            <path
              d="M48.2 40.9H20.7l4.8-20.4h25.9L48.2 41zM102.9 40.9H75.3l1.2-20.4h25.2L103 41zM157.4 40.9h-27.5l-3.2-20.4h25.8l4.9 20.4zM38.7 48.1H25.5v71.4h13.2V48.1zM152.5 48.1h-13.1v71.4h13.1V48.1zM106.2 48.1H71.8v10h34.4v-10z"
              fill="#E3FFE6"
            />
            <path d="M152.5 48.1h-127v77h127v-77z" stroke="#00BC66" stroke-linejoin="round" stroke-width="3" />
            <path d="M71.8 104.5H38.7v15h33.1v-15zM139.4 104.5h-33.2v15h33.2v-15z" fill="#E3FFE6" />
            <path d="M71.8 104.5H38.7v4h33.1v-4zM139.4 104.5h-33.2v4h33.2v-4z" fill="#AFE2B7" />
            <path
              d="M157.4 119.5H20.7v5.7h136.7v-5.7z"
              fill="#fff"
              stroke="#00BC66"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              d="M38.7 47.5v72M139.4 47.5v72M106.2 48.1H71.8V119h34.4V48z"
              stroke="#00BC66"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              d="M71.8 48.1H38.7v52.5h33.1V48.1zM139.4 48.1h-33.2v52.5h33.2V48.1zM100 54.4H78v42.2h22V54.4z"
              fill="#9AF4AD"
            />
            <path d="M151.6 48.1H26.4v10h125.2v-10z" fill="#AFE2B7" />
            <g fill="#E3FFE6" opacity=".6">
              <path d="M78 86.3l22-17.6v-9.8L78 76.5v9.8zM78 93.7L100 76v-5L78 88.9v4.9z" opacity=".6" />
            </g>
            <path d="M100 54.4H78v42.2h22V54.4z" stroke="#00BC66" stroke-linejoin="round" stroke-width="3" />
            <path d="M152.5 6.5h-127v14h127v-14z" fill="#E3FFE6" />
            <path d="M100 107.6H78v5.3h22v-5.3z" stroke="#00BC66" stroke-linejoin="round" stroke-width="3" />
            <path
              d="M94.2 100.6H83.9c-.2 0-.4.2-.4.4v2.5c0 .2.2.3.4.3h10.3c.2 0 .4-.1.4-.3V101c0-.2-.2-.4-.4-.4z"
              fill="#00BC66"
            />
            <path d="M152.5 6.5h-127v3.2h127V6.5z" fill="#AFE2B7" />
            <path d="M152.5 6.5h-127v14h127v-14z" stroke="#00BC66" stroke-linejoin="round" stroke-width="3" />
            <path
              d="M157.4.8H20.7v5.7h136.7V.8z"
              fill="#fff"
              stroke="#00BC66"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <g fill="#E3FFE6" opacity=".6">
              <path d="M71.8 64.3L38.7 90.7v-9.8l33.1-26.4v9.8zM71.8 71.7L38.7 98v-4.8l33.1-26.4v4.9z" opacity=".6" />
            </g>
            <path d="M41.7 47.5h-3v53.1h3v-53z" fill="#AFE2B7" opacity=".5" />
            <g fill="#E3FFE6" opacity=".6">
              <path
                d="M139.4 64.3l-33.2 26.4v-9.8l33.2-26.4v9.8zM139.4 71.7L106.2 98v-4.8l33.2-26.4v4.9z"
                opacity=".6"
              />
            </g>
            <path d="M71.8 48.1H38.7v52.5h33.1V48.1z" stroke="#00BC66" stroke-linejoin="round" stroke-width="3" />
            <path d="M109.3 47.5h-3v53.1h3v-53z" fill="#AFE2B7" opacity=".5" />
            <path
              d="M139.4 48.1h-33.2v52.5h33.2V48.1zM38.7 104.5h33.1"
              stroke="#00BC66"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path d="M157.4 40.6H20.7v7.5h136.7v-7.5z" fill="#E3FFE6" />
            <path d="M48.2 40.6H20.7v7h27.5v-7zM102.9 40.6H75.3v7H103v-7zM157.4 40.6h-27.5v7h27.5v-7z" fill="#9AF4AD" />
            <path
              d="M106.2 104.5h33.2M157.4 40.6H20.7v7.5h136.7v-7.5zM157.4 40.6H20.7l4.8-20h127l4.9 20z"
              stroke="#00BC66"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              d="M25.5 75.5h5.7M25.5 13.5h5.7M146.8 15.1h5.7M139.4 85.7h5.7M146.8 80.2h5.7M146.8 107h5.7M33 92.2h5.7"
              stroke="#00BC66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path d="M144.2 101.3h-5.8a3 3 0 0 1 5.8 0zM132.5 101.3h-5.8a3 3 0 0 1 5.8 0z" fill="#00BC66" />
            <path
              d="M157.4 125.2h9M20 125.2h-8.9M7.6 125.2H.8M199 100.6v24.6H169.9"
              stroke="#00BC66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path d="M225.5 53.5H173V67h52.5V53.5z" fill="#F2F2F2" />
            <path d="M225.5 28.1H173v15h52.5v-15zM225.5 67.6H173v25.6h52.5V67.6z" fill="#E3FFE6" />
            <path d="M225.5 47.6H173V51h52.5v-3.3z" fill="#F2F4FA" />
            <path d="M225.5 28.1H173v3.3h52.5V28z" fill="#AFE2B7" />
            <path
              d="M191.3 60.5a1.7 1.7 0 0 0 .9-1.7c0-1.5-1.1-1.9-2.2-1.9h-2.2c-.4 0-.8.2-.8.5v7c0 .2.3.4.8.4h2.4c1.2 0 2.2-.6 2.2-2.3 0-1-.1-1.6-1.1-2zm-2.6-2.1h1.2c.4 0 .6.3.6.7a.7.7 0 0 1-.2.5.7.7 0 0 1-.4.3h-1.2v-1.5zm1.1 5h-1v-2.2h1c.6 0 .9.2.9 1 0 .7-.1 1.2-.9 1.2zM197 57.4c-.3-.8-2-.8-2.2 0l-2 6.6c-.2.7 1.4 1.1 1.6.5l.3-1.3h2.3l.4 1.3c.2.7 1.8.2 1.6-.5l-2-6.6zm-2 4.5l.9-3 .8 3H195zM203.5 57.4v3.7l-1.7-3.4c-.5-.8-.7-.8-1.3-.8-.4 0-.8.1-.8.5v7c0 .6 1.7.6 1.7 0v-3.8l2 3.8c.4.7 1.8.5 1.8 0v-7c0-.7-1.7-.7-1.7 0zM209.7 60.3l1.8-2.2c.5-.7-.9-1.7-1.3-1l-2 2.9v-2.6c0-.4-.5-.5-1-.5-.3 0-.8.1-.8.5v7c0 .6 1.7.6 1.7 0v-2l.5-.6 1.9 2.8c.4.8 2-.1 1.5-.8l-2.3-3.5z"
              fill="#00BC66"
            />
            <path
              d="M202.6 80.3a1 1 0 0 0-1 1V85a1 1 0 0 0 1.7.8c.2-.2.4-.5.4-.8v-3.6a1 1 0 0 0-1.1-1zM195.9 80.3a1 1 0 0 0-1 1V85a1 1 0 1 0 2 0v-3.6a1 1 0 0 0-1-1z"
              fill="#000"
            />
            <path d="M225.5 28.1H173v15h52.5v-15z" stroke="#00BC66" stroke-linejoin="round" stroke-width="3" />
            <path d="M226.8 43.1h-49.7v4.7h49.7v-4.7z" fill="#E3FFE6" />
            <path d="M225.5 47.6H173v46.9h52.5V47.6z" stroke="#00BC66" stroke-linejoin="round" stroke-width="3" />
            <path d="M226.8 23.4h-49.7V28h49.7v-4.7z" fill="#E3FFE6" />
            <path
              d="M226.8 23.4h-54.7V28h54.7v-4.7zM226.8 43.1h-54.7v4.7h54.7v-4.7z"
              stroke="#00BC66"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              d="M173 89.8h52.5M173 67.6h52.5M173 53.5h52.5M170.2 94.5H228"
              stroke="#00BC66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path d="M211.3 72.4H187v22h24.3v-22z" fill="#fff" />
            <path
              d="M211.4 72.4h-24.2v22h24.2v-22z"
              stroke="#00BC66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path d="M199.3 94.5v-22" stroke="#00BC66" stroke-linejoin="round" stroke-width="3" />
            <path
              d="M195 85.7V82M203.6 85.7V82"
              stroke="#00BC66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              d="M199 112.5a12.1 12.1 0 1 0 0-24.3 12.1 12.1 0 0 0 0 24.3z"
              fill="#fff"
              stroke="#00BC66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              d="M199 100.6a2.4 2.4 0 1 1 2.4-2.4M199 100.6a2.4 2.4 0 0 1 2.4 3 2.4 2.4 0 0 1-4.9-.6M199 95.8v-1.6M199 107v-1.5"
              stroke="#00BC66"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <path d="M0 0h253v126H0z" fill="#fff" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="textWrapper">
        <p>
          A Method of Transportation is the way you plan to physically transport this deposit.
        </p>
        <br />
        <p>
          You can select a user as a Method of Transportation by adding them to the
          <a (click)="goToTransportation()" id="highlightText">Deposit Transportation Group</a>. Or you can add a new
          service provider
        </p>
      </div>
    </div>
    <div class="btnWrapper">
      <gcv-button (clickEvent)="section = 1" buttonText="Add Service" type="primary"></gcv-button>
      <gcv-button (clickEvent)="addUser()" buttonText="Add User" type="primary"></gcv-button>
    </div>
  </div>
</div>
