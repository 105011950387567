import React, { useMemo, useEffect, useState } from 'react';
import { Oval } from 'svg-loaders-react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { $primary } from '../../../util/styleVariables';
import { LoadingContainer } from './styles';
import { formatMoney } from '../../../util/formatHelpers';
import { GcvTimePeriodDropdown, GcvZeroState, GcvDataTable } from '../../../lib';
import calculateTimeRange from '../../../util/calculateDateRange';
import { Dispensary, DailySummary } from '@gcv/shared';
import { api } from 'libs/react-ui/src/api';
import { TimePeriod } from '../Dashboard.page';
import { v4 as uuid } from 'uuid';

interface Props {
  dispensary: Dispensary;
  setTimePeriod: (timeRange: TimePeriod) => any;
  timePeriod: TimePeriod;
  summaries: DailySummary[];
  loadingData: boolean;
}

export const DailySummaries = (props: Props) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const tableColumns = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      left: true,
      format: row => DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_MED),
    },
    {
      name: 'Verified',
      selector: 'verified_percent',
      sortable: true,
      left: true,
      format: row => `${Math.floor(row.verified_percent) || 0}%`,
    },

    {
      name: 'Total Sales',
      selector: 'total_sales',
      sortable: true,
      left: true,
      format: row => formatMoney(row.total_sales),
    },
    {
      name: 'Cash',
      selector: 'total_cash',
      sortable: true,
      left: true,
      format: row => formatMoney(row.total_cash),
    },
    {
      name: 'Debit',
      selector: 'total_debit',
      sortable: true,
      left: true,
      format: row => formatMoney(row.total_debit),
    },

    {
      name: 'Other',
      selector: 'total_other',
      sortable: true,
      left: true,
      format: row => formatMoney(row.total_sales - row.total_debit - row.total_cash),
    },
  ];

  const handleTimeChange = results => {
    const timeRange = calculateTimeRange(results.value, props.dispensary.iana_timezone);
    props.setTimePeriod({ val: results.value, timeRange });
  };

  const handleRowClick = rowData => {
    const { date } = rowData;
    const timezone = props.dispensary.iana_timezone;
    history.push(`${url}/detail/${props.dispensary.id}/${date}`, { timezone });
  };

  const tableRows = props.summaries
    ? props.summaries.map(o => {
        return {
          id: uuid(),
          dispensary_name: o.dispensary_name,
          date: o.date,
          verified_percent: o.verified_percent,
          total_sales: o.total_sales,
          total_cash: o.total_cash,
          total_debit: o.total_debit,
        };
      })
    : [];

  return (
    <>
      <div style={{ marginBottom: '1rem', display: 'flex' }}>
        <GcvTimePeriodDropdown
          emitData={handleTimeChange}
          labelText={'Time Period'}
          newStyle={true}
          defaultValueDrop={props.timePeriod.val}
        ></GcvTimePeriodDropdown>
      </div>
      {props.loadingData ? (
        <LoadingContainer>
          <Oval height={75} width={75} stroke={$primary} viewBox="0 0 38 38" />
        </LoadingContainer>
      ) : (
        <GcvDataTable
          data={tableRows}
          columns={tableColumns}
          onRowClicked={handleRowClick}
          keyField="id"
          defaultSortField="date"
          defaultSortAsc={false}
          noDataComponent={
            <GcvZeroState
              type="bankDash"
              headerText="There is no sales data available for this time period"
              subText="Try changing the time period for more results"
            />
          }
        />
      )}
    </>
  );
};
