import React from 'react';
import { MinifiedDispensary, DueDiligenceStatus } from '@gcv/shared';
import { AccountCardContainer } from './AccountCardContainer';
import { name, city, licenseType, createdOn, documents } from '../columns';

interface Props {
  dispensaries: MinifiedDispensary[];
  tableView: boolean;
  isFiltered: boolean;
  emitData: (e) => void;
}

export const Pending = ({ dispensaries, tableView, isFiltered, emitData }: Props) => {
  const activeDispensaries = dispensaries.filter(
    dispensary =>
      dispensary.due_diligence.due_diligence_status !== DueDiligenceStatus.BANK_APPROVED &&
      dispensary.due_diligence.due_diligence_status !== DueDiligenceStatus.BANK_AWAITING_REVIEW
  );

  const emitDataActive = dispensaryId => {
    emitData({ type: 'pending', dispensaryId });
  };

  const columns = [
    name('Name'),
    city('City'),
    licenseType('License Type'),
    createdOn('Created On'),
    documents('Documents'),
  ];

  return (
    <AccountCardContainer
      accounts={activeDispensaries}
      tableView={tableView}
      emitData={emitDataActive}
      tableColumns={columns}
      sortKey={'date_created'}
      isPending={true}
      noAccountsZeroStateText={'You currently have no accounts pending'}
      isFiltered={isFiltered}
    />
  );
};
