import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { Bank, DEFAULT_GCV_DUE_DILIGENCE_TEMPLATE, DueDiligenceRequirementTemplate } from '@gcv/shared';

import { useApi, getEndpoint } from '../../hooks';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from '@green-check/common-ui';
import { $grey2, formatISOToDateAtTime } from '../../util';
import { GcvDataTable, GcvDrawer, GcvReadMore, GcvButton, GcvInputSelect, GcvInputForm } from '../../lib';

import {
  DrawerContent,
  DrawerHeader,
  VerticalCenter,
  Title,
  SubTitle,
  Spacer,
  Footer,
  DrawerText,
  Warning,
} from './styles';
import { BackButtonIcon } from '../../icons/BackButtonIcon';
import { GcvInputTextArea } from '../../lib/GcvInputTextArea/GcvInputTextArea';
import { api } from '../../api';

interface Props {
  template: DueDiligenceRequirementTemplate;
  open: boolean;
  updateReqStatus: (status: string, requirement_ids: string[]) => void;
  onClose: (drawerType: 'custom' | 'standard') => void;
  bank: Bank;
  updateData: () => void;
  drawerType: 'custom' | 'standard';
}

export const TemplateDrawer = ({ updateReqStatus, template, open, onClose, bank, updateData, drawerType }: Props) => {
  const apiClient = api();
  const { handleSubmit, ...form } = useForm();
  const [editView, setEditView] = useState(false);

  const closeDrawer = () => {
    setEditView(false);
    onClose(drawerType);
  };

  const categoryOptions = [
    { value: 'legal', label: 'Legal' },
    { value: 'operational', label: 'Operational' },
    { value: 'financial', label: 'Financial' },
  ];

  const restoreReq = () => {
    updateReqStatus('not archived', [template.requirement_id]);
    closeDrawer();
  };

  const archiveReq = () => {
    updateReqStatus('archived', [template.requirement_id]);
    closeDrawer();
  };

  const editReq = () => {
    setEditView(true);
  };

  const clearEditReq = () => {
    setEditView(false);
  };

  const saveReq = data => {
    upsertData(data);
    closeDrawer();
  };

  const createReq = data => {
    upsertData(data);
    closeDrawer();
  };

  const upsertData = async data => {
    const newRequirement = {
      name: data.name,
      description: data.description,
      id: template ? template.requirement_id : false,
    };

    await apiClient.banks
      .upsertTemplateRequirement(bank.id, { requirement: newRequirement, category: data.category.value }, () => {})
      .then(() => {
        updateData();
      })
      .catch(e => {});
  };

  const defaultSelectValue =
    template && template.category ? categoryOptions.find(cat => cat.value === template.category) : categoryOptions[0];

  const defaultCategoryValue =
    template && template.category ? categoryOptions.find(cat => cat.value === template.category) : '';

  return (
    <>
      <GcvDrawer open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <VerticalCenter>
                <BackButtonIcon onClick={() => closeDrawer()}></BackButtonIcon>
              </VerticalCenter>
              <VerticalCenter style={{ marginLeft: '13.5px' }}>
                <Title>Custom Requirement</Title>
                <SubTitle>{template ? template.name : null}</SubTitle>
              </VerticalCenter>
            </div>
            <div>
              <VerticalCenter>
                <Title>{template ? (!template.archived ? 'Active' : 'Archived') : null}</Title>
              </VerticalCenter>
            </div>
          </div>
        </DrawerHeader>
        <DrawerContent>
          <div>
            {!template || editView ? (
              <>
                <Spacer style={{ marginBottom: '28px', marginTop: '19px' }}>
                  <GcvInputForm
                    {...form}
                    name={'name'}
                    defaultValue={template ? template.name : ''}
                    rules={{ required: true }}
                    label={'Name'}
                  />
                </Spacer>

                <Spacer style={{ marginTop: '28px', marginBottom: '11px' }}>
                  <GcvInputSelect
                    {...form}
                    options={categoryOptions}
                    label={'Category'}
                    name={'category'}
                    defaultValue={defaultSelectValue}
                    rules={{ required: true }}
                  />
                </Spacer>

                <Spacer style={{ marginTop: '11px' }}>
                  <GcvInputTextArea
                    rules={{ required: true }}
                    defaultValue={template ? template.description : ''}
                    name={'description'}
                    label={'Description'}
                    {...form}
                  ></GcvInputTextArea>
                </Spacer>
              </>
            ) : (
              <>
                <Spacer>
                  <SubTitle>Name</SubTitle>
                  <DrawerText>{template ? template.name : ''}</DrawerText>
                </Spacer>
                <Spacer>
                  <SubTitle>Category</SubTitle>
                  <DrawerText>{defaultCategoryValue ? defaultCategoryValue.label : ''}</DrawerText>
                </Spacer>
                <Spacer>
                  <SubTitle>Description</SubTitle>
                  <DrawerText>
                    <GcvReadMore string={template ? template.description : ''} length={300}></GcvReadMore>
                  </DrawerText>
                </Spacer>
              </>
            )}
          </div>
          <Footer>
            {drawerType === 'standard' ? (
              <></>
            ) : template ? (
              template.archived ? (
                <GcvButton onClick={restoreReq} secondary={true}>
                  Restore
                </GcvButton>
              ) : (
                <>
                  {editView ? (
                    <>
                      <GcvButton onClick={clearEditReq} secondary={true}>
                        Cancel
                      </GcvButton>
                      <GcvButton onClick={handleSubmit(saveReq)} primary={true}>
                        Save
                      </GcvButton>
                    </>
                  ) : (
                    <>
                      <GcvButton onClick={archiveReq} secondary={true}>
                        Archive
                      </GcvButton>
                      <GcvButton onClick={editReq} primary={true}>
                        Edit
                      </GcvButton>
                    </>
                  )}
                </>
              )
            ) : (
              <>
                <GcvButton onClick={closeDrawer} secondary={true}>
                  Cancel
                </GcvButton>
                <GcvButton onClick={handleSubmit(createReq)} primary={true}>
                  Create
                </GcvButton>
              </>
            )}
          </Footer>
        </DrawerContent>
      </GcvDrawer>
    </>
  );
};
