<div class="amplify-container" data-cy="sign-in.component">
  <div class="amplify-form-container">
    <img src="../../assets/gcv_logo_alt.png" class="logo" width="150px" alt="logo" />
    <div class="amplify-form-body">
      <div class="amplify-form-header">Log In</div>
      <div class="amplify-form-row amplify-signin-username">
        <mat-form-field class="example-full-width" [formGroup]="userData">
          <input
            autocomplete="email"
            autofocus
            matInput
            type="Email"
            placeholder="Your email"
            formControlName="username"
            data-cy="sign-in.component.email"
            (keyup)="checkForEnter($event)"
          />
          <mat-error *ngIf="userData.controls.username.errors?.email">
            Please enter a <strong>valid</strong> email
          </mat-error>
          <mat-error *ngIf="userData.controls.username.errors?.required">
            Your email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="amplify-form-row amplify-signin-password">
        <mat-form-field class="example-full-width" [formGroup]="userData">
          <input
            matInput
            autocomplete="current-password"
            type="password"
            placeholder="Password"
            formControlName="password"
            (keyup)="checkForEnter($event)"
            data-cy="sign-in.component.password"
          />
          <mat-error *ngIf="userData.controls.password.errors?.required">
            Your password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="amplify-forgot-password">
        <span class="amplify-form-action"
          ><a class="amplify-form-link" (click)="onForgotPassword()" data-cy="sign-in.component.forgotPassword"
            >I forgot my password</a
          ></span
        >
      </div>

      <div class="amplify-form-actions">
        <gcv-button
          class="fullWidth"
          (clickEvent)="onSignIn()"
          [disabled]="!userData.valid"
          buttonText="Log In"
          type="primary full-width large"
          data-cy="sign-in.component.logIn"
        ></gcv-button>
      </div>
    </div>
  </div>

  <div class="amplify-alert" *ngIf="errorMessage">
    <div class="amplify-alert-body">
      <span class="amplify-alert-icon">&#9888;</span>
      <div class="amplify-alert-message" data-cy="sign-in.component.errorMessage">{{ errorMessage }}</div>
      <a class="amplify-alert-close" (click)="onAlertClose()">&times;</a>
    </div>
  </div>
</div>
