import React from 'react';
import { Container, Background } from './styles';
import { GreenCheckStatusIcon } from '../../icons/GreenCheckStatusIcon';
import { YellowWarningIcon } from '../../icons/YellowWarningIcon';
import { GreyQuestionIcon } from '../../icons/GreyQuestionIcon';
import { RedXIcon } from '../../icons/RedXIcon';

interface Props {
  status?: 'green_checked' | 'warning' | 'red_flag' | 'unknown' | 'unchecked';
}

const colorMap = {
  green_checked: '#02b45b',
  warning: '#F49B20',
  red_flag: '#FF4447',
  unknown: '#f2f2f2',
  unchecked: '#5A5E6B',
};

const iconComponentMap = {
  green_checked: GreenCheckStatusIcon,
  warning: YellowWarningIcon,
  red_flag: RedXIcon,
  unknown: YellowWarningIcon,
  unchecked: GreyQuestionIcon,
};

const viewValueMap = {
  green_checked: 'Verified',
  warning: 'Warning',
  red_flag: 'Unverified',
  unknown: 'Unknown',
  unchecked: 'Unchecked',
};

export const GcvSaleStatusIndicator = (props: Props) => {
  const color = colorMap[props.status];
  const IconComponent = iconComponentMap[props.status];
  const viewValue = viewValueMap[props.status];

  if (!props.status) {
    return null;
  }

  return (
    <Container color={color}>
      <Background color={color} />
      <IconComponent style={{ zIndex: 1, position: 'absolute', left: 3, top: 4 }} />
      <span style={{ color }} data-cy="status-text">
        {viewValue}
      </span>
    </Container>
  );
};
