import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, Grid, makeStyles, TextField } from '@material-ui/core';

import { GcvButton, GcvModal } from '../../../lib';
import { GREEN_CHECK_SERVICES_AMPLIFY_NAME } from 'libs/common-ui/src/lib/common-ui';
import { useApi } from 'libs/react-ui/src/hooks';
import { api } from 'libs/react-ui/src/api';

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'flex-end',
  },
  alertBox: {
    border: '1px solid #3f51b5',
    borderRadius: '4px',
    backgroundColor: 'rgba(105, 150, 250, 0.1)',
    minHeight: '25px',
    textAlign: 'center',
    marginBottom: '1rem',
  },
}));

interface Props {
  selectedDeposit: any;
  setSelectedDeposit: (deposit: {}) => void;
}

export const SearchDepositsCard: React.FC<Props> = props => {
  const classes = useStyles();
  const apiClient = api();

  const [dispensaryId, setDispensaryId] = useState('');
  const [depositId, setDepositId] = useState('');
  const [msg, setMsg] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const buttonDisabled = () => {
    return dispensaryId === '' || depositId === '';
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const searchDeposits = async () => {
    setMsg(null);

    await apiClient.dispensaries
      .getDeposit(dispensaryId, depositId, () => {})
      .then(d => {
        props.setSelectedDeposit(d);
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 404') {
          setMsg('Deposit not found');
        } else {
          setMsg(e.message);
        }
      });
  };

  const updateDeposit = async () => {
    await apiClient.gcv
      .updateDepositGcv(dispensaryId, props.selectedDeposit, () => {})
      .then(response => {
        setMsg('Updated Deposit');
        props.setSelectedDeposit(response);
      });
  };

  const deleteDeposit = async () => {
    setMsg(null);

    await apiClient.gcv
      .deleteDeposit(dispensaryId, props.selectedDeposit.deposit_id, () => {})
      .then(() => {
        setMsg('Deposit Deleted');
      });
    toggleModal();
  };

  return (
    <>
      {msg && <div className={classes.alertBox}>{msg}</div>}

      <Card>
        <CardHeader title="Search Deposits" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="dispensaryId"
                label="Dispensary Id"
                defaultValue={dispensaryId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDispensaryId(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                id="depositId"
                label="Deposit Id"
                defaultValue={depositId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDepositId(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <GcvButton disabled={buttonDisabled()} primary={true} onClick={searchDeposits}>
            Search
          </GcvButton>
          <GcvButton disabled={buttonDisabled()} secondary={true} onClick={updateDeposit}>
            Update Deposit
          </GcvButton>
          <GcvButton disabled={buttonDisabled()} danger={true} onClick={toggleModal}>
            Delete
          </GcvButton>
        </CardActions>
      </Card>

      <GcvModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        backButton={
          <GcvButton onClick={toggleModal} secondary={true}>
            Cancel
          </GcvButton>
        }
        continueButton={
          <GcvButton onClick={deleteDeposit} danger={true}>
            Delete
          </GcvButton>
        }
        children={
          <>
            <p>Dispensary Id: {dispensaryId}</p>
            <p>Deposit Id: {depositId}</p>
            <p>This action is final. There is no going back.</p>
          </>
        }
        title="Are you sure you want to delete this deposit?"
      ></GcvModal>
    </>
  );
};
