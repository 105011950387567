import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { GcvCard } from '../../styles';

import { ReportList } from './Components/ReportList';
import { SuccessFailureModal } from './Components/SuccessFailureModal';

import { DailySummariesReport } from './ReportTypes/DailySummariesReport';
import { DepositsReport } from './ReportTypes/DepositsReport';
import { SARSummaryReport } from './ReportTypes/SARSummaryReport';
import { CTRSummaryReport } from './ReportTypes/CTRSummaryReport';
import { SalesActivityReport } from './ReportTypes/SalesActivityReport';
import { PreComplianceReviewReport } from './ReportTypes/PreComplianceReviewReport';
import { GcvButton, GcvZeroState } from '../../lib';
import { api } from '../../api';
import { IANATimezones } from '@gcv/shared';
import { AccountSummaryReport } from './ReportTypes/AccountSummaryReport';

interface Props {
  bankId: string;
  userId: string;
  viewStatus: string;
  emitData: any;
  timezone: IANATimezones;
}

export const Reports: React.FC<Props> = props => {
  const apiClient = api();

  const [accountList, setAccountList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [exportActivitySuccess, setExportActivitySuccess] = useState(false);
  const [exportActivityFailure, setExportActivityFailure] = useState(false);
  const [responseModalActive, setResponseModalActive] = useState(false);

  useEffect(() => {
    handleFetchAccounts();
  }, []);

  const handleFetchAccounts = async () => {
    const accounts = await apiClient.banks.getBankMinifiedDispensaries(props.bankId, () => {});

    const accountList = accounts.map(a => {
      return { value: a.id, label: a.name };
    });

    setAccountList(accountList);
  };

  return (
    <>
      <h1 data-cy="title">Reports</h1>
      {props.viewStatus !== 'DefaultState' ? (
        <>
          <GcvZeroState
            headerText="You currently have no reports to view"
            dashboardSubText="To begin accepting applications from cannabis businesses please finish setting up your company profile."
            type="basic"
            button={
              <GcvButton
                secondary={props.viewStatus === 'DueDiligenceInProgressState'}
                primary={props.viewStatus === 'DueDiligenceZeroState'}
                onClick={() => props.emitData()}
              >
                {props.viewStatus === 'DueDiligenceZeroState' ? "Let's Go" : 'Continue'}
              </GcvButton>
            }
          ></GcvZeroState>
        </>
      ) : (
        <>
          <Grid container spacing={3} style={{ height: '100%', maxHeight: '600px' }}>
            <Grid item xs={4} style={{ height: '100%' }}>
              <GcvCard style={{ height: '100%' }}>
                <ReportList selectedIndex={selectedIndex} handleListItemClick={(e, i) => setSelectedIndex(i)} />
              </GcvCard>
            </Grid>

            <Grid item xs={8}>
              <GcvCard style={{ height: '100%' }}>
                {selectedIndex === 0 && (
                  <AccountSummaryReport
                    bankId={props.bankId}
                    userId={props.userId}
                    accountList={accountList}
                    setExportActivitySuccess={setExportActivitySuccess}
                    setExportActivityFailure={setExportActivityFailure}
                    setResponseModalActive={setResponseModalActive}
                    timezone={props.timezone}
                  />
                )}

                {selectedIndex === 1 && (
                  <DailySummariesReport
                    bankId={props.bankId}
                    userId={props.userId}
                    accountList={accountList}
                    setExportActivitySuccess={setExportActivitySuccess}
                    setExportActivityFailure={setExportActivityFailure}
                    setResponseModalActive={setResponseModalActive}
                    timezone={props.timezone}
                  />
                )}

                {selectedIndex === 2 && (
                  <DepositsReport
                    bankId={props.bankId}
                    userId={props.userId}
                    accountList={accountList}
                    setExportActivitySuccess={setExportActivitySuccess}
                    setExportActivityFailure={setExportActivityFailure}
                    setResponseModalActive={setResponseModalActive}
                    timezone={props.timezone}
                  />
                )}

                {selectedIndex === 3 && (
                  <CTRSummaryReport
                    bankId={props.bankId}
                    userId={props.userId}
                    accountList={accountList}
                    setExportActivitySuccess={setExportActivitySuccess}
                    setExportActivityFailure={setExportActivityFailure}
                    setResponseModalActive={setResponseModalActive}
                    timezone={props.timezone}
                  />
                )}

                {selectedIndex === 4 && (
                  <SARSummaryReport
                    bankId={props.bankId}
                    userId={props.userId}
                    accountList={accountList}
                    setExportActivitySuccess={setExportActivitySuccess}
                    setExportActivityFailure={setExportActivityFailure}
                    setResponseModalActive={setResponseModalActive}
                    timezone={props.timezone}
                  />
                )}

                {selectedIndex === 5 && (
                  <PreComplianceReviewReport
                    bankId={props.bankId}
                    userId={props.userId}
                    accountList={accountList}
                    setExportActivitySuccess={setExportActivitySuccess}
                    setExportActivityFailure={setExportActivityFailure}
                    setResponseModalActive={setResponseModalActive}
                    timezone={props.timezone}
                  />
                )}

                {selectedIndex === 6 && (
                  <SalesActivityReport
                    bankId={props.bankId}
                    userId={props.userId}
                    accountList={accountList}
                    setExportActivitySuccess={setExportActivitySuccess}
                    setExportActivityFailure={setExportActivityFailure}
                    setResponseModalActive={setResponseModalActive}
                    timezone={props.timezone}
                  />
                )}
              </GcvCard>
            </Grid>
          </Grid>

          <SuccessFailureModal
            exportActivitySuccess={exportActivitySuccess}
            exportActivityFailure={exportActivityFailure}
            responseModalActive={responseModalActive}
            setResponseModalActive={setResponseModalActive}
          />
        </>
      )}
    </>
  );
};
