<gcv-card data-cy="profile-card.component">
  <div *ngIf="!editable" card-body class="innerProfileWrapper">
    <div class="editButtonWrapper">
      <div (click)="edit()" class="editIcon" data-cy="profile-card.component.edit">
        <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 12v3.3c0 .2 0 .4.2.5l.5.2H4c.2 0 .3 0 .5-.2L15.8 4.5a1 1 0 0 0 0-1L12.5.2a.7.7 0 0 0-1 0L9.3 2.5l-9 9c-.2.1-.3.3-.3.5zM12 1.7L14.3 4 13 5.2 10.8 3 12 1.7zM1.4 12.3L9.8 4 12 6.2l-8.3 8.4H1.4v-2.3z"
            fill="#A5A8BA"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div>
      <div class="profileImgWrapper">
        <div class="profile-avatar-letters">
          {{ data.controls['firstName'].value[0] }}{{ data.controls['lastName'].value[0] }}
        </div>
        <span class="name"
          >{{ data.controls['firstName'].value }} {{ data.controls['minitial'].value }}
          {{ data.controls['lastName'].value }}</span
        >
        <span class="title" data-cy="profile-card.component.title">{{ data.controls['title'].value }}</span>
      </div>
    </div>
    <div class="formWrapper">
      <div class="innerInputWrapper">
        <span class="innerInputText">Email</span>
        <span class="innerInputValue" data-cy="profile-card.component.email">{{ data.controls['email'].value }}</span>
      </div>
      <div class="innerInputWrapper">
        <span class="innerInputText">Phone</span>
        <span class="innerInputValue" data-cy="profile-card.component.phone">{{ data.controls['phone'].value }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="editable" card-body class="cardWrapper" data-cy="profile-card.component.staticCard">
    <div class="header"><span>Contact Information</span></div>
    <div>
      <gcv-input
        (value)="handleInput($event)"
        [inputValue]="data.controls['firstName'].value"
        [styles]="{ 'margin-bottom': '1rem', 'max-width': '16rem', 'width.%': '100' }"
        label="First Name"
        property="firstName"
        type="text"
      ></gcv-input>
      <gcv-input
        (value)="handleInput($event)"
        [inputValue]="data.controls['minitial'].value"
        [styles]="{ 'margin-bottom': '1rem', 'max-width': '16rem', 'width.%': '100' }"
        label="Middle Name"
        property="minitial"
        type="text"
      ></gcv-input>
      <gcv-input
        (value)="handleInput($event)"
        [inputValue]="data.controls['lastName'].value"
        [styles]="{ 'margin-bottom': '1rem', 'max-width': '16rem', 'width.%': '100' }"
        label="Last Name"
        property="lastName"
        type="text"
      ></gcv-input>
      <gcv-input
        (value)="handleInput($event)"
        [disabled]="true"
        [inputValue]="data.controls['email'].value"
        [styles]="{ 'margin-bottom': '1rem', 'max-width': '16rem', 'width.%': '100' }"
        label="Email"
        property="email"
        type="text"
      ></gcv-input>
      <gcv-input
        (value)="handleInput($event)"
        [inputValue]="data.controls['phone'].value"
        [styles]="{ 'margin-bottom': '1rem', 'max-width': '16rem', 'width.%': '100' }"
        label="Phone"
        property="phone"
        type="text"
      ></gcv-input>
    </div>
    <div class="btnWrapper">
      <gcv-button
        (clickEvent)="cancel()"
        [attr.data-cy]="'profile-card.component.cancelBtn'"
        buttonText="Cancel"
        type="tertiary fullWidth"
      ></gcv-button>
      <gcv-button
        (clickEvent)="save()"
        [attr.data-cy]="'profile-card.component.saveBtn'"
        buttonText="Save"
        type="primary fullWidth"
      ></gcv-button>
    </div>
  </div>
</gcv-card>
