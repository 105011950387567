<div>
  <div *ngIf="exportable && selection.hasValue()" class="exportWrapper">
    <gcv-button
      (clickEvent)="export()"
      buttonText="Export as CSV"
      data-cy="data-table.component.exportButton"
      type="primary pill thin"
    ></gcv-button>
  </div>

  <ng-template #showSpinner>
    <gcv-spinner></gcv-spinner>
  </ng-template>

  <mat-table
    *ngIf="data; else showSpinner"
    [class.rounded-corners-bottom]="data && data.length <= 10"
    [dataSource]="dataSource"
    class="rounded-corners-top"
    data-cy="data-table.component.dataSource"
    matSort
  >
    <div *ngFor="let column of displayedColumns" [attr.data-cy]="'data-table.component.column'">
      <ng-container
        *ngIf="
          column !== 'edit' &&
          column !== 'status' &&
          column !== 'select' &&
          column !== 'receipt_date' &&
          column !== 'payment_type' &&
          column !== 'total_paid' &&
          column !== 'organization' &&
          column !== 'image' &&
          column !== 'archive_date' &&
          column !== 'lastLogin' &&
          column !== 'statusViewValue' &&
          column !== 'license_expiration' &&
          column !== 'license_name' &&
          column !== 'invitation_status' &&
          column !== 'uploaded_by' &&
          column !== 'expiration_date' &&
          column !== 'upload_date'
        "
        matColumnDef="{{ column }}"
      >
        <mat-header-cell *matHeaderCellDef data-cy="data-table.component.headerCell" mat-sort-header>{{
          dataMap[column]
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let data"
          [ngClass]="column === 'items' ? 'viewItem' : 'nonViewItem'"
          [attr.data-cy]="'data-table.component.cell'"
          [matTooltip]="data[column]"
          matTooltipPosition="above"
          [matTooltipDisabled]="!data[column] || data[column].length < 28"
          >{{ data[column] | fileNamePipe }}</mat-cell
        >
      </ng-container>

      <ng-container *ngIf="column === 'invitation_status'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef data-cy="data-table.component.headerCell" mat-sort-header>{{
          dataMap[column]
        }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.cell">{{
          data.lastLogin ? (data.identityVerified ? 'Confirmed & Verified' : 'Confirmed') : 'Pending'
        }}</mat-cell>
      </ng-container>

      <ng-container *ngIf="column === 'lastLogin'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef data-cy="data-table.component.headerCell" mat-sort-header>{{
          dataMap[column]
        }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.cell">{{
          data[column] === 'Never' ? data[column] : (data[column] | date: 'short')
        }}</mat-cell>
      </ng-container>

      <ng-container *ngIf="column == 'organization' && column != 'due_diligence_step'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef data-cy="data-table.component.headerCell" mat-sort-header>{{
          dataMap[column]
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let data"
          [ngClass]="column === 'items' ? 'viewItem' : 'nonViewItem'"
          data-cy="data-table.component.cell"
          >{{ getCellContents() }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column == 'uploaded_by'" matColumnDef="{{ column }}">
        <mat-header-cell
          *matHeaderCellDef
          class="imageCell"
          data-cy="data-table.component.headerCell"
          mat-sort-header
          >{{ dataMap[column] }}</mat-header-cell
        >
        <mat-cell *matCellDef="let data" class="imageCell" data-cy="data-table.component.imageCell">
          <gcv-initial-logo
            *ngIf="userMap[data.uploaded_by]"
            [firstName]="userMap[data.uploaded_by].firstName"
            [lastName]="userMap[data.uploaded_by].lastName"
            data-cy="data-table.component.name"
          >
          </gcv-initial-logo>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column == 'image'" matColumnDef="{{ column }}">
        <mat-header-cell
          *matHeaderCellDef
          class="imageCell"
          data-cy="data-table.component.headerCell"
          mat-sort-header
          >{{ dataMap[column] }}</mat-header-cell
        >
        <mat-cell *matCellDef="let data" class="imageCell" data-cy="data-table.component.imageCell">
          <gcv-initial-logo [firstName]="data.firstName" [lastName]="data.lastName" data-cy="data-table.component.name">
          </gcv-initial-logo>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'select'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef class="editCell" data-cy="data-table.component.editCell">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            data-cy="data-table.component.checkbox"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let data" class="editCell" data-cy="data-table.component.editCell">
          <mat-checkbox
            (change)="handleChange($event, data)"
            (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(data)"
            data-cy="data-table.component.checkbox"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'edit'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef class="editCell" mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="editCell" data-cy="data-table.component.editCell">
          <svg
            (click)="editProfile(data)"
            class="editProfile"
            height="16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 12v4h5L16 5V4l-3-4a1 1 0 0 0-1 0L9 3l-9 9zM12 2l2 2-1 1-2-2 1-1zM1 12l9-8 2 2-8 9H1v-3z"
              fill="#A5A8BA"
              fill-rule="evenodd"
            />
          </svg>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'receipt_date'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.receiptDate">
          {{ data[column] | date }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'upload_date'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.uploadDate">
          {{ data[column] !== '' ? (data[column] | date: 'MM/dd/yyyy') : 'Not Provided' }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'expiration_date'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.licenseExpiration">
          {{ data[column] | date: 'MM/dd/yyyy' }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'license_name'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.licenseName">
          <span *ngIf="data[column] === 'llc_license'">LLC License</span>
          <span *ngIf="data[column] === 'retail_license'">Retail License</span>
          <span *ngIf="data[column] !== 'retail_license' && data[column] !== 'llc_license'">
            {{ cleanLicenseName(data[column]) }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'archive_date'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.archiveDate">
          {{ data[column] | date }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'total_paid'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.totalPaid">
          {{ data[column] / 100 | currency }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'payment_type'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="nonViewItem" data-cy="data-table.component.paymentType">
          {{ data[column] | titlecase }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'statusViewValue'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" data-cy="data-table.component.dueDiligence">
          <span>{{ data[column] }}</span>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column === 'status'" matColumnDef="{{ column }}">
        <mat-header-cell *matHeaderCellDef class="editCell" mat-sort-header>{{ dataMap[column] }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="editCell" data-cy="data-table.component.status">
          <svg
            *ngIf="data[column] === 'approved' || data[column] === 'green_checked'"
            viewBox="0 0 26 26"
            width="26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-rule="evenodd" fill-rule="evenodd">
              <circle cx="13" cy="13" fill="#00bc66" r="13" />
              <path d="M16 10l-4 4-2-1H8v2l2 1a2 2 0 0 0 3 0l5-4v-2h-2" fill="#fff" />
            </g>
          </svg>
          <svg *ngIf="data[column] === 'red_flag'" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
            <circle clip-rule="evenodd" cx="13" cy="13" fill="#ff4e50" fill-rule="evenodd" r="13" />
            <path d="M15 13l2-2V9h-1l-3 2-2-2H9v1l2 3-2 2v2h1l3-2 2 2h2v-1l-2-3z" fill="#fff" />
          </svg>
          <svg *ngIf="data[column] === 'warning'" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
            <circle clip-rule="evenodd" cx="13" cy="13" fill="#f5a623" fill-rule="evenodd" r="13" />
            <path d="M13 15l-1-1-1-6 2-2 2 2-1 6-1 1zm0 5l-1-2 1-1 2 1-2 2z" fill="#fff" />
          </svg>
          <span *ngIf="data[column] === 'unchecked'">Unchecked</span>
        </mat-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      (click)="handleClick(row, i)"
      [class.clickable]="clickable"
      [class.shadowHover]="clickable"
      *matRowDef="let row; columns: displayedColumns; let i = index"
      data-cy="data-table.component.displayedColumns"
    ></mat-row>
  </mat-table>
  <mat-paginator
    [class.hidden]="data && data.length <= 10"
    class="rounded-corners-bottom"
    #paginator
    (page)="subscribeToPage($event)"
    [pageSizeOptions]="[5, 10, 20]"
    [pageSize]="10"
    [showFirstLastButtons]="true"
    data-cy="data-table.component.paginator"
  >
  </mat-paginator>
  <div *ngIf="deletable && selection.hasValue()" class="deleteWrapper">
    <gcv-button
      (clickEvent)="deleteSelection($event)"
      [buttonText]="deleteText"
      data-cy="data-table.component.deleteButton"
      type="danger pill thin"
    ></gcv-button>
  </div>
</div>
