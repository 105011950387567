import React, { useState, useEffect } from 'react';
import { GcvInputForm } from '../../../lib';
import { ViewMoreIcon } from '../../../icons/ViewMoreIcon';

import { DrawerText, VerticalCenter } from '../styles';

interface Props {
  form: any;
}

export const IntacctForm = ({ form }: Props) => {
  return (
    <>
      <DrawerText>
        To configure this integration, you’ll need to enable access for the Green Check Web Sender ID in your Sage
        Intacct system and create a Green Check Web Services User.
      </DrawerText>
      <DrawerText>
        You will then need to gather the credentials listed below. Follow the steps in the following link to help you
        facilitate this setup.
      </DrawerText>
      <DrawerText>
        <a
          target="_blank"
          href="https://support.greencheckverified.com/knowledge/connect-sage-intacct-to-green-check"
          style={{ display: 'flex' }}
        >
          <VerticalCenter>Step-by-step instructions</VerticalCenter>
          <VerticalCenter style={{ fontSize: 'medium', margin: '1px 0 0 5px' }}>
            <ViewMoreIcon></ViewMoreIcon>
          </VerticalCenter>
        </a>
      </DrawerText>
      <DrawerText>
        Once you have the Company ID, Web Service User ID, and Web Service Password, come back to this page and enter
        them in the fields below to connect the POS integration.
      </DrawerText>
      <GcvInputForm
        {...form}
        name="company_id"
        label="Company ID"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="user_id"
        label="Web Service User ID"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
      <GcvInputForm
        {...form}
        name="user_password"
        label="Web Service Password"
        type="text"
        style={{ width: 'fit-content' }}
        rules={{
          required: true,
        }}
      />
    </>
  );
};
