import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PreloadSelectedModulesList } from '../custom-module-preload-strategy';
import { APP_ROUTES } from './app.routes';

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadSelectedModulesList })],
  providers: [PreloadSelectedModulesList],
  exports: [RouterModule],
})
export class AppRoutingModule {}
